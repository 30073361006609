export const addImmutable = (object, key, value) => ({
  ...object,
  [key]: value
});

export const removeImmutable = (object, key) => {
  const newValues = { ...object };
  delete newValues[key];
  return newValues;
};

export const addToSetImmutable = (set, value) => {
  const newSet = new Set(set);
  newSet.add(value);
  return newSet;
};

export const removeFromSetImmutable = (set, value) => {
  const newSet = new Set(set);
  newSet.delete(value);
  return newSet;
};
