import { FETCH_DROPDOWN_VALUES } from '../action-types';

export const DEFAULT_STATE = {
  durationOptions:    [],
  severityOptions:    [],
  progressionOptions: []
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_DROPDOWN_VALUES.SUCCESS: {
      return {
        ...state,
        durationOptions:    action.payload.dropdownValues.duration,
        severityOptions:    action.payload.dropdownValues.severity,
        progressionOptions: action.payload.dropdownValues.progression
      };
    }
    default: {
      return state;
    }
  }
};
