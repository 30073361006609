import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { I18n } from '@td/shared_utils';

import {
  useHandleFavoriteStatusChange,
  useQuickDxFavorites,
  isDiagnosisCodeFavorite,
  onFavoriteChange,
  getQuickDxIdByDiagnosisCode
} from '../utils';
import { FavoriteActiveIcon, FavoriteDefault } from './icons';

export const CategoryListItem = ({
  diagnose,
  onDiagnoseSelect,
  isAlreadySelected,
  favoritesOnly,
  isPrimaryDiagnosisField
}) => {
  const isFavorite = isDiagnosisCodeFavorite(diagnose.diagnosisCode);

  const quickDxId = diagnose.quickDxId || getQuickDxIdByDiagnosisCode(diagnose.diagnosisCode);
  const { handleFavoriteDiagnosisChange, favoritesEnabled, favorites, isEditable } = useQuickDxFavorites();

  const [handleFavoriteChange] = useHandleFavoriteStatusChange(diagnose, isFavorite, {
    onSuccess: data =>
      handleFavoriteDiagnosisChange(
        {
          ...diagnose,
          quickDxId: data.quickDxId
        },
        data.isFavorite
      )
  });

  const secondaryDiagnosisCheck = !isPrimaryDiagnosisField || !diagnose.isSecondaryDiagnosis;
  const isSelectable = diagnose.active && secondaryDiagnosisCheck;

  const selectDiagnosis = () => {
    if (!isEditable) return;
    if (!isSelectable || !onDiagnoseSelect) return;

    onDiagnoseSelect(diagnose);
  };

  if (!isFavorite && favoritesOnly) return null;

  return (
    <div className={classnames('autocomplete-category-list-item', isAlreadySelected && 'selected')}>
      <div
        className={classnames('is-favorite-icon', !favoritesEnabled && 'uneditable')}
        onClick={() =>
          onFavoriteChange({
            handler:     handleFavoriteChange,
            diagnosisCd: diagnose.diagnosisCode,
            favoritesEnabled,
            isFavorite,
            quickDxId
          })
        }
      >
        {isFavorite ? <FavoriteActiveIcon /> : <FavoriteDefault />}
      </div>

      <div className="diagnose-code" onClick={selectDiagnosis}>
        {diagnose.diagnosisCode}
      </div>

      <div className="diagnose-description" onClick={selectDiagnosis}>
        {isSelectable ? diagnose.diagnosisName : <s>{diagnose.diagnosisName}</s>}
      </div>

      {diagnose.isSecondaryDiagnosis && (
        <div className="secondary-diagnosis-only">
          <I18n scope="quick_dx" text="secondary_diagnosis_only" />
        </div>
      )}

      {!diagnose.active && (
        <div className="diagnose-is-expired">
          <I18n scope="quick_dx" text="expired_diagnose_label" />
        </div>
      )}
    </div>
  );
};

CategoryListItem.propTypes = {
  diagnose:                PropTypes.object.isRequired,
  onDiagnoseSelect:        PropTypes.func.isRequired,
  isAlreadySelected:       PropTypes.bool.isRequired,
  favoritesOnly:           PropTypes.bool,
  isPrimaryDiagnosisField: PropTypes.bool
};
