import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = React.memo(({ width = 16, height = 16, fill = '#000000' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M7.25822 5.98122L11.1268 2.15023L11.9155 1.3615C12.0282 1.24883 12.0282 1.06103 11.9155 0.910798L11.0892 0.084507C10.939 -0.028169 10.7512 -0.028169 10.6385 0.084507L6.01878 4.74178L1.3615 0.084507C1.24883 -0.028169 1.06103 -0.028169 0.910798 0.084507L0.084507 0.910798C-0.028169 1.06103 -0.028169 1.24883 0.084507 1.3615L4.74178 5.98122L0.084507 10.6385C-0.028169 10.7512 -0.028169 10.939 0.084507 11.0892L0.910798 11.9155C1.06103 12.0282 1.24883 12.0282 1.3615 11.9155L6.01878 7.25822L9.84977 11.1268L10.6385 11.9155C10.7512 12.0282 10.939 12.0282 11.0892 11.9155L11.9155 11.0892C12.0282 10.939 12.0282 10.7512 11.9155 10.6385L7.25822 5.98122Z"
    />
  </svg>
));

CloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

CloseIcon.displayName = 'CloseIcon';

export default CloseIcon;
