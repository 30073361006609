import React, { useContext } from 'react';
import useConsultationParticipants from './hooks/use-consultation-participants';

const ParticipantsContext = React.createContext();

const ParticipantsProvider = ({ consultationId, children }) => {
  const participants = useConsultationParticipants({ consultationId });

  return <ParticipantsContext.Provider value={participants}>{children}</ParticipantsContext.Provider>;
};

export const useParticipants = () => useContext(ParticipantsContext);

export default ParticipantsProvider;
