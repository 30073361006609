import { connect } from 'react-redux';

import NetworkTestSuccess from './network-test-success';
import { networkTestComplete } from '../actions';

const mapDispatchToProps = {
  onComplete: networkTestComplete
};

export default connect(
  null,
  mapDispatchToProps
)(NetworkTestSuccess);
