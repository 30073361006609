import { RECEIVE_PERSONAL_HEALTH_RECORDS } from "../actions/index";
import PersonalHealthDocument from '../PersonalHealthDocument';

const DEFAULT_STATE = {
  personalHealthDocuments: [],
  fetched: false
}

export function reducer(state=DEFAULT_STATE, action){
  switch (action.type) {
    case RECEIVE_PERSONAL_HEALTH_RECORDS:
      let data = action.payload.data.personal_health_records;
      return {
        ...state,
        fetched: true,
        personalHealthDocuments: PersonalHealthDocument.buildPersonalHealthDocuments(data)
      };
    default:
      return state;
  }
}
