import React, {useState} from 'react';
import { get } from 'lodash';
import { I18n } from '@td/shared_utils';
import moment from 'moment-timezone';
import Calendar from '../datepicker/datepicker';
import { businessOpenTimeHour, businessCloseTimeHour} from './utils/business_hours';
import getMinDatetime from './utils/get_min_datetime';
import startDate from './utils/get_start_date';

import styles from './styles.module.scss';

export const FollowupDatetimepicker = ({ schedulingIntervalStep = 15, availableTimes }) => {
  const [selectedDate, setSelectedDate] = useState();
  
  const timeZone = moment.tz.guess();
  const systemLocale = get(global, ['I18n', 'locale'], '');
  const locale = systemLocale === 'en' ? 'en-US' : systemLocale;
  const options = {
    weekday:      'long',
    month:        'long',
    day:          'numeric',
    hour:         '2-digit',
    minute:       '2-digit',
    hour12:       true,
    timeZoneName: 'short'
  }

  const handleDateChange = (date) => {
    const followupConsultDate = document.getElementById('followup_consult_date');
    followupConsultDate.value = moment(startDate(availableTimes, date)).format('MM/DD/YYYY h:mm A');
    const followupConsultTimeZone = document.getElementById('followup_time_zone');
    followupConsultTimeZone.value = timeZone;
    setSelectedDate(date);
  }

  const filterTime = (time) => { 
    return time >= getMinDatetime(availableTimes); 
  }

  const selectDateElement = () => {
    if (selectedDate) {
      return (
        <div className={styles.dateWrapper}>
          { selectedDate.toLocaleString(locale, options) }
        </div>
      );
    }
    return (
      <div className={styles.dateWrapper}>
        <I18n scope="provider_reschedule.modal.dates.add"/>
      </div>
    );
  };

  return (
    <div className={styles.followupPicker} tabIndex={0} data-testid="select-date">
      <Calendar
        selectedDate={getMinDatetime(availableTimes)}
        timeIntervals={schedulingIntervalStep}
        onDateChange={handleDateChange}
        filterTime={filterTime}
        minTime={businessOpenTimeHour(availableTimes)}
        maxTime={businessCloseTimeHour(availableTimes)}
        shouldCloseOnSelect={true}
        customInputComponent={selectDateElement()}
      />
    </div>
  )
} 
