import React, { Fragments } from 'react';

const TitleRow = ({ index }) => {
  if (index !== 0) return null;

  return (
    <div className="mds_base_container_title_row">
      <div className="mds_col_2_flex">
        <div className="mds_row_1_flex">
          <label className="mds_label">
            Code
            <div className="mds_spinner_place" />
          </label>
        </div>
      </div>
      <div className="mds_col_6_flex">
        <div className="mds_row_1_flex">
          <label className="mds_label">
            Description
            <div className="mds_spinner_place" />
          </label>
        </div>
      </div>
      <div className="mds_col_1_flex">
        <div className="mds_col_1_flex">
          <div className="mds_row_1_flex" />
        </div>
      </div>
    </div>
  );
};

export default TitleRow;
