import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const SendMessageAction = ({ consultationId, messageRoomId, scheduledDate, supportsNewMessaging }) => {
  const canSendMessage = scheduledDate && moment(scheduledDate).subtract(24, 'hours') < moment();

  if (!(canSendMessage && supportsNewMessaging)) return null;

  return (
    <a href={`/care_team_messages/${messageRoomId}?autolock=true&consultation_id=${consultationId}`}>
      {I18n.t('patient_case_load_mgmt_mvp.patient_list.message_patient')}
    </a>
  );
};

SendMessageAction.propTypes = {
  consultationId: PropTypes.number.isRequired,
  messageRoomId: PropTypes.number.isRequired,
  scheduledDate: PropTypes.string.isRequired,
  supportsNewMessaging: PropTypes.bool.isRequired
};

export default SendMessageAction;
