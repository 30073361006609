import { get, isEmpty, includes } from 'lodash';

import render from '../lib/render';
import { camelizeDeep } from '../lib/utils';
import SurveySummary from './surveys/survey-summary-container';
import MentalStatusExamSummary from './surveys/mental-status-exam-summary-container';

const SUMMARY_BLOCKS = [
  'summary_complaint_block',
  'summary_subjective_note_block',
  'summary_objective_note_block',
  'summary_assessment_note_block',
  'summary_plan_note_block',
  'summary_primary_diagnosis_block',
  'summary_secondary_diagnosis_block',
  'summary_submitted_prescriptions_block',
  'summary_lab_tests_block',
  'summary_primary_follow_up_block',
  'summary_secondary_recommendations_block',
  'summary_images_and_documents_block',
  'summary_additional_follow_up_instructions_and_notes_to_patient_block',
  'summary_excuse_note_block',
  'summary_patient_education_block'
];

const SUMMARY_BLOCK_FOR_NAVIGATION_STEP = {
  EMR: {
    emrAssessment: ['summary_primary_diagnosis_block', 'summary_secondary_diagnosis_block'],
    followup:      [
      'summary_lab_tests_block',
      'summary_primary_follow_up_block',
      'summary_secondary_recommendations_block',
      'summary_excuse_note_block'
    ]
  },
  ALLSCRIPT: {
    followup: [
      'summary_lab_tests_block',
      'summary_primary_follow_up_block',
      'summary_secondary_recommendations_block',
      'summary_excuse_note_block'
    ]
  }
};

const SHOW_SUMMARY_DEFAULT_VALUES = {
  EMR: {
    summary_complaint_block: {
      id:       'complaint_name',
      getValue: () => $('#interview_default_medical_complaint_code_nm').val()
    }
  },
  ALLSCRIPT: {
    summary_complaint_block: {
      id:       'complaint_name',
      getValue: () => $('#interview_default_medical_complaint_code_nm').val()
    }
  }
};

const SHOW_SUMMARY_DEFAULT_BLOCKS = {
  EMR: [
    'summary_complaint_block',
    'summary_patient_education_block',
    'summary_images_and_documents_block',
    'summary_additional_follow_up_instructions_and_notes_to_patient_block'
  ],
  ALLSCRIPT: [
    'summary_complaint_block',
    'summary_patient_education_block',
    'summary_images_and_documents_block',
    'summary_additional_follow_up_instructions_and_notes_to_patient_block'
  ],
  ALLSCRIPT_NUTRITION: [
    'summary_static_message_block',
    'summary_primary_follow_up_block',
    'summary_secondary_recommendations_block',
    'summary_patient_education_block',
    'summary_images_and_documents_block'
  ],
  ALLSCRIPT_MENTAL_HEALTH: [
    'summary_primary_follow_up_block',
    'summary_secondary_recommendations_block',
    'summary_images_and_documents_block',
    'summary_excuse_note_block'
  ]
};

const interview_navigation_type = () => $('#interview_navigation_type').val();
const is_emr = () => includes(['EMR', 'ALLSCRIPT', 'ALLSCRIPT_NUTRITION', 'ALLSCRIPT_MENTAL_HEALTH'], interview_navigation_type());
const defaultShowBlocks = () => get(SHOW_SUMMARY_DEFAULT_BLOCKS, interview_navigation_type(), []);
const blocksForNavigationStep = () => get(SUMMARY_BLOCK_FOR_NAVIGATION_STEP, interview_navigation_type(), {});
const allow = () => is_emr();

const showPsychosocialStressorsSummary = () => {
  const selector = '#psychosocial_stressors_survey_summary';
  const structureSelector = '#react_psychosocial_stressors_container';
  const dataKey = 'psychosocial-stressors-survey';

  if (!$(selector).length) {
    return;
  }

  const surveyStructure = camelizeDeep($(structureSelector).data(dataKey));
  render(SurveySummary, selector, { surveyStructure, hideQuestionLabels: true });
};

const showMentalStatusExamSummary = () => {
  const selector = '#mental_status_exam_survey_summary';
  const structureSelector = '#react_mental_status_exam_container';
  const dataKey = 'mental-status-exam-survey';

  if (!$(selector).length) {
    return;
  }

  const surveyStructure = camelizeDeep($(structureSelector).data(dataKey));
  render(MentalStatusExamSummary, selector, { surveyStructure });
};

export const hideSummaryBlock = block_id => {
  if (allow()) $(`#${block_id}`).hide();
};

export const showSummaryBlock = block_id => {
  if (allow()) $(`#${block_id}`).show();
};

export const setDefaultValues = block_id => {
  if (allow()) {
    const dv = get(SHOW_SUMMARY_DEFAULT_VALUES, [interview_navigation_type(), block_id], {});
    if (!isEmpty(dv)) {
      $(`#${dv.id}`).text(dv.getValue());
    }
  }
};

export const hideSummaryAllBlocks = () => {
  SUMMARY_BLOCKS.forEach(block_id => {
    hideSummaryBlock(block_id);
  });
};

export const showSummaryAllBlocks = () => {
  SUMMARY_BLOCKS.forEach(block_id => {
    showSummaryBlock(block_id);
  });
};

export const showSummaryDefaultBlocks = () => {
  defaultShowBlocks().forEach(block_id => {
    setDefaultValues(block_id);
    showSummaryBlock(block_id);
  });
  // render react component for survey summary
  showPsychosocialStressorsSummary();
  showMentalStatusExamSummary();
};

export const showSummaryBlocksForNavigationStep = navigationStep => {
  const blocks = blocksForNavigationStep();
  if (navigationStep in blocks) {
    const block_ids = blocks[navigationStep];
    block_ids.forEach(block_id => {
      showSummaryBlock(block_id);
    });
  }
};
