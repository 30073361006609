import React from "react";
import PropTypes from "prop-types";

const CalendarAddIcon = React.memo(({ width = 16, height = 16, fill = "#000000" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fill={fill} 
      d="M11.5 9.125C11.5 8.9375 11.3125 8.75 11.125 8.75H8.75V6.375C8.75 6.1875 8.5625 6 8.375 6H7.625C7.40625 6 7.25 6.1875 7.25 6.375V8.75H4.875C4.65625 8.75 4.5 8.9375 4.5 9.125V9.875C4.5 10.0938 4.65625 10.25 4.875 10.25H7.25V12.625C7.25 12.8438 7.40625 13 7.625 13H8.375C8.5625 13 8.75 12.8438 8.75 12.625V10.25H11.125C11.3125 10.25 11.5 10.0938 11.5 9.875V9.125ZM15 3.5C15 2.6875 14.3125 2 13.5 2H12V0.375C12 0.1875 11.8125 0 11.625 0H10.375C10.1562 0 10 0.1875 10 0.375V2H6V0.375C6 0.1875 5.8125 0 5.625 0H4.375C4.15625 0 4 0.1875 4 0.375V2H2.5C1.65625 2 1 2.6875 1 3.5V14.5C1 15.3438 1.65625 16 2.5 16H13.5C14.3125 16 15 15.3438 15 14.5V3.5ZM13.5 14.3125C13.5 14.4375 13.4062 14.5 13.3125 14.5H2.6875C2.5625 14.5 2.5 14.4375 2.5 14.3125V5H13.5V14.3125Z"
    />
  </svg>
));

CalendarAddIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

CalendarAddIcon.displayName = "CalendarAddIcon";

export default CalendarAddIcon;
