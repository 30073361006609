import { groupBy, map } from 'lodash';
import { translate } from '@td/shared_utils';
import { CONSULTS_THAT_CAN_REFER_TO_ITSELF, SERVICE_PLATFORM_CODES } from './constants';

export const groupSpecialties = specialties => {
  const entries = Object.entries(
    groupBy(specialties, spec => {
      if (spec.servicingPlatformCode === SERVICE_PLATFORM_CODES.EMS) return SERVICE_PLATFORM_CODES.Teladoc;
      else return spec.servicingPlatformCode;
    })
  );
  return map(entries, ([servicingPlatformCode, specialties]) => ({
    servicingPlatformCode,
    specialties
  }));
};

export const translateServiceFeature = featureCode =>
  translate(null, 'provider_member_specialties.specialties_features', featureCode);

export const mapServiceCodesToNames = (serviceCodes, services) =>
  map(serviceCodes, serviceCode => {
    const service = services.find(s => s.code === serviceCode);
    return service && service.name;
  });

export const filteredSpecialties = specialties => {
  const filteredEntries = specialties.filter(specialty => specialty.code === 'VPC' ? specialty.specialtyFlavors.includes(specialty.code) : true);
  return filteredEntries;
};
  
export const isServiceReferrable = (service, consultSpecialtyName) =>
  (service.name !== consultSpecialtyName || CONSULTS_THAT_CAN_REFER_TO_ITSELF.includes(service.name)) &&
  (consultSpecialtyName === 'Primary Care' ? service.code !== 'GENMEDICAL' : true) &&
  (service.code === 'VPC' ? service.specialtyFlavors.includes(service.code) : true) &&
  service.code !== 'PROVIDERREFERRAL' &&
  service.referralType;
