import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ReviewAction = ({ consultationId }) => {

  return (
    <Fragment>
      <a key="review" className="review_button" href={`/history/consultation/${consultationId}#lab_tests_section`}>
        Review
      </a>
    </Fragment>
  );
};

ReviewAction.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default ReviewAction;
