exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".EJKEr4tuCFjLGb3zv3DYL {\n  cursor: pointer;\n  flex-shrink: 0; }\n", ""]);

// exports
exports.locals = {
	"participantStatus": "EJKEr4tuCFjLGb3zv3DYL",
	"participantStatus": "EJKEr4tuCFjLGb3zv3DYL"
};