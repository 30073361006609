import {
  FETCH_CLINICAL_MEDICATIONS,
  FETCH_CLINICAL_MEDICATIONS_START,
  FETCH_CLINICAL_MEDICATIONS_SUCCESS,
  FETCH_CLINICAL_MEDICATIONS_FAIL,
  SUBMIT_CLINICAL_MEDICATION_FORM,
  SUBMIT_CLINICAL_MEDICATION_FORM_START,
  SUBMIT_CLINICAL_MEDICATION_FORM_SUCCESS,
  SUBMIT_CLINICAL_MEDICATION_FORM_FAIL,
  SET_CLINICAL_MEDICATION_FORM_FIELD,
  VALIDATE_CLINICAL_MEDICATION_FORM_START,
  VALIDATE_CLINICAL_MEDICATION_FORM_FAIL,
  RESET_CLINICAL_MEDICATION_FORM,
  UPDATE_INPUT_VALUE,
  UPDATE_MEDICATION_LIST,
  FETCH_MEDICATION_LIST_REQUEST,
  FETCH_MEDICATION_LIST_START,
  FETCH_MEDICATION_LIST_SUCCESS,
  FETCH_MEDICATION_LIST_FAIL,
  SET_SELECTED_MEDICATION,
  RESET_MEDICATION_LIST,
  ADD_TO_PENDING_MEDICATION_LIST,
  REMOVE_FROM_PENDING_MEDICATION_LIST,
  CLEAR_PENDING_MEDICATION_LIST,
  SET_SURESCRIPTS_UPDATED_FLAG
} from './actionTypes';

export const addToPendingMedicationList = payload => ({
  type: ADD_TO_PENDING_MEDICATION_LIST,
  payload
});

export const removeFromPendingMedicationList = payload => ({
  type: REMOVE_FROM_PENDING_MEDICATION_LIST,
  payload
});

export const clearPendingMedicationList = () => ({
  type: CLEAR_PENDING_MEDICATION_LIST
});

export const fetchClinicalMedicationsStart = () => ({
  type: FETCH_CLINICAL_MEDICATIONS_START
});

export const fetchClinicalMedicationsSuccess = payload => ({
  type: FETCH_CLINICAL_MEDICATIONS_SUCCESS,
  payload
});

export const fetchClinicalMedicationsFail = error => ({
  type:    FETCH_CLINICAL_MEDICATIONS_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const fetchClinicalMedications = ({ memberId } = {}) => ({
  type:    FETCH_CLINICAL_MEDICATIONS,
  payload: { memberId }
});

export const resetClinicalMedicationForm = () => ({
  type: RESET_CLINICAL_MEDICATION_FORM
});

export const submitClinicalMedicationForm = formData => ({
  type:    SUBMIT_CLINICAL_MEDICATION_FORM,
  payload: formData
});

export const submitClinicalMedicationFormStart = () => ({
  type: SUBMIT_CLINICAL_MEDICATION_FORM_START
});

export const submitClinicalMedicationFormSuccess = payload => ({
  type: SUBMIT_CLINICAL_MEDICATION_FORM_SUCCESS,
  payload
});

export const submitClinicalMedicationFormFail = error => ({
  type:    SUBMIT_CLINICAL_MEDICATION_FORM_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const updateInputValue = value => ({
  type: UPDATE_INPUT_VALUE,
  value
});

export const updateMedicationList = value => ({
  type: UPDATE_MEDICATION_LIST,
  value
});

export const setSelectedMedication = medication => ({
  type: SET_SELECTED_MEDICATION,
  medication
});

export const resetMedicationList = () => ({
  type: RESET_MEDICATION_LIST
});

export const fetchMedicationList = term => {
  return {
    type:    FETCH_MEDICATION_LIST_REQUEST,
    payload: { term }
  };
};

export const fetchMedicationListStart = () => ({
  type: FETCH_MEDICATION_LIST_START
});

export const fetchMedicationListSuccess = data => ({
  type:    FETCH_MEDICATION_LIST_SUCCESS,
  payload: data
});

export const fetchMedicationListFail = error => ({
  type:    FETCH_MEDICATION_LIST_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const onFieldChange = (field, value, formData) => ({
  type:    SET_CLINICAL_MEDICATION_FORM_FIELD,
  payload: { field: value, formData }
});

export const validateClinicalMedicationFormStart = () => ({
  type: VALIDATE_CLINICAL_MEDICATION_FORM_START
});

export const validateClinicalMedicationFormFail = error => ({
  type:    VALIDATE_CLINICAL_MEDICATION_FORM_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const setSurescriptsUpdatedFlag = surescriptsUpdated => ({
  type: SET_SURESCRIPTS_UPDATED_FLAG,
  payload: { surescriptsUpdated }
});
