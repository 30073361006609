import React from 'react';
import LegacyFlowNegotiation from '../negotiation-flows/LegacyFlowNegotiation';
import ProviderProposedFlowNegotiation from '../negotiation-flows/ProviderProposedFlowNegotiation';
import MemberAvailabilityFlowNegotiation from '../negotiation-flows/MemberAvailabilityFlowNegotiation';
import PropTypes from 'prop-types';

const RequestedAndProposedVisitsTableActions = props => {
  const FLOW_FUNCTIONS = {
    Legacy: <LegacyFlowNegotiation {...props} />,
    'Member Availibility': <MemberAvailabilityFlowNegotiation {...props} />,
    'Provider Proposed': <ProviderProposedFlowNegotiation {...props} />
  };

  const { stateMachineCd, memberAvailability } = props;
  if (stateMachineCd == 'CONSULTSTATUS_REQ' && memberAvailability) {
    return FLOW_FUNCTIONS['Member Availibility'];
  } else if (stateMachineCd == 'CONSULTSTATUS_REQ') {
    return FLOW_FUNCTIONS['Legacy'];
  } else if (stateMachineCd == 'CONSULTSTATUS_PROPOSED') {
    return FLOW_FUNCTIONS['Provider Proposed'];
  } else {
    return null;
  }
};

RequestedAndProposedVisitsTableActions.propTypes = {
  stateMachineCd: PropTypes.string,
  consultationId: PropTypes.number.isRequired
};

export default RequestedAndProposedVisitsTableActions;
