import React from 'react';
import PropTypes from 'prop-types';

const SelectInput = props => {
  const { name, label, onChange, defaultOption, value, error, options, hiddenLabel = false } = props;

  return (
    <div className="field-group">
      {!hiddenLabel && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}

      <div className="field">
        <select name={name} value={value} onChange={onChange} className="form-control">
          <option value="">{defaultOption}</option>

          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>

        {error && <div className="alert error-msg">{error[0]}</div>}
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  name:          PropTypes.string.isRequired,
  label:         PropTypes.string.isRequired,
  onChange:      PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value:         PropTypes.string,
  error:         PropTypes.array,
  options:       PropTypes.arrayOf(PropTypes.object),
  hiddenLabel:   PropTypes.bool
};

export default SelectInput;
