import _ from 'lodash';

// Gets the unique sorted country phone code options from the states info
// There may be more than one state in a country
// There may be multiple countries with the same country phone code
// There may be countries that do not have associated phone code currently
// There may be multiple country phone code options for one country
export default function getCountryPhoneCodesFromStates(states) {
  return _(states)
        .map(st => _.pick(st, ['country_cd', 'country_name', 'telephone_country_cd']))
        .uniqBy('country_name')
        .map(st => {
          if (!st.telephone_country_cd || st.telephone_country_cd === 'NA') {
            return {
              ...st,
              telephone_country_cd: null
            };
          }
          // If multiple codes listed like "1-787, 1-939"
          let phoneCodes = st.telephone_country_cd.split(',');
          // Trim whitespace and remove any non-numeric characters like "-", "+", etc
          phoneCodes = _(phoneCodes).map(_.trim).map(ph => ph.replace(/\D/g, '')).value();
          return phoneCodes.map(ph => ({
            ...st,
            telephone_country_cd: ph
          }));
        })
        .flatten()
        .sortBy('country_name')
        .value();
}
