import {
  NUMBER_SELECTED,
  NUMBER_ADDED
} from './actionTypes';

const selectNumber = (id, number) => ({
  type:    NUMBER_SELECTED,
  payload: { id, number }
});

const addNumber = (id, number) => ({
  type:    NUMBER_ADDED,
  payload: { id, number }
});

export { selectNumber, addNumber };
