import React from 'react';
import has from 'lodash/has';
import { createSelector } from '@td/utils';

import DefaultAlertButton from '../components/default-alert-button-container';
import { COMPONENT_ID as PROVIDER_VIDEO_CLOCK_OUT_PROMPT_ID } from 'app/Provider/components/alerts/provider-video-clock-out-prompt';
import ProviderVideoClockOutPromptButtons from 'app/Provider/components/alerts/provider-video-clock-out-prompt/provider-video-clock-out-prompt-buttons-container';

const componentMappings = {
  [PROVIDER_VIDEO_CLOCK_OUT_PROMPT_ID]: ProviderVideoClockOutPromptButtons
};

const getAlertComponentId = state => state.alert.componentId;

export default createSelector(
  getAlertComponentId,
  componentId => {
    if (has(componentMappings, componentId)) {
      const ButtonsForAlert = componentMappings[componentId];

      return <ButtonsForAlert />;
    }

    return <DefaultAlertButton />;
  }
);
