export const MOUNT_CONTAINER_ID = '#quick_dx_root_container';

export const FAVORITES_UNSUPPORTED_VISIT_TYPES = ['NUTRITION'];
export const QUICK_DX_UNEDITABLE_VISIT_TYPE = ['NUTRITION'];

export const WELLNESS_REASONS = ['New Patient Visit', 'Annual Checkup'];
export const WELLNESS_DIAGNOSES = ['Z00.00', 'Z00.01'];

export const DX_NOT_MATCH_REASON_WARNING = 'dx_not_match_reason';
export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
