import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeTravelMedicationSurvey } from '../../actions';

const Answer = React.memo(({ title, value, sectionCode, questionMnemonicCd, answerMnemonicCd, onChange }) => {
  const handleChange = useCallback(() => {
    onChange({
      sectionCode,
      questionType: questionMnemonicCd,
      value: answerMnemonicCd
    });
  }, [onChange, sectionCode, questionMnemonicCd, answerMnemonicCd]);

  return (
    <label>
      <input
        type="radio"
        name={questionMnemonicCd}
        onChange={handleChange}
        checked={value === answerMnemonicCd}
      />

      {title}
    </label>
  );
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (payload) => dispatch(changeTravelMedicationSurvey(payload))
});

Answer.displayName = 'Answer';

Answer.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  sectionCode: PropTypes.string.isRequired,
  questionMnemonicCd: PropTypes.string.isRequired,
  answerMnemonicCd: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(Answer);
