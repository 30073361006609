import {
  INIT_TRAVEL_MEDICATION_SURVEY,
  CHANGE_TRAVEL_MEDICATION_SURVEY,
  FETCH_TRAVEL_MEDICATION_SURVEY,
  INIT_TRAVEL_MEDICATION_SURVEY_CACHE
} from '../action-types';

export const fetchTravelMedicationSurvey = () => ({
  type: FETCH_TRAVEL_MEDICATION_SURVEY
});

export const initTravelMedicationSurvey = (payload) => ({
  type: INIT_TRAVEL_MEDICATION_SURVEY,
  payload
});

export const changeTravelMedicationSurvey = (payload) => ({
  type: CHANGE_TRAVEL_MEDICATION_SURVEY,
  payload
});

export const initTravelMedicationSurveyCache = (payload) => ({
  type: INIT_TRAVEL_MEDICATION_SURVEY_CACHE,
  payload
});
