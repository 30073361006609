import React from 'react';
import PersistentAccordion from '../PersistentAccordion';
import ScheduledVisitsTable from './ScheduledVisitsTable';
import DataTablePagination from '../../../data-table/DataTablePagination';
import { useScheduledVistsTable } from './ScheduledVistsTableProvider';

const ScheduledVisitsTableContainer = () => {
  const {
    loading,
    consultations,
    hasNextPage,
    fetchNextPage,
    metadata: { totalEntries }
  } = useScheduledVistsTable();

  return (
    <PersistentAccordion
      storageKey="td-consult-queues-scheduled-table-open"
      title={I18n.t('dashboard.consult_queues.scheduled_visits_table.title')}
      subtitle={`${totalEntries} ${I18n.t('dashboard.consult_queues.scheduled_visits_table.rows')}`}
      loading={loading}
    >
      <ScheduledVisitsTable data={consultations} totalEntries={totalEntries} />
      <DataTablePagination loading={loading} hasNextPage={hasNextPage} onClick={fetchNextPage} />
    </PersistentAccordion>
  );
};

export default ScheduledVisitsTableContainer;
