export const getCurrentDate = () => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  const modifiedDay = (`0${day}`).slice(-2);
  const modifiedMonth = (`0${month}`).slice(-2);

  return `${modifiedMonth}/${modifiedDay}/${year}`;
};

export const changePropDateFormat = date => {
  if (!date) return '';

  const dateArray = date.split('-');

  return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
};

export const mapDateToValues = date => {
  if (!date) return '';

  const dateArray = date.split('/');
  return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
};

export const mapPropToValues = values => ({
  health_card_state:  values.healthCardProvince,
  health_card_id:     values.healthCardId,
  health_card_expiry: mapDateToValues(values.healthCardExpiry)
});

export const mapValuesToFormData = values => ({
  healthCardProvince: values.health_card_state || '',
  healthCardId:       values.health_card_id || '',
  healthCardExpiry:   changePropDateFormat(values.health_card_expiry) || ''
});
