import React from 'react';
import PropTypes from 'prop-types';

const ViewEHRLink = ({ consultationId, text }) => (
  <a
    className="preview-patient-ehr"
    href={`/consultations/${consultationId}/ehr_preview_modal?load_ease=true`}
    title={I18n.t('consultations.reschedule.provider.action_buttons.preview_patient_ehr')}
  >
    {text || I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.view_ehr')}
  </a>
);

ViewEHRLink.propTypes = {
  consultationId: PropTypes.number.isRequired,
  text: PropTypes.string
};

export default ViewEHRLink;
