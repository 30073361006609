// Constants
const RESET_WEIGHT_FORM            = 'RESET_WEIGHT_FORM';
const PARTIAL_RESET_WEIGHT_FORM    = 'PARTIAL_RESET_WEIGHT_FORM';
const SUBMIT_WEIGHT_FORM           = 'SUBMIT_WEIGHT_FORM';
const SUBMIT_WEIGHT_FORM_START     = 'SUBMIT_WEIGHT_FORM_START';
const SUBMIT_WEIGHT_FORM_SUCCESS   = 'SUBMIT_WEIGHT_FORM_SUCCESS';
const SUBMIT_WEIGHT_FORM_FAIL      = 'SUBMIT_WEIGHT_FORM_FAIL';
const SUBMIT_WEIGHT_FORM_CANCEL    = 'SUBMIT_WEIGHT_FORM_CANCEL';
const VALIDATE_WEIGHT_FORM         = 'VALIDATE_WEIGHT_FORM';
const VALIDATE_WEIGHT_FORM_START   = 'VALIDATE_WEIGHT_FORM_START';
const VALIDATE_WEIGHT_FORM_SUCCESS = 'VALIDATE_WEIGHT_FORM_SUCCESS';
const VALIDATE_WEIGHT_FORM_FAIL    = 'VALIDATE_WEIGHT_FORM_FAIL';
const SET_FORM_FIELD               = 'SET_FORM_FIELD';
const TOGGLE_PATIENT_IS_MINOR      = 'TOGGLE_PATIENT_IS_MINOR';

export {
  SET_FORM_FIELD,
  RESET_WEIGHT_FORM,
  SUBMIT_WEIGHT_FORM,
  SUBMIT_WEIGHT_FORM_START,
  SUBMIT_WEIGHT_FORM_SUCCESS,
  SUBMIT_WEIGHT_FORM_FAIL,
  SUBMIT_WEIGHT_FORM_CANCEL,
  VALIDATE_WEIGHT_FORM,
  VALIDATE_WEIGHT_FORM_START,
  VALIDATE_WEIGHT_FORM_SUCCESS,
  VALIDATE_WEIGHT_FORM_FAIL,
  TOGGLE_PATIENT_IS_MINOR,
  PARTIAL_RESET_WEIGHT_FORM
};
