import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { ButtonBarLoader } from '@td/shared_utils';
import get from 'lodash/get';
import fetchElationEhr from './queries/fetchElationEhr.graphql';

const ButtonElationEhr = ({ consultationId }) => {
  const { loading, data, error } = useQuery(fetchElationEhr, {
    variables: { consultation_id: consultationId }
  });

  if (loading) return <ButtonBarLoader />;
  if (error) return null;
  if (!get(data, 'consultation.elationEhrLink')) return null;

  return (
    <a href={data.consultation.elationEhrLink} target="_blank" rel="noopener noreferrer">
      <div className="button button-elation-ehr">
        <div>Launch Elation EHR</div>
        <div className="button-elation-ehr-icon" />
      </div>
    </a>
  );
};

ButtonElationEhr.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default ButtonElationEhr;
