import { from, of, concat } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, takeUntil, startWith, mergeMap, switchMap, delay, catchError } from 'rxjs/operators';
import { providerOrderV4Path, providerOrderRevokeV4Path } from 'app/config/paths';

import { authToken, teladocApi } from '@td/api';
import { error as errorNotify } from 'app/notifications/actions';

import {
  FETCH_PROVIDER_ORDER_REQUEST,
  FETCH_PROVIDER_ORDER_CANCEL,
  REVOKE_PROVIDER_ORDER_REQUEST,
  REVOKE_PROVIDER_ORDER_START,
  REVOKE_PROVIDER_ORDER_SUCCESS,
  REVOKE_PROVIDER_ORDER_FAIL
} from './actionTypes';

import {
  fetchProviderOrders,
  fetchProviderOrderStart,
  fetchProviderOrderSuccess,
  fetchProviderOrderFail,
  revokeProviderOrderFail
} from './actions';

const makeProviderOrderApiRequest = payload => {
  const { consultationId } = payload;
  const params = {};
  const url = providerOrderV4Path(consultationId);

  return from(teladocApi.get(url, params, authToken.get()));
};

export const fetchProviderOrderEpic = action$ =>
  action$.pipe(
    ofType(FETCH_PROVIDER_ORDER_REQUEST),
    mergeMap(action =>
      makeProviderOrderApiRequest(action.payload).pipe(
        map(response => fetchProviderOrderSuccess(response)),
        takeUntil(action$.pipe(ofType(FETCH_PROVIDER_ORDER_CANCEL))),
        startWith(fetchProviderOrderStart()),
        catchError(error => of(fetchProviderOrderFail(error)))
      )
    )
  );

// REVOKE
const putProviderOrderRevokeRequest = payload => {
  const { consultationId, providerOrderId, internalNote, externalNote } = payload;
  const url = providerOrderRevokeV4Path(consultationId, providerOrderId);
  const params = {
    consultation_id:   consultationId,
    provider_order_id: providerOrderId,
    provider_order:    {
      internal_note: internalNote,
      external_note: externalNote
    }
  };

  return from(teladocApi.put(url, params, authToken.get()));
};

export const revokeProviderOrderSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(REVOKE_PROVIDER_ORDER_SUCCESS),
    // The providerOrder doesn't get updated right after successful revocation
    // even though the endpoint has already returned a success response.
    delay(1000),
    switchMap(() => {
      const {
        settings: {
          consultationParams: { consultationId }
        }
      } = state$.value;

      return of(fetchProviderOrders({ consultationId }));
    })
  );

export const revokeProviderOrderEpic = action$ =>
  action$.pipe(
    ofType(REVOKE_PROVIDER_ORDER_REQUEST),
    switchMap(({ payload }) =>
      concat(
        of({ type: REVOKE_PROVIDER_ORDER_START }),
        putProviderOrderRevokeRequest(payload).pipe(
          switchMap(() => of({ type: REVOKE_PROVIDER_ORDER_SUCCESS })),
          catchError(error => of(revokeProviderOrderFail(error)))
        )
      )
    )
  );

export const revokeProviderOrderFailEpic = action$ =>
  action$.pipe(
    ofType(REVOKE_PROVIDER_ORDER_FAIL),
    switchMap(() => of(errorNotify('Failed to cancel prescription.')))
  );
