exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".dlcqY_g8OqfaRh3gQFRzk {\n  font-size: 14px;\n  color: #696B6B; }\n", ""]);

// exports
exports.locals = {
	"callNotification": "dlcqY_g8OqfaRh3gQFRzk",
	"callNotification": "dlcqY_g8OqfaRh3gQFRzk"
};