import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './char-validator.scss';

const CharValidator = ({ fieldLength, maxLength, minLength }) => {
  const isInvalid = useMemo(() => (maxLength && fieldLength > maxLength) || (minLength && fieldLength < minLength), [
    fieldLength,
    maxLength,
    minLength
  ]);

  const label = useMemo(
    () => (minLength ? `${minLength} Character Minimum. ` : '') + (maxLength ? `${maxLength} Character Maximum. ` : ''),
    [maxLength, minLength]
  );

  return (
    <div className="char-validator">
      <span className={isInvalid ? 'invalid' : ''}>
        {label}(<strong className="count">{fieldLength}</strong>)
      </span>
    </div>
  );
};

CharValidator.propTypes = {
  fieldLength: PropTypes.number.isRequired,
  maxLength:   PropTypes.number,
  minLength:   PropTypes.number
};

export default CharValidator;
