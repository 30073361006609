import React from 'react';
import { Note } from '@td/ehr';
import {
  isAnyLabTestCheckboxPresent,
  isLabTestsSectionReviewRequired,
  isLabTestsNotePresent
} from '../../../ehr-utils';

const EHRNoteForLabTests = () => {
  if (!isAnyLabTestCheckboxPresent()) return null;
  if (!isLabTestsSectionReviewRequired()) return null;

  return (
    <Note>
      Before you can continue, you must download and review this patient’s <a href="#ehr_lab_results">Lab Tests</a>.
    </Note>
  );
};

export default EHRNoteForLabTests;
