import { useEffect, useRef, useState } from 'react';
import getClientInstance from '../../services/apollo-client';
import CONSULTATION_ASSIGNED_ATTENDEES_QUERY from '../queries/consultation-assigned-attendees.graphql';
import { isEqual } from 'lodash';

const participantsSelector = ({
  consultation: {
    consultationInteraction: { assignedPersonRelationships }
  }
}) => assignedPersonRelationships;

const useConsultationParticipants = ({ consultationId }) => {
  const apolloClient = getClientInstance({
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  });

  const [participants, setParticipants] = useState([]);
  const participantsRef = useRef(participants);

  useEffect(() => {
    participantsRef.current = participants;
  }, [participants]);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const { data } = await apolloClient.query({
          query:     CONSULTATION_ASSIGNED_ATTENDEES_QUERY,
          variables: { consultationId }
        });

        const participants = participantsSelector(data).map(({ roleCd, assignedActor: { id, name } }) => ({
          id,
          name,
          roleCd
        }));

        if (!isEqual(participantsRef.current, participants)) {
          setParticipants(participants);
        }

        setTimeout(fetchParticipants, 30000);
      } catch (error) {
        setTimeout(fetchParticipants, 30000);
      }
    };

    fetchParticipants();
  }, []);

  return participants;
};

export default useConsultationParticipants;
