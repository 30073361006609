import { createRequestActions } from '@td/utils';

import {
  FETCH_COMPLAINTS_CLASSES,
  FETCH_COMPLAINTS_LIST,
  RESET_COMPLAINTS_LIST,
  SELECT_COMPLAINT,
  CLEAR_COMPLAINT
} from '../action-types';

export const fetchComplaintsClasses = createRequestActions(FETCH_COMPLAINTS_CLASSES);
export const fetchComplaintsList = createRequestActions(FETCH_COMPLAINTS_LIST);

export const resetComplaintsList = () => ({
  type: RESET_COMPLAINTS_LIST
});

export const selectComplaint = complaint => ({
  type:    SELECT_COMPLAINT,
  payload: {
    complaint
  }
});

export const clearComplaint = () => ({
  type: CLEAR_COMPLAINT
});
