const FETCH_REVIEW_CLINICAL_MEDICATION_REQUEST = 'FETCH_REVIEW_CLINICAL_MEDICATION_REQUEST';
const FETCH_REVIEW_CLINICAL_MEDICATION_SUCCESS = 'FETCH_REVIEW_CLINICAL_MEDICATION_SUCCESS';
const FETCH_REVIEW_CLINICAL_MEDICATION_FAIL    = 'FETCH_REVIEW_CLINICAL_MEDICATION_FAIL';
const FETCH_REVIEW_MEDICATION_HISTORY_SUCCESS  = 'FETCH_REVIEW_MEDICATION_HISTORY_SUCCESS';
const FETCH_REVIEW_MEDICATION_HISTORY_FAIL     = 'FETCH_REVIEW_MEDICATION_HISTORY_FAIL';
const ADD_TO_PENDING_REVIEW_LIST               = 'ADD_TO_PENDING_REVIEW_LIST';
const UPDATE_PENDING_REVIEW_LIST               = 'UPDATE_PENDING_REVIEW_LIST';
const REMOVE_FROM_PENDING_REVIEW_LIST          = 'REMOVE_FROM_PENDING_REVIEW_LIST';
const RESET_PENDING_REVIEW_LIST                = 'RESET_PENDING_REVIEW_LIST';
const SUBMIT_PENDING_REVIEW_LIST               = 'SUBMIT_PENDING_REVIEW_LIST';
const SUBMIT_PENDING_REVIEW_LIST_START         = 'SUBMIT_PENDING_REVIEW_LIST_START';
const SUBMIT_PENDING_REVIEW_LIST_SUCCESS       = 'SUBMIT_PENDING_REVIEW_LIST_SUCCESS';
const SUBMIT_PENDING_REVIEW_LIST_FAIL          = 'SUBMIT_PENDING_REVIEW_LIST_FAIL';
const SUBMIT_PENDING_REVIEW_LIST_CANCEL        = 'SUBMIT_PENDING_REVIEW_LIST_CANCEL';

export {
  FETCH_REVIEW_CLINICAL_MEDICATION_REQUEST,
  FETCH_REVIEW_CLINICAL_MEDICATION_FAIL,
  FETCH_REVIEW_CLINICAL_MEDICATION_SUCCESS,
  FETCH_REVIEW_MEDICATION_HISTORY_SUCCESS,
  FETCH_REVIEW_MEDICATION_HISTORY_FAIL,
  ADD_TO_PENDING_REVIEW_LIST,
  UPDATE_PENDING_REVIEW_LIST,
  REMOVE_FROM_PENDING_REVIEW_LIST,
  RESET_PENDING_REVIEW_LIST,
  SUBMIT_PENDING_REVIEW_LIST,
  SUBMIT_PENDING_REVIEW_LIST_START,
  SUBMIT_PENDING_REVIEW_LIST_FAIL,
  SUBMIT_PENDING_REVIEW_LIST_SUCCESS,
  SUBMIT_PENDING_REVIEW_LIST_CANCEL
};
