import React from 'react';
import PersistentAccordion from '../PersistentAccordion';
import MatchesForWaitlistTable from './MatchesForWaitlistTable';
import { mapKeysDeep, transformKey } from '@td/utils';
import { useMatchesForWaitlistTable } from './MatchesForWaitlistTableProvider';

const MatchesForWaitlistContainer = () => {
  const { data: waitlistData, loading: waitlistLoading} = useMatchesForWaitlistTable();
  const formattedWaitlistData =
    waitlistData &&
    waitlistData.map(item => ({
      communicationMethod: item.attempt.consultation.communicationMethod,
      daysWaiting: item.daysWaiting,
      memberFullName: item.member.fullName,
      memberId: item.member.id,
      consultationId: item.attempt.consultation.id,
      waitlistId: item.waitlist.requestVisitWaitlistId,
      memberAvailability: {
        memberAvailabilityJson: item.member.availability && item.member.availability.memberAvailabilityJson,
        timezone: item.member.availability && item.member.availability.timezone,
        notes: item.member.availability && item.member.availability.notes,
      },
      consultRequestReason: item.attempt.consultation.consultRequestReason,
      consultStatus: item.attempt.consultation.consultStatus
      })).map(item => mapKeysDeep(item, transformKey));

  return (
    <PersistentAccordion
      storageKey="td-consult-queues-waitlist-table-open"
      title={I18n.t('dashboard.consult_queues.matches_for_waitlist_table.title')}
      subtitle={`${waitlistData && waitlistData.length} ${I18n.t(
        'dashboard.consult_queues.matches_for_waitlist_table.rows'
      )}`}
      loading={waitlistLoading}
    >
      <MatchesForWaitlistTable data={formattedWaitlistData} />
    </PersistentAccordion>
  );
};

export default MatchesForWaitlistContainer;
