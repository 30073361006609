import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConnectedConferencePhoneSelect from './conferencePhoneSelect';
import ConnectedConferencePhoneInput from './conferencePhoneInput';

export default class ConferenceParticipantPerson extends Component {
  static propTypes = {
    admin:          PropTypes.bool.isRequired,
    id:             PropTypes.number.isRequired,
    name:           PropTypes.string.isRequired,
    type:           PropTypes.string.isRequired,
    phoneNumbers:   PropTypes.arrayOf(PropTypes.string).isRequired,
    title:          PropTypes.string.isRequired,
    selectedNumber: PropTypes.string.isRequired,
    callStatus:     PropTypes.string.isRequired
  };

  state = { isAddingCustomNumber: false };

  componentWillReceiveProps = ({ selectedNumber }) =>
    this.setState({ isAddingCustomNumber: selectedNumber === 'other' });

  name = () => (this.props.admin ? 'You' : this.props.name);

  title = () => (this.props.admin ? '' : this.props.title);

  render = () => {
    const type = this.props.type.toLowerCase();
    return (
      <div className="participant-info">
        <div className="participant-info-demographic">
          <div className="participant-name">
            <p>{this.name()}</p>
          </div>
          <div className="participant-role">
            <p>{this.title()}</p>
          </div>
        </div>
        {type === 'member' ? (
          ''
        ) : (
          <div className="participant-info-phone">
            <ConnectedConferencePhoneSelect
              callStatus={this.props.callStatus}
              id={this.props.id}
              selectedNumber={this.props.selectedNumber}
              phoneNumbers={this.props.phoneNumbers}
            />

            <ConnectedConferencePhoneInput
              id={this.props.id}
              isAddingCustomNumber={this.state.isAddingCustomNumber}
            />
          </div>
        )}
      </div>
    );
  };
}
