exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._39rO2i4ogiJwE3nzeo5Rnb.teladocModalWrapper .teladocModalContainer {\n  height: initial;\n  width: 640px; }\n  ._39rO2i4ogiJwE3nzeo5Rnb.teladocModalWrapper .teladocModalContainer .teladocModalContent {\n    padding: 24px;\n    border-radius: 12px;\n    max-width: none; }\n    ._39rO2i4ogiJwE3nzeo5Rnb.teladocModalWrapper .teladocModalContainer .teladocModalContent .teladocModalHeader {\n      border-bottom: none;\n      padding: 0;\n      min-height: 36px;\n      height: unset;\n      display: flex;\n      justify-content: space-between;\n      gap: 8px; }\n      ._39rO2i4ogiJwE3nzeo5Rnb.teladocModalWrapper .teladocModalContainer .teladocModalContent .teladocModalHeader .teladocModalTitle {\n        font-size: 22px; }\n    ._39rO2i4ogiJwE3nzeo5Rnb.teladocModalWrapper .teladocModalContainer .teladocModalContent .teladocModalBody {\n      padding: 0; }\n", ""]);

// exports
exports.locals = {
	"externalCalendarsModal": "_39rO2i4ogiJwE3nzeo5Rnb",
	"externalCalendarsModal": "_39rO2i4ogiJwE3nzeo5Rnb"
};