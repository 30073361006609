import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { translate } from '@td/shared_utils';
import { EVENT_TIME_FORMAT } from '../../constants';
import EventDetails from '../event-details';
import styles from './styles.module.scss';

const RecommendedWorkingHoursEvent = React.memo(({ event, createRecommendedWorkingHours, isProviderCalendarV2 }) => {
  const { start, end } = event;

  const eventRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const startTime = moment(start).format(EVENT_TIME_FORMAT);
  const endTime = moment(end).format(EVENT_TIME_FORMAT);

  const handleClick = useCallback(
    e => {
      if (isProviderCalendarV2) {
        e.stopPropagation();
        setIsOpen(true);
      }
    },
    [isProviderCalendarV2]
  );

  const handleApprove = useCallback(() => {
    createRecommendedWorkingHours(event);
    setIsOpen(false);
  }, [event, createRecommendedWorkingHours]);

  const handleCancel = useCallback(e => {
    e.stopPropagation();
    setIsOpen(false);
  }, []);

  useEffect(() => {
    function handleClickOutside(e) {
      if (eventRef.current && !eventRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        ref={eventRef}
        onClick={handleClick}
        className={styles.recommendedWorkingHoursEvent}
        data-testid="recommended-working-hours-event"
      >
        <div className={styles.recommendedWorkingHoursEventTitle} data-testid="recommended-working-hours-event-title">
          {translate(null, 'provider_calendar.events.recommended_working_hours', 'title')}
        </div>
        <div
          className={styles.recommendedWorkingHoursEventTimeRange}
          data-testid="recommended-working-hours-event-time-range"
        >
          {startTime} &ndash; {endTime}
        </div>

        {isProviderCalendarV2 && isOpen && (
          <EventDetails event={event} onApprove={handleApprove} onCancel={handleCancel} />
        )}
      </div>
    </React.Fragment>
  );
});

RecommendedWorkingHoursEvent.displayName = 'RecommendedWorkingHoursEvent';
RecommendedWorkingHoursEvent.propTypes = {
  event: PropTypes.shape({
    start: PropTypes.instanceOf(Date).isRequired,
    end:   PropTypes.instanceOf(Date).isRequired
  }),
  createRecommendedWorkingHours: PropTypes.func,
  isProviderCalendarV2:          PropTypes.bool.isRequired
};

export default RecommendedWorkingHoursEvent;
