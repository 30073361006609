const START_CONFERENCE               = 'conference/START_CONFERENCE';
const CONFERENCE_START_BLOCKED       = 'conference/CONFERENCE_START_BLOCKED';
const CONFERENCE_START_UNBLOCKED     = 'conference/CONFERENCE_START_UNBLOCKED';
const CONFERENCE_CONFIRMATION_ACCEPT = 'conference/CONFERENCE_CONFIRMATION_ACCEPT';
const PROVIDER_SELECT_NOT_CONNECTED  = 'conference/PROVIDER_SELECT_NOT_CONNECTED';
const CONFERENCE_CONFIRMATION_REJECT = 'conference/CONFERENCE_CONFIRMATION_REJECT';
const FETCH_CONFERENCE               = 'conference/FETCH_CONFERENCE';
const FETCH_VENDORS                  = 'conference/FETCH_VENDORS';
const FETCH_COUNTRY_PHONE_CODES      = 'conference/FETCH_COUNTRY_PHONE_CODES';
const NUMBER_SELECTED                = 'conference/NUMBER_SELECTED';
const NUMBER_ADDED                   = 'conference/NUMBER_ADDED';
const CALL_PARTICIPANT               = 'conference/CALL_PARTICIPANT';
const CALL_STATUS_CHANGE             = 'conference/CALL_STATUS_CHANGE';
const MUTE_TOGGLE                    = 'conference/MUTE_TOGGLE';
const ADD_PARTICIPANT                = 'conference/ADD_PARTICIPANT';
const REMOVE_PARTICIPANT             = 'conference/REMOVE_PARTICIPANT';


export {
  START_CONFERENCE,
  CONFERENCE_START_BLOCKED,
  CONFERENCE_START_UNBLOCKED,
  CONFERENCE_CONFIRMATION_ACCEPT,
  PROVIDER_SELECT_NOT_CONNECTED,
  CONFERENCE_CONFIRMATION_REJECT,
  FETCH_CONFERENCE,
  FETCH_VENDORS,
  FETCH_COUNTRY_PHONE_CODES,
  NUMBER_SELECTED,
  NUMBER_ADDED,
  CALL_PARTICIPANT,
  CALL_STATUS_CHANGE,
  MUTE_TOGGLE,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT
};
