import { UPDATE_MENTAL_STATUS_EXAM_INPUT } from './action-types';

export const MSEReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MENTAL_STATUS_EXAM_INPUT:
      const { questionCode, inputValue } = action.payload;
      return { ...state, [questionCode]: inputValue };
    default:
      return state;
  }
};
