exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3JDol_w2ZBg36BnI_Le5Kp {\n  margin-block: 5px;\n  width: fit-content; }\n  ._3JDol_w2ZBg36BnI_Le5Kp ._3laW_QHJ9FAzhha9iBbODs {\n    color: #42a6cb;\n    height: 16px; }\n", ""]);

// exports
exports.locals = {
	"followupPicker": "_3JDol_w2ZBg36BnI_Le5Kp",
	"followupPicker": "_3JDol_w2ZBg36BnI_Le5Kp",
	"dateWrapper": "_3laW_QHJ9FAzhha9iBbODs",
	"dateWrapper": "_3laW_QHJ9FAzhha9iBbODs"
};