import _ from 'lodash';
import { logger, mapKeysDeep, transformKey } from '@td/utils';
import {
  INITIAL_CALL_STATE,
  CONFERENCE_NOT_STARTED,
  CONFERENCE_COMPLETED,
  NOT_CONNECTED,
  CONNECTED,
  CONFERENCE_IN_PROGRESS
} from '../lib/states';
import {
  START_CONFERENCE,
  CONFERENCE_CONFIRMATION_ACCEPT,
  CONFERENCE_CONFIRMATION_REJECT,
  CONFERENCE_START_BLOCKED,
  CONFERENCE_START_UNBLOCKED,
  FETCH_CONFERENCE,
  FETCH_VENDORS,
  FETCH_COUNTRY_PHONE_CODES,
  NUMBER_SELECTED,
  NUMBER_ADDED,
  CALL_PARTICIPANT,
  CALL_STATUS_CHANGE,
  MUTE_TOGGLE,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT
} from '../actions/actionTypes';
import { isInactiveInboundCaller } from '../lib/participants';
import getCountryPhoneCodesFromStates from '../lib/countryPhoneCodes';

export const INITIAL_STATE = {
  dialMember:              true,
  status:                  CONFERENCE_NOT_STARTED,
  conferenceParticipants:  [],
  conferenceStartAttempts: 0,
  warmTransferVendors:     [],
  countryPhoneCodes:       []
};

const VENDOR_ACTION_WARM_TRANSFER = 'Warm Transfer';

const conferenceReducer = (state = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case START_CONFERENCE:
      newState = {
        ...state,
        status:                  action.payload,
        conferenceStartAttempts: state.conferenceStartAttempts + 1
      };
      break;

    case CONFERENCE_START_BLOCKED:
    case CONFERENCE_START_UNBLOCKED:
    case CONFERENCE_CONFIRMATION_ACCEPT:
    case CONFERENCE_CONFIRMATION_REJECT:
      // We don't allow showing start conference again if the conference has started already
      if (state.status === CONFERENCE_IN_PROGRESS || state.status === CONFERENCE_COMPLETED) {
        return { ...state };
      }
      newState = { ...state, status: action.payload };
      break;

    case FETCH_CONFERENCE: {
      const {
        id,
        uuid,
        token,
        video_websocket_url,
        conference_attendees,
        consultation_id,
        status
      } = action.payload.data.conference;

      const conferenceParticipants = conference_attendees.map(attendee => {
        const { type, title, phone_numbers, name, attendee_id, attendance_id, admin } = attendee;

        return {
          person: {
            type,
            title,
            name,
            admin,
            id:             attendee.id,
            attendeeId:     attendee_id,
            attendanceId:   attendance_id,
            phoneNumbers:   phone_numbers,
            selectedNumber: phone_numbers[0],
            consultationId: consultation_id
          },
          conferenceCall: {
            ...INITIAL_CALL_STATE,
            /* eslint-disable camelcase */
            callStatus: attendance_id ? CONNECTED : NOT_CONNECTED
            /* eslint-enable */
          }
        };
      });

      const conferenceStatus = status === 'active' ? CONFERENCE_NOT_STARTED : CONFERENCE_COMPLETED;

      newState = {
        ...state,
        id,
        uuid,
        token,
        status:            conferenceStatus,
        videoWebsocketUrl: video_websocket_url,
        conferenceParticipants
      };
      break;
    }

    case FETCH_VENDORS: {
      const { vendors } = action.payload.data;
      const warmTransferVendors = vendors.filter(v => v.action === VENDOR_ACTION_WARM_TRANSFER);

      newState = {
        ...state,
        warmTransferVendors
      };
      break;
    }

    case FETCH_COUNTRY_PHONE_CODES: {
      const states = _.get(action, 'payload.data.states', []);
      const countryPhoneCodes = getCountryPhoneCodesFromStates(states);
      return {
        ...state,
        countryPhoneCodes
      };
    }

    case NUMBER_SELECTED: {
      const conferenceParticipants = state.conferenceParticipants.map(
        ({ person, conferenceCall }) => {
          if (person.id === action.payload.id) {
            return {
              conferenceCall,
              person: { ...person, selectedNumber: action.payload.number }
            };
          }

          return { person, conferenceCall };
        }
      );

      newState = { ...state, conferenceParticipants };
      break;
    }

    case NUMBER_ADDED: {
      const conferenceParticipants = state.conferenceParticipants.map(
        ({ person, conferenceCall }) => {
          if (person.id === action.payload.id) {
            return {
              conferenceCall,
              person: {
                ...person,
                selectedNumber: action.payload.number,
                phoneNumbers:   [action.payload.number, ...person.phoneNumbers]
              }
            };
          }

          return { person, conferenceCall };
        }
      );

      newState = { ...state, conferenceParticipants };
      break;
    }

    case CALL_PARTICIPANT: {
      const { participantId, attendeeId, attendanceId, conferenceId } = mapKeysDeep(
        action.payload.data,
        transformKey
      );

      const conferenceParticipants = state.conferenceParticipants.map(
        ({ person, conferenceCall }) => {
          if (person.id === participantId) {
            return {
              conferenceCall,
              person: { ...person, attendeeId, attendanceId, conferenceId }
            };
          }

          return { person, conferenceCall };
        }
      );

      newState = { ...state, conferenceParticipants };
      break;
    }

    case CALL_STATUS_CHANGE: {
      const { attendanceId, callStatus } = mapKeysDeep(action.payload.data, transformKey);
      const conferenceParticipants = state.conferenceParticipants
        .map(({ person, conferenceCall }) => {
          if (person.attendanceId === attendanceId) {
            return {
              conferenceCall: { ...conferenceCall, callStatus },
              person
            };
          }

          return { person, conferenceCall };
        })
        // Delete inbound caller participant if it is disconnected
        // We are not allowed to directly call inbound caller
        // because its phone number is nurse line
        .filter(participant => !isInactiveInboundCaller(participant));

      newState = { ...state, conferenceParticipants };
      break;
    }

    case MUTE_TOGGLE: {
      const { attendanceId, muted } = action.payload;
      const conferenceParticipants = state.conferenceParticipants.map(
        ({ person, conferenceCall }) => {
          if (person.attendanceId === attendanceId) {
            return {
              conferenceCall: { ...conferenceCall, muted },
              person
            };
          }

          return { person, conferenceCall };
        }
      );

      newState = { ...state, conferenceParticipants };
      break;
    }

    case ADD_PARTICIPANT: {
      newState = {
        ...state,
        dialMember:             action.dialMember,
        conferenceParticipants: [action.participant, ...state.conferenceParticipants]
      };
      break;
    }

    case REMOVE_PARTICIPANT: {
      const filtered = state.conferenceParticipants.filter(
        participant => participant.person.id !== action.id
      );

      newState = {
        ...state,
        dialMember:             action.dialMember,
        conferenceParticipants: filtered
      };
      break;
    }

    default:
      return state;
  }

  logger(
    { ...newState, countryPhoneCodes: '' },
    'ConferenceWidget State Change',
    false,
    action.type
  );
  return newState;
};

export default conferenceReducer;
