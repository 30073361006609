import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate, I18n } from '@td/shared_utils';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { get, isEmpty, includes, reject } from 'lodash';

import {
  FluentProvider,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  Button,
  useId,
  Toaster,
  Toast,
  ToastTitle,
  useToastController,
  teamsLightTheme
} from '@fluentui/react-components';

import { redirectToHome } from 'app/lib/utils';
import { FormikContext } from '../contexts';
import { FIELDS } from '../constants';
import ModalTitle from './ModalTitle';
import ModalContent from './ModalContent';
import ModalActions from './ModalActions';
import cancelConsult from '../apis/cancelConsult';
import prepareConsultFormData from '../utils/prepareConsultFormData';
import { getConsultNavigation, passedVerificationStep } from '../utils/consultNavigation';

import styles from '../styles.module.scss';

const validationSchema = () => Yup.object({
  [FIELDS.REASON]: Yup
    .string()
    .required(translate(null, 'consultation.cancel.modal.fields.reason.error')),
  [FIELDS.NOTES]: Yup
    .string()
    .required(translate(null, 'consultation.cancel.modal.fields.notes.error'))
});

const CancelConsult = ({ cancelReasons, restrictedReasons, cancelUrl }) => {
  const allReasons = cancelReasons.map(([text, value]) => {
    return { value, text };
  });
  const [availableReasons, setAvailableReasons] = useState([]);
  const [cancelInProgress, setCancelInProgress] = useState(false);

  const toasterId = useId('consult-cancel-toaster');
  const { dispatchToast } = useToastController(toasterId);

  const showConsultNotInitializedToast = () => {
    dispatchToast(
      <Toast>
        <ToastTitle><I18n scope="consultation.cancel.not_initialized_warning" /></ToastTitle>
      </Toast>,
      { position: 'top-end', intent: 'warning', timeout: 10000 }
    );
  };

  const modalOpenChange = (ev, data) => {
    if (isEmpty(getConsultNavigation())) {
      showConsultNotInitializedToast();
      return data.open = false;
    }

    if (cancelInProgress) {
      return data.open = true;
    }

    if (data.open) {
      if (passedVerificationStep()) {
        const filteredReasons = reject(allReasons, ({ value }) => includes(restrictedReasons, value));
        setAvailableReasons(filteredReasons);
      } else {
        setAvailableReasons(allReasons);
      }
    } else {
      setAvailableReasons([]);
    }
  };

  const formInitialValues = {
    [FIELDS.REASON]: '',
    [FIELDS.NOTES]: ''
  };

  const showSuccessToast = () => {
    dispatchToast(
      <Toast>
        <ToastTitle><I18n scope="consultation.cancel.success" /></ToastTitle>
      </Toast>,
      { position: 'top-end', intent: 'success', timeout: 10000 }
    );
  };

  const showErrorToast = (isNotAuthorizedError) => {
    const errorMessage = isNotAuthorizedError ?
      <I18n scope="consultation.cancel.errors.unauthorized" /> :
      <I18n scope="consultation.cancel.errors.generic" />;
    dispatchToast(
      <Toast>
        <ToastTitle>{errorMessage}</ToastTitle>
      </Toast>,
      { position: 'top-end', intent: 'error', timeout: 10000 }
    );
  };

  const handleSubmit = ({ reason, notes }) => {
    setCancelInProgress(true);

    const consultFormData = prepareConsultFormData(reason, notes);

    cancelConsult({
      cancelUrl,
      data: consultFormData,
      onSuccess: () => {
        showSuccessToast();
        redirectToHome();
      },
      onError: error => {
        showErrorToast(get(error, 'status') === 403);
        redirectToHome();
      }
    });
  };

  return (
    <FluentProvider theme={teamsLightTheme}>
      <Toaster toasterId={toasterId} />
      <Dialog onOpenChange={modalOpenChange}>
        <DialogTrigger>
          <Button className={styles.cancelConsultBtn} />
        </DialogTrigger>
        <DialogSurface>
          <DialogBody>
            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchema()}
              onSubmit={handleSubmit}
            >
              {(formikContext) => (
                <FormikContext.Provider
                  value={{
                    ...formikContext,
                    cancelInProgress
                  }}
                >
                  <ModalTitle />
                  <ModalContent cancelReasonsOptions={availableReasons} />
                  <ModalActions />
                </FormikContext.Provider>
              )}
            </Formik>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </FluentProvider>
  );
};

CancelConsult.propTypes = {
  cancelReasons: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.string)
  ).isRequired,
  restrictedReasons: PropTypes.arrayOf(PropTypes.string),
  cancelUrl: PropTypes.string.isRequired
};

export default CancelConsult;
