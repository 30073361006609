import {
  ADD_NEW_ITEM_TO_SECONDARY_DIAGNOSES_LIST,
  REMOVE_ITEM_AT_INDEX_FROM_SECONDARY_DIAGNOSES_LIST,
  REPLACE_ITEM_AT_INDEX_IN_SECONDARY_DIAGNOSES_LIST
} from '../actions';
import removeAtIndex from '../../utils/arrays/removeAtIndex';
import replaceAtIndex from '../../utils/arrays/replaceAtIndex';

const DEFAULT_STATE = {
  secondaryDiagnosesList: []
};

export const secondaryDiagnosestReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_ITEM_TO_SECONDARY_DIAGNOSES_LIST: {
      return {
        ...state,
        secondaryDiagnosesList: [...state.secondaryDiagnosesList, action.payload.newItem]
      };
    }
    case REMOVE_ITEM_AT_INDEX_FROM_SECONDARY_DIAGNOSES_LIST: {
      return {
        ...state,
        secondaryDiagnosesList: removeAtIndex(state.secondaryDiagnosesList, action.payload.index)
      };
    }
    case REPLACE_ITEM_AT_INDEX_IN_SECONDARY_DIAGNOSES_LIST: {
      return {
        ...state,
        secondaryDiagnosesList: replaceAtIndex(
          state.secondaryDiagnosesList,
          action.payload.index,
          action.payload.updatedItem
        )
      };
    }
    default: {
      return state;
    }
  }
};

export default secondaryDiagnosestReducer;
