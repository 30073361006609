import React, { Fragment } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const CardIdField = (
  {
    label,
    styles,
    name = 'healthCardId',
    id = 'healthCardId',
    errors
  }) => (
    <Fragment>
      <label
        className={styles.fieldLabel}
        htmlFor={id}
      >{label}</label>
      <Field
        type="text"
        name={name}
        id={id}
        className={cx(styles.inputField, errors[name] ? styles.errorField : '')}
      />
      {
        errors[name] &&
        <span className={styles.errorMessage} data-testid="id-error">
          {errors[name]}
        </span>
      }
    </Fragment>
  );

CardIdField.propTypes = {
  label:  PropTypes.string,
  styles: PropTypes.object,
  name:   PropTypes.string,
  id:     PropTypes.string,
  errors: PropTypes.shape({
    healthCardId: PropTypes.string
  })
};
