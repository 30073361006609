import { compact, filter, join } from 'lodash';
import { createSelector } from '@td/utils';
import { TRAVEL_MEDICATION_COMPLAINT_CODE } from '../../../constants';
import { sectionCodes } from '../constants';
import { sectionToNote } from '../utils';

const getSectionAnswers = ({ chiefComplaint, travelMedicationSurvey: { survey } }, sectionCode) => {
  if (!chiefComplaint || chiefComplaint.complaintCd !== TRAVEL_MEDICATION_COMPLAINT_CODE) {
    return [];
  }

  return filter(survey[sectionCode], ({ value }) => !!value);
};

export default createSelector(
  (state) => getSectionAnswers(state, sectionCodes.ASSOCIATED_CONDITIONS),
  (state) => getSectionAnswers(state, sectionCodes.VACCINATION_HISTORY),
  (associatedConditions, vaccinationHistory) => (
    join(
      compact([
        sectionToNote(associatedConditions, sectionCodes.ASSOCIATED_CONDITIONS),
        sectionToNote(vaccinationHistory, sectionCodes.VACCINATION_HISTORY)
      ]),
      "\n\n"
    )
  )
);
