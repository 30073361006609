import React from 'react';
import PropTypes from 'prop-types';
import styles from './consult-followup.module.scss';
import Loader from 'app/components/Loader';
import { I18n } from '@td/shared_utils';
import TextWithPopup from './text-with-popup';
import { groupSpecialties, filteredSpecialties } from '../helpers';
import { useQuery } from '@apollo/client';
import SERIVCE_SPECIALTIES_QUERY from '../queries/service-specialties.graphql';

export const TRANSLATION_SCOPE = 'provider_member_specialties';

const InfoboxSpecialties = ({ memberId, stateCode }) => {
  const { data, error, loading } = useQuery(SERIVCE_SPECIALTIES_QUERY, {
    variables: { memberId, stateCode }
  });

  const renderSpecialties = () => {
    const filteredServiceSpecialties = filteredSpecialties(data.member.serviceSpecialties);
    const platformsWithSpecialties = groupSpecialties(filteredServiceSpecialties || []);

    return platformsWithSpecialties.map(platform => (
      <div key={platform.servicingPlatformCode} className={styles.specialtiesGroup}>
        <div className={styles.specialtiesGroupTitle}>
          <I18n scope={`${TRANSLATION_SCOPE}.service_platforms`} text={platform.servicingPlatformCode} />
        </div>
        <div>
          {platform.specialties.map((spec, index) => (
            <div key={index}>
              <TextWithPopup
                text={spec.displayName}
                popupTitle={spec.displayName}
                popupDescription={<I18n scope={`${TRANSLATION_SCOPE}.specialties_descriptions`} text={spec.code} />}
              />
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const renderContent = () => {
    if (error) return <I18n scope={TRANSLATION_SCOPE} text="failed_to_load" />;
    else if (loading) return <Loader />;
    else return renderSpecialties();
  };

  return (
    <li className={styles.infoboxSpecialtiesRoot}>
      <strong>
        <I18n scope={TRANSLATION_SCOPE} text="title" />
      </strong>
      <div>{renderContent()}</div>
    </li>
  );
};

InfoboxSpecialties.propTypes = {
  memberId: PropTypes.number.isRequired
};

export default InfoboxSpecialties;
