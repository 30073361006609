import { ErrorNames as OTErrorNames } from 'opentok-network-test-js';
import includes from 'lodash/includes';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

export const ErrorNames = {
  TAS_CREDENTIALS: 'TasCredentialsError',
  FAILED_AUDIO_TEST: 'FailedAudioTestError',
  FAILED_VIDEO_TEST: 'FailedVideoTestError',
  ...OTErrorNames
};

export const isUnsupportedBrowserError = error =>
  includes(get(error, 'errorCodes'), ErrorNames.UNSUPPORTED_BROWSER);

export const isDevicePermissionError = error =>
  !isEmpty(
    intersection(get(error, 'errorCodes'), [
      ErrorNames.FAILED_TO_CREATE_LOCAL_PUBLISHER,
      ErrorNames.INIT_PUBLISHER_ERROR
    ])
  );

export const isMissingCameraError = error =>
  !isEmpty(
    intersection(get(error, 'errorCodes'), [
      ErrorNames.NO_VIDEO_CAPTURE_DEVICES,
      ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES
    ])
  );

export const isMissingMicrophoneError = error =>
  !isEmpty(
    intersection(get(error, 'errorCodes'), [
      ErrorNames.NO_AUDIO_CAPTURE_DEVICES,
      ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES
    ])
  );

export const isFailedAudioTestError = error =>
  includes(get(error, 'errorCodes'), ErrorNames.FAILED_AUDIO_TEST);

export const isFailedVideoTestError = error =>
  includes(get(error, 'errorCodes'), ErrorNames.FAILED_VIDEO_TEST);

export default class OpenTokNetworkTestError extends Error {
  constructor(message, errorCodes, testResults = null) {
    super(message);

    this.errorCodes = errorCodes;
    this.testResults = testResults;
    this.name = 'OpenTokNetworkTestError';

    Error.captureStackTrace(this, OpenTokNetworkTestError);
  }
}
