import { isEhrTabReviewed } from '../../ehr/ehr-utils';

const validate = () => {
  if (isEhrTabReviewed()) {
    return [];
  }

  return ['Please mark as reviewed EHR section before continue.'];
};

export default () => Promise.resolve(validate());
