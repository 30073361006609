import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import apolloLogger from 'apollo-link-logger';

const getClientInstance = ({
  uri = `${window.SCREENFLOW_HOST}/v5/graphql`,
  credentials = 'same-origin',
  envelop = window.TELADOC_ENVELOP,
  logger = () => null, // FIXME: This func and env variables
  cacheOptions = {},
  defaultOptions = {}
} = {}) => {
  const headers = { ...envelop };

  return new ApolloClient({
    link: ApolloLink.from([
      apolloLogger,
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) => logger({ message, locations, path }));
        }
        if (networkError) logger({ message: 'Network error', networkError });
      }),
      require('graphql-ruby-operation-store').apolloLink,
      new BatchHttpLink({
        uri,
        credentials,
        headers,
        batchInterval: 100
      })
    ]),
    cache: new InMemoryCache(cacheOptions),
    defaultOptions
  });
};

export default getClientInstance;
