import React from 'react';
import PropTypes from 'prop-types';

const ProviderVideoClockOutPromptButtons = ({ remainClockedInForVideo, clockInForPhone }) => (
  <div>
    <button onClick={remainClockedInForVideo}>Remain clocked in for video</button>
    <button onClick={clockInForPhone}>Close</button>
  </div>
);

ProviderVideoClockOutPromptButtons.propTypes = {
  remainClockedInForVideo: PropTypes.func.isRequired,
  clockInForPhone: PropTypes.func.isRequired
};

export default ProviderVideoClockOutPromptButtons;
