export const UNITS = [
  'mg',
  'ml',
  'g',
  'units',
  'intl units',
  'mcg',
  'ea',
  'in',
  'meq',
  'gtt',
  'appful',
  'scp',
  'supp(s)',
  'gum',
  'patch',
  'loz',
  'mu',
  'mmol',
  'cap(s)',
  'l',
  'tab(s)',
  'puff(s)',
  'spray(s)',
  'dose',
  'inh',
  'pkt(s)',
  'kit',
  'app',
  'ng/kg',
  'mbq',
  'mci',
  'uci',
  'pump(s)'
];
