import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field } from 'formik';
import { row, fullWidth, field, quarter, dispenseInstructions } from '../styles.module.scss';

class DispenseInstructions extends React.Component {
  render() {
    const { values, errors, touched, ...props } = this.props;

    return (
      <div className={dispenseInstructions}>
        <h3>Dispense Instructions</h3>
        <div className={cx(row, quarter)}>
          <div className={field}>
            <label>Total Quantity</label>
            <Field
              name="totalQuantity"
              type="text"
              className="form-field"
              placeholder="Enter a total quantity"
              value={values.totalQuantity}
            />
            {
              errors.totalQuantity && touched.totalQuantity &&
              <div>{errors.totalQuantity}</div>
            }
          </div>
        </div>
        <div className={cx(row, quarter)}>
          <div className={field}>
            <label>Refills</label>
            <Field
              name="refills"
              type="text"
              className="form-field"
              placeholder="Enter a refills quantity"
              value={values.refills}
            />
            {
              errors.refills && touched.refills &&
              <div>{errors.refills}</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

DispenseInstructions.propTypes = {
  values: PropTypes.shape({
    totalQuantity: PropTypes.number.isRequired,
    refills:       PropTypes.string.isRequired
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur:   PropTypes.func,
  values:       PropTypes.object.isRequired,
  errors:       PropTypes.object.isRequired,
  touched:      PropTypes.object.isRequired
};

export { DispenseInstructions };
