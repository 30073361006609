import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Toggle from '../../../components/toggle/toggle';
import { SWITCHER } from '../../constants';
import InfoTooltip from '../info-tooltip';

const AcceptingNewPatientsSwitcher = ({ toggle, position }) => {
  const data = toggle.toggleData;
  const setData = toggle.updateToggleValue;
  const message = data.tooltipMessage;
  const turnOff = message && message.length > 0;
  return (
    <div
      className={classNames('accepting-new-patients-swticher', { revert: true })}
      data-testid="accepting-new-patients-swticher"
    >
      <div className="labels">
        <span className="top-label">{SWITCHER.accepting_new_patients}</span>
        <span className="bottom-label">{SWITCHER.up_to_overall_limit}</span>
        {turnOff && <InfoTooltip messagesToDisplay={message} position={position} />}
      </div>
      <Toggle
        checked={data.acceptingNewPatients}
        onChange={setData}
        disableToggle={turnOff}
      />
    </div>
  );
};

AcceptingNewPatientsSwitcher.propTypes = {
  toggle: PropTypes.shape({
    toggleData: PropTypes.shape({
      acceptingNewPatients:      PropTypes.bool,
      tooltipMessage:            PropTypes.array,
    }),
    updateToggleValue: PropTypes.func
  }),
  position: PropTypes.string
};

export default AcceptingNewPatientsSwitcher;
