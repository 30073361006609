import React from 'react';
import PropTypes from 'prop-types';

const PharmacyListItem = ({
  name,
  addressLine1,
  addressCity,
  addressState,
  phone,
  onClickHandler
}) => (
  <tr>
    <td>{name}</td>
    <td>{addressLine1}</td>
    <td>{addressCity}</td>
    <td>{addressState}</td>
    <td>{phone}</td>
    <td><a href="" onClick={e => onClickHandler(name, e)}>Select</a></td>
  </tr>
);

PharmacyListItem.propTypes = {
  name: PropTypes.string.isRequired,
  addressLine1: PropTypes.string.isRequired,
  addressCity: PropTypes.string.isRequired,
  addressState: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired
};

export default PharmacyListItem;
