export const canUsePictureInPicture = () => 'documentPictureInPicture' in window;

export const createPipWindow = async (width, height, options = {}) => {
  if (!canUsePictureInPicture()) return null;

  const pipWindow = await window.documentPictureInPicture.requestWindow({
    width,
    height,
    ...options
  });

  [...document.styleSheets].forEach((styleSheet) => {
    try {
      const cssRules = [...styleSheet.cssRules].map((rule) => rule.cssText).join('');
      const style = document.createElement('style');

      style.textContent = cssRules;
      pipWindow.document.head.appendChild(style);
    } catch (e) {
      const link = document.createElement('link');

      link.rel = 'stylesheet';
      link.type = styleSheet.type;
      link.media = styleSheet.media;
      link.href = styleSheet.href;
      pipWindow.document.head.appendChild(link);
    }
  });

  return pipWindow;
}

const tdPipWindow = {
  canUsePictureInPicture,
  createPipWindow
}

export default tdPipWindow;
