import { style } from 'typestyle';

const radioAnswerStyle = {
  answerContainer: style({
    display:             'grid !important',
    padding:             '0',
    gridTemplateColumns: '1fr 2.1fr',
    $nest:               {
      li: {
        '&:nth-last-child(1)': {
          gridRowStart:    '1',
          justifySelf:     'start',
          gridColumnStart: '2'
        }
      }
    }
  }),
  answer: style({
    marginTop:       '1em',
    justifySelf:     'start',
    gridColumnStart: '1'
  })
};

const twoColumnStyle = {
  answerContainer: style({
    display:             'grid',
    padding:             '0px 8px',
    gridTemplateColumns: '1fr 2.1fr',
    $nest:               {
      div: {
        '&:nth-child(1)': {
          gridRowStart:    '1',
          justifySelf:     'start',
          gridColumnStart: '1'
        }
      }
    }
  }),
  answer: style({
    marginTop:       '1em',
    justifySelf:     'start',
    gridColumnStart: '2'
  })
};

const threeColumnStyle = {
  answerContainer: style({
    display:             'grid',
    padding:             '0px 8px',
    gridTemplateColumns: '1fr 1fr 1.1fr',
    $nest:               {
      div: {
        '&:nth-child(1)': {
          gridRowStart:    '1',
          justifySelf:     'start',
          gridColumnStart: '1'
        },
        '&:nth-last-child(1)': {
          justifySelf: 'start',
          gridArea:    '1 / 3 / 10 / 3'
        }
      }
    }
  }),
  answer: style({
    marginTop:       '1em',
    justifySelf:     'start',
    gridColumnStart: '2'
  })
};

const questionStyle = style({
  paddingTop:    '0px',
  paddingBottom: '12px',
  $nest:         {
    p: {
      background: '#e5f4fa',
      color:      '#244861',
      padding:    '4px 8px',
      fontWeight: 'bold'
    }
  }
});

export const textFieldAnswerStyle = gridColumnStart =>
  style({
    marginTop:   '1em',
    justifySelf: 'start',
    gridColumnStart,
    $nest:       {
      div: {
        width: '230px',
        $nest: {
          input: {
            width:   '100%',
            padding: '0.5em'
          },
          p: {
            background: 'initial',
            color:      '#7C7D74',
            fontWeight: 'initial',
            padding:    0,
            marginTop:  '1em'
          }
        }
      }
    }
  });

export const textFieldRadioAnswerStyle = gridColumnStart =>
  style({
    justifySelf: 'start',
    position:    'absolute',
    gridColumnStart,
    $nest:       {
      div: {
        width: '460px',
        $nest: {
          input: {
            marginTop: '1em',
            width:     '100%',
            padding:   '0.5em'
          },
          p: {
            background: 'initial',
            color:      '#7C7D74',
            fontWeight: 'initial',
            padding:    0,
            marginTop:  '1em'
          }
        }
      }
    }
  });

export const safetyPlanStyle = style({
  background:    '#fff',
  padding:       '1em',
  paddingBottom: '0px',
  width:         '250px',
  position:      'absolute',
  right:         '0',
  top:           '25px',
  $nest:         {
    h4: {
      color: '#7C7D74'
    },
    textarea: {
      height:       '150px !important',
      resize:       'none',
      marginBottom: '0.5em !important'
    }
  }
});

export const questionCountStyle = style({
  position:   'absolute',
  top:        '6px',
  right:      '10px',
  color:      '#fff',
  fontWeight: 'bold'
});

export const validationLabelStyle = {
  required: style({
    color: 'red !important'
  }),
  normal: style({
    color: 'inherit !important'
  })
};

export const surveyStyles = {
  questionStyle,
  SECTIONMNEMONIC_MENTALSTATUSEXAM_GENERAL: {
    Q_1_1: {
      ...threeColumnStyle
    },
    Q_1_2: {
      ...threeColumnStyle
    },
    Q_1_3: {
      ...threeColumnStyle
    },
    Q_1_4: {
      ...threeColumnStyle
    },
    Q_1_5: {
      ...threeColumnStyle
    }
  },
  SECTIONMNEMONIC_MENTALSTATUSEXAM_COGNIT: {
    Q_2_1: {
      ...twoColumnStyle
    },
    Q_2_2: {
      ...threeColumnStyle
    },
    Q_2_3: {
      ...threeColumnStyle
    }
  },
  SECTIONMNEMONIC_MENTALSTATUSEXAM_THOUGHT: {
    Q_3_1: {
      ...threeColumnStyle
    },
    Q_3_2: {
      ...threeColumnStyle
    },
    Q_3_3: {
      ...threeColumnStyle
    },
    Q_3_4: {
      ...threeColumnStyle
    },
    Q_3_5: {
      ...radioAnswerStyle
    },
    Q_3_6: {
      ...radioAnswerStyle
    }
  }
};
