exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1A2tI2wyUWXssVidhaxZ3l input[type=\"text\"] {\n  width: 100%;\n  min-width: 200px;\n  padding: 8px !important;\n  border-radius: 0px;\n  height: 33.5px; }\n  ._1A2tI2wyUWXssVidhaxZ3l input[type=\"text\"]:disabled {\n    background-color: #e8e8e8;\n    background-image: none; }\n", ""]);

// exports
exports.locals = {
	"surveyAnswerNote": "_1A2tI2wyUWXssVidhaxZ3l",
	"surveyAnswerNote": "_1A2tI2wyUWXssVidhaxZ3l"
};