import React, { useEffect } from 'react';
import { NON_CANCELLABLE_STATUSES } from '../../constants';

export const useCancelConsultButtonRemover = ({ callStatus }) => {
  // Quick implementation to hide the cancel consult button (at the top of consultation page),
  // since it will be difficult to communicate the current call status to where that button is rendered
  useEffect(() => {
    const cancelConsultButton = document.getElementById('cancel_consult');
    if (!cancelConsultButton) return;

    if (NON_CANCELLABLE_STATUSES.includes(callStatus)) {
      cancelConsultButton.classList.add('auto-dialer-hide');
    } else {
      cancelConsultButton.classList.remove('auto-dialer-hide');
    }
  }, [callStatus]);
};
