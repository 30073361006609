import classNames from 'classnames';
import React from 'react';
import closeIcon from '../../../assets/images/closeIcon.svg';
import PropTypes from 'prop-types';

const PatientLimitConfigureNotification = ({ showNotification, setShowNotification, successMsg, message }) => {
  const handleClose = () => {
    setShowNotification(false);
  };

  const successNotificationBannerWrapper = classNames({
    successNotificationBannerWrapper: !successMsg
  });

  return (
    showNotification && (
      <div className={`patientLimitNotificationWrapper ${successNotificationBannerWrapper}`}>
        <div className="notificationContainer">
          <div>{message}</div>
        </div>
        <img src={closeIcon} className="close-button" onClick={handleClose} />
      </div>
    )
  );
};

PatientLimitConfigureNotification.propTypes = {
  showNotification:    PropTypes.bool.isRequired,
  setShowNotification: PropTypes.func.isRequired,
  successMsg:          PropTypes.bool,
  message:             PropTypes.string
};

export default PatientLimitConfigureNotification;
