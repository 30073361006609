import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { controls } from '../utils';
import CallComplete from './CallComplete';
import CallInProgress from './CallInProgress';
import DialingMember from './DialingMember';
import DialingProvider from './DialingProvider';
import JoinViaPhone from './JoinViaPhone';
import StartCall from './StartCall';
import UnavailableMember from './UnavailableMember';
import LoadingIndicator from 'app/components/loading-indicator/loading-indicator';
import {
  ACTIVE_CONFERENCE_STATUSES,
  CONFERENCE_STATUSES,
  DISCONNECTED_STATUSES,
  VIEW_STATES,
  ATTENDEE_STATUSES
} from '../constants/call-status';
import { get } from 'lodash';
import BusyMember from './BusyMember';

const WebRTC = ({
  consultation,
  viewState,
  device,
  setCallStatus,
  setShouldPoll,
  activeConferenceRS,
  setDevice,
  memberCallData,
  providerCallData,
  callStatus,
  isMicrophoneAllowed,
  hasActiveCall,
  setHasActiveCall,
  callStatusInterval,
  isNotDevelopment,
  actionAutoCallChecker,
  setActionAutoCallChecker,
  onCallJoin,
  onEndCall
}) => {
  const [isMuted, setIsMuted] = useState(false);

  const { consultationId, member, provider } = consultation;
  const primaryProviderPhoneNumber = provider.phoneNumbers.length ? provider.phoneNumbers[0] : {};
  const secondaryProviderPhoneNumber = provider.phoneNumbers.length > 1 ? provider.phoneNumbers[1] : {};
  const memberName = `${member.firstNm} ${member.lastNm}`;
  const {
    beginCall,
    onCancelClick,
    disconnectCall,
    joinCall,
    callPhone,
    toggleMute,
    restartCall,
    memberUnavailableModal
  } = controls(
    device,
    setDevice,
    consultationId,
    setCallStatus,
    provider,
    callStatus,
    isMuted,
    setIsMuted,
    callStatusInterval,
    setShouldPoll,
    setHasActiveCall
  );

  const handleEndCall = () => {
    disconnectCall();

    if (onEndCall) onEndCall();
  };

  const handleJoinCall = () => {
    joinCall();

    if (onCallJoin) onCallJoin();
  };

  // Autostart call functionality.
  useEffect(() => {
    const shouldAutostartCall = !!device && !hasActiveCall && isNotDevelopment && actionAutoCallChecker;

    if (shouldAutostartCall) {
      beginCall();
      setActionAutoCallChecker(false);
    }
  }, [device, hasActiveCall, isNotDevelopment, actionAutoCallChecker]);

  useEffect(() => {
    if (viewState === VIEW_STATES.UNAVAILABLE) {
      memberUnavailableModal.show();
    }
  }, [viewState]);

  const printState = objToPrint =>
    Object.entries(objToPrint).map(entry => <div key={entry[0]}>{`${entry[0]}: ${entry[1]}`}</div>);

  return (
    <div className="auto-dialer-container">
      {process.env.NODE_ENV !== 'production' && (
        <div>
          <div>{`VIEW STATE: ${viewState}\n\n`}</div>
          <div>{`CALL STATE: ${callStatus}\n\n`}</div>
          <div>
            {' \n\nMEMBER STATUS:\n'}
            {printState(memberCallData)}
          </div>
          <div>
            {' \n\nPROVIDER STATUS:\n'}
            {printState(providerCallData)}
          </div>
        </div>
      )}
      {viewState === VIEW_STATES.UNKNOWN && (
        <div>
          <LoadingIndicator />
          <p>Loading...</p>
        </div>
      )}
      {viewState === VIEW_STATES.START_CALL && (
        <StartCall
          isReady={!!device && !hasActiveCall}
          onClick={beginCall}
          autostartCallActive={isNotDevelopment && actionAutoCallChecker}
        />
      )}
      {viewState === VIEW_STATES.DIALING_MEMBER && (
        <DialingMember
          primaryDialCount={memberCallData.primaryDialCount}
          secondaryDialCount={memberCallData.secondaryDialCount}
        />
      )}

      {viewState === VIEW_STATES.MEMBER_BUSY && (
        <BusyMember
          onCancelClick={onCancelClick}
          retryCall={() => {
            handleEndCall();
            setIsMuted(false);
            setCallStatus(CONFERENCE_STATUSES.READY_TO_START);
          }}
        />
      )}

      {viewState === VIEW_STATES.UNAVAILABLE && <UnavailableMember onCancelClick={onCancelClick} />}

      {viewState === VIEW_STATES.DIALING_PROVIDER && <DialingProvider memberName={memberName} />}

      {(viewState === VIEW_STATES.CALL_IN_PROGRESS || viewState === VIEW_STATES.PROVIDER_REJOIN) && (
        <CallInProgress
          callStatus={callStatus}
          connectedViaPhone={
            !!providerCallData.joinedViaPhoneDate && providerCallData.currentStatusCode == ATTENDEE_STATUSES.JOINED
          }
          handleEndCall={handleEndCall}
          handleCallPhone={callPhone}
          handleJoinCall={handleJoinCall}
          isMicrophoneAllowed={isMicrophoneAllowed}
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          memberCallStatus={memberCallData.currentStatusCode}
          memberName={memberName}
          providerCallStatus={providerCallData.currentStatusCode}
          primaryPhone={primaryProviderPhoneNumber.formattedNationalNumber}
          secondaryPhone={secondaryProviderPhoneNumber.formattedNationalNumber}
          setCallStatus={setCallStatus}
          toggleMute={toggleMute}
          onCallJoin={onCallJoin}
        />
      )}
      {viewState === VIEW_STATES.CALL_COMPLETE && <CallComplete callStatus={callStatus} onClick={restartCall} />}
      {viewState === VIEW_STATES.JOIN_VIA_PHONE && (
        <JoinViaPhone primaryPhone={consultation.phoneNumber} secondaryPhone={consultation.alternatePhoneNumber} />
      )}
    </div>
  );
};
// Consultation obj shape converted exhaustively in AutoDialerInitializer.js
WebRTC.propTypes = {};

WebRTC.displayName = 'WebRTC';

export default WebRTC;
