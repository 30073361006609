import React from 'react';

import render from '../../../../app/lib/render';
import QuestionCount from './components/question-count';
import { textFieldAnswerStyle, textFieldRadioAnswerStyle, validationLabelStyle } from './styles';
import SurveyTextfield from './containers/survey-textfield-container';
import { updateMentalStatusExamInputAction } from './redux/actions';
import { translate } from '@td/shared_utils';
import { ANSWER_NAME, TEXT_FIELD_NAME, VALUE_NAME } from './constants';
import { hydrate } from 'react-dom';

export const section = (sectionConfig, content) => (
  <div key={sectionConfig.name} className={'ct react_section'}>
    <h3>{sectionConfig.name}</h3>
    <QuestionCount count={sectionConfig.data.questions.length} />
    <div className="ehr_content">{content}</div>
  </div>
);

// This is passed as a callback function to the Survey component. It is passed only to the "Other" labelled checkboxes
// and depending if they are checked or not we display a textfield below them in a designated container by rendering a react component.
export const addTextField = (answerVal, answerCode, questionCode) => {
  const answerClass = `${questionCode}_TEXT`;
  const gridColumnStart = '3';
  const placeholder = translate(null, 'ehr.mh.objective_tab.mental_status_exam_survey.placeholders', 'required');
  const name = TEXT_FIELD_NAME;

  const textFieldClass = `${answerClass}_custom`;
  const answerContainer = $(`.mental-status-exam .${answerClass}`);

  const isChecked = answerVal[answerCode];
  if (isChecked) {
    const textFieldContainer = answerContainer.clone();
    textFieldContainer.removeClass();
    textFieldContainer.toggleClass(textFieldClass);
    textFieldContainer.toggleClass(textFieldAnswerStyle(gridColumnStart));

    answerContainer.append(textFieldContainer);
    render(SurveyTextfield, `.${textFieldClass}`, {
      placeholder,
      name,
      questionCode,
      isRequired: true,
      minChars:   5,
      styles:     { validationLabelStyle }
    });
  } else {
    window.td.store.dispatch(updateMentalStatusExamInputAction(questionCode, ''));
    $(`.${textFieldClass}`).remove();
  }
};

export const addTextFieldForRadio = () => {
  const questionCodes = ['Q_3_5', 'Q_3_6'];

  questionCodes.forEach(questionCode => {
    const answer = `${questionCode}_TEXT`;
    const answerSelector = `.mental-status-exam .${answer}`;
    const textFieldContainerClass = `${answer}_custom`;
    const textFieldContainer = $('<div/>')
      .addClass(textFieldContainerClass)
      .addClass(textFieldRadioAnswerStyle(2));
    $(answerSelector).html(textFieldContainer);

    const checkboxConfig = {
      placeholder:  translate(null, 'ehr.mh.objective_tab.mental_status_exam_survey.placeholders', 'optional'),
      name:         TEXT_FIELD_NAME,
      label:        translate(null, 'ehr.mh.objective_tab.mental_status_exam_survey.comments', 'label'),
      hiddenFields: [
        {
          name:  ANSWER_NAME,
          value: 'TEXT'
        },
        {
          name:  VALUE_NAME,
          value: 'Y'
        }
      ],
      questionCode
    };

    render(SurveyTextfield, `.${textFieldContainerClass}`, checkboxConfig);
  });
};

export const expandSections = () => {
  const sectionsSelector = '.mental-status-exam .react_section';
  $(sectionsSelector).toggleClass('expanded');
  $(`${sectionsSelector} .ehr_content`).css('display', 'block');
};

export const disableCheckboxes = (answerVal, answerCode, questionCode) => {
  // Disabling based on the checkbox selection from this list.
  const noneAnswerCodes = ['NONE', 'NORMAL', 'DENYALL', 'LOGICAL', 'FULL'];
  const isChecked = answerVal[answerCode];
  const checkedBox = $(`.${questionCode}_${answerCode}`);
  const allCheckboxes = checkedBox.siblings().andSelf();

  // Find the checked boxes with answer codes other than the above.
  const checkboxesWithDifferentAnswerCodes = allCheckboxes
    .find(
      `:not(.${questionCode}_NONE,.${questionCode}_NORMAL,.${questionCode}_DENYALL,.${questionCode}_LOGICAL,.${questionCode}_FULL)`
    )
    .is(':checked');

  if (isChecked) {
    if (noneAnswerCodes.indexOf(answerCode) !== -1) {
      const allSiblingsExceptOther = checkedBox.siblings(`:not(.${questionCode}_TEXT)`);
      allSiblingsExceptOther.find('input:checkbox').attr('disabled', 'disabled');
    } else {
      allCheckboxes
        .first()
        .find('input:checkbox')
        .attr('disabled', 'disabled');
    }
  } else if (!checkboxesWithDifferentAnswerCodes) {
    allCheckboxes.find('input:checkbox').removeAttr('disabled');
  }
};
