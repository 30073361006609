import { connect } from 'react-redux';

import
  getGeneralInformationNote
from '../../reducers/selectors/system-generated-note/getGeneralInformationNote';
import getSymptomsNote from '../../reducers/selectors/system-generated-note/getSymptomsNote';
import SystemGeneratedNote from './system-generated-note';
import { getTravelMedicationSurveyNote } from '../../sections/travel-medication-survey/selectors';

const mapStateToProps = (state, ownProps) => ({
  generalInformationNote: getGeneralInformationNote(state, ownProps),
  symptomsNote: getSymptomsNote(state),
  travelMedicationSurveyNote: getTravelMedicationSurveyNote(state)
});

export default connect(mapStateToProps)(SystemGeneratedNote);
