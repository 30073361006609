import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

// TODO: This element should be revisited in the scope of the Banners story
// https://jira.teladoc.net/browse/P360-2272
const ErrorNotification = ({ text, className }) => (
  <div className={cx(styles.errorNotification, className)}>
    {text}
  </div>
);

ErrorNotification.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default ErrorNotification;
