exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".kq2TYXpu72RMq16_bt2mN {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 12px 0; }\n  .kq2TYXpu72RMq16_bt2mN ._2oF0q4NxoFwntiRZfgA94Z {\n    margin-right: 16px; }\n  .kq2TYXpu72RMq16_bt2mN ._34uBQtSKZdTU4rGuw60cZN {\n    color: #7c7d74;\n    display: inline;\n    margin-bottom: 3px; }\n\n._1VUQazvnVg5HFdVgnh_ejd {\n  display: flex;\n  align-content: flex-start; }\n  ._1VUQazvnVg5HFdVgnh_ejd ._3sx3AtbftQMS4rXXuKhGmt {\n    display: flex;\n    background: none;\n    border: none;\n    padding: 0;\n    margin-left: 16px; }\n\n._2WUDV07nUScIp_UJYVdQHY {\n  display: flex;\n  justify-content: start; }\n  ._2WUDV07nUScIp_UJYVdQHY ._8QuWu36Xe7DsIfJThFHXl {\n    width: 14px;\n    height: 14px;\n    margin: 2px;\n    border-radius: 50%;\n    animation: _8QuWu36Xe7DsIfJThFHXl 1.1s infinite linear;\n    border-bottom: 1px solid #F9A101;\n    border-left: 1px solid #F9A101;\n    border-right: 1px solid #F9A101;\n    border-top: 1px solid #e5f4f9; }\n  ._2WUDV07nUScIp_UJYVdQHY ._2nVG338e88Sis2EUI5Yyls {\n    color: #F9A101;\n    margin: auto 4px; }\n\n._1VUQazvnVg5HFdVgnh_ejd {\n  display: flex;\n  align-content: flex-start; }\n  ._1VUQazvnVg5HFdVgnh_ejd ._3sx3AtbftQMS4rXXuKhGmt {\n    display: flex;\n    background: none;\n    border: none;\n    padding: 0;\n    margin-left: 16px; }\n\n._1M7qamSCMieeIpwm_VLdmV {\n  padding: 7px 13px !important;\n  min-width: 72px; }\n\n._1Cv4GMvWZ3DpyVsNzp8Bou {\n  color: #D0021C;\n  display: flex;\n  align-items: center;\n  gap: 0.2rem;\n  margin-top: 0.2rem; }\n\n@keyframes _8QuWu36Xe7DsIfJThFHXl {\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(360deg); } }\n", ""]);

// exports
exports.locals = {
	"header": "kq2TYXpu72RMq16_bt2mN",
	"header": "kq2TYXpu72RMq16_bt2mN",
	"externalCalendarIcon": "_2oF0q4NxoFwntiRZfgA94Z",
	"externalCalendarIcon": "_2oF0q4NxoFwntiRZfgA94Z",
	"calendar": "_34uBQtSKZdTU4rGuw60cZN",
	"calendar": "_34uBQtSKZdTU4rGuw60cZN",
	"accountActions": "_1VUQazvnVg5HFdVgnh_ejd",
	"accountActions": "_1VUQazvnVg5HFdVgnh_ejd",
	"button": "_3sx3AtbftQMS4rXXuKhGmt",
	"button": "_3sx3AtbftQMS4rXXuKhGmt",
	"synchronizing": "_2WUDV07nUScIp_UJYVdQHY",
	"synchronizing": "_2WUDV07nUScIp_UJYVdQHY",
	"loader": "_8QuWu36Xe7DsIfJThFHXl",
	"loader": "_8QuWu36Xe7DsIfJThFHXl",
	"syncMessage": "_2nVG338e88Sis2EUI5Yyls",
	"syncMessage": "_2nVG338e88Sis2EUI5Yyls",
	"reloginButton": "_1M7qamSCMieeIpwm_VLdmV",
	"reloginButton": "_1M7qamSCMieeIpwm_VLdmV",
	"expirationNote": "_1Cv4GMvWZ3DpyVsNzp8Bou",
	"expirationNote": "_1Cv4GMvWZ3DpyVsNzp8Bou"
};