import { createSelector } from '@td/utils';

import getAddedSymptomIds from './getAddedSymptomIds';
import getAddedSymptomsFromSearchSet from './getAddedSymptomsFromSearchSet';

export default createSelector(
  getAddedSymptomIds,
  getAddedSymptomsFromSearchSet,
  (addedSymptomIds, addedSymptomFromSearchSet) =>
    addedSymptomIds.filter(symptomId => addedSymptomFromSearchSet.has(symptomId))
);
