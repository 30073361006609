import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import EXTERNAL_ACCOUNTS_AUTH_URL_MUTATION from '../mutations/external_accounts_auth_url.graphql';

export default () => {
  const [authUrl, setAuthUrl] = useState(null);
  const [createAccountAuthUrl] = useMutation(EXTERNAL_ACCOUNTS_AUTH_URL_MUTATION, {
    onCompleted: ({ externalCalendarsCreateLoginUrl: { url } }) => setAuthUrl(url),
    onError: (e) => console.error('Error: ', e)
  });

  useEffect(() => {
    createAccountAuthUrl();
  }, []);

  return authUrl;
};

