import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toLower from 'lodash/toLower';

import Autosuggest from 'react-autosuggest';

import ISymptom from '../../interfaces/symptom-interface';

class AddSymptom extends Component {
  state = {
    filteredSymptoms: this.props.symptoms,
    focus: null
  };

  onChange = (event, { newValue }) => {
    this.props.onInputChange(newValue);
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.addSymptom(suggestion.code);
  };

  caseInsensitiveContains = (text, value) => {
    return toLower(text).indexOf(toLower(value)) > -1;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      filteredSymptoms: this.props.symptoms.filter(symptom => this.caseInsensitiveContains(symptom.name, value))
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ filteredSymptoms: [] });
  };

  render() {
    return (
      <div className="add-symptom">
        <h4>
          <label className="label" htmlFor="add-symptom">
            Add a Symptom:  (required)
          </label>
        </h4>
        <div className="add-symptom-input">
          <div className="add-symptom-input-field">
            <Autosuggest
              suggestions={this.state.filteredSymptoms}
              getSuggestionValue={symptom => symptom.name}
              renderSuggestion={symptom => symptom.name}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={this.onSuggestionSelected}
              shouldRenderSuggestions={() => true}
              inputProps={{
                placeholder: '',
                id:          'add-symptom',
                name:        'add-symptom',
                value:       this.props.searchValue,
                onChange:    this.onChange,
                onFocus: ()=>{this.setState({...this.state, focus: true})},
                onBlur: ()=>{this.setState({...this.state, focus: false})}
              }}
            />
          </div>
          {this.props.searchValue !== '' && this.state.focus === false ? <div className="associated_symptoms_inline_validation">Please select a valid symptom.</div> : null }
        </div>
      </div>
    );
  }
}

AddSymptom.propTypes = {
  symptoms:      PropTypes.arrayOf(ISymptom).isRequired,
  searchValue:   PropTypes.string,
  addSymptom:    PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired
};

export default AddSymptom;
