import React from 'react';
import PropTypes from 'prop-types';
import ActionsContainer from '../ActionsContainer';
import RescheduleAction from '../actions/RescheduleAction';
import ViewProposalAction from '../actions/ViewProposalAction';

const ProviderProposedFlowNegotiation = (props) => {
  return (
    <ActionsContainer>
      {[
        <RescheduleAction key="proposed-reschedule" {...props} />,
        <ViewProposalAction key="proposed-proposed" {...props} />
      ]}
    </ActionsContainer>
  );
};

ProviderProposedFlowNegotiation.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default ProviderProposedFlowNegotiation;
