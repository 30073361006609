import { FETCH_SYMPTOMS, FETCH_SYMPTOM_CLASSES } from '../action-types';

export const DEFAULT_STATE = {
  allSymptoms:        [],
  symptomsById:       {},
  allSymptomClasses:  [],
  symptomClassesById: {}
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_SYMPTOMS.SUCCESS: {
      return {
        ...state,
        allSymptoms:  action.payload.allSymptoms,
        symptomsById: action.payload.symptomsById
      };
    }
    case FETCH_SYMPTOM_CLASSES.SUCCESS: {
      return {
        ...state,
        allSymptomClasses:  action.payload.allSymptomClasses,
        symptomClassesById: action.payload.symptomClassesById
      };
    }
    default: {
      return state;
    }
  }
};
