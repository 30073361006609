import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'app/components/Loader';
import SameDayVisitsSectionMessage from '../../my-account/same-day-visits/components/SameDayVisitsSectionMessage';
import useSameDayVisitsToggle from '../../my-account/same-day-visits/hooks/useSameDayVisitsToggle';

const SidebarSameDayVisits = ({ providerId }) => {
  const { isAcceptingSameDayVisits, isDisabled, isLoading, error } = useSameDayVisitsToggle(providerId);

  return (
    <div className="module">
      <h1>{I18n.t('my_account.same_day_visits_section.title')}</h1>
      <div className="section-column">
        {isLoading ? (
          <div className="loading-container">
            <Loader />
          </div>
        ) : (
          <div className="section-column align-start">
            {error ? (
              <p className="error-message">{I18n.t('my_schedule.mutations.error')}</p>
            ) : (
              <SameDayVisitsSectionMessage
                isDisabled={isDisabled}
                isAcceptingSameDayVisits={isAcceptingSameDayVisits}
              />
            )}
            {!isDisabled && (
              <a className="button primary" href="/my_account?edit=true#same_day_visits_section">
                {I18n.t('my_account.same_day_visits_section.edit')}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

SidebarSameDayVisits.propTypes = {
  providerId: PropTypes.number.isRequired
};

export default SidebarSameDayVisits;
