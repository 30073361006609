import React, { useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import closeIcon from '../../assets/images/closeIcon.svg';

const Banner = ({ message, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    onClose();
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="notificationBannerWrapper">
      <div className="notificationContainer">
        {message.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
      <img src={closeIcon} className="close-button" onClick={handleClose} />
    </div>
  );
};

Banner.propTypes = {
  message: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Banner;
