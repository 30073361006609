import React from 'react';
import PropTypes from 'prop-types';

import SymptomsList from './symptoms-list';
import ISymptom from '../../interfaces/symptom-interface';

const AssociatedSymptoms = ({ symptoms }) => (
  <SymptomsList symptoms={symptoms} listId={'Associated'} />
);

AssociatedSymptoms.propTypes = {
  symptoms: PropTypes.arrayOf(ISymptom).isRequired
};

export default AssociatedSymptoms;
