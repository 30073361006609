import React from 'react';
import PropTypes from 'prop-types';

// Styles
import '../styles/notification.scss';

const Notification = ({ type, message, warnings = [] }) => {
  const className = `notificationWrapper ${type}-type`;
  return (
    <div className={className}>
      {typeof message === 'string' || message instanceof String ? message : JSON.stringify(message)}
      {warnings.length > 0 && (
        <ul className="warnings">
          {warnings.map(warning => <li key={warning.replace(/\s/g, '')}>{warning}</li>)}
        </ul>
      )}
    </div>
  );
};

Notification.propTypes = {
  type:     PropTypes.string.isRequired,
  message:  PropTypes.string.isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string)
};

export default Notification;
