/* eslint-disable max-len */
import React, { useState } from 'react';
import { I18n, translateMarkupString } from '@td/shared_utils';
import AutoDialerModal from './modals/AutoDialerModal';
import { MODAL_TYPES } from '../constants/modal-types';
import { AutoDialerV2 as api } from '@teladoc/auto_dialer';
import { specialtyNames } from '../../../constants';

import { useAutoDialer } from '../utils';

const AutoDialerFooter = () => {
  const {
    consultation: { consultationId, phoneNumber: primaryPhone, alternatePhoneNumber: secondaryPhone, specialtyName },
    hidePhoneNumber, memberCallData
  } = useAutoDialer();

  const [showMemberPhoneForAlexa, setShowMemberPhoneForAlexa] = useState(false);
  const [alexaMessageWasShown, setAlexaMessageWasShown] = useState(false);
  const [showMemberPhone, setShowMemberPhone] = useState(false);
  const [memberPhoneWasShown, setMemberPhoneWasShown] = useState(false);
  const [modalType, setModalType] = useState('');
  const handleClose = () => setModalType('');

  const toggleShowMemberPhoneFunc = () => (hidePhoneNumber ? toggleShowMemberPhoneForAlexa() : toggleShowMemberPhone());

  const toggleShowMemberPhone = () => {
    const showPhone = !showMemberPhone;

    setShowMemberPhone(showPhone);

    // Send reporting data only when member
    // number are shown and was never shown before
    if (showPhone && !memberPhoneWasShown) {
      setMemberPhoneWasShown(true);
      api.updateMemberNumberShown(consultationId);
    }
  };

  const toggleShowMemberPhoneForAlexa = () => {
    const showPhone = !showMemberPhoneForAlexa;

    setShowMemberPhoneForAlexa(showPhone);

    if (!showPhone) setShowMemberPhone(false);

    if (showPhone && !alexaMessageWasShown) {
      setAlexaMessageWasShown(true);
      api.updateAlexaMessageShown(consultationId);
    }
  };

  const mhHidePhoneNumberFeatureToggle = Boolean(window.FEATURE_TOGGLES.mh_auto_dialer_hide_member_phone_number)

  const toggleAutoDialerTechIssues = (mhHidePhoneNumberFeatureToggle === false) ||
  (mhHidePhoneNumberFeatureToggle && specialtyName !== specialtyNames.MENTAL_HEALTH) ||
  (mhHidePhoneNumberFeatureToggle && specialtyName === specialtyNames.MENTAL_HEALTH && memberCallData.totalDialCount > 0)

  return (
    <React.Fragment>
      <div className="auto-dialer-footer">
        <div className="auto-dialer-footer-help-container">
          <span className="auto-dialer-link" id="audio-test-link" onClick={() => setModalType(MODAL_TYPES.AUDIO_TEST)}>
            <I18n scope="autodialer.call_interface.footer" text="test_audio_cta" />
          </span>

          <span className="auto-dialer-link-divider">|</span>

          <span className="auto-dialer-link" id="audio-faq-link">
            <a
              href="https://s3.amazonaws.com/communications.teladoc.com/resources/video_support/Provider_Audio_FAQ_Document.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              <I18n scope="autodialer.call_interface.footer" text="faq" />
            </a>
          </span>
        </div>
        {toggleAutoDialerTechIssues && (
          <div className="auto-dialer-tech-issues">
            {translateMarkupString('autodialer.call_interface.fallbacks', 'troubleshooting_html', { b: <b /> })}

            {
              <div className="auto-dialer-link auto-dialer-show-member-phone" onClick={toggleShowMemberPhoneFunc}>
                {showMemberPhone || showMemberPhoneForAlexa ? (
                  <I18n scope="autodialer.call_interface.fallbacks" text="hide_member_number" />
                ) : (
                  <I18n scope="autodialer.call_interface.fallbacks" text="show_member_number" />
                )}
              </div>
            }

            {showMemberPhoneForAlexa && (
              <div>
                <p>{translateMarkupString('autodialer.call_interface.fallbacks.alexa', 'message', { b: <b /> })}</p>
                <br />
                <p>
                  <I18n scope="autodialer.call_interface.fallbacks.alexa" text="show_member_number_pre" />
                  <div className="auto-dialer-link auto-dialer-show-member-phone" onClick={toggleShowMemberPhone}>
                    <I18n scope="autodialer.call_interface.fallbacks.alexa" text="show_member_number_link" />
                  </div>
                </p>
              </div>
            )}

            {showMemberPhone && (
              <div className="auto-dialer-tech-issues-phone-numbers">
                {primaryPhone && (
                  <p>
                    <b>
                      <I18n scope="autodialer.call_interface.fallbacks" text="primary_phone" />
                    </b>
                    : {primaryPhone}
                  </p>
                )}
                {secondaryPhone && (
                  <p>
                    <b>
                      <I18n scope="autodialer.call_interface.fallbacks" text="secondary_phone" />
                    </b>
                    : {secondaryPhone}
                  </p>
                )}
                {!primaryPhone && !secondaryPhone && (
                  <p>
                    <I18n scope="misc" text="none" />
                  </p>
                )}
                <p>
                  <I18n scope="autodialer.call_interface.fallbacks" text="star_67_reminder" />
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      <AutoDialerModal handleClose={handleClose} modalType={modalType} />
    </React.Fragment>
  );
};

AutoDialerFooter.displayName = 'AutoDialerFooter';

export default AutoDialerFooter;
/* eslint-enable max-len */
