import moment from 'moment';
import { map, zipObject } from 'lodash';
import { DAY_NAMES, VIEW_URI_HASH_INDICATOR } from './constants';

export const computeStartEnd = ({ startDate, startTime, endDate, endTime }) => ({
  start: moment(startDate).set({ hours: moment(startTime).hours(), minutes: moment(startTime).minutes() }),
  end:   moment(endDate).set({ hours: moment(endTime).hours(), minutes: moment(endTime).minutes() })
});

export const checkOverlap = ({ startDateTime, endDateTime }, { start, end }) => {
  const eventStartDateTime = moment(startDateTime);
  const eventEndDateTime = moment(endDateTime);

  const isEqual = eventStartDateTime.isSame(start) && eventEndDateTime.isSame(end);
  const isStartInBetween =
    eventStartDateTime.isBetween(start, end) || start.isBetween(eventStartDateTime, eventEndDateTime);
  const isEndInBetween = eventEndDateTime.isBetween(start, end) || end.isBetween(eventStartDateTime, eventEndDateTime);
  const isDateInBetween = isStartInBetween || isEndInBetween;

  const maxDiff = 15 * 60 * 1000 - 1;
  const exceedsDiff = end.diff(eventStartDateTime) > maxDiff;

  return isEqual || (isDateInBetween && exceedsDiff);
};

export const checkEventsOverlap = (eventsList, selectedEventDate) => {
  const computedSelectedDate = computeStartEnd(selectedEventDate);
  return eventsList.some(event => checkOverlap(event, computedSelectedDate));
};

export const isValidDate = ({ startDate, startTime, endDate, endTime }) => {
  const isDateCorrect = moment(endDate).diff(moment(startDate)) > 0;
  const isTimeCorrect = moment(endTime).diff(moment(startTime)) > 0;
  return isDateCorrect && isTimeCorrect;
};

export const getRecurringDates = event => {
  const { startDateTime, endDateTime, recurrenceRuleEndDt, schedulingTimeSpanDays } = event;

  let startDate = moment(startDateTime);
  const newEvents = [event];

  const recurringEndDate = recurrenceRuleEndDt
    ? moment(recurrenceRuleEndDt)
    : moment()
        .startOf('day')
        .add(schedulingTimeSpanDays, 'days');

  while (startDate < recurringEndDate) {
    const endDate = moment(startDate).set({
      hours:   moment(endDateTime).hours(),
      minutes: moment(endDateTime).minutes()
    });

    const newEvent = {
      ...event,
      startDateTime:       startDate,
      endDateTime:         endDate,
      recurrenceRuleEndDt: recurringEndDate
    };

    newEvents.push(newEvent);
    startDate = moment(startDate).add(1, 'week');
  }
  return newEvents;
};

export const sortByWeekdays = events => {
  const daysInWeekCollection = zipObject(
    DAY_NAMES,
    Array.from(Array(DAY_NAMES.length), () => [])
  );
  map(events, event => {
    const { startDateTime: eventStart, endDateTime: eventEnd } = event;
    const eventDay = DAY_NAMES[moment(eventStart).day()];

    if (
      !daysInWeekCollection[eventDay].some(
        ({ startDateTime, endDateTime }) =>
          (moment(startDateTime).hours() === moment(eventStart).hours() &&
            moment(startDateTime).minutes() === moment(eventStart).minutes()) ||
          (moment(endDateTime).hours() === moment(eventEnd).hours() &&
            moment(endDateTime).minutes() === moment(eventEnd).minutes())
      )
    ) {
      daysInWeekCollection[eventDay].push({
        ...event,
        startDateTime: moment(eventStart),
        endDateTime:   moment(eventEnd)
      });
    }
  });
  Object.keys(daysInWeekCollection).forEach(weekDay => {
    const dayEventsArray = daysInWeekCollection[weekDay];
    dayEventsArray.sort((a, b) => moment(a.startDateTime).hour() - moment(b.startDateTime).hour());
  });
  return daysInWeekCollection;
};

export const dayNameToIndex = dayName => DAY_NAMES.findIndex(day => day.toLowerCase() === dayName.toLowerCase());

export const getNextDay = (date, dayName) => {
  const dayIndex = dayNameToIndex(dayName);
  const nextDay = moment(date).day(dayIndex);
  while (moment() > nextDay) {
    nextDay.add(1, 'week');
  }
  return nextDay;
};

export const separateDatesAndTimes = ({ startDateTime, endDateTime }) => ({
  startDate: moment(startDateTime).toDate(),
  startTime: moment(startDateTime).toDate(),
  endDate:   moment(endDateTime).toDate(),
  endTime:   moment(endDateTime).toDate()
});

export const currentlyActiveEvents = events =>
  events.filter(({ endDateTime }) => moment().isBefore(moment(endDateTime)));

export const isSettingsViewRequested = () => {
  const { hash } = window.location;
  return hash === VIEW_URI_HASH_INDICATOR.regularWorkingHours || hash === VIEW_URI_HASH_INDICATOR.externalCalendars;
};

export const clearExternalCalendarViewHash = () => {
  const { hash, pathname } = window.location;

  if (hash === VIEW_URI_HASH_INDICATOR.externalCalendars) {
    window.history.pushState({}, '', pathname);
  }
};
