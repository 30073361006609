import { some } from 'lodash';
import { translate } from '@td/shared_utils';
import { TRANSLATION_SCOPE, sectionCodes } from '../constants';

const travelMedicationSurveyValidator = ({ travelMedicationSurvey: { survey } }) => ({
  associatedConditionsSurvey: {
    invalid: some(survey[sectionCodes.ASSOCIATED_CONDITIONS], ({ value }) => !value),
    message: translate(null, `${TRANSLATION_SCOPE}.sections.${sectionCodes.ASSOCIATED_CONDITIONS}.errors`, 'required')
  },
});

export default travelMedicationSurveyValidator;
