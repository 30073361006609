import React, { createContext, useContext, useEffect, useState } from 'react';
import { logError } from '@td/shared_utils';

import { AutoDialerV3 } from '@teladoc/auto_dialer';

import getCallStatusRoute from '../call-status';
import { CONFERENCE_STATUSES } from '../../constants';

import { useNotification } from '..';

const AutoDialerContext = createContext(null);

export const AutoDialerProvider = props => {
  const { AutoDialerProvider: Provider } = AutoDialerV3;

  return (
    <Provider {...props}>
      <AutoDialerProviderWrapped {...props}>{props.children}</AutoDialerProviderWrapped>
    </Provider>
  );
};

export const AutoDialerProviderWrapped = ({ children }) => {
  const [viewState, setViewState] = useState(CONFERENCE_STATUSES.READY_TO_START);

  const autoDialer = AutoDialerV3.useAutoDialer();
  const { memberCallData, currentAttendeeCallData, callStatus, setCallStatus, interpreterCallData } = autoDialer;

  useNotification({ memberCallData });

  useEffect(() => {
    // Temporary fix, should consider how to avoid this status properly
    if (callStatus !== CONFERENCE_STATUSES.WEBRTC_DEVICE_ERROR) {
      const newViewState = getCallStatusRoute(
        callStatus,
        memberCallData.currentStatusCode,
        memberCallData.primaryDialCount,
        currentAttendeeCallData.currentStatusCode,
        interpreterCallData.currentStatusCode
      );

      setViewState(newViewState);
    }
  });

  return (
    <AutoDialerContext.Provider
      value={{
        viewState,
        callStatus,
        setCallStatus,
        setViewState,
        ...autoDialer
      }}
    >
      {children}
    </AutoDialerContext.Provider>
  );
};

AutoDialerProvider.displayName = 'AutoDialerProvider';
AutoDialerProviderWrapped.displayName = 'AutoDialerProviderWrapped';

export const useAutoDialer = () => {
  const contextValue = useContext(AutoDialerContext);

  if (!contextValue) {
    logError({}, 'You must wrap your component with AutoDialerProvider before accessing its context.');
  }

  return contextValue;
};
