import {
  FETCH_COMPLAINTS_LIST,
  RESET_COMPLAINTS_LIST
} from '../action-types';

export const DEFAULT_STATE = {
  complaintSuggestions: []
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_COMPLAINTS_LIST.SUCCESS: {
      return {
        ...state,
        complaintSuggestions: action.payload.complaints
      };
    }
    case RESET_COMPLAINTS_LIST: {
      return {
        ...state,
        complaintSuggestions: []
      };
    }
    default: {
      return state;
    }
  }
};
