import { fillFollowupSummary, validateConsultFollowup } from '../../consult-followup';
import store from 'app/lib/store';

export default () => {
  const state = store.getState().consultFollowup;
  const errors = [...validateConsultFollowup(state), ...coffeeValidate()];
  if (!errors.length) {
    fillFollowupSummary(state);
  } else {
    $('.secondary_instruction').html('');
  }
  return Promise.resolve(errors);
};

const coffeeValidate = () => {
  const { followUpSpecified } = window.consultationInterview.validation;
  const that = window.consultationInterview.validation;
  return followUpSpecified.apply(that);
};
