import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFetcher } from '@td/shared_utils';
import { ClinicalAlertsForm } from './clinical-alerts-form';

const ClinicalAlerts = ({
  mainInquiry,
  caFormMainInquiryChange,
  updateClinicalAlertsActivatedFlag,
  consultationId,
  cachedClinicalAlerts,
  ...props
}) => {
  const { data, loading, error } = useFetcher('/v4/medical_alert_codes/', { consultation_id: consultationId });

  useEffect(() => {
    if (data && data.medicalAlertCodes && data.medicalAlertCodes.length > 0) {
      updateClinicalAlertsActivatedFlag(true);
    } else {
      updateClinicalAlertsActivatedFlag(false);
    }
  }, [data]);

  return !loading && !error && data && data.medicalAlertCodes && data.medicalAlertCodes.length > 0 ? (
    <ClinicalAlertsForm
      mainInquiry={mainInquiry}
      caFormMainInquiryChange={caFormMainInquiryChange}
      medicalAlertCodesData={data.medicalAlertCodes}
      cachedClinicalAlerts={cachedClinicalAlerts}
      {...props}
    />
  ) : null;
};

export default ClinicalAlerts;

ClinicalAlerts.propTypes = {
  mainInquiry:                       PropTypes.string.isRequired,
  caFormMainInquiryChange:           PropTypes.func.isRequired,
  updateClinicalAlertsActivatedFlag: PropTypes.func.isRequired,
  consultationId:                    PropTypes.number,
  cachedClinicalAlerts:              PropTypes.array
};
