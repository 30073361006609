import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export const RadioButton = ({ choice, surveyNamingKey, questionCode, onChangeCallback, computedValue }) => {
  const isChecked = computedValue === choice.answerCode;

  useEffect(() => {
    setImmediate(() => {
      if (computedValue) {
        onChangeCallback(computedValue);
      }
    });
  }, []);

  return (
    <ul style={{ display: 'flex' }}>
      <li className="survey-radio">
        <input
          type="hidden"
          name={`${surveyNamingKey}[surveys][][sections][][questions][][answers][][answer_code]`}
          value={choice.answerCode}
        />
        <input
          type="hidden"
          value={isChecked ? 'Y' : 'N'}
          name={`${surveyNamingKey}[surveys][][sections][][questions][][answers][][value]`}
        />
        <input
          name={questionCode}
          onChange={() => onChangeCallback(choice.answerCode)}
          type="radio"
          checked={isChecked}
        />
      </li>
      <li>
        <div className="temporary-css-override-survey-checkbox" dangerouslySetInnerHTML={{ __html: choice.answer }} />
      </li>
    </ul>
  );
};

RadioButton.propTypes = {
  choice: PropTypes.shape({
    answer:     PropTypes.string,
    answerCode: PropTypes.string
  }),
  answerConfig: PropTypes.shape({
    value: PropTypes.boolean
  }),
  surveyNamingKey:  PropTypes.string,
  questionCode:     PropTypes.string,
  onChangeCallback: PropTypes.func,
  computedValue:    PropTypes.string
};
