import { useEffect } from 'react';
import { authToken } from '@td/api';
import { useUpdater } from '@td/shared_utils';

const useFetchData = (url, params) => {
  const request = useUpdater('get', { url, headers: { Authorization: authToken.get() }, params });

  useEffect(() => {
    request.callApi();
  }, [params]);

  return {
    data: request.data || {},
    loading: request.loading,
    error: request.error
  };
};

export default useFetchData;
