export const NOT_CONNECTED       = 'not-connected';
export const INITIATED           = 'initiated';
export const RINGING             = 'ringing';
export const CONNECTED           = 'in-progress';
export const DISCONNECTING       = 'disconnecting';
export const DISCONNECTED        = 'completed';
export const NO_ANSWER           = 'no-answer';
export const CONNECT_FAILED      = 'failed';
export const INITIAL_CALL_STATE  = {
  callStatus: NOT_CONNECTED,
  muted:      false,
  mutable:    true
};
