import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@td/shared_utils';

const RetryCall = ({ handleClick }) => (
  <div className="text-center retry-call-container">
    <I18n scope="autodialer.call_interface.call_complete" text="if_call_not_complete" />{' '}
    <span className="auto-dialer-link" onClick={handleClick}>
      <I18n scope="autodialer.call_interface.call_complete" text="call_again" />
    </span>
  </div>
);

RetryCall.propTypes = {
  handleClick: PropTypes.func.isRequired
};

export default RetryCall;
