import { createRequestActionTypes } from '@td/utils';

export const FETCH_COMPLAINTS_CLASSES = createRequestActionTypes(
  'subjective',
  'FETCH_COMPLAINTS_CLASSES'
);

export const FETCH_COMPLAINTS_LIST = createRequestActionTypes(
  'subjective',
  'FETCH_COMPLAINTS_LIST'
);

export const RESET_COMPLAINTS_LIST = 'subjective/RESET_COMPLAINTS_LIST';
export const SELECT_COMPLAINT = 'subjective/SELECT_COMPLAINT';
export const CLEAR_COMPLAINT = 'subjective/CLEAR_COMPLAINT';
