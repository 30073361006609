const FIELDS = {
  REASON: 'reason',
  NOTES: 'notes'
};

const VERIFICATION_STEP_KEY = 'verification';

export {
  FIELDS,
  VERIFICATION_STEP_KEY
};
