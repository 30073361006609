import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({ className, text, onClick, disabled }) => (
  <input
    className={classNames(className, { disabled })}
    disabled={disabled}
    type="button"
    value={text}
    onClick={onClick}
  />
);

Button.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  disabled: false
};

export default Button;
