import { connect } from 'react-redux';

import Alert from './alert';
import { closeAlert } from '../actions';
import getAlertContent from '../selectors/get-alert-content';
import getAlertButtons from '../selectors/get-alert-buttons';

const mapStateToProps = state => ({
  content: getAlertContent(state),
  buttons: getAlertButtons(state)
});

const mapDispatchToProps = {
  closeAlert
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert);
