import React, {useContext} from 'react';
import {
  Button,
  DialogTitle,
  DialogTrigger,
  makeStyles
} from '@fluentui/react-components';
import { I18n } from '@td/shared_utils';
import { Dismiss24Regular } from "@fluentui/react-icons";
import { FormikContext } from '../contexts';

const useStyles = makeStyles({
  dialogTitle: {
    border: 'none'
  }
});

const ModalTitle = () => {
  const fluentStyles = useStyles();
  const { cancelInProgress } = useContext(FormikContext);

  return (
    <DialogTitle
      className={fluentStyles.dialogTitle}
      action={
        <DialogTrigger action="close">
          <Button
            disabled={cancelInProgress}
            appearance="subtle"
            aria-label="close"
            icon={<Dismiss24Regular/>}
          />
        </DialogTrigger>
      }
    >
      <I18n scope="consultation.cancel.modal.title"/>
    </DialogTitle>
  );
};

export default ModalTitle;
