import { isEmpty, debounce } from 'lodash';
import { lastActiveTimeUpdated, updateLastActiveTime, fetchProviderLastActiveTime } from './actions';
import store from '../lib/store';

//
// The interval between two api call is 5 minutes
//
const INTERVAL = 5 * 60 * 1000;

let lastActiveTime = new Date();

// expose getProviderLastActiveTime to coffee script
window.getProviderLastActiveTime = () => lastActiveTime;

const providerActiveStatus = {
  isProviderLoggedOut: () => isEmpty(document.body.dataset.providerId),

  fetchLastActiveTime: () => {
    if (providerActiveStatus.isProviderLoggedOut()) {
      return;
    }

    store.dispatch(fetchProviderLastActiveTime.start());
  },

  //
  // This function is to make an API call to /v4/user_last_active_time,
  // Only call TAS when the listeners have detected user activities
  // and the provider is logged in
  //
  punch: () => {
    if (providerActiveStatus.isProviderLoggedOut()) {
      return;
    }

    updateLastActiveTime(lastActiveTime).then(() => {
      store.dispatch(lastActiveTimeUpdated(lastActiveTime));
    });
  },

  //
  // This function is to detect user activities
  // We care about 3 types of activities for now
  //
  addListeners: () => {
    ['mousemove', 'keyup', 'focus'].forEach(event => {
      // keep the lastActiveTime updated, since the api only fired every 5 minutes
      window.addEventListener(event, () => (lastActiveTime = new Date()), false);
      // it will not fire api call for every event, it can safely throttle the over often api calls
      window.addEventListener(
        event,
        debounce(providerActiveStatus.punch, INTERVAL, {
          leading:  true,
          trailing: true,
          maxWait:  INTERVAL
        }),
        false
      );
    });
  },

  main: () => {
    providerActiveStatus.fetchLastActiveTime();

    providerActiveStatus.addListeners();
  }
};

export default providerActiveStatus;
