import React from 'react';
import PropTypes from 'prop-types';

import PrescriptionSubmissionErrors from './prescription-submission-errors';

const ErxErrors = ({
  patientSyncError,
  prescriptionsSubmissionErrors,
  remedyApiUnavailable,
  pharmacyNcpdpIdMissing
}) => (
  <div>
    <input
      type="hidden"
      value={patientSyncError}
      data-name="patient_sync_error"
      name="interview[patient_sync_error]"
    />
    {prescriptionsSubmissionErrors.map(pse => (
      <PrescriptionSubmissionErrors key={pse.drug_ndc_code} pse={pse} />
    ))}
    <input
      type="hidden"
      value={remedyApiUnavailable}
      data-name="remedy_api_unavailable"
      name="interview[remedy_api_unavailable]"
    />
    <input
      type="hidden"
      value={pharmacyNcpdpIdMissing}
      data-name="pharmacy_ncpdp_id_missing"
      name="interview[pharmacy_ncpdp_id_missing]"
    />
  </div>
);

ErxErrors.propTypes = {
  patientSyncError:              PropTypes.string.isRequired,
  prescriptionsSubmissionErrors: PropTypes.array.isRequired,
  remedyApiUnavailable:          PropTypes.bool.isRequired,
  pharmacyNcpdpIdMissing:        PropTypes.bool.isRequired
};

export default ErxErrors;
