const initialLockedConsultationState = false;

const dateTimeFormatLong = 'dddd, MMM. D YYYY - h:mma';

const initialModalErrorMessage = false;

const initialFormState = { startDate: '', startTime: '', endDate: '', endTime: '', appointmentType: '' };

const validationProps = {
  minShiftLengthSize: null,
  maxShiftLengthSize: null,
  shiftTimeStep:      null,
  minStartDate:       null
};

const validationPropsMessages = {
  startDateInPast:        '',
  endDateBeforeStartDate: '',
  endTimeBeforeStartTime: '',
  minShiftLength:         '',
  maxShiftLength:         '',
  overlappingShift:       ''
};

export {
  initialLockedConsultationState,
  dateTimeFormatLong,
  initialModalErrorMessage,
  initialFormState,
  validationProps,
  validationPropsMessages
};
