import { NON_TELADOC_SERVICES_THAT_SUPPORT_PCP_REFERAL, FOLLOWUP_APPOINTMENT_OPTIONS } from '../constants';
import { translateServiceFeature } from '../helpers';
import $ from 'jquery';
import { translate } from '@td/shared_utils';
import ReactDOM from 'react-dom';
import React from 'react';
import ReferralsSummary from '../components/referrals-summary';

const TRANSLATION_SCOPE = 'consult_followup.summary';

const fillFollowupSummary = state => {
  const { selectedAppointmentOption, communicationMethod, noFurtherCareDays } = state;
  const $primaryFollowupElement = $('#primary_followup');

  if (selectedAppointmentOption === FOLLOWUP_APPOINTMENT_OPTIONS.PROVIDER_SCHEDULED) {
    // handled by CoffeeScript
  } else if (selectedAppointmentOption === FOLLOWUP_APPOINTMENT_OPTIONS.MEMBER_SCHEDULED) {
    $primaryFollowupElement.text(translate(null, TRANSLATION_SCOPE, 'appointment.member_scheduled'));
  } else if (selectedAppointmentOption === FOLLOWUP_APPOINTMENT_OPTIONS.NO_APPOINTMENT) {
    $primaryFollowupElement.text(
      translate(null, TRANSLATION_SCOPE, 'appointment.no_appointment', { noFurtherCareDays })
    );
  }

  const container = $('.summary.react-step #summary_secondary_recommendations_block')[0];
  ReactDOM.render(<ReferralsSummary {...state} />, container);

  if (communicationMethod === 'Message Center') {
    const message = getAppointmentMemberMessage(state);
    if (message) $('.primary_follow_up_message').show();
    else $('.primary_follow_up_message').hide();
    $('.primary_instruction').html(message || '');
  }

  if (communicationMethod === 'Message Center') {
    const message = getReferralsMemberMessage(state);
    if (message) $('.secondary_follow_up_message').show();
    else $('.secondary_follow_up_message').hide();
    $('.secondary_instruction').html(message || '');
  }
};

export default fillFollowupSummary;

const getReferralsMemberMessage = ({ selectedTeladocServices, selectedNonTeladocService, teladocServices }) => {
  const lines = [];
  for (const { serviceCode, featureCode } of selectedTeladocServices) {
    const service = mapTeladocService(teladocServices, serviceCode, featureCode);
    const line = translate(null, TRANSLATION_SCOPE, 'message_for_member.single_service', { service });
    lines.push(`<li>${line}</li><br/>`);
  }
  if (selectedNonTeladocService) {
    lines.push(
      `<li>${getMessageForNonTeladocService(
        selectedNonTeladocService,
        $('#secondary_referral_specialty_name_search')
      )}</li><br/>`
    );
  }
  return lines.join('');
};

const mapTeladocService = (teladocServices, serviceCode, featureCode) => {
  const service = teladocServices.find(s => s.code === serviceCode);
  if (featureCode) {
    const featureTranslated = translateServiceFeature(featureCode);
    return `${service.displayName} - ${featureTranslated}`;
  } else return service.displayName;
};

const getMessageForNonTeladocService = (serviceCode, element) => {
  if (NON_TELADOC_SERVICES_THAT_SUPPORT_PCP_REFERAL.includes(serviceCode)) {
    const translated = translate(null, TRANSLATION_SCOPE, 'non_teladoc_service');
    return `${translated}: ${element.val()}`;
  } else {
    const instructionValue = translate(
      null,
      `${TRANSLATION_SCOPE}.message_for_member.non_teladoc_services`,
      serviceCode
    );
    return `Your provider recommends ${instructionValue}`;
  }
};

const getAppointmentMemberMessage = ({ selectedAppointmentOption, noFurtherCareDays }) => {
  if (selectedAppointmentOption === FOLLOWUP_APPOINTMENT_OPTIONS.MEMBER_SCHEDULED) {
    return translate(null, TRANSLATION_SCOPE, 'appointment.member_scheduled');
  } else if (selectedAppointmentOption === FOLLOWUP_APPOINTMENT_OPTIONS.NO_APPOINTMENT) {
    return translate(null, TRANSLATION_SCOPE, 'appointment.no_appointment', { noFurtherCareDays });
  }
};
