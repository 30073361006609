import { teladocApi, TdApiRoutes, authToken } from '@td/api';

import { CALL_OPTUMNURSELINE, CONSULTATION_EVENT, CREATE_CONFERENCE_STATISTICS, CREATE_AUTOMATED_CALL } from './actionTypes';

const callOptumNurseline = consultationId => {
  const params = { consultation_id: consultationId };
  return {
    type:    CALL_OPTUMNURSELINE,
    payload: teladocApi.post(TdApiRoutes.media.createSipCall(), params, authToken.get())
  };
};

const logConferenceEvent = (consultationId, eventName) => {
  const params = { event_name: eventName };
  return {
    type:    CONSULTATION_EVENT,
    payload: teladocApi.post(TdApiRoutes.consultationEvent(consultationId), params, authToken.get())
  };
};

const createConferenceStatistic = conference_statistic_params => ({
    type:    CREATE_CONFERENCE_STATISTICS,
    payload: teladocApi.post(TdApiRoutes.media.createConferenceStatistic(), conference_statistic_params, authToken.get())
  });

const createAutomatedCall = consultationId => {
  const params = { consultation_id: consultationId };
  return {
    type:    CREATE_AUTOMATED_CALL,
    payload: teladocApi.post(TdApiRoutes.media.createAutomatedCall(), params, authToken.get())
  };
};

export { callOptumNurseline, logConferenceEvent, createConferenceStatistic, createAutomatedCall };
