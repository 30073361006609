import { get, isEmpty } from 'lodash';
import { PRIMARY, SECONDARY } from "../constants";

export const diagnosisHintParams = () => {
  const complaintCd = get(document.querySelector('input[name="interview[complaint_cd]"]'), 'value', null);
  const diagnosisHintRequired = get(document.querySelector('input[name="interview[diagnosis_hint_required]"]'), 'value', null);

  const hintSubScope = (isPrimary) => isPrimary ? PRIMARY : SECONDARY;

  return {
    complaintCd,
    diagnosisHintRequired: !isEmpty(diagnosisHintRequired),
    hintSubScope,
  }
};
