class FavIconManager {
  initialize = () => {
    this.currentIconLink = $('link[rel="shortcut icon"]');
    this.iconSrc = this.currentIconLink.attr('href');
  };

  drawAlertIcon = iconSrc => {
    const img = document.createElement('img');

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const context = canvas.getContext('2d');
      context.clearRect(0, 0, img.width, img.height);
      context.drawImage(img, 0, 0);

      const radius = 3;
      const centerX = img.width - radius;
      const centerY = img.height - radius;

      context.fillStyle = '#f07746';

      context.beginPath();
      context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      context.fill();

      this.changeFavIcon(context.canvas.toDataURL());
    };

    img.src = iconSrc;
  };

  changeFavIcon = iconSrc => {
    this.currentIconLink.attr('href', iconSrc);
  };

  changeFavIconToAlert = () => {
    this.drawAlertIcon(this.iconSrc);
  };

  changeFavIconToDefault = () => {
    this.changeFavIcon(this.iconSrc);
  };
}

const favIconManager = new FavIconManager();
const changeFavIconToAlert = favIconManager.changeFavIconToAlert;
const changeFavIconToDefault = favIconManager.changeFavIconToDefault;
const initialize = favIconManager.initialize;

export { initialize, changeFavIconToAlert, changeFavIconToDefault };
