import { createSelector } from '@td/utils';

const getSymptomsAddedReducer = state => state.symptomsAdded;

export default createSelector(
  getSymptomsAddedReducer,
  symptomsAddedReducer =>
    JSON.stringify({
      ...symptomsAddedReducer,
      addedSymptomsWithSearchBox: [...symptomsAddedReducer.addedSymptomsWithSearchBox.keys()]
    })
);
