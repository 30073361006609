import React from 'react';

import PatientInfo from './sections/patient-info';
import MealPlan from './sections/meal-plan';
import AdditionalNotes from './sections/additional-notes';
import ShoppingGuide from './sections/shopping-guide';
import SuggestedSupplements from './sections/suggested-supplements';

export default class NutPlanSectionFactory {
  static create(section, toggleSection, patientInfo) {
    switch (section.title) {
      case 'Patient Information':
        return (<PatientInfo
          toggleSection={toggleSection}
          section={section}
          patientInfo={patientInfo}
        />);
      case 'Meal Plan':
        return <MealPlan section={section} toggleSection={toggleSection} />;
      case 'Additional Notes':
        return <AdditionalNotes section={section} toggleSection={toggleSection} />;
      case 'Shopping Guide':
        return <ShoppingGuide section={section} toggleSection={toggleSection} />;
      case 'Suggested Supplements':
        return <SuggestedSupplements section={section} toggleSection={toggleSection} />;
      default:
        return;
    }
  }
}
