import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { authToken } from '@td/api';
import { get } from 'lodash';
import { useUpdater } from '@td/shared_utils';
import AutoDialer from './AutoDialer';

const POLLING_DELAY_MS = 4000;
const AUTO_DIALER_CONTAINER_ID = 'auto_dialer_consult_ct';

const AutoDialerInitializer = ({ consultation, options = {} }) => {
  const { consultationId, member, provider } = consultation;
  const [showAutodialer, setShowAutoDialer] = useState(false);

  // RS stands for 'request state'
  const activeConferenceRS = useUpdater('post', {
    url:     '/v4/autodialer/has-active-call',
    method:  'post',
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `${authToken.get()}` },
    params:  { consultation_id: consultationId }
  });

  useEffect(() => {
    activeConferenceRS.callApi();
  }, [consultationId]);

  useEffect(() => {
    // Decides to show or hide 'patient_contact_information' and 'auto_dialer_consult_ct' sections.
    if (get(activeConferenceRS, 'data.data.hasConfiguredConference')) {
      const confPresence = get(activeConferenceRS, 'data.data.hasConfiguredConference');
      const containersIdsToRemove = [];

      if (confPresence) {
        ['patient_contact_information', 'patient_not_reached'].forEach(containerId => {
          const container = document.getElementById(containerId);
          if (container) container.remove();
        });
        setShowAutoDialer(true);
      } else {
        setShowAutoDialer(false);
      }
    }
  }, [activeConferenceRS.data]);
  return showAutodialer ? (
    <AutoDialer consultation={consultation} activeConferenceRS={activeConferenceRS} options={options} />
  ) : null;
};

AutoDialerInitializer.propTypes = {
  consultation: PropTypes.shape({
    alternatePhoneNumber: PropTypes.string,
    phoneNumber:          PropTypes.string.isRequired,
    member:               PropTypes.shape({
      authProfileId:           PropTypes.string,
      brandName:               PropTypes.string,
      createdAt:               PropTypes.string,
      dob:                     PropTypes.string,
      domesticCountryCd:       PropTypes.string,
      firstNm:                 PropTypes.string.isRequired,
      genderCd:                PropTypes.string,
      groupId:                 PropTypes.number,
      hasWebAccess:            PropTypes.bool,
      isActiveConsult:         PropTypes.bool,
      isAdult:                 PropTypes.bool,
      languageCd:              PropTypes.string,
      lastNm:                  PropTypes.string.isRequired,
      memberId:                PropTypes.number,
      memberProviderRelations: PropTypes.array,
      memberStatusCd:          PropTypes.string,
      middleNm:                PropTypes.string,
      personType:              PropTypes.string,
      primaryMemberId:         PropTypes.number,
      residenceAddress:        PropTypes.object,
      userNm:                  PropTypes.string
    }).isRequired,
    provider: PropTypes.shape({
      clockedIn:            PropTypes.bool,
      communicationMethods: PropTypes.arrayOf(PropTypes.string),
      createdAt:            PropTypes.string,
      dob:                  PropTypes.string,
      firstNm:              PropTypes.string.isRequired,
      genderCd:             PropTypes.string,
      id:                   PropTypes.number,
      languages:            PropTypes.arrayOf(PropTypes.string),
      lastNm:               PropTypes.string.isRequired,
      location:             PropTypes.string,
      middleNm:             PropTypes.string,
      phoneNumbers:         PropTypes.arrayOf(
        PropTypes.shape({
          countryCode:             PropTypes.string,
          dialingCode:             PropTypes.string,
          formattedNationalNumber: PropTypes.string,
          id:                      PropTypes.number,
          number:                  PropTypes.string,
          phoneNumber:             PropTypes.string,
          preferredFlag:           PropTypes.string
        })
      ),
      salutation: PropTypes.string,
      suffix:     PropTypes.string,
      userNm:     PropTypes.string
    }).isRequired
  }).isRequired
};

export default AutoDialerInitializer;
