import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

const PIPNotification = ({ isThereAttendee, isPublished, onStartPublisher, onEndCall, pipWindow }) => {
  const handleCallButton = () => {
    if (isPublished) {
      onEndCall();
      pipWindow.close();
    } else {
      onStartPublisher();
    }
  }

  return (
    <div className={styles.pipNotification}>
      <div>
        <span>
          Video call
        </span>
      </div>
      <div>
        <span>
          Call ongoing as Picture in Picture
        </span>
      </div>
      <div>
        <button
          className='button'
          onClick={handleCallButton}
        >
          {
            isPublished
              ? 'End call'
              : isThereAttendee ? 'Join call' : 'Start call'
          }
        </button>
      </div>
    </div>
  )
}

PIPNotification.propTypes = {
  isThereAttendee: PropTypes.bool.isRequired,
  isPublished: PropTypes.bool.isRequired,
  onStartPublisher: PropTypes.func.isRequired,
  onEndCall: PropTypes.func.isRequired,
  pipWindow: PropTypes.object.isRequired
}

export default PIPNotification;

