import React from 'react';
import PropTypes from 'prop-types';
import { NOTIFICATION_MESSAGES } from '../../constants';
import './styles.scss';

const PatientLimitsButton = ({ handleClick }) => {
  return (
    <div className="patient-limits-wrapper" onClick={handleClick}>
      <div className="button-container">
        <div className="row">
          <div className="line small" />
          <div className="circle" />
          <div className="line medium separated" />
        </div>
        <div className="row closed">
          <div className="line large" />
          <div className="circle" />
          <div className="line smallest separated" />
        </div>
        <div className="row">
          <div className="line small" />
          <div className="circle" />
          <div className="line medium separated" />
        </div>
      </div>
      <div className="text-container">
        <span>{NOTIFICATION_MESSAGES.title}</span>
      </div>
    </div>
  );
};

PatientLimitsButton.propTypes = {
  handleClick: PropTypes.func
};

export default PatientLimitsButton;
