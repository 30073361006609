import React from 'react';

export default () => (
  <div>
    <h5 className="network-test-content network-test-header">
      Camera or Microphone Cannot be Accessed
    </h5>
    <div className="network-test-content">
      Our performance test confirmed your browser cannot access your camera or microphone. Because
      of this <span className="network-test-strong">you have been clocked in for phone only.</span>{' '}
      Please make sure you give the website the necessary permissions if you want to clock in for
      video consultations.
    </div>
    <ul className="network-test-content network-test-list">
      <li>
        In Chrome, within Settings, look for Permissions and then allow access to both the camera
        and microphone.
      </li>
      <li>
        In Safari, open Teladoc Provider app. Click Safari menu in upper left, then Preferences.
        Select Websites tab. Select Camera in sidebar. Select Teladoc Provider app from list of
        websites and set permissions to Allow. Repeat same steps for Microphone.
      </li>
    </ul>
  </div>
);
