import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export const SurveyDatePicker = ({ choice, answerConfig, surveyNamingKey, cachedAnswer }) => {
  // TODO get rid of the hardcoded max value
  const { value, onChangeCallback } = answerConfig;

  const computedValue = value || cachedAnswer;

  useEffect(() => {
    setImmediate(() => {
      if (cachedAnswer) {
        onChangeCallback({
          [choice.answerCode]: cachedAnswer
        });
      }
    });
  }, []);

  return (
    <div>
      <input
        type="hidden"
        name={`${surveyNamingKey}[surveys][][sections][][questions][][answers][][answer_code]`}
        value={choice.answerCode}
      />
      <input
        name={`${surveyNamingKey}[surveys][][sections][][questions][][answers][][value]`}
        value={computedValue}
        onChange={event => {
          onChangeCallback({
            [choice.answerCode]: event.target.value
          });
        }}
        max={new Date().toJSON().split('T')[0]}
        type="date"
      />
    </div>
  );
};

SurveyDatePicker.propTypes = {
  choice: PropTypes.shape({
    answer:     PropTypes.string,
    answerCode: PropTypes.string
  }),
  answerConfig: PropTypes.shape({
    onChangeCallback: PropTypes.func
  }),
  surveyNamingKey: PropTypes.string,
  cachedAnswer:    PropTypes.string
};
