import validate from 'validate.js';

const exclusionList = ['Select one...', ''];
const isEmrNewMedicationEnabled = window.FEATURE_TOGGLES.emr_new_medication;

const constraints = {
  ...(!isEmrNewMedicationEnabled && {
    drugQuantity: {
      presence: {
        message:    '^Please indicate the dosage',
        allowEmpty: false
      },
      numericality: {
        message:     '^Dosage must be a valid number',
        strict:      true,
        greaterThan: 0
      }
    },
    drugQuantityUnit: {
      presence: {
        message:    '^Please indicate dosage unit',
        allowEmpty: false
      }
    },
  }),
  drugName: {
    presence: {
      message:    '^Please enter the medication',
      allowEmpty: false
    },
    length: {
      minimum: 3,
      message: '^Medication must have a minimum length of 3 characters'
    }
  },
  drugFrequency: {
    presence: {
      message:    '^Please indicate the directions',
      allowEmpty: false
    },
    ...(isEmrNewMedicationEnabled ? {
      length: {
        maximum: 250,
        message: '^Direction must have a maximum length of 250 characters'
      }
    } : {
      exclusion: {
        within:  exclusionList,
        message: '^Directions are not valid'
      }
    }),
  },
  activelyTakingFlag: {
    inclusion: {
      within:  ['Y', 'No'],
      message: '^Please indicate the drug status'
    },
    presence: {
      allowEmpty: false
    }
  }
};

export function medicationValidator(attributes) {
  return validate(attributes, constraints);
}
