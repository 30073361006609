import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CONFERENCE_NEEDS_CONFIRMATION, CONFERENCE_IN_PROGRESS } from '../lib/states';

const getInstructions = ({ brandName, status, showCallRecordingMessage}) => {
  if (status === CONFERENCE_NEEDS_CONFIRMATION || status === CONFERENCE_IN_PROGRESS) {
    return (
      'Once you’re connected, the patient will be connected into the conference.' +
      '<br/>You can see the status of yourself and the patient in the conference room below.'
    );
  }

  const instruction =  brandName === 'Tricare'
    ? 'Thank you for helping Teladoc serve military families through Tricare.' +
      '<br/>Please make sure your correct phone number is here.'
    : 'A conference connection is required for this visit.' +
      '<br/>Please validate the number you can be reached and' +
      '<br/> click the Start Conference button below.';

  return showCallRecordingMessage == "true" ?  (`${instruction}<br/>Note: This call may be recorded for quality purposes.`) : instruction ;
};

export const ProviderInstructionsContainer = props => <div className="provider-instructions" dangerouslySetInnerHTML={{__html: getInstructions(props)}} />;

ProviderInstructionsContainer.propTypes = {
  brandName:                PropTypes.string.isRequired,
  showCallRecordingMessage: PropTypes.string,
  status:                   PropTypes.string.isRequired
};

export default connect(({ conference }) => ({
  status: conference.status
}))(ProviderInstructionsContainer);
