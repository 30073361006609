import React, { useRef, useMemo } from 'react';
import { ModalProvider } from 'app/modal';
import { SignalBars } from '@td/td-ui-kit';
import BandwidthColors from 'app/Provider/enums/bandwidth-colors';

import AutoDialerFooter from './AutoDialerFooter';
import WebRTC from './WebRTC';

import { AutoDialerProvider, useAutoDialer } from '../utils';
import CallStatusProvider from '../../../call-details/CallStatusProvider';
import CallStatus from '../../../call-details/components/call-status';
import CallNotification from '../../../call-details/components/call-notification';
import CallDetailsPopoverButton from '../../../call-details/components/call-details-popover-button';
import CallDetailsWrapper from '../../../call-details/components/call-details-wrapper';
import { AUDIO_VISIT_TYPE } from '../../../call-details/constants';
import ParticipantsProvider from '../../../call-details/ParticipantsProvider';
import { NotifyRegisteredNurseButton } from '../../../attendee-notifications';

const AutoDialer = () => {
  const { mosScore, consultation, attendeeNotificationsEnabled, callStatus } = useAutoDialer();
  const { consultationId } = consultation;

  const containerRef = useRef();
  const inProgress = useMemo(() => callStatus === 'IN_PROGRESS', [callStatus]);

  return (
    <div data-autodialer-version="AutoDialerV2" id="auto_dialer_consult_ct">
      <CallDetailsWrapper>
        <CallNotification />
        <CallDetailsPopoverButton consultation={consultation} visitType={AUDIO_VISIT_TYPE} />
      </CallDetailsWrapper>

      <div ref={containerRef}>
        <CallStatus containerRef={containerRef} consultation={consultation}/>

        <div id="auto_dialer_widget_container">
          <div className="auto-dialer-container-wrapper">
            <SignalBars
              barsClassName="auto-dialer-bandwidth-bar"
              barsContainerClassName="auto-dialer-bandwidth"
              emptySignalColor="#FFFFFF"
              numberOfBars={Math.round(mosScore)}
              signalColor={BandwidthColors[Math.round(mosScore)]}
            />

            <WebRTC />

            <div>
              <NotifyRegisteredNurseButton
                inProgress={inProgress}
                consultationId={consultationId}
                attendeeNotificationsEnabled={attendeeNotificationsEnabled}
              />
            </div>
          </div>

          <AutoDialerFooter/>
        </div>
      </div>
    </div>
  );
};

const AutoDialerContainer = ({ consultation, ...props }) => {
  const { consultationId } = consultation;

  return (
    <ModalProvider>
      <AutoDialerProvider {...props} consultation={consultation}>
        <CallStatusProvider consultationId={consultationId}>
          <ParticipantsProvider consultationId={consultationId}>
            <AutoDialer />
          </ParticipantsProvider>
        </CallStatusProvider>
      </AutoDialerProvider>
    </ModalProvider>
  );
};

AutoDialer.displayName = 'AutoDialer';
AutoDialerContainer.displayName = 'AutoDialerContainer';

export default AutoDialerContainer;

export { AutoDialer };
