import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { teladocApi, authToken } from '@td/api';
import NetworkTest from 'opentok-network-test-js';

import OpenTokNetworkTestError, { ErrorNames } from './open-tok-network-test-error';
import { uid } from '../utils';

const MOS_THRESHOLD = 2.4;
const QUALITY_TEST_DURATION = 15 * 1000;
const BANDWIDTH_TOO_LOW_REASON = 'Bandwidth too low.';

const getOpenTokSessionCredentials = () =>
  teladocApi
    .post(
      '/v4/videos/provider_network_test',
      {
        identifier: uid()
      },
      authToken.get()
    )
    .then(response => get(response, ['data', 'result']))
    .catch(error => {
      throw new OpenTokNetworkTestError(error.message, [ErrorNames.TAS_CREDENTIALS]);
    });

const handleOpenTokRequestError = error => {
  if (error.name === 'OpenTokNetworkTestError') {
    throw error;
  }

  throw new OpenTokNetworkTestError(error.message, [error.name]);
};

export const createNetworkTestSession = () =>
  getOpenTokSessionCredentials()
    .then(
      credentials => new NetworkTest(window.OT, credentials, { timeout: QUALITY_TEST_DURATION })
    )
    .catch(handleOpenTokRequestError);

export const testConnectivity = session =>
  session
    .testConnectivity()
    .then(result => {
      if (result.success) {
        return { data: { success: true } };
      }

      const errorCodes = map(get(result, 'failedTests'), failedTest =>
        get(failedTest, ['error', 'name'])
      );

      throw new OpenTokNetworkTestError('Connectivity test failed.', errorCodes);
    })
    .catch(handleOpenTokRequestError);

const isAudioUnsupported = results => !get(results, ['audio', 'supported']);
const isVideoUnsupported = results => !get(results, ['video', 'supported']);

const isAudioBandwidthTooLowReason = results =>
  get(results, ['audio', 'reason']) === BANDWIDTH_TOO_LOW_REASON;
const isVideoBandwidthTooLowReason = results =>
  get(results, ['video', 'reason']) === BANDWIDTH_TOO_LOW_REASON;

const isAudioMosBelowThreshold = results => get(results, ['audio', 'mos']) < MOS_THRESHOLD;
const isVideoMosBelowThreshold = results => get(results, ['video', 'mos']) < MOS_THRESHOLD;

export const testQuality = session =>
  session
    .testQuality()
    .then(results => {
      const errorCodes = [];

      if (isAudioUnsupported(results)) {
        if (isAudioBandwidthTooLowReason(results)) {
          errorCodes.push(ErrorNames.FAILED_AUDIO_TEST);
        } else {
          errorCodes.push(ErrorNames.NO_AUDIO_CAPTURE_DEVICES);
        }
      } else if (isAudioMosBelowThreshold(results)) {
        errorCodes.push(ErrorNames.FAILED_AUDIO_TEST);
      }

      if (isVideoUnsupported(results)) {
        if (isVideoBandwidthTooLowReason(results)) {
          errorCodes.push(ErrorNames.FAILED_VIDEO_TEST);
        } else {
          errorCodes.push(ErrorNames.NO_VIDEO_CAPTURE_DEVICES);
        }
      } else if (isVideoMosBelowThreshold(results)) {
        errorCodes.push(ErrorNames.FAILED_VIDEO_TEST);
      }

      const testResults = { audio: results.audio, video: results.video };

      if (isEmpty(errorCodes)) {
        return { data: { testResults } };
      }

      throw new OpenTokNetworkTestError('Call quality test failed.', errorCodes, testResults);
    })
    .catch(handleOpenTokRequestError);
