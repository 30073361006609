import React, {useEffect, useRef, useState} from 'react';
import '../styles.scss';
import { useLazyQuery, useMutation } from '@apollo/client';

import PropTypes from 'prop-types';
import START_TIMER from '../mutations/start_timer.graphql';
import STOP_TIMER from '../mutations/stop_timer.graphql';
import PROMPT_SHOWN from '../mutations/prompt_shown.graphql';
import CONSULTATION_TIMER_CONFIG from '../queries/consultation_timer_config.graphql';

import infoIcon from '../../assets/images/info-icon.svg';
import stopIcon from '../../assets/images/stop-icon.svg';

const ProviderConsultationTimer = ({ consultation_id, pipWindow }) => {
  const [finalSeconds, setFinalSeconds] = useState(0);
  const [finalMinutes, setFinalMinutes] = useState(0);
  const [showReminderPopUp, setShowReminderPopUp] = useState(false);
  const [timerStopped, setTimerStopped] = useState(false);
  const [displayTimer, setDisplayTimer] = useState(false);

  const [isConsultCompleted, setIsConsultCompleted] = useState(false);
  const [visitDuration, setVisitDuration] = useState(0);
  const [promptData, setPromptData] = useState({});

  const initial_timeout = useRef();
  const autoclosing_timeout = useRef();
  const followup_timeout = useRef();

  let seconds = 0;
  let minutes = 0;
  const interval = useRef();

  const [getConfig, { data }] = useLazyQuery(CONSULTATION_TIMER_CONFIG, {
    variables: { consultation_id }
  });

  useEffect(() => {
    if (data) {
      setIsConsultCompleted(data && data.consultConfig && data.consultConfig.completion_flg);
      setVisitDuration(data && data.consultConfig && data.consultConfig.visit_duration);
      setPromptData(data && data.consultConfig && data.consultConfig.prompt);
    }
  }, [data]);

  useEffect(() => {
    if (pipWindow && displayTimer) {
      const pipContainer = pipWindow.document.querySelector('#pip_container');
      pipContainer.className = 'react with-timer';
    }
  }, [pipWindow, displayTimer]);

  const startTimer = () => {
    if (data && data.consultConfig && data.consultConfig.should_show_timer) {
      if (visitDuration) {
        resumeTimer();
      } else if (finalSeconds === 0 && finalMinutes === 0) {
        startClock();
        callStartTimer({ variables: { consultation_id } });
        if (promptData && promptData.should_show_prompt) {
          handlePrompt(promptData.first_time_prompt_wait_time);
        }
      }
      setDisplayTimer(true);
    }
  };

  const [callStartTimer] = useMutation(START_TIMER);
  const [callStopTimer] = useMutation(STOP_TIMER);
  const [prompt_shown] = useMutation(PROMPT_SHOWN);

  const stopTimer = () => {
    setTimerStopped(true);
    pauseClock();
    callStopTimer({ variables: { consultation_id } });
    clearTimeout(initial_timeout.current);
    clearTimeout(autoclosing_timeout.current);
    clearTimeout(followup_timeout.current);
  };

  const startClock = () => {
    seconds = 0;
    minutes = 0;
    refreshClock();
    cronometer();
  };

  const resumeTimer = () => {
    setDisplayTimer(true);
    const totalTime = parseInt(visitDuration);
    seconds = totalTime % 60;
    minutes = parseInt(totalTime / 60);
    if (!isConsultCompleted) {
      cronometer();
    } else {
      setFinalSeconds(seconds < 10 ? `0${seconds}` : seconds);
      setFinalMinutes(minutes < 10 ? `0${minutes}` : minutes);
    }

    if (promptData && promptData.should_show_prompt && !isConsultCompleted) {
      handlePrompt(promptData.first_time_prompt_wait_time - visitDuration);
    }
  };

  const cronometer = () => {
    interval.current = setInterval(() => {
      if (seconds < 59) {
        seconds++;
        refreshClock();
      } else if (seconds == 59) {
        minutes++;
        seconds = 0;
        refreshClock();
      }
    }, 1000);
  };

  const refreshClock = () => {
    setFinalSeconds(seconds < 10 ? `0${seconds}` : seconds);
    setFinalMinutes(minutes < 10 ? `0${minutes}` : minutes);
  };

  const pauseClock = () => {
    clear(interval.current);
  };

  const clear = intervalID => {
    clearInterval(intervalID);
  };

  const handlePrompt = first_time_prompt_wait_time => {
    initial_timeout.current = setTimeout(() => {
      setShowReminderPopUp(true);
      // prompt_shown call
      prompt_shown({ variables: { consultation_id } });
      // condition for autoclosing the pop up
      if (promptData.auto_hide) {
        autoClose();
      }
    }, first_time_prompt_wait_time * 1000);
  };

  const noActionCall = () => {
    if (promptData.is_recurring) {
      clearTimeout(autoclosing_timeout.current);
      setShowReminderPopUp(false);
      followup_timeout.current = setTimeout(() => {
        setShowReminderPopUp(true);
        // prompt_shown call
        prompt_shown({ variables: { consultation_id } });
        // condition for autoclosing pop up
        if (promptData.auto_hide) {
          autoClose();
        }
      }, promptData.recurring_prompt_wait_time * 1000);
    }
  };
  const yesActionCall = () => {
    stopTimer();
    setShowReminderPopUp(false);
  };

  const autoClose = () => {
    autoclosing_timeout.current = setTimeout(() => {
      setShowReminderPopUp(false);
      stopTimer();
    }, promptData.auto_hide_after * 1000);
  };

  return (
    <div>
      <button className="btn hidden" id="config" onClick={() => getConfig()} />
      <div className={displayTimer ? 'timer-section' : 'hide-timer-section'}>
        <section className="clock">
          <div className="tooltip-info">
            <img className="tooltip-icon" id="tooltip-timer" src={infoIcon}/>
            <span className="tooltiptext">Stop timer when call ends</span>
          </div>
          <div className="timer-text">Time with patient</div>
          <div className="timer-container">
            <div className="timer-row">
              <div className="input-wrapper">
                <div className="buttons-wrapper">
                  <button className="btn hidden" id="start-cronometer" onClick={startTimer}>
                    Start
                  </button>
                </div>
              </div>
              <div id="timer">
                <div className="clock-wrapper">
                  <span className="minutes">{finalMinutes || '00'}</span>
                  <span className="dots">:</span>
                  <span className="seconds">{finalSeconds || '00'}</span>
                </div>
              </div>
              <div className="buttons-wrapper">
                <img
                  src={stopIcon}
                  id="stop-timer"
                  className={isConsultCompleted || timerStopped ? 'stop-icon-disabled' : 'stop-icon'}
                  onClick={stopTimer}
                />
                <button className="btn hidden" id="resume-timer" onClick={resumeTimer}/>
              </div>
            </div>
          </div>
        </section>
      </div>

      {showReminderPopUp ? (
        <div className="reminder-pop-up-container-new">
          <div className="reminder-modal-content">
            <div className="reminder-modal-header">
              <span className="reminder-modal-title">Has the call ended?</span>
            </div>
            <div className="reminder-pop-up-body">
              <h1 className="text-content">
                If you don’t take action within one minute, the call timer will stop automatically.
              </h1>
            </div>
            <div className="action-buttons-wrapper">
              <button className="action-call-button no-action-call-button" onClick={noActionCall}>
                NO, KEEP TIMER RUNNING
              </button>
              <button className="action-call-button yes-action-call-button" onClick={yesActionCall}>
                YES, STOP TIMER
              </button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

ProviderConsultationTimer.propTypes = {
  configApiData: PropTypes.object
};

export default ProviderConsultationTimer;

