import React, { useState, Fragment } from 'react';
import classNames from 'classnames';
import closeIcon from '../../assets/images/close-icon.svg';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import styles from './text-with-popup.module.scss';
import PropTypes from 'prop-types';

const TextWithPopup = ({ id, text, textStyle, popupTitle, popupDescription, popupStyle, popupTitleStyle }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger:         'click',
    placement:       'right-end',
    offset:          [15, 20],
    visible:         tooltipVisible,
    onVisibleChange: setTooltipVisible
  });
  return (
    <Fragment>
      <span ref={setTriggerRef} className={styles.text} style={textStyle}>
        {text}
      </span>
      {visible && (
        <div
          id={id}
          ref={setTooltipRef}
          {...getTooltipProps()}
          className={classNames(popupStyle, 'tooltip-container', styles.popup)}
        >
          <img className={styles.popupCloseIcon} src={closeIcon} onClick={() => setTooltipVisible(false)} />
          <div className={classNames(popupTitleStyle, styles.popupTitle)}>{popupTitle}</div>
          <div className={styles.popupDescription}>{popupDescription}</div>
          <div {...getArrowProps()} className="tooltip-arrow" />
        </div>
      )}
    </Fragment>
  );
};

TextWithPopup.propTypes = {
  text:             PropTypes.node.isRequired,
  popupTitle:       PropTypes.node.isRequired,
  popupDescription: PropTypes.node.isRequired,
  id:               PropTypes.string,
  textStyle:        PropTypes.object,
  popupStyle:       PropTypes.string,
  popupTitleStyle:  PropTypes.string
};

export default TextWithPopup;
