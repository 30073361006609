import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { formatTime } from '../utils'

const MemberAvailability = ({ data, requestedDates, displayInScheduleModal = false }) => {
  if (!data && !isEmpty(requestedDates)) return requestedDates.map(date => <p>{formatTime(date)}</p>)
  if (!data) return null;

  const { memberAvailabilityJson, timezone } = data;

  const localTimezone = moment.tz.guess();
  const timezoneString = moment().tz(localTimezone).format('z');

  const toLocalTimezone = date =>
    moment.tz(date, 'HH:mm', timezone).tz(localTimezone);

  return (
    <div className="member-availability-container">
      {memberAvailabilityJson && Object.entries(memberAvailabilityJson).flatMap(([day, dayAvailablity]) => {
        const formattedDay = moment()
          .day(day)
          .format(displayInScheduleModal ? 'dddd' : 'ddd');

        const formattedTimes = dayAvailablity.map(({ startTime, endTime }) => {
          const [momentStartTime, momentEndTime] = [toLocalTimezone(startTime), toLocalTimezone(endTime)];
          const startAndEndTimesAreInTheSamePeriod = momentStartTime.format('A') === momentEndTime.format('A');

          return `${momentStartTime.format(startAndEndTimesAreInTheSamePeriod ? 'h:mm' : 'h:mm A')}-${momentEndTime.format(
            'h:mm A'
          )}`;
        });

        return (
          <Fragment>
            {!displayInScheduleModal ? (
              <p key={formattedDay}>
                <strong>{formattedDay}</strong> {formattedTimes.join(', ')}{' '}
                {timezoneString}
              </p>
            ) : (
              <div className="patientPreferncesDays" key={formattedDay}>
                <p className="weekDay">{formattedDay}</p>
                <p className="timing">
                  {formattedTimes.join(', ')}{' '}
                  {timezoneString}
                </p>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

MemberAvailability.propTypes = {
  data: PropTypes.shape({
    memberAvailabilityJson: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          startTime: PropTypes.string,
          endTime: PropTypes.string,
        })
      )
    ),
    timezone: PropTypes.string,
  }),
  displayInScheduleModal: PropTypes.bool,
};

export default MemberAvailability;
