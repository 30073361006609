// Constants
const FETCH_CLINICAL_MEDICATIONS                = 'FETCH_CLINICAL_MEDICATIONS';
const FETCH_CLINICAL_MEDICATIONS_START          = 'FETCH_CLINICAL_MEDICATIONS_START';
const FETCH_CLINICAL_MEDICATIONS_SUCCESS        = 'FETCH_CLINICAL_MEDICATIONS_SUCCESS';
const FETCH_CLINICAL_MEDICATIONS_FAIL           = 'FETCH_CLINICAL_MEDICATIONS_FAIL';
const SUBMIT_CLINICAL_MEDICATION_FORM           = 'SUBMIT_CLINICAL_MEDICATION_FORM';
const SUBMIT_CLINICAL_MEDICATION_FORM_START     = 'SUBMIT_CLINICAL_MEDICATION_FORM_START';
const SUBMIT_CLINICAL_MEDICATION_FORM_SUCCESS   = 'SUBMIT_CLINICAL_MEDICATION_FORM_SUCCESS';
const SUBMIT_CLINICAL_MEDICATION_FORM_FAIL      = 'SUBMIT_CLINICAL_MEDICATION_FORM_FAIL';
const SUBMIT_CLINICAL_MEDICATION_FORM_CANCEL    = 'SUBMIT_CLINICAL_MEDICATION_FORM_CANCEL';
const SET_CLINICAL_MEDICATION_FORM_FIELD        = 'SET_CLINICAL_MEDICATION_FORM_FIELD';
const VALIDATE_CLINICAL_MEDICATION_FORM         = 'VALIDATE_CLINICAL_MEDICATION_FORM';
const VALIDATE_CLINICAL_MEDICATION_FORM_START   = 'VALIDATE_CLINICAL_MEDICATION_FORM_START';
const VALIDATE_CLINICAL_MEDICATION_FORM_SUCCESS = 'VALIDATE_CLINICAL_MEDICATION_FORM_SUCCESS';
const VALIDATE_CLINICAL_MEDICATION_FORM_FAIL    = 'VALIDATE_CLINICAL_MEDICATION_FORM_FAIL';
const RESET_CLINICAL_MEDICATION_FORM            = 'RESET_CLINICAL_MEDICATION_FORM';
const UPDATE_INPUT_VALUE                        = 'UPDATE_INPUT_VALUE';
const UPDATE_MEDICATION_LIST                    = 'UPDATE_MEDICATION_LIST';
const FETCH_MEDICATION_LIST_REQUEST             = 'FETCH_MEDICATION_LIST_REQUEST';
const FETCH_MEDICATION_LIST_START               = 'FETCH_MEDICATION_LIST_START';
const FETCH_MEDICATION_LIST_SUCCESS             = 'FETCH_MEDICATION_LIST_SUCCESS';
const FETCH_MEDICATION_LIST_FAIL                = 'FETCH_MEDICATION_LIST_FAIL';
const FETCH_MEDICATION_LIST_CANCEL              = 'FETCH_MEDICATION_LIST_CANCEL';
const SET_SELECTED_MEDICATION                   = 'SET_SELECTED_MEDICATION';
const RESET_MEDICATION_LIST                     = 'RESET_MEDICATION_LIST';
const ADD_TO_PENDING_MEDICATION_LIST            = 'ADD_TO_PENDING_MEDICATION_LIST';
const REMOVE_FROM_PENDING_MEDICATION_LIST       = 'REMOVE_FROM_PENDING_MEDICATION_LIST';
const CLEAR_PENDING_MEDICATION_LIST             = 'CLEAR_PENDING_MEDICATION_LIST';
const SET_SURESCRIPTS_UPDATED_FLAG              = 'SET_SURESCRIPTS_UPDATED_FLAG';

export {
  UPDATE_INPUT_VALUE,
  UPDATE_MEDICATION_LIST,
  FETCH_MEDICATION_LIST_REQUEST,
  FETCH_MEDICATION_LIST_START,
  FETCH_MEDICATION_LIST_SUCCESS,
  FETCH_MEDICATION_LIST_CANCEL,
  FETCH_MEDICATION_LIST_FAIL,
  SET_SELECTED_MEDICATION,
  RESET_MEDICATION_LIST,
  FETCH_CLINICAL_MEDICATIONS,
  FETCH_CLINICAL_MEDICATIONS_START,
  FETCH_CLINICAL_MEDICATIONS_SUCCESS,
  FETCH_CLINICAL_MEDICATIONS_FAIL,
  SUBMIT_CLINICAL_MEDICATION_FORM,
  SUBMIT_CLINICAL_MEDICATION_FORM_START,
  SUBMIT_CLINICAL_MEDICATION_FORM_SUCCESS,
  SUBMIT_CLINICAL_MEDICATION_FORM_FAIL,
  SUBMIT_CLINICAL_MEDICATION_FORM_CANCEL,
  SET_CLINICAL_MEDICATION_FORM_FIELD,
  VALIDATE_CLINICAL_MEDICATION_FORM,
  VALIDATE_CLINICAL_MEDICATION_FORM_START,
  VALIDATE_CLINICAL_MEDICATION_FORM_SUCCESS,
  VALIDATE_CLINICAL_MEDICATION_FORM_FAIL,
  RESET_CLINICAL_MEDICATION_FORM,
  ADD_TO_PENDING_MEDICATION_LIST,
  REMOVE_FROM_PENDING_MEDICATION_LIST,
  CLEAR_PENDING_MEDICATION_LIST,
  SET_SURESCRIPTS_UPDATED_FLAG
};
