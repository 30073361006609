import { of, concat } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, filter, map } from 'rxjs/operators';
import { PROVIDER_SELECT_NOT_CONNECTED, CALL_STATUS_CHANGE } from './actions/actionTypes';
import { conferenceConfirmationAccept } from './actions/conferenceActions.js';
import { disconnectParticipant, conferenceConfirmationReject } from './actions';
import { findProvider, canDisconnectParticipant } from './lib/participants';
import { CONNECTED } from './lib/states/callStates';

export const rejectConferenceEpic = (action$, state$) =>
  action$.pipe(
    ofType(PROVIDER_SELECT_NOT_CONNECTED),
    mergeMap(() => {
      const { conferenceParticipants } = state$.value.conference;
      const provider = findProvider(conferenceParticipants);
      const {
        person: { conferenceId, attendanceId }
      } = provider;

      // The reason of checking provider connect status at here is that stopping
      // an unconnected participant will cause displaying unexpected call status
      if (attendanceId && canDisconnectParticipant(provider)) {
        return concat(of(disconnectParticipant({ conferenceId, attendanceId })), of(conferenceConfirmationReject()));
      }

      return of(conferenceConfirmationReject());
    })
  );

export const confirmConferenceEpic = (action$, state$) =>
  action$.pipe(
    ofType(CALL_STATUS_CHANGE),
    filter(() => {
      const {
        conference: { conferenceParticipants }
      } = state$.value;
      const provider = findProvider(conferenceParticipants);
      const {
        conferenceCall: { callStatus }
      } = provider;

      return callStatus && callStatus === CONNECTED;
    }),
    map(() => conferenceConfirmationAccept())
  );
