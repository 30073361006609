import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverridesList, hasErxFunctionality } from 'medication_service_ui';
import { I18n } from '@td/shared_utils';

import ActionsMenu from './ActionsMenu';
import '../styles.scss';

class ProviderOrderListItem extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document
      .querySelectorAll('.truncated-text')
      .forEach(el => el.addEventListener('mouseenter', event => this.truncatedTextExpansion(event)));
  }

  truncatedTextExpansion(event) {
    const text = event.target;
    const $text = $(event.target);
    if (text.offsetHeight < text.scrollHeight && !$text.attr('title')) $text.attr('title', $text.text());
  }

  renderRequesterName = providerOrder => {
    const { providerParams } = this.props;
    const originalProviderName = providerParams.providerNameWithCredentials || providerParams.providerName;

    if (providerOrder.createdByType !== 'Provider') {
      return providerOrder.relatedProviderOrder ? providerOrder.relatedProviderOrder.originalRequestor : originalProviderName;
    }

    return providerOrder.requestedBy;
  }

  render() {
    const {
      providerOrder,
      displayOptions: { showRequester, showActions, section },
      consultationParams,
      consultationParams: { countryCode, consultationIsGlobal, consultationId },
      canDoCancelRx,
      revokeHandlerFunc,
      revokePending,
      memberParams,
      pharmacyParams,
      providerParams,
      revokeSuccess
    } = this.props;

    const { overrides = [], status, manualRx } = providerOrder;

    return (
      <tbody>
        <tr>
          <td>
            {providerOrder.drugName}
            {!!overrides.length && <OverridesList overrides={overrides} />}
          </td>
          <td>{providerOrder.drugQuantity}</td>
          <td className="instructions">
            <div className="truncated-text">{providerOrder.instructions}</div>
          </td>
          {showRequester && <td>{this.renderRequesterName(providerOrder)}</td>}
          <td>
            {status}
            {hasErxFunctionality(countryCode, 'manualRx', consultationIsGlobal) && manualRx && (
              <div className="manualrx">
                <span>
                  <I18n scope="provider_order.status" text="manual" />
                </span>
              </div>
            )}
          </td>
          {showActions &&
            hasErxFunctionality(countryCode, 'revokeAction', consultationIsGlobal) &&
            section === 'history' && (
              <ActionsMenu
                providerOrder={providerOrder}
                canDoCancelRx={canDoCancelRx}
                consultationParams={consultationParams}
                revokeHandlerFunc={revokeHandlerFunc}
                revokePending={revokePending}
                providerParams={providerParams}
                memberParams={memberParams}
                pharmacyParams={pharmacyParams}
                revokeSuccess={revokeSuccess}
              />
            )}
        </tr>
      </tbody>
    );
  }
}

ProviderOrderListItem.propTypes = {
  providerOrder:      PropTypes.object.isRequired,
  canDoCancelRx:      PropTypes.bool,
  consultationParams: PropTypes.shape({
    consultationId: PropTypes.number.isRequired,
    countryCode:    PropTypes.string.isRequired
  }).isRequired,
  providerParams: PropTypes.shape({
    providerCanRx:   PropTypes.bool.isRequired,
    firstNm:         PropTypes.string,
    lastNm:          PropTypes.string,
    providerAddress: PropTypes.string
  }).isRequired,
  memberParams: PropTypes.shape({
    memberId:      PropTypes.number,
    memberName:    PropTypes.string,
    memberGender:  PropTypes.string,
    phoneNumber:   PropTypes.string,
    memberAddress: PropTypes.string,
    memberDoB:     PropTypes.string,
    memberHeight:  PropTypes.string,
    memberWeight:  PropTypes.string
  }).isRequired,
  pharmacyParams: PropTypes.shape({
    pharmacyNm:   PropTypes.string,
    addressLine1: PropTypes.string,
    phone:        PropTypes.object
  }).isRequired,
  onDelete:          PropTypes.func,
  toggleEditMode:    PropTypes.func,
  revokeHandlerFunc: PropTypes.func.isRequired,
  revokePending:     PropTypes.bool.isRequired,
  displayOptions:    PropTypes.object.isRequired,
  revokeSuccess:     PropTypes.bool.isRequired
};

export default ProviderOrderListItem;
