import { useEffect } from 'react';
import _ from 'lodash';

import { useModal } from 'app/modal';
import axios from 'axios';
import { CONFERENCE_STATUSES, VIEW_STATES } from '../../constants';

const getConsultId = () => {
  const consultationWrapper = document.querySelector('#consult');

  return parseInt(_.get(consultationWrapper, 'dataset.consultation_id', null), 10);
};

export const useMemberUnavailableModal = ({ viewState, callStatus, setCallStatus }) => {
  const { setModalContent } = useModal();

  const showModal = () => {
    const url = `/consultations/${getConsultId()}/patient_unavailable_modal`;

    setCallStatus(CONFERENCE_STATUSES.READY_TO_START);

    $(document).on('click', '#CancelConsultSubmit', event => {
      event.preventDefault();
      $('<input>')
        .attr({
          type: 'hidden',
          name: 'consultation[reason_cd]'
        })
        .val('PRVCANCELRSN_UNABLETOREACH')
        .appendTo($('#consult_form'));
      $('<input>')
        .attr({
          type: 'hidden',
          name: 'save_data_on_cancel'
        })
        .val('true')
        .appendTo($('#consult_form'));
      $('<input>')
        .attr({
          type: 'hidden',
          name: 'authenticity_token'
        })
        .val($('[name="authenticity_token"]').val())
        .appendTo($('#consult_form'));
      $.ajax({
        url:  $(`#edit_consultation_${getConsultId()}.edit_consultation`).attr('action'),
        type: 'PATCH',
        data: $('#consult_form').serialize()
      });
    });

    axios
      .get(url)
      .then(({ data }) => {
        setModalContent({
          modalId:    'auto-dialer-member-unavailable-modal',
          modalProps: {
            showCloseButton: false,
            modalBodyStyles: {
              width:    '400px',
              minWidth: 'auto'
            }
          },
          html: data
        });
      })
      .catch(console.error);
  };

  useEffect(() => {
    const callStatusEqualsComplete = callStatus === CONFERENCE_STATUSES.CONFERENCE_COMPLETE;
    const viewStateEqualsUnavailable = viewState === VIEW_STATES.UNAVAILABLE;

    if (callStatusEqualsComplete && viewStateEqualsUnavailable) {
      showModal();
    }
  }, [viewState, callStatus]);
};
