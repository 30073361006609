import { combineEpics } from 'redux-observable';
import { map } from 'rxjs/operators';
import _ from 'lodash';
import { teladocApi, authToken } from '@td/api';
import { from, zip } from 'rxjs';

import { FETCH_DROPDOWN_VALUES } from '../action-types';
import createRequestEpic from '../../lib/redux/utilities/create-request-epic';

const getMembers = memberGroup =>
  from(teladocApi.get(`/v1/lists/${memberGroup}`, authToken.get())).pipe(
    map(res =>
      _.chain(res.data.data)
        .orderBy(member => member.master_code_id)
        .map(member => ({
          label: member.name,
          value: member.master_code_cd
        }))
        .value()
    )
  );

export const fetchDropdownTypesEpic = createRequestEpic(FETCH_DROPDOWN_VALUES, () =>
  zip(getMembers('duration'), getMembers('clinicalseverity'), getMembers('clinicalprogression')).pipe(
    map(([duration, severity, progression]) => ({
      data: {
        dropdownValues: {
          duration,
          severity,
          progression
        }
      }
    }))
  )
);

export default combineEpics(fetchDropdownTypesEpic);
