import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NotifyNurseButton from '../notify-nurse-button';
import { attendeeTypes } from '../../../constants';
import { useParticipants } from '../../../call-details/ParticipantsProvider';

const NotifyRegisteredNurseButton = React.memo(
  ({ inProgress, consultationId, attendeeNotificationsEnabled, className }) => {
    const participants = useParticipants();
    const canNotifyRn = useMemo(
      () => attendeeNotificationsEnabled && inProgress && participants.some(p => p.roleCd === attendeeTypes.RN),
      [attendeeNotificationsEnabled, inProgress, participants]
    );

    return (
      canNotifyRn && (
        <NotifyNurseButton roleCd={attendeeTypes.RN} consultationId={consultationId} className={className} />
      )
    );
  }
);

NotifyRegisteredNurseButton.propTypes = {
  inProgress:                   PropTypes.bool.isRequired,
  consultationId:               PropTypes.number.isRequired,
  attendeeNotificationsEnabled: PropTypes.bool.isRequired,
  className:                    PropTypes.string
};

export default NotifyRegisteredNurseButton;
