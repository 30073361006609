import {
  FETCH_COMPLAINTS_CLASSES
} from '../action-types';

export const DEFAULT_STATE = {
  complaintsClasses: {}
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_COMPLAINTS_CLASSES.SUCCESS: {
      return {
        ...state,
        complaintsClasses: action.payload.complaintsClasses
      };
    }
    default: {
      return state;
    }
  }
};
