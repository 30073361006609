import { createSelector } from '@td/utils';

import getSymptomClassesById from './getSymptomClassesById';
import getChiefComplaintClassName from './getChiefComplaintClassName';

export default createSelector(
  getChiefComplaintClassName,
  getSymptomClassesById,
  (chiefComplaintClass, symptomClassesById) => symptomClassesById[chiefComplaintClass]
);
