import * as validations from './';
import store from 'app/lib/store';
import {
  markConsultNavigationStep,
  addErrorsToConsultNavigationStep,
  clearErrorsFromConsultNavigationStep
} from 'app/consult-navigation/actions';

const markAsIncomplete = index => store.dispatch(markConsultNavigationStep(index, false));

const markAsComplete = index => store.dispatch(markConsultNavigationStep(index, true));

const showSummaryBlocksForNavigationStep = jsValidation =>
  window.consultationInterview.summary.utils.showSummaryBlocksForNavigationStep(jsValidation);

const displayErrors = (selectedIndex, errors) => {
  const index = selectedIndex;
  if (index >= 0) {
    store.dispatch(addErrorsToConsultNavigationStep(index, errors));
  }
};

const clearErrors = selectedIndex => {
  const index = selectedIndex || store.getState().consultNavigation.selectedIndex;
  if (index >= 0) {
    store.dispatch(clearErrorsFromConsultNavigationStep(index));
  }
};

const validateConsultationStep = index => {
  if (index === null) {
    return Promise.resolve(false);
  } // zero is falsy, null is equivalent to zero
  const item = store.getState().consultNavigation.items[index] || {};
  // The jsValidation is fetched from graphQL, and defined on interview_navigation_items_web.yml or interview_navigation_items_mobile.yml
  const validator = validations[item.jsValidation];
  return validator().then(rawErrors => {
    const errors = rawErrors
      .filter(error => {
        if (!error) return false;
        if (typeof error === 'string') return true;

        const {
          errorContainerSelector,
          validator: { invalid, message }
        } = error;

        if (errorContainerSelector) {
          const validationErrorContainer = document.querySelector(errorContainerSelector);

          if (validationErrorContainer) {
            if (invalid) {
              validationErrorContainer.innerText = message;
              validationErrorContainer.setAttribute('data-show', true);
            } else {
              // show & empty validation container in a case there is no error
              validationErrorContainer.innerText = '';
              validationErrorContainer.setAttribute('data-show', false);
            }
          }
        }

        return invalid;
      })
      .map(error => {
        // in a case the error is just string (not an inline error)
        if (typeof error === 'string') return error;

        const {
          validator: { invalid, message, isInline },
          validatorKey
        } = error;

        if ((isInline || validatorKey) && invalid) {
          return {
            message,
            validatorKey
          };
        }

        return message;
      });

    if (errors && errors.length) {
      markAsIncomplete(index);
      displayErrors(index, errors);

      return Promise.resolve(false);
    } else {
      markAsComplete(index);
      showSummaryBlocksForNavigationStep(item.jsValidation);
      clearErrors(index);
      return Promise.resolve(true);
    }
  });
};

export default validateConsultationStep;
