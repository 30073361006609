import createFormReducer from '../../lib/redux/utilities/create-form-reducer';

import { DURATION_SEVERITY_PROGRESSION_FORM } from '../action-types';

export default createFormReducer({
  fields: [
    'duration_value',
    'duration_cd',
    'severity_cd',
    'severity_value',
    'progression_cd',
    'progression_value'
  ],
  dependentFields: {
    severity_cd:    'severity_value',
    progression_cd: 'progression_value'
  },
  formActionTypes: DURATION_SEVERITY_PROGRESSION_FORM
});
