import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { I18n, translateMarkupString } from '@td/shared_utils';
import AutoDialerModal from './modals/AutoDialerModal';
import { MODAL_TYPES } from '../constants/modal-types';
import { updateAlexaMessageShown, updateMemberNumberShown } from 'app/AutoDialer/v1/api';

const AutoDialerFooter = ({ consultationId, hidePhoneNumber, primaryPhone, secondaryPhone }) => {
  const [showMemberPhoneForAlexa, setShowMemberPhoneForAlexa] = useState(false);
  const [showMemberPhone, setShowMemberPhone] = useState(false);
  const [alexaMessageWasShown, setAlexaMessageWasShown] = useState(false);
  const [memberPhoneWasShown, setMemberPhoneWasShown] = useState(false);
  const [modalType, setModalType] = useState('');
  const handleClose = () => setModalType('');

  const toggleShowMemberPhoneFunc = () => (hidePhoneNumber ? toggleShowMemberPhoneForAlexa() : toggleShowMemberPhone());

  const toggleShowMemberPhone = () => {
    const showPhone = !showMemberPhone;

    setShowMemberPhone(showPhone);

    // Send reporting data only when member
    // number are shown and was never shown before
    if (showPhone && !memberPhoneWasShown) {
      setMemberPhoneWasShown(true);
      updateMemberNumberShown(consultationId);
    }
  };

  const toggleShowMemberPhoneForAlexa = () => {
    const showPhone = !showMemberPhoneForAlexa;

    setShowMemberPhoneForAlexa(showPhone);

    if (!showPhone) setShowMemberPhone(false);

    if (showPhone && !alexaMessageWasShown) {
      setAlexaMessageWasShown(true);
      updateAlexaMessageShown();
    }
  };

  return (
    <React.Fragment>
      <div className="auto-dialer-footer">
        <div className="auto-dialer-footer-help-container">
          <span className="auto-dialer-link" id="audio-test-link" onClick={() => setModalType(MODAL_TYPES.AUDIO_TEST)}>
            <I18n scope="autodialer.call_interface.footer" text="test_audio_cta" />
          </span>{' '}
          |{' '}
          <span className="auto-dialer-link" id="audio-faq-link">
            <a
              href="https://s3.amazonaws.com/communications.teladoc.com/resources/video_support/Provider_Audio_FAQ_Document.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              <I18n scope="autodialer.call_interface.footer" text="faq" />
            </a>
          </span>
        </div>
        <div className="auto-dialer-tech-issues">
          {translateMarkupString('autodialer.call_interface.fallbacks', 'troubleshooting_html', { b: <b /> })}
          {
            <div className="auto-dialer-link auto-dialer-show-member-phone" onClick={toggleShowMemberPhoneFunc}>
              {showMemberPhone || showMemberPhoneForAlexa ? (
                <I18n scope="autodialer.call_interface.fallbacks" text="hide_member_number" />
              ) : (
                <I18n scope="autodialer.call_interface.fallbacks" text="show_member_number" />
              )}
            </div>
          }
          {showMemberPhoneForAlexa && (
            <div>
              <p>{translateMarkupString('autodialer.call_interface.fallbacks.alexa', 'message', { b: <b /> })}</p>
              <br />
              <p>
                <I18n scope="autodialer.call_interface.fallbacks.alexa" text="show_member_number_pre" />
                <div className="auto-dialer-link auto-dialer-show-member-phone" onClick={toggleShowMemberPhone}>
                  <I18n scope="autodialer.call_interface.fallbacks.alexa" text="show_member_number_link" />
                </div>
              </p>
            </div>
          )}
          {showMemberPhone && (
            <div className="auto-dialer-tech-issues-phone-numbers">
              {primaryPhone && (
                <p>
                  <b>
                    <I18n scope="autodialer.call_interface.fallbacks" text="primary_phone" />
                  </b>
                  : {primaryPhone}
                </p>
              )}
              {secondaryPhone && (
                <p>
                  <b>
                    <I18n scope="autodialer.call_interface.fallbacks" text="secondary_phone" />
                  </b>
                  : {secondaryPhone}
                </p>
              )}
              {!primaryPhone && !secondaryPhone && (
                <p>
                  <I18n scope="misc" text="none" />
                </p>
              )}
              <p>
                <I18n scope="autodialer.call_interface.fallbacks" text="star_67_reminder" />
              </p>
            </div>
          )}
        </div>
      </div>
      <AutoDialerModal handleClose={handleClose} modalType={modalType} />
    </React.Fragment>
  );
};

AutoDialerFooter.propTypes = {
  consultationId:  PropTypes.number.isRequired,
  hidePhoneNumber: PropTypes.bool.isRequired,
  primaryPhone:    PropTypes.string,
  secondaryPhone:  PropTypes.string
};

AutoDialerFooter.displayName = 'AutoDialerFooter';

export default AutoDialerFooter;
