import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { I18n } from '@td/shared_utils';
import { convertPhoneToDigitsOnly, validateNumber } from '../utils';

const TransferCall = ({
  callCtaText,
  handleCallPhone,
  hidePhoneNumbers,
  primaryPhone,
  question,
  secondaryPhone,
  onCallJoin,
  setCtaDisabled
}) => {
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [disableInputs, setDisableInputs] = useState(false);
  const otherTelephoneRef = useRef(null);

  // Number validation: length, valid region etc.
  const validatePhoneInput = () => {
    if (!disableInputs) {
      const value = get(otherTelephoneRef, 'current.value', '');

      const phoneValidation = validateNumber(value, { defaultCountryCode: '1' });

      if (!phoneValidation.invalid) {
        setPhoneNumberError(false);
        setDisableInputs(true);
        setCtaDisabled(true);

        handleCallPhone(value);
      } else {
        setPhoneNumberError(true);
      }
    }
  };

  const handleCall = phoneNumber => {
    if (!disableInputs) {
      setDisableInputs(true);
      setCtaDisabled(true);

      handleCallPhone(convertPhoneToDigitsOnly(phoneNumber));
    }
  };

  const callCtaTextClassName = `ml-12 auto-dialer-link call-phone-link ${
    disableInputs ? 'call-phone-link--disabled' : ''
  }`;

  return (
    <div>
      <div className="call-phone-container">
        <b>{question}</b>
        <div className="auto-dialer-link call-phone-cancel-link" onClick={hidePhoneNumbers}>
          <I18n scope="autodialer.call_interface.call_in_progress" text="cancel" />
        </div>
      </div>
      {primaryPhone && (
        <div className="call-phone-container">
          <div>{primaryPhone}</div>
          <div className={callCtaTextClassName} onClick={() => handleCall(convertPhoneToDigitsOnly(primaryPhone))}>
            {callCtaText}
          </div>
        </div>
      )}
      {secondaryPhone && (
        <div className="call-phone-container">
          <div>{secondaryPhone}</div>
          <div className={callCtaTextClassName} onClick={() => handleCall(convertPhoneToDigitsOnly(secondaryPhone))}>
            {callCtaText}
          </div>
        </div>
      )}
      <div className="call-phone-container phone-input-container">
        <span>
          <I18n scope="autodialer.call_interface.fallbacks" text="other" />:{' '}
          <input
            ref={otherTelephoneRef}
            type="tel"
            className={`phone-input ${phoneNumberError ? 'phone-input-error' : ''}`}
            disabled={disableInputs}
            placeholder="(XXX)-XXX-XXXX"
            onKeyDown={() => setPhoneNumberError(false)}
          />
        </span>
        <div className={callCtaTextClassName} onClick={validatePhoneInput}>
          {callCtaText}
        </div>
      </div>
      {phoneNumberError && (
        <div className="phone-input-error-message">
          <I18n scope="autodialer.call_interface.call_in_progress" text="transfer_call_error_message" />
        </div>
      )}
    </div>
  );
};

TransferCall.propTypes = {
  callCtaText:      PropTypes.string.isRequired,
  handleCallPhone:  PropTypes.func.isRequired,
  hidePhoneNumbers: PropTypes.func.isRequired,
  primaryPhone:     PropTypes.string,
  question:         PropTypes.string.isRequired,
  secondaryPhone:   PropTypes.string
};

export default TransferCall;
