import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { authToken } from '@td/api';
import { useUpdater } from '@td/shared_utils';
import TeladocModal from '../../TeladocModal';
import ConsultationAttestationConfirm from './consultation-attestation-confirm';

const ConsultationAttestation = ({ consultationId, dataMessage, dataDismissSteps, dataShow, isModalOpen = false }) => {
  const [showModal, setShowModal] = useState(isModalOpen);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isConfirming, setConfirming] = useState(false);

  const handleToggleModal = () => {
    if (showModal) {
      setConfirming(false);
    }
    setShowModal(!showModal);
  };

  const cancelConsultationRequest = useUpdater('post', {
    url:     `/consultations/${consultationId}/cancel_consult`,
    method:  'patch',
    baseURL: window.location.origin,
    headers: {
      Authorization:  `${authToken.get()}`,
      Accept:         'text/javascript, application/javascript',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

  const handleSubmit = () => {
    setConfirming(true);
    setTimeout(() => {
      handleToggleModal();
    }, 1000);
  };

  const handleCancel = e => {
    e.preventDefault();
    setShowModal(false);
    setShowConfirmationDialog(true);
  };

  const handleConfirmDialogSubmit = e => {
    e.preventDefault();
    setConfirming(true);
    $('<input>')
      .attr({ type: 'hidden', name: 'consultation[reason_cd]' })
      .val('PRVCANCELRSN_NOCOMPLETIONATTESTATION')
      .appendTo($('#consult_form'));
    $('<input>')
      .attr({ type: 'hidden', name: 'save_data_on_cancel' })
      .val('true')
      .appendTo($('#consult_form'));
    cancelConsultationRequest.callApi($('#consult_form').serialize());
  };

  const handleConfirmDialogCancel = e => {
    if (e) {
      e.preventDefault();
    }
    setShowConfirmationDialog(false);
    setShowModal(true);
  };

  useEffect(() => {
    if (cancelConsultationRequest && cancelConsultationRequest.finished) {
      cancelConsultationRequest.reset();
      setConfirming(false);
      setShowConfirmationDialog(false);
      window.location.href = '/';
    }
  }, [cancelConsultationRequest]);

  const isSubmitting = (cancelConsultationRequest && cancelConsultationRequest.loading) || isConfirming;

  return (
    <div className="consultationAttestationWrapper">
      <input
        type="button"
        className="button openModalAction"
        value="Close visit and electronically sign"
        onClick={handleToggleModal}
        data-show={dataShow}
        data-dismiss-steps={dataDismissSteps}
        data-message={dataMessage}
      />
      <TeladocModal
        className="attestationModal"
        title="Consultation Attestation"
        isOpen={showModal}
        onClose={handleToggleModal}
        parentClassName="consultationAttestationWrapper"
      >
        <div className="notice">
          <p>
            If you cannot attest to ALL of the statements below, please return to the chart and update deficiencies or
            click “CANCEL VISIT” to cancel and this visit will return to the queue for another provider.
          </p>
        </div>
        <div className="attestationModal-content">
          <p className="explanation">By clicking “COMPLETE VISIT” you attest to ALL the conditions below:</p>
          <ol>
            <li>
              You have communicated with the patient identified on this visit note and provided a diagnosis, treatment
              plan, and anticipatory guidance.
            </li>
            <li>
              You have sufficiently documented in the visit note the patient’s clinical concerns and your assessment,
              medical decision making, diagnosis, and treatment plan.
            </li>
            <li>
              You understand selecting “COMPLETE VISIT” generates and submits bills (including patient copay) for this
              consult with you, under your NPI, as the rendering provider.
            </li>
          </ol>
        </div>
        <div className="actionButtons">
          <button
            id="cancelButton"
            key="cancelButton"
            className="secondary button"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {cancelConsultationRequest && cancelConsultationRequest.loading ? 'Submitting...' : 'Cancel Visit'}
          </button>
          <button
            id="confirmButton"
            key="confirmButton"
            className="button"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isConfirming ? 'Submitting...' : 'Complete Visit'}
          </button>
        </div>
      </TeladocModal>
      <ConsultationAttestationConfirm
        actionsDisabled={isSubmitting}
        isModalOpen={showConfirmationDialog}
        cancelCallback={handleConfirmDialogCancel}
        confirmCallback={handleConfirmDialogSubmit}
      />
    </div>
  );
};

ConsultationAttestation.propTypes = {
  consultationId:   PropTypes.number.isRequired,
  dataMessage:      PropTypes.string,
  dataDismissSteps: PropTypes.number,
  dataShow:         PropTypes.bool,
  isModalOpen:      PropTypes.bool
};

export default ConsultationAttestation;
