import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { get } from 'lodash';
import PROVIDER_SOLICITATION from '../queries/usc-provider-solicitation.graphql';
import SET_USC_COMMITMENT from '../mutations/set-usc-commitment.graphql';
import styles from '../styles.module.scss';
import pillIcon from './../../assets/images/pill-icon.svg';
import { OptionContainer, CommitmentOption } from './solicitation/commitment-options';
import Button from '../../components/Button';
import '../styles.scss';

const ProviderSolicitation = ({ providerID }) => {
  const solicitationName = 'Antibiotic stewardship';
  const submitButtonText = 'Submit and continue to dashboard';
  const [commitmentChoice, setCommitmentChoice] = useState('');
  const [providerSignature, setProviderSignature] = useState('');

  const [callUpdateCommitment, { data: updateCommitmentResponse }] = useMutation(SET_USC_COMMITMENT);

  const exitPrompt = () => {
    // after a screen refresh, provider will no longer see the solicitation
    window.location.reload();
  };

  useEffect(() => {
    if (updateCommitmentResponse && get(updateCommitmentResponse, 'createProviderStudyAgreement.success')) {
      exitPrompt();
    }
  }, [updateCommitmentResponse]);

  const { loading, error, data } = useQuery(PROVIDER_SOLICITATION, {
    variables: { providerID }
  });

  let enableExit = false;

  if (error || loading || !data) return null;
  if (data.provider.providerPrompts.providerSolicitation === null) return null;
  const {
    provider: {
      providerPrompts: {
        providerSolicitation: { title, content, accept, decline, signature }
      }
    }
  } = data;

  // Interpolate message to patients, paragraphs are separated by '|' in the db
  const messageToPatients = content.split('|').reduce(
    (acc, line, i) =>
      acc.concat(
        <p key={i} className={styles.p_msg}>
          {line}
        </p>
      ),
    []
  );

  if (providerSignature && commitmentChoice) {
    enableExit = true;
  }

  const handleCommitmentChange = event => {
    if (['Y', 'N'].includes(event.target.value)) setCommitmentChoice(event.target.value);
  };

  const handleSubmitCommitment = event => {
    event.preventDefault();
    if (enableExit) {
      callUpdateCommitment({ variables: { providerId: providerID, studyOptOutFlg: commitmentChoice } });
    }
  };

  const updateProviderSignature = event => {
    setProviderSignature(event.target.value);
  };

  return (
    <div className={`module ${styles.study_solicitation}`}>
      <div className={styles.header}>
        <img src={pillIcon} alt="pill" className={styles.blue_pill_icon} />
        <span>{solicitationName}</span>
      </div>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.message}>
        <h4>My message to patients:</h4>
        <div> {messageToPatients} </div>
      </div>
      <h4 className={styles.div_title}>Select one (required):</h4>
      <OptionContainer onChange={handleCommitmentChange} className={styles.options_container}>
        <CommitmentOption
          text={accept}
          returnTag="N" // N as in opt out=no
          className={styles.radio_container}
          classRadioIcon={styles.radio_icon}
        />
        <CommitmentOption
          text={decline}
          returnTag="Y" // Y as in opt out=yes
          className={styles.radio_container}
          classRadioIcon={styles.radio_icon}
        />
      </OptionContainer>
      <h4 className={styles.marginated}>Signature (required):</h4>
      <div className={styles.signature_header}>{signature}</div>
      <input
        type="text"
        onChange={updateProviderSignature}
        name="signatureInput"
        id="signature"
        className={styles.text_input}
      />
      <Button
        className={styles.button}
        onClick={handleSubmitCommitment}
        disabled={!enableExit}
        text={submitButtonText}
      />
    </div>
  );
};

ProviderSolicitation.propTypes = {
  providerID: PropTypes.number.isRequired
};

export default ProviderSolicitation;
