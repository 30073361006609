import React from 'react';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';
import cn from 'classnames';

import './Modal.scss';

const getApplicationNode = () => document.getElementsByClassName('consultations')[0];

const Modal = ({
  showCloseButton,
  showHeader,
  show,
  centered,
  children,
  className,
  onClose,
  titleText,
  modalBodyStyles = { width: 'auto' },
  ...rest
}) => {
  let options;
  if (!(rest.titleText || rest.titleId)) {
    options = Object.assign({}, rest, { titleText: 'Dialog' });
  } else {
    options = Object.assign({}, rest);
  }

  return (
    <AriaModal
      mounted={show}
      onExit={onClose}
      getApplicationNode={getApplicationNode}
      dialogClass={cn('modal-window', { 'centered-modal-window': centered }, className)}
      underlayClickExits={false}
      escapeExits={false}
      focusDialog
      {...options}
    >
      <div className="modal-content" style={modalBodyStyles}>
        {showHeader && (
          <header className="modal-header">
            {titleText && <h5>{titleText}</h5>}
            {showCloseButton && (
              <button className="header-close-btn" aria-labelledby="header-close-hint" onClick={onClose}>
                ✖
              </button>
            )}
            <span id="header-close-hint" className="header-close-hint">
              Close modal
            </span>
          </header>
        )}
        {children}
      </div>
    </AriaModal>
  );
};

Modal.propTypes = {
  show:            PropTypes.bool,
  showHeader:      PropTypes.bool,
  centered:        PropTypes.bool,
  children:        PropTypes.node.isRequired,
  className:       PropTypes.string,
  onClose:         PropTypes.func.isRequired,
  titleText:       PropTypes.string,
  showCloseButton: PropTypes.bool,
  fullScreenWidth: PropTypes.bool
};

Modal.defaultProps = {
  show:            false,
  centered:        false,
  showHeader:      true,
  showCloseButton: true
};

export default Modal;
