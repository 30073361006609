import React, { useContext, useState, useEffect } from 'react';
import Modal from 'app/components/Modal';

const ModalContext = React.createContext(null);

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);
  const [modalHtml, setModalHtml] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalProps, setModalProps] = useState({});

  const closeModal = () => {
    setShowModal(false);
    setModalProps({});

    setModalId(null);
    setModalTitle(null);
    setModalHtml(null);
    setModalContent(null);
  };

  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    setImmediate(() => {
      const closeModalButton = document.querySelector(`#${modalId} .modal-close-button`);

      if (closeModalButton) {
        closeModalButton.addEventListener('click', event => {
          event.preventDefault();

          closeModal();
        });
      }
    });
  }, [modalId]);

  const contextValue = {
    showModal:       handleShowModal,
    closeModal,
    setModalContent: ({
      closeButtonSelector,
      modalId: newModalId,
      modalProps = {},
      content = null,
      html = null,
      title = null
    }) => {
      const noContent = !content && !html;

      if (!newModalId || noContent) return;

      setModalContent(content);
      setModalHtml(html);

      setShowModal(true);
      setModalTitle(title);
      setModalId(newModalId);
      setModalProps(modalProps);
    }
  };

  return (
    <ModalContext.Provider value={contextValue}>
      <Modal
        centered
        showCloseButton
        fullScreenWidth={false}
        id={modalId}
        onClose={closeModal}
        show={showModal}
        {...modalProps}
      >
        {modalId && (
          <div id={modalId}>
            {modalTitle && <h2>{modalTitle}</h2>}

            {modalContent}
            {modalHtml && <div dangerouslySetInnerHTML={{ __html: modalHtml }} />}
          </div>
        )}
      </Modal>

      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const modal = useContext(ModalContext);

  if (modal === null) {
    throw new Error('Your component must be wrapped in ModalProvider in order to use its context.');
  }

  return modal;
};
