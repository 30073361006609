import { CREATE_NOTIFICATION } from './action-types';

export const success = (message, options = {}) => ({
  type:    CREATE_NOTIFICATION,
  payload: { message, type: 'success', options }
});

export const error = message => ({
  type:    CREATE_NOTIFICATION,
  payload: {
    message,
    type:    'error',
    options: {
      timeOut:         0,
      extendedTimeout: 0,
      closeButton:     true
    }
  }
});
