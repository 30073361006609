import { translate } from '@td/shared_utils';
import React from 'react';
import './ConsultationSchedulingSuccess.scss';
import moment from 'moment-timezone';

const ConsultationSchedulingSuccess = ({
  data: { message, btnText, visitDate, backupDates },
  handleSuccess,
  payload
}) => {
  const { consultation } = payload;

  const formattedDate = utcDateTimeString => {
    const inputDateTimeUTC = moment.utc(utcDateTimeString);
    const browserTimeZone = moment.tz.guess();
    const inputDateTimeBrowser = inputDateTimeUTC.clone().tz(browserTimeZone);
    const outputString = inputDateTimeBrowser.format('MM/DD/YYYY h:mm A z');
    return outputString;
  };

  return (
    <div className="consultationSchedulingSuccessWrapper">
      <div className="subHeading">
        <p className="subHeadingText">{message}</p>
      </div>
      <div className="dateAndTimesContainer">
        <div className="visitDateAndTime">
          <div className="visitDateAndTimeLabel">
            {translate(null, 'member_availability.schedule_modal', 'visit_date_and_time')}
          </div>
          <div className="visitDateAndTime">{formattedDate(visitDate)}</div>
        </div>
        <div className="backupDatesAndTimeContainer">
          <div className="backupDatesAndTime">
            <div className="backupDatesAndTimeLabel">
              {translate(null, 'member_availability.schedule_modal', 'backup_dates_and_time')}
            </div>

            <div className="backupDatesAndTimes">
              {backupDates && backupDates.map((date, index) => <div key={index}>{formattedDate(date)}</div>)}
            </div>
          </div>
        </div>
        <div className="noteToPatientContainer">
          <div className="noteToPatientLabel">
            {translate(null, 'member_availability.schedule_modal.visit_scheduled_modal', 'note_to_patient')}
          </div>
          <div className="noteToPatient">{consultation.scheduling_notes}</div>
        </div>
        <a className="button confirm" onClick={handleSuccess}>
          {btnText}
        </a>
      </div>
    </div>
  );
};

export default ConsultationSchedulingSuccess;
