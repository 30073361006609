import moment from 'moment';
import { ajax } from 'rxjs/ajax';
import _ from 'lodash';
import { logError } from '@td/shared_utils';

// TODO: Delegate this to TAS
const calculateAge = dateString => moment().diff(dateString, 'years');

export const isPatientMinor = dob => calculateAge(dob) < 18;

// A uid generator function that doesn't have the guarantees of UUID.
// Only meant for keying objects uniquely, please don't use it for anything with security implications.
// https://gist.github.com/jed/982883
export const uid = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, a => (a ^ ((Math.random() * 16) >> (a / 4))).toString(16));

export const redirectToRelative = path => {
  $('body').append(
    '<div class="blocking-container"><img id="tdspinner" src="/assets/ajax_loader_66.gif" alt="Loading..." ></div>'
  );

  window.location.replace(window.location.origin + path);
};

export const redirectToHome = () => redirectToRelative('/');

export const createCsrfHeaders = () => ({
  'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  ...window.TELADOC_ENVELOP
});

export const getTimeToAlert = (referenceDate, alertTiming) => {
  const referenceDateMoment = moment(referenceDate).isValid() ? moment(referenceDate) : moment.now();

  return Math.max(0, alertTiming - (moment.now() - referenceDateMoment));
};

export const fetchOrigin$ = url => ajax({ url, headers: window.TELADOC_ENVELOP });

export const $rollout = {
  get: toggleName => {
    const featureToggles = window.FEATURE_TOGGLES_DATA;
    const toggle = featureToggles[toggleName];

    const active = () => _.get(featureToggles, `[${toggleName}].enabled`, false);
    const data = active() ? _.get(featureToggles, `[${toggleName}].data`, {}) : {};

    if (!toggleName) {
      logError({}, '[js $rollout] toggleName was not specified');
    }

    if (toggle === undefined) {
      logError({}, `[js $rollout] feature toggle '${toggleName}' was not found in the toggles list`);
    }

    return {
      name: toggleName,
      active,
      data
    };
  },
  active: toggleName => {
    const featureToggles = window.FEATURE_TOGGLES_DATA;

    if (!toggleName) {
      logError({}, '[js $rollout.active] toggleName was not specified');
    }

    if (featureToggles[toggleName] === undefined) {
      logError({}, `[js $rollout.active] feature toggle '${toggleName}' was not found in the toggles list`);
    }

    return _.get(featureToggles, `[${toggleName}].enabled`, false);
  }
};

export function getTimeToNextRefresh(defaultRefresh) {
  if (window.td && window.td.globals && window.td.globals.requestRefreshInterval) {
    return window.td.globals.requestRefreshInterval * 1000;
  } else {
    return defaultRefresh * 1000;
  }
}

export function getDelay(defaultDelay) {
  if (window.td && window.td.globals && window.td.globals.requestInitialDelay) {
    return window.td.globals.requestInitialDelay * 1000;
  } else {
    return defaultDelay;
  }
}

export const camelizeDeep = obj =>
  _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);
    acc[camelKey] = _.isObject(value) ? camelizeDeep(value) : value;
  });

export const calculateAgeByBirthday = birthday => {
  const birthDate = new Date(birthday);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
    age--;
  }

  return age;
};
