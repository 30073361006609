import React, { useState, useContext, createContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { DEFAULT_SORTING_COLUMN, DEFAULT_SORTING_DIRECTION, DEFAULT_FILTERS } from './constants';
import { isEqual } from 'lodash';
import FETCH_PATIENT_LIST from './patient-list.graphql';

const PatientListContext = createContext();

export const usePatientList = () => useContext(PatientListContext);

const PatientListProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(DEFAULT_SORTING_COLUMN);
  const [sortDirection, setSortDirection] = useState(DEFAULT_SORTING_DIRECTION);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const hasNextPage = useMemo(() => (data ? data.collection.length < data.metaData.totalCount : false), [data]);

  const handlePaginationChange = () => {
    setLoading(true);
    setCurrentPage(currentPage + 1);
  };

  const handleSortChange = (newSortColumn, newSortDirection) => {
    if (newSortColumn === sortColumn && newSortDirection === sortDirection) return;

    setLoading(true);
    setData(undefined);
    setCurrentPage(1);
    setSortColumn(newSortColumn);
    setSortDirection(newSortDirection);
  };

  const handleFiltersChange = newFilters => {
    if (isEqual(newFilters, filters)) return;

    setLoading(true);
    setData(undefined);
    setCurrentPage(1);
    setFilters(newFilters);
  };

  useQuery(FETCH_PATIENT_LIST, {
    variables: {
      page:         currentPage,
      searchParams: filters,
      sortParams:   { field: sortColumn, order: sortDirection }
    },
    fetchPolicy: 'network-only',
    onCompleted: response => {
      setData(prev => {
        const patientList = response.currentUserProvider.patientList;
        if (!prev) return patientList;
        return {
          ...patientList,
          collection: [...prev.collection, ...patientList.collection]
        };
      });
      setLoading(false);
    },
    onError: _ => setLoading(false)
  });

  const state = {
    currentPage,
    sortColumn,
    sortDirection,
    filters,
    data,
    loading,
    hasNextPage,
    handlePaginationChange,
    handleSortChange,
    handleFiltersChange
  };

  return <PatientListContext.Provider value={state}>{children}</PatientListContext.Provider>;
};

export default PatientListProvider;
