import moment from 'moment-timezone';

export const BROWSER_TIMEZONE = moment.tz.guess();

export const getDayOfTheWeek = date =>
  moment(date)
    .format('dddd')
    .toLowerCase();

export const timeToMinutes = time => time.hours() * 60 + time.minutes();

export const isWithinDisabledTime = (role, isInitial) => {
  return role === 'psychiatrist' && !isInitial ? 15 : 45;
};

export const timeIsBetween = (time, start, end, disabledTime = 0) => {
  const timeValue = timeToMinutes(time);
  const startTimeInMinutes = timeToMinutes(start);
  const endTimeInMinutes = timeToMinutes(end);

  if (startTimeInMinutes <= endTimeInMinutes) {
    return timeValue >= startTimeInMinutes && timeValue <= (endTimeInMinutes - disabledTime);
  } else {
    return timeValue >= startTimeInMinutes || timeValue <= (endTimeInMinutes - disabledTime);
  }
};

export const isDateAfter24Hours = date => {
  const oneDayLater = moment().add(1, 'days');
  return moment(date).isAfter(oneDayLater);
};

export const isTimeInNext48Hours = time => {
  if (!isDateAfter24Hours(time)) return false;

  const currentTime = moment();
  const timeAfter48Hours = moment().add(48, 'hours');
  return moment(time).isBetween(currentTime, timeAfter48Hours);
};

export const dayIsIncludedInMemberAvailability = (day, memberAvailabilityData) =>
  Object.keys(memberAvailabilityData).includes(day);

export const dateIsIncludedInMemberAvailability = (date, memberAvailabilityData) =>
  dayIsIncludedInMemberAvailability(getDayOfTheWeek(date), memberAvailabilityData);

export const gapBetweenSlots = (index, totalSlots, currentRange, nextRange) => {
  if (index + 1 < totalSlots) {
    const endTimeOfCurrentRange = currentRange.endTime;
    const startTimeOfNextRange = nextRange.startTime;

    return endTimeOfCurrentRange.isBefore(startTimeOfNextRange);
  }
};
