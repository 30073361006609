import { authToken } from '@td/api';
import { get } from 'lodash';

import axios from 'axios';

const axiosInstance = axios.create();
// added in order to define the request as XHR (request.xhr? in rails) to prevent issue with redirects handling
axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default ({ cancelUrl, data, onSuccess, onError }) => {
  axiosInstance.patch(
    cancelUrl,
    data,
    {
      ...authToken.get(),
      baseURL: window.location.origin
    }
  ).then(response => {
    if (typeof onSuccess === 'function') onSuccess(response);
  }).catch(error => {
    if (typeof onError === 'function') onError(get(error, 'response') || {});
  });
};
