import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

import { useQuickDx } from '../api';
import {
  useCachedDiagnosis,
  useDiagnosesConfig,
  useQuickDxFavorites,
  checkIfDxMatchReason,
  diagnosisHintParams,
} from '../utils';
import { I18n } from '@td/shared_utils';
import { SelectedDxChip } from './SelectedDxChip';
import { SearchDxBar } from './SearchDxBar';
import { ShortcutsList } from './ShortcutsList';
import { getConsultationSpecialty } from '../utils/getConsultationType';
import store from 'app/lib/store';
import {
  addNewItemToSecondaryDiagnosesList,
  removeItemAtIndexFromSecondaryDiagnosesList
} from 'app/components/SecondaryDiagnosesContainer/redux/actions';
import {
  setPrimaryDiagnosis,
  clearPrimaryDiagnosis
} from '../redux/actions';

import './styles/QuickDxSearchBar.scss';

export const QuickDxSearchBar = props => {
  const { info: diagnosisConfig, raw } = useDiagnosesConfig();
  const { complaintCd, diagnosisHintRequired, hintSubScope } = diagnosisHintParams();

  const MAXIMUM_DIAGNOSIS_AMOUNT = diagnosisConfig.secondaryDiagnosis.maxItems;

  const [searchTerm, setSearchTerm] = useState('');
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const [selectedDx, setSelectedDx] = useState([]);

  const { selectedDxCodes, shortcutsList } = useQuickDxFavorites();
  const [searchTermDebounced] = useDebounce(searchTerm, 600);
  const cachedDiagnosis = useCachedDiagnosis();

  const { label, inputPlaceholder } = props;

  const maximumDiagnosisAmount = props.multipleDiagnosises ? MAXIMUM_DIAGNOSIS_AMOUNT : 1;

  const canAddOneMoreDiagnosis = maximumDiagnosisAmount >= selectedDx.length + 1;
  const quickDxSearch = useQuickDx({ searchTerm: searchTermDebounced });

  useEffect(() => {
    const itemsToPush = selectedDx
      .map(({ diagnosisCode }) => diagnosisCode)
      .filter(code => !selectedDxCodes.list.includes(code));

    selectedDxCodes.set([...selectedDxCodes.list, ...itemsToPush]);
    const {
      consultNavigation: { selectedIndex }
    } = store.getState();

    if (props.isPrimaryDiagnosis && getConsultationSpecialty() === 'VPC') {
      checkIfDxMatchReason(selectedIndex, selectedDx);
    }
  }, [selectedDx.length]);

  const removeDiagnosis = diagnose => {
    const diagnoseIndex = selectedDx.findIndex(({ diagnosisCode }) => diagnosisCode === diagnose.diagnosisCode);

    if (!props.isPrimaryDiagnosis) {
      store.dispatch(removeItemAtIndexFromSecondaryDiagnosesList({ index: diagnoseIndex }));
    } else {
      store.dispatch(clearPrimaryDiagnosis());
    }

    setSelectedDx(selectedDx.filter((_, index) => diagnoseIndex !== index));
    selectedDxCodes.remove(diagnose.diagnosisCode);
  };

  const handleDiagnoseSelect = (diagnose, index = selectedDx.length + 1) => {
    if (!diagnose.active) return;

    const fitsMaximumDiagnosisesNumber = amount => amount < maximumDiagnosisAmount;
    const alreadySelected = selectedDxCodes.list.includes(diagnose.diagnosisCode);

    if (!fitsMaximumDiagnosisesNumber(selectedDx.length) || alreadySelected) return;

    const newItem = {
      text:        `${diagnose.diagnosisCode} ${diagnose.diagnosisName}`,
      id:          diagnose.diagnosisCodeId,
      code:        diagnose.diagnosisCode,
      description: diagnose.diagnosisName,
      index
    };

    if (!props.isPrimaryDiagnosis) {
      store.dispatch(addNewItemToSecondaryDiagnosesList({ newItem }));
    } else {
      store.dispatch(setPrimaryDiagnosis({ diagnosis: newItem }));
    }

    if (!fitsMaximumDiagnosisesNumber(selectedDx.length + 1)) {
      setSearchIsFocused(false);
      setSearchTerm('');
    }

    setSelectedDx([...selectedDx, diagnose]);

    selectedDxCodes.add(diagnose.diagnosisCode);
  };

  useEffect(() => {
    if (props.isPrimaryDiagnosis) {
      const { primary } = cachedDiagnosis;

      if (primary) handleDiagnoseSelect(primary);

      return () => removeDiagnosis(primary);
    }

    const { secondary } = cachedDiagnosis;

    secondary.forEach((diagnose, cachedDiagnosisIndex) => handleDiagnoseSelect(diagnose, cachedDiagnosisIndex));
    setSelectedDx(secondary);

    return () => {
      secondary.forEach((diagnose, cachedDiagnosisIndex) => removeDiagnosis(diagnose, cachedDiagnosisIndex));
      setSelectedDx([]);
    };
  }, []);

  return (
    <div className="quick-dx" data-section-name={props.sectionName}>
      <div className="label">{label}</div>
      {diagnosisHintRequired && (
        <span className="diagnosis-hint">
          <I18n scope={`quick_dx.diagnosis_hint.${complaintCd}`} text={hintSubScope(props.isPrimaryDiagnosis)} />
        </span>
      )}
      <div className="selected-dxs-chips-list">
        {selectedDx.map((diagnose, index) => (
          <SelectedDxChip
            key={diagnose.diagnosisCode}
            diagnosis={diagnose}
            index={index}
            isPrimaryDiagnosis={props.isPrimaryDiagnosis}
            onRemove={() => removeDiagnosis(diagnose)}
            isFavorite={diagnose.isQuickDx}
          />
        ))}
      </div>

      {canAddOneMoreDiagnosis && (
        <React.Fragment>
          <SearchDxBar
            {...props}
            searchTerm={{ actual: searchTerm, debounced: searchTermDebounced }}
            selectedDiagnoses={selectedDx}
            searchIsFocused={searchIsFocused}
            setSearchIsFocused={setSearchIsFocused}
            dxData={quickDxSearch}
            onSearchTermChange={newSearchTerm => setSearchTerm(newSearchTerm)}
            searchQuery={searchTerm}
            onDiagnoseSelect={handleDiagnoseSelect}
            isInvalid={Boolean(props.validationError)}
          />

          {props.validationError && (
            <div className="interview_inline_validation_error" id="diagnosis_validation_error" data-show="true">
              {props.validationError.message}
            </div>
          )}

          {props.isPrimaryDiagnosis && (
            <ShortcutsList
              list={shortcutsList}
              removeDiagnosis={removeDiagnosis}
              handleDiagnoseSelect={handleDiagnoseSelect}
              selectedDx={selectedDx}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

QuickDxSearchBar.displayName = 'QuickDxSearchBar';

QuickDxSearchBar.propTypes = {
  multipleDiagnosises: PropTypes.bool,
  label:               PropTypes.string.isRequired,
  inputPlaceholder:    PropTypes.string.isRequired
};
