import React from 'react';
import { useCallStatus } from '../../CallStatusProvider';
import { I18n } from '@td/shared_utils';
import styles from './styles.module.scss';

const CallNotification = () => {
  const { attendees } = useCallStatus();

  const resolveRoleCd = roleCd => roleCd.replace(/^(tdoc\_)/, '');

  const memberJoined = attendees.find(
    attendee => resolveRoleCd(attendee.roleCd) === 'member' && attendee.status === 'JOINED'
  );
  const nurseJoined = attendees.find(
    attendee =>
      (resolveRoleCd(attendee.roleCd) === 'admin_ma' || resolveRoleCd(attendee.roleCd) === 'admin_rn') &&
      attendee.status === 'JOINED'
  );

  return (
    <div className={styles.callNotification}>
      {memberJoined && !nurseJoined && <I18n scope="call_details.notifications" text="only_member" />}

      {nurseJoined && !memberJoined && <I18n scope="call_details.notifications" text="only_nurse" />}

      {memberJoined && nurseJoined && <I18n scope="call_details.notifications" text="member_and_nurse" />}
    </div>
  );
};

export default CallNotification;
