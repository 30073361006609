import { get, includes, chain, find, isNil, thru } from 'lodash';

const popupForSubjectiveTabSelector = state => {
  try {
    const consultNavigationItems = state.consultNavigation.items;

    return chain(consultNavigationItems)
      .find(item => includes('complaints', item.key))
      .get('components')
      .thru(jsonString => {
        if (isNil(jsonString)) {
          return { view_best_practice_popup: { meta: {} } };
        } else {
          return JSON.parse(jsonString);
        }
      }) // "{"view_best_practice_popup":{"display":true, meta: {"outside_popup_list":[],"inside_popup_list":[], "popup_text": "text"} }}"
      .get('view_best_practice_popup')
      .value(); // { display: true, meta: { outside_popup_list: [], inside_popup_list: [], popup_text: "text" } }
  } catch (e) {
    return {};
  }
};

export const metaOfPopupForSubjectiveTabSelector = state => {
  const metaData = popupForSubjectiveTabSelector(state).meta;

  if (isNil(metaData)) {
    return {
      meta: {
        outside_popup_list: [],
        inside_popup_list:  [],
        popup_text:         ''
      }
    };
  }

  return metaData;
};

export const displayPopupForSubjectiveTabSelector = state => popupForSubjectiveTabSelector(state).display;
export const outsidePopupListForSubjectiveTabSelector = state =>
  metaOfPopupForSubjectiveTabSelector(state).outside_popup_list;
export const insidePopupListForSubjectiveTabSelector = state =>
  metaOfPopupForSubjectiveTabSelector(state).inside_popup_list;
export const popupTextForSubjectiveTabSelector = state => metaOfPopupForSubjectiveTabSelector(state).popup_text;
