import React, { useState, Fragment } from 'react';
import ScheduleConsultationMemberAvailability from '../../../schedule-pop-up/ScheduleConsultationMemberAvailability';
import ScheduleAction from '../actions/ScheduleAction';
import ActionsContainer from '../ActionsContainer';

const MemberAvailabilityFlowNegotiation = ({ memberAvailability, consultationId, timezone, memberId, isInitial }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);

  return (
    <Fragment>
      <ActionsContainer>{[<ScheduleAction key="ma-schedule" openModal={openModal} />]}</ActionsContainer>
      {isModalOpen && (
        <ScheduleConsultationMemberAvailability
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          memberAvailability={memberAvailability}
          timezone={timezone}
          consultationId={consultationId}
          memberId={memberId}
          isInitial={isInitial}
        />
      )}
    </Fragment>
  );
};

export default MemberAvailabilityFlowNegotiation;
