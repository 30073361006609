import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '../../assets/images/new-info-icon.svg';
import StatusTooltipContent from '../../patient-list/StatusTooltipContent/StatusTooltipContent';

export const sortOrderTypes = {
  ASC:  'asc',
  DESC: 'desc'
};

const DataTableSortButton = React.memo(({ id, sortColumn, sortDirection, onClick, children, tableName }) => {
  const handleClick = useCallback(
    currentDirection => {
      onClick(id, currentDirection);
    },
    [id, sortColumn, sortDirection, onClick]
  );

  const ascIconClass = classnames('sort-icon', {
    unselected:           id !== sortColumn,
    'partially-selected': id === sortColumn && sortDirection !== sortOrderTypes.ASC,
    selected:             id === sortColumn && sortDirection === sortOrderTypes.ASC
  });

  const descIconClass = classnames('sort-icon', 'bottom-icon', {
    unselected:           id !== sortColumn,
    'partially-selected': id === sortColumn && sortDirection !== sortOrderTypes.DESC,
    selected:             id === sortColumn && sortDirection === sortOrderTypes.DESC
  });

  return (
    <div className="header-container">
      <div className="header-title">{children}</div>
      {tableName === 'PatientList' && children === 'Status' && (
        <React.Fragment>
          <img
            data-place="top"
            data-tip
            src={InfoIcon}
            className="status-info-icon"
            data-for="status-definition-tooltip"
          />
          <ReactTooltip id="status-definition-tooltip" effect="solid" place="top" class="info-tooltip-for-statuses">
            <StatusTooltipContent />
          </ReactTooltip>
        </React.Fragment>
      )}
      <div className="header-icons">
        <FaCaretUp size={16} onClick={() => handleClick(sortOrderTypes.ASC)} className={ascIconClass} />
        <FaCaretDown onClick={() => handleClick(sortOrderTypes.DESC)} className={descIconClass} size={16} />
      </div>
    </div>
  );
});

DataTableSortButton.propTypes = {
  id:            PropTypes.string.isRequired,
  sortColumn:    PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onClick:       PropTypes.func.isRequired,
  children:      PropTypes.node.isRequired,
  tableName:     PropTypes.string
};

export default DataTableSortButton;
