import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { I18n } from '@td/shared_utils';

import { AutoDialerV2 } from '@teladoc/auto_dialer';

import { useAutoDialer, useCallInProgressProps } from '../utils';
import { ATTENDEE_STATUSES, CONFERENCE_STATUSES, DIALING_STATUSES } from '../constants/call-status';

const { TransferCall, RetryCall, DialingMember } = AutoDialerV2;

const CallInProgress = () => {
  const [showPhoneNumbers, setShowPhoneNumbers] = useState(false);
  const [ctaDisabled, setCtaDisabled] = useState(false);

  const {
    memberCallData: { currentStatusCode: memberCallStatus },
    currentAttendeeCallData: { currentStatusCode: currentAttendeeCallStatus },
    isMicrophoneAllowed,
    callStatus,
    startAutodial,
    toggleMute,
    connectedViaPhone,
    isMuted
  } = useAutoDialer();

  // Re-enable the CTA whenever the callStatus or currentAttendeeCallStatus changes
  useEffect(() => {
    setCtaDisabled(false);
  }, [callStatus, currentAttendeeCallStatus]);

  const {
    isError,
    disableTransferToPhone,
    componentProps: {
      buttonCta,
      buttonCtaHandler,
      callCta,
      errorMessage,
      joinByPhone,
      joinByPhoneCta,
      phoneQuestion,
      title
    }
  } = useCallInProgressProps({ setCtaDisabled, showPhoneNumbers });

  const showMuteButton =
    !connectedViaPhone &&
    isMicrophoneAllowed &&
    callStatus === CONFERENCE_STATUSES.IN_PROGRESS &&
    currentAttendeeCallStatus === ATTENDEE_STATUSES.JOINED;

  return (
    <div>
      <div>
        <h1 className="auto-dialer-title-text text-center">{title}</h1>

        {memberCallStatus === ATTENDEE_STATUSES.DISCONNECTED && (
          <RetryCall
            handleClick={() => {
              startAutodial();
            }}
          />
        )}

        {DIALING_STATUSES.includes(memberCallStatus) && (
          <div className="auto-dialer-dialing-container">
            <DialingMember />
          </div>
        )}

        <div className="auto-dialer-button-container">
          {showMuteButton && (
            <button
              className={classnames('button', 'auto-dialer-button', isError && 'auto-dialer-button--disabled')}
              disabled={isError}
              onClick={toggleMute}
            >
              {isMuted ? (
                <I18n scope="autodialer.call_interface.call_in_progress" text="unmute" />
              ) : (
                <I18n scope="autodialer.call_interface.call_in_progress" text="mute" />
              )}
            </button>
          )}

          {buttonCta && (
            <button
              className={classnames(
                'button',
                'auto-dialer-button',
                (isError || ctaDisabled) && 'auto-dialer-button--disabled'
              )}
              disabled={isError || ctaDisabled}
              onClick={buttonCtaHandler}
            >
              {buttonCta}
            </button>
          )}
        </div>

        <div className="text-center">
          {connectedViaPhone ? (
            <p>
              <I18n scope="autodialer.call_interface.call_in_progress" text="connected_via_phone" />
            </p>
          ) : (
            <React.Fragment>
              {joinByPhone}
              <span
                disabled={disableTransferToPhone}
                className={classnames(
                  disableTransferToPhone ? 'auto-dialer-link--disabled' : 'auto-dialer-link',
                  'margin-left'
                )}
                onClick={disableTransferToPhone ? null : () => setShowPhoneNumbers(true)}
              >
                {joinByPhoneCta}
              </span>
            </React.Fragment>
          )}
        </div>
      </div>

      {showPhoneNumbers && (
        <TransferCall
          callCtaText={callCta}
          hidePhoneNumbers={() => setShowPhoneNumbers(false)}
          question={phoneQuestion}
          setCtaDisabled={setCtaDisabled}
        />
      )}

      {errorMessage && (
        <div className="error">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default CallInProgress;
