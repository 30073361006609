import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ErxErrors from './erx-errors';
import PatientFetchSyncErrors from './patient-fetch-sync-errors-container';
import { erxErrorsInit } from '../actions';
import './styles.scss';
import getRemedyApiUnavailable from '../reducers/selectors/get-remedy-api-unavailable';
import getPharmacyNcpdpIdMissing from '../reducers/selectors/get-pharmacy-ncpdp-id-missing';

export class ErxErrorsContainer extends Component {
  componentDidMount() {
    const {
      cachedErxErrors: {
        prescriptions_submission_errors: prescriptionsSubmissionErrors
      }
    } = this.props;

    this.props.erxErrorsInit({
      prescriptionsSubmissionErrors
    });
  }

  render() {
    const {
      consultationId,
      patientSyncError,
      prescriptionsSubmissionErrors,
      remedyApiUnavailable,
      pharmacyNcpdpIdMissing
    } = this.props;

    return (
      <span>
        <PatientFetchSyncErrors consultationId={consultationId} />
        <ErxErrors
          patientSyncError={patientSyncError}
          prescriptionsSubmissionErrors={prescriptionsSubmissionErrors}
          remedyApiUnavailable={remedyApiUnavailable}
          pharmacyNcpdpIdMissing={pharmacyNcpdpIdMissing}
        />
      </span>
    );
  }
}

ErxErrorsContainer.propTypes = {
  consultationId:  PropTypes.number.isRequired,
  cachedErxErrors: PropTypes.shape({
    prescriptions_submission_errors: PropTypes.array
  }),
  erxErrorsInit:                 PropTypes.func.isRequired,
  patientSyncError:              PropTypes.string.isRequired,
  prescriptionsSubmissionErrors: PropTypes.array.isRequired,
  remedyApiUnavailable:          PropTypes.bool.isRequired,
  pharmacyNcpdpIdMissing:        PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  patientSyncError:              state.erxErrors.errors.patientSyncError,
  prescriptionsSubmissionErrors: state.erxErrors.errors.prescriptionsSubmissionErrors,
  remedyApiUnavailable:          getRemedyApiUnavailable(state),
  pharmacyNcpdpIdMissing:        getPharmacyNcpdpIdMissing(state)
});

const mapDispatchToProps = {
  erxErrorsInit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErxErrorsContainer);
