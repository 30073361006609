import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { get, flatMap, reject } from 'lodash';
import { translate } from '@td/shared_utils';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/special-working-hours-modal.scss';

// Components
import TeladocModal from '../../TeladocModal';
import Notification from './notification';

// Constants and helpers
import { scheduleTypes } from '../constants';
import { checkEventsOverlap, getRecurringDates, isValidDate, separateDatesAndTimes } from '../helpers';

const TRANSLATION_SCOPE = 'my_schedule.modals.special_working_hours';

// Icons
const trashIcon = require('../../assets/images/event_details/trash_icon_red.svg');

const SpecialWorkingHoursModal = ({
  isSubmitting,
  onModalClose,
  onSubmit,
  onDelete,
  event,
  specialWorkingHours,
  regularWorkingHours,
  personalEvents
}) => {
  /*
   * States
   */
  const initDate = {
    startDateTime: moment(
      get(
        event,
        'startDateTime',
        moment()
          .startOf('day')
          .set('hour', 8)
      )
    ).toDate(),
    endDateTime: moment(
      get(
        event,
        'endDateTime',
        moment()
          .startOf('day')
          .set('hour', 9)
      )
    ).toDate()
  };
  const [notification, setNotification] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(initDate);

  const [eventName, setEventName] = useState(get(event, 'eventName', ''));
  const [availabilityOverrideFlg, setAvailabilityOverrideFlg] = useState(get(event, 'availabilityOverrideFlag', false));

  /*
   * Callbacks
   */
  const verifyAndSave = () => {
    const separatedDateTime = separateDatesAndTimes(selectedDateTime);

    const isValidSelectedDate = isValidDate(separatedDateTime);
    if (!isValidSelectedDate) {
      return setNotification({
        type:    'error',
        message: translate(null, 'my_schedule.notifications', 'end_time_after_start_time')
      });
    }

    const personalEventsOverlap = checkEventsOverlap(personalEvents, separatedDateTime);
    if (personalEventsOverlap) {
      return setNotification({
        type:    'error',
        message: translate(null, 'my_schedule.notifications', 'personal_events_overlap')
      });
    }

    const rawRegularWorkingHours = flatMap(regularWorkingHours)
      .map(getRecurringDates)
      .flat();
    const filteredSpecialWorkingHours = reject(
      specialWorkingHours,
      specialWorkingHour =>
        event && event.providerScheduleId && specialWorkingHour.providerScheduleId === event.providerScheduleId
    );

    const regularWorkingHoursOverlap = availabilityOverrideFlg
      ? false
      : checkEventsOverlap(rawRegularWorkingHours, separatedDateTime);
    const specialWorkingHoursOverlap = checkEventsOverlap(filteredSpecialWorkingHours, separatedDateTime);

    if (specialWorkingHoursOverlap || regularWorkingHoursOverlap) {
      return setNotification({
        type:    'error',
        message: translate(null, 'my_schedule.notifications', 'working_hours_overlap')
      });
    }

    const { startDateTime, endDateTime } = selectedDateTime;

    const atbEvent = {
      eventName,
      eventTypeCode: scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode,
      startDateTime: moment(startDateTime),
      endDateTime:   moment(endDateTime),
      availabilityOverrideFlg
    };

    onSubmit(atbEvent);
  };

  const handleDatePickerChange = ({ date, type }) => {
    const keyToUpdate = type.substring(0, 3) === 'end' ? 'endDateTime' : 'startDateTime';
    const isDate = type.slice(-4) === 'Date';
    const dateMoment = moment(date);

    setSelectedDateTime(prevSelectedDateTime => {
      const updateDateTime = (isDateKey, valueToUpdate) =>
        isDateKey
          ? moment(valueToUpdate)
              .set({ year: dateMoment.year(), month: dateMoment.month(), date: dateMoment.date() })
              .seconds(0)
              .milliseconds(0)
              .toDate()
          : moment(valueToUpdate)
              .set({ hours: dateMoment.hours(), minutes: dateMoment.minutes() })
              .seconds(0)
              .milliseconds(0)
              .toDate();

      const newValue = updateDateTime(isDate, prevSelectedDateTime[keyToUpdate]);
      const newSelectedDateTime = { ...prevSelectedDateTime, [keyToUpdate]: newValue };

      if (keyToUpdate === 'startDateTime' && isDate) {
        newSelectedDateTime.endDateTime = updateDateTime(isDate, newSelectedDateTime.endDateTime);
      }

      return newSelectedDateTime;
    });
  };

  const handleTitleChange = e => setEventName(get(e, 'target.value', ''));

  /*
   * Constants
   */
  const isDisabled = !selectedDateTime.startDateTime || !selectedDateTime.endDateTime || eventName === '';

  /*
   * Sub-components
   */
  const modalActionButtons = () => (
    <div className="actionButtons">
      <div className="regularActions">
        <button id="cancelButton" key="cancelButton" className="button" onClick={onModalClose} disabled={isSubmitting}>
          {translate(null, TRANSLATION_SCOPE, 'actions.cancel')}
        </button>
        <button
          id="confirmButton"
          key="confirmButton"
          className="button"
          onClick={verifyAndSave}
          disabled={isSubmitting || isDisabled}
        >
          {isSubmitting
            ? translate(null, TRANSLATION_SCOPE, 'actions.submitting')
            : translate(null, TRANSLATION_SCOPE, 'actions.save')}
        </button>
      </div>
      {event && (
        <div className="deleteAction" onClick={onDelete}>
          <img key="deleteIcon" src={trashIcon} alt={translate(null, TRANSLATION_SCOPE, 'actions.delete')} />
          <span className="label">{translate(null, TRANSLATION_SCOPE, 'actions.delete')}</span>
        </div>
      )}
    </div>
  );

  const renderTimePicker = ({ selectedTime, label, type }) => (
    <DatePicker
      selected={selectedTime}
      onChange={date =>
        handleDatePickerChange({
          date,
          label,
          type
        })
      }
      showTimeSelect
      showTimeSelectOnly
      className={`${type}Picker`}
      timeIntervals={15}
      id={type}
      name={type}
      valueName={type}
      dateFormat="h:mm aa"
      timeCaption={label}
    />
  );
  renderTimePicker.propTypes = {
    selectedTime: PropTypes.object,
    label:        PropTypes.string,
    type:         PropTypes.string
  };

  const renderDatePicker = ({ selectedTime, label, type }) => (
    <DatePicker
      selected={selectedTime}
      onChange={date =>
        handleDatePickerChange({
          date,
          label,
          type
        })
      }
      showTimeSelect={false}
      className={`${type}Picker`}
      timeIntervals={15}
      id={type}
      name={type}
      valueName={type}
      dateFormat="MM/dd/yyyy"
      timeCaption={label}
    />
  );
  renderDatePicker.propTypes = {
    selectedTime: PropTypes.object,
    label:        PropTypes.string,
    type:         PropTypes.string
  };

  /*
   * Render
   */
  return (
    <TeladocModal
      className="specialWorkingHoursModalWrapper"
      title={
        event ? translate(null, TRANSLATION_SCOPE, 'actions.edit') : translate(null, TRANSLATION_SCOPE, 'actions.add')
      }
      isOpen
      onClose={onModalClose}
    >
      <div className="contentWrapper">
        <div className="subtitle">
          <p>{translate(null, TRANSLATION_SCOPE, 'subtitle')}</p>
        </div>

        <div className="timeSetWrapper">
          {notification && <Notification type={notification.type} message={notification.message} />}
          <p className="requiredFieldsLabel">
            <span className="dot" />
            {translate(null, TRANSLATION_SCOPE, 'required_fields')}
          </p>
          <div className="timeBlocks">
            <div className="timeBlock">
              <div className="sectionWrapper">
                <div className="typeLabel">{translate(null, TRANSLATION_SCOPE, 'type_label')}</div>
                <div className="sectionContent radioSelectionWrapper">
                  <div className="formInput">
                    <div>
                      <label className="radioItem" htmlFor="radioAddTo">
                        <input
                          id="radioAddTo"
                          checked={!availabilityOverrideFlg}
                          className="typeActionRadio"
                          type="radio"
                          onChange={() => setAvailabilityOverrideFlg(false)}
                        />
                        <span className={!availabilityOverrideFlg ? 'checked' : ''}>
                          {translate(null, TRANSLATION_SCOPE, 'add_to_label')}
                        </span>
                      </label>
                      <label className="radioItem" htmlFor="radioReplace">
                        <input
                          id="radioReplace"
                          checked={availabilityOverrideFlg}
                          className="typeActionRadio"
                          type="radio"
                          onChange={() => setAvailabilityOverrideFlg(true)}
                        />
                        <span className={availabilityOverrideFlg ? 'checked' : ''}>
                          {translate(null, TRANSLATION_SCOPE, 'replace_label')}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sectionWrapper">
                <div className="startEndLabel">{translate(null, TRANSLATION_SCOPE, 'start_label')}</div>
                <div className="sectionContent">
                  <div className="formInput">
                    {renderDatePicker({
                      selectedTime: selectedDateTime.startDateTime,
                      type:         'startDate',
                      label:        translate(null, TRANSLATION_SCOPE, 'start_date')
                    })}
                    <div className="separator" />
                    {renderTimePicker({
                      selectedTime: selectedDateTime.startDateTime,
                      type:         'startTime',
                      label:        translate(null, TRANSLATION_SCOPE, 'start_time')
                    })}
                  </div>
                </div>
              </div>

              <div className="sectionWrapper">
                <div className="startEndLabel">{translate(null, TRANSLATION_SCOPE, 'end_label')}</div>
                <div className="sectionContent">
                  <div className="formInput">
                    {renderDatePicker({
                      selectedTime: selectedDateTime.endDateTime,
                      type:         'endDate',
                      label:        translate(null, TRANSLATION_SCOPE, 'end_date')
                    })}
                    <div className="separator" />
                    {renderTimePicker({
                      selectedTime: selectedDateTime.endDateTime,
                      type:         'endTime',
                      label:        translate(null, TRANSLATION_SCOPE, 'end_time')
                    })}
                  </div>
                </div>
              </div>

              <div className="sectionWrapper">
                <div className="titleLabel">{translate(null, TRANSLATION_SCOPE, 'event_title_label')}</div>
                <div className="sectionContent">
                  <div className="formInput">
                    <input
                      type="text"
                      className="eventTitle"
                      name="eventTitle"
                      onChange={handleTitleChange}
                      value={eventName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {modalActionButtons()}
      </div>
    </TeladocModal>
  );
};

SpecialWorkingHoursModal.propTypes = {
  regularWorkingHours: PropTypes.object,
  isSubmitting:        PropTypes.bool,
  onModalClose:        PropTypes.func.isRequired,
  onSubmit:            PropTypes.func.isRequired,
  onDelete:            PropTypes.func.isRequired,
  event:               PropTypes.shape({
    startDateTime:            PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string]),
    endDateTime:              PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string]),
    eventName:                PropTypes.string,
    availabilityOverrideFlag: PropTypes.bool
  }),
  specialWorkingHours: PropTypes.arrayOf(PropTypes.object),
  personalEvents:      PropTypes.arrayOf(PropTypes.object)
};

export default SpecialWorkingHoursModal;
