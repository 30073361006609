import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@td/shared_utils';

const StartCall = ({ isReady = false, autostartCallActive = false, onClick }) => {
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    setDisableButton(!isReady || autostartCallActive);
  }, [isReady]);

  return (
    <div>
      <button
        className={`button auto-dialer-button ${disableButton ? 'auto-dialer-button--disabled' : ''}`}
        onClick={() => {
          setDisableButton(true);
          onClick();
        }}
        disabled={disableButton}
      >
        <I18n scope="autodialer.call_interface.dialing" text="start_call" />
      </button>
      <p className="default-message">
        <I18n scope="autodialer.call_interface.dialing" text="start_call_description" />
      </p>
    </div>
  );
};

StartCall.propTypes = {
  isReady: PropTypes.bool,
  onClick: PropTypes.func
};

export default StartCall;
