import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { chain, get, includes, isEmpty, values } from 'lodash';
import { useExternalCalendarAccountAlerts } from './contexts/external-calendar-account-alerts-context';
import { useProviderEligibility } from '../my-schedule/features/external-calendar/contexts/provider-eligibility-context';
import { allowedAlertCodesByScreenType, bannerTypeByAlertCode, screenTypes } from './constants';
import { translateMarkupString } from '@td/shared_utils';
import Banners from '../banners';

const ExternalCalendarAccountAlerts = React.memo(({ screenType, onOpenWorkingHours = () => {} }) => {
  const { isProviderEligibleForExternalCalendars } = useProviderEligibility();
  const { externalCalendarAccountAlerts, closeExternalCalendarAccountAlert } = useExternalCalendarAccountAlerts();

  const banners = useMemo(() => {
    if (!isProviderEligibleForExternalCalendars) {
      return [];
    }

    return chain(externalCalendarAccountAlerts)
      .filter(({ alertCd }) => includes(allowedAlertCodesByScreenType[screenType], alertCd))
      .map(({ id, alertCd, externalCalendarAccount }) => ({
        id,
        type: bannerTypeByAlertCode[alertCd],
        message: translateMarkupString(
          `external_calendar_accounts.alerts.${screenType}`,
          alertCd,
          { a: <a onClick={onOpenWorkingHours} /> },
          { email_address: get(externalCalendarAccount, 'emailAddress') }
        ),
        onClose: () => {
          closeExternalCalendarAccountAlert({
            alertId: `${id}`
          });
        }
      }))
      .value();
  }, [
    isProviderEligibleForExternalCalendars,
    screenType,
    externalCalendarAccountAlerts,
    closeExternalCalendarAccountAlert
  ]);

  if (isEmpty(banners)) {
    return null;
  }

  return (
    <Banners
      banners={banners}
    />
  );
});

ExternalCalendarAccountAlerts.displayName = 'ExternalCalendarAccountAlerts';
ExternalCalendarAccountAlerts.propTypes = {
  screenType: PropTypes.oneOf(values(screenTypes)).isRequired,
  onOpenWorkingHours: PropTypes.func
};

export default ExternalCalendarAccountAlerts;
