import { connect } from 'react-redux';
import get from 'lodash/get';

import { addErrorsToConsultNavigationStep } from 'app/consult-navigation/actions';
import getProviderParams from 'app/Provider/selectors/getProviderParams';
import { LockNextConsultRequestHandler } from '../components/lock-next-consult-request-handler';


const mapStateToProps = state => ({
  communicationMethods: get(getProviderParams(state), ['communicationMethods']),
  consultNavItems:      state.consultNavigation
});
const mapDispatchToProps = { addErrorsToConsultNavigationStep };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockNextConsultRequestHandler);
