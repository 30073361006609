import { CA_FORM_MAIN_INQUIRY_CHANGE } from '../action-types';

const DEFAULT_STATE = {
  mainInquiry: ''
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CA_FORM_MAIN_INQUIRY_CHANGE:
      return { ...state, mainInquiry: action.payload };
    default:
      return state;
  }
};
