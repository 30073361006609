import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LineChart } from 'react-chartkick';
import { LAB_RESULT_NAMES } from './../constants';

class PersonalHealthRecordGraph extends Component {
  static propTypes = {
    data: PropTypes.array
  };

  constructor(props) {
    super(props);
  }

  chartData(){
    let groupedList = _.groupBy(this.props.data, (item)=> LAB_RESULT_NAMES[item.labTestName]);
    return _.map(groupedList, (records, name) => {
      return {
        "name": name,
        data: _.reduce(records, (result, record) => {
          const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
          const label = new Date(record.startDate).toLocaleString('en-US', options);
          result[label] = record.getNumbericResult();
          return result;
        }, {})
      };
    });
  }

  chartUnits(){
    return this.props.data[0].getUnits();
  }

  render() {
    let prevDate = '';

    return (
      <LineChart
        data={this.chartData()}
        suffix={this.chartUnits()}
        width="1200px"
        height="600px"
        curve={false}
        legend={true}
        min={null}
        discrete={true}
        colors={["#4e89a4", "#D21F3C", "#6dc4e5", "#4e89a4"]}
        library={{
          xAxis: { crosshair: true,
            labels: {
              formatter() {
                const options = { year: 'numeric', month: 'short', day: 'numeric' };
                return new Date(this.value).toLocaleDateString('en-US', options);
              }
            }
          },
          tooltip: {shared: true},
          plotOptions: {
            series: {
              lineWidth: 3,
              symbols: ["triangle","square","diamond","circle"],
              marker: {
                enabled: true,
                radius: 3
              },
              states: {
                hover: {
                    lineWidthPlus: 0
                }
              }
            }
          }
        }}
      />
    );
  }
}

export default PersonalHealthRecordGraph;
