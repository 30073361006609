import moment from 'moment-timezone';
import { flatMap, get, map, pull } from 'lodash';
import { translate } from '@td/shared_utils';
import { eventTypeCodes } from '../constants';

const AVAILABILITY_ROUNDING_INTERVAL = 15;

export const formatRecommendedWorkingHours = (
  recommendedWorkingHours,
  providerSpecialties,
  providerSchedulingTimeBuffer
) => {
  const formattedRecommendedWorkingHours = [];

  const providerSpecialty = pull(providerSpecialties, 'GENMEDICAL');
  const providerSchedulingTimeBufferEnd = moment()
    .startOf('minute')
    .add(
      Math.ceil((moment().minutes() + 1) / AVAILABILITY_ROUNDING_INTERVAL) * AVAILABILITY_ROUNDING_INTERVAL -
        moment().minutes(),
      'minutes'
    )
    .add(providerSchedulingTimeBuffer, 'seconds');

  const { start: startHour = 0, end: endHour = 24, min_duration: minDuration = 30 } = get(
    window.FEATURE_TOGGLES_DATA,
    `recommended_working_hours.data.settings.${providerSpecialty[0]}`,
    {}
  );

  map(splitMultidaysEvents(recommendedWorkingHours), ({ start: eventStart, end: eventEnd }) => {
    const workingHoursStart = moment(eventStart)
      .startOf('day')
      .add(startHour, 'hours');
    const workingHoursEnd = moment(eventEnd)
      .startOf('day')
      .add(endHour, 'hours');

    const recommendationsDisplayRangeStart = workingHoursStart.isAfter(providerSchedulingTimeBufferEnd)
      ? workingHoursStart
      : providerSchedulingTimeBufferEnd;

    const recommendationsDisplayRangeEnd = workingHoursEnd.isAfter(providerSchedulingTimeBufferEnd)
      ? workingHoursEnd
      : providerSchedulingTimeBufferEnd;

    if (
      moment(eventStart).isBefore(recommendationsDisplayRangeEnd) &&
      moment(eventEnd).isAfter(recommendationsDisplayRangeStart)
    ) {
      const start = moment.max(eventStart, recommendationsDisplayRangeStart);
      const end = moment.min(eventEnd, recommendationsDisplayRangeEnd);

      if (moment(end).diff(start, 'minutes') >= minDuration) {
        formattedRecommendedWorkingHours.push(createRecommendedWorkingHoursEvent({ start, end }));
      }
    }
  });

  return formattedRecommendedWorkingHours;
};

const createRecommendedWorkingHoursEvent = ({ start, end }) => ({
  start:         moment(start).toDate(),
  end:           moment(end).toDate(),
  startDateTime: moment(start).toDate(),
  endDateTime:   moment(end).toDate(),
  eventDuration: moment.duration(moment(end).diff(moment(start))),
  typeCode:      eventTypeCodes.RECOMMENDED_WORKING_HOURS,
  title:         translate(null, 'provider_calendar.events.recommended_working_hours', 'title'),
  reason:        translate(null, 'my_schedule.events.recommended_working_hours', 'reason')
});

const splitMultidaysEvents = recommendedWorkingHours =>
  flatMap(recommendedWorkingHours, recommendedWorkingHour => {
    const { start, end } = recommendedWorkingHour;

    if (!moment(start).isSame(moment(end), 'day')) {
      return [
        {
          ...recommendedWorkingHour,
          start,
          end: moment(start)
            .endOf('day')
            .toDate()
        },
        {
          ...recommendedWorkingHour,
          start: moment(start)
            .add(1, 'day')
            .startOf('day')
            .toDate(),
          end: moment(end).toDate()
        }
      ];
    }

    return [recommendedWorkingHour];
  });
