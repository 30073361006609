import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as Yup from 'yup';
import { Field, withFormik } from 'formik';
import { row, fullWidth, field, actionField } from '../styles.module.scss';

const renderDistanceOptions = radiuses => radiuses.map((option, index) =>
  <option key={index} value={option}>{option}</option>);

const validationSchema = Yup.object().shape({
  searchParams: Yup.object().shape({
    postalCode:    Yup.string(),
    city:          Yup.string(),
    radius:        Yup.string(),
    name:          Yup.string()
  })
});

const formInitialState = () => ({
  consultationId:   '',
  selectedPharmacy: {},
  pharmacyParams:   {},
  searchParams:     {
    postalCode:     '',
    city:           '',
    radius:         '5',
    name:           ''
  },
  errors:           {},
  radiuses:         [5, 10, 25, 50]
});

const mapPropsToValues = () => (formInitialState());

const SearchForm = props => {
  const {
    values: { searchParams, radiuses },
    touched,
    errors,
    handleChange,
    handleSubmit,
  } = props;
  return (
    <div className="pharmacy-form">
      <h3>Search Pharmacies</h3>
      <h4>Enter street address, postal code OR city &amp; state</h4>

      <form onSubmit={handleSubmit}>
        <div className={cx(row, fullWidth)}>
          <div className={field}>
            <label>Postal Code</label>
            <Field
              name="searchParams.postalCode"
              id="searchParams.postalCode"
              type="text"
              className="form-field"
              onChange={e => props.setFieldValue('searchParams.postalCode', e.target.value)}
              value={searchParams.postalCode}
              placeholder="Enter a valid postal code"
            />
            {
              errors && errors.searchParams && touched.postalCode &&
              <div>{errors.postalCode}</div>
            }
          </div>
        </div>

        <div className={cx(row)}>
          <h4> OR</h4>
        </div>

        <div className={cx(row, fullWidth)}>
          <div className={field}>
            <label>City</label>
            <Field
              name="searchParams.city"
              id="searchParams.city"
              type="text"
              placeholder="Enter a valid city"
              className="form-field"
              onChange={handleChange}
              value={searchParams.city}
            />
            {
              errors && errors.searchParams && errors.city &&
              <div>{errors.city}</div>
            }
          </div>
        </div>

        <div className={cx(row, fullWidth)}>
          <div className={field}>
            <label>Pharmacy Name</label>
            <Field
              name="searchParams.name"
              id="searchParams.name"
              type="text"
              className="form-field"
              value={searchParams.name}
              placeholder="Enter a valid pharmacy name"
            />
            {
              errors && errors.searchParams && errors.name &&
              <div>{errors.name}</div>
            }
          </div>
        </div>

        <div className={cx(row, fullWidth)}>
          <div className={field}>
            <label>Radius</label>
            <Field
              name="searchParams.radius"
              id="searchParams.radius"
              component="select"
              className="form-field"
              placeholder="5"
            >
              {renderDistanceOptions(radiuses)}
            </Field>
            {
              errors && errors.searchParams && errors.radius &&
              <div>{errors.radius}</div>
            }
          </div>
        </div>

        <div className={cx(row, fullWidth, actionField)}>
          <div className={cx('action')}>
            <button
              type="submit"
              className="button-primary"
              disabled={!props.dirty && !props.isSubmitting}
            >
              Search
            </button>
          </div>

          <div className={cx('action')}>
            <button
              disabled={!props.dirty}
              onClick={props.handleReset}
              type="button"
              className="button-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

SearchForm.propTypes = {
  values: PropTypes.shape({
    searchParams: PropTypes.shape({
      postalCode:    PropTypes.string,
      city:          PropTypes.string,
      stateProvince: PropTypes.string,
      radius:        PropTypes.string,
      name:          PropTypes.string
    }),
    radiuses: PropTypes.array.isRequired
  }).isRequired,
  dirty:          PropTypes.bool,
  isSubmitting:   PropTypes.bool,
  stateProvinces: PropTypes.array.isRequired,
  touched:        PropTypes.object,
  errors:         PropTypes.object,
  onChange:       PropTypes.func,
  handleBlur:     PropTypes.func,
  handleChange:   PropTypes.func,
  handleSubmit:   PropTypes.func,
  handleReset:    PropTypes.func
};

export default withFormik({
  mapPropsToValues,
  validationSchema,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onFormSubmit(values, { props, setSubmitting });

    setTimeout(() => {
      setSubmitting(true);
    }, 1000);
  },
  displayName: 'SearchPharmacyForm'
})(SearchForm);
