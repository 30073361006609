import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Creatable } from 'react-select';
import cx from 'classnames';
import { Field, withFormik } from 'formik';
import prescriptionValidation from '../validations';
import StructuredSigBuilder from './structured-sig-builder';
import { DIRECTIONS } from '../../../config/directions';
import { UNITS } from '../../../config/units';
import { PharmacyNotes } from './pharmacy-notes';
import { DispenseInstructions } from './dispense-instructions';
import ADD_PRESCRIPTION_TO_ACTIVE_CONSULT_QUERY from '../mutations/add-prescription-to-active-consult.graphql';
import styles from '../styles.module.scss';
import { mapFormValueToRequest } from '../utils';

const { row, actionField, fullWidth, checkboxField } = styles;

const displayableDrugName = (drugName, specificity) =>
  `${drugName}${specificity == 'GENERIC' ? ' (generic)' : ''}`;

const getOptionLabel = ({ drugName, specificity }) => displayableDrugName(drugName, specificity);
const getOptionValue = ({ identification: { id } }) => id;
// These new medications are manual - they are
// entered by the provider.
const getNewOptionData = (inputValue, optionLabel) => {
  return {
    identification: {
      id: null,
      code: 'CODESET_DIN'
    },
    drugName: inputValue,
    specificity: '',
    manual: true,
    __isNew__: true
  }
};

const sigTemplate = {
  direction:              'Take',
  quantity:               '',
  unit:                   '',
  route:                  '',
  frequency:              '',
  additionalInstructions: ''
};

const formInitialState = () => ({
  consultationId:     '',
  confirmation:       false,
  selectedMedication: null,
  sig:                { ...sigTemplate },
  pharmacyNotes:      '',
  totalQuantity:      '',
  refills:            '',
  errors:             {},
  directions:         DIRECTIONS,
  units:              UNITS,
  substitution:       true
});

class PrescriptionBuilderForm extends Component {
  render() {
    const {
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      handleChange,
      handleSubmit,
      handleReset,
      loading,
      medications,
      selectedMedication
    } = this.props;

    const submitBtnText = isSubmitting
      ? 'Submitting Pending Prescription' : 'Add Pending Prescription';

    return (
      <form
        onSubmit={handleSubmit} className="ca-prescription-form"
      >
        <div className="auto-suggest">
          <label htmlFor="rx-search-canada">Medication</label>
          <Creatable
            id="rx-search-canada"
            isClearable
            isCreatable
            isLoading={loading}
            name="drug"
            options={medications}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            getNewOptionData={getNewOptionData}
            onInputChange={this.props.onInputChange}
            onChange={this.props.onChange}
            value={selectedMedication}
          />
        </div>

        { selectedMedication && (
          <div>
            <StructuredSigBuilder
              selectedMedication={selectedMedication}
              {...this.props}
            />
            <PharmacyNotes {...this.props} />
            <DispenseInstructions {...this.props} />

            <div className={cx(row, fullWidth)}>
              <div className={checkboxField}>
                <Field
                  id="allow-substitution"
                  type="checkbox"
                  name="substitution"
                  checked={values.substitution}
                />
                <label htmlFor="allow-substitution">
                  Allow substitution
                </label>
              </div>
            </div>
            <div className={cx(row, fullWidth)}>
              <div className={checkboxField}>
                <Field
                  type="checkbox"
                  name="confirmation"
                  id="confirmation"
                  checked={values.confirmation}
                  onChange={handleChange}
                />
                <label htmlFor="confirmation">
                  I confirm that the above prescription(s) and instructions are correct.
                </label>
                {
                  errors.confirmation && touched.confirmation &&
                  <div>{errors.confirmation}</div>
                }
              </div>
            </div>

            <div className={cx(row, fullWidth, actionField)}>
              <div className={cx('action')}>
                <button
                  type="submit"
                  className="button-primary"
                  disabled={!dirty && !isSubmitting}
                >
                  { submitBtnText }
                </button>
              </div>

              <div className={cx('action')}>
                <button
                  disabled={!dirty}
                  onClick={handleReset}
                  type="button"
                  className="button-secondary"
                >
                  Start Over
                </button>
              </div>
            </div>
          </div>
      )}
      </form>
    );
  }
}

PrescriptionBuilderForm.propTypes = {
  consultationId:     PropTypes.number,
  loading:            PropTypes.bool,
  isSubmitting:       PropTypes.bool,
  dirty:              PropTypes.bool,
  selectedMedication: PropTypes.shape({
    issuingBodyId:   PropTypes.string,
    issuingBodyCode: PropTypes.string
  }),
  values: PropTypes.shape({
    confirmation:       PropTypes.bool,
    selectedMedication: PropTypes.string,
    pharmacyNotes:      PropTypes.string,
    sig:                PropTypes.shape({
      direction:              PropTypes.string,
      quantity:               PropTypes.string,
      unit:                   PropTypes.string,
      route:                  PropTypes.string,
      frequency:              PropTypes.string,
      additionalInstructions: PropTypes.string
    })
  }),
  medications:   PropTypes.array.isRequired,
  touched:       PropTypes.object,
  errors:        PropTypes.object,
  onChange:      PropTypes.func,
  onInputChange: PropTypes.func,
  handleBlur:    PropTypes.func,
  handleChange:  PropTypes.func,
  handleSubmit:  PropTypes.func,
  handleReset:   PropTypes.func
};

const PrescriptionBuilder = withFormik({
  enableReinitialize: true,
  mapPropsToValues:   () => (formInitialState()),
  validationSchema:   prescriptionValidation,
  handleSubmit:       (values, { props, setSubmitting, setErrors, resetForm }) => {
    const prescriptionMutation = ADD_PRESCRIPTION_TO_ACTIVE_CONSULT_QUERY;
    const { apolloClient, consultationId, selectedMedication, onSuccessfulAdditionHandler } = props;
    const substitutions = values.substitution;
    const params = mapFormValueToRequest({ ...values, consultationId, selectedMedication, substitutions});
    setSubmitting(true);

    apolloClient
      .mutate({ mutation: prescriptionMutation, variables: { ...params }, refetchQueries: ['activeConsultationQuery', 'completedConsultationQuery'] })
      .then(response => {
        resetForm();
        onSuccessfulAdditionHandler();
      }).catch(e => {
        const errors = e.graphQLErrors.map(error => error.message);
        setSubmitting(false);
        setErrors({ form: errors });
      });
  },
  displayName: 'PrescriptionBuilder'
})(PrescriptionBuilderForm);

export default PrescriptionBuilder;
