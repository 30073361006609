import React from 'react';
import { get } from 'lodash';
import { I18n } from '@td/shared_utils';
import PropTypes from 'prop-types';
import { DEFAULT_ACCOUNTS_LIMIT } from '../../../../constants';

import plusIcon from '../../../../../assets/images/add_icon.svg';
import styles from './styles.module.scss';

const TRANSLATION_SCOPE = 'my_schedule.settings_page';

const AddAccountBar = ({ accountsCount, onClick }) => {
  const accountsLimit = get(window.FEATURE_TOGGLES_DATA, 'provider_external_calendars.data.accounts_limit', DEFAULT_ACCOUNTS_LIMIT);

  return (
    (accountsCount < accountsLimit) ? (
      <button
        className={styles.addAccountButton}
        onClick={onClick}
        data-testid="add-account-button"
      >
        <img
          src={plusIcon}
          className={styles.plusIcon}
          width={16}
          height={16}
          alt="plus"
          data-testid="plus-icon"
        />
        <I18n scope={TRANSLATION_SCOPE} text="actions.add_calendar" />
      </button>
    ) : (
      <p className={styles.limitMessage} data-testid="limit-text">
        <I18n
          scope={TRANSLATION_SCOPE}
          text="external_calendars_section.accounts_limit_text"
          options={{ accountsLimit }}
        />
      </p>
    )
  );
};

AddAccountBar.propTypes = {
  accountsCount: PropTypes.number.isRequired,
  onClick:       PropTypes.func.isRequired
};

export default AddAccountBar;
