import store from 'app/lib/store';
import { reject, find } from 'lodash';

export default () => {
  const { summarySpecified } = window.consultationInterview.validation;
  const that = window.consultationInterview.validation;
  const errors = [];
  const navigationItems = store.getState().consultNavigation.items;
  const allOtherItems = reject(navigationItems, { key: 'summary' });

  if (find(allOtherItems, { valid: false })) {
    errors.push('All consultation sections must be completed to submit');
    return Promise.resolve(errors);
  } else {
    // Additional check when all fields are populated but there is a TIME CONFLICT presented (in the meantime)
    return Promise.resolve(summarySpecified.apply(that));
  }
};
