const SET_CONSULT_FOLLOWUP_STATE = 'SET_CONSULT_FOLLOWUP_STATE';

export const setFollowupReduxState = newState => ({ type: SET_CONSULT_FOLLOWUP_STATE, payload: newState });

const initialState = {
  teladocServices:           undefined,
  noFurtherCareMinDays:      undefined,
  noFurtherCareMaxDays:      undefined,
  communicationMethod:       undefined,
  noFurtherCareDays:         '',
  selectedAppointmentOption: undefined,
  selectedTeladocServices:   [],
  selectedNonTeladocService: undefined
};

export const consultFollowupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONSULT_FOLLOWUP_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
