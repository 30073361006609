import React from 'react';
import { FaVideo, FaPhone } from 'react-icons/fa';
import PropTypes from 'prop-types';

const iconProps = {
  color: '#4C4C4C',
  size: 16
};

const COMMUNICATION_METHODS = {
  Phone: <FaPhone {...iconProps} style={{ transform: 'rotate(90deg)' }} />,
  Video: <FaVideo {...iconProps} />
};

const CommunicationMethodIcon = ({ code }) => COMMUNICATION_METHODS[code] || null;

CommunicationMethodIcon.propTypes = {
  code: PropTypes.string.isRequired
};

export default CommunicationMethodIcon;
