/* eslint-disable import/prefer-default-export */
import { get, intersection } from 'lodash';

export const recommendedWorkingHoursToggleEnabledForSpecialty = (providerSpecialties = []) => {
  if (!get(window.FEATURE_TOGGLES, 'recommended_working_hours')) {
    return false;
  }

  const featureDataSpecialties = get(window.FEATURE_TOGGLES_DATA, 'recommended_working_hours.data.specialties', []);

  return get(featureDataSpecialties, 0) === 'ALL' ?
    true :
    intersection(featureDataSpecialties, providerSpecialties).length > 0;
};
