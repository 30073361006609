import React from 'react';
import PropTypes from 'prop-types';
import ViewEHRLink from '../ViewEHRLink';

const ViewEHRAction = ({ consultationId }) => (
  <ViewEHRLink
    consultationId={consultationId}
    text={I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.view_ehr')}
  />
);

ViewEHRAction.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default ViewEHRAction;
