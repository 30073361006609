import React from 'react';

import './loading-indicator.scss';

export default () => (
  <div className="lds-ring">
    <div />
    <div />
    <div />
    <div />
  </div>
);
