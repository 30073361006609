import { useQuery } from '@apollo/client';

import ANY_CONSULTATION_LOCKED_OR_COMPLETED from '../../patient-volume-manager/queries/any_consult_locked_or_completed.graphql';
import { get } from 'lodash';

const useAnyConsultationLockedOrCompleted = () => {
  const { data, loading, error } = useQuery(ANY_CONSULTATION_LOCKED_OR_COMPLETED);

  return {
    data: get(data, 'currentUserProvider.anyConsultationLockedOrCompleted'),
    loading,
    error
  };
};

export default useAnyConsultationLockedOrCompleted;
