import React from 'react';
import PropTypes from 'prop-types';

const ReviewSummaryItem = ({ medication }) => (
  <div className="medication-review-summary__item">
    {medication.drugName} - {medication.activelyTakingFlag === 'Y'
      ? 'Currently Taking' : 'Not Currently Taking'
    }
  </div>
);

ReviewSummaryItem.propTypes = {
  medication: PropTypes.object.isRequired
};

export default ReviewSummaryItem;
