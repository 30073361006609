import { connect } from 'react-redux';
import SymptomsHiddenReducers from './symptoms-hidden-reducers';
import
  getSymptomsFormReducerStringified
from '../../reducers/selectors/symptoms/getSymptomsFormReducerStringified';
import
  getSymptomsAddedReducerStringified
from '../../reducers/selectors/symptoms/getSymptomsAddedReducerStringified';

const mapStateToProps = state => ({
  symptomsFormReducer:  getSymptomsFormReducerStringified(state),
  symptomsAddedReducer: getSymptomsAddedReducerStringified(state)
});

export default connect(mapStateToProps)(SymptomsHiddenReducers);
