import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ProviderClockInType from '../../../Provider/enums/provider-clock-in-type';
import ProviderClockInRequest from '../enums/provider-clock-in-request';

import ProviderClockInButton from './provider-clock-in-button-container';
import ProviderClockOutButton from './provider-clock-out-button-container';
import ProviderClockInLocation from './provider-clock-in-location-container';

const ProviderClockInOutButtons = ({
  serviceSpecialty,
  clockedIn,
  clockInType,
  videoClockInAllowed,
  combineVideoPhoneClockInButton,
  clockInLocationInit,
  onProviderClockInButtonClick,
  onSwitchClockInButtonClick,
  useLocationForClockIn
}) => {
  const displaySingleClockInButton = combineVideoPhoneClockInButton || !videoClockInAllowed;
  const clockInTypeForButton = () =>
    videoClockInAllowed ? ProviderClockInRequest.VIDEO : ProviderClockInRequest.NORMAL;
  const audioOnlyVerbiage =
    Boolean(window.FEATURE_TOGGLES.allow_auto_dialer) && serviceSpecialty === 'General Medical' ? 'Audio' : 'Phone';

  useEffect(() => {
    clockInLocationInit();
  }, []);

  const handleProviderClockInButtonClick = clockInType => {
    if (useLocationForClockIn) {
      return () => onProviderClockInButtonClick(clockInType);
    } else {
      return () => onSwitchClockInButtonClick(clockInType);
    }
  };

  const handleSwitchClockInButtonClick = clockInType => () => onSwitchClockInButtonClick(clockInType);

  return (
    <section className="clock-in-out-buttons">
      {!clockedIn && displaySingleClockInButton && (
        <ProviderClockInButton
          clockInType={clockInTypeForButton()}
          onClick={handleProviderClockInButtonClick(clockInTypeForButton())}
          requestMode
        />
      )}
      {!clockedIn &&
        !displaySingleClockInButton && [
          <ProviderClockInButton
            clockInType={ProviderClockInRequest.VIDEO}
            onClick={handleProviderClockInButtonClick(ProviderClockInRequest.VIDEO)}
            key={ProviderClockInRequest.VIDEO}
            text={`Clock In to Video & ${audioOnlyVerbiage}`}
            requestMode
          />,
          <ProviderClockInButton
            clockInType={ProviderClockInRequest.NORMAL}
            onClick={handleProviderClockInButtonClick(ProviderClockInRequest.NORMAL)}
            key={ProviderClockInRequest.NORMAL}
            text={`Clock In to ${audioOnlyVerbiage} Only`}
            requestMode
          />
        ]}
      {clockedIn &&
        videoClockInAllowed &&
        {
          [ProviderClockInType.PHONE]: (
            <ProviderClockInButton
              clockInType={ProviderClockInRequest.VIDEO}
              onClick={handleSwitchClockInButtonClick(ProviderClockInRequest.VIDEO)}
              text={`Switch to Video & ${audioOnlyVerbiage}`}
            />
          ),
          [ProviderClockInType.VIDEO]: (
            <ProviderClockInButton
              clockInType={ProviderClockInRequest.NORMAL}
              onClick={handleSwitchClockInButtonClick(ProviderClockInRequest.NORMAL)}
              text={`Switch to ${audioOnlyVerbiage} Only`}
            />
          )
        }[clockInType]}
      {clockedIn && <ProviderClockOutButton />}
    </section>
  );
};

ProviderClockInOutButtons.propTypes = {
  serviceSpecialty:               PropTypes.string,
  clockedIn:                      PropTypes.bool.isRequired,
  clockInType:                    PropTypes.string,
  videoClockInAllowed:            PropTypes.bool.isRequired,
  combineVideoPhoneClockInButton: PropTypes.bool.isRequired
};

export default ProviderClockInOutButtons;
