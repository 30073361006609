import {
  ADD_TO_PENDING_REVIEW_LIST,
  UPDATE_PENDING_REVIEW_LIST,
  REMOVE_FROM_PENDING_REVIEW_LIST,
  RESET_PENDING_REVIEW_LIST,
  SUBMIT_PENDING_REVIEW_LIST,
  SUBMIT_PENDING_REVIEW_LIST_START,
  SUBMIT_PENDING_REVIEW_LIST_FAIL,
  SUBMIT_PENDING_REVIEW_LIST_SUCCESS
} from './action-types';

export const addToPendingReviewList = payload => ({
  type: ADD_TO_PENDING_REVIEW_LIST,
  payload
});

export const updatePendingReviewList = payload => ({
  type: UPDATE_PENDING_REVIEW_LIST,
  payload
});

export const removeFromPendingReviewList = payload => ({
  type: REMOVE_FROM_PENDING_REVIEW_LIST,
  payload
});

export const resetPendingReviewList = () => ({
  type: RESET_PENDING_REVIEW_LIST
});

export const submitPendingReviewListStart = payload => ({
  type: SUBMIT_PENDING_REVIEW_LIST_START,
  payload
});

export const submitPendingReviewListSuccess = payload => ({
  type: SUBMIT_PENDING_REVIEW_LIST_SUCCESS,
  payload
});

export const submitPendingReviewListFail = error => ({
  type:    SUBMIT_PENDING_REVIEW_LIST_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const submitPendingReviewList = payload => ({
  type: SUBMIT_PENDING_REVIEW_LIST,
  payload
});
