import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { authToken } from '@td/api';
import { useUpdater } from '@td/shared_utils';
import { get } from 'lodash';

export const LockNextConsultButton = ({ communicationMethods, consultNavItems }) => {
  const { selectedIndex, items } = consultNavItems;
  const r = useUpdater('get', { url: '/v4/consultations/count', headers: { Authorization: `${authToken.get()}` } });
  const [minConsultCount, setMinConsultCount] = useState(3);

  const numAvailableConsults = () => {
    let total = null;
    if (r.data) {
      total = 0;
      if (communicationMethods.includes('Phone')) {
        total += r.data.onDemandPhone;
      }
      if (communicationMethods.includes('Video')) {
        total += r.data.onDemandVideo;
      }
    }
    return total;
  };

  const nonSummaryTabsAreValid = () =>
    // Aggregate the value of 'valid' prop across all items with logical OR, ignore summary tab validations
     items.reduce((a, cv) => (a && (cv.valid || cv.key === 'summary')), true);

  const notEnoughAvailableConsults = () => numAvailableConsults() === null || numAvailableConsults() <= Number(minConsultCount);

  const toolTipMessage = () => {
    if (!nonSummaryTabsAreValid()) {
      return 'All tabs must be valid';
    } else if (notEnoughAvailableConsults()) {
      return 'No consults available to lock';
    } else {
      return null;
    }
  };

  const loggingDetail = () => {
    if (!nonSummaryTabsAreValid()) {
      return 'Tabs were not valid on submit';
    } else if (notEnoughAvailableConsults()) {
      return `Available consults ${numAvailableConsults()} below minimum threshold of ${minConsultCount}`;
    } else {
      return "";
    }
  };

  const isDisabled = () => (!nonSummaryTabsAreValid() || notEnoughAvailableConsults() || items[selectedIndex].key != 'summary');

  useEffect(() => {
    if (items[selectedIndex].key === 'summary' && nonSummaryTabsAreValid()) {
      r.callApi();
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (document.getElementById('react_submission_request_handler')) {
      setMinConsultCount(document.getElementById('react_submission_request_handler').dataset.minConsultCount);
    }
  }, [selectedIndex]);

  return (
    <span
      data-tip={toolTipMessage()}
    >
      <input
        disabled={isDisabled()}
        type="submit"
        name="commit"
        value="Complete and Lock Next"
        id="submit_consult"
        className="button lock_next"
      />
      <ReactTooltip type="error" />
      <input type="hidden" name="interview[auto_lock][][enabled]" value={!isDisabled()}/>
      <input type="hidden" name="interview[auto_lock][][detail]" value={loggingDetail()}/>
      <input type="hidden" name="interview[auto_lock][][visible]" value={true}/>
      <input type="hidden" name="interview[auto_lock][][selected]" value={false}/>
    </span>
  );
};

LockNextConsultButton.propTypes = {
  communicationMethods: PropTypes.array,
  consultNavItems:      PropTypes.shape({
    items:         PropTypes.array,
    selectedIndex: PropTypes.number
  })
};
