import React, { useEffect, useState } from 'react';
import Modal from 'app/components/Modal';
import ProviderClockInLocation from 'app/sidebar/provider-clock-in-out/components/provider-clock-in-location';
import Button from '../../components/Button';
import { useMutation } from '@apollo/client';
import Loader from 'app/components/Loader';
import { logError } from '@td/shared_utils';
import ConsultationAttestation from '../../consultation-attestation';
import ConsultationComplete from '../../consultation-complete';

import SET_PROVIDER_LOCATION from '../mutations/set-provider-location.graphql';

const locationCheck = ({
  locationType,
  locationText,
  locationList,
  clockInLocationInit,
  dataMessage,
  dataDismissSteps,
  dataShow,
  shouldAskLocation,
  consultationId,
  changeConsultNavigationStep
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showLocationBtn, setShowLocationBtn] = useState(shouldAskLocation);
  const [isCompleteModal, setCompleteModal] = useState(dataDismissSteps && dataShow);

  const [setProviderLocation, { loading, error, data }] = useMutation(SET_PROVIDER_LOCATION);

  useEffect(() => {
    clockInLocationInit();
  }, []);

  useEffect(() => {
    if (data) {
      if (data.tasSetProviderLocation && data.tasSetProviderLocation.success) {
        setShowModal(false);
        setShowLocationBtn('false');
      } else {
        setShowModal(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      logError(error, 'Unable to set provider location');
    }
  }, [error]);

  if (loading) {
    return <Loader />;
  }

  const handleClose = () => setShowModal(false);
  const handleButtonClick = () => {
    setShowModal(true);
  };
  const onSendLocationClick = (currentLocationType, currentLocationText) => {
    setProviderLocation({ variables: { locationType: currentLocationType, locationText: currentLocationText } });
  };
  const handleConsultConfirm = () => {
    setCompleteModal(false);
  };
  const handleConsultCancel = () => {
    changeConsultNavigationStep(dataDismissSteps);
  };

  const actionButton = () => {
    if (showLocationBtn === 'true') {
      return (
        <Button
          className="check-location-button"
          onClick={handleButtonClick}
          text="Close visit and electronically sign"
        />
      );
    } else if (isCompleteModal) {
      return (
        <ConsultationComplete
          dataMessage={dataMessage}
          dataDismissSteps={dataDismissSteps}
          dataShow={dataShow}
          onConfirm={handleConsultConfirm}
          onCancel={handleConsultCancel}
        />
      );
    }
    return (
      <ConsultationAttestation
        consultationId={consultationId}
        dataMessage={dataMessage}
        dataDismissSteps={dataDismissSteps}
        dataShow={dataShow}
      />
    );
  };

  return (
    <div>
      <Modal
        centered
        showCloseButton
        className="location-modal"
        id="location-modal"
        onClose={handleClose}
        show={showModal}
      >
        <ProviderClockInLocation
          locationType={locationType}
          locationText={locationText}
          locationList={locationList}
          withClockInButtons={false}
          onSendLocationClick={onSendLocationClick}
        />
      </Modal>
      {actionButton()}
    </div>
  );
};

export default locationCheck;
