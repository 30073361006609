import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchPatientSyncErrors } from '../actions';

export class PatientFetchSyncErrorsContainer extends Component {
  componentDidMount() {
    if (!this.props.patientSyncErrorsFetched) {
      const { consultationId } = this.props;
      this.props.fetchPatientSyncErrors({ consultationId });
    }
  }

  componentWillUnmount() {
    if (this.props.patientSyncErrorsLoading) {
      this.props.fetchPatientSyncErrorsCancel();
    }
  }

  render() {
    return null;
  }
}

PatientFetchSyncErrorsContainer.propTypes = {
  consultationId:               PropTypes.number.isRequired,
  fetchPatientSyncErrors:       PropTypes.func.isRequired,
  fetchPatientSyncErrorsCancel: PropTypes.func.isRequired,
  patientSyncErrorsFetched:     PropTypes.bool.isRequired,
  patientSyncErrorsLoading:     PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  fetchPatientSyncErrors:       fetchPatientSyncErrors.start,
  fetchPatientSyncErrorsCancel: fetchPatientSyncErrors.cancel
};

const mapStateToProps = state => ({
  patientSyncErrorsFetched: state.erxErrors.patientSyncErrorsRequest.isSuccess,
  patientSyncErrorsLoading: state.erxErrors.patientSyncErrorsRequest.isLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientFetchSyncErrorsContainer);
