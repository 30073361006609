/* eslint-disable no-param-reassign */
/* eslint-disable global-require */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { translate } from '@td/shared_utils';
import { scheduleTypeMethods } from '../../my-schedule/constants';
import EventDetails from './event-details';
import disconnectedIcon from './../../assets/images/event_details/disconnected.svg';

const TRANSLATION_SCOPE = 'my_schedule.event_details';

const CustomCalendarEvent = ({
  event,
  onEditEvent,
  showDetails,
  setShowDetails,
  scheduleTypes,
  setActiveModal,
  canEditSchedule,
  calendarIncrement
}) => {
  const wrapperRef = useRef(null);

  /*
   * Callbacks
   */
  const handleEditEvent = currentEvent => {
    setShowDetails(null);
    onEditEvent(currentEvent);
  };

  const onShowDetails = mouseEvent => {
    const { target, clientY } = mouseEvent || {};
    const isEventContainer = target && target.className && (target.className.includes('rbc-event-content') || target.className.includes('provider-calendar-event'));

    if (mouseEvent && isEventContainer && wrapperRef && wrapperRef.current) {
      let containerTop = null;
      let containerBot = null;
      const monthView = document.getElementsByClassName('rbc-month-view');
      if (monthView && monthView[0] && monthView[0]) {
        containerTop = monthView[0].getBoundingClientRect().top;
        containerBot = monthView[0].getBoundingClientRect().bottom;
      } else {
        containerTop = document.getElementsByClassName('rbc-time-content')[0].getBoundingClientRect().top;
        containerBot = document.getElementsByClassName('rbc-time-content')[0].getBoundingClientRect().bottom;
      }
      const top = wrapperRef.current.getBoundingClientRect().top;
      const scroll = top - wrapperRef.current.offsetTop;
      let middleFix = clientY - containerTop > 20 ? -25 : -2;
      if (containerBot - clientY < 178) {
        middleFix = containerBot - clientY - 180;
        event.afterPosition = 'bot';
      } else {
        event.afterPosition = 'top';
      }
      event.topPosition = (clientY - scroll) + middleFix;
    }
    if (!showDetails) {
      setShowDetails(event.id);
    }
  };

  const handleStartVisit = consultationId => {
    if (consultationId) {
      window.open(`/consultations/${consultationId}/init_review?load_ease=true`, '_blank');
    }
  };

  const handleReschedule = ({ consultationId, rescheduleProps }) => {
    if (consultationId) {
      setActiveModal({ modal: 'RESCHEDULE_MODAL', consultationId, rescheduleProps });
    }
  };

  const useOutsideAlerter = ref => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(currentEvent) {
        if (
          showDetails &&
          ref.current &&
          !ref.current.contains(currentEvent.target) &&
          currentEvent.target.nodeName !== 'BUTTON' &&
          currentEvent.target.nodeName !== 'A'
        ) {
          setShowDetails(null);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  /*
   * Constants
   */
  useOutsideAlerter(wrapperRef);

  const { PROVSCHEDEVENT_AWM, PROVSCHEDEVENT_PERSONALEVENT } = scheduleTypes;
  const { eventName: memberName, consultLcnsFlag } = event;
  const isUnavailability = event.typeCode === PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode;
  const isSmallTimeSlot = event.eventDuration.asMilliseconds() <= (3600 * 1000) / 2;
  const isAWMEvent = event.typeCode === PROVSCHEDEVENT_AWM.eventTypeCode;
  const isCanceled = isAWMEvent && consultLcnsFlag;
  const isCompleted = isAWMEvent && !isCanceled && moment(event.end).isBefore(moment());
  let eventStatusIcon;
  let statusTypeMethod;

  if (isCanceled || isCompleted) {
    const iconType = isCompleted ? '_white' : '';
    statusTypeMethod = isCompleted ? scheduleTypeMethods.completed : scheduleTypeMethods.canceled;
    eventStatusIcon = require(`../../assets/images/${statusTypeMethod.icon}${iconType}.svg`);
  }

  const isExpiredExternalEvent = event.externalEvent && event.externalEvent.isExpired;

  /*
   * Render
   */
  return (
    <div
      className={`provider-calendar-event ${event.allDayEventFlg && 'full-time-event'} ${isCanceled &&
        'canceled-event'}`}
      key={event.id}
      onClick={onShowDetails}
      ref={wrapperRef}
    >
      <div className="provider-calendar-event-heading-wrapper">
        {isCanceled && <div className="canceled-event-side-indicator" />}
        {(isCanceled || isCompleted) && (
          <div className="provider-calendar-event-icon status-icon">
            <img src={eventStatusIcon} alt={statusTypeMethod && statusTypeMethod.label} />
          </div>
        )}
        {isExpiredExternalEvent && (
          <div className="provider-calendar-event-icon status-icon">
            <img src={disconnectedIcon} alt="disconnected icon" />
          </div>
        )}
        <div className="provider-calendar-event-title-wrapper">
          <span className={`title ${isCanceled && 'canceled'} ${event.typeCode}`}>{memberName || event.title}</span>
        </div>
      </div>
      {!isSmallTimeSlot && !isCanceled && !event.allDayEventFlg && !isUnavailability && (
        <div className="provider-calendar-event__time">
          {moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')} (
          {Math.floor(event.eventDuration.asMilliseconds() / 1000 / 60)}{' '}
          {translate(null, TRANSLATION_SCOPE, 'minutes_abbrev')})
        </div>
      )}
      {showDetails &&
        showDetails === event.id &&
        [PROVSCHEDEVENT_AWM.eventTypeCode, PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode].includes(event.typeCode) && (
          <EventDetails
            event={event}
            onReschedule={handleReschedule}
            onStartVisit={() => handleStartVisit(event.consultationId)}
            onEditEvent={() => handleEditEvent(event)}
            canEditSchedule={canEditSchedule}
            calendarIncrement={calendarIncrement}
          />
        )}
    </div>
  );
};

CustomCalendarEvent.propTypes = {
  event: PropTypes.shape({
    id:              PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    consultationId:  PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    consultLcnsFlag: PropTypes.bool,
    eventName:       PropTypes.string,
    typeCode:        PropTypes.string.isRequired,
    title:           PropTypes.string.isRequired,
    start:           PropTypes.instanceOf(Date).isRequired,
    end:             PropTypes.instanceOf(Date).isRequired,
    eventDuration:   PropTypes.object.isRequired,
    allDayEventFlg:  PropTypes.bool,
    afterPosition:   PropTypes.string,
    topPosition:     PropTypes.number
  }).isRequired,
  onEditEvent:    PropTypes.func.isRequired,
  showDetails:    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setShowDetails: PropTypes.func.isRequired,
  scheduleTypes:  PropTypes.shape({
    PROVSCHEDEVENT_AWM:           PropTypes.shape({ eventTypeCode: PropTypes.string.isRequired }).isRequired,
    PROVSCHEDEVENT_PERSONALEVENT: PropTypes.shape({ eventTypeCode: PropTypes.string.isRequired }).isRequired
  }).isRequired,
  setActiveModal:    PropTypes.func.isRequired,
  canEditSchedule:   PropTypes.bool,
  calendarIncrement: PropTypes.number
};

export default CustomCalendarEvent;
