exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._129u51wV7ye2yXzhRQR9xp {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16px;\n  color: #000000;\n  padding: 24px 32px;\n  background: #FFE2E0;\n  border: 2px solid #AA1010;\n  border-radius: 8px; }\n", ""]);

// exports
exports.locals = {
	"errorNotification": "_129u51wV7ye2yXzhRQR9xp",
	"errorNotification": "_129u51wV7ye2yXzhRQR9xp"
};