import React from 'react';
import PropTypes from 'prop-types';

const PhoneNumberRenderer = ({
  country_cd,
  area_code,
  extension,
  party_phone_fax_id,
  phone_fax_type_cd,
  phone_number,
  preferred_flg,
  ref_telco_carrier_id,
  telco_carrier_name }) => (
    <div className="phone-wrapper">
      {`+${country_cd} ${area_code}-${phone_number}`}
    </div>);

const SavedPharmacy = props => {
  return (
    <div className="pharmacy-card">
    <div className="pharmacy-card__icon" />
    <div className="pharmacy-card__details">
      <h4 className="pharmacy-card__details-name">
        {props.pharmacy_nm}
      </h4>
      <div className="pharmacy-card__details-address-line-1">
        {props.address_line1}</div>
      <div className="pharmacy-card__details-address-line-2">
        {props.city}, {props.postal} {props.state_province}
      </div>
      <PhoneNumberRenderer {...props.phone} />
    </div>
  </div>
  );
};

export default SavedPharmacy;
