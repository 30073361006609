import {
  SUBMIT_CLINICAL_MEDICATION_FORM_START,
  SUBMIT_CLINICAL_MEDICATION_FORM_SUCCESS,
  SUBMIT_CLINICAL_MEDICATION_FORM_FAIL,
  VALIDATE_CLINICAL_MEDICATION_FORM,
  VALIDATE_CLINICAL_MEDICATION_FORM_START,
  VALIDATE_CLINICAL_MEDICATION_FORM_SUCCESS,
  VALIDATE_CLINICAL_MEDICATION_FORM_FAIL,
  SET_CLINICAL_MEDICATION_FORM_FIELD,
  RESET_CLINICAL_MEDICATION_FORM
} from '../actionTypes';

const DEFAULT_STATE = {
  isLoading: false,
  formData:  {
    activelyTakingFlag: '',
    drugName:           null,
    drugCode:           '',
    drugFrequency:      '',
    drugQuantity:       '',
    touched:            false
  },
  validation:   { error: {}, valid: false, pending: false },
  submitResult: { pending: false, success: false, failed: false }
};

const formReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SUBMIT_CLINICAL_MEDICATION_FORM_START: {
      let submitResult = state.submitResult;
      submitResult = {
        pending: true,
        success: false,
        failed:  false
      };

      return {
        ...state,
        isLoading: true,
        submitResult
      };
    }
    case SUBMIT_CLINICAL_MEDICATION_FORM_SUCCESS: {
      let submitResult = state.submitResult;
      submitResult = {
        pending: false,
        success: true,
        failed:  false
      };

      return {
        ...state,
        isLoading: false,
        submitResult
      };
    }
    case SUBMIT_CLINICAL_MEDICATION_FORM_FAIL: {
      let submitResult = state.submitResult;
      submitResult = {
        pending: false,
        success: false,
        failed:  true
      };

      return {
        ...state,
        isLoading: false,
        submitResult
      };
    }
    case SET_CLINICAL_MEDICATION_FORM_FIELD: {
      const { field, value } = action.payload;
      const formData = state.formData;
      formData[field] = value;
      formData.touched = true;

      return {
        ...state,
        formData
      };
    }
    case VALIDATE_CLINICAL_MEDICATION_FORM: {
      let validation = state.validation;

      validation = {
        pending: true,
        valid:   false,
        error:   {}
      };

      return {
        ...state,
        validation
      };
    }
    case VALIDATE_CLINICAL_MEDICATION_FORM_START: {
      let validation = state.validation;

      validation = {
        pending: true,
        valid:   false,
        error:   {}
      };

      return {
        ...state,
        validation
      };
    }
    case VALIDATE_CLINICAL_MEDICATION_FORM_SUCCESS: {
      let validation = state.validation;

      validation = {
        pending: false,
        valid:   true,
        error:   {}
      };

      return {
        ...state,
        validation
      };
    }
    case VALIDATE_CLINICAL_MEDICATION_FORM_FAIL: {
      let validation = state.validation;

      validation = {
        pending: false,
        valid:   false,
        error:   action.payload
      };

      return {
        ...state,
        validation
      };
    }
    case RESET_CLINICAL_MEDICATION_FORM: {
      return {
        ...state,
        formData: {
          activelyTakingFlag: '',
          drugName:           null,
          drugCode:           '',
          drugFrequency:      '',
          drugQuantity:       '',
          touched:            false
        },
        validation:   { error: {}, valid: false, pending: false },
        submitResult: { pending: false, success: false, failed: false }
      };
    }
    default: {
      return state;
    }
  }
};

export { formReducer };

