import { authToken, teladocApi } from '@td/api';
import { logError } from '@td/shared_utils';
import { CONFERENCE_STATUSES } from '../constants/call-status';

export const authorize = async consultationId =>
  await teladocApi
    .post('/v4/autodialer/authorize', { consultation_id: consultationId }, authToken.get())
    .then(({ data }) => {
      if (data && data.data) {
        const { dial_provider: dialProvider, token } = data.data;

        if (dialProvider && token) {
          return { dialProvider, token };
        }
      }

      return {};
    })
    .catch(err => {
      logError(err, 'Unable to authorize the webRTC feature');

      return { status: CONFERENCE_STATUSES.AUTH_ERROR };
    });

export const checkIfShouldHidePhoneNumber = async consultationId =>
  await teladocApi
    .get(`/v4/consultations/${consultationId}/hide_phone_number_for_autodialer`, {}, authToken.get())
    .then(res => res.data)
    .catch(err => {
      logError(err, 'Error when checking if the consultation is IOT based');
      return false;
    });

export const getCallStatus = async consultationId => {
  const errorReturn = { status: CONFERENCE_STATUSES.BAD_REQUEST, callData: {} };

  return await teladocApi
    .post('/v4/autodialer/status', { consultation_id: consultationId }, authToken.get())
    .then(({ data }) => {
      if (data && data.data) {
        const { autodialer, attendees } = data.data;

        if (autodialer) {
          return { status: autodialer.current_status_code, attendees };
        }
      }

      return errorReturn;
    })
    .catch(err => {
      logError(err, 'Error retrieving webRTC call status data');

      return errorReturn;
    });
};

export const initiateCall = async consultationId =>
  await teladocApi
    .post('/v4/autodialer/start-conference', { consultation_id: consultationId }, authToken.get())
    .then(() => ({ status: CONFERENCE_STATUSES.STARTING }))
    .catch(err => {
      logError(err, 'Error starting webRTC call');

      return { status: CONFERENCE_STATUSES.CALL_FAILURE };
    });

export const endCall = async consultationId =>
  await teladocApi
    .post('/v4/autodialer/end-conference', { consultation_id: consultationId }, authToken.get())
    .catch(err => {
      logError(err, 'Error ending webRTC call');
    });

export const joinByPhone = async (consultationId, externalTypeId, phoneNumber) =>
  await teladocApi
    .post(
      '/v4/autodialer/join',
    {
      consultation_id:  consultationId,
      role_cd:          externalTypeId,
      telephone_number: phoneNumber
    },
      authToken.get()
    )
    .then(data => data)
    .catch(err => {
      logError(err, 'Error joining webRTC call via phone');
    });

export const updateAlexaMessageShown = async consultationId =>
  await teladocApi
    .post('/v4/autodialer/update-alexa-message-shown', { consultation_id: consultationId }, authToken.get())
    .catch(err => {
      logError(err, 'Error updating alexa message shown');
    });

export const updateMemberNumberShown = async consultationId =>
  await teladocApi
    .post(
      '/v4/autodialer/update',
      { consultation_id: consultationId, data: { manually_dialed_flg: true } },
      authToken.get()
    )
    .then(returnData => returnData)
    .catch(err => {
      logError(err, 'Error updating consultation');
    });

export const updateConsultationStatus = async (consultationId, status, reason) =>
  await teladocApi
    .post(
      `/v4/consultations/${consultationId}/consult_statuses`,
      { consultation_id: consultationId, status, reason },
      authToken.get()
    )
    .then(returnData => returnData)
    .catch(err => {
      logError(err, 'Error updating consultation');
    });
