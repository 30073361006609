import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { translate } from '@td/shared_utils';
import { map, filter } from 'lodash';
import { useMutation } from '@apollo/client';
import { externalAccountProviderIcons } from '../../../../constants';
import deleteIcon from '../../../../../assets/images/trash_icon.svg';
import editIcon from '../../../../../assets/images/edit_icon.svg';
import AlertIcon from '../../../../../icons/components/alert-icon';
import CalendarSettings from '../calendar-settings';
import RemoveAccountConfirmationModal from '../remove-account-confirmation-modal';
import { sortExternalCalendars } from '../../utils/calendar';

import DESTROY_ACCOUNT_MUTATION from '../../mutations/destroy_external_account.graphql';

import styles from './styles.module.scss';

const TRANSLATION_SCOPE = 'my_schedule.settings_page.external_calendars_section';

const ExternalAccount = ({ account, onRelogin, onSettingsChanged }) => {
  const [showCalendarSettings, setShowCalendarSettings] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const Icon = externalAccountProviderIcons[account.calendarType];

  const handleSettingsClose = useCallback((isUpdated) => {
    setShowCalendarSettings(false);

    if (isUpdated) {
      onSettingsChanged();
    }
  }, [onSettingsChanged]);

  const handleRemoveModalClose = useCallback(() => {
    setShowRemoveConfirmation(false);
  }, []);

  const [handleDestroy] = useMutation(DESTROY_ACCOUNT_MUTATION);

  const handleAccountDestroy = useCallback(() => {
    setShowRemoveConfirmation(false);

    handleDestroy({ variables: { id: account.id } });
    onSettingsChanged();
  }, []);

  const accountLabel = useMemo(() => {
    const activeCalendars = filter(sortExternalCalendars(account.calendars), { active: true });
    return map(activeCalendars, 'name').join(', ');
  }, [account.calendars]);

  const { isSynchronizing, isDestroying } = account;

  return (
    <div className={cx('header', styles.header)}>
      <div className={styles.externalCalendarIcon} data-testid="calendar-icon">
        <Icon width={32} height={32} />
      </div>
      <div className="availabilityText">
        <h4 className="sectionTitle">{account.emailAddress}</h4>
        <p className={styles.calendar}>
          {accountLabel}
        </p>
        {isSynchronizing && !isDestroying && (
          <div className={styles.synchronizing} data-testid="sync-loading">
            <div className={styles.loader} />
            <p
              className={styles.syncMessage}
              data-testid="sync-message"
            >
              {translate(null, TRANSLATION_SCOPE, 'statuses.syncing')}
            </p>
          </div>
        )}
        {isDestroying && (
          <div className={styles.synchronizing} data-testid="remove-loading">
            <div className={styles.loader} />
            <p
              className={styles.syncMessage}
              data-testid="remove-message"
            >
              {translate(null, TRANSLATION_SCOPE, 'statuses.removing')}
            </p>
          </div>
        )}
        {account.expired && (
          <div className={styles.expirationNote} data-testid="expiration-note">
            <AlertIcon />
            {translate(null, TRANSLATION_SCOPE, 'statuses.expired')}
          </div>
        )}
      </div>
      {!account.isSynchronizing && !account.isDestroying && (
        !account.expired ? (
          <div className={styles.accountActions} data-testid="account-actions">
            <button
              className={styles.button}
              onClick={() => setShowRemoveConfirmation(true)}
              data-testid="remove-action"
            >
              <img src={deleteIcon} alt={translate(null, TRANSLATION_SCOPE, 'actions.delete')} />
            </button>
            <button
              className={styles.button}
              onClick={() => setShowCalendarSettings(true)}
              data-testid="edit-action"
            >
              <img src={editIcon} alt={translate(null, TRANSLATION_SCOPE, 'actions.edit')} />
            </button>
          </div>
        ) : (
          <div className="availabilityAction">
            <button
              className={cx('button', styles.reloginButton)}
              onClick={() => { onRelogin(account); }}
              data-testid="relogin-action"
            >
              {translate(null, TRANSLATION_SCOPE, 'actions.relogin')}
            </button>
          </div>
        )
      )}

      {showCalendarSettings && (
        <CalendarSettings
          account={account}
          onClose={handleSettingsClose}
        />
      )}

      {showRemoveConfirmation && (
        <RemoveAccountConfirmationModal
          account={account}
          onClose={handleRemoveModalClose}
          onConfirm={handleAccountDestroy}
        />
      )}
    </div>
  );
};

ExternalAccount.propTypes = {
  account: PropTypes.shape({
    id:              PropTypes.string,
    emailAddress:    PropTypes.string,
    expired:         PropTypes.bool,
    isSynchronizing: PropTypes.bool,
    isDestroying:    PropTypes.bool,
    calendarTypeCd:  PropTypes.string,
    calendars:       PropTypes.arrayOf(
      PropTypes.shape({
        id:              PropTypes.string,
        name:            PropTypes.string,
        active:          PropTypes.bool,
        isSynchronizing: PropTypes.bool
      })
    )
  }).isRequired,
  onRelogin: PropTypes.func.isRequired,
  onSettingsChanged: PropTypes.func.isRequired
};

export default ExternalAccount;
