/* global newrelic */
const trackEvent = eventData => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push(eventData);
  }
  if (typeof newrelic !== 'undefined' && eventData && eventData.content) {
    const { event, content, ...otherProps } = eventData;
    newrelic.addPageAction(event, otherProps);
  }
};

export default trackEvent;
