import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import { ofType } from 'redux-observable';
import { flatMap, filter, map } from 'rxjs/operators';

import {
  symptomSelected,
  symptomsFormRadioChange,
  focusSymptom,
  addSymptomSearchSuccess,
  focusSymptomSet,
  toggleAccordion,
  symptomUnselectedOnValueChange
} from '../actions';
import {
  SYMPTOMS_FORM_RADIO_CHANGE,
  ADD_SYMPTOM_SEARCH_REQUEST,
  SYMPTOM_UNSELECTED,
  FOCUS_SYMPTOM
} from '../action-types';

import getChiefComplaintClassName from '../reducers/selectors/symptoms/getChiefComplaintClassName';
import getChiefComplaintClassSymptomIdsSet from '../reducers/selectors/symptoms/getChiefComplaintClassSymptomIdsSet';
import getSelectedSymptomIds from '../reducers/selectors/symptoms/getSelectedSymptomIdsSet';

export const symptomSelectedEpic = (action$, state$) =>
  action$.pipe(
    ofType(SYMPTOMS_FORM_RADIO_CHANGE),
    filter(action => {
      const storeState = state$.value;
      const { symptomCode, radioValue } = action.payload;

      const hasSymptomBeenSelected = getSelectedSymptomIds(storeState).has(symptomCode);

      return radioValue !== 'n/a' && !hasSymptomBeenSelected;
    }),
    map(action => {
      const storeState = state$.value;
      const { symptomCode, symptomClass } = action.payload;

      const chiefComplaintClassName = getChiefComplaintClassName(storeState);

      return symptomSelected(symptomCode, symptomClass === 'Associated' ? chiefComplaintClassName : symptomClass);
    })
  );

export const symptomUnselectedEpic = action$ =>
  action$.pipe(
    ofType(SYMPTOM_UNSELECTED),
    map(action => symptomsFormRadioChange(action.payload.symptomCode, 'n/a'))
  );

export const symptomUnselectedOnValueChangeEpic = (action$, state$) =>
  action$.pipe(
    ofType(SYMPTOMS_FORM_RADIO_CHANGE),
    filter(action => {
      const storeState = state$.value;
      const { symptomCode, radioValue, symptomClass } = action.payload;

      const isChiefComplaintClassSymptom = getChiefComplaintClassSymptomIdsSet(storeState).has(symptomCode);

      const isAdditionalSymptom = symptomClass !== 'Associated' && symptomClass !== 'Added symptoms';

      return (isChiefComplaintClassSymptom || isAdditionalSymptom) && radioValue === 'n/a';
    }),
    map(action => symptomUnselectedOnValueChange(action.payload.symptomCode))
  );

export const addSymptomFromSearchEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_SYMPTOM_SEARCH_REQUEST),
    flatMap(action => {
      const storeState = state$.value;
      const symptomCode = action.payload.symptomCode;

      const symptomHasRadioValue = Boolean(storeState.symptomsForm.radioValues[symptomCode]);

      if (symptomHasRadioValue) {
        return of(focusSymptom(symptomCode));
      }

      const outputActions = [];

      const isChiefComplaintClassSymptom = getChiefComplaintClassSymptomIdsSet(storeState).has(symptomCode);
      const symptomSelectedClass = storeState.symptomsAdded.symptomSelectedClass[symptomCode];

      if (isChiefComplaintClassSymptom) {
        outputActions.push(of(symptomsFormRadioChange(symptomCode, 'yes', getChiefComplaintClassName(storeState))));
      } else if (symptomSelectedClass) {
        outputActions.push(of(symptomsFormRadioChange(symptomCode, 'yes', symptomSelectedClass)));
      } else {
        outputActions.push(of(addSymptomSearchSuccess(symptomCode)));
      }

      outputActions.push(of(focusSymptom(symptomCode)));

      return concat(...outputActions);
    })
  );

export const focusSymptomEpic = (action$, state$) =>
  action$.pipe(
    ofType(FOCUS_SYMPTOM),
    flatMap(action => {
      const storeState = state$.value;

      const selectedTabIndex = storeState.symptomTabs.selectedTabIndex;

      if (selectedTabIndex === 0) {
        return of(focusSymptomSet(action.payload.symptomCode, 'Associated'));
      }

      const symptomSelectedClass = storeState.symptomsAdded.symptomSelectedClass[action.payload.symptomCode];

      const outputActions = [];

      if (!storeState.symptomAccordions.expandedAccordions.has(symptomSelectedClass)) {
        outputActions.push(of(toggleAccordion(symptomSelectedClass)));
      }

      outputActions.push(of(focusSymptomSet(action.payload.symptomCode, symptomSelectedClass)));

      return concat(...outputActions);
    })
  );

export default combineEpics(
  symptomSelectedEpic,
  symptomUnselectedEpic,
  addSymptomFromSearchEpic,
  focusSymptomEpic,
  symptomUnselectedOnValueChangeEpic
);
