import { combineReducers } from 'redux';

import checkNetworkTestNeededRequest from './check-network-test-needed-request-reducer';
import qualityCheckRequest from './quality-check-request-reducer';
import connectivityCheckRequest from './connectivity-check-request-reducer';
import networkTestStatus from './network-test-status-reducer';
import bandwidthTestResultsRequest from './bandwidth-test-results-request-reducer';
import networkTestSettings from './network-test-settings-reducer';

export default combineReducers({
  checkNetworkTestNeededRequest,
  qualityCheckRequest,
  connectivityCheckRequest,
  bandwidthTestResultsRequest,
  status: networkTestStatus,
  settings: networkTestSettings
});
