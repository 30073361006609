import { createRequestActions } from '@td/utils';

import {
  CHECK_NETWORK_TEST_NEEDED,
  PERFORM_CONNECTIVITY_CHECK,
  NETWORK_TEST_START,
  NETWORK_TEST_FAIL,
  NETWORK_TEST_COMPLETE,
  NETWORK_TEST_CANCEL,
  NETWORK_TEST_SUCCESS,
  PERFORM_QUALITY_CHECK,
  NETWORK_TEST_PROLONGED,
  LOG_BANDWIDTH_TEST_RESULTS,
  UPDATE_NETWORK_TEST_SETTINGS
} from '../action-types';

export const checkNetworkTestNeeded = createRequestActions(CHECK_NETWORK_TEST_NEEDED);

export const networkTestStart = () => ({
  type: NETWORK_TEST_START
});

export const networkTestFail = (error, testResults) => ({
  type: NETWORK_TEST_FAIL,
  payload: { error, testResults }
});

export const networkTestCancel = () => ({
  type: NETWORK_TEST_CANCEL
});

export const networkTestSuccess = testResults => ({
  type: NETWORK_TEST_SUCCESS,
  payload: { testResults }
});

export const networkTestComplete = () => ({
  type: NETWORK_TEST_COMPLETE
});

export const networkTestProlonged = () => ({
  type: NETWORK_TEST_PROLONGED
});

export const updateNetworkTestSettings = settings => ({
  type: UPDATE_NETWORK_TEST_SETTINGS,
  payload: settings
});

export const performConnectivityCheck = createRequestActions(PERFORM_CONNECTIVITY_CHECK);
export const performQualityCheck = createRequestActions(PERFORM_QUALITY_CHECK);
export const logBandwidthTestResults = createRequestActions(LOG_BANDWIDTH_TEST_RESULTS);
