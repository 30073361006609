import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { get, isEmpty } from 'lodash';

import FETCH_SAME_DAY_VISITS_TOGGLE from '../queries/fetch-same-day-visits-toggle.graphql';
import UPDATE_SAME_DAY_VISITS_TOGGLE from '../mutations/update-same-day-visits-toggle.graphql';

const useSameDayVisitsToggle = providerId => {
  const [toggle, setToggle] = useState(false);
  const [error, setError] = useState(undefined);

  const { data, loading: fetchLoading, error: fetchError } = useQuery(FETCH_SAME_DAY_VISITS_TOGGLE, {
    variables: { providerId },
    onCompleted: data => setToggle(get(data, 'providerSettings.visitLeadTimeOptInFlg') === 'Y')
  });

  const [updateSameDayVisitsToggle, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_SAME_DAY_VISITS_TOGGLE
  );

  const onUpdate = values => {
    const formattedValues = {
      ...values,
      toggleValue: values.toggleValue ? 'Y' : 'N'
    };

    updateSameDayVisitsToggle({
      variables: { ...formattedValues, providerId: providerId }
    }).then(result => {
      if (get(result, 'data.sameDaySchedulingToggle.success')) {
        setToggle(values.toggleValue);
      }
      if (!isEmpty(get(result, 'data.sameDaySchedulingToggle.errors'))) {
        setError(get(result, 'data.sameDaySchedulingToggle.errors'));
      }
    });
  };

  return {
    isAcceptingSameDayVisits: toggle,
    isDisabled: get(data, 'providerSettings.disableSameDaySchedulingFlg') === 'Y',
    isLoading: fetchLoading || mutationLoading,
    error: fetchError || mutationError || error,
    onUpdate
  };
};

export default useSameDayVisitsToggle;
