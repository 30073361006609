import React from 'react';
import PropTypes from 'prop-types';
import SmoothCollapse from 'react-smooth-collapse';

import SymptomsList from './symptoms-list';
import ISymptom from '../../interfaces/symptom-interface';

const SymptomsAccordion = ({
  symptoms,
  symptomClass,
  expanded,
  toggleAccordion,
  accordionToggleEnd
}) => (
  <div>
    <p onClick={() => toggleAccordion(symptomClass)} className="symptoms-accordion-class">
      {expanded ? '-' : '+'}
      {'  '}
      {symptomClass}
    </p>
    <SmoothCollapse
      onChangeEnd={() => accordionToggleEnd(symptomClass)}
      expanded={expanded}
    >
      <SymptomsList symptoms={symptoms} listId={symptomClass} />
    </SmoothCollapse>
  </div>
);

SymptomsAccordion.propTypes = {
  symptomClass:       PropTypes.string.isRequired,
  symptoms:           PropTypes.arrayOf(ISymptom).isRequired,
  expanded:           PropTypes.bool.isRequired,
  toggleAccordion:    PropTypes.func.isRequired,
  accordionToggleEnd: PropTypes.func.isRequired
};

export default SymptomsAccordion;
