import { connect } from 'react-redux';

import
  makeGetShouldDisableSymptom
from '../../reducers/selectors/symptoms/makeGetShouldDisableSymptom';
import
  makeGetShouldFocusSymptom
from '../../reducers/selectors/symptoms/makeGetShouldFocusSymptom';
import
  makeGetCanRemoveSymptom
from '../../reducers/selectors/symptoms/makeGetCanRemoveSymptom';
import {
  symptomsFormNoteChange,
  symptomsFormRadioChange,
  symptomUnselected,
  focusSymptomClear
} from '../../actions';
import SymptomsListItem from './symptoms-list-item';

const makeMapStateToProps = () => {
  const getShouldDisableSymptom = makeGetShouldDisableSymptom();
  const getShouldFocusSymptom = makeGetShouldFocusSymptom();
  const getCanRemoveSymptom = makeGetCanRemoveSymptom();

  const mapStateToProps = (state, ownProps) => ({
    radioValue:    state.symptomsForm.radioValues[ownProps.symptom.code] || 'n/a',
    noteValue:     state.symptomsForm.noteValues[ownProps.symptom.code] || '',
    shouldDisable: getShouldDisableSymptom(state, ownProps),
    shouldFocus:   getShouldFocusSymptom(state, ownProps),
    canRemove:     getCanRemoveSymptom(state, ownProps)
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  onRadioChange:       symptomsFormRadioChange,
  onNoteChange:        symptomsFormNoteChange,
  onSymptomUnselected: symptomUnselected,
  onSymptomFocusClear: focusSymptomClear
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SymptomsListItem);
