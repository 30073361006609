import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { mapKeysDeep, transformKey } from '@td/utils';
import { get } from 'lodash';

import { authorize, getCallStatus, updateConsultationStatus } from '../api';
import {
  CONFERENCE_STATUSES,
  ATTENDEE_STATUSES,
  DISCONNECTED_STATUSES,
  NON_CANCELLABLE_STATUSES,
  VIEW_STATES,
  CALL_IN_PROGRESS_STATUSES,
  CONSULTSTATUS_LOCK,
  CONSULTATION_STATUSES
} from '../constants/call-status';
import { playNotification } from '../utils';
import getCallStatusRoute from '../utils/call-status';
import getDevice from '../utils/device';
import { translate, logError } from '@td/shared_utils';
import WebRTC from './WebRTC';

const POLLING_DELAY_MS = 4000;

const WebRTCInitializer = ({ consultation, activeConferenceRS, onCallJoin, onEndCall, options = {} }) => {
  let callStatusInterval;
  const { consultationId, member, provider } = consultation;
  const [callStatus, setCallStatus] = useState(CONFERENCE_STATUSES.READY_TO_START);
  const [attendeesData, setAttendeesData] = useState([]);
  const [device, setDevice] = useState(null);
  const [isMicrophoneAllowed, setMicrophoneAllowed] = useState(false);
  const memberCallData = attendeesData.find(attendee => attendee.externalTypeId === 'member') || {};
  const providerCallData = attendeesData.find(attendee => attendee.externalTypeId === 'provider') || {};
  const [pollingErrorCount, setPollingErrorCount] = useState(0);
  const pollErrorLimit = 120;
  const [shouldPoll, setShouldPoll] = useState(false);
  const [viewState, setViewState] = useState(CONFERENCE_STATUSES.READY_TO_START);
  const [hasActiveCall, setHasActiveCall] = useState(get(activeConferenceRS, 'data.data.hasActiveCall'));
  const [actionAutoCallChecker, setActionAutoCallChecker] = useState(options.startAutoDialerCallAutomatically);
  const isNotDevelopment = process.env.NODE_ENV !== 'development';
  const [previousViewState, setPreviousViewState] = useState(null);

  // Alert the provider if they try to navigate away from the call
  useEffect(() => {
    if (!CALL_IN_PROGRESS_STATUSES.includes(callStatus)) {
      window.onbeforeunload = null;
    } else {
      window.onbeforeunload = event => {
        const message = translate(null, 'autodialer.call_interface.call_in_progress', 'leaving_page_warning');
        if (event) {
          event.returnValue = message;
        }
      };
    }
  }, [callStatus]);

  // Quick implementation to hide the cancel consult button (at the top of consultation page),
  // since it will be difficult to communicate the current call status to where that button is rendered
  useEffect(() => {
    const cancelConsultButton = document.getElementById('cancel_consult');
    if (!cancelConsultButton) return;

    if (NON_CANCELLABLE_STATUSES.includes(callStatus)) {
      cancelConsultButton.classList.add('auto-dialer-hide');
    } else {
      cancelConsultButton.classList.remove('auto-dialer-hide');
    }
  }, [callStatus]);

  useEffect(() => {
    const callStartedOrComplete = DISCONNECTED_STATUSES.includes(callStatus);
    const belowPollingErrLimit = pollingErrorCount < pollErrorLimit;
    // Temporary fix, should consider how to avoid this status properly
    const notWebrtcDeviceError = callStatus !== CONFERENCE_STATUSES.WEBRTC_DEVICE_ERROR;
    setShouldPoll((!callStartedOrComplete && belowPollingErrLimit && notWebrtcDeviceError) || hasActiveCall);
  }, [pollingErrorCount, callStatus, pollErrorLimit, hasActiveCall]);

  useEffect(() => {
    // Request microphone permissions
    if (navigator && navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          setMicrophoneAllowed(true);
        })
        .catch(error => {
          logError(error, 'Unable to get access to microphone');
          setMicrophoneAllowed(false);
        });
    }
  }, []);

  // Get the device based on the provider passed in.
  // At the moment, only Twilio is set up, but in the future we'll want to option to pass
  // in different `dialProvider` types. If a new dialProvider is needed,
  // set up a new adapter for it in the `utils` folder.
  useEffect(() => {
    // This if statement may need to be adjusted when future vendors are added since they may not take a token
    if (!device) {
      authorize(consultationId)
        .then(({ dialProvider, token }) => {
          if (dialProvider && token) {
            const webDevice = getDevice(dialProvider, { setCallStatus }, { token });
            setDevice(webDevice);
          } else {
            setCallStatus(CONFERENCE_STATUSES.AUTH_ERROR);
          }
        })
        .catch(({ status: errorCallStatus }) => setCallStatus(errorCallStatus));
    }
  }, [device]);

  // Logic around updating the status that control the view state by polling conf api
  useEffect(() => {
    if (shouldPoll) {
      callStatusInterval = setInterval(() => {
        getCallStatus(consultationId)
          .then(({ status, attendees = [] }) => {
            const attendeeData = mapKeysDeep(attendees, transformKey);
            setPollingErrorCount(0);
            // This can accidentally update attendeeData to null, need better handling here
            if (attendeeData.length) {
              setAttendeesData(attendeeData);
            }
            setCallStatus(status);
          })
          .catch(_e => {
            const updatedCount = pollingErrorCount + 1;
            setPollingErrorCount(updatedCount);

            if (updatedCount == pollErrorLimit) {
              setCallStatus(CONFERENCE_STATUSES.BAD_REQUEST);
            }
          });
      }, POLLING_DELAY_MS);

      return () => clearInterval(callStatusInterval);
    } else {
      clearInterval(callStatusInterval);
    }
  }, [shouldPoll, pollingErrorCount]);

  useEffect(() => {
    // Temporary fix, should consider how to avoid this status properly
    if (callStatus !== CONFERENCE_STATUSES.WEBRTC_DEVICE_ERROR) {
      setPreviousViewState(viewState);
      setViewState(
        getCallStatusRoute(callStatus, memberCallData.currentStatusCode, providerCallData.currentStatusCode)
      );
    }
  });

  // handle case when provider transfer call to start timer
  useEffect(() => {
    const providerDisconnected = providerCallData.currentStatusCode === ATTENDEE_STATUSES.DISCONNECTED;
    const memberIsBusy = memberCallData.currentStatusCode === ATTENDEE_STATUSES.BUSY;

    if (providerDisconnected || memberIsBusy) {
      onEndCall();
    }

    if (providerCallData.currentStatusCode === ATTENDEE_STATUSES.JOINED) {
      onCallJoin();
    }
  }, [providerCallData.currentStatusCode, memberCallData.currentStatusCode]);

  useEffect(() => {
    memberCallData.currentStatusCode === ATTENDEE_STATUSES.JOINED && playNotification();
  }, [memberCallData.currentStatusCode]);

  return (
    <WebRTC
      consultation={consultation}
      viewState={viewState}
      device={device}
      setCallStatus={setCallStatus}
      setShouldPoll={setShouldPoll}
      setDevice={setDevice}
      memberCallData={memberCallData}
      providerCallData={providerCallData}
      callStatus={callStatus}
      isMicrophoneAllowed={isMicrophoneAllowed}
      hasActiveCall={hasActiveCall}
      setHasActiveCall={setHasActiveCall}
      callStatusInterval={callStatusInterval}
      isNotDevelopment={isNotDevelopment}
      actionAutoCallChecker={actionAutoCallChecker}
      setActionAutoCallChecker={setActionAutoCallChecker}
      onCallJoin={onCallJoin}
      onEndCall={onEndCall}
    />
  );
};

// Consultation obj shape converted exhaustively in AutoDialerInitializer.js
WebRTCInitializer.propTypes = {
  consultation:  PropTypes.object,
  hasActiveCall: PropTypes.bool,
  options:       PropTypes.object
};

WebRTCInitializer.displayName = 'WebRTCInitializer';

export default WebRTCInitializer;
