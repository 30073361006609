import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '@td/shared_utils';
import Modal from '../modal';
import styles from './styles.module.scss';
import ErrorNotification from '../error-notification';
import Divider from '../divider';
import ModalAction from '../modal-action';
import ModalActionBar from '../modal-action-bar';
import { createCalendarSettingsMap } from '../../utils';
import CalendarSettingsRow from './calendar-settings-row';
import { externalAccountProviderIcons } from '../../../../constants';
import { sortExternalCalendars } from '../../utils/calendar';

const TRANSLATION_SCOPE = 'my_schedule.modals.calendars_settings';

const CalendarSettingsModal = ({ account, onSubmit, onClose, initialValues }) => {
  const [activeCalendarsMap, setActiveCalendarsMap] = useState(initialValues || createCalendarSettingsMap(account.calendars));
  const [error, setError] = useState(null);
  const CalendarIcon = externalAccountProviderIcons[account.calendarType];

  const sortedCalendars = useMemo(() => {
    return sortExternalCalendars(account.calendars);
  }, [account.calendars]);

  const handleChange = useCallback((calendar) => {
    if (error) {
      setError(null);
    }

    setActiveCalendarsMap((prevValue) => ({ ...prevValue, [calendar.id]: !prevValue[calendar.id] }));
  }, [error]);

  const handleSubmit = () => {
    const { calendars } = account;

    if (calendars.every(({ id }) => !activeCalendarsMap[id])) {
      const provider = translate(null, 'my_schedule.settings_page.external_calendars_section.providers', account.calendarType);
      setError(translate(null, TRANSLATION_SCOPE, 'errors.empty_selection', {provider}));
      return;
    }

    const isChanged = calendars.some(({ id, active }) => active !== activeCalendarsMap[id]);

    if (!isChanged) {
      setError(translate(null, TRANSLATION_SCOPE, 'errors.no_changes'));
      return;
    }

    onSubmit(calendars.filter(calendar => activeCalendarsMap[calendar.id]));
  };

  return (
    <Modal
      className={styles.calendarSettingsModal}
      title={translate(null, TRANSLATION_SCOPE, 'title')}
      onClose={onClose}
    >
      <div className={styles.calendarSettingsModalContent}>
        <div
          className={styles.calendarSettingsModalSubtitle}
          data-testid="subtitle"
        >
          {translate(null, TRANSLATION_SCOPE, 'subtitle')}
        </div>
        {error && <ErrorNotification text={error} />}
        <Divider />
        <div
          className={styles.externalAccountTile}
          data-testid="account-tile"
        >
          <CalendarIcon width={32} height={32} />
          {account.emailAddress}
        </div>
        <div className={styles.calendarSettingsItems} data-testid="calendars-list">
          {sortedCalendars.map((calendar) => (
            <CalendarSettingsRow
              key={calendar.id}
              calendar={calendar}
              selected={!!activeCalendarsMap[calendar.id]}
              onChange={handleChange}
            />
          ))}
        </div>
        <Divider />
        <ModalActionBar>
          <ModalAction variant="secondary" onClick={onClose}>
            {translate(null, TRANSLATION_SCOPE, 'actions.cancel')}
          </ModalAction>
          <ModalAction variant="primary" onClick={handleSubmit}>
            {translate(null, TRANSLATION_SCOPE, 'actions.confirm')}
          </ModalAction>
        </ModalActionBar>
      </div>
    </Modal>
  );
};

CalendarSettingsModal.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
    emailAddress: PropTypes.string,
    calendarType: PropTypes.string,
    calendars: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default CalendarSettingsModal;
