import React, { Fragment } from 'react';
import { get } from 'lodash';
import ViewEHRAction from '../../consult-queues/components/actions/ViewEHRAction';
import FollowUpAction from '../actions/FollowUpAction.js';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FaEllipsisV } from 'react-icons/fa';
import './ActionsMenu.css';
import PatientListSendMessageAction from '../actions/PatientListSendMessageAction';

const ActionsMenu = ({
  lastConsultationForCurrentProvider,
  nextConsultationForCurrentProvider,
  relationWithCurrentProvider,
  currentConsultationForProvider
}) => {
  const consultationId = get(currentConsultationForProvider, 'id');
  const statusCd = get(relationWithCurrentProvider, 'statusCd');
  const requestOrFollowUpDate = get(nextConsultationForCurrentProvider, 'requestOrFollowUpDate');

  if (statusCd === 'CARETEAMRLTNSTATUS_INACTIVE' || statusCd === 'CARETEAMRLTNSTATUS_TERMINATED') {
    return null;
  }

  const actions = [
    <ViewEHRAction consultationId={consultationId} />,
    <PatientListSendMessageAction
      lastConsultationForCurrentProvider={lastConsultationForCurrentProvider}
      currentConsultationForProvider={currentConsultationForProvider}
      nextConsultationForCurrentProvider={nextConsultationForCurrentProvider}
    />
  ];

  if (!requestOrFollowUpDate) {
    actions.push(<FollowUpAction consultationId={consultationId} />);
  }

  return (
    <span className="kebab-menu">
      <FaEllipsisV size={16} color="#42a6cc" data-tip data-event="click" data-for={consultationId} />
      <ReactTooltip
        id={consultationId}
        place="left"
        type="dark"
        effect="solid"
        globalEventOff="click"
        clickable
        className="patient-list-actions"
        isCapture={true}
        offset={{ right: 30, bottom: 10 }}
      >
        {actions.map((action, index) => (
          <Fragment key={index}>{action}</Fragment>
        ))}
      </ReactTooltip>
    </span>
  );
};

ActionsMenu.propTypes = {
  lastConsultationForCurrentProvider: PropTypes.object,
  nextConsultationForCurrentProvider: PropTypes.object,
  relationWithCurrentProvider: PropTypes.object,
  currentConsultationForProvider: PropTypes.object
};

export default ActionsMenu;
