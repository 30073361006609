import React from 'react';
import { connect } from 'react-redux';

import locationCheck from './location-check';
import getLocationType from '../../Provider/selectors/getLocationType';
import getLocationText from '../../Provider/selectors/getLocationText';
import getLocationList from '../../Provider/selectors/getLocationList';
import { changeConsultNavigationStep } from '../../consult-navigation/actions';

import { clockInLocationInit } from '../../sidebar/provider-clock-in-out/actions';

const mapStateToProps = state => ({
  locationType: getLocationType(state),
  locationText: getLocationText(state),
  locationList: getLocationList(state)
});

const mapDispatchToProps = dispatch => ({
  clockInLocationInit: () => {
    dispatch(clockInLocationInit());
  },
  changeConsultNavigationStep: index => {
    dispatch(changeConsultNavigationStep(index));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(locationCheck);
