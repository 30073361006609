import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { usePatientList } from '../../../PatientListProvider';

import { FaRegCalendarAlt, FaSearch } from 'react-icons/fa';

import CustomSelect from '../CustomSelect';
import InputWithIcon from '../InputWithIcon';
import { STATUS_MAP } from '../../../constants';
import { validationSchema } from './validations';

const initialValues = {
  personName:           '',
  status:               [],
  lastConsultationFrom: '',
  lastConsultationTo:   '',
  nextConsultationFrom: '',
  nextConsultationTo:   '',
  noNextConsultation:   false
};

const CustomDateInput = forwardRef(props => <InputWithIcon {...props} right={<FaRegCalendarAlt size={16} />} />);

const CustomSearchInput = props => <InputWithIcon {...props} left={<FaSearch size={16} />} />;

const Error = ({ name }) => <ErrorMessage component="div" className="validation-msg" name={name} />;

const PatientListFiltersForm = ({ onSubmit }) => {
  const { filters } = usePatientList();

  const removeEmptyFilters = values =>
    Object.entries(values).reduce((a, [k, v]) => (v && !(Array.isArray(v) && !v.length) ? ((a[k] = v), a) : a), {});

  const handleSubmit = values => onSubmit(removeEmptyFilters(values));

  const statusMultiSelectOptions = Object.entries(STATUS_MAP).map(([code, name]) => ({ label: name, value: code }));

  return (
    <div className="filters-panel">
      <Formik
        initialValues={{ ...initialValues, ...filters }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, resetForm, handleChange, errors, touched }) => (
          <Form>
            <div className="filter-container">
              <div className="filter-section">
                <b>{I18n.t('patient_case_load_mgmt_mvp.patient_list.name')}</b>
                <Field
                  name="personName"
                  type="text"
                  placeholder={I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.name_placeholder')}
                  className="filter-input search-input-text"
                  component={CustomSearchInput}
                />

                <b>{I18n.t('patient_case_load_mgmt_mvp.patient_list.status')}</b>
                <Field
                  className="filter-input"
                  name="status"
                  options={statusMultiSelectOptions}
                  component={CustomSelect}
                  placeholder={I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.status_placeholder')}
                  isMulti
                />
              </div>
              <div className="filter-section">
                <b>{I18n.t('patient_case_load_mgmt_mvp.patient_list.last_consult')}</b>
                <div className="filter-row">
                  <b>{I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.last_consult_from')}</b>
                  <div className="date-picker-column">
                    <DatePicker
                      placeholderText={I18n.t(
                        'patient_case_load_mgmt_mvp.patient_list.filters.date_format_placeholder'
                      )}
                      name="lastConsultationFrom"
                      selected={values.lastConsultationFrom}
                      onChange={date => setFieldValue('lastConsultationFrom', date)}
                      maxDate={values.lastConsultationTo ? new Date(values.lastConsultationTo) : new Date()}
                      autoComplete="off"
                      customInput={<CustomDateInput />}
                      className={`filter-input calendar-input ${errors.lastConsultationFrom &&
                        touched.lastConsultationFrom &&
                        'error-border'}`}
                    />
                    <Error name="lastConsultationFrom" />
                  </div>
                  <b>{I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.last_consult_to')}</b>
                  <div className="date-picker-column">
                    <DatePicker
                      placeholderText={I18n.t(
                        'patient_case_load_mgmt_mvp.patient_list.filters.date_format_placeholder'
                      )}
                      name="lastConsultationTo"
                      selected={values.lastConsultationTo}
                      onChange={date => setFieldValue('lastConsultationTo', date)}
                      minDate={new Date(values.lastConsultationFrom)}
                      maxDate={new Date()}
                      autoComplete="off"
                      customInput={<CustomDateInput />}
                      className={`filter-input calendar-input ${errors.lastConsultationTo &&
                        touched.lastConsultationTo &&
                        'error-border'}`}
                    />
                    <Error name="lastConsultationTo" />
                  </div>
                </div>
                <b>{I18n.t('patient_case_load_mgmt_mvp.patient_list.next_consult')}</b>
                <div className="filter-row">
                  <b>{I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.next_consult_from')}</b>
                  <div className="date-picker-column">
                    <DatePicker
                      placeholderText={I18n.t(
                        'patient_case_load_mgmt_mvp.patient_list.filters.date_format_placeholder'
                      )}
                      name="nextConsultationFrom"
                      selected={values.nextConsultationFrom}
                      onChange={date => {
                        setFieldValue('nextConsultationFrom', date);
                        setFieldValue('noNextConsultation', false);
                      }}
                      minDate={new Date()}
                      maxDate={new Date(values.nextConsultationTo)}
                      className={`filter-input calendar-input ${errors.nextConsultationFrom &&
                        touched.nextConsultationTo &&
                        'error-border'}`}
                      autoComplete="off"
                      customInput={<CustomDateInput />}
                    />
                    <Error name="nextConsultationFrom" />
                  </div>

                  <b>{I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.next_consult_to')}</b>
                  <div className="date-picker-column">
                    <DatePicker
                      placeholderText={I18n.t(
                        'patient_case_load_mgmt_mvp.patient_list.filters.date_format_placeholder'
                      )}
                      name="nextConsultationTo"
                      selected={values.nextConsultationTo}
                      onChange={date => {
                        setFieldValue('nextConsultationTo', date);
                        setFieldValue('noNextConsultation', false);
                      }}
                      minDate={values.nextConsultationFrom ? new Date(values.nextConsultationFrom) : new Date()}
                      className={`filter-input calendar-input ${errors.nextConsultationTo &&
                        touched.nextConsultationTo &&
                        'error-border'}`}
                      autoComplete="off"
                      customInput={<CustomDateInput />}
                    />
                    <Error name="nextConsultationTo" />
                  </div>
                </div>

                <div className="filter-row centered">
                  <input
                    name="noNextConsultation"
                    type="checkbox"
                    checked={values.noNextConsultation}
                    onChange={event => {
                      handleChange(event);
                      setFieldValue('nextConsultationFrom', '');
                      setFieldValue('nextConsultationTo', '');
                    }}
                  />
                  <label>{I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.no_next_consult')}</label>
                </div>
              </div>
            </div>

            <div className="divider" />

            <div className="filter-actions">
              <button
                className="button secondary"
                style={{ margin: 0 }}
                onClick={() => resetForm(initialValues)}
                type="button"
              >
                {I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.clear')}
              </button>
              <button className="button no_select submit_button" type="submit">
                {I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.apply')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

PatientListFiltersForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default PatientListFiltersForm;
