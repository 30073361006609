import { ParseError, parsePhoneNumberWithError, isValidNumber } from 'libphonenumber-js';

const DEFAULT_FAILED_PHONE_VALIDATION_ERROR = 'Invalid phone number';

export const validateNumber = (rawNumber, options = {}) => {
  // libphonenumber-js can only validate international number
  const number = isValidNumber(rawNumber) ? rawNumber : `+${options.defaultCountryCode || ''}${rawNumber}`;

  try {
    const phoneNumber = parsePhoneNumberWithError(number, 'US');

    return {
      number,
      invalid: !phoneNumber.isValid(),
      phone:   phoneNumber
    };
  } catch (error) {
    let message = DEFAULT_FAILED_PHONE_VALIDATION_ERROR;

    if (error instanceof ParseError) {
      message = error.message;
    }

    return {
      number,
      message,
      invalid: true
    };
  }
};
