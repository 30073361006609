import { Field } from 'formik';
import InputElement from 'react-input-mask';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const ExpiryField = (
  {
    label,
    styles,
    name = 'healthCardExpiry',
    id = 'healthCardExpiry',
    errors,
    values
  }) => (
    <Fragment>
      <label className={styles.fieldLabel} htmlFor={id}>
        {label}
      </label>
      <Field
        name={name}
        id={id}
      >
        {({ field }) => (
          <InputElement
            {...field}
            type="text"
            className={cx(styles.inputField, errors[name] ? styles.errorField : '')}
            value={values[name]}
            placeholder="MM/DD/YYYY"
            mask="99/99/9999"
          />
      )
      }
      </Field>
      {
      errors[name] &&
      <span className={styles.errorMessage} data-testid="expiry-error">
          {errors[name]}
        </span>
    }
    </Fragment>
);

ExpiryField.propTypes = {
  label:  PropTypes.string,
  styles: PropTypes.object,
  name:   PropTypes.string,
  id:     PropTypes.string,
  errors: PropTypes.shape({
    healthCardExpiry: PropTypes.string
  }),
  values: PropTypes.shape({
    healthCardExpiry: PropTypes.string
  })
};
