import $ from 'jquery';
import React from 'react';
import { useQuery } from '@apollo/client';
import CONSULTATION_PROMPTS from '../queries/consultation-prompts.graphql';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import trackEvent from '../utils/utils';

const ConsultationPrompt = ({ consultationId }) => {
  const { loading, error, data } = useQuery(CONSULTATION_PROMPTS, {
    variables: { consultationId }
  });

  const { providerId } = $('body').data();

  if (error || loading || !data) return null;
  const {
    consultation: { studyProviderPrompts }
  } = data;

  if (studyProviderPrompts === null) return null;

  const prompt = <div className="notice">{studyProviderPrompts}</div>;

  trackEvent({
    event:           'usc-provider-consult-message',
    'app-name':      'provider-web',
    content:         studyProviderPrompts.substring(0, 200),
    consultation_id: consultationId,
    providerId
  });

  return prompt;
};

ConsultationPrompt.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default ConsultationPrompt;
