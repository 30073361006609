import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate, translateMarkupString } from '@td/shared_utils';
import ConfirmationModal from '../confirmation-modal';
import styles from './styles.module.scss';

const TRANSLATION_SCOPE = 'my_schedule.modals.calendars_settings_confirmation';

const CalendarSettingsConfirmationModal = ({ onConfirm, onCancel, onClose }) => (
  <ConfirmationModal
    className={styles.calendarSettingsConfirmationModal}
    title={translate(null, TRANSLATION_SCOPE, 'title')}
    onConfirm={onConfirm}
    onCancel={onCancel}
    onClose={onClose}
    cancelButtonText={translate(null, TRANSLATION_SCOPE, 'actions.cancel')}
    confirmButtonText={translate(null, TRANSLATION_SCOPE, 'actions.confirm')}
  >
    <Fragment>
      <p className={styles.connectingNote}>
        {translateMarkupString(TRANSLATION_SCOPE, 'connect_note', { b: <b /> })}
      </p>
      <p className={styles.disconnectingNote}>
        {translateMarkupString(TRANSLATION_SCOPE, 'disconnect_note', { b: <b /> })}
      </p>
    </Fragment>
  </ConfirmationModal>
);

CalendarSettingsConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CalendarSettingsConfirmationModal;
