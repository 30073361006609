import React, { useCallback } from 'react';
import { translate } from '@td/shared_utils';
import { Toggle } from '../../../components';
import { useRecommendedWorkingHours } from '../../contexts/recommended-working-hours-context';
import styles from './styles.module.scss';

const RecommendedWorkingHoursToggle = React.memo(() => {
  const { recommendedWorkingHoursFlag, updateRecommendedWorkingHoursFlag } = useRecommendedWorkingHours();

  const handleChange = useCallback(
    value => {
      updateRecommendedWorkingHoursFlag({
        variables: {
          recommendWorkHourFlg: value
        }
      });
    },
    [updateRecommendedWorkingHoursFlag]
  );

  return (
    <div className={styles.recommendedWorkingHoursToggle}>
      <Toggle checked={recommendedWorkingHoursFlag} onChange={handleChange} />

      {translate(null, 'my_schedule.recommended_working_hours_toggle', 'label')}
    </div>
  );
});

RecommendedWorkingHoursToggle.displayName = 'RecommendedWorkingHoursToggle';

export default RecommendedWorkingHoursToggle;
