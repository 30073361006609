import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isNil, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styles from './styles.scss';

import TextWithPopup from '../../consult-followup/components/text-with-popup';
import {
  displayPopupForSubjectiveTabSelector,
  outsidePopupListForSubjectiveTabSelector,
  insidePopupListForSubjectiveTabSelector,
  popupTextForSubjectiveTabSelector
} from './redux/selectors';

const PopupForSubjectiveTab = ({ showPopup, outsidePopupList, insidePopupList, popupText }) => {
  useEffect(() => {
    if (isNil(showPopup)) return;
    if (isNil(popupText)) return;
  }, []);

  const mappedText = text => text.map((item, index) => <li key={index}>{item}</li>);

  const showHrAttribute = () => {
    if (!isEmpty(insidePopupList)) {
      return <hr />;
    }
  };

  const mappedPopupText = () =>
    popupText.split('<br><br>').map((item, index) => (
      <div key={index}>
        <div>{item}</div>
        <br />
      </div>
    ));

  const composedTextForPopup = () => (
    <div>
      <ul className={styles.listOfText}>{mappedText(insidePopupList)}</ul>
      {showHrAttribute()}
      <div>{mappedPopupText()}</div>
    </div>
  );

  const showNotesText = () => {
    if (!isEmpty(outsidePopupList)) {
      return <p className={styles.notesText}>Notes:</p>;
    }
  };

  const styleForPopupSpan = () => ({
    fontSize: 12,
    position: 'static'
  });

  return (
    <div>
      {showPopup && (
        <div className={styles.divOfPopup}>
          <ul className={styles.listOfText}>{mappedText(outsidePopupList)}</ul>
          <TextWithPopup
            id="best_practice_for_subjective_notes_popup"
            text="View best practice"
            textStyle={styleForPopupSpan()}
            popupTitle="Best Practice for Subjective Notes"
            popupDescription={composedTextForPopup()}
            popupStyle={styles.popupStyle}
            popupTitleStyle={styles.popupTitleStyle}
          />
          {showNotesText()}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  showPopup:        displayPopupForSubjectiveTabSelector(state),
  outsidePopupList: outsidePopupListForSubjectiveTabSelector(state),
  insidePopupList:  insidePopupListForSubjectiveTabSelector(state),
  popupText:        popupTextForSubjectiveTabSelector(state)
});

PopupForSubjectiveTab.propTypes = {
  showPopup:        PropTypes.bool.isRequired,
  outsidePopupList: PropTypes.array,
  insidePopupList:  PropTypes.array,
  popupText:        PropTypes.string
};

export default connect(mapStateToProps)(PopupForSubjectiveTab);
