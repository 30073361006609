import React from 'react';
import { translate } from '@td/shared_utils';
import renderHTML from 'react-render-html';
import { instructions } from '../../constants';

const GettingStartedBanner = () => (
  <div className="bannerWrapper">
    <div className="bannerTitle">
      {translate(null, 'patient_case_load_mgmt_mvp.new_feature_guided_tour', 'getting_started_banner_title')}
    </div>
    {instructions.map((instruction, index) => (
      <div className="instructionWrapper" key={index}>
        <p className="instructionTitle">{translate(null, instruction, 'instruction_title')}</p>
        <p className="instructionContent">{renderHTML(translate(null, instruction, 'instruction'))}</p>
      </div>
    ))}
  </div>
);

export default GettingStartedBanner;
