import React from 'react';

export const FavoriteDefault = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3798 5.11037L10.4966 5.34562L10.7565 5.38301L15.1224 6.01099C15.4713 6.06624 15.6456 6.51267 15.3531 6.80725L12.2131 9.88739L12.026 10.071L12.0705 10.3293L12.8177 14.6629C12.8724 15.0376 12.4576 15.3236 12.1375 15.1579C12.1371 15.1577 12.1367 15.1575 12.1362 15.1573L8.24937 13.0942L8.01586 12.9703L7.78195 13.0935L3.86376 15.1573C3.86354 15.1574 3.86332 15.1575 3.8631 15.1576C3.54285 15.324 3.12759 15.0379 3.1823 14.6629L3.92947 10.3293L3.974 10.071L3.78687 9.88739L0.646928 6.80726C0.354424 6.51272 0.528619 6.06639 0.877384 6.01101L5.27291 5.38308L5.53512 5.34562L5.65142 5.10765L7.59524 1.13029C7.76747 0.789016 8.2509 0.80867 8.40174 1.12407L8.40169 1.12409L8.40492 1.1306L10.3798 5.11037Z"
      stroke="#808080"
    />
  </svg>
);
