import { reject } from 'lodash';
import {
  ADD_TO_PENDING_MEDICATION_LIST,
  REMOVE_FROM_PENDING_MEDICATION_LIST,
  CLEAR_PENDING_MEDICATION_LIST
} from '../actionTypes';

const DEFAULT_STATE = {
  medications: []
};

const pendingMedicationReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_TO_PENDING_MEDICATION_LIST: {
      const medication = action.payload;
      let { medications } = state;

      medications = reject(medications, med =>
        med.drugName === medication.drugName
      );

      medications.push(medication);

      return {
        ...state,
        medications
      };
    }
    case REMOVE_FROM_PENDING_MEDICATION_LIST: {
      let { medications } = state;

      medications = reject(medications, med =>
        med.drugName === action.payload.drugName
      );

      return { medications };
    }
    case CLEAR_PENDING_MEDICATION_LIST: {
      return { medications: [] };
    }
    default: {
      return state;
    }
  }
};

export { pendingMedicationReducer };
