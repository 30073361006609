import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { translate } from '@td/shared_utils';

import QUICK_DX_LIST_QUERY from './graphql/quick-dx-list.gql';
import SEARCH_DIAGNOSISES_QUERY from './graphql/search.gql';
import CREATE_QUICK_DX_RECORD_MUTATION from './graphql/create-quick-dx-record.gql';
import DELETE_QUICK_DX_RECORD_MUTATION from './graphql/delete-quick-dx-record.gql';
import FETCH_SHORTCUTS from './graphql/fetch-shortcuts.gql';

import { getConsultationSpecialty } from '../utils';

import incomingNotification from 'app/lib/notifications-listener';

export const useQuickDx = ({ searchTerm, onFetched } = {}) =>
  useQuery(SEARCH_DIAGNOSISES_QUERY, {
    variables: {
      term:        searchTerm,
      specialtyCd: getConsultationSpecialty()
    },
    skip:        searchTerm.length < 3,
    onCompleted: data => {
      if (!onFetched) return;

      onFetched(data);
    }
  });

export const useShortcutsList = ({ onFetched, consultationId: id }) =>
  useQuery(FETCH_SHORTCUTS, {
    variables:   { id },
    onCompleted: data => {
      if (!onFetched) return;

      const rawList = _.get(data, 'consultation.diagnosisShortcuts', null);

      try {
        // since data arrives as json string...
        const parsedList = JSON.parse(rawList);
        const list = _.get(parsedList, 'data', []);

        onFetched(list);
      } catch (error) {
        console.error(error);
        onFetched([]);
      }
    }
  });

export const useQuickDxList = ({ onFetched } = {}) => {
  const query = useQuery(QUICK_DX_LIST_QUERY, {
    variables: {
      specialtyCd: getConsultationSpecialty()
    },
    onCompleted: data => {
      if (!onFetched) return;

      onFetched(data);
    }
  });

  return {
    list: _.get(query, 'data.providerQuickDxList', []),
    query
  };
};

const handleFavoriteStatusChangeGraphqlResponse = args => {
  const { result, errorMessagePrefix = '', isFavorite, options } = args;

  const quickDxId = _.get(result, 'quickDx.quickDxId');

  const isSuccess = _.get(result, 'success', true);
  const errorMessage = _.get(result, 'errors[0]', null);
  const onSuccess = _.get(args, 'options.onSuccess', null);

  if (isSuccess && !errorMessage) {
    if (onSuccess) onSuccess({ isFavorite, quickDxId });

    return;
  }

  incomingNotification({
    type:    'error',
    message: `${errorMessagePrefix}${errorMessage}`
  });
};

export const useCreateQuickDxRecord = (diagnosisCd, options = {}) =>
  useMutation(CREATE_QUICK_DX_RECORD_MUTATION, {
    onCompleted: ({ createQuickDx }) =>
      handleFavoriteStatusChangeGraphqlResponse({
        errorMessagePrefix: translate(null, 'quick_dx', 'cannot_add_diagnosis_as_favorite'),
        result:             createQuickDx,
        isFavorite:         true,
        options
      })
  });

export const useDeleteQuickDxRecord = (diagnosisCd, options = {}) =>
  useMutation(DELETE_QUICK_DX_RECORD_MUTATION, {
    onCompleted: ({ deleteQuickDx }) =>
      handleFavoriteStatusChangeGraphqlResponse({
        errorMessagePrefix: translate(null, 'quick_dx', 'cannot_remove_favorite_diagnosis'),
        result:             deleteQuickDx,
        isFavorite:         false,
        options
      })
  });
