import { SELECTED_TAB_CHANGE, CLEAR_COMPLAINT } from '../action-types';

export const DEFAULT_STATE = {
  selectedTabIndex: 0
};

function onSelectedTabChange(state, action) {
  return {
    ...state,
    selectedTabIndex: action.payload.selectedTabIndex
  };
}

function onComplaintClear() {
  return DEFAULT_STATE;
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SELECTED_TAB_CHANGE:
      return onSelectedTabChange(state, action);
    case CLEAR_COMPLAINT:
      return onComplaintClear(state, action);
    default:
      return state;
  }
};
