import React from 'react';
import PatientListFilters from '../PatientListFilters';
import PatientListManageLimits from '../PatientListManageLimits';

const PatientListToolbar = () => (
  <div className="patient-list-toolbar">
    <PatientListFilters />
    <PatientListManageLimits />
  </div>
);

export default PatientListToolbar;
