import validateConsultationStep from './validations/validate-consultation-step';
import { checkFollowUpConsult, checkFollowTimeConflict, getProviderLockedTimeSlots } from './epics';

const consultationInterview = {
  validateConsultationStep,
  checkFollowUpConsult,
  checkFollowTimeConflict,
  getProviderLockedTimeSlots
};

export default consultationInterview;
