const NOTIFICATION_AUDIO_FILE = '/assets/reservation-alert.mp3';

let audioElement = null;

export const playNotification = ({ audioUrl = NOTIFICATION_AUDIO_FILE } = {}) => {
  if (!audioElement) {
    const audioElementInstance = document.createElement('audio');

    audioElementInstance.src = audioUrl;
    audioElementInstance.preload = true;

    document.body.appendChild(audioElementInstance);
    audioElement = audioElementInstance;
  }

  audioElement.currentTime = 0;
  audioElement.play();
};
