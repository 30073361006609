import { connect } from 'react-redux';

import ProviderVideoClockOutPromptButtons from './provider-video-clock-out-prompt-buttons';
import { closeAlert } from 'app/components/alert/actions';
import { clockInProvider } from 'app/sidebar/provider-clock-in-out/actions';
import ProviderClockInRequest from 'app/sidebar/provider-clock-in-out/enums/provider-clock-in-request';

export const COMPONENT_ID = 'PROVIDER_VIDEO_CLOCK_OUT_PROMPT';

export const mapDispatchToProps = dispatch => ({
  remainClockedInForVideo: () => dispatch(closeAlert()),
  clockInForPhone: () => {
    dispatch(closeAlert());
    dispatch(clockInProvider.start({ clockInType: ProviderClockInRequest.NORMAL }));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(ProviderVideoClockOutPromptButtons);
