import { deepFreeze } from '@td/utils';

export default deepFreeze({
  severity: {
    maxLength: 40
  },
  progression: {
    maxLength: 40
  },
  symptomNote: {
    maxLength: 100
  },
  duration: {
    minValue: 0,
    maxValue: 100
  }
});
