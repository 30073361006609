import { createSelector } from '@td/utils';

import getChiefComplaintClassSymptomIdsSet from './getChiefComplaintClassSymptomIdsSet';
import getSelectedSymptomIds from './getSelectedSymptomIds';

export default createSelector(
  getChiefComplaintClassSymptomIdsSet,
  getSelectedSymptomIds,
  (chiefComplaintClassSymptomIdsSet, selectedSymptomIds) =>
    selectedSymptomIds.filter(symptom => !chiefComplaintClassSymptomIdsSet.has(symptom))
);
