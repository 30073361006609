import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { authToken, teladocApi } from '@td/api';
import { Query, ApolloConsumer } from 'react-apollo';
import { Modal } from 'medication_service_ui';
import { PendingProviderOrder } from 'medication_service_ui';
import debounce from 'lodash/debounce';
import HistoryProviderOrder from './history-provider-order.js';
import DRUG_SEARCH_QUERY from '../../prescription/queries/drug-search.graphql';
import COMPLETED_CONSULTATION_QUERY from '../queries/completed-consultation-query.graphql';
import CanadaPrescriptionBuilder from '../../prescription/components/builder';
import styles from '../../prescription/styles.module.scss';
const { row, actionField, fullWidth, checkboxField } = styles;

class CompletedConsultationProviderOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term:                            '',
      selectedMedication:              null,
      isAddingPrescription:            false,
      showModal:                       false,
      disableConfirmationBtn:          false,
      showPrescriptionConfirmationBtn: false,

    };
    this.onInputChange = debounce(this.onInputChange.bind(this), 150);
  }

  componentDidMount() {
    const { consultationParams } = this.props;

    if (consultationParams.statusCode == 'Completed'
        || consultationParams.statusCode == 'CONSULTSTATUS_COM') {
      this.setState({ showPrescriptionConfirmationBtn: true });
    }
  }

  onInputChange(term) {
    if (term.length < 2) { return; }
    this.setState({ term });
  }

  onAddDecision = ({ target: { value } }) => {
    const isAddingPrescription = value === 'yes';
    this.setState({ isAddingPrescription, showModal: isAddingPrescription });
  }

  onSuccessfulAdditionHandler = () => {
    if (this.state.showPrescriptionConfirmationBtn == false) { this.hideModal(); }
    this.setMedication(null);
  }

  setMedication = selectedMedication => {
    this.setState({ selectedMedication });
  }

  handleNewSubmission = async event => {
    event.preventDefault();
    this.setState({ disableConfirmationBtn: true });
    const { consultationId, client } = this.props;
    const url = `/v1/consultations/${consultationId}/add_canada_prescriptions_post_consult`;

    const params = {
      request: {
        consultations: { id: consultationId },
        id: consultationId,
      },
      id: consultationId,
      action: "add_canada_prescriptions_post_consult",
      controller: "consultations",
      consultation: {},
    };

    const response = await teladocApi.post(url, params, authToken.get());

    if (response.status == 200) {
      this.setState({ showModal: false, disableConfirmationBtn: false });

      await client.query({
        query: COMPLETED_CONSULTATION_QUERY,
        variables: { consultationId },
        fetchPolicy: 'network-only'
      });
    }

    this.setState({ showModal: false, disableConfirmationBtn: false });
  }

  hideModal = () => {
    this.setState({ showModal: false });
  }

  showModal = () => {
    this.setState({ showModal: true });
  }

  render() {
    const { consultationId, allowAddition = true } = this.props;

    const {
      term,
      selectedMedication,
      isAddingPrescription,
      showModal,
      showPrescriptionConfirmationBtn
    } = this.state;

    return (
      <Query query={COMPLETED_CONSULTATION_QUERY}
        variables={{ consultationId }}>
        {
        ({ data: { currentUserProvider: { consultation: { globalId, providerOrders = [] } = {} } = {} } }) => {
          const hasAddedPrescriptions = !!providerOrders.length;
          const yesChecked = isAddingPrescription || hasAddedPrescriptions;

          return (
            <div>
              <HistoryProviderOrder providerOrders={providerOrders} />
              <br />
              <br />

              <div
                id="activate-add-prescription-modal"
                className="prescription-modal"
                style={{ marginBottom: '0.5rem' }}
              >
                <p>Are you adding a prescription?</p>
                <label className="radio">
                  <input
                    id="activate-add-prescription-modal-no"
                    name="isAddingPrescription"
                    type="radio"
                    value="no"
                    disabled={hasAddedPrescriptions}
                    checked={!yesChecked}
                    onChange={this.onAddDecision}
                  />No
                </label>
                <label className="radio">
                  <input
                    id="activate-add-prescription-modal-yes"
                    name="isAddingPrescription"
                    type="radio"
                    value="yes"
                    checked={yesChecked}
                    onChange={this.onAddDecision}
                  />Yes
                </label>

                { yesChecked && allowAddition &&
                  <a
                    onClick={this.showModal}
                    className="add-prescription-link"
                  >{ hasAddedPrescriptions ? 'Add another prescription' : 'Add a prescription' }</a>
                }
              </div>

              <Modal show={showModal} onClose={this.hideModal}>
                <Query query={DRUG_SEARCH_QUERY}
                  variables={{ term, consultationId }}
                  skip={!term.length}>
                  {
                    ({ loading, error, data: { searchRx: { medications = [] } = {} } = {} }) => (
                      <ApolloConsumer>
                        {client => (
                          <CanadaPrescriptionBuilder
                            onSuccessfulAdditionHandler={this.onSuccessfulAdditionHandler}
                            medications={medications}
                            consultationId={globalId}
                            loading={loading}
                            onInputChange={this.onInputChange}
                            onChange={this.setMedication}
                            selectedMedication={selectedMedication}
                            apolloClient={client}
                          />)}
                      </ApolloConsumer>
                    )
                  }
                </Query>

                {
                  providerOrders.length && (
                    <PendingProviderOrder
                      providerOrders={providerOrders}
                      submissionHandlerFunc={this.handleNewSubmission}
                      showConfirmationBtn={showPrescriptionConfirmationBtn}
                      disableBtn={this.state.disableConfirmationBtn}
                    />
                  )
                }
              </Modal>
            </div>
          );
        }
      }
      </Query>
    );
  }
}

CompletedConsultationProviderOrder.propTypes = {
  providerOrders: PropTypes.arrayOf(PropTypes.object)
};

export default CompletedConsultationProviderOrder;
