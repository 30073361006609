exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3vdPysURka9T5Uc3nD_CSX {\n  min-height: 200px; }\n\n._3miiQLxs8j9mxKviPm885c {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px; }\n", ""]);

// exports
exports.locals = {
	"explanationModalContent": "_3vdPysURka9T5Uc3nD_CSX",
	"explanationModalContent": "_3vdPysURka9T5Uc3nD_CSX",
	"explanationModalDescription": "_3miiQLxs8j9mxKviPm885c",
	"explanationModalDescription": "_3miiQLxs8j9mxKviPm885c"
};