import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import SurveySummary from './survey-summary-container';
import { SAFETY_PLAN_QUESTION_CD } from '../../components/objective-tab/mental-status-exam/constants';
import { translate } from '@td/shared_utils';

const MentalStatusExamSummary = ({ surveyState, surveyStructure, customTextAnswers }) => {
  const addSafetyPlan = () => {
    const safetyPlanStruct = {
      questionCode: SAFETY_PLAN_QUESTION_CD,
      question:     translate(null, 'ehr.mh.objective_tab.mental_status_exam_survey.safety_plan', 'title'),
      choices:      [{ answer: ' ', answerCode: 'TEXT' }]
    };
    if (get(surveyStructure, 'sections[2].questions')) {
      // Adds the safety plan as 3rd question in the specified section
      surveyStructure.sections[2].questions.splice(2, 0, safetyPlanStruct);
    }
  };

  useEffect(addSafetyPlan, []);

  return (
    <React.Fragment>
      <SurveySummary
        surveyState={surveyState}
        customTextAnswers={customTextAnswers}
        hideQuestionLabels={false}
        surveyStructure={surveyStructure}
      />
    </React.Fragment>
  );
};

MentalStatusExamSummary.propTypes = {
  surveyState:       PropTypes.object,
  surveyStructure:   PropTypes.object,
  customTextAnswers: PropTypes.object
};

export default MentalStatusExamSummary;
