import {
  SET_CLOCK_IN_OUT_SETTINGS,
  SET_CLOCK_IN_OUT_LOCATION_SETTINGS,
  ENABLE_REQUEST_MODE,
  DISABLE_REQUEST_MODE
} from '../action-types';

export const DEFAULT_STATE = {
  combineVideoPhoneClockInButton: true,
  requestMode:                    false,
  requestClockInType:             '',
  location:                       {
    type:          {},
    text:          '',
    list:          [],
    useForClockIn: false
  }
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CLOCK_IN_OUT_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case SET_CLOCK_IN_OUT_LOCATION_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case ENABLE_REQUEST_MODE:
      return {
        ...state,
        requestMode:        true,
        requestClockInType: action.payload.clockInType
      };
    case DISABLE_REQUEST_MODE:
      return {
        ...state,
        requestMode:        false,
        requestClockInType: ''
      };
    default:
      return state;
  }
};
