import React, { useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons';
import CallDetailsPopover from '../call-details-popover';
import { I18n } from '@td/shared_utils';
import styles from './styles.module.scss';

const CallDetailsButton = ({ consultation, visitType, pipWindow }) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <React.Fragment>
      <button
        className={styles.callDetailsButton}
        onClick={() => setIsShow(!isShow)}
      >
        <I18n scope="call_details" text="popover_button"/>
        {
          isShow
            ? <ArrowUpIcon width={8} height={8}/>
            : <ArrowDownIcon width={8} height={8}/>
        }
      </button>
      <CallDetailsPopover
        isShow={isShow}
        pipWindow={pipWindow}
        consultation={consultation}
        visitType={visitType}
      />
    </React.Fragment>
  );
};

export default CallDetailsButton;
