import { connect } from 'react-redux';
import SurveySummary from './survey-summary';

const mapStateToProps = state => ({
  surveyState: state.surveys
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SurveySummary);
