exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._2jdQiQPQp_5_1myuv5GD2w {\n  display: flex;\n  flex-direction: column;\n  gap: 20px; }\n\n._2oII2HHyVPHrX31nGrDAvn {\n  line-height: 16px;\n  color: #7C7D74;\n  margin-top: 20px; }\n\n._2p_xZ8bBE680a294xtnwZe {\n  width: 75%;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  margin: 0 auto; }\n\n._32blfD4JluMvXTjXmqp5yW {\n  display: flex;\n  align-items: center;\n  padding: 0 16px;\n  border: 1px solid #E9E6E0;\n  cursor: pointer; }\n\n._19JvuMDjBBV0Lo1c7fbdxO {\n  color: #7C7D74; }\n\n.iIHEXIK0gQjRUBhuSzLxm {\n  font-size: 14px;\n  font-weight: bold;\n  color: #244861;\n  margin: 12px 16px; }\n", ""]);

// exports
exports.locals = {
	"connectExternalCalendarModalContent": "_2jdQiQPQp_5_1myuv5GD2w",
	"connectExternalCalendarModalContent": "_2jdQiQPQp_5_1myuv5GD2w",
	"connectExternalCalendarModalSubtitle": "_2oII2HHyVPHrX31nGrDAvn",
	"connectExternalCalendarModalSubtitle": "_2oII2HHyVPHrX31nGrDAvn",
	"emailProviders": "_2p_xZ8bBE680a294xtnwZe",
	"emailProviders": "_2p_xZ8bBE680a294xtnwZe",
	"emailProvider": "_32blfD4JluMvXTjXmqp5yW",
	"emailProvider": "_32blfD4JluMvXTjXmqp5yW",
	"additionalSetupNote": "_19JvuMDjBBV0Lo1c7fbdxO",
	"additionalSetupNote": "_19JvuMDjBBV0Lo1c7fbdxO",
	"providerTitle": "iIHEXIK0gQjRUBhuSzLxm",
	"providerTitle": "iIHEXIK0gQjRUBhuSzLxm"
};