import { createSelector } from '@td/utils';

const getSymptomFocusClass = (state, props) =>
  state.symptomsFocus.symptomsToFocus[props.symptom.code];
const getSymptomListId = (_, props) => props.listId;
const getSymptomAccordionExpandFinished = (state, props) =>
  state.symptomAccordions.expandFinished.has(props.listId);

const makeGetShouldFocusSymptom = () =>
  createSelector(
    getSymptomFocusClass,
    getSymptomListId,
    getSymptomAccordionExpandFinished,
    (symptomFocusClass, symptomListId, symptomAccordionExpandFinished) =>
      symptomFocusClass === symptomListId && symptomAccordionExpandFinished
  );

export default makeGetShouldFocusSymptom;
