import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { has } from 'lodash';
import moment from 'moment';
import { AvailabilityCheckModal } from '../';

import DISPLAY_SHIFTS_QUERY from '../../my-schedule/queries/provider-profile-schedules.graphql';

import { scheduleTypes } from '../../my-schedule/constants';

const { PROVSCHEDEVENT_ATB, PROVSCHEDEVENT_RH } = scheduleTypes;

const AvailabilityCheckContainer = ({ providerId }) => {
  /*
   * Queries / mutations
   */
  const { loading: loadingDisplayShift, data: responseProviderProfile, error: errorDisplayShift } = useQuery(
    DISPLAY_SHIFTS_QUERY,
    {
      variables: { providerId }
    }
  );

  /*
   * Render
   */
  if (
    loadingDisplayShift ||
    errorDisplayShift ||
    !responseProviderProfile ||
    !has(responseProviderProfile, 'providerProfile.schedules')
  ) {
    return null;
  }

  const { schedules } = responseProviderProfile.providerProfile;

  const rhWithRemainingOccurrences = ({
    eventTypeCode,
    endDateTime = null,
    recurrenceRuleEndDt = null,
    schedulingTimeSpanDays = 90
  }) => {
    const endDate = moment(endDateTime);
    if (eventTypeCode !== PROVSCHEDEVENT_RH.eventTypeCode || !endDate.isValid()) return false;

    const recurrenceDate = moment(recurrenceRuleEndDt);

    return recurrenceDate.isValid()
      ? recurrenceDate.isAfter(Date.now())
      : endDate.add(schedulingTimeSpanDays, 'days').isAfter(Date.now());
  };

  const futureATB = ({ eventTypeCode, endDateTime = null }) =>
    eventTypeCode === PROVSCHEDEVENT_ATB.eventTypeCode && moment(endDateTime).isSameOrAfter(Date.now());

  const futureWorkingSchedules = schedules.filter(
    schedule => rhWithRemainingOccurrences(schedule) || futureATB(schedule)
  );

  return futureWorkingSchedules.length === 0 ? <AvailabilityCheckModal /> : null;
};

AvailabilityCheckContainer.propTypes = {
  providerId: PropTypes.number.isRequired
};

export default AvailabilityCheckContainer;
