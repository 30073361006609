import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  INITIATED,
  RINGING,
  CONNECTED,
  CONFERENCE_IN_PROGRESS
} from '../lib/states';
import { isEnabledToCall } from '../lib/conferenceCall';

export const ConferenceParticipantCall = props => {
  if (props.conferenceStatus === CONFERENCE_IN_PROGRESS) {
    return (
      <div className="call-actions">
        <ConnectDisconnectIcon
          callStatus={props.callStatus}
          onRedial={props.onRedial}
          onDisconnect={props.onDisconnect}
        />
        <MuteUnmuteIcon
          callStatus={props.callStatus}
          muted={props.muted}
          mutable={props.mutable}
          onMuteToggle={props.onMuteToggle}
        />
      </div>
    );
  } else {
    return <div />;
  }
};

ConferenceParticipantCall.propTypes = {
  conferenceStatus: PropTypes.string.isRequired,
  callStatus:       PropTypes.string.isRequired,
  muted:            PropTypes.bool.isRequired,
  mutable:          PropTypes.bool.isRequired,
  onRedial:         PropTypes.func.isRequired,
  onDisconnect:     PropTypes.func.isRequired,
  onMuteToggle:     PropTypes.func.isRequired
};

export const ConnectDisconnectIcon = ({ callStatus, onRedial, onDisconnect }) => {
  if (callStatus === INITIATED) {
    return <img src="/assets/loader_tiny.gif" alt="Dialing..." id="dialing_loader" />;
  } else if (callStatus === RINGING || callStatus === CONNECTED) {
    return <div className="icon hangup" onClick={onDisconnect} />;
  } else if (isEnabledToCall(callStatus)) {
    return <div className="icon dial" onClick={onRedial} />;
  } else {
    return <div className="icon" />;
  }
};

ConnectDisconnectIcon.propTypes = {
  callStatus:   PropTypes.string.isRequired,
  onRedial:     PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired
};

export const MuteUnmuteIcon = ({ callStatus, muted, onMuteToggle, mutable }) => {
  if (!mutable) { return null; }

  if (callStatus === CONNECTED && !muted) {
    return <div className="icon mute" onClick={onMuteToggle} />;
  } else if (callStatus === CONNECTED && muted) {
    return <div className="icon unmute" onClick={onMuteToggle} />;
  } else {
    return null;
  }
};

MuteUnmuteIcon.propTypes = {
  callStatus:   PropTypes.string.isRequired,
  muted:        PropTypes.bool.isRequired,
  onMuteToggle: PropTypes.func.isRequired,
  mutable:      PropTypes.bool.isRequired
};

export default connect(
  ({ conference }) => ({ conferenceStatus: conference.status }),
  {}
)(ConferenceParticipantCall);
