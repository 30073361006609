import _ from 'lodash';
import { createSelector } from '@td/utils';

const getDropdownValue = dropdownName => state =>
  state.durationSeverityProgressionForm.formValues[dropdownName];
const getDropdownOptions = dropdownLabel => state =>
  state.durationSeverityProgression[`${dropdownLabel}Options`];

const makeGetDropdownSelectedLabel = (dropdownName, dropdownLabel) =>
  createSelector(
    getDropdownValue(dropdownName),
    getDropdownOptions(dropdownLabel),
    (dropdownValue, dropdownOptions) =>
      _.chain(_.find(dropdownOptions, ['value', dropdownValue]))
        .get('label')
        .value()
  );

export const getDurationSelectedLabel = makeGetDropdownSelectedLabel('duration_cd', 'duration');
export const getProgressionSelectedLabel = makeGetDropdownSelectedLabel(
  'progression_cd',
  'progression'
);
export const getSeveritySelectedLabel = makeGetDropdownSelectedLabel('severity_cd', 'severity');
