import { createSelector } from '@td/utils';

import getSymptomsById from './getSymptomsById';
import getChiefComplaintClassSymptomIds from './getChiefComplaintClassSymptomIds';

export default createSelector(
  getChiefComplaintClassSymptomIds,
  getSymptomsById,
  (chiefComplaintClassSymptomIds, symptomsById) =>
    chiefComplaintClassSymptomIds.map(symptomId => symptomsById[symptomId])
);
