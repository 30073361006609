import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPersonalHealthRecords } from '../actions/index';
import PersonalHealthRecord from './personalHealthRecord';

const EMPTY_ROW = [
  {
    Value: "No reported health data",
    Type:   null,
    Source: null,
    Date:   null,
    Show: null
  }
];

@connect(
  state => ({
    personalHealthDocuments: state.personalHealthDocuments.personalHealthDocuments,
    fetched: state.personalHealthDocuments.fetched
  }),
  { fetchPersonalHealthRecords }
)

class PersonalHealthRecordList extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  componentWillMount(){
    this.props.fetchPersonalHealthRecords(this.props.memberId);
  }

  render() {
    let personalHealthDocuments = [];

    if (this.props.fetched && this.props.personalHealthDocuments.length > 0){
      personalHealthDocuments = this.props.personalHealthDocuments.map((value) =>
       ({
          Value:  value.getLastMeasurementReading(),
          Type:   value.getType(),
          Source: value.getDataSourceName(),
          Date:   value.getLast().getDate(),
          Show: <PersonalHealthRecord data={value} />
        })
      );
    }else{
      personalHealthDocuments = EMPTY_ROW
    }

    if (this.props.fetched && this.props.personalHealthDocuments.length == 0){
      $('#personal_health_records').removeClass('review_required');
      $('#personal_health_records').find('.review').remove();
      $('#personal_health_records').find('.reviewed_tick').remove();
    }

    return (
      <div>
        <table className="personal_health_records_table">
          <thead>
            <tr>
              <th>Last Value</th>
              <th>Type</th>
              <th>Source</th>
              <th>Date</th>
              <th>Show</th>
            </tr>
          </thead>
          <tbody>
            {personalHealthDocuments.map(document => (
              <tr key={document.Value}>
                <td>{document.Value}</td>
                <td>{document.Type}</td>
                <td>{document.Source}</td>
                <td>{document.Date}</td>
                <td>{document.Show}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PersonalHealthRecordList;
