import {
  SET_FORM_FIELD,
  RESET_WEIGHT_FORM,
  SUBMIT_WEIGHT_FORM_START,
  SUBMIT_WEIGHT_FORM_SUCCESS,
  SUBMIT_WEIGHT_FORM_FAIL,
  VALIDATE_WEIGHT_FORM,
  VALIDATE_WEIGHT_FORM_START,
  VALIDATE_WEIGHT_FORM_SUCCESS,
  VALIDATE_WEIGHT_FORM_FAIL,
  TOGGLE_PATIENT_IS_MINOR,
  PARTIAL_RESET_WEIGHT_FORM
} from './actionTypes';

const DEFAULT_STATE = {
  fetched:        false,
  isLoading:      false,
  memberParams:   {},
  isPatientMinor: false,
  updatedWeight:  null,
  message:        '',
  validation:     { error: {}, valid: false, pending: false },
  formData:       {
    weight:  '',
    touched: false
  },
  submitResult: { pending: false, success: false, failed: false }
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_PATIENT_IS_MINOR: {
      return Object.assign(
        {}, state,
        { isPatientMinor: !state.isPatientMinor }
      );
    }
    case SUBMIT_WEIGHT_FORM_START: {
      let submitResult = state.submitResult;
      submitResult = {
        pending: true,
        success: false,
        failed:  false
      };

      return {
        ...state,
        fetched:   false,
        isLoading: true,
        submitResult
      };
    }
    case SUBMIT_WEIGHT_FORM_SUCCESS: {
      let submitResult = state.submitResult;
      const { weight } = action.payload;

      submitResult = {
        pending: false,
        success: true,
        failed:  false
      };

      return {
        ...state,
        fetched:   true,
        isLoading: false,
        submitResult,
        updatedWeight: weight
      };
    }
    case SUBMIT_WEIGHT_FORM_FAIL: {
      let submitResult = state.submitResult;
      submitResult = {
        pending: false,
        success: false,
        failed:  true
      };

      return {
        ...state,
        isLoading: false,
        fetched:   false,
        submitResult
      };
    }

    case SET_FORM_FIELD: {
      const { field, value } = action.payload;
      const formData = state.formData;
      formData[field] = value;
      formData.touched = true;

      return {
        ...state,
        formData
      };
    }

    case VALIDATE_WEIGHT_FORM: {
      let validation = state.validation;

      validation = {
        pending: true,
        valid:   false,
        error:   {}
      };

      return {
        ...state,
        validation
      };
    }

    case VALIDATE_WEIGHT_FORM_START: {
      let validation = state.validation;

      validation = {
        pending: true,
        valid:   false,
        error:   {}
      };

      return {
        ...state,
        validation
      };
    }
    case VALIDATE_WEIGHT_FORM_SUCCESS: {
      let validation = state.validation;

      validation = {
        pending: false,
        valid:   true,
        error:   {}
      };

      return {
        ...state,
        validation
      };
    }
    case VALIDATE_WEIGHT_FORM_FAIL: {
      let validation = state.validation;

      validation = {
        pending: false,
        valid:   false,
        error:   action.payload
      };

      return {
        ...state,
        validation
      };
    }
    case RESET_WEIGHT_FORM: {
      let validation = state.validation;
      validation = {
        pending: false,
        valid:   false,
        error:   {}
      };
      let formData   = state.formData;
      formData = { weight: '', touched: false };

      return {
        ...state,
        validation,
        formData
      };
    }
    case PARTIAL_RESET_WEIGHT_FORM: {
      return {
        ...state,
        submitResult: { pending: false, success: false, failed: false }
      };
    }
    default: {
      return state;
    }
  }
};
