import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { needsResponseMessages } from '../Message/actions';
import ReactDOM from 'react-dom';

const MESSAGE_COUNT_ICON_COLOR = '#aa1010';
const MESSAGE_COUNT_COLOR = '#fff';

const blockStyle = {
  marginLeft: '1.1em'
};

const messageCountStyle = {
  display:         'inline',
  backgroundColor: MESSAGE_COUNT_ICON_COLOR,
  color:           MESSAGE_COUNT_COLOR,
  borderRadius:    '3px',
  padding:         '0.1em 0.5em',
  fontSize:        'small',
  fontWeight:      'normal'
};

const Counter = ({ isNeedsResponseMessages, needsResponseMessages: fetchNeedsResponseMessages }) => {
  useEffect(() => {
    fetchNeedsResponseMessages();
  }, []);

  if (isNeedsResponseMessages > 0) {
    return (
      <div style={blockStyle}>
        <div className="message-count" style={messageCountStyle}>
          {isNeedsResponseMessages}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => ({
  isNeedsResponseMessages: state.needsResponseMessagesReducer.needsResponseMessages
});
export default connect(mapStateToProps, { needsResponseMessages })(Counter);

Counter.propTypes = {
  isNeedsResponseMessages: PropTypes.number,
  needsResponseMessages:   PropTypes.func
};
