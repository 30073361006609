import {
  FETCH_PROVIDER_START,
  FETCH_PROVIDER_SUCCESS,
  FETCH_PROVIDER_FAIL,
  UPDATE_PROVIDER_SUCCESS,
  UPDATE_PROVIDER_FAIL
} from './actionTypes';

const DEFAULT_STATE = {
  fetched:   false,
  isLoading: false,
  message:   '',
  provider:  {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_PROVIDER_START: {
      return {
        ...state,
        fetched:   false,
        isLoading: true
      };
    }
    case FETCH_PROVIDER_SUCCESS: {
      const provider = action.payload.data.provider;
      return {
        ...state,
        fetched:   true,
        isLoading: false,
        provider
      };
    }
    case FETCH_PROVIDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        fetched:   false
      };
    }
    case UPDATE_PROVIDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        fetched:   false
      };
    }
    case UPDATE_PROVIDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        fetched:   false
      };
    }
    default: {
      return state;
    }
  }
};
