import {
  NON_TELADOC_SERVICES_THAT_SUPPORT_PCP_REFERAL,
  FOLLOWUP_APPOINTMENT_OPTIONS,
  FOLLOWUP_SECTIONS
} from '../constants';
import { usePcpSelectionDom } from '../dom-integration';
import { translate } from '@td/shared_utils';

const TRANSLATION_SCOPE = 'consult_followup.errors';

const validateConsultFollowup = state => {
  const errors = [];
  const addError = (key, message) =>
    errors.push({
      validator: {
        invalid: true,
        message
      },
      validatorKey: key
    });

  let yesContinueTreat = '';
  const contTreatExist = document.getElementById('continue_to_treat_yes');
  if (contTreatExist != null) yesContinueTreat = contTreatExist.checked;

  const countryCode = document.getElementById('consult_form').getAttribute('data-country-code');
  const isUSRegion = countryCode === 'USA';
  const consultSpecialtyName = document.getElementById('consult_form').getAttribute('data-consult-specialty-name');
  const isMentalHealth = consultSpecialtyName === 'Mental Health';
  const patient_management_tools_v2 = window.FEATURE_TOGGLES.patient_management_tools_v2;
  const patient_management_tools_v2_enabled = patient_management_tools_v2 && isUSRegion && isMentalHealth;

  if (!patient_management_tools_v2_enabled || (yesContinueTreat && patient_management_tools_v2_enabled)) {
    if (!state.selectedAppointmentOption) {
      addError(undefined, 'You must specify appointment option'); // TODO: inline
    } else if (state.selectedAppointmentOption === FOLLOWUP_APPOINTMENT_OPTIONS.PROVIDER_SCHEDULED) {
      // handled by CoffeeScript
    } else if (state.selectedAppointmentOption === FOLLOWUP_APPOINTMENT_OPTIONS.NO_APPOINTMENT) {
      const value = parseInt(state.noFurtherCareDays);
      if (!value) {
        addError(FOLLOWUP_SECTIONS.NO_APPOINTMENT, translate(null, TRANSLATION_SCOPE, 'num_of_days_missing'));
      }
      if (isNaN(value)) {
        addError(FOLLOWUP_SECTIONS.NO_APPOINTMENT, translate(null, TRANSLATION_SCOPE, 'num_of_days_not_numeric'));
      } else if (value < state.noFurtherCareMinDays || value > state.noFurtherCareMaxDays) {
        addError(
          FOLLOWUP_SECTIONS.NO_APPOINTMENT,
          translate(
            null,
            TRANSLATION_SCOPE,
            patient_management_tools_v2_enabled ? 'num_of_days_not_in_range_pclm' : 'num_of_days_not_in_range',
            {
              min_days: state.noFurtherCareMinDays,
              max_days: state.noFurtherCareMaxDays
            }
          )
        );
      }
    }
  }

  const isSelectedWithoutFeature = service => {
    const selectedService = state.selectedTeladocServices.find(s => s.serviceCode === service.code);
    return selectedService && !selectedService.featureCode && service.features.length > 1;
  };

  for (const service of state.teladocServices) {
    if (isSelectedWithoutFeature(service)) {
      addError(
        FOLLOWUP_SECTIONS.TELADOC_SERVICES,
        translate(null, TRANSLATION_SCOPE, 'service_feature_missing', { service: service.displayName })
      );
    }
  }

  const pcpSelectionDom = usePcpSelectionDom();

  if (
    NON_TELADOC_SERVICES_THAT_SUPPORT_PCP_REFERAL.includes(state.selectedNonTeladocService) &&
    !pcpSelectionDom.getValue()
  ) {
    addError(FOLLOWUP_SECTIONS.NON_TELADOC_SERVICES, translate(null, TRANSLATION_SCOPE, 'pcp_missing'));
  }

  const followupInput = document.getElementById('followup_consult_date');
  const selectedFollowupDate = followupInput && followupInput.value;
  const browserTimezone = moment.tz.guess();

  if (selectedFollowupDate) {
    const selectedDate = moment.tz(selectedFollowupDate, 'MM/DD/YYYY h:mm A', browserTimezone);
    const currentDate = moment.tz(moment(), browserTimezone);
    if (selectedDate.isSameOrBefore(currentDate)) {
      addError(undefined, translate(null, TRANSLATION_SCOPE, 'visit_not_in_future'));
    }
  }

  return errors;
};

export default validateConsultFollowup;
