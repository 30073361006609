import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import ReactAccordion from './accordion/react-accordion';
import NutPlanSectionFactory from './nut-plan-sections/nut-plan-section-factory';
import PatientInfo from '../interfaces/patient-info-interface';
import './nut-plan.scss';

export default class NutritionPlan extends Component {
  constructor(props) {
    super(props);
    this.controlNamePrefix =
      'interview[provider_intake_surveys][SURVEYMNEMONIC_NUTPLAN][sections][SECTIONMNEMONIC_NUTPLAN_INCLUDEGUIDE]';
    this.includeGuideQuestions = this.props.nutritionPlanSections[
      this.props.nutritionPlanSections.length - 1
    ].questions;
    this.onClickHandler = this.onClickHandler.bind(this);
    this.toggleNextButton = this.toggleNextButton.bind(this);
    this.state = {
      sections:          this.prepareSections(),
      isChecked:         this.includeGuideQuestions.Q_6_1.response !== 'NO',
      isPlanTabSelected: false
    };
  }

  onClickHandler() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  prepareSections() {
    const sections = [];
    this.props.nutritionPlanSections.forEach((section, index) => {
      sections.push(cloneDeep(section));
      sections[index].reviewed = JSON.parse(section.reviewed || false);
    });
    return sections;
  }

  toggleNextButton(showButton) {
    const continueButton = $('a.continue, #submit_consult');
    const toggleFunction = showButton ? 'show' : 'hide';
    if (this.props.isPlanSelected) {
      continueButton[toggleFunction]();
    } else if (this.state.isPlanTabSelected) {
      continueButton.show();
    }
    if (this.props.isPlanSelected !== this.state.isPlanTabSelected) {
      this.setState({ isPlanTabSelected: this.props.isPlanSelected });
    }
  }

  render() {
    const { validationErrors } = this.props;

    const reviewRequiredValidationError = validationErrors.find(
      ({ validatorKey }) => validatorKey === 'nutritionReviewAllSectionsRequired'
    );

    return (
      <div id="supplementGuide">
        <ReactAccordion
          sectionBuilder={NutPlanSectionFactory}
          sections={this.state.sections}
          additionalData={this.props.patientInfo}
          reviewedHandler={this.toggleNextButton}
        />

        <div className="include-supplement-guide">
          <input type="hidden" name={`${this.controlNamePrefix}[questions][Q_6_1][response]`} value="NO" />
          <input
            id="include-guide"
            type="checkbox"
            name={`${this.controlNamePrefix}[questions][Q_6_1][response]`}
            value="YES"
            onChange={this.onClickHandler}
            checked={this.state.isChecked}
          />
          <label htmlFor="include-guide">{this.includeGuideQuestions.Q_6_1.question}</label>
        </div>

        {reviewRequiredValidationError && (
          <div className="interview_inline_validation_error show">{reviewRequiredValidationError.message}</div>
        )}
      </div>
    );
  }
}

NutritionPlan.propTypes = {
  nutritionPlanSections: PropTypes.arrayOf(PropTypes.object),
  patientInfo:           PatientInfo,
  isPlanSelected:        PropTypes.bool,
  validationErrors:      PropTypes.array
};
