import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class ConferencePhoneCountryCodeSelect extends Component {
  static propTypes = {
    // Should be in the format <code>-<3_letter_country_code> e.g. 1-USA
    value:             PropTypes.string.isRequired,
    // Will be called with the new value in the same format as above
    onChange:          PropTypes.func.isRequired,
    countryPhoneCodes: PropTypes.arrayOf(
                        PropTypes.shape({
                          country_cd:           PropTypes.string.isRequired,
                          country_name:         PropTypes.string.isRequired,
                          telephone_country_cd: PropTypes.string
                        })
                      ).isRequired
  }

  static defaultProps = {
    countryPhoneCodes: []
  }

  render = () => (
    <div className="form-element select">
      <select
        className="country-phone-code-select"
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {
          this.props.countryPhoneCodes.map(country => {
            const hasPhoneCode = !_.isNil(country.telephone_country_cd);
            const phoneCode = hasPhoneCode ? `${country.telephone_country_cd}` : 'NA';
            // Just country_cd is not enough for unique identification of an option
            // as there may be multiple options for one and the same country
            // (different possible phone codes)
            // Just phoneCode is also not enoguh, as there are distinct countries
            // using one and the same country phone code (e.g. USA, Canada)
            const uid = `${phoneCode}-${country.country_cd}`;
            return (
              <option
                key={uid}
                value={uid}
                disabled={!hasPhoneCode}
              >
                {`${country.country_name} +${phoneCode}`}
              </option>
            );
          })
        }
      </select>
    </div>
  );
}

export { ConferencePhoneCountryCodeSelect };

export default connect(
  state => ({ countryPhoneCodes: state.conference.countryPhoneCodes }),
  null
)(ConferencePhoneCountryCodeSelect);
