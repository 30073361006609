import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const MAX_LENGTH = 250;
const DEFAULT_TEXTAREA_HEIGHT = 36;

const MedicationDirectionField = ({
  name,
  placeholder,
  error,
  onChange,
  value,
  label,
}) => {
  const textareaRef = useRef(null);
  const textareaValueLength = textareaRef.current ? textareaRef.current.value.length : 0;

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      const computedStyle = window.getComputedStyle(textareaRef.current);
      const borderTopWidth = parseFloat(computedStyle.getPropertyValue('border-top-width'));
      const borderBottomWidth = parseFloat(computedStyle.getPropertyValue('border-bottom-width'));
      const totalHeight = (textareaRef.current.scrollHeight || DEFAULT_TEXTAREA_HEIGHT) + borderTopWidth + borderBottomWidth;
      textareaRef.current.style.height = `${totalHeight}px`;
    }
  };

  return (
    <div className="new-medication-field">
      <label
        className="new-medication-field__label"
        htmlFor={name}
      >
        {label}
      </label>
      <div className="new-medication-field__input--container medication-direction">
        <textarea
          rows={1}
          value={MAX_LENGTH ? value.slice(0, MAX_LENGTH) : value}
          onChange={onChange}
          id={name}
          name={name}
          placeholder={placeholder}
          ref={textareaRef}
          maxLength={MAX_LENGTH}
        />
        <div className="direction_example">
          <p className="direction_example--paragraph">Eg. Take 1 pill 3 times a day, after your meal</p>
          <span className="direction_example--value_length">{textareaValueLength}/{MAX_LENGTH}</span>
        </div>
        {error && <div className="alert alert-danger error-msg">{error[0]}</div>}
      </div>
    </div>
  );
};

MedicationDirectionField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.array,
  value: PropTypes.string,
};

export default MedicationDirectionField;
