import { SELECT_COMPLAINT, CLEAR_COMPLAINT } from '../action-types';

export const DEFAULT_STATE = {
  complaintId:         null,
  complaintText:       null,
  other:               null,
  refClassComplaintId: null,
  symptomClass:        null,
  isComplaintSelected: false
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SELECT_COMPLAINT: {
      return {
        ...state,
        ...action.payload.complaint
      };
    }
    case CLEAR_COMPLAINT: {
      return DEFAULT_STATE;
    }
    default: {
      return state;
    }
  }
};
