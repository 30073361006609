import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './text-area.scss';

export const TextArea = ({ answer, answerConfig, surveyNamingKey }) => {
  const { displayAttrs, value, onChangeCallback } = answerConfig;
  const minChars = displayAttrs && displayAttrs.minChars;
  const charCount = value.length;

  useEffect(() => {
    if (value) {
      onChangeCallback({ [answer.answerCode]: value });
    }
  }, []);

  return (
    <div>
      {Number.isInteger(minChars) && minChars > 0 ? (
        <span className={charCount >= minChars ? '' : 'text-area-with-char-reqs-invalid'}>
          {`${minChars} character minimum. (${charCount})`}
        </span>
      ) : null}
      <input
        type="hidden"
        name={`${surveyNamingKey}[surveys][][sections][][questions][][answers][][answer_code]`}
        value={answer.answerCode}
      />
      <textarea
        name={`${surveyNamingKey}[surveys][][sections][][questions][][answers][][value]`}
        value={value || answer.value}
        onChange={event => onChangeCallback({ [answer.answerCode]: event.target.value })}
      />
    </div>
  );
};

TextArea.propTypes = {
  answerConfig: PropTypes.shape({
    displayAttrs: PropTypes.shape({
      minChars: PropTypes.number
    }),
    value:            PropTypes.string.isRequired,
    onChangeCallback: PropTypes.func
  }),
  answer: PropTypes.shape({
    answer:     PropTypes.string,
    answerCode: PropTypes.string
  }),
  surveyNamingKey: PropTypes.string
};
