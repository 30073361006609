import React from 'react';
import PropTypes from 'prop-types';
import ReviewSummaryItem from './review-summary-item';

const ReviewSummary = ({ medications = [] }) => {
  const viewRow = medications.map(medication =>
    <ReviewSummaryItem
      medication={medication}
      key={medication.clinicalMedicationId}
    />
  );

  return (
    <div className="medication-review-summary">
      <div className="medication-review-summary__header">
        <p>
          You have updated the status of the following medications.
          Please confirm all medications statuses are accurate before proceeding.
        </p>
      </div>
      <div className="medication-review-summary__body">
        {viewRow}
      </div>
    </div>
  );
};

ReviewSummary.propTypes = {
  medications: PropTypes.array.isRequired
};

export default ReviewSummary;
