import React from 'react';
import has from 'lodash/has';
import { createSelector } from '@td/utils';

import ProposePhoneOnlyMessage, {
  COMPONENT_ID as PROPOSE_PHONE_ONLY_MESSAGE_ID
} from 'app/Provider/components/alerts/propose-phone-only-message';

import { COMPONENT_ID as PROVIDER_VIDEO_CLOCK_OUT_PROMPT_ID } from 'app/Provider/components/alerts/provider-video-clock-out-prompt';
import ProviderVideoClockOutPrompt from 'app/Provider/components/alerts/provider-video-clock-out-prompt/provider-video-clock-out-prompt-content';

const componentMappings = {
  [PROPOSE_PHONE_ONLY_MESSAGE_ID]: ProposePhoneOnlyMessage,
  [PROVIDER_VIDEO_CLOCK_OUT_PROMPT_ID]: ProviderVideoClockOutPrompt
};

const getAlertComponentId = state => state.alert.componentId;
const getAlertMessage = state => state.alert.message;

export default createSelector(
  getAlertComponentId,
  getAlertMessage,
  (componentId, message) => {
    if (has(componentMappings, componentId)) {
      const ComponentForAlert = componentMappings[componentId];

      return <ComponentForAlert />;
    }

    return message;
  }
);
