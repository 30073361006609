import React from 'react';
import PropTypes from 'prop-types';

const GoogleIcon = React.memo(({ width = 16, height = 16 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_2204_3019" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <path d="M20 0H0V20H20V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_2204_3019)">
      <path d="M19.6 10.2274C19.6 9.51828 19.5364 8.83648 19.4182 8.18188H10V12.0501H15.3818C15.15 13.3001 14.4455 14.3592 13.3864 15.0683V17.5774H16.6182C18.5091 15.8365 19.6 13.2728 19.6 10.2274Z" fill="#4285F4" />
      <path d="M10 19.9999C12.7 19.9999 14.9636 19.1044 16.6181 17.5772L13.3863 15.0681C12.4909 15.6681 11.3454 16.0226 10 16.0226C7.3954 16.0226 5.1909 14.2635 4.4045 11.8999H1.0636V14.4908C2.7091 17.759 6.0909 19.9999 10 19.9999Z" fill="#34A853" />
      <path d="M4.4045 11.8999C4.2045 11.2999 4.0909 10.659 4.0909 9.99993C4.0909 9.34083 4.2045 8.69993 4.4045 8.09993V5.50903H1.0636C0.3864 6.85903 0 8.38633 0 9.99993C0 11.6135 0.3864 13.1408 1.0636 14.4908L4.4045 11.8999Z" fill="#FBBC04" />
      <path d="M10 3.9773C11.4681 3.9773 12.7863 4.4818 13.8227 5.4727L16.6909 2.6045C14.9591 0.9909 12.6954 0 10 0C6.0909 0 2.7091 2.2409 1.0636 5.5091L4.4045 8.1C5.1909 5.7364 7.3954 3.9773 10 3.9773Z" fill="#E94235" />
    </g>
  </svg>
));

GoogleIcon.propTypes = {
  width:  PropTypes.number,
  height: PropTypes.number
};

GoogleIcon.displayName = 'GoogleIcon';

export default GoogleIcon;
