import React, { useEffect } from 'react';
import {
  tryToggleContinueButton,
  wasEhrTabReviewedBefore,
  selectAllscriptConfirmReviewCheckbox
} from '../../../ehr-utils';
import { I18n } from '@td/shared_utils';

const AllscriptConfirmReviewCheckbox = ({}) => {
  useEffect(() => {
    if (wasEhrTabReviewedBefore()) {
      selectAllscriptConfirmReviewCheckbox();
    }
    tryToggleContinueButton();
  }, []);

  return (
    <div className="ehr_confirm_review_checkbox_container">
      <input type="checkbox" id="allscript_confirm_review_checkbox" onClick={tryToggleContinueButton} />
      <label htmlFor="allscript_confirm_review_checkbox">
        <I18n scope="consultations" text="allscript_review_confirmed_second_checkbox" />
      </label>
    </div>
  );
};

export default AllscriptConfirmReviewCheckbox;
