exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1APSdszOhZpl9HkpOlIGZe {\n  margin: 20px auto;\n  padding: 0 0 48px 0; }\n\n._2zD2hXSqslC9mzgHHxwoCY {\n  display: flex;\n  background: none;\n  align-items: center;\n  justify-content: space-between;\n  border: none;\n  margin: 20px 0 0;\n  padding: 0;\n  color: #27ACCD;\n  font-weight: 700;\n  font-size: 12px; }\n\n._3jbmnKEAHOqEW6I0Rb32zA {\n  margin-right: 8px; }\n\n._23mPS7-3RzuaB72ngnoHmY {\n  font-weight: 700;\n  margin-top: 15px; }\n", ""]);

// exports
exports.locals = {
	"calendarsWrapper": "_1APSdszOhZpl9HkpOlIGZe",
	"calendarsWrapper": "_1APSdszOhZpl9HkpOlIGZe",
	"addAccountButton": "_2zD2hXSqslC9mzgHHxwoCY",
	"addAccountButton": "_2zD2hXSqslC9mzgHHxwoCY",
	"plusIcon": "_3jbmnKEAHOqEW6I0Rb32zA",
	"plusIcon": "_3jbmnKEAHOqEW6I0Rb32zA",
	"limitMessage": "_23mPS7-3RzuaB72ngnoHmY",
	"limitMessage": "_23mPS7-3RzuaB72ngnoHmY"
};