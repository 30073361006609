import _ from 'lodash';
import { createSelector } from '@td/utils';

import getSymptomsById from './getSymptomsById';
import getSelectedSymptomIds from './getSelectedSymptomIds';

const getSymptomRadioValues = state => state.symptomsForm.radioValues;
const getSymptomNoteValues = state => state.symptomsForm.noteValues;

const makeGetSymptomsWithNotes = radioValue =>
  createSelector(
    getSymptomsById,
    getSelectedSymptomIds,
    getSymptomRadioValues,
    getSymptomNoteValues,
    (symptomsById, selectedSymptomIds, symptomRadioValues, symptomNoteValues) =>
      _.chain(selectedSymptomIds)
        .filter(symptomId => symptomRadioValues[symptomId] === radioValue)
        .map(symptomId => ({
          name: symptomsById[symptomId] ? symptomsById[symptomId].name : '',
          note: symptomNoteValues[symptomId]
        }))
        .value()
  );

export const getPositiveSymptomsWithNotes = makeGetSymptomsWithNotes('yes');
export const getNegativeSymptomsWithNotes = makeGetSymptomsWithNotes('no');
