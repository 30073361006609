import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import UPDATE_CUSTOM_BUFFER from '../mutations/update-custom-buffer-time.graphql';

const useUpdateCustomBuffer = () => {
  const [performMutation] = useMutation(UPDATE_CUSTOM_BUFFER);

  const callUpdateCustomBuffer = async variables => {
    try {
      const result = await performMutation({ variables });

      const { success, errors } = get(result, 'data.tasUpdateCustomBufferTimeInProviderSettings');

      if (success) {
        return { success: true, errors: null };
      } else {
        return { success: false, errors };
      }
    } catch (mutationError) {
      return { success: false, errors: mutationError };
    }
  };

  return { callUpdateCustomBuffer };
};

export default useUpdateCustomBuffer;
