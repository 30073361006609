import store from 'app/lib/store';

const validate = () => {
  const {
    medicationReview: { pendingReviewList }
  } = store.getState();

  const tabValid = pendingReviewList.length === 0;

  if (!tabValid) {
    return [
      {
        validator: {
          invalid:  true,
          message:  'Please submit the reviewed medication before continue.',
          isInline: true
        },
        validatorKey: 'reviewedMedicationSubmitRequired'
      }
    ];
  }

  return [];
};

export default () => Promise.resolve(validate());
