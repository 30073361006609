import {
  ACTIVE_CONFERENCE_STATUSES,
  CONFERENCE_STATUSES,
  DISCONNECTED_STATUSES,
  VIEW_STATES,
  ATTENDEE_STATUSES,
  CONSULTSTATUS_LOCK,
  CONSULTATION_STATUSES
} from '../constants/call-status';
import { endCall, initiateCall, joinByPhone, updateConsultationStatus } from '../api';
import { useMemberUnavailableModal } from '../utils';

export const controls = (
  device,
  setDevice,
  consultationId,
  setCallStatus,
  provider,
  callStatus,
  isMuted,
  setIsMuted,
  callStatusInterval,
  setShouldPoll,
  setHasActiveCall
) => {
  if (!device) return {};

  const memberUnavailableModal = useMemberUnavailableModal({ setCallStatus });

  const beginCall = async () => {
    setCallStatus(CONFERENCE_STATUSES.STARTING);

    await updateConsultationStatus(
      consultationId,
      CONSULTSTATUS_LOCK,
      CONSULTATION_STATUSES.CONSULTSTATUSRSN_CALLSTARTED
    );

    await initiateCall(consultationId)
      .then(({ status }) => {
        setCallStatus(status);
      })
      .catch(({ status }) => {
        setCallStatus(status);
      });
  };

  // This should make sure the call is destroyed
  const onCancelClick = () => {
    setCallStatus(CONFERENCE_STATUSES.READY_TO_START);
    document.getElementById('cancel_consult').click();
  };

  const disconnectCall = () => {
    // If there's an active connection, disconnect that before ending the call
    if (device.getConnection()) {
      device.disconnectAll();
    }

    if (ACTIVE_CONFERENCE_STATUSES.includes(callStatus)) {
      endCall(consultationId);
    }
  };

  const joinCall = () => {
    device.createConnection({
      attendee_external_id:        provider.id,
      attendee_external_type_id:   'provider',
      conference_external_id:      consultationId,
      conference_external_type_id: 'consultation'
    });
  };

  const callPhone = phoneNumber => {
    if (device.getConnection()) {
      device.disconnectAll();
    }

    joinByPhone(consultationId, 'provider', phoneNumber);
  };

  const toggleMute = () => {
    device.toggleMute(!isMuted);
    setIsMuted(!isMuted);
  };

  // TODO move all of this logic to some cleanup method
  const restartCall = () => {
    clearInterval(callStatusInterval);
    setCallStatus(CONFERENCE_STATUSES.READY_TO_START);
    setShouldPoll(false);
    setHasActiveCall(false);
    setIsMuted(false);

    if (device) {
      device.removeDevice();
    }
    setDevice(null);
  };
  return {
    beginCall,
    onCancelClick,
    disconnectCall,
    joinCall,
    callPhone,
    toggleMute,
    restartCall,
    memberUnavailableModal
  };
};
