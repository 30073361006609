import { useState, useEffect, useMemo } from 'react';
import useFetchData from './useFetchData';
import { isEmpty, omit } from 'lodash';

const useFetchPaginatedConsultations = url => {
  const [consultations, setConsultations] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [requestParams, setRequestParams] = useState({ page: 1 });

  const { data, loading, error } = useFetchData(url, requestParams);
  const resetPagination = () => {
    setConsultations([]);
    setRequestParams({ page: 1 });
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const { consultations, ...rest } = data;
      setConsultations(prevConsultations => [...prevConsultations, ...consultations]);
      setMetadata(omit(rest, ['status', 'statusText']));
    }
  }, [data]);

  const fetchNextPage = () => setRequestParams(prevState => ({ page: prevState.page + 1 }));

  const hasNextPage = useMemo(() => metadata.page < metadata.totalPages, [metadata]);

  return { consultations, loading, error, hasNextPage, fetchNextPage, metadata, resetPagination };
};

export default useFetchPaginatedConsultations;
