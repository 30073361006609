import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputElement from 'react-input-mask';
import ConferencePhoneCountryCodeSelect from './conferencePhoneCountryCodeSelect';
import { isPhoneValid } from '../lib/phones';
import { addNumber, unblockConferenceStart } from '../actions';

class ConferencePhoneInput extends Component {
  static propTypes = {
    id:                     PropTypes.number.isRequired,
    isAddingCustomNumber:   PropTypes.bool.isRequired,
    addNumber:              PropTypes.func.isRequired,
    unblockConferenceStart: PropTypes.func.isRequired
  }

  state = { number: '', isValid: false, selectedPhoneCodeUID: '1-USA', selectedPhoneCode: '1' }

  componentWillReceiveProps = ({ isAddingCustomNumber }) => {
    if (!isAddingCustomNumber && this.state.number !== '') {
      this.setState({ number: '', isValid: false });
    }
  }

  onChange = event => {
    const number = event.target.value;
    this.setState({ number, isValid: this.isValid(this.state.selectedPhoneCode, number) });
  }

  onCountryCodeChange = event => {
    const selectedPhoneCodeUID = event.target.value;
    const selectedPhoneCode = selectedPhoneCodeUID.split('-')[0];
    this.setState({ selectedPhoneCode, selectedPhoneCodeUID });
  }

  onSubmit = event => {
    event.preventDefault();

    const { isValid, selectedPhoneCode, number } = this.state;
    if (isValid) {
      this.props.unblockConferenceStart();
      this.props.addNumber(this.props.id, this.fullNumber(selectedPhoneCode, number));
    }
  }

  isValid = (selectedPhoneCode, number) =>
    // Check based on the simple RegEx from here: https://www.twilio.com/docs/glossary/what-e164
    this.scrub(selectedPhoneCode).length >= 1 &&
    this.scrub(number).length >= 4 &&
    isPhoneValid(this.fullNumber(selectedPhoneCode, number));

  fullNumber = (phoneCode, number) => `+${this.scrub(phoneCode)}${this.scrub(number)}`;

  scrub = number =>
    number.replace(/[^\d]/g, '');

  renderInner = () => {
    if (!this.props.isAddingCustomNumber) { return; }

    return (
      <form onSubmit={this.onSubmit}>
        <ConferencePhoneCountryCodeSelect
          value={this.state.selectedPhoneCodeUID}
          onChange={this.onCountryCodeChange}
        />
        <InputElement
          className="phone-input"
          type="tel"
          value={this.state.number}
          onChange={this.onChange}
          // 4 to 14 digits, should be in sync with the checks in isValid
          mask="dddd??????????"
          formatChars={{
            d:   '[0-9]',
            '?': '[0-9]'
          }}
          maskChar={null}
        />
        <input type="submit" value="Save" disabled={!this.state.isValid} />
      </form>
    );
  }

  render = () => (
    <div className="form-element">
      {this.renderInner()}
    </div>
  );
}

export { ConferencePhoneInput };

export default connect(
  null,
  { addNumber, unblockConferenceStart }
)(ConferencePhoneInput);
