import _ from 'lodash';

/*
parses data-navigation-items of #consult_nav and returns config for secondary diagnoses
secondary_diagnoses: {
  display: true
  meta: {minItems: 0, maxItems: 11}
}
*/
export const useDiagnosesConfig = () => {
  const dataElement = document.querySelector('#consult_nav');
  const configData = _.get(dataElement, 'dataset.navigationItems', null);

  if (!configData) {
    return {};
  }

  try {
    const parsedConfig = JSON.parse(configData);
    const navItems = _.get(parsedConfig, 'items', []);
    const dxTab = navItems.find(({ key }) => key === 'dx' || key === 'emr_assessment');
    const metaData = _.get(dxTab, 'components');

    if (!metaData) {
      return {};
    }

    const parsedMetadata = JSON.parse(metaData);

    return {
      raw:  parsedMetadata,
      info: {
        primaryDiagnosis: {
          isReadOnly:  _.get(parsedMetadata, 'primary_diagnosis.meta.read_only', null),
          display:     _.get(parsedMetadata, 'primary_diagnosis.display', null),
          noteI18nKey: _.get(parsedMetadata, 'primary_diagnosis.meta.pre_select_diagnosis_note_i18n', null)
        },
        secondaryDiagnosis: {
          display:  _.get(parsedMetadata, 'secondary_diagnoses.display', null),
          minItems: _.get(parsedMetadata, 'secondary_diagnoses.meta.minItems', null),
          maxItems: _.get(parsedMetadata, 'secondary_diagnoses.meta.maxItems', null)
        }
      }
    };
  } catch (e) {
    console.error(e);
    return {};
  }
};
