import React from 'react';
import { Note } from '@td/ehr';
import { translateMarkupString } from '@td/shared_utils';
import {
  isMentalHealthSectionReviewRequired,
  isMentalHealthNotePresent,
  hasCouplesTherapyDocumentsSection
} from '../../../ehr-utils';

const EHRNoteForCouplesTherapyMentalHealthDocuments = ({ isViewedAllMentalHealthDocuments }) => {
  if (!hasCouplesTherapyDocumentsSection()) return null;
  if (!isMentalHealthSectionReviewRequired()) return null;
  if (isViewedAllMentalHealthDocuments) return null;

  return (
    <Note>
      {translateMarkupString(
        'ehr.mh.couples_therapy_documents_section',
        'review_required_notice',
        { a: (<a href={'#mh-couples-therapy'} />) }
      )}
    </Note>
  );
};

export default EHRNoteForCouplesTherapyMentalHealthDocuments;
