import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'lodash';
import { TextArea, Checkbox, RadioButton, SurveyDatePicker } from './answer-types';
import { CHECKBOX_ARRAY, CHECKBOX, TEXT_AREA, SELECT, RADIO, DATE } from '../constants.js';
import { getValidationErrorsByQuestionCode } from '../utils';

export const SurveyQuestion = ({ question, questionConfig, surveyNamingKey, cachedQuestion, section, styles }) => {
  const choices = question.choices;

  const responseComponent = () => {
    let component = null;

    switch (questionConfig.displayCode) {
      case CHECKBOX_ARRAY:
        component = (
          <div className={get(styles, [section.sectionCode, question.questionCode, 'answerContainer'])}>
            {choices.map((choice, index) => {
              const answerClass = `${question.questionCode}_${choice.answerCode}`;
              const cachedAnswer = get(cachedQuestion, `answers[${index}].table.value`);

              return (
                <div
                  className={cx(answerClass, get(styles, [section.sectionCode, question.questionCode, 'answer']))}
                  key={choice.answerCode}
                >
                  <Checkbox
                    cachedAnswer={cachedAnswer}
                    choice={choice}
                    answerConfig={questionConfig[choice.answerCode]}
                    surveyNamingKey={surveyNamingKey}
                  />
                </div>
              );
            })}
          </div>
        );
        break;
      case CHECKBOX:
        // Not yet implemented
        component = null;
        break;
      case TEXT_AREA:
        const answer = get(cachedQuestion, 'answers[0].table.value');
        const cachedQuestionData = {
          answerCode: get(choices, '[0].answerCode')
        };

        if (answer) {
          cachedQuestionData.value = answer || '';
        }

        component = (
          <TextArea
            answer={choices[0].value ? choices[0] : cachedQuestionData}
            answerConfig={questionConfig[choices[0].answerCode]}
            surveyNamingKey={surveyNamingKey}
          />
        );
        break;
      case SELECT:
        // Not yet implemented
        component = null;
        break;
      case RADIO:
        const [currentSelectedChoice, setCurrentSelectedChoice] = useState(null);
        const updateRadios = value => {
          setCurrentSelectedChoice(value);

          choices.forEach(choice => {
            questionConfig[choice.answerCode].onChangeCallback({ [choice.answerCode]: value === choice.answerCode });
          });
        };

        const cachedAnswer = get(cachedQuestion, 'answers', []).find(
          cachedOption => get(cachedOption, 'table.value') === 'Y'
        );
        const computedValue = currentSelectedChoice || get(cachedAnswer, 'table.answer_code');

        component = (
          <ul
            style={{ display: 'flex' }}
            className={get(styles, [section.sectionCode, question.questionCode, 'answerContainer'])}
          >
            {choices.map(choice => (
              <li
                key={choice.answerCode}
                className={cx(
                  `${question.questionCode}_${choice.answerCode}`,
                  get(styles, [section.sectionCode, question.questionCode, 'answer'])
                )}
              >
                <RadioButton
                  choice={choice}
                  computedValue={computedValue}
                  answerConfig={questionConfig[choice.answerCode]}
                  surveyNamingKey={surveyNamingKey}
                  questionCode={question.questionCode}
                  onChangeCallback={updateRadios}
                />
              </li>
            ))}
          </ul>
        );
        break;
      case DATE:
        const cachedDate = get(cachedQuestion, 'answers[0].table.value');

        component = (
          <SurveyDatePicker
            choice={choices[0]}
            cachedAnswer={cachedDate}
            answerConfig={questionConfig[choices[0].answerCode]}
            surveyNamingKey={surveyNamingKey}
          />
        );
        break;
      default:
        component = null;
        break;
    }
    return component;
  };

  const validationErrors = getValidationErrorsByQuestionCode(`${section.sectionCode}.${question.questionCode}`);

  return get(questionConfig, 'displayAttrs.visible') != false ? (
    <div className={cx('survey-question', get(styles, 'questionStyle'))}>
      <p>{question.question}</p>
      {responseComponent()}

      {validationErrors.map((error, index) => (
        <div className="interview_inline_validation_error show" key={`survey-validation-error-idx-${index}`}>
          {get(error, 'message', null)}
        </div>
      ))}
    </div>
  ) : null;
};

SurveyQuestion.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string
  }),
  questionConfig: PropTypes.shape({
    displayCode: PropTypes.string,
    displayAttr: PropTypes.object
  }),
  surveyNamingKey: PropTypes.string,
  styles:          PropTypes.object,
  section:         PropTypes.object,
  cachedQuestion:  PropTypes.object
};
