import React from 'react';
import { NameAttributes, usePcpSelectionDom } from '../dom-integration';
import HTMLElementRelocator from '../../components/html-element-relocator';
import cx from 'classnames';
import { FOLLOWUP_SECTIONS, NON_TELADOC_SERVICES, NON_TELADOC_SERVICES_THAT_SUPPORT_PCP_REFERAL } from '../constants';
import FieldError from './field-error';
import { I18n, translate } from '@td/shared_utils';
import { useEffect } from 'react';

export const TRANSLATION_SCOPE = 'consult_followup.non_teladoc_services';

const ExternalServicesSelector = ({
  className,
  memberHasPcp,
  services,
  selectedService,
  setSelectedService,
  fieldErrors,
  consultSpecialtyName,
  pclmIntegrationEnabled
}) => {
  const pcpReferalActive = NON_TELADOC_SERVICES_THAT_SUPPORT_PCP_REFERAL.includes(selectedService);
  const shConsultAlertDisplayed =
    selectedService === NON_TELADOC_SERVICES.ALTCARE_SH && consultSpecialtyName === 'Sexual Health Consultation';
  const shAlertDisplayed = selectedService === NON_TELADOC_SERVICES.ALTCARE_SHEALTH;

  const pcpSelectionDom = usePcpSelectionDom();
  useEffect(() => {
    if (!pcpReferalActive) pcpSelectionDom.reset();
  }, [pcpReferalActive]);

  return (
    <div className={cx('followup-non-teladoc-services', className)}>
      {!memberHasPcp && (
        <div className="member-missing-pcp-message" style={{ color: '#f30b00', marginBottom: 10, fontWeight: 'bold' }}>
          <I18n scope={TRANSLATION_SCOPE} text="no_pcp" />
        </div>
      )}
      {/* logic for mh and feature toggle only here */}
      <h3 className="mb-2 font-bold followup-non-teladoc-services-label" style={{ color: '#244861' }}>
        <I18n scope={TRANSLATION_SCOPE} text={pclmIntegrationEnabled ? 'heading-mh-pclm' : 'heading'} />
      </h3>
      {/* This will only display for mh and pclm feature toggle */}
      {pclmIntegrationEnabled && (
        <p>
          <I18n scope={TRANSLATION_SCOPE} text="external-referrals-description" />
        </p>
      )}
      <select
        style={{
          width:        240,
          height:       34,
          background:   'white',
          border:       '1px solid #CCCCCC',
          color:        selectedService ? 'unset' : '#AAAAAA',
          paddingRight: 20,
          fontSize:     12
        }}
        name={NameAttributes.referrals.nonTeladocServices.dropdown.html}
        value={selectedService}
        onChange={e => setSelectedService(e.target.value)}
      >
        <option value="">{translate(null, TRANSLATION_SCOPE, 'placeholder')}</option>
        {services.map(({ displayName, displayCode }) => (
          <option key={displayCode} value={displayCode}>
            {displayName}
          </option>
        ))}
      </select>
      <HTMLElementRelocator
        initialPositionContainer=".consult_form #non_teladoc_service_secondary_referral"
        currentPosition={pcpReferalActive ? 'relocated' : 'initial'}
      />
      <div style={{ marginLeft: 20 }}>
        <div className="followup-non-teladoc-services-errors">
          {(fieldErrors || [])
            .filter(e => e.validatorKey === FOLLOWUP_SECTIONS.NON_TELADOC_SERVICES)
            .map((e, i) => (
              <FieldError style={{ margin: '8px 0' }} key={i} message={e.message} />
            ))}
        </div>
        {shConsultAlertDisplayed && (
          <div className="alert_message">
            <p>
              <I18n scope={TRANSLATION_SCOPE} text="consult_alert1" />
            </p>
            <p>
              <span className="note">
                <I18n scope={TRANSLATION_SCOPE} text="consult_alert2" />
              </span>
            </p>
          </div>
        )}
        {shAlertDisplayed && (
          <div className="alert_message">
            <p>
              <I18n scope={TRANSLATION_SCOPE} text="alert" />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExternalServicesSelector;
