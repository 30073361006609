import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import DELETE_PENDING_PRESCRIPTION_MUTATION from '../../prescription/mutations/delete-pending-prescription.graphql';

const HistoryProviderOrder = ({ providerOrders }) => (
  <div id="pending_prescriptions">
    <table>
      <thead>
        <tr>
          <th>Drug</th>
          <th>Quantity</th>
          <th>Instructions</th>
          <th>Requestor</th>
          <th>Refills</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        { providerOrders.map(order =>
          <tr key={order.id}>
            <td>{ order.medicationRecord.drugName }</td>
            <td>{ order.medicationRecord.quantity }</td>
            <td>{ order.instructions }</td>
            <td>
              { order.requestor.name }
              <br />
              ({ order.requestor.type })
            </td>
            <td>{ order.refills }</td>
            <td>
              { order.statuses.map((status, index) =>
                <p key={index}>{ status.name }</p>)
              }
            </td>
            <td>
              <Mutation mutation={DELETE_PENDING_PRESCRIPTION_MUTATION} refetchQueries={['completedConsultationQuery']}>
                { (deletePendingPrescription, { loading }) =>
                  <button
                    disabled={loading}
                    onClick={() => deletePendingPrescription({ variables: { providerOrderId: order.id } })}
                    type="button"
                  >
                    {loading ? 'Deleting' : 'Delete'}
                  </button>
                }
              </Mutation>
            </td>
          </tr>)
        }
      </tbody>
    </table>
  </div>
);

HistoryProviderOrder.propTypes = {
  providerOrders: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default HistoryProviderOrder;
