// Example:
//   const array = ['ant', 'bison', 'camel', 'duck', 'elephant'];
//   replaceAtIndex(array, 3, 'cat')
//   => ['ant', 'bison', 'camel', 'cat', 'elephant'];
//
const replaceAtIndex = (array, index, replacement) => {
  if (index < 0) return array;
  if (index >= array.length) return array;

  const copiedElementsBeforeIndex = copyElementsBeforeIndex(array, index);
  const copiedElementsAfterIndex = copyElementsAfterIndex(array, index);

  return [...copiedElementsBeforeIndex, replacement, ...copiedElementsAfterIndex];
};

const copyElementsBeforeIndex = (array, index) => array.slice(0, index);

const copyElementsAfterIndex = (array, index) => array.slice(index + 1, array.length);

export default replaceAtIndex;
