import TwilioDevice from './twilio-adapter';

// Add new device services here
const TWILIO = 'TWILIO';

const getDevice = (dialProvider, callbacks, options) => {
  if (dialProvider === TWILIO) {
    return new TwilioDevice(options.token, callbacks, {
      codecPreferences:   ['opus', 'pcmu'],
      debug:              process.env.NODE_ENV != 'production',
      enableRingingState: true,
      fakeLocalDTMF:      true,
      warnings:           process.env.NODE_ENV != 'production'
    });
  }
};

export default getDevice;
