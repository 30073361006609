// The inbound caller is specifically referring to an attendee who has been on the conference call
// before a provider starts a consultation
//
// We only have one partner, Tricare (Optum), using inbound caller for conference consultations
//
// The UI is designed for only allowing one inbound caller for one consultation
// The inbound caller should be removed as soon as its phone call connection is end
//
// That means we don't allow providers to make outbound calls
// directly to the phone numbers of inbound callers

import {
  CONNECTED, RINGING
} from '../lib/states';

const findParticipantByType = (participants, type) =>
  participants
    .find(({ person }) => person.type === type);

export const findInboundCaller = participants =>
  findParticipantByType(participants, 'InboundCaller');

export const findMember = participants =>
  findParticipantByType(participants, 'Member');

export const findProvider = participants =>
  findParticipantByType(participants, 'Provider');

export const hasInboundCaller = participants =>
  findInboundCaller(participants) !== undefined;

export const isInactiveInboundCaller = ({ person, conferenceCall }) =>
  person.type === 'InboundCaller' && conferenceCall.callStatus !== CONNECTED;

export const canDisconnectParticipant = ({ conferenceCall }) => {
  const { callStatus } = conferenceCall;
  return callStatus === CONNECTED || callStatus === RINGING;
};
