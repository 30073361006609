import * as d3 from 'd3-selection';

function npsGauge(score, targetContainer) {
  const $container = d3.select(targetContainer);
  const width = parseFloat($container.style('width'));
  const colored_bar_x_offset = 30;
  const npsRed = '#C20700';
  const npsYellow = '#DB8F01';
  const npsGreen = '#15C300';

  const gauge_h = 60;

  const chart_w = width;
  const chart_y_pos = 0;

  // Conversion from -100 to +100 scale, to 0 to +100 scale.
  // D3 needs 0 to 100 scale for correct placement of score along line.
  const scoreRescaled = (score + 100) / 200;
  const resultPos = chart_w * scoreRescaled;

  // Chart size -----------

  const svg = d3
    .select('#linear-gauge')
    .append('svg')
    .attr('width', '100%')
    .attr('height', '70px');

  const gradient = svg
    .append('svg:defs')
    .append('svg:linearGradient')
    .attr('id', 'gradient')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '100%')
    .attr('y2', '0%')
    .attr('spreadMethod', 'pad');

  gradient
    .append('svg:stop')
    .attr('offset', '0%')
    .attr('stop-color', npsRed)
    .attr('stop-opacity', 1);

  gradient
    .append('svg:stop')
    .attr('offset', '50%')
    .attr('stop-color', npsYellow)
    .attr('stop-opacity', 1);

  gradient
    .append('svg:stop')
    .attr('offset', '100%')
    .attr('stop-color', npsGreen)
    .attr('stop-opacity', 1);

  // colored bar
  svg
    .append('g')
    .append('rect')
    .attr('x', colored_bar_x_offset)
    .attr('y', chart_y_pos + 30)
    .attr('rx', 9)
    .attr('ry', 9)
    .attr('width', '80%')
    .attr('height', gauge_h * 0.25)
    .style('fill', 'url(#gradient)');

  /** **************************************
   * Text, titles
   *****************************************/
  svg
    .append('g')
    .append('text')
    .attr('x', 0)
    .attr('y', 42)
    .attr('fill', '#254A65')
    .text('-100');

  svg
    .append('g')
    .append('text')
    .classed('rightPrcnt', true)
    .attr('x', 333)
    .attr('y', 42)
    .attr('text-anchor', 'end')
    .attr('fill', '#254A65')
    .text('+100');

  const tickMark = svg.append('g');

  let rectWidth = 65;
  const rectStrokeWidth = 2;
  let rectLeftBoundary = 40;
  let rectRightBoundary = 220;

  let rectXPosition;
  let textXPosition;
  let rectStrokeColor;

  // Styling rules for various number lengths.

  if (score == -100) {
    rectLeftBoundary = 30;
    rectXPosition = Math.max(rectLeftBoundary, resultPos - rectWidth / 2);
    textXPosition = rectXPosition + 7;
  }

  if (score >= -99 && score < 0) {
    rectWidth = 55;
    rectXPosition = Math.max(rectLeftBoundary, resultPos - rectWidth / 2);
    textXPosition = rectXPosition + 10;
  }
  if (score == 0) {
    rectWidth = 40;
    rectXPosition = Math.min(rectRightBoundary, resultPos - rectWidth / 2);
    textXPosition = rectXPosition + 13;
  }
  if (score > 0 && score < 100) {
    rectXPosition = Math.min(rectRightBoundary, resultPos - rectWidth / 2);
    textXPosition = rectXPosition + 13;
  }
  if (score == 100) {
    rectRightBoundary = 230;
    rectXPosition = Math.min(rectRightBoundary, resultPos - rectWidth / 2);
    textXPosition = rectXPosition + 5;
  }

  // Cutoffs for rectangle border
  if (score < 25) {
    rectStrokeColor = npsRed;
  }
  if (score >= 25 && score < 50) {
    rectStrokeColor = npsYellow;
  }
  if (score >= 50) {
    rectStrokeColor = npsGreen;
  }

  // Add a + sign to positive values
  const displayScore = score > 0 ? `+${score}` : score;

  // Score rectangle
  tickMark
    .append('rect')
    .attr('x', rectXPosition)
    .attr('y', 18)
    .attr('rx', 12)
    .attr('ry', 12)
    .attr('width', rectWidth)
    .attr('height', 40)
    .attr('fill', 'white')
    .attr('stroke', rectStrokeColor)
    .attr('stroke-width', rectStrokeWidth);

  // Score text
  tickMark
    .append('text')
    .attr('x', textXPosition)
    .attr('y', chart_y_pos + 21)
    .attr('dy', '1em')
    .attr('id', 'score')
    .attr('fill', '#254A65')
    .text(displayScore);
}

export default npsGauge;
