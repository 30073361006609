import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaPhone } from 'react-icons/fa';
import { I18n } from '@td/shared_utils';

const DialingMember = ({ primaryDialCount = 1, secondaryDialCount }) => (
  <React.Fragment>
    <div className="dialing-container">
      <div className="auto-dialer-loader-container">
        <div className="auto-dialer-loader dialing-loader" />
        <FaPhone className="auto-dialer-phone-icon" color="#204661" size={16} />
      </div>

      <div className="dialing-messages">
        <h1 className="auto-dialer-title-text dialing-title">
          <I18n scope="autodialer.call_interface.dialing" text="dialing_member" />
        </h1>
        {secondaryDialCount ? (
          <p className="dialing-text">
            <I18n scope="autodialer.call_interface.dialing" text="dialing_secondary" />
          </p>
        ) : (
          <p className="dialing-text">
            {primaryDialCount === 1 ? (
              <I18n scope="autodialer.call_interface.dialing" text="first_attempt" />
            ) : (
              <I18n scope="autodialer.call_interface.dialing" text="second_attempt" />
            )}
          </p>
        )}
      </div>
    </div>
  </React.Fragment>
);

DialingMember.propTypes = {
  onRestartClick:     PropTypes.func,
  primaryDialCount:   PropTypes.number,
  secondaryDialCount: PropTypes.number
};

export default DialingMember;
