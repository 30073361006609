import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const AcceptAction = ({ consultationId, requestedDates, requestDate }) => {
  
  const formattedDates = requestedDates && requestedDates.map(inputDateString => {
    return moment(inputDateString, "YYYY-MM-DD HH:mm:ss Z").tz("America/New_York").format(); // for timezone refrence - teladoc_framework/app/helpers/shared/utility_helper.rb
  });

  const utcDateStrings =
    requestedDates &&
    requestedDates.map(dateString => {
      const utcDateString = new Date(dateString).toISOString();
      return utcDateString;
    });

  return (
    <Fragment>
      <a
        className={formattedDates.length > 1 ? 'view_requested_dates requested_dates' : 'accept_consult'}
        data-time={requestDate}
        data-requested-dates={formattedDates}
        data-requested-dates-utc={utcDateStrings}
        title="Select a requested date"
        href={`/consultations/${consultationId}/accept`}
      >
        Accept
      </a>
    </Fragment>
  );
};

AcceptAction.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default AcceptAction;
