import React, { useEffect, useMemo, useState, useCallback } from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const AutoDialerTimer = ({ callJoinTime, callLengthLabel, autoDialerContainer }) => {
  const autoDialerContainerWidth = useMemo(() => get(autoDialerContainer, 'current.clientWidth', 0), [
    autoDialerContainer.current
  ]);

  const callTimerIsActive = callJoinTime && callLengthLabel;
  const [labelSticky, setLabelSticky] = useState(true);
  const [labelOffsetLeft, setLabelOffsetLeft] = useState(0);
  const [labelWidth, setLabelWidth] = useState(autoDialerContainerWidth);

  const handleScreenConditionsChange = useCallback(() => {
    if (autoDialerContainer && autoDialerContainer.current) {
      const autoDialerContainerBounds = autoDialerContainer.current.getBoundingClientRect();
      const isSticky = autoDialerContainerBounds.top >= 0;

      setLabelSticky(isSticky);
      setLabelOffsetLeft(autoDialerContainerBounds.left);
      setLabelWidth(autoDialerContainerBounds.width);
    }
  }, [autoDialerContainer, setLabelSticky, setLabelOffsetLeft]);

  useEffect(() => {
    handleScreenConditionsChange();

    window.addEventListener('scroll', handleScreenConditionsChange);
    window.addEventListener('resize', handleScreenConditionsChange);

    return () => {
      window.removeEventListener('scroll', handleScreenConditionsChange);
      window.removeEventListener('resize', handleScreenConditionsChange);
    };
  }, []);

  return (
    <div
      className={classnames(
        'auto-dialer-call-length-label',
        !labelSticky && 'fixed-call-length-label',
        labelSticky && 'sticky-call-length-label',
        callTimerIsActive && 'show'
      )}
      style={{
        left:  !labelSticky ? `${labelOffsetLeft}px` : 0,
        width: `${labelWidth}px`
      }}
    >
      {callLengthLabel}
    </div>
  );
};

AutoDialerTimer.propTypes = {
  callJoinTime:        PropTypes.any.isRequired,
  callLengthLabel:     PropTypes.string.isRequired,
  autoDialerContainer: PropTypes.any.isRequired
};

export default AutoDialerTimer;
