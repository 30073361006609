import { connect } from 'react-redux';
import ProviderClockInOut from './provider-clock-in-out';

import getRequestMode from '../../../Provider/selectors/getRequestMode';

const mapStateToProps = state => ({
  requestMode: getRequestMode(state)
});

export default connect(mapStateToProps)(ProviderClockInOut);
