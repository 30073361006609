import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
const backIcon = require('../../../assets/images/back_icon.svg');

const Breadcrumbs = ({ items, activeIndex, onClick, onBack }) => {
  const numberOfItems = items.length;
  return (
    <div className="breadcrumbsWrapper">
      {onBack && (
        <span className="backIcon" onClick={() => onBack()}>
          <img className="backAction" key="backIcon" src={backIcon} />
        </span>
      )}
      {map(items, (item, index) => {
        const isLast = numberOfItems - 1 === index;
        const component = isLast ? (
          <span key={index} className="breadcrumbItem active">
            {item.label}
          </span>
        ) : (
          <span className="breadcrumbItem" onClick={() => onClick(index)} key={index}>{`${item.label} / `}</span>
        );
        return component;
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label:   PropTypes.string.isRequired,
      onClick: PropTypes.Func
    })
  ),
  activeIndex: PropTypes.number.isRequired,
  onBack:      PropTypes.func,
  onClick:     PropTypes.func
};

export default Breadcrumbs;
