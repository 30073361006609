import { connect } from 'react-redux';

import getSymptomsForSearch from '../../reducers/selectors/symptoms/getSymptomsForSearch';
import {
  addSymptomSearchRequest,
  addSymptomInputChange
} from '../../actions';
import AddSymptom from './add-symptom';

const mapStateToProps = state => ({
  symptoms:    getSymptomsForSearch(state),
  searchValue: state.symptomAddForm.searchValue
});

const mapDispatchToProps = {
  addSymptom:    addSymptomSearchRequest,
  onInputChange: addSymptomInputChange
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSymptom);
