import React from 'react';

export const COMPONENT_ID = 'PROPOSE_PHONE_ONLY_MESSAGE';

export default () => {
  return (
    <div className="propose_phone_only_message">
      You are currently logged in for video visits but have recently cancelled a video request. If
      you do not want to take video visits at this time, please switch to{' '}
      <a data-method="post" href="/dashboard/clock_in_out?clock_in_out=1">
        Phone Only
      </a>
      .
    </div>
  );
};
