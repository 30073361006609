exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3hrMXoKTJdpo9ZdFvZZlSq {\n  display: block;\n  width: 234px;\n  height: 44px;\n  background: url(" + require("../../assets/images/btn_cancel_consult.png") + ") no-repeat 0 0;\n  background-color: transparent !important;\n  border: none !important;\n  transition: none !important;\n  text-indent: -9999px;\n  position: absolute;\n  right: 0;\n  top: 30px; }\n  ._3hrMXoKTJdpo9ZdFvZZlSq:active {\n    background-position: 0 100%; }\n", ""]);

// exports
exports.locals = {
	"cancelConsultBtn": "_3hrMXoKTJdpo9ZdFvZZlSq",
	"cancelConsultBtn": "_3hrMXoKTJdpo9ZdFvZZlSq"
};