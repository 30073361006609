import { useQuery } from '@apollo/client';
import { useState } from 'react';
import WAITLIST from '../components/MatchesForWaitlistTable/waitlist.graphql';

const useFetchWaitlist = () => {
  const [loading, setLoading] = useState(true);
  const { data, error, refetch } = useQuery(WAITLIST, {
    onCompleted: _ => setLoading(false)
  });

  const refetchWaitlist = async isWaitlistConsult => {
    if (isWaitlistConsult) {
      setLoading(true);
      await refetch();
      setLoading(false);
    }
  };

  const waitlistData = (data && data.currentUserProvider && data.currentUserProvider.waitlist) || [];

  return {
    data: waitlistData,
    loading,
    error,
    refetch,
    refetchWaitlist
  };
};

export default useFetchWaitlist;
