import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@td/shared_utils';
import { CalendarAddIcon } from '../../../../icons';

import styles from './styles.module.scss';

const TRANSLATION_SCOPE = 'my_schedule.settings_page';

const EmptyState = ({ onClick }) => (
  <div className="header">
    <div className={styles.externalCalendarIcon} data-testid="external-calendar-icon">
      <CalendarAddIcon width={32} height={32} fill="#244861" />
    </div>
    <div className="availabilityText">
      <h4 className="sectionTitle">{translate(null, TRANSLATION_SCOPE, 'external_calendars_section.subsection_title')}</h4>
    </div>
    <div className="availabilityAction">
      <button className="button" onClick={onClick} data-testid="availability-action">
        {translate(null, TRANSLATION_SCOPE, 'actions.add')}
      </button>
    </div>
  </div>
);

EmptyState.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default EmptyState;
