import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@td/shared_utils';
import Modal from 'app/components/Modal';
import AudioTest from './AudioTest';
import { MODAL_TYPES } from '../../constants/modal-types';

const AutoDialerModal = ({ handleClose, modalType }) => (
  // Additional modal types can be added as needed
  <Modal centered showCloseButton id="audio-modal" onClose={handleClose} show={!!modalType}>
    <h1>
      {modalType === MODAL_TYPES.AUDIO_TEST ? (
        <I18n scope="autodialer.call_interface.footer" text="audio_test" />
      ) : null}
    </h1>
    <div className="modal-body">
      {modalType === MODAL_TYPES.AUDIO_TEST ? <AudioTest handleClose={handleClose} /> : null}
    </div>
  </Modal>
);

AutoDialerModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  modalType:   PropTypes.string
};

AutoDialerModal.displayName = 'AutoDialerModal';

export default AutoDialerModal;
