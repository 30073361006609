import { SHOW_ALERT, CLOSE_ALERT } from './action-types';
import { AlertPriority } from './enums';

export const showAlert = ({
  message = null,
  componentId = null,
  buttonText = 'Ok',
  priority = AlertPriority.NORMAL,
  dispatchOnClose = []
}) => ({
  type: SHOW_ALERT,
  payload: {
    message,
    componentId,
    buttonText,
    priority,
    dispatchOnClose
  }
});

export const closeAlert = () => ({
  type: CLOSE_ALERT
});
