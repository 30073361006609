import { connect } from 'react-redux';

import
  getSelectedSymptomsWithValueIds
from '../../reducers/selectors/symptoms/getSelectedSymptomsWithValueIds';
import SymptomsHiddenFields from './symptoms-hidden-fields';

const mapStateToProps = state => ({
  symptomIds: getSelectedSymptomsWithValueIds(state)
});

export default connect(mapStateToProps)(SymptomsHiddenFields);
