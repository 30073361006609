import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

const MissingDeviceError = ({ device }) => (
  <div>
    <h5 className="network-test-content network-test-header">No {capitalize(device)} Detected</h5>
    <div className="network-test-content">
      Our performance test could not locate your computer's {device}. Because of this{' '}
      <span className="network-test-strong">you have been clocked in for phone only.</span> Please
      ensure it is properly plugged in, enabled and functioning correctly in order to clock in for
      video consultations.
    </div>
  </div>
);

MissingDeviceError.propTypes = {
  device: PropTypes.string.isRequired
};

export default MissingDeviceError;
