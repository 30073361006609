import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { get, isEmpty, mapValues } from 'lodash';
import { MdInfoOutline } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

const SELF_HARM_LABEL = 'selfHarmOnUA';

const Indicators = ({ indicators }) => {
  const filteredIndicators = mapValues(indicators, labels => labels.filter(label => !label.includes('selfHarm')));

  return (
    <Fragment>
      {Object.entries(filteredIndicators).map(([category, labels]) => (
        <div key={category} className={`indicator-${category}`}>
          {labels.map(label => (
            <p key={label}>{I18n.t(`dashboard.consultations.list_item.badges.${label}`)}</p>
          ))}
        </div>
      ))}
    </Fragment>
  );
};

const CosultCrisisAlertTooltip = ({ indicators, consultationId }) => {
  const TOOLTIP_ID = `crisis-alert-${consultationId}`;
  const consultCrisisAlertNeeded = get(indicators, 'notices', []).includes(SELF_HARM_LABEL);

  if (!consultCrisisAlertNeeded) return null;

  return (
    <Fragment>
      <MdInfoOutline size={16} data-tip data-for={TOOLTIP_ID} className="icon indicator-alerts" />
      <ReactTooltip id={TOOLTIP_ID} place="top" effect="solid" className="crisis-alert-tooltip">
        {I18n.t('ehr.surveys.emotional_health.crisis.current.explanation')}
      </ReactTooltip>
    </Fragment>
  );
};

const PatientInformation = ({ memberFullName, memberHealthEquityPronoun, indicators, consultationId }) => (
  <Fragment>
    <div className="name-container">
      <p className="name">{memberFullName}</p>
      <CosultCrisisAlertTooltip indicators={indicators} consultationId={consultationId} />
    </div>
    {!isEmpty(memberHealthEquityPronoun) && <p>{`(${memberHealthEquityPronoun.join(', ')})`}</p>}
    <Indicators indicators={indicators} />
  </Fragment>
);

PatientInformation.propTypes = {
  memberFullName: PropTypes.string.isRequired,
  memberHealthEquityPronoun: PropTypes.arrayOf(PropTypes.string),
  indicators: PropTypes.object.isRequired,
  consultationId: PropTypes.number.isRequired
};

export default PatientInformation;
