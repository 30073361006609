import { useEffect, useRef, useState } from 'react';
import getClientInstance from '../../services/apollo-client';
import CONSULTATION_CONFERENCE_LOGS_QUERY from '../queries/consultation-conference-logs.graphql';
import { isEqual } from 'lodash';

const logsSelector = ({ consultation: { conferenceLogs } }) => conferenceLogs;

const useConferenceLogs = ({ consultationId }) => {
  const POLLING_TIMER = 15000;
  let pollingTimeout;

  const apolloClient = getClientInstance({
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  });

  const [logs, setLogs] = useState([]);
  const logsRef = useRef(logs);

  useEffect(() => {
    logsRef.current = logs;
  }, [logs]);

  useEffect(() => {
    let authErrorCount = 0;

    const fetchLogs = async () => {
      try {
        const { data, errors } = await apolloClient.query({
          query:     CONSULTATION_CONFERENCE_LOGS_QUERY,
          variables: { consultationId }
        });

        const logs = logsSelector(data);

        if (!isEqual(logsRef.current, logs)) {
          setLogs(logs);
        }

        authErrorCount = 0;
        pollingTimeout = setTimeout(fetchLogs, POLLING_TIMER);
      } catch (error) {
        if (error.message && error.message.includes('Unauthorized')) {
          authErrorCount += 1;

          console.error('GraphQL Auth failed');

          if (authErrorCount < 3) {
            pollingTimeout = setTimeout(fetchLogs, POLLING_TIMER);
          }
        } else {
          pollingTimeout = setTimeout(fetchLogs, POLLING_TIMER);
        }
      }
    };

    fetchLogs();

    return () => {
      if (pollingTimeout) clearTimeout(pollingTimeout);
    };
  }, []);

  return logs;
};

export default useConferenceLogs;
