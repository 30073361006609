import React, { Component, Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import NutritionPlanAlert from '../nutrition-plan-alert';
import CharValidator from '../char-validator';
import PatientInfoInterface from '../../../interfaces/patient-info-interface';
import './patient-Info.scss';

export default class PatientInfo extends Component {
  constructor(props) {
    super(props);
    const { name, dob, height, weight } = this.props.patientInfo;
    const patientBMI = this.calculateBMI(
      parseInt(this.props.section.questions.Q_1_3.response, 10) || height,
      this.props.section.questions.Q_1_4.response || weight
    );
    this.isUsingMetricSystem = this.isUsingMetricSystem.bind(this);
    this.getHeightUnit = this.getHeightUnit.bind(this);
    this.getWeightUnit = this.getWeightUnit.bind(this);
    this.calculateHeight = this.calculateHeight.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleHeightChange = this.handleHeightChange.bind(this);
    this.handleWeightChange = this.handleWeightChange.bind(this);
    this.handleAgeChange = this.handleAgeChange.bind(this);
    this.calculateBMI = this.calculateBMI.bind(this);
    this.handleBMIChange = this.handleBMIChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.reviewedHandler = this.reviewedHandler.bind(this);
    this.checkSectionValidity = this.checkSectionValidity.bind(this);
    this.handleShareBmiAndWeight = this.handleShareBmiAndWeight.bind(this);
    this.heightInput = React.createRef();
    this.heightInInchesInput = React.createRef();
    this.controlNamePrefix =
      'interview[provider_intake_surveys][SURVEYMNEMONIC_NUTPLAN][sections][SECTIONMNEMONIC_NUTPLAN_PATINFO]';
    this.state = {
      patientName:         this.props.section.questions.Q_1_1.response || name,
      patientDOB:          this.props.section.questions.Q_1_2.response || moment(dob).format('MM/DD/YYYY'),
      patientHeight:       parseInt(this.props.section.questions.Q_1_3.response, 10) || height,
      patientWeight:       this.props.section.questions.Q_1_4.response || weight,
      patientAge:          this.calculateAge(this.props.section.questions.Q_1_2.response || dob),
      date:                this.props.section.questions.Q_1_6.response || moment().format('MM/DD/YYYY'),
      patientBMI,
      patientWeightStatus: patientBMI && this.calculateWeightStatus(patientBMI),
      bmiNotes:            this.props.section.questions.Q_1_9.response || '',
      shareBmiAndWeight:   this.props.section.questions.Q_1_10.response !== 'NO',
      isSectionInvalid:    false
    };
  }

  isUsingMetricSystem() {
    return this.props.patientInfo.using_metric_system;
  }

  getHeightUnit() {
    return this.isUsingMetricSystem() ? 'cm' : 'ft';
  }

  getWeightUnit() {
    return this.isUsingMetricSystem() ? 'kg' : 'lbs';
  }

  validateInput(event) {
    const eventObj = event;
    const key = eventObj.keyCode || eventObj.which;
    if (
      !eventObj.shiftKey &&
      ((eventObj.type !== 'paste' && ((key > 48 && key <= 57) || (key > 96 && key <= 105))) || key === 8 || key === 46)
    ) {
      return true;
    } else if ((key === 48 || key === 96) && eventObj.target.value.length) {
      return true;
    } else {
      eventObj.returnValue = false;
      if (eventObj.preventDefault) {
        event.preventDefault();
      }
      return false;
    }
  }

  validateBmiNote() {
    return !this.state.shareBmiAndWeight || this.state.bmiNotes.length >= 5;
  }

  handleChanges(event, fieldIdentifier) {
    this.setState({ [fieldIdentifier]: event.target.value }, () => this.checkSectionValidity());
  }

  handleHeightChange(event) {
    const height = this.calculateHeight();
    const bmi = height && this.handleBMIChange(height, this.state.patientWeight);
    const patientWeightStatus = bmi && this.calculateWeightStatus(bmi);
    this.setState(
      {
        patientBMI:    isNaN(bmi) ? '' : bmi,
        patientHeight: height || '',
        patientWeightStatus
      },
      () => this.checkSectionValidity()
    );
  }

  handleWeightChange(event) {
    const bmi = event.target.value && this.handleBMIChange(this.state.patientHeight, event.target.value);
    const patientWeightStatus = bmi && this.calculateWeightStatus(bmi);
    this.setState(
      {
        patientBMI:    bmi,
        patientWeight: event.target.value || '',
        patientWeightStatus
      },
      () => this.checkSectionValidity()
    );
  }

  checkSectionValidity() {
    if (
      (!(this.state.patientHeight && this.state.patientWeight) || !this.validateBmiNote()) &&
      this.props.section.reviewed
    ) {
      this.props.toggleSection();
    }
  }

  handleBMIChange(height, weight) {
    return height && weight ? this.calculateBMI(height, weight) : '';
  }

  calculateBMI(height, weight) {
    return this.isUsingMetricSystem()
      ? Math.round((weight / ((height * height) / 10000)) * 10) / 10
      : Math.round((weight / (height * height)) * 703 * 10) / 10;
  }

  calculateWeightStatus(bmi) {
    if (bmi <= 18.5) {
      return 'underweight';
    } else if (bmi > 18.5 && bmi <= 24.9) {
      return 'normal weight';
    } else if (bmi >= 25 && bmi <= 29.9) {
      return 'overweight';
    } else if (bmi >= 30) {
      return 'obese';
    } else {
      return '';
    }
  }

  reviewedHandler() {
    let alertVisibility;
    if (this.state.patientHeight && this.state.patientWeight && this.validateBmiNote()) {
      alertVisibility = false;
      this.props.toggleSection();
    } else {
      alertVisibility = true;
    }
    this.setState({ isSectionInvalid: alertVisibility });
  }

  handleAgeChange(event, fieldIdentifier) {
    this.setState({
      [fieldIdentifier]: event.target.value,
      patientAge:        this.calculateAge(event.target.value)
    });
  }

  calculateAge(value) {
    return moment().diff(value, 'years', false);
  }

  calculateHeight() {
    const heightValue = this.heightInput.current.value || 0;

    if (this.isUsingMetricSystem()) {
      return heightValue;
    }

    return heightValue * 12 + parseInt(this.heightInInchesInput.current.value || 0, 10);
  }

  handleShareBmiAndWeight() {
    this.setState({ shareBmiAndWeight: !this.state.shareBmiAndWeight }, this.checkSectionValidity);
  }

  render() {
    const heightUnit = this.getHeightUnit();
    const weightUnit = this.getWeightUnit();

    return (
      <div className="patient-info-wrapper">
        <NutritionPlanAlert show={this.state.isSectionInvalid} />
        <div className="patient-info">
          <div className="left-side-panel">
            <div className="patient-name control-wrapper">
              <label className="control-label" htmlFor={`${this.controlNamePrefix}[questions][Q_1_1][response]`}>
                {this.props.section.questions.Q_1_1.question}
              </label>
              <input
                className="input-control readonly"
                name={`${this.controlNamePrefix}[questions][Q_1_1][response]`}
                value={this.state.patientName}
                readOnly
              />
            </div>
            <div className="date control-wrapper">
              <label className="control-label" htmlFor={`${this.controlNamePrefix}[questions][Q_1_6][response]`}>
                {this.props.section.questions.Q_1_6.question}
              </label>
              <input
                className="input-control readonly"
                name={`${this.controlNamePrefix}[questions][Q_1_6][response]`}
                value={this.state.date}
                readOnly
              />
            </div>
            <div className="patient-dob control-wrapper">
              <label className="control-label" htmlFor={`${this.controlNamePrefix}[questions][Q_1_2][response]`}>
                {this.props.section.questions.Q_1_2.question}
              </label>
              <input
                className="input-control readonly"
                name={`${this.controlNamePrefix}[questions][Q_1_2][response]`}
                value={this.state.patientDOB}
                readOnly
              />
            </div>
            <div className="patient-age control-wrapper">
              <label className="control-label" htmlFor={`${this.controlNamePrefix}[questions][Q_1_5][response]`}>
                {this.props.section.questions.Q_1_5.question}
              </label>
              <input
                className="input-control readonly"
                name={`${this.controlNamePrefix}[questions][Q_1_5][response]`}
                value={this.state.patientAge}
                readOnly
              />
            </div>
          </div>
          <div className="right-side-panel">
            <div className="patient-height control-wrapper">
              <label className="control-label" htmlFor={`${this.controlNamePrefix}[questions][Q_1_3][response]`}>
                {this.props.section.questions.Q_1_3.question}
                <span className="required-control">*</span>
              </label>
              <input
                className={`height-${heightUnit} input-control`}
                name={`height-${heightUnit}`}
                maxLength={this.isUsingMetricSystem() ? '3' : '1'}
                ref={this.heightInput}
                defaultValue={
                  this.isUsingMetricSystem() ? this.state.patientHeight : Math.floor(this.state.patientHeight / 12)
                }
                onKeyDown={event => this.validateInput(event)}
                onChange={this.handleHeightChange}
              />
              <label className="control-label-height" htmlFor={`height-${heightUnit}`}>
                {heightUnit.toUpperCase()}
              </label>
              <input
                className="height-value input-control readonly"
                name={`${this.controlNamePrefix}[questions][Q_1_3][response]`}
                value={this.state.patientHeight}
                readOnly
              />
              {!this.isUsingMetricSystem() && (
                <Fragment>
                  <input
                    name="height-in"
                    className="height-in input-control"
                    maxLength="2"
                    ref={this.heightInInchesInput}
                    defaultValue={this.state.patientHeight - Math.floor(this.state.patientHeight / 12) * 12}
                    onKeyDown={event => this.validateInput(event)}
                    onChange={this.handleHeightChange}
                  />
                  <label className="control-label-height" htmlFor="height-in">
                    IN
                  </label>
                </Fragment>
              )}
            </div>
            <div className="patient-weight control-wrapper">
              <label className="control-label" htmlFor={`${this.controlNamePrefix}[questions][Q_1_4][response]`}>
                {this.props.section.questions.Q_1_4.question}
                <span className="required-control">*</span>
              </label>
              <input
                className="input-control"
                maxLength="3"
                name={`${this.controlNamePrefix}[questions][Q_1_4][response]`}
                defaultValue={this.state.patientWeight}
                onKeyDown={event => this.validateInput(event)}
                onChange={this.handleWeightChange}
              />
              <span className={`${weightUnit}-label`}>{weightUnit.toUpperCase()}</span>
            </div>
            <div className="bmi control-wrapper">
              <label className="control-label" htmlFor={`${this.controlNamePrefix}[questions][Q_1_7][response]`}>
                {this.props.section.questions.Q_1_7.question}
              </label>
              <input
                className="input-control readonly"
                name={`${this.controlNamePrefix}[questions][Q_1_7][response]`}
                value={this.state.patientBMI}
                readOnly
              />
            </div>
            <div className="weight-status control-wrapper">
              <label className="control-label" htmlFor={`${this.controlNamePrefix}[questions][Q_1_8][response]`}>
                {this.props.section.questions.Q_1_8.question}
              </label>
              <input
                className="input-control readonly"
                name={`${this.controlNamePrefix}[questions][Q_1_8][response]`}
                value={this.state.patientWeightStatus}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="show-bmi-and-weight-status">
          <input type="hidden" name={`${this.controlNamePrefix}[questions][Q_1_10][response]`} value="NO" />
          <input
            id="show-bmi-and-weight-status"
            type="checkbox"
            name={`${this.controlNamePrefix}[questions][Q_1_10][response]`}
            value="YES"
            onChange={this.handleShareBmiAndWeight}
            checked={this.state.shareBmiAndWeight}
          />
          <label htmlFor="show-bmi-and-weight-status">{this.props.section.questions.Q_1_10.question}</label>
        </div>
        <div className="bmi-notes">
          <span className="bmi-notes-text">
            <span className={`required-control${this.state.shareBmiAndWeight ? '' : ' hidden'}`}>*</span>
            {`Your BMI calculates your approximate body fat and risk
             for metabolic diseases such as obesity,
             heart disease and diabetes. Your weight status indicates:`}
          </span>
          {this.state.shareBmiAndWeight && (
            <CharValidator fieldLength={this.state.bmiNotes.trim().length} minLength={5} />
          )}
          <textarea
            maxLength="550"
            name={`${this.controlNamePrefix}[questions][Q_1_9][response]`}
            className="bmi-notes-field height-100"
            onChange={event => this.handleChanges(event, 'bmiNotes')}
            defaultValue={this.state.bmiNotes}
            readOnly={!this.state.shareBmiAndWeight}
          />
        </div>
        <div className="reviewed-checkbox">
          <input type="hidden" name={`${this.controlNamePrefix}[reviewed]`} value="false" />
          <input
            id="patient-info-reviewed"
            type="checkbox"
            name={`${this.controlNamePrefix}[reviewed]`}
            value="true"
            onChange={this.reviewedHandler}
            checked={this.props.section.reviewed}
          />
          <label htmlFor="patient-info-reviewed">{`Reviewed ${this.props.section.title}`}</label>
        </div>
      </div>
    );
  }
}

PatientInfo.propTypes = {
  section:       PropTypes.object.isRequired,
  patientInfo:   PatientInfoInterface,
  toggleSection: PropTypes.func.isRequired
};
