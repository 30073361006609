import { combineReducers } from 'redux';

import { formReducer as medicationForm } from './formReducer';
import medications from './medicationsReducer';
import { suggestionsReducer as medicationAutosuggest } from './suggestionsReducer';
import { pendingMedicationReducer as pendingMedications } from './pending-medication-reducer';

export const reducer = combineReducers({
  medicationForm,
  medications,
  medicationAutosuggest,
  pendingMedications
});
