import { connect } from 'react-redux';

import { selectedTabChange } from '../../actions/symptoms-actions';
import SymptomTabs from './symptom-tabs';

const mapStateToProps = state => ({
  selectedTabIndex: state.symptomTabs.selectedTabIndex
});

const mapDispatchToProps = {
  changeSelectedTab: selectedTabChange
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SymptomTabs);
