import PropTypes from 'prop-types';
import { consultationIsRequested } from '../utils';

const RequestedAndProposedVisitsTableStatus = ({ status }) => {
  if (consultationIsRequested(status))
    return I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.status.patient_requested');

  return I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.status.you_proposed');
};

RequestedAndProposedVisitsTableStatus.propTypes = {
  status: PropTypes.string
};

export default RequestedAndProposedVisitsTableStatus;
