import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { authToken } from '@td/api';
import { useUpdater } from '@td/shared_utils';

const useFetchProviderLockedSlots = memberId => {
  const lockedTimeSlotsRequestRS = useUpdater('get', {
    url: '/v4/consultations/locked_slots',
    method: 'get',
    headers: { Authorization: authToken.get() }
  });

  useEffect(() => {
    lockedTimeSlotsRequestRS.callApi({
      member_id: memberId
    });
  }, [memberId]);

  return {
    data: lockedTimeSlotsRequestRS.data || {},
    loading: lockedTimeSlotsRequestRS.loading,
    error: lockedTimeSlotsRequestRS.error
  };
};

useFetchProviderLockedSlots.propTypes = {
  memberId: PropTypes.number.isRequired
};

export default useFetchProviderLockedSlots;
