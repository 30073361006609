import React from 'react';
import PropTypes from 'prop-types';

const ArrowUpIcon = ({ width = 8, height = 8, fill = '#181919', ...props }) => (
  <svg {...props} width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0677967 4.86066L0.411299 5.22224C0.501695 5.31264 0.646328 5.31264 0.718644 5.22224L3.99096 1.94993L7.28136 5.22224C7.35367 5.31264 7.49831 5.31264 7.5887 5.22224L7.9322 4.86066C8.0226 4.78834 8.0226 4.64371 7.9322 4.55332L4.15367 0.774784C4.06328 0.684389 3.93672 0.684389 3.84633 0.774784L0.0677967 4.55332C-0.0225992 4.64371 -0.0225992 4.78834 0.0677967 4.86066Z"
      fill={fill}
    />
  </svg>
);

ArrowUpIcon.propTypes = {
  width:  PropTypes.number,
  height: PropTypes.number,
  fill:   PropTypes.string
};

export default ArrowUpIcon;
