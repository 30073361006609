import _ from 'lodash';
import {
  BODY_TEMPERATURE_TYPES,
  KINSA_TEMPERATURE,
  HK_BODY_TEMPERATURE,
  PHR_CATEGORY
} from 'app/PersonalHealthRecords/constants';

class PersonalHealthRecord {
  constructor(data={}) {
    Object.assign(this, {
      clinicalDocumentId: data.clinical_document_id,
      labTestName: data.lab_test_name,
      labResultType: data.lab_result_type,
      numericResult: data.numeric_result,
      measurementUnit: data.measurement_unit,
      startDate: data.start_date,
      dataSourceName: data.data_source_name,
      id: data.clinical_lab_result_id
    });
  }

  isForTemperature(){
    return _.includes(BODY_TEMPERATURE_TYPES, this.labTestName);
  }

  getNumbericResult(){
    return _.round(this.numericResult, 1);
  }

  getReading(){
    if (this.isForTemperature()){
      return this.getTemperature();
    } else {
      return `${this.getNumbericResult()}${this.measurementUnit}`;
    }
  }

  getDate(){
    return new Date(this.startDate).toLocaleDateString();
  }

  getTime(){
    return new Date(this.startDate).toLocaleTimeString();
  }

  getType(){
    return PHR_CATEGORY[this.labResultType];
  }

  getUnits(){
    switch (this.measurementUnit) {
      case 'degF':
        return '°F';
      case 'degC':
        return '°C';
      default:
        return this.measurementUnit;
    }
  }

  getTemperature(){
    const abbr = (this.measurementUnit == "degF") ? "F" : "C";
    return `${this.getNumbericResult()}°${abbr}`;
  }
}

export default PersonalHealthRecord;
