import React from 'react';
import PropTypes from 'prop-types';

class RevokeOrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      internalNote:  '',
      externalNote:  '',
      pendingSubmit: false
    };
  }

  onFieldChange = evt => {
    const {
      target: { name, value }
    } = evt;
    this.setState({ [name]: value });
  };

  onSubmit = evt => {
    evt.preventDefault();

    const { internalNote, externalNote } = this.state;
    this.setState({ pendingSubmit: true });
    this.props.onSubmit({ internalNote, externalNote });
  };

  render() {
    const { drugName } = this.props;
    const { pendingSubmit } = this.state;

    return (
      <form className="revoke-order-form" onSubmit={this.onSubmit}>
        <h2>Revoke Order</h2>

        <p>
          <strong>{drugName}</strong>
        </p>

        <div className="form-field">
          <label htmlFor="nurse_action_revoke_order_internal_notes">Internal Note:</label>
          <textarea
            id="nurse_action_revoke_order_internal_notes"
            name="internalNote"
            rows="15"
            onChange={this.onFieldChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="nurse_action_revoke_order_external_notes">External Note:</label>
          <textarea
            id="nurse_action_revoke_order_external_notes"
            name="externalNote"
            rows="15"
            onChange={this.onFieldChange}
          />
        </div>
        <div style={{ marginTop: '1.5rem' }} className="form-actions">
          <button disabled={pendingSubmit} className="button-primary button" name="commit" type="submit">
            {pendingSubmit ? 'Revoking' : 'Revoke this order'}
          </button>
        </div>
      </form>
    );
  }
}

RevokeOrderForm.propTypes = {
  drugName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default RevokeOrderForm;
