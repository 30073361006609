import React from 'react';
import PropTypes from 'prop-types';

const AudioIcon = ({ width = 11, height = 11, fill = '#565757', ...props }) => (
  <svg {...props} width={width} height={height} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2269 0.483642L8.20812 0.0326463C7.73565 -0.0747336 7.26318 0.161502 7.09137 0.591022L6.14643 2.78157C5.97462 3.16814 6.082 3.64061 6.42562 3.9198L7.28465 4.62851C6.68333 5.74526 5.73838 6.66873 4.62163 7.29153L3.91293 6.43249C3.63374 6.08887 3.16127 5.98149 2.7747 6.1533L0.584148 7.07677C0.154628 7.27005 -0.0816077 7.74253 0.0257722 8.215L0.476768 10.2337C0.584148 10.6847 0.992192 10.9854 1.44319 10.9854C6.7048 10.9854 11 6.73315 11 1.45006C11 0.999066 10.6779 0.591022 10.2269 0.483642ZM1.48614 9.95455L1.03514 8.02171L3.13979 7.11972L4.34244 8.58009C6.46857 7.57072 7.56384 6.47544 8.57321 4.34932L7.11285 3.14666L8.01484 1.04202L9.96915 1.49301C9.94768 6.1533 6.14643 9.93308 1.48614 9.95455Z"
      fill={fill}
    />
  </svg>
);

AudioIcon.propTypes = {
  width:  PropTypes.number,
  height: PropTypes.number,
  fill:   PropTypes.string
};

export default AudioIcon;
