import React from 'react';
import PropTypes from 'prop-types';

const CancelRxModalInfo = ({ patient, prescription, prescriber, pharmacy }) => (
  <div className="cancel-rx-modal-info">
    <h2 className="title">Patient</h2>
    <table>
      <tbody>
        <tr>
          <th className="header-field">Member ID</th>
          <td className="data-field">{patient.id}</td>
        </tr>
        <tr>
          <th className="header-field">Name</th>
          <td className="data-field">{patient.name}</td>
        </tr>
        <tr>
          <th className="header-field">Gender</th>
          <td className="data-field">{patient.gender}</td>
        </tr>
        <tr>
          <th className="header-field">Phone Number</th>
          <td className="data-field">{patient.phone}</td>
        </tr>
        <tr>
          <th className="header-field">Address</th>
          <td className="data-field">{patient.address}</td>
        </tr>{' '}
        <tr>
          <th className="header-field">Date of birth</th>
          <td className="data-field">{patient.dob}</td>
        </tr>{' '}
        <tr>
          <th className="header-field">Height</th>
          <td className="data-field">{patient.height}</td>
        </tr>{' '}
        <tr>
          <th className="header-field">Weight</th>
          <td className="data-field">{patient.weight}</td>
        </tr>
      </tbody>
    </table>
    <h2 className="title">Prescription</h2>
    <table>
      <tbody>
        <tr>
          <th className="header-field">Name</th>
          <td className="data-field">{prescription.name}</td>
        </tr>
        <tr>
          <th className="header-field">SIG</th>
          <td className="data-field">{prescription.sig}</td>
        </tr>
        <tr>
          <th className="header-field">Notes</th>
          <td className="data-field">{prescription.notes}</td>
        </tr>
        <tr>
          <th className="header-field">Refills</th>
          <td className="data-field">{prescription.refills}</td>
        </tr>
      </tbody>
    </table>
    <h2 className="title">Physician</h2>
    <table>
      <tbody>
        <tr>
          <th className="header-field">Name</th>
          <td className="data-field">{prescriber.name}</td>
        </tr>
        <tr>
          <th className="header-field">Address</th>
          <td className="data-field">{prescriber.address}</td>
        </tr>
      </tbody>
    </table>
    <h2 className="title">Pharmacy</h2>
    <table>
      <tbody>
        <tr>
          <th className="header-field">Name</th>
          <td className="data-field">{pharmacy.name}</td>
        </tr>
        <tr>
          <th className="header-field">Address</th>
          <td className="data-field">{pharmacy.address}</td>
        </tr>
        <tr>
          <th className="header-field">Phone Number</th>
          <td className="data-field">{pharmacy.phone}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

CancelRxModalInfo.defaultProps = {
  patient:      {},
  prescription: {},
  prescriber:   {},
  pharmacy:     {}
};

CancelRxModalInfo.propTypes = {
  patient: PropTypes.shape({
    id:      PropTypes.number,
    name:    PropTypes.string,
    gender:  PropTypes.string,
    phone:   PropTypes.string,
    address: PropTypes.string,
    dob:     PropTypes.string,
    height:  PropTypes.string,
    weight:  PropTypes.string
  }).isRequired,
  prescription: PropTypes.shape({
    name:    PropTypes.string,
    sig:     PropTypes.string,
    notes:   PropTypes.string,
    refills: PropTypes.number
  }).isRequired,
  prescriber: PropTypes.shape({
    name:    PropTypes.string,
    address: PropTypes.string
  }).isRequired,
  pharmacy: PropTypes.shape({
    name:    PropTypes.string,
    address: PropTypes.string,
    phone:   PropTypes.string
  }).isRequired
};

export default CancelRxModalInfo;
