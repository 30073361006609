import React from 'react';
import PropTypes from 'prop-types';

import GuidedMenuItem from './item';
import { ConsultationPrompt } from '../../study-prompts';
import { BarLoader } from '@td/shared_utils';

import styles from './styles.scss';
import { isEmpty } from 'lodash';

class GuidedMenu extends React.Component {
  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
  }

  state = {
    touchedItems: []
  };

  componentDidUpdate(_prevProps) {
    if (this.menuRef.current) {
      this.menuRef.current.scrollIntoView({
        behavior: 'smooth',
        block:    'center'
      });
    }
  }

  render() {
    const { selectedIndex, items, changeStep, consultationId } = this.props;
    if (isEmpty(items)) {
      return <BarLoader />;
    }

    const errors = items[selectedIndex].errors || [];
    const warnings = items[selectedIndex].warnings || [];
    const studyConsultationPrompts = selectedIndex > 2 && selectedIndex < 7 && (
      <ConsultationPrompt consultationId={consultationId} />
    );

    // Filtering to form up an array of just strings with validation error messages
    const filteredErrors = errors.map(errorItem => errorItem.message || errorItem);

    return (
      <div>
        <ul className={styles.consultNav} ref={this.menuRef}>
          {items.map((item, index) => (
            <GuidedMenuItem
              item={item}
              key={item.name}
              touched={this.state.touchedItems.includes(item.name)}
              step={index}
              totalSteps={items.length}
              selectedIndex={selectedIndex}
              onClick={step => {
                changeStep(step);

                if (!this.state.touchedItems.includes(item.name)) {
                  this.state.touchedItems.push(item.name);
                }
              }}
            />
          ))}
        </ul>
        {!!filteredErrors.length && (
          <div className={styles.errors} data-testid="errors">
            <ul>
              {filteredErrors.map((error, index) => (
                <li className={styles.error} key={index}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        )}
        {!!warnings.length && (
          <div className={styles.warnings} data-testid="warnings">
            <ul>
              {warnings.map((warning, index) => (
                <li className={styles.warning} key={index}>
                  {warning.value}
                </li>
              ))}
            </ul>
          </div>
        )}
        {studyConsultationPrompts}
      </div>
    );
  }
}

GuidedMenu.defaultProps = {
  errors: []
};

GuidedMenu.propTypes = {
  errors:         PropTypes.arrayOf(PropTypes.object).isRequired,
  items:          PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedIndex:  PropTypes.number.isRequired,
  changeStep:     PropTypes.func.isRequired,
  consultationId: PropTypes.number
};

export default GuidedMenu;
