import { includes } from 'lodash';
import {
  DISCONNECTED,
  NO_ANSWER,
  NOT_CONNECTED,
  CONNECT_FAILED
} from '../lib/states';

// Enable calls to the participants when their call statuses are either
// DISCONNECTED, NO_ANSWER,
// NOT_CONNECTED -- We didn't attach dial button / hangup button to it
// for multiway communication consultations
// Adding NOT_CONNECTED to this list is allowing providers to call
// new created participants after the conference is started,
// like Language Lines, Hear Impaired Lines or inbound callers who was dropped from the calls
export const isEnabledToCall = currentCallStatus =>
  includes([DISCONNECTED, NO_ANSWER, NOT_CONNECTED, CONNECT_FAILED], currentCallStatus);
