import {
  createRequestActionTypes,
  createFormActionTypes
} from '@td/utils';

export const FETCH_DROPDOWN_VALUES = createRequestActionTypes(
  'subjective',
  'FETCH_DROPDOWN_VALUES'
);

export const DURATION_SEVERITY_PROGRESSION_FORM = createFormActionTypes(
  'subjective',
  'DURATION_SEVERITY_PROGRESSION_FORM'
);
