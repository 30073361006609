import React from 'react';

export const CloseIcon = () => (
  <svg
    className="quick-dx-remove-icon"
    width="10"
    height="10"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.000244141" y="11.3137" width="16" height="2" rx="1" transform="rotate(-45 0.000244141 11.3137)" />

    <rect x="1.41431" width="16" height="2" rx="1" transform="rotate(45 1.41431 0)" />
  </svg>
);
