exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3oEoPDcQjNTLgNHQSlMOXd {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16px;\n  color: #7C7D74;\n  margin-top: 12px; }\n\n._2EPlN-TX6FsLZBWz-tVbZp {\n  display: flex;\n  flex-direction: column;\n  gap: 20px; }\n", ""]);

// exports
exports.locals = {
	"confirmationModalSubtitle": "_3oEoPDcQjNTLgNHQSlMOXd",
	"confirmationModalSubtitle": "_3oEoPDcQjNTLgNHQSlMOXd",
	"confirmationModalContent": "_2EPlN-TX6FsLZBWz-tVbZp",
	"confirmationModalContent": "_2EPlN-TX6FsLZBWz-tVbZp"
};