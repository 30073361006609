import $ from 'jquery';

// TODO: all DOM logic like JQuery selectors should be here

const rootSelector = '.followup.react-step';

export const usePcpSelectionDom = () => {
  const getValue = () => $(`${rootSelector} #secondary_referral_specialty_name_search`).val();
  const reset = () => {
    $(`${rootSelector} #secondary_referral_specialty_name_search`).val('');
    $(`${rootSelector} #interview_secondary_provider_specialty_cds`).val('');
  };
  return { getValue, reset };
};

const nameAttributePair = name => ({
  html:    `interview[${name}]`,
  restore: name
});

const arrayNameAttributePair = name => ({
  html:    `interview[${name}][]`,
  restore: name
});

export const NameAttributes = {
  appointment: {
    activeSection:           nameAttributePair('instruction_radio'),
    noFurtherCareDays:       nameAttributePair('followup_days'),
    noFurtherCareDaysHidden: {
      html: 'interview[followup_instruction_with_days]'
    }
  },
  referrals: {
    teladocServices: {
      service:         arrayNameAttributePair('secondary_teladoc_services'),
      serviceFeatures: {
        html: feature => `interview[secondary_provider_skills][${feature}]`
      },
      program: arrayNameAttributePair('programs'),
      p2pNote: nameAttributePair('secondary_followup_note')
    },
    partnerServices:    arrayNameAttributePair('teladoc_secondary_partners'),
    nonTeladocServices: {
      dropdown: nameAttributePair('secondary_followup_instruction'),
      p2pNote:  nameAttributePair('secondary_non_teladoc_service_note')
    }
  }
};
