import React from 'react';

import { AutoDialerV3 } from '@teladoc/auto_dialer';
import LoadingIndicator from 'app/components/loading-indicator/loading-indicator';
import * as styles from '../styles.module.scss';

import { VIEW_STATES } from '../constants/call-status';
import { useAutoDialer } from '../utils';

const {
  ReadyToCallWindow,
  CallControls,
  DialingMemberRedesign,
  DialingInterpreter,
  JoinViaPhone,
  UnavailableMemberModals,
} = AutoDialerV3;

const WebRTC = ({ containerRef }) => {
  const { viewState, attendeeStatusesData } = useAutoDialer();
  const { isInterpreterOnCall } = attendeeStatusesData;

  return (
    <main ref={containerRef}>
      <div className={styles.callViewContainer}>
        {viewState === VIEW_STATES.UNKNOWN && (
          <div className={styles.loadingContainer}>
            <div>
              <LoadingIndicator />
              <p>Loading...</p>
            </div>
          </div>
        )}

        {viewState === VIEW_STATES.START_CALL && <ReadyToCallWindow />}

        {(
          viewState === VIEW_STATES.UNAVAILABLE ||
          viewState === VIEW_STATES.CALL_IN_PROGRESS ||
          viewState === VIEW_STATES.DIALING_MEMBER
        ) && (
          <div className={styles.dialingContainer}>
            <DialingMemberRedesign />
            {isInterpreterOnCall && <DialingInterpreter />}
          </div>
        )}

        {viewState === VIEW_STATES.JOIN_VIA_PHONE && <JoinViaPhone />}
      </div>
      {viewState !== VIEW_STATES.UNKNOWN && viewState !== VIEW_STATES.JOIN_VIA_PHONE && (
        <CallControls viewState={viewState} />
      )}

      {viewState === VIEW_STATES.UNAVAILABLE && <UnavailableMemberModals />}
    </main>
  );
};

// Consultation obj shape converted exhaustively in AutoDialerInitializer.js
WebRTC.propTypes = {};

WebRTC.displayName = 'WebRTC';

export default WebRTC;
