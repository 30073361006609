import React, { createContext, useContext } from 'react';
import useFetchWaitlist from '../../hooks/useFetchWaitlist';

const MatchesForWaitlistTableContext = createContext();

export const useMatchesForWaitlistTable = () => useContext(MatchesForWaitlistTableContext);

export const MatchesForWaitlistTableProvider = ({ children }) => {
  const consultationsData = useFetchWaitlist();

  return (
    <MatchesForWaitlistTableContext.Provider value={consultationsData}>
      {children}
    </MatchesForWaitlistTableContext.Provider>
  );
};
