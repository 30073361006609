import { diagnosisValidate } from './emr-assessment';

const updateANoteSummary = aNote => {
  $('#a_note').text(aNote.val() || 'N/A');
};

const assessmentNoteValidate = () => {
  const validations = [];
  const aNote = $('.soap_note.a');
  const aNoteMinLength = $(aNote).data('length');

  validations.push({
    validator: {
      message: `SOAP Notes (A) must contain at least ${aNoteMinLength} characters`,
      invalid: aNote.val().trim().length < aNoteMinLength
    },
    errorContainerSelector: '#soap_a_validation_error'
  });

  const invalidValidations = validations.filter(({ validator }) => validator.invalid);

  if (!invalidValidations.length) {
    updateANoteSummary(aNote);
  }

  return validations;
};
const dx = () => diagnosisValidate().concat(assessmentNoteValidate());

export default () => Promise.resolve(dx());
