import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NutPlanCheckbox from './nut-plan-checkbox-control';
import './suggested-supplements.scss';

export default class SuggestedSupplements extends Component {
  constructor(props) {
    super(props);
    this.getControls = this.getControls.bind(this);
    this.controlNamePrefix =
      'interview[provider_intake_surveys][SURVEYMNEMONIC_NUTPLAN][sections][SECTIONMNEMONIC_NUTPLAN_SUPPLEMENTS]';
    this.controls = this.getControls();
  }

  getControls() {
    return Object.entries(this.props.section.questions)
      .filter(question => question[1].question_type === 'checkbox')
      .map(question => (<NutPlanCheckbox
        key={question[1].question}
        control={question[1]}
        questionIdentifier={question[0]}
        controlNamePrefix={`${this.controlNamePrefix}[questions]`}
        classNameIdentifier="suggested-supplements"
      />));
  }

  render() {
    return (
      <div className="suggested-supplements-wrapper">
        <div className="suggested-supplements-notice">
          <span>{this.props.section.notice}</span>
        </div>
        <div className="suggested-supplements-controls">
          {this.controls}
        </div>
        <div className="reviewed-checkbox">
          <input type="hidden" name={`${this.controlNamePrefix}[reviewed]`} value="false" />
          <input
            id="suggested-supplements-reviewed"
            type="checkbox"
            name={`${this.controlNamePrefix}[reviewed]`}
            value="true"
            onChange={this.props.toggleSection}
            checked={this.props.section.reviewed}
          />
          <label
            htmlFor="suggested-supplements-reviewed"
          >
            {`Reviewed ${this.props.section.title}`}
          </label>
        </div>
      </div>
    );
  }
}

SuggestedSupplements.propTypes = {
  section:       PropTypes.object.isRequired,
  toggleSection: PropTypes.func.isRequired
};
