import { authToken } from '@td/api';

import {
  FETCH_PROVIDER_REQUEST,
  FETCH_PROVIDER_START,
  FETCH_PROVIDER_SUCCESS,
  FETCH_PROVIDER_FAIL,
  UPDATE_PROVIDER_SUCCESS,
  UPDATE_PROVIDER_FAIL,
  TRACK_FOR_CLOCK_OUT,
  TRACK_CONSULT_INACTIVITY,
  TRACK_LOCKED_CONSULT,
  TRACK_IN_REVIEW_CONSULT,
  START_REVIEW,
  EXTEND_EHR_REVIEW,
  LOG_ALERT_ACKNOWLEDGEMENT
} from './actionTypes';

export const fetchProviderStart = () => ({
  type: FETCH_PROVIDER_START
});

export const fetchProviderSuccess = payload => ({
  type: FETCH_PROVIDER_SUCCESS,
  payload
});

export const fetchProviderFail = error => ({
  type: FETCH_PROVIDER_FAIL,
  error: true,
  message: error.message || 'Something went wrong'
});

export const fetchProviderRequest = payload => {
  const endpoint = `/v4/providers/${payload}`;
  const params = {};
  return {
    type: FETCH_PROVIDER_REQUEST,
    payload: { url: endpoint, params, token: authToken.get() }
  };
};

export const updateProviderSuccess = () => ({
  type: UPDATE_PROVIDER_SUCCESS
});

export const updateProviderFail = () => ({
  type: UPDATE_PROVIDER_FAIL
});

export const trackForClockOut = clockOutAlertTiming => ({
  type: TRACK_FOR_CLOCK_OUT,
  payload: {
    clockOutAlertTiming
  }
});

export const trackConsultInactivity = consultInactivitySettings => ({
  type: TRACK_CONSULT_INACTIVITY,
  payload: consultInactivitySettings
});

export const trackLockedConsult = payload => ({
  type: TRACK_LOCKED_CONSULT,
  payload
});

export const trackInReviewConsult = payload => ({
  type: TRACK_IN_REVIEW_CONSULT,
  payload
});

export const startReview = payload => ({
  type: START_REVIEW,
  payload
});

export const extendEhrReview = payload => ({
  type: EXTEND_EHR_REVIEW,
  payload
});

export const logAlertAcknowledgement = payload => ({
  type: LOG_ALERT_ACKNOWLEDGEMENT,
  payload
});
