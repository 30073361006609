import { mapKeysDeep, transformKey } from '@td/utils';
import _ from 'lodash';

import render from '../lib/render';

import AutoDialerInitializerV1 from './v1/components/AutoDialerInitializer';
import AutoDialerInitializerV2 from './v2/components/AutoDialerInitializer';
import AutoDialerInitializerV3 from './v3/components/AutoDialerInitializer';


const DEFAULT_AUTO_DIALER_VERSION = AutoDialerInitializerV1;

export const initializeAutoDialer = ({ $rootNode }) => {
  const autodialParams = $rootNode.find('#react_auto_dialer_widget_container').data('autodial_params');
  const transformedAutoDialerProps = mapKeysDeep(autodialParams, transformKey);
  const autodialerVersion = _.get(autodialParams, 'version');

  const versions = {
    v1: AutoDialerInitializerV1,
    v2: AutoDialerInitializerV2,
    v3: AutoDialerInitializerV3,
  };

  const AutoDialerInitializer = versions[autodialerVersion] || DEFAULT_AUTO_DIALER_VERSION;

  render(AutoDialerInitializer, '#react_auto_dialer_widget_container', {
    ...transformedAutoDialerProps,
    options: {
      ..._.get(transformedAutoDialerProps, 'options', {}),
      startAutoDialerCallAutomatically: Boolean(window.FEATURE_TOGGLES.start_autodialer_call_automatically)
    }
  });
};
