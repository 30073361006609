import * as Yup from 'yup';
import { string } from 'yup';
import { get } from 'lodash';
import moment from 'moment';
import { getCurrentDate } from 'app/canada/health-card/utils';


const healthCardIdValidation = (
  { required, healthCardExpiry, healthCardProvince, ID_FORMAT_BY_STATE }) => string().trim().when([],
  schema => {
    let resultSchema = schema;

    if (required || healthCardExpiry || healthCardProvince) {
      resultSchema = schema.required(
        'Please enter the appropriate health card number.'
      );
    }

    const provinceRegexp = get(ID_FORMAT_BY_STATE, healthCardProvince);

    if (provinceRegexp) {
      const errorMessage = 'Please enter the appropriate health card number.';

      resultSchema = resultSchema.matches(new RegExp(provinceRegexp), {
        message:            errorMessage,
        excludeEmptyString: true
      });
    }

    return resultSchema;
  }).nullable();

const healthCardExpiryValidation = (
  { required, healthCardId, healthCardProvince, EXPIRY_OBLIGATIONS_BY_STATE }
) => string().trim().when([],
  schema => {
    let resultSchema = schema;

    const expiryByState = get(EXPIRY_OBLIGATIONS_BY_STATE, healthCardProvince);

    if (!!expiryByState || required || healthCardId || healthCardProvince) {
      resultSchema = schema.required(
        'Please enter a valid date.'
      );
    }

    const format = 'MM/DD/YYYY';

    resultSchema = resultSchema.test(
      'validISODate',
      'Please enter a valid date.',
      expiry =>
        !expiry ||
        moment(expiry, format, true).isValid()
    );

    resultSchema = resultSchema.test('expiryInPast',
      'Health Card Expiry is in the past',
      value => {
        const healthCardExpiry = moment(value, format, true);
        const isOutDated = healthCardExpiry.isValid() && moment() > healthCardExpiry;

        const isEqual = getCurrentDate() === value;

        if (isEqual) return true;

        return !isOutDated;
      }
    );

    return resultSchema;
  }).nullable();

export const healthCardProvinceValidation = ({ required, healthCardId, healthCardExpiry }) => string().trim().when([], {
  is:   () => required || healthCardExpiry || healthCardId,
  then: Yup.string().trim().required(
    'Please select the issuing province for your health card.'),
  otherwise: Yup.string().trim()
});

export const healthCardValidateSchema = ({ required, validationConstants }) => Yup.lazy(values => {
  const { healthCardId, healthCardExpiry, healthCardProvince } = values;
  return Yup.object().shape({
    healthCardId:       healthCardIdValidation({ required, healthCardExpiry, healthCardProvince, ...validationConstants }),
    healthCardProvince: healthCardProvinceValidation({ required, healthCardExpiry, healthCardId, ...validationConstants }),
    healthCardExpiry:   healthCardExpiryValidation({ required, healthCardProvince, healthCardId })
  });
});
