import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { flatMap, switchMap, takeUntil, startWith, catchError } from 'rxjs/operators';
import { authToken, teladocApi } from '@td/api';

import { SUBMIT_PENDING_REVIEW_LIST, SUBMIT_PENDING_REVIEW_LIST_CANCEL } from './action-types';
import { submitPendingReviewListSuccess, submitPendingReviewListFail, submitPendingReviewListStart } from './actions';

import { fetchClinicalMedications } from '../ClinicalMedication/actions';

import { success, error as errorNotify } from '../notifications/actions';

const postPendingReviewList = (payload, memberId) => {
  const params = {
    member_id:            memberId,
    clinical_medications: payload
  };

  const url = `/v4/members/${memberId}/clinical_medications/batch_update`;
  return from(teladocApi.put(url, params, authToken.get()));
};

export const submitPendingReviewListEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_PENDING_REVIEW_LIST),
    switchMap(action => {
      const {
        settings: {
          memberParams: { memberId }
        }
      } = state$.value;

      return postPendingReviewList(action.payload, memberId).pipe(
        flatMap(response =>
          of(
            submitPendingReviewListSuccess(response),
            fetchClinicalMedications({ memberId }),
            success('Medication status updated successfully!')
          )
        ),
        takeUntil(action$.pipe(ofType(SUBMIT_PENDING_REVIEW_LIST_CANCEL))),
        startWith(submitPendingReviewListStart()),
        catchError(
          error => of(submitPendingReviewListFail(error)),
          errorNotify('Failed to update medication status. Please resubmit again.')
        )
      );
    })
  );
