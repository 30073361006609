import { createRequestEpic } from '@td/utils';
import { from, of, EMPTY, race, merge } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, first, switchMap, delay, takeUntil, mapTo, catchError, filter } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { teladocApi, authToken } from '@td/api';
import get from 'lodash/get';

import createRequestEpicWithNotification from '../../lib/redux/utilities/create-request-epic';
import {
  testQuality,
  testConnectivity,
  createNetworkTestSession
} from '../../lib/open-tok-network-test/open-tok-network-test';
import {
  PERFORM_QUALITY_CHECK,
  PERFORM_CONNECTIVITY_CHECK,
  NETWORK_TEST_START,
  NETWORK_TEST_CANCEL,
  NETWORK_TEST_SUCCESS,
  NETWORK_TEST_FAIL,
  LOG_BANDWIDTH_TEST_RESULTS,
  CHECK_NETWORK_TEST_NEEDED
} from '../action-types';
import {
  performQualityCheck,
  performConnectivityCheck,
  networkTestFail,
  networkTestSuccess,
  networkTestProlonged,
  logBandwidthTestResults,
  updateNetworkTestSettings,
  networkTestCancel,
  networkTestStart
} from '../actions';
import getProviderClockedIn from 'app/Provider/selectors/getProviderClockedIn';
import { clockInProvider } from 'app/sidebar/provider-clock-in-out/actions';
import ProviderClockInRequest from 'app/sidebar/provider-clock-in-out/enums/provider-clock-in-request';
import getProviderId from 'app/Provider/selectors/getProviderId';
import getProviderParams from 'app/Provider/selectors/getProviderParams';
import ProviderClockInType from 'app/Provider/enums/provider-clock-in-type';
import { CLOCK_IN_PROVIDER } from 'app/sidebar/provider-clock-in-out/action-types';

const TEST_PROLONGED_TIME = 30 * 1000;

export const checkNetworkTestNeededEpic = createRequestEpicWithNotification(CHECK_NETWORK_TEST_NEEDED, ({ store }) => {
  const providerId = getProviderId(store.value);

  return from(teladocApi.get(`/v4/providers/${providerId}/bandwidth_test_needed`, null, authToken.get()));
});

export const checkNetworkTestNeededSuccessEpic = action$ =>
  action$.pipe(
    ofType(CHECK_NETWORK_TEST_NEEDED.SUCCESS),
    map(action => {
      if (action.payload.bandwidth_test_needed) {
        return networkTestStart();
      } else {
        return clockInProvider.start({ clockInType: ProviderClockInRequest.VIDEO });
      }
    })
  );

const networkTestError$ = error =>
  of(
    networkTestFail(
      {
        message:    error.message,
        errorCodes: error.errorCodes
      },
      error.testResults
    )
  );

export const performTest$ = (actionTypes, test) =>
  createRequestEpic(
    actionTypes,
    ({ action$, action }) =>
      race(
        from(test(action.payload.session)),
        action$.pipe(
          ofType(NETWORK_TEST_CANCEL),
          first(),
          switchMap(() => {
            action.payload.session.stop();

            return EMPTY;
          })
        )
      ),
    [networkTestError$]
  );

export const onNetworkTestStartEpic = action$ =>
  action$.pipe(
    ofType(NETWORK_TEST_START),
    switchMap(() =>
      from(createNetworkTestSession()).pipe(
        switchMap(session =>
          merge(
            of(performConnectivityCheck.start({ session })),
            action$.pipe(
              ofType(PERFORM_CONNECTIVITY_CHECK.SUCCESS),
              map(() => performQualityCheck.start({ session }))
            ),
            action$.pipe(
              ofType(PERFORM_QUALITY_CHECK.SUCCESS),
              map(action => networkTestSuccess(action.payload.testResults))
            )
          )
        ),
        catchError(networkTestError$)
      )
    )
  );

export const performConnectivityCheckEpic = performTest$(PERFORM_CONNECTIVITY_CHECK, testConnectivity);

export const performQualityCheckEpic = performTest$(PERFORM_QUALITY_CHECK, testQuality);

export const onNetworkTestProlongedEpic = action$ =>
  action$.pipe(
    ofType(NETWORK_TEST_START),
    switchMap(() =>
      of({}).pipe(
        delay(TEST_PROLONGED_TIME),
        map(() => networkTestProlonged()),
        takeUntil(action$.pipe(ofType(NETWORK_TEST_CANCEL, NETWORK_TEST_SUCCESS, NETWORK_TEST_FAIL)))
      )
    )
  );

export const onNetworkTestFailEpic = action$ =>
  action$.pipe(
    ofType(NETWORK_TEST_FAIL),
    map(action =>
      logBandwidthTestResults.start({
        results: action.payload.testResults,
        error:   {
          message:     action.payload.error.message,
          error_codes: action.payload.error.errorCodes
        }
      })
    )
  );

export const onNetworkTestSuccessEpic = action$ =>
  action$.pipe(
    ofType(NETWORK_TEST_SUCCESS),
    map(action => logBandwidthTestResults.start({ results: action.payload.testResults, error: null }))
  );

export const logBandwidthTestResultsEpic = createRequestEpicWithNotification(
  LOG_BANDWIDTH_TEST_RESULTS,
  ({ action, store }) => {
    const providerId = getProviderId(store.value);

    return from(
      teladocApi.post(
        `/v4/providers/${providerId}/bandwidth_test_results`,
        { bandwidth_test: action.payload },
        authToken.get()
      )
    );
  }
);

const clockInProvider$ = (allowVideoClockIn, state$) =>
  of({}).pipe(
    filter(
      () =>
        !getProviderClockedIn(state$.value) ||
        (get(getProviderParams, 'clockInType') !== ProviderClockInType.VIDEO && allowVideoClockIn)
    ),
    map(() => {
      if (allowVideoClockIn) {
        return clockInProvider.start({ clockInType: ProviderClockInRequest.VIDEO });
      }

      return clockInProvider.start({ clockInType: ProviderClockInRequest.NORMAL });
    })
  );

export const onLogBandwidthTestResultsSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOG_BANDWIDTH_TEST_RESULTS.SUCCESS),
    map(action => action.payload.allow_video_clock_in),
    switchMap(allowVideoClockIn =>
      merge(of(updateNetworkTestSettings({ allowVideoClockIn })), clockInProvider$(allowVideoClockIn, state$))
    )
  );

export const onFinalizingNetworkTestFailedEpic = action$ =>
  action$.pipe(ofType(LOG_BANDWIDTH_TEST_RESULTS.FAIL, CLOCK_IN_PROVIDER.FAIL), mapTo(networkTestCancel()));

export default combineEpics(
  checkNetworkTestNeededEpic,
  checkNetworkTestNeededSuccessEpic,
  onNetworkTestStartEpic,
  onNetworkTestProlongedEpic,
  onNetworkTestFailEpic,
  onNetworkTestSuccessEpic,
  performConnectivityCheckEpic,
  performQualityCheckEpic,
  logBandwidthTestResultsEpic,
  onLogBandwidthTestResultsSuccessEpic,
  onFinalizingNetworkTestFailedEpic
);
