import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ChiefComplaint from './chief-complaint';
import IComplaint from '../../interfaces/complaint-interface';
import ICachedComplaint from '../../interfaces/cached-complaint-interface';
import { mapInlineErrors } from 'app/consultation-interview/utils';

import {
  fetchComplaintsClasses,
  fetchComplaintsList,
  resetComplaintsList,
  selectComplaint,
  clearComplaint
} from '../../actions';

export class ChiefComplaintContainer extends Component {
  componentDidMount() {
    if (!this.props.classesFetched) {
      this.props.fetchComplaintsClasses(this.props.specialty);
    }
  }

  componentWillUnmount() {
    if (this.props.classesLoading) {
      this.props.cancelFetchComplaintsClasses();
    }

    if (this.props.autosuggestLoading) {
      this.props.cancelFetchComplaintsList();
    }
  }

  render() {
    if (!this.props.classesFetched) {
      return null;
    }

    return (
      <ChiefComplaint
        complaintSuggestions={this.props.complaintSuggestions}
        complaintsClasses={this.props.complaintsClasses}
        fetchComplaintsList={this.props.fetchComplaintsList}
        resetComplaintsList={this.props.resetComplaintsList}
        selectComplaint={this.props.selectComplaint}
        clearComplaint={this.props.clearComplaint}
        selectedComplaint={this.props.complaint}
        cachedComplaint={this.props.cachedComplaint}
        validationErrors={this.props.validationErrors}
      />
    );
  }
}

ChiefComplaintContainer.propTypes = {
  specialty:                    PropTypes.string.isRequired,
  complaint:                    IComplaint,
  complaintSuggestions:         PropTypes.array.isRequired,
  classesFetched:               PropTypes.bool.isRequired,
  classesLoading:               PropTypes.bool.isRequired,
  autosuggestLoading:           PropTypes.bool.isRequired,
  fetchComplaintsClasses:       PropTypes.func.isRequired,
  cancelFetchComplaintsClasses: PropTypes.func.isRequired,
  fetchComplaintsList:          PropTypes.func.isRequired,
  cancelFetchComplaintsList:    PropTypes.func.isRequired,
  resetComplaintsList:          PropTypes.func.isRequired,
  selectComplaint:              PropTypes.func.isRequired,
  clearComplaint:               PropTypes.func.isRequired,
  complaintsClasses:            PropTypes.shape({
    ref_class_complaint_id: PropTypes.number,
    symptom_class:          PropTypes.string
  }),
  cachedComplaint:  ICachedComplaint,
  validationErrors: PropTypes.array
};

const mapStateToProps = state => ({
  complaintSuggestions: state.chiefComplaintSuggestions.complaintSuggestions,
  classesFetched:       state.complaintsClassesRequest.isSuccess,
  classesLoading:       state.complaintsClassesRequest.isLoading,
  autosuggestLoading:   state.chiefComplaintRequest.isLoading,
  complaint:            state.chiefComplaint,
  complaintsClasses:    state.complaintsClasses.complaintsClasses,
  validationErrors:     mapInlineErrors(state)
});

const mapDispatchToProps = {
  fetchComplaintsClasses:       fetchComplaintsClasses.start,
  cancelFetchComplaintsClasses: fetchComplaintsClasses.cancel,
  fetchComplaintsList:          fetchComplaintsList.start,
  cancelFetchComplaintsList:    fetchComplaintsList.cancel,
  resetComplaintsList,
  selectComplaint,
  clearComplaint
};

export default connect(mapStateToProps, mapDispatchToProps)(ChiefComplaintContainer);
