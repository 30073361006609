exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3SmVW1j1b1fIy0SzW2upPI {\n  display: flex;\n  flex-direction: column;\n  row-gap: 0.5rem; }\n  ._3SmVW1j1b1fIy0SzW2upPI:has(+ *) {\n    margin-bottom: 1rem; }\n", ""]);

// exports
exports.locals = {
	"bannersContainer": "_3SmVW1j1b1fIy0SzW2upPI",
	"bannersContainer": "_3SmVW1j1b1fIy0SzW2upPI"
};