import { translate } from '@td/shared_utils';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import infoIcon from '../../../assets/images/Info.svg'
import { NOTIFICATION_MESSAGES } from '../../constants';

const InfoTooltip = ({ messagesToDisplay, position}) => {
  const tooltipMessages = messagesToDisplay.map((message, index) => <div key={index}>{message}</div>);

  const getLabel = () => {
    if (messagesToDisplay.length === 2) {
      return `${translate(null, 'patient_case_load_mgmt', 'turned_off_by_admin')}, ${translate(
        null,
        'patient_case_load_mgmt',
        'no_active_licenses'
      )}`;
    } else if (messagesToDisplay.includes(NOTIFICATION_MESSAGES.turned_off_by_admin)) {
      return translate(null, 'patient_case_load_mgmt', 'turned_off_by_admin');
    } else if (messagesToDisplay.includes(NOTIFICATION_MESSAGES.no_active_licenses)) {
      return translate(null, 'patient_case_load_mgmt', 'no_active_licenses');
    } else {
      return;
    }
  };

  return (
    <div className="info-tooltip">
      <span className="label-text">
        {getLabel()}
        <img 
          data-place="bottom"
          data-event="click"
          data-tip src={infoIcon}
          className="info-icon"
          data-for={position}
        />
      </span>
      <ReactTooltip
        className="custom-tooltip"
        offset={{ left: 145 }}
        getContent={() => tooltipMessages}
        globalEventOff="click"
        id={position}
      />
    </div>
  );
};

export default InfoTooltip;
