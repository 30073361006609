import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'app/components/Modal';

const Alert = ({ content, buttons, closeAlert }) => (
  <div>
    {!!content && (
      <Modal
        className="alert-modal"
        centered
        show={!!content}
        onClose={closeAlert}
        showHeader={false}
      >
        <div className="alert-content">
          <div className="alert-content-body">{content}</div>
          <div className="alert-buttons">{buttons}</div>
        </div>
      </Modal>
    )}
  </div>
);

Alert.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttons: PropTypes.node,
  closeAlert: PropTypes.func
};

export default Alert;
