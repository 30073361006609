export const getFiltersCount = filters => {
  const { lastConsultationFrom, lastConsultationTo, nextConsultationFrom, nextConsultationTo, ...rest } = Object.assign(
    {
      lastConsultationRange: filters.lastConsultationFrom && filters.lastConsultationTo,
      nextConsultationRange: filters.nextConsultationFrom && filters.nextConsultationTo
    },
    filters
  );

  return Object.values(rest).filter(Boolean).length;
};
