import React from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../../components/TextInput';
import SelectInput, { selectOptionsType } from '../../../components/SelectInput';
import validation from '../../utilities/constants/validation';

const { severity, progression, duration } = validation;

const DurationSeverityProgressionForm = ({
  durationOptions,
  progressionOptions,
  severityOptions,
  formValues,
  changeField,
  durationRequired,
  severityRequired,
  progressionRequired,
  validationErrors
}) => {
  const validationErrorsKeys = [
    'durationNumber',
    'durationValue',
    'durationType',
    'severity',
    'otherSeverity',
    'progression',
    'otherProgression'
  ];

  const containerValidatonErrorsList = validationErrors.filter(({ validatorKey }) =>
    validationErrorsKeys.includes(validatorKey)
  );

  return (
    <div>
      {(durationRequired || severityRequired || progressionRequired) && (
        <h3>
          Duration, Severity & Progression (Required - Please describe the chief complaint using the selections below)
        </h3>
      )}
      <div className="dsp-inputs">
        {durationRequired && [
          <div className="duration-input-container" key="duration-input-container">
            <TextInput
              className="duration-input"
              name="interview[duration_value]"
              data-name="duration_value"
              label="Duration"
              placeholder="#"
              value={formValues.duration_value}
              onChange={changeField}
              min={0}
              type="number"
            />
            {formValues.duration_value !== '' &&
            (+formValues.duration_value <= duration.minValue || +formValues.duration_value > duration.maxValue) ? (
              <span className="duration-validation red">B/w 0-100</span>
            ) : null}
          </div>,
          <SelectInput
            className="duration-select"
            name="interview[duration_cd]"
            data-name="duration_cd"
            label=""
            options={durationOptions}
            defaultOption="Select Duration"
            value={formValues.duration_cd}
            onChange={changeField}
            min={0}
            type="number"
          />
        ]}
        {severityRequired && (
          <div className="select-with-input">
            <SelectInput
              name="interview[severity_cd]"
              data-name="severity_cd"
              label="Severity"
              options={severityOptions}
              defaultOption="Select Severity"
              value={formValues.severity_cd}
              onChange={changeField}
            />
            {formValues.severity_cd === 'CLINICALSEVERITY_OTHER' ? (
              <div>
                <TextInput
                  name="interview[severity_value]"
                  data-name="severity_value"
                  label=""
                  placeholder="(description required)"
                  value={formValues.severity_value}
                  onChange={changeField}
                  maxLength={severity.maxLength}
                />
                <span
                  className={`max-len-span
                    ${formValues.severity_value.length === severity.maxLength ? 'red' : ''}`}
                >
                  ({formValues.severity_value.length} of {severity.maxLength} characters max)
                </span>
              </div>
            ) : null}
          </div>
        )}
        {progressionRequired && (
          <div className="select-with-input">
            <SelectInput
              name="interview[progression_cd]"
              data-name="progression_cd"
              label="Progression"
              options={progressionOptions}
              defaultOption="Select Progression"
              value={formValues.progression_cd}
              onChange={changeField}
            />
            {formValues.progression_cd === 'CLINICALPROGRESSION_OTHER' ? (
              <div>
                <TextInput
                  name="interview[progression_value]"
                  data-name="progression_value"
                  label=""
                  placeholder="(description required)"
                  value={formValues.progression_value}
                  onChange={changeField}
                  maxLength={progression.maxLength}
                />
                <span
                  className={`max-len-span
                  ${formValues.progression_value.length === progression.maxLength ? 'red' : ''}`}
                >
                  ({formValues.progression_value.length} of {progression.maxLength} characters max)
                </span>
              </div>
            ) : null}
          </div>
        )}
      </div>

      {/* Validation errors */}
      {Boolean(containerValidatonErrorsList.length) && (
        <div className="interview_inline_validation_error show">
          {containerValidatonErrorsList.map(({ message, validatorKey }) => (
            <div key={`validation-error-${validatorKey}`}>{message}</div>
          ))}
        </div>
      )}
    </div>
  );
};

DurationSeverityProgressionForm.propTypes = {
  durationOptions:    selectOptionsType,
  severityOptions:    selectOptionsType,
  progressionOptions: selectOptionsType,
  formValues:         PropTypes.shape({
    duration_cd:       PropTypes.string,
    duration_value:    PropTypes.string,
    progression_cd:    PropTypes.string,
    progression_value: PropTypes.string,
    severity_cd:       PropTypes.string,
    severity_value:    PropTypes.string
  }).isRequired,
  changeField:         PropTypes.func.isRequired,
  durationRequired:    PropTypes.bool,
  severityRequired:    PropTypes.bool,
  progressionRequired: PropTypes.bool,
  validationErrors:    PropTypes.array
};

export default DurationSeverityProgressionForm;
