import React from 'react';
import PropTypes from 'prop-types';

import SymptomHiddenFields from './symptom-hidden-fields-container';

const SymptomsHiddenFields = ({ symptomIds }) => (
  <span>
    {symptomIds.map(symptomId => (
      <SymptomHiddenFields key={symptomId} symptomId={symptomId} />
    ))}
  </span>
);

SymptomsHiddenFields.propTypes = {
  symptomIds: PropTypes.arrayOf(PropTypes.number)
};

export default SymptomsHiddenFields;
