import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { I18n, translate } from '@td/shared_utils';

const AudioTest = ({ handleClose }) => {
  const [hasSoundPlayed, setHasSoundPlayed] = useState(false);
  const [audio] = useState(new window.Audio('https://communications.teladoc.com/downloads/test1234.mp3'));
  const [soundPlaying, setPlaying] = useState(false);
  const toggle = () => setPlaying(!soundPlaying);

  const onFailure = () => {
    setPlaying(true);
  };

  const soundPlayed = () => {
    setPlaying(false);
    setHasSoundPlayed(true);
  };

  useEffect(() => {
    if (soundPlaying) {
      audio.play();
    }

    return () => audio.pause();
  }, [soundPlaying]);

  useEffect(() => {
    audio.addEventListener('ended', soundPlayed);

    return () => {
      audio.removeEventListener('ended', soundPlayed);
    };
  }, []);

  return (
    <div className="auto-dialer-modal-container" id="auto-dialer-audio-test">
      <p>
        <I18n scope="video_test.test_page.sound_test" text="instructions" />
      </p>
      <button className="button tertiary" onClick={toggle} disabled={soundPlaying}>
        <I18n scope="video_test.test_page.sound_test" text="play_sound_button_label" />
      </button>

      <p>
        <I18n scope="video_test.test_page.sound_test" text="sound_played_question" />
      </p>
      <div className="audio-test-buttons">
        <button
          aria-label={translate(
            null,
            'video_test.test_page.sound_test.action_buttons.aria_labels',
            'audio_test_failed'
          )}
          className="button tertiary"
          disabled={!hasSoundPlayed || soundPlaying}
          id="audio-test-fail"
          onClick={onFailure}
        >
          {translate(null, 'video_test.test_page.sound_test.action_buttons.labels', 'audio_test_failed')}
        </button>
        <button
          aria-label={translate(
            null,
            'video_test.test_page.sound_test.action_buttons.aria_labels',
            'audio_test_passed'
          )}
          className="button tertiary"
          disabled={!hasSoundPlayed || soundPlaying}
          id="audio-test-success"
          onClick={handleClose}
        >
          {translate(null, 'video_test.test_page.sound_test.action_buttons.labels', 'audio_test_passed')}
        </button>
      </div>

      <p>
        <I18n scope="video_test.test_page.sound_test" text="problem_question" />
      </p>
      <p>
        <I18n scope="video_test.test_page.sound_test" text="troubleshooting_message" />
      </p>
    </div>
  );
};

AudioTest.propTypes = {
  handleClose: PropTypes.func.isRequired
};

AudioTest.displayName = 'AudioTest';

export default AudioTest;
