import React from 'react';
import PropTypes from 'prop-types';
import { MdEdit } from 'react-icons/md';

const SameDayVisitsSectionHeader = ({ canEdit, onEdit }) => {
  return (
    <h2>
      <div className="header">
        <p>{I18n.t('my_account.same_day_visits_section.title')}</p>
        {canEdit && (
          <a className="edit-link" onClick={onEdit}>
            {I18n.t('my_account.same_day_visits_section.edit')} <MdEdit className="edit-icon" />
          </a>
        )}
      </div>
    </h2>
  );
};

SameDayVisitsSectionHeader.propTypes = {
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func
};

export default SameDayVisitsSectionHeader;
