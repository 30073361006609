import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';
import PersonalHealthRecordGraph from './personalHealthRecordGraph';

class PersonalHealthRecord extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);
    this.state = { modalActive: false };
  }

  deactivateModal = () =>
    this.setState({modalActive: false});

  activateModal = () =>
    this.setState({modalActive: true});

  render() {
    return (
      <div>
        <a href="#" onClick={this.activateModal}>show</a>
        <AriaModal mounted={this.state.modalActive}
          titleText='Personal Health Data'
          focusDialog onExit={this.deactivateModal}>
          <div className="personal_health_records_modal">
            <h1>{this.props.data.getType()}
              <a className="close_modal" href="#" onClick={this.deactivateModal}>x</a>
            </h1>
            <div className="graph">
              <PersonalHealthRecordGraph data={this.props.data.personalHealthRecords} />
            </div>
          </div>
        </AriaModal>
      </div>
    );
  }
}

export default PersonalHealthRecord;
