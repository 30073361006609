import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { teladocApi, authToken } from '@td/api';
import { getRecordByKey, saveRecord } from '../../lib/persistence';

import PatientVolumeManagerContainer from '../../patient-volume-manager';
import PatientList from '../../patient-list';
import NotifyProvidersAboutNewFeature from '../../components/NewFeatureOverview';
import Loader from 'app/components/Loader';
import PatientListManageLimits from '../../patient-list/PatientListManageLimits';
import ConsultQueues from '../../consult-queues/components/ConsultQueues';

import useAnyConsultationLockedOrCompleted from '../hooks/useAnyConsultationLockedOrCompleted';
import { useData } from 'app/DataProvider';
import { useQuery } from '@apollo/client';
import FETCH_ACCEPTING_NEW_PATIENTS_VALUE from '../../patient-volume-manager/queries/fetch_accepting_new_patients_value.graphql';
import { autoDeclineEnabled } from '../../patient-volume-manager/helper';
import { ACCEPTING_NEW_PATIENT_REASON } from '../../patient-volume-manager/constants';

const currentTabKey = 'patients-dashboard-current-tab-index';

const DashboardContent = () => {
  const consultTab = useRef();
  const patientsTab = useRef();
  const [tabIndex, setTabIndex] = useState(Number(getRecordByKey(currentTabKey)) || 0);
  const [isOpen, setIsOpen] = useState(false);

  const naviagteToMyAccount = (url) => {
    window.location.href = url;
  };

  const {
    data: anyConsultLockedOrCompleted,
    loading: anyConsultationLockedOrCompletedLoading
  } = useAnyConsultationLockedOrCompleted();

  const {
    displayProviderMemberAvailability,
    displaySameDayVisit,
    providerId,
    role,
    maxOverallPatientsLimit,
    maxDailyNewPatientsLimit,
    isExistingProvider,
    defaultOverallPatientsLimit
  } = useData();

  const { error: acceptingPatientsError, data: acceptingPatientsData } = useQuery(FETCH_ACCEPTING_NEW_PATIENTS_VALUE, {
    variables: { providerId }
  });

  const acceptingNewPatientsData = acceptingPatientsData && acceptingPatientsData.fetchPatientToggleValue;
  let offByAdmin, hasActiveLicense;

  if (acceptingNewPatientsData) {
    const {
      acceptingNewPatientsFlg,
      acceptingNewPatientsReasonCd,
      personType,
      licenseStatus
    } = acceptingNewPatientsData;

    offByAdmin =
      (!autoDeclineEnabled && personType === 'Admin' && acceptingNewPatientsFlg === 'N') ||
      acceptingNewPatientsReasonCd === ACCEPTING_NEW_PATIENT_REASON.off_by_admin;
    hasActiveLicense = licenseStatus === 'Active';
  }

  const showLegacyConsultQueues = () => {
    const consultsContent = document.getElementById('requested_consult_queue');
    consultTab.current.appendChild(consultsContent);
    consultsContent.style.display = 'initial';
  };

  useEffect(() => {
    if (!displayProviderMemberAvailability) showLegacyConsultQueues();

    teladocApi
      .get('v4/ref_alerts/same_day_scheduling_alert', authToken.get())
      .then(response => {
        const data = response.data.ref_alert;
        if (data) {
          setIsOpen(data.display_alert);
        }
      })
      .catch(() => {
        setIsOpen(false);
      });
  }, []);

  return (
    <React.Fragment>
      <div id="summaryCardsContainer" />
      <div className="dashboard-content">
        <div id="schedule_followup_container" className="schedule-follow-up" />
        <div className="tabs-buttons">
          <div className="tabs-container">
            <button
              className={classNames({ active: tabIndex === 0 })}
              onClick={() => {
                saveRecord(currentTabKey, 0);
                setTabIndex(0);
              }}
            >
              <span className="button-text">{I18n.t('dashboard.tabs.visits')}</span>
              {tabIndex === 0 && <span className="button-active-bar" />}
            </button>
            <button
              className={classNames({ active: tabIndex === 1 })}
              onClick={() => {
                saveRecord(currentTabKey, 1);
                setTabIndex(1);
              }}
            >
              <span className="button-text">{I18n.t('dashboard.tabs.patients')}</span>
              {tabIndex === 1 && <span className="button-active-bar" />}
            </button>
          </div>
          <PatientVolumeManagerContainer
            providerId={providerId}
            role={role}
            maxOverallPatientsLimit={maxOverallPatientsLimit}
            maxDailyNewPatientsLimit={maxDailyNewPatientsLimit}
            defaultOverallPatientsLimit={defaultOverallPatientsLimit}
            isV2Active={true}
            isAnyConsultCompleted={anyConsultLockedOrCompleted}
            isExistingProvider={isExistingProvider}
            acceptingPatientsData={acceptingPatientsData}
            acceptingPatientsError={acceptingPatientsError}
          />
        </div>
        <div className="tabs-panels">
          <section id="tab-consults" ref={consultTab} className={classNames('tabs-panel', { active: tabIndex === 0 })}>
            {displayProviderMemberAvailability && (
              <ConsultQueues hasActiveLicense={hasActiveLicense} offByAdmin={offByAdmin} />
            )}
          </section>
          <section id="tab-patients" ref={patientsTab} className={classNames('tabs-panel', { active: tabIndex === 1 })}>
            {anyConsultationLockedOrCompletedLoading ? (
              <div className="any-consultation-completed-loading">
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                {anyConsultLockedOrCompleted ? (
                  <PatientList />
                ) : (
                  <div className="patients-tab-container">
                    <div className="managePatientListBtnContainer">
                      <PatientListManageLimits />
                    </div>
                    <p>{I18n.t('patient_case_load_mgmt_mvp.patient_list.no_completed_consultation')}</p>
                  </div>
                )}
              </React.Fragment>
            )}
          </section>
        </div>
      </div>
      <NotifyProvidersAboutNewFeature isOpen={isOpen} setIsOpen={setIsOpen} navigate={naviagteToMyAccount}/>
    </React.Fragment>
  );
};

export default DashboardContent;