import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ReactAccordionSection from './react-accordion-section';
import './assets/stylesheets/accordion.scss';

export default class ReactAccordion extends Component {
  constructor(props) {
    super(props);
    this.toggleSection = this.toggleSection.bind(this);
    this.toggleReviewSection = this.toggleReviewSection.bind(this);
    this.getRequiredSections = this.getRequiredSections.bind(this);
    this.setReviewedStatus = this.setReviewedStatus.bind(this);
    this.updateExpandedList = this.updateExpandedList.bind(this);
    this.toggleReviewedSection = this.toggleReviewedSection.bind(this);
    this.isExpanded = this.isExpanded.bind(this);
    this.getReviewedSection = this.getReviewedSection.bind(this);
    this.isAllSectionsReviewed = this.isAllSectionsReviewed.bind(this);
    this.handleReviewedSections = this.handleReviewedSections.bind(this);
    this.updateReviewedArray = this.updateReviewedArray.bind(this);
    this.state = {
      expanded:          [],
      requiredForReview: this.getRequiredSections(),
      reviewed:          this.getReviewedSection()
    };
  }

  getReviewedSection() {
    const reviewedSections = [];
    this.props.sections.forEach((item, index) => {
      if (item.reviewed) {
        reviewedSections.push(index);
      }
    });
    return reviewedSections;
  }

  getRequiredSections() {
    const requiredSections = [];
    this.props.sections.forEach((item, index) => {
      if (item.title && !!Object.getOwnPropertyDescriptor(item, 'reviewed')) {
        requiredSections.push(index);
      }
    });
    return requiredSections;
  }

  setReviewedStatus(index) {
    this.props.sections[index].reviewed = !this.props.sections[index].reviewed;
  }

  handleReviewedSections() {
    if (this.isAllSectionsReviewed()) {
      this.props.reviewedHandler(true);
    } else {
      this.props.reviewedHandler(false);
    }
  }

  updateReviewedArray(index) {
    const updatedReviewedList = [...this.state.reviewed];
    if (this.props.sections[index].reviewed) {
      updatedReviewedList.push(index);
    } else {
      const unreviewedElem = this.state.reviewed.find(item => item === index);
      updatedReviewedList.splice(this.state.reviewed.indexOf(unreviewedElem), 1);
    }
    this.setState({ reviewed: updatedReviewedList }, () => this.handleReviewedSections());
  }

  isAllSectionsReviewed() {
    return this.state.requiredForReview.length === this.state.reviewed.length;
  }

  toggleReviewSection(index) {
    const nextSection =
      this.state.requiredForReview[this.state.requiredForReview.indexOf(index)] !== -1 ?
      this.state.requiredForReview[this.state.requiredForReview.indexOf(index + 1)] :
      this.state.requiredForReview[0];
    this.setReviewedStatus(index);
    if (this.props.sections[index].reviewed) {
      this.toggleReviewedSection(index, nextSection);
    } else {
      this.setState({ expanded: [...this.state.expanded] }, () => this.updateReviewedArray(index));
    }
  }

  updateExpandedList(index) {
    const expandedElem = this.state.expanded.find(item => item === index);
    if (!_.isUndefined(expandedElem)) {
      const updatedExpandedList = [...this.state.expanded];
      updatedExpandedList.splice(this.state.expanded.indexOf(expandedElem), 1);
      return updatedExpandedList;
    }
    return [...this.state.expanded, index];
  }

  toggleSection(index) {
    const updatedExpandedList = this.updateExpandedList(index);
    this.setState({ expanded: updatedExpandedList });
  }

  toggleReviewedSection(index, nextSection) {
    const updatedExpandedList = [...this.state.expanded];
    updatedExpandedList.splice(this.state.expanded.indexOf(index), 1);
    const nextSectionElem = !_.isUndefined(nextSection) &&
      this.state.expanded.find(item => item === nextSection);
    if (_.isUndefined(nextSectionElem)) {
      updatedExpandedList.push(nextSection);
    }
    this.setState({ expanded: updatedExpandedList }, () => this.updateReviewedArray(index));
  }

  isExpanded(index) {
    const listItem = this.state.expanded.find(item => item === index);
    return !_.isUndefined(listItem);
  }

  render() {
    this.handleReviewedSections();
    const sections = this.props.sections
      .filter(section => section.title)
      .map(
      (section, index) => {
        const isReviewRequired = !!Object.getOwnPropertyDescriptor(section, 'reviewed');
        const reviewHandler = isReviewRequired ? () => this.toggleReviewSection(index) : null;
        const isReviewed = section.reviewed;
        return (
          <ReactAccordionSection
            expanded={this.isExpanded(index)}
            index={index}
            key={section.title}
            title={section.title}
            toggleSection={() => this.toggleSection(index)}
            reviewRequired={isReviewRequired}
            isReviewed={isReviewed}
          >
            {this.props.sectionBuilder.create(section, reviewHandler, this.props.additionalData)}
          </ReactAccordionSection>
        );
      }
    );

    return (
      <div className="react-accordion-wrapper" id="reactAccordionWrapper">
        {sections}
      </div>
    );
  }
}

ReactAccordion.propTypes = {
  sections:        PropTypes.array.isRequired,
  sectionBuilder:  PropTypes.func.isRequired,
  additionalData:  PropTypes.any,
  reviewedHandler: PropTypes.func.isRequired
};
