import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = props => {
  const { className, fill = '#000', width, height, stroke, strokeWidth = 5, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      id="Symbols"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      height={`${width}px`}
      width={`${height}px`}
      className={className}
      {...rest}
    >
      <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z" />
    </svg>
  );
};

CloseIcon.propTypes = {
  className:   PropTypes.string,
  fill:        PropTypes.string,
  width:       PropTypes.number.isRequired,
  height:      PropTypes.number.isRequired,
  stroke:      PropTypes.string,
  strokeWidth: PropTypes.number
};

CloseIcon.defaultProps = {
  stroke: '#FFF',
  fill:   'none'
};

export default CloseIcon;
