import moment from 'moment-timezone';

const timeRangeToTimeBlock = (timeRanges, { isRecommendedWorkingHour = false, appointmentType }) => {
  const formatDate = date => moment(date).format('YYYY-MM-DD');

  return timeRanges.map(({ start, end }) => ({
    appointmentType,
    isRecommendedWorkingHour,
    endTime:   end,
    startTime: start,
    endDate:   formatDate(end),
    startDate: formatDate(start)
  }));
};

export default timeRangeToTimeBlock;
