import React from 'react';
import { I18n } from '@td/shared_utils';

import './styles/styles.scss';

export const OutdatedBrowserVersionNotice = () => (
  <div className="outdated-browser-version-notice">
    <h2>
      <I18n scope="browser_version_warning" text="title" />
    </h2>
    <p>
      <I18n scope="browser_version_warning" text="description" />
    </p>
  </div>
);
