import React from 'react';

import ViewEHRLink from '../consult-queues/components/ViewEHRLink';
import { get } from 'lodash';

const PatientListMemberName = ({
  fullName,
  lastConsultationForCurrentProvider,
  nextConsultationForCurrentProvider
}) => {
  const consultationId = get(nextConsultationForCurrentProvider, 'id') || get(lastConsultationForCurrentProvider, 'id');

  if (!consultationId) return <p>{fullName}</p>;

  return <ViewEHRLink text={fullName} consultationId={Number(consultationId)} />;
};

export default PatientListMemberName;
