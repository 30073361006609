exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._2Fnc6anf80u0G5gNboDQH1.teladocModalWrapper .defaultCloseIcon {\n  opacity: 0;\n  pointer-events: none; }\n", ""]);

// exports
exports.locals = {
	"removeAccountConfirmationModal": "_2Fnc6anf80u0G5gNboDQH1",
	"removeAccountConfirmationModal": "_2Fnc6anf80u0G5gNboDQH1"
};