import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@td/shared_utils';

// Styles
import '../styles/custom-buffer.scss';

// Constants
const TRANSLATION_SCOPE = 'my_schedule.custom_buffer';
const BUFFER_OPTIONS = [0, 5, 10, 15];

const PostConsultBufferSection = ({ providerCustomBuffer, setProviderCustomBuffer }) => (
  <div className="availabilityBlock postConsultBufferSection">
    <div className="availabilityList">
      <div className="header">
        <div className="availabilityText">
          <h4 className="sectionTitle">{translate(null, TRANSLATION_SCOPE, 'section.title')}</h4>
          <p className="sectionSubtitle timeSet">{translate(null, TRANSLATION_SCOPE, 'section.subtitle')}</p>
        </div>
      </div>
      <div className="day">
        <div className="frame">
          <label htmlFor="bufferDropdownSelect" className="bufferLabel">
            {translate(null, TRANSLATION_SCOPE, 'buffer_label')}
          </label>
          <div className="bufferDropdown">
            <select
              className="bufferDropdownSelect"
              name="bufferDropdownSelect"
              id="bufferDropdownSelect"
              onChange={({ target: { value } }) => setProviderCustomBuffer(parseInt(value, 10))}
              value={providerCustomBuffer}
            >
              {BUFFER_OPTIONS.map(bufferOption => (
                <option
                  key={`bufferOption-${bufferOption}`}
                  value={bufferOption}
                >
                  {bufferOption === 0
                    ? translate(null, TRANSLATION_SCOPE, 'buffer_none')
                    : `${bufferOption} ${translate(null, TRANSLATION_SCOPE, 'minutes')}`}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
);

PostConsultBufferSection.propTypes = {
  providerCustomBuffer:    PropTypes.number.isRequired,
  setProviderCustomBuffer: PropTypes.func.isRequired
};

export default PostConsultBufferSection;
