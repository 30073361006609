import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { filter, has, get } from 'lodash';
import { translate } from '@td/shared_utils';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/personal-event-modal.scss';

// Components, constants & helpers
import TeladocModal from '../../TeladocModal';
import Loader from '../../components/Loader';
import Notification from './notification';
import { scheduleTypes } from '../constants';
import { checkEventsOverlap, isValidDate, separateDatesAndTimes } from '../helpers';

// Icons
const trashIcon = require('../../assets/images/event_details/trash_icon_red.svg');

// Constants
const TRANSLATION_SCOPE = 'my_schedule.modals.personal_event';

const PersonalEventModal = ({
  personalEvents,
  isSubmitting,
  onModalClose,
  onSubmit,
  onDelete,
  event
}) => {
  /*
   * State
   */
  const isCalendarEvent = !has(event, '__typename');
  const eventProperties = {
    title:   isCalendarEvent ? 'title' : 'eventName',
    start:   isCalendarEvent ? 'start' : 'startDateTime',
    end:     isCalendarEvent ? 'end' : 'endDateTime',
    fullDay: 'allDayEventFlg'
  };
  const initDate = {
    startDateTime: moment(
      get(
        event,
        eventProperties.start,
        moment()
          .startOf('day')
          .set('hour', 8)
      )
    ).toDate(),
    endDateTime: moment(
      get(
        event,
        eventProperties.end,
        moment()
          .startOf('day')
          .set('hour', 9)
      )
    ).toDate()
  };

  const [notification, setNotification] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(initDate);
  const [eventName, setEventName] = useState(get(event, eventProperties.title, ''));
  const [isFullDay, setFullDay] = useState(get(event, eventProperties.fullDay, false));

  /*
   * Constants
   */
  const isDisabled = !selectedDateTime.startDateTime || !selectedDateTime.endDateTime || !eventName;

  /*
   * Helpers
   */
  const verifyAndSave = () => {
    const separatedDateTime = separateDatesAndTimes(selectedDateTime);
    const isValidSelectedDate = isValidDate(separatedDateTime);

    if (!isValidSelectedDate) {
      return setNotification({
        type:    'error',
        message: translate(null, 'my_schedule.notifications', 'end_time_after_start_time')
      });
    }

    const allPersonalEvents = filter(
      personalEvents,
      personalEvent => personalEvent.providerScheduleId !== get(event, 'providerScheduleId')
    );
    const eventsOverlap = checkEventsOverlap(allPersonalEvents, separatedDateTime);

    if (eventsOverlap) {
      return setNotification({
        type:    'error',
        message: translate(null, 'my_schedule.notifications', 'personal_events_overlap')
      });
    }

    const { startDateTime, endDateTime } = selectedDateTime;

    const newEvent = {
      eventName,
      eventTypeCode:  scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode,
      startDateTime:  moment(startDateTime),
      endDateTime:    moment(endDateTime),
      allDayEventFlg: isFullDay
    };

    onSubmit(newEvent);
  };

  /*
   * Callbacks
   */
  const getFullDayEvent = prevEvent => ({
    startDateTime: moment(prevEvent.startDateTime)
      .startOf('day')
      .toDate(),
    endDateTime: moment(prevEvent.endDateTime)
      .endOf('day')
      .toDate()
  });

  const handleDatePickerChange = ({ date, type }) => {
    const keyToUpdate = type.substring(0, 3) === 'end' ? 'endDateTime' : 'startDateTime';
    const isDate = type.slice(-4) === 'Date';
    const dateMoment = moment(date);

    setSelectedDateTime(prevSelectedDateTime => {
      const updateDateTime = (isDateKey, valueToUpdate) =>
        isDateKey
          ? moment(valueToUpdate)
              .set({ year: dateMoment.year(), month: dateMoment.month(), date: dateMoment.date() })
              .seconds(0)
              .milliseconds(0)
              .toDate()
          : moment(valueToUpdate)
              .set({ hours: dateMoment.hours(), minutes: dateMoment.minutes() })
              .seconds(0)
              .milliseconds(0)
              .toDate();

      const newValue = updateDateTime(isDate, prevSelectedDateTime[keyToUpdate]);
      const newSelectedDateTime = { ...prevSelectedDateTime, [keyToUpdate]: newValue };

      if (keyToUpdate === 'startDateTime' && isDate) {
        newSelectedDateTime.endDateTime = updateDateTime(isDate, newSelectedDateTime.endDateTime);
      }

      return isFullDay ? getFullDayEvent(newSelectedDateTime) : newSelectedDateTime;
    });
  };

  const handleTitleChange = e => {
    setEventName(e.target.value);
  };

  const onToggleFullDay = () => {
    setFullDay(prevIsFullDay => !prevIsFullDay);
  };

  /*
   * Effects
   */
  useEffect(() => {
    if (isFullDay) {
      setSelectedDateTime(prevSelectedDateTime => getFullDayEvent(prevSelectedDateTime));
    }
  }, [isFullDay]);

  /*
   * Sub-components
   */
  const renderTimePicker = ({ selectedTime, label, type, fullDay }) => (
    <DatePicker
      selected={selectedTime}
      onChange={date =>
        handleDatePickerChange({
          date,
          label,
          type
        })
      }
      showTimeSelect
      showTimeSelectOnly
      className={`${type}Picker ${fullDay && 'fullDay'}`}
      timeIntervals={15}
      id={type}
      name={type}
      valueName={type}
      dateFormat="h:mm aa"
      timeCaption={label}
    />
  );

  renderTimePicker.propTypes = {
    selectedTime: PropTypes.object.isRequired,
    label:        PropTypes.string.isRequired,
    type:         PropTypes.string.isRequired,
    fullDay:      PropTypes.bool
  };

  const renderDatePicker = ({ selectedTime, label, type }) => (
    <DatePicker
      selected={selectedTime}
      onChange={date =>
        handleDatePickerChange({
          date,
          label,
          type
        })
      }
      showTimeSelect={false}
      className={`${type}Picker`}
      timeIntervals={15}
      id={type}
      name={type}
      valueName={type}
      dateFormat="MM/dd/yyyy"
      timeCaption={label}
    />
  );

  renderDatePicker.propTypes = {
    selectedTime: PropTypes.object.isRequired,
    label:        PropTypes.string.isRequired,
    type:         PropTypes.string.isRequired
  };

  const modalActionButtons = () => (
    <div className="actionButtons">
      <div className="regularActions">
        <button id="cancelButton" key="cancelButton" className="button" onClick={onModalClose} disabled={isSubmitting}>
          {translate(null, TRANSLATION_SCOPE, 'actions.cancel')}
        </button>
        <button
          id="confirmButton"
          key="confirmButton"
          className="button"
          onClick={verifyAndSave}
          disabled={isSubmitting || isDisabled}
        >
          {isSubmitting
            ? translate(null, TRANSLATION_SCOPE, 'actions.submitting')
            : translate(null, TRANSLATION_SCOPE, 'actions.save')}
        </button>
      </div>
      {event && (
        <div className="deleteAction" onClick={onDelete}>
          <img key="deleteIcon" src={trashIcon} alt={translate(null, TRANSLATION_SCOPE, 'actions.delete')} />
          <span className="label">{translate(null, TRANSLATION_SCOPE, 'actions.delete')}</span>
        </div>
      )}
    </div>
  );

  /*
   * Render
   */
  return (
    <TeladocModal
      className="personalEventModalWrapper"
      title={
        event ? translate(null, TRANSLATION_SCOPE, 'actions.edit') : translate(null, TRANSLATION_SCOPE, 'actions.add')
      }
      isOpen
      onClose={onModalClose}
    >
      <div className="contentWrapper">
        <div className="subtitle">
          <p>{translate(null, TRANSLATION_SCOPE, 'subtitle')}</p>
        </div>

        {isSubmitting && (
          <div className="loadingSpinner">
            <Loader />
          </div>
        )}

        {notification && <Notification type={notification.type} message={notification.message} />}

        <div className="timeSetWrapper">
          <p className="requiredFieldsLabel">
            <span className="dot" />
            {translate(null, TRANSLATION_SCOPE, 'required_fields')}
          </p>
          <div className="timeBlocks">
            <div className="timeBlock">
              <div className="sectionWrapper allDaySwitch">
                <div className="switchWrapper">
                  <label className="switch" htmlFor="fullDayCheckbox">
                    <input type="checkbox" id="fullDayCheckbox" onClick={onToggleFullDay} defaultChecked={isFullDay} />
                    <span id="allDaySlider" className="round" />
                  </label>
                </div>
                <div className="label">{translate(null, TRANSLATION_SCOPE, 'all_day_event')}</div>
              </div>

              <div className="sectionWrapper">
                <div className="startEndLabel">{translate(null, TRANSLATION_SCOPE, 'event_start')}</div>
                <div className="sectionContent">
                  <div className="formInput">
                    {renderDatePicker({
                      selectedTime: selectedDateTime.startDateTime,
                      type:         'startDate',
                      label:        translate(null, TRANSLATION_SCOPE, 'start_date')
                    })}
                    <div className={`separator ${isFullDay && 'fullDay'}`} />
                    {renderTimePicker({
                      selectedTime: selectedDateTime.startDateTime,
                      type:         'startTime',
                      label:        translate(null, TRANSLATION_SCOPE, 'start_time'),
                      fullDay:      isFullDay
                    })}
                  </div>
                </div>
              </div>

              <div className="sectionWrapper">
                <div className="startEndLabel">{translate(null, TRANSLATION_SCOPE, 'event_end')}</div>
                <div className="sectionContent">
                  <div className="formInput">
                    {renderDatePicker({
                      selectedTime: selectedDateTime.endDateTime,
                      type:         'endDate',
                      label:        translate(null, TRANSLATION_SCOPE, 'end_date')
                    })}
                    <div className={`separator ${isFullDay && 'fullDay'}`} />
                    {renderTimePicker({
                      selectedTime: selectedDateTime.endDateTime,
                      type:         'endTime',
                      label:        translate(null, TRANSLATION_SCOPE, 'end_time'),
                      fullDay:      isFullDay
                    })}
                  </div>
                </div>
              </div>

              <div className="sectionWrapper">
                <div className="titleLabel">{translate(null, TRANSLATION_SCOPE, 'event_title_label')}</div>
                <div className="sectionContent">
                  <div className="formInput">
                    <input
                      type="text"
                      className="eventTitle"
                      name="eventTitle"
                      onChange={handleTitleChange}
                      value={eventName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {modalActionButtons()}
      </div>
    </TeladocModal>
  );
};

PersonalEventModal.propTypes = {
  personalEvents: PropTypes.arrayOf(
    PropTypes.shape({
      startDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string]),
      endDateTime:   PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string])
    })
  ).isRequired,
  isSubmitting: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onSubmit:     PropTypes.func.isRequired,
  onDelete:     PropTypes.func.isRequired,
  event:        PropTypes.shape({
    providerScheduleId: PropTypes.string.isRequired,
    title:              PropTypes.string,
    start:              PropTypes.instanceOf(Date),
    end:                PropTypes.instanceOf(Date),
    eventName:          PropTypes.string,
    startDateTime:      PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string]),
    endDateTime:        PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string])
  })
};

export default PersonalEventModal;
