import { connect } from 'react-redux';

import { toggleAccordion, accordionToggleEnd } from '../../actions';
import
  getAddedSymptomsFromSearch
from '../../reducers/selectors/symptoms/getAddedSymptomsFromSearch';
import AddedSymptomsAccordion from './added-symptoms-accordion';

const mapStateToProps = state => ({
  symptoms: getAddedSymptomsFromSearch(state),
  expanded: state.symptomAccordions.expandedAccordions.has('Added symptoms')
});

const mapDispatchToProps = {
  toggleAccordion,
  accordionToggleEnd
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddedSymptomsAccordion);
