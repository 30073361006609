import React, { Fragment } from 'react';
import { I18n, translate } from '@td/shared_utils';
import { PropTypes } from 'prop-types';
import { LABS_QUESTION_SETTINGS } from '../constants';

function LabQuestionOptions(props) {
  return (
    <Fragment>
      <ul className="yes_no_radios" id="lab_prescription">
        {props.options.map(option => (
          <li key={option.value}>
            <input
              name="interview[labs_prescribe]"
              defaultChecked={props.isOptionSelected(option.value)}
              type="radio"
              value={option.value}
              id={`lab_prescription_${option.value}`}
              onClick={props.labOptionChanged}
            />
            <label htmlFor={`lab_prescription_${option.value}`}>{option.text}</label>
          </li>
        ))}
      </ul>
    </Fragment>
  );
}

LabQuestionOptions.propTypes = {
  isOptionSelected: PropTypes.func.isRequired,
  labOptionChanged: PropTypes.func.isRequired,
  options:          PropTypes.array
};

function LabQuestion(props) {
  return (
    <Fragment>
      <h4>Labs</h4>
      <p>Are you ordering labs?</p>
      <LabQuestionOptions options={LABS_QUESTION_SETTINGS.OPTIONS} {...props} />
    </Fragment>
  );
}

LabQuestion.propTypes = {
  isOptionSelected: PropTypes.func.isRequired,
  labOptionChanged: PropTypes.func.isRequired
};

export default LabQuestion;
