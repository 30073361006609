import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DraggableVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
      originalX: 0,
      originalY: 0,
      translateX: -10,
      translateY: -10,
      lastTranslateX: 0,
      lastTranslateY: 0
    };
  }

  componentWillUnmount() {
    const {pipWindow} = this.props;
    if (pipWindow) {
      pipWindow.removeEventListener('mousemove', this.handleMouseMove);
      pipWindow.removeEventListener('mouseup', this.handleMouseUp);
      pipWindow.removeEventListener('touchend', this.handleTouchEnd);
      pipWindow.removeEventListener('touchmove', this.handleTouchMove);
    } else {
      window.removeEventListener('mousemove', this.handleMouseMove);
      window.removeEventListener('mouseup', this.handleMouseUp);
      window.removeEventListener('touchend', this.handleTouchEnd);
      window.removeEventListener('touchmove', this.handleTouchMove);
    }
  }

  setTranslateProps = ({ clientX, clientY, prevState }) => ({
    translateX: clientX - prevState.originalX + prevState.lastTranslateX,
    translateY: clientY - prevState.originalY + prevState.lastTranslateY
  });

  handleMouseDown = ({ clientX, clientY }) => {
    const {pipWindow} = this.props;
    if (pipWindow) {
      pipWindow.addEventListener('mousemove', this.handleMouseMove);
      pipWindow.addEventListener('mouseup', this.handleMouseUp);
    } else {
      window.addEventListener('mousemove', this.handleMouseMove);
      window.addEventListener('mouseup', this.handleMouseUp);
    }

    this.setState({
      originalX:  clientX,
      originalY:  clientY,
      isDragging: true
    });
  };

  handleTouchStart = event => {
    const {pipWindow} = this.props;
    if (pipWindow) {
      pipWindow.addEventListener('touchend', this.handleMouseUp);
      pipWindow.addEventListener('touchmove', this.handleTouchMove);
    } else {
      window.addEventListener('touchend', this.handleMouseUp);
      window.addEventListener('touchmove', this.handleTouchMove);
    }

    this.setState({
      originalX:  event.touches[0].clientX,
      originalY:  event.touches[0].clientY,
      isDragging: true
    });
  };

  handleMouseMove = ({ clientX, clientY }) => {
    const { isDragging } = this.state;

    if (!isDragging) {
      return;
    }

    this.setState(prevState => ({
      ...this.setTranslateProps({ clientX, clientY, prevState })
    }));
  };

  handleMouseUp = () => {
    const { pipWindow } = this.props;
    if (pipWindow) {
      pipWindow.removeEventListener('mousemove', this.handleMouseMove);
      pipWindow.removeEventListener('mouseup', this.handleMouseUp);
    } else {
      window.removeEventListener('mousemove', this.handleMouseMove);
      window.removeEventListener('mouseup', this.handleMouseUp);
    }

    this.setState({
      originalX:      0,
      originalY:      0,
      lastTranslateX: this.state.translateX,
      lastTranslateY: this.state.translateY,
      isDragging: false
    });
  };

  handleTouchMove = ({ touches }) => {
    const { clientX, clientY } = touches[0];

    this.setState(prevState => ({
      ...this.setTranslateProps({ clientX, clientY, prevState })
    }));
  };

  handleTouchEnd = () => {
    window.removeEventListener('touchmove', this.handleTouchMove);
    window.removeEventListener('touchend', this.handleTouchEnd);

    this.setState({
      originalX:      0,
      originalY:      0,
      lastTranslateX: this.state.translateX,
      lastTranslateY: this.state.translateY,
      isDragging:     false
    });
  };

  render() {
    const { children, ...restOfProps } = this.props;
    const { isDragging, translateX, translateY } = this.state;

    return (
      <div
        onMouseDown={event => this.handleMouseDown(event)}
        onTouchStart={event => this.handleTouchStart(event)}
        style={{
          transform: `translate(${translateX}px, ${translateY}px)`,
          bottom:    0,
          right:     0,
          position:  'absolute',
          zIndex:    999,
          cursor:    `${isDragging ? 'grabbing' : 'grab'}`
        }}
      >
        {React.Children.map(children, child => React.cloneElement(child, { ...restOfProps }))}
      </div>
    );
  }
}

DraggableVideo.propTypes = {
  children: PropTypes.node,
  pipWindow: PropTypes.object
};
export { DraggableVideo };
