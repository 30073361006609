import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ActionsContainer = ({ children }) => {
  const filterdActions = children.filter(action => action.type(action.props) !== null);

  const isFirstItem = index => index === 0;

  return (
    <div className="actions_wrapper">
      {filterdActions.map((action, index) => (
        <Fragment key={index}>
          {!isFirstItem(index) && <p>|</p>}
          {action}
        </Fragment>
      ))}
    </div>
  );
};

ActionsContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default ActionsContainer;
