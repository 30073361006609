import React, { useState, useEffect } from 'react';

const withLocalStoragePersistance = WrappedComponent => {
  return ({ children, storageKey, ...rest }) => {
    const storedIsOpen = localStorage.getItem(storageKey);
    const [isOpen, setIsOpen] = useState(storedIsOpen !== 'false' ? true : false);

    useEffect(() => {
      localStorage.setItem(storageKey, isOpen);
    }, [isOpen, storageKey]);

    const toggleAccordion = () => setIsOpen(!isOpen);

    return (
      <WrappedComponent isOpen={isOpen} onToggle={toggleAccordion} {...rest}>
        {children}
      </WrappedComponent>
    );
  };
};

export default withLocalStoragePersistance;
