import { style } from 'typestyle';

export default {
  container: style({
    marginTop:  '5px',
    marginLeft: '10px'
  }),
  questionContainer: style({
    $nest: {
      span: {
        fontWeight: 'bold'
      }
    },
    marginTop: '0.5em'
  })
};
