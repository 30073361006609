const multiWayConsultAttendeesVerification = () => {
  // This checks if the current consultation is couples therapy and the :couples_therapy_enhancements feature toggle is on
  const isCouplesTherapy = $('.multi-way-consult-attendees-verification-checkbox-group').length !== 0;

  const selections = $(
    '.multi-way-consult-attendees-verification-checkbox-group input[name="interview[consult_attendee_presence[][id]]"]:checked'
  );

  return [
    {
      validator: {
        message: window.I18n.t('ehr.mh.verification_tab.attendee.error'),
        invalid: isCouplesTherapy && selections.length === 0
      },
      // eslint-disable-next-line max-len
      errorContainerSelector:
        '#verify_multi_way_consult_attendees_verification_validation_error.interview_inline_validation_error'
    }
  ];
};

export default multiWayConsultAttendeesVerification;
