// Attendee statuses
export const DIALING = 'DIALING';
export const READY_TO_DIAL = 'READY_TO_DIAL';
export const RETRYING_PRIMARY = 'RETRYING_PRIMARY';
export const DIALING_SECONDARY = 'DIALING_SECONDARY';
export const NOT_REACHED = 'NOT_REACHED';
export const ANSWERED = 'ANSWERED';
export const JOINED = 'JOINED';
export const DISCONNECTED = 'DISCONNECTED';
export const BUSY = 'BUSY';

// Conference statuses
export const READY_TO_START = 'READY_TO_START';
export const STARTING = 'STARTING';
export const IN_PROGRESS = 'IN_PROGRESS';
export const CONFERENCE_COMPLETE = 'CONFERENCE_COMPLETE';

// Error statuses
export const AUTH_ERROR = 'AUTH_ERROR';
export const BAD_REQUEST = 'BAD_REQUEST';
export const CALL_FAILURE = 'CALL_FAILURE';
export const WEBRTC_DEVICE_ERROR = 'WEBRTC_DEVICE_ERROR';

// View States
export const PROVIDER_REJOIN = 'PROVIDER_REJOIN';
export const START_CALL = 'START_CALL';
export const JOIN_VIA_PHONE = 'JOIN_VIA_PHONE';
export const CALL_IN_PROGRESS = 'CALL_IN_PROGRESS';
export const UNAVAILABLE = 'UNAVAILABLE';
export const MEMBER_BUSY = 'BUSY';
export const CALL_COMPLETE = 'CALL_COMPLETE';
export const DIALING_PROVIDER = 'DIALING_PROVIDER';
export const DIALING_MEMBER = 'DIALING_MEMBER';
export const UNKNOWN = 'UNKNOWN';
export const VENDOR_CONN_ISSUE = 'VENDOR_CONN_ISSUE';

export const ATTENDEE_STATUSES = {
  ANSWERED,
  BAD_REQUEST,
  DIALING,
  DIALING_SECONDARY,
  DISCONNECTED,
  JOINED,
  NOT_REACHED,
  READY_TO_DIAL,
  RETRYING_PRIMARY,
  BUSY
};

export const CONFERENCE_STATUSES = {
  READY_TO_START,
  STARTING,
  IN_PROGRESS,
  CONFERENCE_COMPLETE,
  AUTH_ERROR,
  BAD_REQUEST,
  WEBRTC_DEVICE_ERROR,
  CALL_FAILURE
};

export const NON_CANCELLABLE_STATUSES = [STARTING, IN_PROGRESS];
export const DISCONNECTED_STATUSES = [AUTH_ERROR, CALL_FAILURE, CONFERENCE_COMPLETE, READY_TO_START, BUSY];
export const DIALING_STATUSES = [DIALING, RETRYING_PRIMARY, DIALING_SECONDARY, ANSWERED];
export const ACTIVE_CONFERENCE_STATUSES = [STARTING, IN_PROGRESS, CONFERENCE_COMPLETE, BAD_REQUEST];
export const CALL_IN_PROGRESS_STATUSES = [CONFERENCE_STATUSES.STARTING, CONFERENCE_STATUSES.IN_PROGRESS];
export const ON_CALL_STATUSES = [ANSWERED, DIALING, DIALING_SECONDARY, JOINED, RETRYING_PRIMARY];

export const VIEW_STATES = {
  PROVIDER_REJOIN,
  CALL_COMPLETE,
  CALL_IN_PROGRESS,
  DIALING_MEMBER,
  DIALING_PROVIDER,
  JOIN_VIA_PHONE,
  START_CALL,
  UNAVAILABLE,
  UNKNOWN,
  VENDOR_CONN_ISSUE,
  CALL_IN_PROGRESS_STATUSES,
  MEMBER_BUSY
};
