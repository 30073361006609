import React from 'react';
import render from '../lib/render';

import store from 'app/lib/store';
import getClientInstance from 'app/services/apollo-client';
import { ApolloProvider as ApolloProviderNew } from '@apollo/client';
import { Provider } from 'react-redux';

import { QuickDxComponent } from './components/QuickDx';
import { MOUNT_CONTAINER_ID } from './constants';
import { QuickDxFavoritesProvider } from './utils';

export const initializeQuickDx = () => {
  const QuickDx = () => (
    <Provider store={store}>
      <ApolloProviderNew client={getClientInstance()}>
        <QuickDxFavoritesProvider>
          <QuickDxComponent />
        </QuickDxFavoritesProvider>
      </ApolloProviderNew>
    </Provider>
  );

  render(QuickDx, MOUNT_CONTAINER_ID);
};
