import { createRequestActionTypes } from '@td/utils';

export const CLOCK_IN_PROVIDER_INIT = 'sidebar/CLOCK_IN_PROVIDER_INIT';
export const CLOCK_IN_PROVIDER = createRequestActionTypes('sidebar', 'CLOCK_IN_PROVIDER');
export const CLOCK_OUT_PROVIDER = createRequestActionTypes('sidebar', 'CLOCK_OUT_PROVIDER');
export const SET_CLOCK_IN_OUT_SETTINGS = 'sidebar/SET_CLOCK_IN_OUT_SETTINGS';
export const SET_CLOCK_IN_OUT_LOCATION_SETTINGS = 'sidebar/SET_CLOCK_IN_OUT_LOCATION_SETTINGS';
export const INIT_CLOCK_IN_LOCATION = 'sidebar/INIT_CLOCK_IN_LOCATION';
export const ENABLE_REQUEST_MODE = 'sidebar/ENABLE_REQUEST_MODE';
export const DISABLE_REQUEST_MODE = 'sidebar/DISABLE_REQUEST_MODE';
