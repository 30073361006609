exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3E3X1avnHnEb-dsUUVjXgG {\n  display: flex;\n  justify-content: space-between;\n  padding: 0 10px; }\n  ._3E3X1avnHnEb-dsUUVjXgG > div, ._3E3X1avnHnEb-dsUUVjXgG > label {\n    flex: 1;\n    display: flex !important;\n    align-items: center;\n    height: 40px;\n    font-weight: 400 !important; }\n  ._3E3X1avnHnEb-dsUUVjXgG ._2a1EHhL8r6IERQfGa4VAF5 {\n    flex: 2;\n    color: #000000;\n    padding-right: 24px; }\n", ""]);

// exports
exports.locals = {
	"surveyQuestion": "_3E3X1avnHnEb-dsUUVjXgG",
	"surveyQuestion": "_3E3X1avnHnEb-dsUUVjXgG",
	"surveyQuestionTitle": "_2a1EHhL8r6IERQfGa4VAF5",
	"surveyQuestionTitle": "_2a1EHhL8r6IERQfGa4VAF5"
};