import { createSelector } from '@td/utils';

import getSymptomsSelectedClasses from './getSymptomsSelectedClasses';
import getChiefComplaintClassName from './getChiefComplaintClassName';
import getChiefComplaintClassSymptomIdsSet from './getChiefComplaintClassSymptomIdsSet';

const getSymptomFromProps = (_, props) => props.symptom;
const getListIdFromProps = (_, props) => props.listId;

const getSymptomSelectedClass = createSelector(
  getSymptomsSelectedClasses,
  getSymptomFromProps,
  (symptomsSelectedClasses, symptom) => symptomsSelectedClasses[symptom.code]
);

const makeGetShouldDisableSymptom = () =>
  createSelector(
    getSymptomFromProps,
    getListIdFromProps,
    getSymptomSelectedClass,
    getChiefComplaintClassName,
    getChiefComplaintClassSymptomIdsSet,
    (symptom, listId, symptomSelectedClass, chiefComplaintClassName, chiefComplaintSymptomIdsSet) =>
      listId !== 'Associated' &&
      listId !== chiefComplaintClassName &&
      ((symptomSelectedClass && listId !== symptomSelectedClass) ||
        chiefComplaintSymptomIdsSet.has(symptom.code))
  );

export default makeGetShouldDisableSymptom;
