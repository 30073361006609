import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectNumber, blockConferenceStart, unblockConferenceStart } from '../actions';
import { RINGING, CONNECTED } from '../lib/states';

class ConferencePhoneSelect extends Component {
  static propTypes = {
    phoneNumbers:           PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedNumber:         PropTypes.string.isRequired,
    id:                     PropTypes.number.isRequired,
    selectNumber:           PropTypes.func.isRequired,
    blockConferenceStart:   PropTypes.func.isRequired,
    unblockConferenceStart: PropTypes.func.isRequired,
    callStatus:             PropTypes.string.isRequired
  }

  onChange = event => {
    const { value } = event.target;

    if (value === 'other') {
      this.props.blockConferenceStart();
    } else {
      this.props.unblockConferenceStart();
    }

    this.selectNumber(value);
  }

  selectNumber = number =>
    this.props.selectNumber(this.props.id, number);

  displayNumber = number =>
    number.replace(/^(\+1)(\d{3})(\d{3})(\d{4})$/, '$1 ($2) $3-$4');

  isDisabled = () =>
    this.props.callStatus === RINGING || this.props.callStatus  === CONNECTED;

  optionsForSelect = () => (
    this.props.phoneNumbers.map(number => (
      { value: number, text: this.displayNumber(number) }
    )).concat({ value: 'other', text: 'Other' })
  )

  renderOptionsForSelect = () => (
    this.optionsForSelect().map(({ value, text }, index) =>
      <option key={index} value={value}>{text}</option>
    )
  )

  render = () => (
    <div className="form-element select">
      <select
        disabled={this.isDisabled()}
        value={this.props.selectedNumber}
        onChange={this.onChange}
      >
        {this.renderOptionsForSelect()}
      </select>
    </div>
  )
}

export { ConferencePhoneSelect };

export default connect(
  () => ({}),
  { selectNumber, blockConferenceStart, unblockConferenceStart }
)(ConferencePhoneSelect);
