import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import TRACK_PROMPT_CLICK from '../mutations/track-prompt-click.graphql';
import CloseIcon from '../../icons';
import styles from '../styles.module.scss';
import trackEvent from '../utils/utils';

import pillIcon from '../../assets/images/icon-pill.svg';
import thumbsUpIcon from '../../assets/images/icon-thumbs-up.svg';

const StudyPrompts = ({ text, styling, links, updatedAt, metricId, type, providerId }) => {
  const [display, setDisplay] = useState(true);
  const [callTrackClick] = useMutation(TRACK_PROMPT_CLICK);

  const textWithLinks = links.reduce(
    (currTextWithLinks, link) =>
      currTextWithLinks.replace(
        link.interpolate,
        `<a class="${styles.studyDashboardPromptLink}" href="${link.href}">${link.text}</a>`
      ),
    text
  );

  const closeWith = (e, action) => {
    e.preventDefault();
    setDisplay(false);
    if (!metricId) return;

    let eventAction = '';
    let piwikAction = '';

    switch (action) {
      case 'dismiss':
        eventAction = 'CLICKTRACKEVENT_DISMISSED';
        piwikAction = 'ignore';
        break;
      case 'understand':
        eventAction = 'CLICKTRACKEVENT_RESPONDED';
        piwikAction = 'acknowledge';
        break;
    }

    trackEvent({
      event:      'usc-provider-prompt-hide',
      'app-name': 'provider-web',
      action:     piwikAction,
      content:    text.substring(0, 200),
      providerId
    });

    callTrackClick({
      variables: {
        guidelineId:             '',
        guidelineName:           '',
        app:                     'provider',
        clickTrackingEventCd:    eventAction,
        studyContainerContentId: metricId,
        providerId
      }
    });
  };

  if (!display) return null;

  let heading = '';
  let understandBtn = '';
  let closeBtn = '';
  if (updatedAt) {
    heading = (
      <h4 className={styles.studyDashboardPromptHeading}>
        <img src={pillIcon} style={{ marginBottom: -4 }} />
        ANTIBIOTIC STEWARDSHIP | New data: {moment(updatedAt).format('ll')}
      </h4>
    );
    understandBtn = (
      <a href="" className={styles.studyDashboardPromptUnderstandLink} onClick={e => closeWith(e, 'understand')}>
        <img src={thumbsUpIcon} />I UNDERSTAND
      </a>
    );
  }

  if (type === 'commitment') {
    closeBtn = (
      <a className={'dismissBtn'} onClick={e => closeWith(e, 'dismiss')}>
        <CloseIcon height={15} width={15} fill="black" style={{ cursor: 'pointer' }} />
      </a>
    );
  }

  if (text.length > 0) {
    trackEvent({
      event:      'usc-provider-prompt',
      'app-name': 'provider-web',
      content:    text.substring(0, 200),
      providerId
    });
  }

  let providerPromptStyling;
  switch (styling) {
    case 'positive':
      providerPromptStyling = 'success';
      break;

    case 'negative':
      providerPromptStyling = 'error';
      break;

    default:
      providerPromptStyling = 'notice';
      break;
  }

  return (
    <div className={`${providerPromptStyling} ${styles.studyDashboardPrompt}`}>
      <span className={styles.studyDashboardPromptText}>
        {heading}
        <p dangerouslySetInnerHTML={{ __html: textWithLinks }} />
        {understandBtn}
      </span>
      {closeBtn}
    </div>
  );
};

StudyPrompts.propTypes = {
  text:       PropTypes.string.isRequired,
  styling:    PropTypes.string,
  links:      PropTypes.arrayOf(PropTypes.object),
  updatedAt:  PropTypes.string,
  metricId:   PropTypes.number,
  type:       PropTypes.string,
  providerId: PropTypes.string.isRequired
};

export default StudyPrompts;
