import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mapIntegerToYesNo } from 'medication_service_ui';
import InterviewItem from './interview-item';

class ReviewLineItem extends Component {
  state = {
    medication:     this.props.medication || {},
    selectedOption: this.props.medication.activelyTakingFlag
                      ? mapIntegerToYesNo(this.props.medication.activelyTakingFlag)
                      : '',
    isDirty:           false,
    pendingReviewList: this.props.pendingReviewList || []
  };

  componentWillReceiveProps(nextProps) {
    const { medication, isDirty } = this.state;
    const { pendingReviewList } = nextProps;

    if (!pendingReviewList.includes(medication) && isDirty) {
      this.setState({
        isDirty:        false,
        medication:     nextProps.medication,
        selectedOption: nextProps.medication.activelyTakingFlag
                        ? mapIntegerToYesNo(nextProps.medication.activelyTakingFlag)
                        : ''
      });
    }
  }

  handleOnChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    const { medication } = this.state;
    const { pendingReviewList } = this.props;
    const index = pendingReviewList.findIndex(
      med => med.clinicalMedicationId === medication.clinicalMedicationId);

    if (index > -1) {
      this.setState({
        selectedOption: value,
        medication:     {
          ...medication,
          activelyTakingFlag: (value === 'Yes' ? 'Y' : 'N')
        }
      }, () => { this.props.updatePendingReviewList(this.state.medication); });
    } else {
      this.setState(prevState => ({
        selectedOption: value,
        isDirty:        !prevState.isDirty,
        medication:     {
          ...medication,
          activelyTakingFlag: (value === 'Yes' ? 'Y' : 'N'),
          isDirty:            !prevState.isDirty
        }
      }), () => { this.props.addToPendingReviewList(this.state.medication); });
    }
  }

  render() {
    const { medication, selectedOption } = this.state;

    return (
      <InterviewItem
        {...medication}
        selectedOption={selectedOption}
        responseOptions={this.props.responseOptions}
        onChangeFuncHandler={this.handleOnChange}
        showQuantity={this.props.showQuantity}
      />
    );
  }
}

ReviewLineItem.propTypes = {
  showQuantity:            PropTypes.bool,
  medication:              PropTypes.object.isRequired,
  responseOptions:         PropTypes.array.isRequired,
  pendingReviewList:       PropTypes.array.isRequired,
  addToPendingReviewList:  PropTypes.func.isRequired,
  updatePendingReviewList: PropTypes.func.isRequired
};

export default ReviewLineItem;
