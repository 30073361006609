import React, { Fragment } from 'react';
import { translate } from '@td/shared_utils';

const FollowUpAction = ({ consultationId }) => {
  const handleFollowUp = event => {
    if (event.detail == 1) {
      event && event.preventDefault();
      $.get(`/history/consultation/${consultationId}/schedule_followup_modal`, { from_dashboard: true });
    }
  };

  return (
    <Fragment>
      <a onClick={handleFollowUp} className="schedule_followup" id="schedule-follow-up">
        {translate(null, 'patient_case_load_mgmt_mvp.patient_list', 'schedule_follow_up')}
      </a>
    </Fragment>
  );
};

export default FollowUpAction;
