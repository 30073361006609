import React from 'react';
import PropTypes from 'prop-types';

const DataTablePagination = ({
  name = I18n.t('patient_case_load_mgmt_mvp.patient_list.view_more_default'),
  onClick,
  loading,
  hasNextPage = true
}) => {
  if (!hasNextPage) return null;

  return (
    !loading && (
      <div className="pagination-container">
        <a onClick={onClick} className="pagination-link">
          {I18n.t('patient_case_load_mgmt_mvp.patient_list.view_more')} {name}
        </a>
      </div>
    )
  );
};

DataTablePagination.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasNextPage: PropTypes.bool
};

export default DataTablePagination;
