import { merge, get } from 'lodash';
import { CHECKBOX_ARRAY, CHECKBOX, TEXT_AREA, SELECT, RADIO, DATE } from 'app/surveys/constants.js';

// This is the base case. This is the information that will actually be passed to the
// 'onChange' and 'value' attributes in the input fields of each child component
const createChoiceConfig = (choiceJson, questionOnChange, displayCode, questionStateTree, questionJson) => {
  const conf = {};
  const answerCode = choiceJson.answerCode;
  const answerValue = questionStateTree[answerCode];
  if (displayCode === CHECKBOX_ARRAY) {
    conf.value = answerValue === undefined ? false : answerValue;
  } else if (displayCode === TEXT_AREA) {
    conf.displayAttrs = { minChars: choiceJson.minChars };
    conf.value = answerValue === undefined ? '' : answerValue;
  } else if (displayCode == RADIO) {
    conf.value = answerValue === undefined ? false : answerValue;
  } else if (displayCode == DATE) {
    conf.value = answerValue === undefined ? '' : answerValue;
  }

  conf.onChangeCallback = newAnswerVal => {
    const transformation =
      choiceJson.callback && choiceJson.callback(newAnswerVal, answerCode, get(questionJson, 'questionCode'));
    questionOnChange(newAnswerVal, transformation);
  };

  return conf;
};

const createQuestionConfig = (questionJson, sectionOnChange, sectionStateTree) => {
  const questionCode = questionJson.questionCode;
  const questionStateTree = sectionStateTree[questionCode] || {};

  const questionOnChange = (newAnswerValues, transformation) => {
    const newStateTree = { [questionCode]: newAnswerValues };
    sectionOnChange(newStateTree, transformation);
  };
  const displayCode = `${questionJson.questionTypeCode} ${questionJson.displayTypeCode}`;

  return questionJson.choices.reduce(
    (acc, currentChoiceJson) => ({
      ...acc,
      displayCode,
      displayAttrs:                   questionStateTree.displayAttrs || {},
      [currentChoiceJson.answerCode]: createChoiceConfig(
        currentChoiceJson,
        questionOnChange,
        displayCode,
        questionStateTree,
        questionJson
      )
    }),
    {}
  );
};

const createSectionConfig = (sectionJson, surveyOnChange, surveyStateTree) => {
  const sectionCode = sectionJson.sectionCode;
  const sectionStateTree = surveyStateTree[sectionCode] || {};

  const sectionOnChange = (newQuestionStateTree, transformation) => {
    const newStateTree = { [sectionCode]: newQuestionStateTree };

    surveyOnChange(newStateTree, transformation);
  };

  return sectionJson.questions.reduce(
    (acc, currentQuestionJson) => ({
      ...acc,
      [currentQuestionJson.questionCode]: createQuestionConfig(currentQuestionJson, sectionOnChange, sectionStateTree)
    }),
    {}
  );
};

// The survey config object exists as a flexible way to pass callbacks and input values to the
// child components of a survey. The config is re-generated on each re-render so that the child
// components remain controlled by react and the survey answers are synced with the redux store.
//
// surveyJson: this is the json response from TAS for a given survey, but it also can contain
// callbacks attached to different levels of survey object and display options, see
// clinical-alert-form.js for an example
//
// surveyStateTree: this is provided by redux, in the initial call to createSurveyConfig
// this will be an empty object, but on subsequent calls, as child component values change
// it will reflect the current answer state of the survey. It can also hold display
// information. Why? So that interactions between questions can be enforced by reading and
// writing to the tree. The surveyStateTree represents a single source of truth for how the
// state of the form *should* be when the next config is generated.
//
// persistSurveyChanges: whatever is using this must pass function that takes the new survey
// tree as an arg and persists it in the surveyStateTree so the next time the config is
// generated it has the up to date information

export const createSurveyConfig = (surveyJson, surveyStateTree, persistSurveyChanges) => {
  const surveyCode = surveyJson.surveyCode;

  const surveyOnChange = (newSectionStateTree, transformation) => {
    const newStateTree = { [surveyCode]: newSectionStateTree };
    persistSurveyChanges(merge(newStateTree, transformation));
  };

  return surveyJson.sections.reduce(
    (acc, currentSectionJson) => ({
      ...acc,
      [currentSectionJson.sectionCode]: createSectionConfig(currentSectionJson, surveyOnChange, surveyStateTree)
    }),
    {}
  );
};
