import React from 'react';
import { Note } from '@td/ehr';
import { translateMarkupString } from '@td/shared_utils';
import {
  wasEhrTabReviewedBefore,
  isMentalHealthSectionReviewRequired,
  isMentalHealthNotePresent,
  hasAdolescentDocumentsSection
} from '../../../ehr-utils';

const EHRNoteForAdolescentMentalHealthDocuments = ({ isViewedAllMentalHealthDocuments }) => {
  if (wasEhrTabReviewedBefore()) return null;
  if (!hasAdolescentDocumentsSection()) return null;
  if (!isMentalHealthSectionReviewRequired()) return null;
  if (isViewedAllMentalHealthDocuments) return null;

  return (
    <Note>
      {translateMarkupString(
        'ehr.mh.minor_documents_section',
        'review_required_notice',
        { a: (<a href={'#mh-minor'} />) }
      )}
    </Note>
  );
};

export default EHRNoteForAdolescentMentalHealthDocuments;
