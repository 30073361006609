import _ from 'lodash';
import { createSelector } from '@td/utils';

import getSymptomsById from './getSymptomsById';
import getSymptomIds from './getSymptomIds';

export default createSelector(
  getSymptomsById,
  getSymptomIds,
  (symptomsById, symptomIds) => _.map(symptomIds, symptomId => symptomsById[symptomId])
);
