import React from 'react';
import PatientLimitButton from '../patient-volume-manager/components/patient-limits-button/patient-limit-button';

const PatientListManageLimits = () => (
  <div>
    <button id="patient-limit-button" className="tabs-buttons patient-limits-button">
      <PatientLimitButton />
    </button>
  </div>
);

export default PatientListManageLimits;
