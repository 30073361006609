import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { includes, snakeCase, isEmpty } from 'lodash';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

const METHOD_AT_HOME = 'AT_HOME';
const DISABLED_MESSAGE = 'COVID-19 PCR home test kit cannot be ordered with any other lab tests';

/**
 * Given the lab categories data structure and an array of selected offers,
 * determine the selected method, e.g. AT_LAB or AT_HOME. (We have a current
 * techincal limitation that more than one of these methods cannot be used
 * for a single consult)
 */
const getSelectedMethod = (labCategories, selectedOffers) => {
  let selectedMethod = null;
  if (labCategories && !isEmpty(selectedOffers)) {
    // Loop over the categories and each offer under it until we find one that is selected
    // That determines the selected method code, e.g. AT_LAB or AT_HOME. We do not allow
    // selection of offers that have any other method code besides this.
    labCategories.forEach(({ labOffers }) => {
      const aSelectedOffer = labOffers.find(({ id }) => includes(selectedOffers, id.toString()));
      if (aSelectedOffer) {
        selectedMethod = aSelectedOffer.methodCode;
        return;
      }
    });
  }
  return selectedMethod;
};

const LabOrdersList = ({ labCategories, selectedOffers, clickOffer }) => {
  const selectedMethod = getSelectedMethod(labCategories, selectedOffers);

  const renderLabOffer = ({ id: tasId, name, methodCode, description, notes }) => {
    const id = snakeCase(name);
    const isSelectedOffer = includes(selectedOffers, tasId.toString());

    // CKAN-1604 requirement is to highlight the note for at home labs in red
    // Note: if other labs need note highlighted in red in future the we will need more extensible mechanism
    const highlighted = isSelectedOffer && methodCode === METHOD_AT_HOME;

    // Do not allow selecting both AT_LAB and AT_HOME offers for the same consult
    const offerCanBeSelected = selectedMethod == null || methodCode === selectedMethod;
    const tooltip = offerCanBeSelected ? '' : DISABLED_MESSAGE;

    return (
      <li id={`${id}_container`} key={id} data-tip={tooltip}>
        <input
          defaultChecked={isSelectedOffer}
          name="interview[lab_offers][]"
          value={tasId}
          id={id}
          type="checkbox"
          onClick={clickOffer}
          disabled={!offerCanBeSelected}
        />
        <label htmlFor={id}>{description}</label>
        <div className={classnames('notes', { highlighted })}>{isSelectedOffer ? notes : ''}</div>
      </li>
    );
  };

  const offerElems = labCategories.map(({ name, labOffers }) => (
    <Fragment key={name}>
      <p className="lab-category-heading">{name}</p>
      <ul className="lab-offers-list">{labOffers.map(renderLabOffer)}</ul>
    </Fragment>
  ));

  return (
    <Fragment>
      <ReactTooltip place="top" type="dark" effect="float" />
      {offerElems}
    </Fragment>
  );
};

LabOrdersList.propTypes = {
  clickOffer: PropTypes.func.isRequired,
  labCategories: PropTypes.array.isRequired,
  selectedOffers: PropTypes.array.isRequired
};

export default LabOrdersList;

export { DISABLED_MESSAGE };
