import { useCreateQuickDxRecord, useDeleteQuickDxRecord } from '../api';
import { getConsultationSpecialty, useQuickDxFavorites } from '.';

export const useHandleFavoriteStatusChange = (diagnosis, isFavorite, options = {}) => {
  const { diagnosisCode } = diagnosis;

  if (!isFavorite) {
    return useCreateQuickDxRecord(diagnosisCode, options);
  }

  return useDeleteQuickDxRecord(diagnosisCode, options);
};

export const onFavoriteChange = ({ favoritesEnabled, quickDxId, diagnosisCd, isFavorite, isDisabled, handler }) => {
  if (!favoritesEnabled) return;
  if (isDisabled) return;

  if (isFavorite) {
    return handler({
      variables: { quickDxId: parseInt(quickDxId, 10) }
    });
  }

  return handler({
    variables: {
      diagnosisCd,
      specialtyCd: getConsultationSpecialty()
    }
  });
};
