import React, { useState } from 'react';
import TeladocModal from 'app/TeladocModal';
import InnerContent from './innerContent';
import PropTypes from 'prop-types';
import '../styles.scss';
import { teladocApi, authToken } from '@td/api';
import { translate } from '@td/shared_utils';
import { useData } from 'app/DataProvider';

const NotifyProvidersAboutNewFeature = ({ isOpen, setIsOpen, navigate }) => {
  const title = translate(null, 'dashboard.same_day_visit_alert_modal', 'title');

  const { providerId } = useData();

  const closeModal = () => {
    setIsOpen(false);

    const createParams = {
      provider_id: providerId,
      alert_type: 'same_day_scheduling_alert'
    };

    teladocApi.post(`/v4/providers/${providerId}/alerts`, createParams, authToken.get());
  };

  const handleTurnOnSameDayVisits = () => {
    closeModal();
    if (navigate) {
      navigate('/my_account?edit=true#same_day_visits_section');
    }
  };

  const actions = [
    {
      className: '',
      primary:   false,
      onClick:   closeModal,
      disabled: false,
      label:    translate(null, 'dashboard.same_day_visit_alert_modal.actions', 'dismiss')
    },
    {
      className: '',
      primary:   true,
      onClick:   handleTurnOnSameDayVisits,
      disabled:  false,
      label:     translate(null, 'dashboard.same_day_visit_alert_modal.actions', 'turn_on_same_day_visits')
    }
  ];

  return (
    <TeladocModal
      className={'feature-overview-modal-styles'}
      showCloseButton={false}
      isOpen={isOpen}
      onClose={handleTurnOnSameDayVisits}
      title={title}
      actions={actions}
      children={<InnerContent />}
    />
  );
};

NotifyProvidersAboutNewFeature.propTypes = {
  isOpen:    PropTypes.bool,
  setIsOpen: PropTypes.func,
  navigate:  PropTypes.func
};

NotifyProvidersAboutNewFeature.defaultProps = {
  isOpen: false
};

export default NotifyProvidersAboutNewFeature;
