import createFormReducer from '../../lib/redux/utilities/create-form-reducer';

import { ADDITIONAL_NOTES_FORM } from '../action-types';

export default createFormReducer({
  fields: [
    'subjective_additional_notes'
  ],
  formActionTypes: ADDITIONAL_NOTES_FORM
});
