import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export const MainInquiry = ({ medicalAlertCode, mainInquiry, caFormMainInquiryChange, surveyNamingKey }) => {
  const mainInquiryChange = value => {
    caFormMainInquiryChange(value);
    // Needed to hide/show information in the followup tab related to PUI
    const globalCaMainInquiryChangeEvent = new CustomEvent('GlobalCaMainInquiryChangeEvent', { detail: value });
    window.dispatchEvent(globalCaMainInquiryChangeEvent);
  };

  useEffect(() => {
    mainInquiryChange(mainInquiry || '');
  }, []);

  return (
    <div>
      <div className="clinical-alerts-container">
        <h4 className="clinical-alerts-header">{medicalAlertCode.alertQuestion}</h4>
        <a
          href={medicalAlertCode.moreInfoFilename}
          target="_blank"
          rel="noopener noreferrer"
          className="clinical-alerts-more-info"
        >
          {medicalAlertCode.moreInfoLabel}
        </a>
      </div>

      <ul className="yes_no_radios">
        <li>
          <input
            name={`${surveyNamingKey}[response_flag]`}
            type="radio"
            value="Y"
            checked={mainInquiry === 'Y'}
            id="clinical_alert_yes"
            onChange={event => mainInquiryChange(event.target.value)}
          />
          <label htmlFor="clinical_alert_yes">Yes</label>
        </li>
        <li>
          <input
            name={`${surveyNamingKey}[response_flag]`}
            type="radio"
            checked={mainInquiry === 'N'}
            value="N"
            id="clinical_alert_no"
            onChange={event => mainInquiryChange(event.target.value)}
          />
          <label htmlFor="clinical_alert_no">No</label>
        </li>
      </ul>
    </div>
  );
};

MainInquiry.propTypes = {
  mainInquiry:             PropTypes.string.isRequired,
  caFormMainInquiryChange: PropTypes.func.isRequired,
  medicalAlertCode:        PropTypes.shape({
    moreInfoLabel:    PropTypes.string,
    moreInfoFilename: PropTypes.string,
    alertQuestion:    PropTypes.string
  })
};
