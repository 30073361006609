import React from 'react';
import PropTypes from 'prop-types';
import { MdCancel } from 'react-icons/md';

import UnsupportedBrowserError from './network-test-errors/unsupported-browser-error';
import DevicePermissionError from './network-test-errors/device-permission-error';
import FailedTestError from './network-test-errors/failed-test-error-container';
import MissingDeviceError from './network-test-errors/missing-device-error';
import ServerError from './network-test-errors/server-error';
import NetworkTestErrorActions from './network-test-error-actions-container';
import {
  isUnsupportedBrowserError,
  isDevicePermissionError,
  isMissingCameraError,
  isMissingMicrophoneError,
  isFailedAudioTestError,
  isFailedVideoTestError
} from '../../lib/open-tok-network-test/open-tok-network-test-error';

const getErrorComponent = error => {
  if (isUnsupportedBrowserError(error)) {
    return <UnsupportedBrowserError />;
  }

  if (isDevicePermissionError(error)) {
    return <DevicePermissionError />;
  }

  if (isMissingCameraError(error)) {
    return <MissingDeviceError device="camera" />;
  }

  if (isMissingMicrophoneError(error)) {
    return <MissingDeviceError device="microphone" />;
  }

  if (isFailedAudioTestError(error) || isFailedVideoTestError(error)) {
    return <FailedTestError />;
  }

  return <ServerError />;
};

const NetworkTestError = ({ error }) => (
  <div>
    <div className="network-test-results">
      <MdCancel className="network-test-icon network-test-icon-error" />
      {getErrorComponent(error)}
    </div>
    <NetworkTestErrorActions />
  </div>
);

NetworkTestError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    errorCodes: PropTypes.arrayOf(PropTypes.string)
  })
};

export default NetworkTestError;
