import React, { useEffect, useState } from 'react';
import moment from 'moment';
import stopIcon from '../../assets/images/stop-icon.svg';
import infoIcon from '../../assets/images/info-icon.svg';
import styles from './styles.module.scss';
import cn from 'classnames';

const Timer = ({ pipWindow }) => {
  const [show, setShow] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());
  const [elapsedTime, setElapsedTime] = useState(0);
  const [running, setRunning] = useState(false);
  const [formattedTime, setFormattedTime] = useState('00:00');

  useEffect(() => {
    show && startTimer();
  }, [show]);

  useEffect(() => {
    let interval;

    if (running) {
      interval = setInterval(() => {
        const elapsedTimeMilliseconds = Date.now() - startTime;
        setElapsedTime(elapsedTimeMilliseconds);
        setFormattedTime(moment.utc(elapsedTimeMilliseconds).format('mm:ss'));
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [running, startTime]);

  useEffect(() => {
    if (pipWindow && show) {
      const pipContainer = pipWindow.document.querySelector('#pip_container');
      pipContainer.className = 'react with-timer';
    }
  }, [pipWindow, show]);

  const startTimer = () => {
    setShow(true);
    setRunning(true);
    setStartTime(Date.now() - elapsedTime);
  };

  const stopTimer = () => {
    setRunning(false);
  };

  return (
    <div className={cn(styles.timerContainer, !show && styles.hide)}>
      <button
        id="start-cronometer"
        className={styles.hide}
        onClick={() => setShow(true)}
      />
      <div>
        <div>
          <img src={infoIcon} alt="" />
          <span>Time with patient</span>
        </div>
        <div>
          <span id="formatted-time">{formattedTime}</span>
          <img src={stopIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Timer;
