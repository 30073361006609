import React from 'react';
import PropTypes from 'prop-types';

const FailedTestError = ({ allowVideoClockIn }) => (
  <div>
    <h5 className="network-test-content network-test-header">Low Bandwidth Detected</h5>
    {!allowVideoClockIn && (
      <div className="network-test-content">
        Our performance test shows you currently do not have adequate bandwidth to conduct a video
        visit. Because of this{' '}
        <span className="network-test-strong">you have been clocked in for phone only.</span> In
        order to ensure a smooth video experience for the member, please take the following steps:
      </div>
    )}
    {allowVideoClockIn && (
      <div className="network-test-content">
        Our performance test shows you currently do not have adequate bandwidth to conduct a video
        visit. However, you currently have{' '}
        <span className="network-test-strong">scheduled video visits in your queue.</span> Because
        of this{' '}
        <span className="network-test-strong">you have been clocked in for phone and video.</span>{' '}
        In order to serve these members with video visits:
      </div>
    )}
    <ul className="network-test-content network-test-list">
      <li>If using wi-fi, move to another location with better signal strength</li>
      <li>Or, use an ethernet cable to connect to your network</li>
    </ul>
  </div>
);

FailedTestError.propTypes = {
  allowVideoClockIn: PropTypes.bool
};

export default FailedTestError;
