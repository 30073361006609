import React from 'react';

import { AutoDialerV2 } from '@teladoc/auto_dialer';

import CallInProgress from './CallInProgress';
import LoadingIndicator from 'app/components/loading-indicator/loading-indicator';

import { VIEW_STATES } from '../constants/call-status';
import { useAutoDialer } from '../utils';

const { CallComplete, DialingMember, DialingProvider, UnavailableMember, JoinViaPhone, StartCall } = AutoDialerV2;

const WebRTC = () => {
  const {
    memberName,
    viewState,
    hasActiveCall,
    currentAttendeeCallData,
    callStatus,
    memberCallData,
    device,
    beginCall,
    restartCall,
    onCancelClick
  } = useAutoDialer();

  const printState = objToPrint =>
    Object.entries(objToPrint).map(entry => <div key={entry[0]}>{`${entry[0]}: ${entry[1]}`}</div>);

  return (
    <div className="auto-dialer-container">
      {process.env.NODE_ENV !== 'production' && (
        <div>
          <div>{`VIEW STATE: ${viewState}\n\n`}</div>
          <div>{`CALL STATE: ${callStatus}\n\n`}</div>
          <div>
            {' \n\nMEMBER STATUS:\n'}
            {printState(memberCallData)}
          </div>
          <div>
            {' \n\nCURRENT ATTENDEE STATUS:\n'}
            {printState(currentAttendeeCallData)}
          </div>
        </div>
      )}

      {viewState === VIEW_STATES.UNKNOWN && (
        <div>
          <LoadingIndicator />
          <p>Loading...</p>
        </div>
      )}

      {viewState === VIEW_STATES.START_CALL && <StartCall isReady={device && !hasActiveCall} onClick={beginCall} />}

      {viewState === VIEW_STATES.DIALING_MEMBER && <DialingMember />}

      {viewState === VIEW_STATES.UNAVAILABLE && <UnavailableMember onCancelClick={onCancelClick} />}

      {viewState === VIEW_STATES.DIALING_PROVIDER && <DialingProvider memberName={memberName} />}

      {(viewState === VIEW_STATES.CALL_IN_PROGRESS || viewState === VIEW_STATES.PROVIDER_REJOIN) && <CallInProgress />}

      {viewState === VIEW_STATES.CALL_COMPLETE && <CallComplete callStatus={callStatus} onClick={restartCall} />}

      {viewState === VIEW_STATES.JOIN_VIA_PHONE && <JoinViaPhone />}
    </div>
  );
};

// Consultation obj shape converted exhaustively in AutoDialerInitializer.js
WebRTC.propTypes = {};

WebRTC.displayName = 'WebRTC';

export default WebRTC;
