import React, { useRef, useEffect, Children, cloneElement, isValidElement, useState } from 'react';
import PropTypes from 'prop-types';
import { useKeepAliveContext } from './keep-alive-provider';
import { isEqual } from 'lodash';

const KeepAlive = ({
                     id,
                     children,
                     useKeepAlive = true,
                     containerClassName = '',
                     portalClassName = '',
                     ...restOfProps
                   }) => {
  const { getPortalElement } = useKeepAliveContext();

  const [props, setProps] = useState(null);

  const containerRef = useRef();

  if (useKeepAlive && !isEqual(props, restOfProps)) {
    setProps(restOfProps);
  }

  useEffect(() => {
    useKeepAlive && appendPortalElement();
  }, [props]);

  const cloneChildrenAndInjectProps = () => {
    return Children.map(children, child => {
      return isValidElement(child)
        ? cloneElement(child, { ...restOfProps })
        : child;
    });
  };

  const appendPortalElement = () => {
    const element = getPortalElement(
      id,
      portalClassName,
      cloneChildrenAndInjectProps()
    );

    containerRef.current.appendChild(element);
  };

   return (
     useKeepAlive
       ? <div className={containerClassName} ref={containerRef}/>
       : cloneChildrenAndInjectProps()
   );
};

KeepAlive.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  useKeepAlive: PropTypes.bool,
  containerClassName: PropTypes.string,
  portalClassName: PropTypes.string,
};

export default KeepAlive;
