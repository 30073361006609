import React, { useEffect } from 'react';
import { translate } from '@td/shared_utils';
import PropTypes from 'prop-types';
import ExternalAccounts from '../external-accounts';
import { clearExternalCalendarViewHash } from '../../../../helpers';
import styles from './styles.module.scss';
import { useProviderEligibility } from '../../contexts/provider-eligibility-context';

const TRANSLATION_SCOPE = 'my_schedule.settings_page.external_calendars_section';

const MainContentWrapper = ({ providerId }) => {
  useEffect(() => {
    clearExternalCalendarViewHash();
  }, []);

  const { isProviderEligibleForExternalCalendars } = useProviderEligibility();

  if (!isProviderEligibleForExternalCalendars) {
    return null;
  }

  return (
    <div className={styles.externalCalendarsMainContentWrapper}>
      <h4 className={styles.sectionTitle} data-testid="main-wrapper-title">{translate(null, TRANSLATION_SCOPE, 'title')}</h4>
      <p className="subtitle">{translate(null, TRANSLATION_SCOPE, 'subtitle')}</p>
      <ExternalAccounts />
    </div>
  );
};

MainContentWrapper.propTypes = {
  providerId: PropTypes.number.isRequired
};

export default MainContentWrapper;
