export const SET_PRIMARY_DIAGNOSIS = 'SET_PRIMARY_DIAGNOSIS';
export const CLEAR_PRIMARY_DIAGNOSIS = 'CLEAR_PRIMARY_DIAGNOSIS';

export const setPrimaryDiagnosis = ({ diagnosis }) => ({
  type: SET_PRIMARY_DIAGNOSIS,
  payload: { diagnosis }
});

export const clearPrimaryDiagnosis = () => ({
  type: CLEAR_PRIMARY_DIAGNOSIS,
});

