import { createSelector } from '@td/utils';

import getSymptomsById from './getSymptomsById';

const getSymptomClassSymptomIds = (state, props) =>
  state.symptoms.symptomClassesById[props.symptomClass].symptoms;

const makeGetSymptomsForClass = () =>
  createSelector(
    getSymptomsById,
    getSymptomClassSymptomIds,
    (symptomsById, symptomClassSymptomIds) =>
      symptomClassSymptomIds.map(symptomId => symptomsById[symptomId])
  );

export default makeGetSymptomsForClass;
