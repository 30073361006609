import { createSelector } from '@td/utils';

const getSymptomId = (_, props) => props.symptomId;
const getSymptomName = (state, props) => state.symptoms.symptomsById[props.symptomId].name;
const getSymptomRadioValue = (state, props) => state.symptomsForm.radioValues[props.symptomId];
const getSymptomNoteValue = (state, props) => state.symptomsForm.noteValues[props.symptomId];

export default () =>
  createSelector(
    getSymptomId,
    getSymptomName,
    getSymptomRadioValue,
    getSymptomNoteValue,
    (symptomId, symptomName, radioValue, noteValue) => ({
      id:         symptomId,
      name:       symptomName,
      radioValue: radioValue === 'yes' ? 'SYMPTOMPRESENTNOW_YES' : 'SYMPTOMPRESENTNOW_NO',
      noteValue
    })
  );
