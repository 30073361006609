import React from 'react';
import PropTypes from 'prop-types';
import ActionsContainer from '../ActionsContainer';
import StartAction from '../actions/StartAction';
import RescheduleScheduleVisitAction from '../actions/RescheduleScheduleVisitAction';
import ViewEHRAction from '../actions/ViewEHRAction';
import SendMessageAction from '../actions/SendMessageAction';

const ScheduledVisitsTableActions = props => (
  <ActionsContainer>
    {[
      <StartAction key="start" {...props} />,
      <ViewEHRAction key="view-ehr" {...props} />,
      <SendMessageAction key="send-message" {...props} />,
      <RescheduleScheduleVisitAction key="reschedule" {...props} />
    ]}
  </ActionsContainer>
);

ScheduledVisitsTableActions.propTypes = {
  consultationId: PropTypes.number.isRequired,
  messageRoomId: PropTypes.number.isRequired
};

export default ScheduledVisitsTableActions;
