import React from 'react';
import { translate } from '@td/shared_utils';
import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const TRANSLATION_SCOPE = 'my_schedule.modals.calendars_settings';

const CalendarSettingsRow = ({ calendar, selected, onChange }) => (
  <div
    className={styles.externalCalendarTile}
    data-testid="calendar-tile"
  >
    <div className={styles.externalCalendarTile_calendarName}>
      {calendar.name}
    </div>
    <label
      className={styles.externalCalendarTile_checkbox}
      htmlFor={`calendar-checkbox-${calendar.id}`}
    >
      <input
        id={`calendar-checkbox-${calendar.id}`}
        type="checkbox"
        value={calendar.id}
        checked={selected}
        onChange={() => onChange(calendar)}
      />
      <div className={styles.externalCalendarTile_checkboxLabel}>
        {translate(null, TRANSLATION_SCOPE, 'form.checkbox.label')}
      </div>
    </label>
  </div>
);

CalendarSettingsRow.propTypes = {
  calendar: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CalendarSettingsRow;
