import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import getAddedSymptomIds from '../../reducers/selectors/symptoms/getAddedSymptomIds';
import getAssociatedSymptoms from '../../reducers/selectors/symptoms/getAssociatedSymptoms';
import
  getChiefComplaintClassName
from '../../reducers/selectors/symptoms/getChiefComplaintClassName';
import AssociatedSymptoms from './associated-symptoms';
import ISymptom from '../../interfaces/symptom-interface';

export const AssociatedSymptomsContainer = ({
  chiefComplaintClass,
  symptoms,
  addedSymptomsLength
}) => (
  <div>
    {chiefComplaintClass || addedSymptomsLength > 0 ? (
      <AssociatedSymptoms symptoms={symptoms} />
    ) : (
      <div className="no-class-message">
        Add a symptom below or select from a list of additional symptoms.
      </div>
    )}
  </div>
);

const mapStateToProps = state => ({
  symptoms:            getAssociatedSymptoms(state),
  addedSymptomsLength: getAddedSymptomIds(state).length,
  chiefComplaintClass: getChiefComplaintClassName(state)
});

AssociatedSymptomsContainer.propTypes = {
  symptoms:            PropTypes.arrayOf(ISymptom).isRequired,
  addedSymptomsLength: PropTypes.number.isRequired,
  chiefComplaintClass: PropTypes.string
};

export default connect(mapStateToProps)(AssociatedSymptomsContainer);
