import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, parseCanadaPostalCode } from 'medication_service_ui';
import { CANADIAN_PROVINCES } from 'app/config/canadian-provinces';
import SearchPharmacyForm from './components/search-pharmacy-form';
import PharmacyList from './components/pharmacy-list';
import SavedPharmacy from './components/saved-pharmacy';
import Pharmacy from './components/pharmacy';
import PHARMACY_SEARCH_QUERY from './queries/pharmacy-search.graphql';
import ADD_CONSULTATION_PHARMACY from './mutations/add-consultation-pharmacy.graphql';
import ACTIVE_CONSULTATION_QUERY from '../prescription/queries/active-consultation-query.graphql';

const PharmacyRenderer = props => {
  if (Object.keys(props).length === 0) {
    return null;
  }
  if (props.hasOwnProperty('pharmacy_id')) {
    return (<SavedPharmacy {...props} />);
  }
  return (<Pharmacy {...props} />);
};

const ChangePharmacy = ({ providerCanRx, onClickHandler }) => {
  if (!providerCanRx) { return null; }

  return (
    <div>
      <a href="" onClick={onClickHandler}>Change Pharmacy</a>
    </div>
  );
};

class CanadaPharmacyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal:        false,
      isManualPharmacy: false,
      searchResults:    [],
      selectedPharmacy: {}
    };
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  toggleManualPharmacy = ({ target: { value } }) => {
    const isManualPharmacy = value === 'yes';
    this.setState({ isManualPharmacy });
  }

  onChangeHandler = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name.replace(/searchParams./gi, '');

    event.preventDefault();

    this.setState({
      showModal:    true,
      searchParams: { [name]: value }
    });
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  handlePharmacySelect = (name, event) => {
    event.preventDefault();
    const { searchResults } = this.state;

    const pharmacy = searchResults.find(obj => {
      return obj.name === name;
    });

    if (!pharmacy) { return false; }

    this.setState({ selectedPharmacy: pharmacy, showModal: false });
    const { client, memberParams: { memberId }, consultationParams: { consultationId } } = this.props;

    Object.keys(pharmacy).forEach(key => {
      if (pharmacy[key] === null) {
        pharmacy[key] = '';
      }
      return pharmacy;
    });

    if (pharmacy.distance === '') {
      pharmacy.distance = 0;
    }

    client
      .query({
        query:     ACTIVE_CONSULTATION_QUERY,
        variables: { consultationId }
      }).then(response => {
        const {
          data: {
            currentUserProvider: { consultation: { globalId } = {} } = {} }
        } = response;

        // TODO: move this into a method
        client
          .mutate({
            mutation:  ADD_CONSULTATION_PHARMACY,
            variables: {
              memberId,
              erxVendorId:     pharmacy.erxVendorId,
              erxVendorCode:   pharmacy.erxVendorCode,
              issuingBodyId:   pharmacy.issuingBodyId,
              issuingBodyCode: pharmacy.issuingBodyCode,
              consultationId:  globalId,
              pharmacyParams:  {
                addressCity:     pharmacy.addressCity,
                addressLine1:    pharmacy.addressLine1,
                addressLine2:    pharmacy.addressLine2,
                addressState:    pharmacy.addressState,
                addressZipCode:  pharmacy.addressZipCode,
                country:         pharmacy.country,
                distance:        pharmacy.distance,
                erxVendorCode:   pharmacy.erxVendorCode,
                erxVendorId:     pharmacy.erxVendorId,
                fax:             pharmacy.fax,
                issuingBodyCode: pharmacy.issuingBodyCode,
                issuingBodyId:   pharmacy.issuingBodyId,
                latitude:        pharmacy.latitude,
                longitude:       pharmacy.longitude,
                name:            pharmacy.name,
                phone:           pharmacy.phone,
                types:           pharmacy.types
              }
            }
          })
          .then(pharmacy_response => {
          }).catch(e => {
            const errors = e.graphQLErrors.map(error => error.message);
        });

    }).catch(e => {
      const errors = e.graphQLErrors.map(error => error.message);
    });
  }

  handleSearchSubmit = (values, { props, setSubmitting }) => {
    this.setState({ searchResults: [] });

    const { searchParams } = values;
    const search = {
      ...parseCanadaPostalCode(searchParams),
      country: 'ca',
      radius:  parseInt(searchParams.radius, 10)
    };

    const { client } = this.props;

    client
      .query({
        query:     PHARMACY_SEARCH_QUERY,
        variables: { search }
      })
      .then(({ data: { searchPharmacy: searchResults = [] } = {} }) => {
        this.setState({ searchResults });
        setSubmitting(true);
      }).catch(e => {
        const errors = e.graphQLErrors.map(error => error.message);
        setSubmitting(false);
        props.setErrors({ form: errors });
      });
  }

  render() {
    const { selectedPharmacy, showModal, searchResults } = this.state;

    const {
      providerParams,
      pharmacy,
      memberParams,
      consultationParams,
      client,
      showChangeLink
    } = this.props;

    return (
      <div className="" role="form">
        { Object.keys(selectedPharmacy).length > 0
          ? (<PharmacyRenderer {...selectedPharmacy} />)
          : (<PharmacyRenderer {...pharmacy} />)}

        {
          !!showChangeLink && (<ChangePharmacy
            {...providerParams}
            onClickHandler={this.onChangeHandler}
          />)
        }

        {showModal && (
          <Modal
            show={this.state.showModal}
            onClose={this.hideModal}
          >
            <SearchPharmacyForm
              handleChange={this.onChangeHandler}
              stateProvinces={CANADIAN_PROVINCES}
              onFormSubmit={this.handleSearchSubmit}
            />

            { searchResults.length > 0 && (
              <PharmacyList
                searchResults={searchResults}
                onClickHandler={this.handlePharmacySelect}
              />)
            }
          </Modal>)
        }
      </div>
    );
  };
};

CanadaPharmacyContainer.propTypes = {
  client:             PropTypes.shape({}),
  providerParams:     PropTypes.shape({}),
  pharmacy:           PropTypes.shape({}),
  memberParams:       PropTypes.shape({}),
  consultationParams: PropTypes.shape({})
};

export default CanadaPharmacyContainer;
