import React from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from 'app/components/loading-indicator/loading-indicator';

const NetworkTestOngoing = ({ prolonged, finalizing, onCancelClick }) => {
  const canCancelTest = prolonged && !finalizing;

  return (
    <div className="network-test-centered">
      <h5 className="network-test-content network-test-header">Clocking in to Video and Phone</h5>
      {finalizing ? (
        <p className="network-test-content">
          We are processing the results. This will only take a few moments...
        </p>
      ) : (
        <p className="network-test-content">
          Please wait while we are testing your video settings to ensure a great video experience
          for our members.
        </p>
      )}
      <div className="network-test-loading">
        <LoadingIndicator />
      </div>
      <div
        id="network-test-cancel-section"
        style={{ visibility: canCancelTest ? 'visible' : 'hidden' }}
      >
        <p className="network-test-legend">Still working...</p>
        <div className="network-test-actions">
          <input
            type="button"
            value="Cancel Test and Clock in to Phone Only"
            onClick={onCancelClick}
          />
        </div>
      </div>
    </div>
  );
};

NetworkTestOngoing.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  prolonged: PropTypes.bool.isRequired,
  finalizing: PropTypes.bool.isRequired
};

export default NetworkTestOngoing;
