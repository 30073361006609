import React from 'react';
import PropTypes from 'prop-types';
import ReviewLineItem from './review-line-item';
import '../styles.scss';
import { snakeCase } from 'lodash';

const displayableSurescriptsMedication = medications => {
  let results = [];

  results = medications.reduce(((accum, GroupedMedications) => {
    GroupedMedications.forEach(prescription => {
      const index = accum.findIndex(item =>
        item.drugDescription === prescription.drugDescription ||
        item.clinicalMedicationId === prescription.clinicalMedicationId ||
        snakeCase(prescription.drugDescription) === snakeCase(item.drugDescription)
      );

      if (index === -1) {
        const medication = Object.assign({}, prescription, {
          drugName:           prescription.drugDescription,
          createdAt:          prescription.lastFillDate,
          drugQuantity:       prescription.getQuantity(),
          activelyTakingFlag: null
        });
        accum.push(medication);
      }
    });

    return accum;
  }), []);

  return results.filter(e => e.validForReview);
};

const SurescriptsMedicationReview = ({
   medications,
   responseOptions,
   addToPendingReviewList,
   pendingReviewList,
   updatePendingReviewList
  }) => {
  const surescriptsMeds = displayableSurescriptsMedication(medications);

  return (
    <div className="medication-review">
      <div className="medication-review__list">
        <div className="medication-review-list__header">
          <div className="medication-review-list__header--item">Drug Name</div>
          <ul className="medication-review-list__header--item-group">
            <li className="medication-review-list__header--item">Currently Taking</li>
            <li className="medication-review-list__header--item">Reported Date</li>
          </ul>
        </div>

        {
          surescriptsMeds.length > 0 ?
            (surescriptsMeds.map((medication, index) =>
              <ReviewLineItem
                key={`${medication.clinicalMedicationId}-${index}`}
                medication={medication}
                responseOptions={responseOptions}
                addToPendingReviewList={addToPendingReviewList}
                updatePendingReviewList={updatePendingReviewList}
                pendingReviewList={pendingReviewList}
                showQuantity={false}
              />
            )
          ) : (<p>No medication to review</p>)
        }
      </div>
    </div>
  );
};

SurescriptsMedicationReview.defaultProps = {
  responseOptions: ['Yes', 'No']
};

SurescriptsMedicationReview.propTypes = {
  medications:             PropTypes.array.isRequired,
  responseOptions:         PropTypes.array.isRequired,
  pendingReviewList:       PropTypes.array.isRequired,
  addToPendingReviewList:  PropTypes.func.isRequired,
  updatePendingReviewList: PropTypes.func.isRequired
};

export default SurescriptsMedicationReview;
