import React from 'react';
import { Query, ApolloConsumer } from 'react-apollo';
import { Modal } from 'medication_service_ui';
import DRUG_SEARCH_QUERY from './queries/drug-search.graphql';
import ACTIVE_CONSULTATION_QUERY from './queries/active-consultation-query.graphql';
import debounce from 'lodash/debounce';
import CanadaPrescriptionBuilder from './components/builder';
import CanadaProviderOrders from './components/provider-orders';

class CanadaPrescriptionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      term:                            '',
      selectedMedication:              null,
      isAddingPrescription:            false,
      showModal:                       false,
    };

    this.setMedication = this.setMedication.bind(this);
    this.onInputChange = debounce(this.onInputChange.bind(this), 150);
    this.onAddDecision = this.onAddDecision.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  onInputChange(term) {
    if (term.length < 2) { return; }
    this.setState({ term });
  }

  setMedication(selectedMedication) {
    this.setState({ selectedMedication });
  }

  onAddDecision({ target: { value } }) {
    const isAddingPrescription = value === 'yes';
    this.setState({ isAddingPrescription, showModal: isAddingPrescription });
  }

  hideModal() {
    this.setState({ showModal: false });
  }

  showModal() {
    this.setState({ showModal: true });
  }

  onSuccessfulAdditionHandler = () => {
    this.hideModal();
    this.setMedication(null);
  }

  render() {
    const { consultationId, allowAddition = true } = this.props;
    const {
      term,
      selectedMedication,
      isAddingPrescription,
      showModal,
    } = this.state;

    return (
      <Query query={ACTIVE_CONSULTATION_QUERY} variables={{ consultationId }}>
        {
        ({ data: { currentUserProvider: { consultation: { globalId, providerOrders = [] } = {} } = {} } }) => {
          const hasAddedPrescriptions = !!providerOrders.length;
          const yesChecked = (isAddingPrescription || hasAddedPrescriptions);

          return (
            <div>
              <div
                id="activate-add-prescription-modal"
                className="prescription-modal"
                style={{ marginBottom: '0.5rem' }}
              >
                <p>Are you adding a prescription?</p>
                <label className="radio">
                  <input
                    id="activate-add-prescription-modal-no"
                    name="isAddingPrescription"
                    type="radio"
                    value="no"
                    disabled={hasAddedPrescriptions}
                    checked={!yesChecked}
                    onChange={this.onAddDecision}
                  />No
                </label>
                <label className="radio">
                  <input
                    id="activate-add-prescription-modal-yes"
                    name="isAddingPrescription"
                    type="radio"
                    value="yes"
                    checked={yesChecked}
                    onChange={this.onAddDecision}
                  />Yes
                </label>

                { yesChecked && allowAddition &&
                  <a
                    onClick={this.showModal}
                    className="add-prescription-link"
                  >{ hasAddedPrescriptions ? 'Add another prescription' : 'Add a prescription' }</a>
                }
              </div>
              <CanadaProviderOrders providerOrders={providerOrders} />
              <Modal show={showModal} onClose={this.hideModal}>
                <Query query={DRUG_SEARCH_QUERY} variables={{ term, consultationId }} skip={!term.length}>
                  {
                    ({ loading, error, data: { searchRx: { medications = [] } = {} } = {} }) => (
                      <ApolloConsumer>
                        {client => (
                          <CanadaPrescriptionBuilder
                            onSuccessfulAdditionHandler={this.onSuccessfulAdditionHandler}
                            medications={medications}
                            consultationId={globalId}
                            loading={loading}
                            onInputChange={this.onInputChange}
                            onChange={this.setMedication}
                            selectedMedication={selectedMedication}
                            apolloClient={client}
                          />)}
                      </ApolloConsumer>
                    )
                  }
                </Query>
              </Modal>
            </div>
          );
        }
      }
      </Query>
    );
  }
}

export { CanadaPrescriptionContainer };
