import { get, flattenDeep } from 'lodash';
import store from 'app/lib/store';

// This is used to insert values into a json response from the v4 survey_serializer
// The structure will look smth like this:
// {
//    "title": "Test Survey",
//    "surveyCode": "SURVEY_TEST",
//    "sections": [
//        {
//            "sectionCode": "SECTION_1_TEST",
//            "questions": [
//                {
//                    "questionCode": "QUESTION_1_CHECKBOX_ARRAY",
//                    "choices": [
//                        {
//                            "sequence": 1,
//                            "answerCode": "CHOICE_1",
//                            "answer": "I feel fine."
//                        }
//                    ]
//                }
//            ]
//        }
//    ]
//
//  survey: the survey response to be modified
//  path: an array of the form [<sectionCode>, <questionCode>, <answerCode>] indicating where the attrKey
//  and attrVal should be inserted
//  attrKey: the key for the new attribute
//  attrVal: the attribute you want to insert
//
export const surveyInsert = (survey, path, attrKey, attrVal) => {
  let currentObj = survey;
  [
    ['sectionCode', 'sections'],
    ['questionCode', 'questions'],
    ['answerCode', 'choices']
  ].forEach((code, index) => {
    // This will evaluate to currentObject[sections].find(e => e[sectionCode] === 'someSectionCode' for
    // the first iteration and so on for subsequent ones
    currentObj = currentObj[code[1]].find(e => e[code[0]] === path[index]);
    if (path.length - 1 === index) {
      currentObj[attrKey] = attrVal;
      return;
    }
  });
};

export const getValidationErrorsByQuestionCode = questionCode => {
  const menuItems = get(store.getState(), 'consultNavigation.items', []);
  const mappedErrors = menuItems.map(({ errors = [] }) => errors || []);
  const errorsList = flattenDeep(mappedErrors);

  return errorsList.filter(validationError => get(validationError, 'validatorKey') === questionCode);
};
