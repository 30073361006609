import { connect } from 'react-redux';
import get from 'lodash/get';
import getProviderParams from '../../../Provider/selectors/getProviderParams';

import ProviderClockInOutStatusText from './provider-clock-in-out-status-text';

import getLocationType from '../../../Provider/selectors/getLocationType';
import getLocationText from '../../../Provider/selectors/getLocationText';
import getUseLocationForClockIn from '../../../Provider/selectors/getUseLocationForClockIn';
import getRequestMode from '../../../Provider/selectors/getRequestMode';

export const mapStateToProps = state => ({
  serviceSpecialty:      get(getProviderParams(state), ['serviceSpecialty']),
  locationType:          getLocationType(state),
  locationText:          getLocationText(state),
  useLocationForClockIn: getUseLocationForClockIn(state),
  requestMode:           getRequestMode(state)
});

export default connect(mapStateToProps)(ProviderClockInOutStatusText);
