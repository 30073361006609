import React from 'react';
import PropTypes from 'prop-types';

const AlertIcon = ({ width = 12, height = 12, fill = '#D0021C' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0C2.68548 0 0 2.70968 0 6C0 9.31452 2.68548 12 6 12C9.29032 12 12 9.31452 12 6C12 2.70968 9.29032 0 6 0ZM6 10.8387C3.31452 10.8387 1.16129 8.68548 1.16129 6C1.16129 3.33871 3.31452 1.16129 6 1.16129C8.66129 1.16129 10.8387 3.33871 10.8387 6C10.8387 8.68548 8.66129 10.8387 6 10.8387ZM7.01613 8.32258C7.01613 7.76613 6.55645 7.30645 6 7.30645C5.41935 7.30645 4.98387 7.76613 4.98387 8.32258C4.98387 8.90323 5.41935 9.33871 6 9.33871C6.55645 9.33871 7.01613 8.90323 7.01613 8.32258ZM5.03226 3.21774L5.20161 6.50806C5.20161 6.65323 5.34677 6.77419 5.49194 6.77419H6.48387C6.62903 6.77419 6.77419 6.65323 6.77419 6.50806L6.94355 3.21774C6.94355 3.04839 6.82258 2.90323 6.65323 2.90323H5.32258C5.15323 2.90323 5.03226 3.04839 5.03226 3.21774Z"
      fill={fill}
    />
  </svg>
);

AlertIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default AlertIcon;
