import { combineEpics } from 'redux-observable';
import { map } from 'rxjs/operators';
import { fetchOrigin$ } from '../../lib/utils';

import createRequestEpic from '../../lib/redux/utilities/create-request-epic';
import { FETCH_PATIENT_SYNC_ERRORS } from '../action-types';

export const fetchPatientSyncErrorsEpic = createRequestEpic(FETCH_PATIENT_SYNC_ERRORS, ({ action }) =>
  fetchOrigin$(`/consultations/${action.payload.consultationId}/check_for_patient_sync_errors`).pipe(
    map(ajaxResponse => ({
      data: {
        error: ajaxResponse.response.error
      }
    }))
  )
);

export default combineEpics(fetchPatientSyncErrorsEpic);
