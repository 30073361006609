import every from 'lodash/every';
import store from 'app/lib/store';
import { isViewedAllMentalHealthDocumentsSelector } from './redux/selectors';

const getCurrentConsultationId = () => window.consultation_id();

const isCurrentConsultationActive = () => $('#consult').data('active_consult') === true;

const getAuthorizationToken = () => $('meta[name=csrf-token]').attr('content');

const logConsultationEvent = (eventName, consultationId) => {
  if (!consultationId) return;

  $.ajax({
    type:     'POST',
    url:      `/consultations/${consultationId}/log_event`,
    data:     { event_name: eventName },
    dataType: 'json',
    headers:  { 'X-CSRF-Token': getAuthorizationToken() }
  });
};

const cacheEhrTabReviewState = () => {
  if (!isCurrentConsultationActive()) return;

  const consultationId = getCurrentConsultationId();

  if (!consultationId) return;

  const reviewState = $('#consult_form').serialize();

  $('#interview_required_checks').val('true');

  $.ajax({
    type:    'POST',
    url:     `/consultations/${consultationId}/cache_interview`,
    data:    reviewState,
    headers: { 'X-CSRF-Token': getAuthorizationToken() }
  });

  logConsultationEvent('EHR Review End', consultationId);

  if ($('#video_consult_ct').length < 1) logConsultationEvent('Conference Started', consultationId);
};

export const selectEhrConfirmReviewCheckbox = () => $('#ehr_confirm_review_checkbox').prop('checked', true);

export const selectAllscriptConfirmReviewCheckbox = () => $('#allscript_confirm_review_checkbox').prop('checked', true);

export const hideMentalHealthNote = () => $('#note-for-adolescent-mental-health-documents').hide();

export const hideLabTestsNote = () => $('#note-for-lab-tests').hide();

export const showLabTestsNote = () => $('#note-for-lab-tests').show();

const enableContinueButton = () => $('a.button.continue.start_consult').removeClass('continue_btn');

const disableContinueButton = () => $('a.button.continue.start_consult').addClass('continue_btn');

const isEhrConfirmReviewCheckboxPresent = () => $('#ehr_confirm_review_checkbox').length;

const isEhrConfirmReviewCheckboxReviewed = () => {
  if (!isEhrConfirmReviewCheckboxPresent()) return true;

  return $('#ehr_confirm_review_checkbox').prop('checked');
};

const isAllscriptConfirmReviewCheckboxPresent = () => $('#allscript_confirm_review_checkbox').length;

const isAllscriptConfirmReviewCheckboxReviewed = () => {
  if (!isAllscriptConfirmReviewCheckboxPresent()) return true;

  return $('#allscript_confirm_review_checkbox').prop('checked');
};

export const hasAdolescentDocumentsSection = () => $('#mh-minor').length;

export const hasCouplesTherapyDocumentsSection = () => $('#mh-couples-therapy').length;

export const isMentalHealthSectionPresent = () =>
  hasAdolescentDocumentsSection() || hasCouplesTherapyDocumentsSection();

export const isMentalHealthSectionReviewRequired = () => {
  if (!isMentalHealthSectionPresent()) return false;

  return $('#mh-minor').hasClass('review_required') || $('#mh-couples-therapy').hasClass('review_required');
};

export const mentalHealthPatientType = () => (hasCouplesTherapyDocumentsSection() ? 'couple' : 'minor');

export const isMentalHealthNotePresent = () =>
  $('#note-for-adolescent-mental-health-documents').length ||
  $('#note-for-couples-therapy-mental-health-documents').length;

export const isMentalHealthSectionReviewed = () => {
  if (!isMentalHealthSectionPresent()) return true;
  if (!isMentalHealthSectionReviewRequired()) return true;

  return isViewedAllMentalHealthDocumentsSelector(store.getState());
};

export const isLabTestsSectionPresent = () => $('#tests').length;

export const isLabTestsSectionReviewRequired = () => {
  if (!isLabTestsSectionPresent()) return false;

  return $('#tests').hasClass('review_required');
};

export const isLabTestsNotePresent = () => $('#note-for-lab-tests').length;

const isLabTestsNoteHidden = () => isLabTestsNotePresent() && $('#note-for-lab-tests').is(':hidden');

export const isAnyLabTestCheckboxPresent = () => $('.lab_results_review_cb').length;

export const areAllLabTestCheckboxesHidden = () =>
  isAnyLabTestCheckboxPresent() && every($('.lab_results_review_cb'), checkbox => $(checkbox).is(':hidden'));

export const isLabTestsSectionReviewed = () => {
  if (!isLabTestsSectionPresent()) return true;
  if (!isLabTestsSectionReviewRequired()) return true;
  if (!isLabTestsNotePresent()) return true;
  if (!isAnyLabTestCheckboxPresent()) return true;

  return isLabTestsNoteHidden() && areAllLabTestCheckboxesHidden();
};

export const isEhrTabReviewed = () => {
  const reviewStates = [
    isEhrConfirmReviewCheckboxReviewed(),
    isAllscriptConfirmReviewCheckboxReviewed(),
    isMentalHealthSectionReviewed(),
    isLabTestsSectionReviewed()
  ];

  // If every review state is truthy then the whole EHR tab is reviewed.
  return every(reviewStates, state => Boolean(state) === true);
};

export const wasEhrTabReviewedBefore = () =>
  $('#interview_started').val() === 'true' || $('#interview_required_checks').val() === 'true';

export const tryToggleContinueButton = () => {
  if (isEhrTabReviewed()) {
    cacheEhrTabReviewState();

    enableContinueButton();
  } else {
    disableContinueButton();
  }
};
