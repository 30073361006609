import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WebSocket from '../../components/phoenixWebsocket';
import { updateCallStatus } from '../actions';

class ConferenceWebsocket extends Component {
  static propTypes = {
    endpoint:         PropTypes.string,
    uuid:             PropTypes.string,
    token:            PropTypes.string,
    updateCallStatus: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props);
    this.state = this.updateConferenceState(props);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.endpoint && !this.state.socket.endpoint) {
      this.setState(this.updateConferenceState(nextProps));
    }
  }

  updateConferenceState = ({ endpoint, uuid, token }) => ({
    socket: {
      endpoint,
      params: { guardian_token: token }
    },
    channel: {
      topic:        `conferences:${uuid}`,
      events:       ['call_status_event'],
      pushCallback: this.handlePush.bind(this)
    }
  })

  handlePush = (event) =>
    this.props.updateCallStatus(event);

  render = () => (
    this.props.token ? <WebSocket {...this.state} /> : <div />
  )
}

export default connect(
  ({ conference }) => ({
    endpoint: conference.videoWebsocketUrl,
    uuid:     conference.uuid,
    token:    conference.token
  }),
  { updateCallStatus }
)(ConferenceWebsocket);
