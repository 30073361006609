import { createSelector } from '@td/utils';

import getChiefComplaintClassSymptoms from './getChiefComplaintClassSymptoms';
import getAddedSymptomIds from './getAddedSymptomIds';
import getSymptomsById from './getSymptomsById';

const getAddedAssociatedSymptoms = createSelector(
  getAddedSymptomIds,
  getSymptomsById,
  (addedSymptomsIds, symptomsById) =>
    addedSymptomsIds.map(symptom => symptomsById[symptom])
);

export default createSelector(
  getChiefComplaintClassSymptoms,
  getAddedAssociatedSymptoms,
  (chiefComplaintSymptoms, addedSymptoms) => [...chiefComplaintSymptoms, ...addedSymptoms]
);
