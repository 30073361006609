import React from 'react';
import { connect } from 'react-redux';

import ProviderClockInLocation from './provider-clock-in-location';
import getLocationType from '../../../Provider/selectors/getLocationType';
import getLocationText from '../../../Provider/selectors/getLocationText';
import getLocationList from '../../../Provider/selectors/getLocationList';
import getRequestClockInType from '../../../Provider/selectors/getRequestClockInType';

import { clockInProviderInit, disableRequestMode } from '../actions';

const mapStateToProps = state => ({
  locationType:       getLocationType(state),
  locationText:       getLocationText(state),
  locationList:       getLocationList(state),
  requestClockInType: getRequestClockInType(state)
});

const mapDispatchToProps = dispatch => ({
  onProviderClockInButtonClick: (clockInType, currentLocationType, currentLocationText) => {
    dispatch(clockInProviderInit(clockInType, currentLocationType, currentLocationText));
  },
  onCancelClick: () => {
    dispatch(disableRequestMode());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderClockInLocation);
