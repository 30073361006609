import React from 'react';
import PropTypes from 'prop-types';

const isEmrNewMedicationEnabled = window.FEATURE_TOGGLES.emr_new_medication;

const PendingMedicationItem = ({ medication, removeHandler, editHandler }) => {
  const removeHandlerFunc = () => {
    removeHandler(medication);
  };

  const editHandlerFunc = () => {
    editHandler(medication);
  };

  return (
    <tr>
      <td>{medication.drugName}</td>
      {!isEmrNewMedicationEnabled && (
        <td>
          {medication.drugQuantity} {medication.drugQuantityUnit}
        </td>
      )}
      <td>{medication.drugFrequency}</td>
      <td>{medication.activelyTakingFlag === 'Y' ? 'Current' : 'Past'}</td>
      <td>
        <ul className="horizontal-list">
          <li>
            <a href="javascript:void(0)" className="edit" onClick={editHandlerFunc}>
              Edit
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" className="delete" onClick={removeHandlerFunc}>
              Remove
            </a>
          </li>
        </ul>
      </td>
    </tr>
  );
};

PendingMedicationItem.propTypes = {
  medication:    PropTypes.object.isRequired,
  removeHandler: PropTypes.func.isRequired,
  editHandler:   PropTypes.func.isRequired
};

export default PendingMedicationItem;
