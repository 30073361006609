import React, { useState, useRef, useEffect, useContext } from 'react';
import getClientInstance from '../services/apollo-client';
import CONSULTATION_CONFERENCE_STATUS_QUERY from './queries/consultation-conference-status.graphql';
import { isEqual } from 'lodash';

const initialState = {
  status:    '',
  startedAt: '',
  endedAt:   '',
  attendees: []
};

const CallStatusContext = React.createContext(initialState);

const statusSelector = ({ consultation: { consultationConferenceStatus: status } }) => status;

const CallStatusProvider = ({ consultationId, children }) => {
  const POLLING_TIMER = 15000;
  let pollingTimeout;

  const apolloClient = getClientInstance({
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  });

  const [status, setStatus] = useState(initialState);
  const statusRef = useRef(status);

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => {
    let authErrorCount = 0;

    const fetchStatus = async () => {
      try {
        const { data, errors } = await apolloClient.query({
          query: CONSULTATION_CONFERENCE_STATUS_QUERY,
          variables: { consultationId }
        });

        const status = statusSelector(data);

        if (!isEqual(statusRef.current, status)) {
          setStatus(status);
        }

        authErrorCount = 0;
        pollingTimeout = setTimeout(fetchStatus, POLLING_TIMER);
      } catch (error) {
        if (error.message && error.message.includes('Unauthorized')) {
          authErrorCount += 1;

          console.error('GraphQL Auth failed');

          if (authErrorCount < 3) {
            pollingTimeout = setTimeout(fetchStatus, POLLING_TIMER);
          }
        } else {
          pollingTimeout = setTimeout(fetchStatus, POLLING_TIMER);
        }
      }
    };

    fetchStatus();

    return () => { if (pollingTimeout) clearTimeout(pollingTimeout); }
  }, []);

  return <CallStatusContext.Provider value={status}>{children}</CallStatusContext.Provider>;
};

export const useCallStatus = () => useContext(CallStatusContext);

export default CallStatusProvider;
