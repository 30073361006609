import { CREATE_NOTIFICATION } from './action-types';

const DEFAULT_STATE = {
  current: null
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CREATE_NOTIFICATION: {
      const { message, type, options = {} } = action.payload;

      return {
        ...state,
        current: {
          message,
          type,
          options
        }
      };
    }

    default: {
      return state;
    }
  }
};

export { DEFAULT_STATE };
