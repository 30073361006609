import React from 'react';
import PropTypes from 'prop-types';

const DefaultAlertButton = ({ closeAlert, buttonText }) => (
  <button onClick={closeAlert}>{buttonText}</button>
);

DefaultAlertButton.defaultProps = {
  buttonText: 'Ok'
};

DefaultAlertButton.propTypes = {
  closeAlert: PropTypes.func.isRequired,
  buttonText: PropTypes.string
};

export default DefaultAlertButton;
