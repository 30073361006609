import React from 'react';
import PropTypes from 'prop-types';

const Pharmacy = ({
  name,
  addressLine1,
  addressCity,
  addressState,
  phone }) => (
  <div className="pharmacy-card">
    <div className="pharmacy-card__icon" />
    <div className="pharmacy-card__details">
      <h4 className="pharmacy-card__details-name">
        {name}
      </h4>
      <div className="pharmacy-card__details-address-line-1">
        {addressLine1}</div>
      <div className="pharmacy-card__details-address-line-2">
        {addressCity}, {addressState}
      </div>
      <div className="pharmacy-card__details-address-line-3">
        {phone}
      </div>
    </div>
  </div>
);


Pharmacy.propTypes = {
  name: PropTypes.string.isRequired,
  addressLine1: PropTypes.string.isRequired,
  addressCity: PropTypes.string.isRequired,
  addressState: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired
};

export default Pharmacy;
