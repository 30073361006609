import { get } from 'lodash';
import { translate } from '@td/shared_utils';
import store from 'app/lib/store';
import {
  addWarningsToConsultNavigationStep,
  clearWarningFromConsultNavigationStep
} from 'app/consult-navigation/actions';
import { WELLNESS_REASONS, WELLNESS_DIAGNOSES, DX_NOT_MATCH_REASON_WARNING } from '../constants';

const isDxMatchReason = (selectedDx, requestReason) => {
  const isReasonWellness = WELLNESS_REASONS.includes(requestReason);
  const isWellnessDxSelected = selectedDx.some(dx => WELLNESS_DIAGNOSES.includes(dx.diagnosisCode));
  return isReasonWellness === isWellnessDxSelected;
};

export const checkIfDxMatchReason = (selectedNavIndex, selectedDx) => {
  const requestReason = get(store.getState(), 'settings.consultationParams.requestReason', null);

  if (!requestReason || !selectedDx.length || isDxMatchReason(selectedDx, requestReason)) {
    const warnings = get(store.getState(), `consultNavigation.items[${selectedNavIndex}].warnings`, null) || [];
    const isWarning = warnings.some(warning => warning.key === DX_NOT_MATCH_REASON_WARNING);

    isWarning && store.dispatch(clearWarningFromConsultNavigationStep(selectedNavIndex, DX_NOT_MATCH_REASON_WARNING));
  } else {
    store.dispatch(
      addWarningsToConsultNavigationStep(selectedNavIndex, {
        key:   DX_NOT_MATCH_REASON_WARNING,
        value: translate(null, 'quick_dx', 'dx_not_match_reason_warning', { requestReason })
      })
    );
  }
};
