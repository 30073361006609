import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@td/shared_utils';
import TeladocModal from '../../TeladocModal';

// Styles
import '../styles/custom-buffer.scss';

// Constants
const TRANSLATION_SCOPE = 'my_schedule.custom_buffer.explanation_modal';

const PostConsultBufferModal = ({ onModalClose, onSubmit }) => {
  const paragraphs = translate(null, TRANSLATION_SCOPE, 'paragraphs') || {};

  return (
    <TeladocModal
      className="postConsultBufferModal"
      title={translate(null, TRANSLATION_SCOPE, 'title')}
      isOpen
      onClose={onModalClose}
    >
      <div className="paragraphsSection">
        {Object.entries(paragraphs).map(([pKey, pText]) => (
          <p key={pKey}>{pText}</p>
        ))}
      </div>
      <div className="actionButtons">
        <div className="regularActions">
          <button id="confirmButton" className="button" onClick={onSubmit}>
            {translate(null, TRANSLATION_SCOPE, 'button')}
          </button>
        </div>
      </div>
    </TeladocModal>
  );
};

PostConsultBufferModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onSubmit:     PropTypes.func.isRequired
};

export default PostConsultBufferModal;
