import _ from 'lodash';
import $ from 'jquery';

export const useCachedDiagnosis = () => {
  const secondaryDiagnosis = $('#quick_dx_root_container').data('cached-secondary-diagnoses') || [];
  const consultFormCachedData = $('#consult_form').data('initial-values');

  // After cashing retrival secondary diagnosis array of object looks like this:
  // {
  //   "code": "E09.3211",
  //   "name": "Drug or chemical induced diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, right eye",
  //   "id": "86740",
  //   "flag": "false",
  //   "is_quick_dx": "true"
  // }

  const primary = {
    active:               true,
    isQuickDx:            _.get(consultFormCachedData, 'primary_medical_diagnosis_is_quick_dx', false) === 'true',
    diagnosisName:        _.get(consultFormCachedData, 'primary_medical_diagnosis_name', ''),
    diagnosisCode:        _.get(consultFormCachedData, 'primary_medical_diagnosis_code', false),
    diagnosisCodeId:      _.get(consultFormCachedData, 'primary_medical_diagnosis_id', null),
    isSecondaryDiagnosis:
      _.get(consultFormCachedData, 'primary_medical_diagnosis_secondary_diagnosis_flag', false) === 'true'
  };

  return {
    primary:   primary.diagnosisCodeId ? primary : null,
    secondary: secondaryDiagnosis
      .filter(({ id }) => Boolean(id))
      .map(({ flag, code, id, is_quick_dx: isQuickDx, name }) => ({
        active:               true,
        isSecondaryDiagnosis: flag === 'true',
        isQuickDx:            isQuickDx === 'true',
        diagnosisCode:        code,
        diagnosisCodeId:      id,
        diagnosisName:        name
      }))
  };
};
