import React from 'react';
import { connect } from 'react-redux';
import { ehrIsLoadedSelector, isViewedAllMentalHealthDocumentsSelector } from '../../redux/selectors';
import EHRConfirmReviewCheckbox from './components/ehr-confirm-review-checkbox';
import AllscriptConfirmReviewCheckbox from './components/allscript-confirm-review-checkbox';
import EHRButtonContinue from './components/ehr-button-continue';
import EHRNoteForLabTests from './components/ehr-note-for-lab-tests';
import EHRNoteForAdolescentMentalHealthDocuments from './components/ehr-note-for-adolescent-mental-health-documents';
import EHRNoteForCouplesTherapyMentalHealthDocuments from './components/ehr-note-for-couples-therapy-mental-health-documents';

function confirmReviewCheckboxes(shouldDisplayAllscriptCheckboxes) {
  if (shouldDisplayAllscriptCheckboxes === 'true') {
    return (
      <div>
        <EHRConfirmReviewCheckbox i18nKey="allscript_review_confirmed_first_checkbox" />
        <AllscriptConfirmReviewCheckbox />
      </div>
    );
  } else {
    return <EHRConfirmReviewCheckbox i18nKey="review_confirmed" />;
  }
}

const EHRNavigationButtons = ({
  consultationId,
  shouldDisplayAllscriptCheckboxes,
  ehrIsLoaded,
  isViewedAllMentalHealthDocuments
}) => {
  if (!ehrIsLoaded) return null;

  return (
    <React.Fragment>
      <div id="pre-consult">
        <div className="buttons" id="prov-nav">
          <div id="navigation-buttons">
            <EHRNoteForLabTests />
            <EHRNoteForAdolescentMentalHealthDocuments
              isViewedAllMentalHealthDocuments={isViewedAllMentalHealthDocuments}
            />
            <EHRNoteForCouplesTherapyMentalHealthDocuments
              isViewedAllMentalHealthDocuments={isViewedAllMentalHealthDocuments}
            />
            {confirmReviewCheckboxes(shouldDisplayAllscriptCheckboxes)}
            <EHRButtonContinue consultationId={consultationId} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  ehrIsLoaded:                      ehrIsLoadedSelector(state),
  isViewedAllMentalHealthDocuments: isViewedAllMentalHealthDocumentsSelector(state)
});

export default connect(mapStateToProps)(EHRNavigationButtons);
