import { connect } from 'react-redux';

import { caFormMainInquiryChange, updateClinicalAlertsActivatedFlag } from 'app/subjective-tab/actions';
import { mapInlineErrors } from 'app/consultation-interview/utils';
import ClinicalAlerts from './clinical-alerts';

const mapStateToProps = state => ({
  mainInquiry:      state.clinicalAlertForm.mainInquiry,
  consultationId:   state.settings.consultationParams.consultationId,
  validationErrors: mapInlineErrors(state)
});

const mapDispatchToProps = {
  caFormMainInquiryChange,
  updateClinicalAlertsActivatedFlag
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalAlerts);
