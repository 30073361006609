import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CancelRxModalInfo from './cancel-rx-modal-info';
import { ConfirmModal } from '@teladoc/inbox';
import RevokeOrderForm from './revoke-order-form';
import Modal from '../../components/Modal';
import tableActionMenu from '../../assets/images/table-action-menu.svg';
import '../styles.scss';

const revokedStates = ['Revoked', 'eRx Cancel Pending', 'eRx Cancel Approved', 'eRx Cancel Declined'];

class ActionsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revoking:        false,
      showActionsMenu: false,
      openCancelModal: false,
      revoked:         false,
      revokeModalOpen: false
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.revokePending && this.state.revoking) {
      if (nextProps.revokeSuccess) {
        this.setState({ revoked: true });
      }

      this.setState({ revoking: false });
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ showActionsMenu: false });
    }
  }

  deactivateRevokeModal = () => {
    this.setState({ revokeModalOpen: false });
  };

  openRevokeConfirmation = async event => {
    const { canDoCancelRx } = this.props;

    const {
      providerOrder: { manualRx }
    } = this.props;

    if (canDoCancelRx && !manualRx) {
      this.setState({ openCancelModal: true });
    } else {
      this.setState({ revokeModalOpen: true });
    }
  };

  revokeHandler = ({ internalNote, externalNote }) => {
    this.setState({ revoking: true });

    this.deactivateRevokeModal();

    const {
      consultationParams: { consultationId },
      providerOrder: { providerOrderId }
    } = this.props;

    this.props.revokeHandlerFunc({
      consultationId,
      providerOrderId,
      internalNote,
      externalNote
    });
  };

  revokeButtonLabel = (inRevokedState, inRevokingState) => {
    const { canDoCancelRx } = this.props;

    const {
      providerOrder: { manualRx }
    } = this.props;

    const labelStates = {
      ss4: {
        active:     'Revoke',
        completed:  'Revoked',
        inProgress: 'Revoking'
      },
      ss6: {
        active:     'Cancel',
        completed:  'Cancelled',
        inProgress: 'Cancelling'
      }
    };
    const versionToUse = canDoCancelRx && !manualRx ? 'ss6' : 'ss4';

    let label = labelStates[versionToUse].active;

    if (inRevokedState) {
      label = labelStates[versionToUse].completed;
    } else if (inRevokingState) {
      label = labelStates[versionToUse].inProgress;
    }

    return label;
  };

  buildPatientParams = () => {
    const {
      consultationParams: { phoneNumber },
      memberParams: { memberId, memberDoB, memberName, memberGender, memberHeight, memberWeight, memberAddress }
    } = this.props;

    return {
      id:      memberId,
      name:    memberName,
      gender:  memberGender,
      phone:   phoneNumber,
      address: memberAddress,
      dob:     memberDoB,
      height:  memberHeight,
      weight:  memberWeight
    };
  };

  buildPrescriptionParams = () => {
    const { providerOrder } = this.props;

    return {
      name:    providerOrder.drugName,
      sig:     providerOrder.instructions,
      notes:   providerOrder.pharmacyNotes,
      refills: providerOrder.refills
    };
  };

  buildProviderParams = () => {
    const {
      providerParams: { firstNm, lastNm, providerAddress }
    } = this.props;

    return {
      name:    `${firstNm} ${lastNm}`,
      address: providerAddress
    };
  };

  buildPharmacyParams = () => {
    const {
      pharmacyParams: { addressLine1, pharmacyNm, phone }
    } = this.props;

    return {
      name:    pharmacyNm,
      address: addressLine1,
      phone:   `+${phone.countryCd} (${phone.areaCode}) ${phone.phoneNumber.slice(0, 3)}-${phone.phoneNumber.slice(3)}`
    };
  };

  render() {
    const {
      providerOrder: { status, providerOrderId },
      consultationParams: { consultationId },
      providerOrder
    } = this.props;
    const { revoking, openCancelModal } = this.state;

    const revoked = revokedStates.includes(status) || this.state.revoked;
    const revokeLabel = this.revokeButtonLabel(revoked, revoking);

    const cancelRxModalConfig = {
      patient:      this.buildPatientParams(),
      prescription: this.buildPrescriptionParams(),
      prescriber:   this.buildProviderParams(),
      pharmacy:     this.buildPharmacyParams()
    };

    const confirmDialog = {
      headerText:       'Are you sure you want to cancel this prescription?',
      content:          <CancelRxModalInfo {...cancelRxModalConfig} />,
      confirmationText: <h2>Confirm you want to cancel this prescription</h2>,
      confirmText:      'Yes',
      cancelText:       'No',
      additionalClass:  'cancel-rx-dialog',
      onConfirm:        () => {
        this.setState({ revoking: true, openCancelModal: false });

        this.props.revokeHandlerFunc({
          consultationId,
          providerOrderId
        });
      },
      onCancel: () => {
        this.setState({ revoking: false, openCancelModal: false });
      }
    };

    return (
      <React.Fragment>
        <td>
          <div className="actions-menu">
            <button
              onClick={() => this.setState({ showActionsMenu: !this.state.showActionsMenu })}
              className="actions-menu"
            >
              <img src={tableActionMenu} />
            </button>

            {this.state.showActionsMenu && (
              <div id="table-actions-menu" className="menu-content" ref={this.wrapperRef}>
                <button onClick={this.openRevokeConfirmation} disabled={revoked || revoking} className="action-item">
                  {revokeLabel}
                </button>
              </div>
            )}
          </div>
        </td>

        {this.state.revokeModalOpen && (
          <Modal show={this.state.revokeModalOpen} onClose={this.deactivateRevokeModal} className="revoke-order-modal">
            <RevokeOrderForm drugName={providerOrder.drugName} onSubmit={this.revokeHandler} />
          </Modal>
        )}

        {openCancelModal && <ConfirmModal {...confirmDialog} />}
      </React.Fragment>
    );
  }
}

ActionsMenu.propTypes = {
  providerOrder:      PropTypes.object.isRequired,
  canDoCancelRx:      PropTypes.bool,
  consultationParams: PropTypes.shape({
    consultationId: PropTypes.number.isRequired
  }).isRequired,
  revokeHandlerFunc: PropTypes.func.isRequired,
  providerParams:    PropTypes.shape({
    providerCanRx:   PropTypes.bool.isRequired,
    firstNm:         PropTypes.string,
    lastNm:          PropTypes.string,
    providerAddress: PropTypes.string
  }).isRequired,
  memberParams: PropTypes.shape({
    memberId:      PropTypes.number,
    memberName:    PropTypes.string,
    memberGender:  PropTypes.string,
    phoneNumber:   PropTypes.string,
    memberAddress: PropTypes.string,
    memberDoB:     PropTypes.string,
    memberHeight:  PropTypes.string,
    memberWeight:  PropTypes.string
  }).isRequired,
  pharmacyParams: PropTypes.shape({
    pharmacyNm:   PropTypes.string,
    addressLine1: PropTypes.string,
    phone:        PropTypes.object
  }).isRequired,
  revokeSuccess: PropTypes.bool.isRequired
};

export default ActionsMenu;
