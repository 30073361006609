import React from 'react';

class AvailabilityNotification extends React.Component {
	constructor(props) {
    super();
		this.state = {
      isOpen: props.isOpen,
		}
  }

  render() {
    const { isOpen } = this.state;
    const showAvailabilityNotification = this.showAvailabilityNotification()

    return (
      <div className="availability-notification-component">
        {isOpen && showAvailabilityNotification ? (
          <div className='availability-notification-wrapper'>
            <div className='availability-notification-notice'>
              <p className='availability-notification-text'>Updating your availability makes you more visible for consult bookings.</p>
              <a className='availability-notification-update-button' href="/my_account#preferred-availability">Update</a>
              <button className='availability-notification-close' onClick={this.handleClose}>
                <span className="availability-notification-close-icon"></span>
              </button>
            </div>
          </div>
        ) : null }
      </div>
    );
  }

  handleClose = () =>  {
    window.sessionStorage.setItem('show_availability_notification', false);
    this.setState({ isOpen: false });
  }

  showAvailabilityNotification = () => {
    const serializedState =  window.sessionStorage.getItem('show_availability_notification');

    if (serializedState === null) {
      return true;
    }

    return JSON.parse(serializedState);
  };
};
export default AvailabilityNotification;

