import React from 'react';
import './StatusTooltipContent.scss';
import { STATUS_MAP, STATUS_DEFINITION } from '../constants';

const statuses = [
  { key: 'CARETEAMRLTNSTATUS_NEW', definition: 'NEW' },
  { key: 'CARETEAMRLTNSTATUS_NEWTOYOU', definition: 'NEW_TO_YOU' },
  { key: 'CARETEAMRLTNSTATUS_ESTABLISHED', definition: 'ESTABLISHED' },
  { key: 'CARETEAMRLTNSTATUS_INACTIVE', definition: 'INACTIVE' },
  { key: 'CARETEAMRLTNSTATUS_TERMINATED', definition: 'TERMINATED' }
];

const StatusTooltipContent = () => {
  return (
    <div className="status-definition-tooltip-container">
      {statuses.map(({ key, definition }) => (
        <div key={key} className="status-definition-tooltip-wrapper">
          <span className="status">{STATUS_MAP[key]}:</span>
          <span className="status-definition"> {STATUS_DEFINITION[definition]}</span>
        </div>
      ))}
    </div>
  );
};

export default StatusTooltipContent;
