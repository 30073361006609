import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  CONFERENCE_NOT_STARTED,
  CONFERENCE_NEEDS_CONFIRMATION,
  CONFERENCE_IN_PROGRESS
} from '../lib/states';

import {
  startConference as start,
  conferenceConfirmationAccept as accept,
  selectNotConnectedToConference as reject,
  addParticipant,
  callParticipant
} from '../actions';

import VendorControls, { isVendorAdded } from './vendorControls';

export const ConferenceControlsContainer = props => {
  const control = (conferenceStatus => {
    switch (conferenceStatus) {
      case CONFERENCE_NOT_STARTED:
        return <StartConferenceButton onClick={props.start} />;
      case CONFERENCE_NEEDS_CONFIRMATION:
        return <ConferenceConfirmation onReject={props.reject} attempts={props.attempts} />;
      case CONFERENCE_IN_PROGRESS:
        // Disable vendor controls once any vendor has been added as a participant
        const vendorAdded = isVendorAdded(props.participants);
        return (
          <VendorControls
            consultationId={props.consultationId}
            conferenceId={props.conferenceId}
            warmTransferVendors={props.warmTransferVendors}
            disabled={vendorAdded}
            addParticipant={props.addParticipant}
            callParticipant={props.callParticipant}
          />
        );
      default:
        return null;
    }
  })(props.status);

  return <div className="conference-controls">{control}</div>;
};

ConferenceControlsContainer.propTypes = {
  participants: PropTypes.array.isRequired,
  status:       PropTypes.string.isRequired,
  id:           PropTypes.number,
  start:        PropTypes.func.isRequired,
  accept:       PropTypes.func.isRequired,
  reject:       PropTypes.func.isRequired,
  attempts:     PropTypes.number.isRequired
};

// Conference Confirmation Page
export class ConferenceConfirmation extends Component {
  static propTypes = {
    onReject: PropTypes.func.isRequired,
    attempts: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { warningDismissed: false };
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.attempts !== nextProps.attempts && this.state.warningDismissed) {
      this.setState({ warningDismissed: false });
    }
  };

  onDismiss = () => this.setState({ warningDismissed: true });

  renderWarning = () => {
    if (this.props.attempts <= 1 || this.state.warningDismissed) {
      return null;
    }

    return (
      <div className="conference-confirmation-warning">
        <p>
          If you have made several attempts and have not received a call, please either use the
          conferencing on your phone or contact the Orange Team and they will provide you with a
          conference line.
        </p>
        <button onClick={this.onDismiss}>Ok</button>
      </div>
    );
  };

  render = () => (
    <div className="conference-confirmation-inner">
      <div className="conference-confirmation-info">
        <h1>Calling you now...</h1>
        <h2>Please wait a few moments</h2>
      </div>
      <div className="conference-confirmation-actions">
        <button className="reject" onClick={this.props.onReject}>
          I have not received a call.
        </button>
      </div>
      {this.renderWarning()}
    </div>
  );
}

// Start Conference Button
export const StartConferenceButton = ({ onClick }) => (
  <div className="provider-instructions">
    <p>
      Click <span>START CONFERENCE</span> when ready and our conference service will call you to
      connect you in. Be patient. It may take up to two minutes to connect.
    </p>
    <button className="conference-controls start-conference" onClick={onClick}>
      Start Conference
    </button>
  </div>
);

StartConferenceButton.propTypes = { onClick: PropTypes.func.isRequired };

export default connect(
  ({ conference }) => ({
    participants:        conference.conferenceParticipants,
    status:              conference.status,
    id:                  conference.id,
    attempts:            conference.conferenceStartAttempts,
    conferenceId:        conference.id,
    warmTransferVendors: conference.warmTransferVendors
  }),
  { start, accept, reject, addParticipant, callParticipant }
)(ConferenceControlsContainer);
