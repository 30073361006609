import React from 'react';
import {ApolloProvider} from '@apollo/client';
import CallStatusProvider from '../../call-details/CallStatusProvider';
import ParticipantsProvider from '../../call-details/ParticipantsProvider';
import {KeepAliveProvider} from '../../keep-alive/keep-alive-provider';
import getClientInstance from "../../services/apollo-client";
import Video from './video';
import tdPipWindow from '../../lib/td-pip-window';

const VideoInitializer = ({consultationId, consultation, ...props}) => {
  const isMentalHealth = consultation.specialtyCd === 'BEHAVHEALTH';
  const canUsePIP = tdPipWindow.canUsePictureInPicture();

  return (
    <ApolloProvider client={getClientInstance()}>
      <CallStatusProvider consultationId={consultationId}>
        <ParticipantsProvider consultationId={consultationId}>
          <KeepAliveProvider>
            <Video
              consultationId={consultationId}
              consultation={consultation}
              isMentalHealth={isMentalHealth}
              canUsePIP={canUsePIP}
              {...props}
            />
          </KeepAliveProvider>
        </ParticipantsProvider>
      </CallStatusProvider>
    </ApolloProvider>
  )
};

export default VideoInitializer;
