import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const BannersContainer = React.memo(({ children }) => (
  <div
    data-testid="banners-container"
    className={styles.bannersContainer}
  >
    {children}
  </div>
));

BannersContainer.displayName = 'BannersContainer';
BannersContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default BannersContainer;
