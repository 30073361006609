import {
  SURVEY_ANSWER_CHANGE
} from 'app/surveys/action-types/survey-action-types';
import { merge } from 'lodash';

const surveyReducer = (state = {}, action) => {
  switch (action.type) {
    case SURVEY_ANSWER_CHANGE:
      const surveys = merge({}, state, action.payload);
      return { ...state, ...surveys };
    default:
      return state;
  }
};

export default surveyReducer;
