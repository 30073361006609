// Constants
const FETCH_PROVIDER_ORDER_REQUEST  = 'FETCH_PROVIDER_ORDER_REQUEST';
const FETCH_PROVIDER_ORDER_START    = 'FETCH_PROVIDER_ORDER_START';
const FETCH_PROVIDER_ORDER_SUCCESS  = 'FETCH_PROVIDER_ORDER_SUCCESS';
const FETCH_PROVIDER_ORDER_FAIL     = 'FETCH_PROVIDER_ORDER_FAIL';
const FETCH_PROVIDER_ORDER_CANCEL   = 'FETCH_PROVIDER_ORDER_CANCEL';
const UPDATE_PROVIDER_ORDER_SUCCESS = 'UPDATE_PROVIDER_ORDER_SUCCESS';
const UPDATE_PROVIDER_ORDER_FAIL    = 'UPDATE_PROVIDER_ORDER_FAIL';
const DELETE_PROVIDER_ORDER         = 'DELETE_PROVIDER_ORDER';
const DELETE_PROVIDER_ORDER_SUCCESS = 'DELETE_PROVIDER_ORDER_SUCCESS';
const DELETE_PROVIDER_ORDER_FAIL    = 'DELETE_PROVIDER_ORDER_FAIL';
const REVOKE_PROVIDER_ORDER_REQUEST = 'REVOKE_PROVIDER_ORDER_REQUEST';
const REVOKE_PROVIDER_ORDER_START   = 'REVOKE_PROVIDER_ORDER_START';
const REVOKE_PROVIDER_ORDER_SUCCESS = 'REVOKE_PROVIDER_ORDER_SUCCESS';
const REVOKE_PROVIDER_ORDER_FAIL    = 'REVOKE_PROVIDER_ORDER_FAIL';

export {
  FETCH_PROVIDER_ORDER_REQUEST,
  FETCH_PROVIDER_ORDER_START,
  FETCH_PROVIDER_ORDER_SUCCESS,
  FETCH_PROVIDER_ORDER_CANCEL,
  FETCH_PROVIDER_ORDER_FAIL,
  UPDATE_PROVIDER_ORDER_SUCCESS,
  UPDATE_PROVIDER_ORDER_FAIL,
  DELETE_PROVIDER_ORDER,
  DELETE_PROVIDER_ORDER_SUCCESS,
  DELETE_PROVIDER_ORDER_FAIL,
  REVOKE_PROVIDER_ORDER_REQUEST,
  REVOKE_PROVIDER_ORDER_START,
  REVOKE_PROVIDER_ORDER_SUCCESS,
  REVOKE_PROVIDER_ORDER_FAIL
};
