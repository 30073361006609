import { combineReducers } from 'redux';

import providerClockInRequest from './provider-clock-in-request-reducer';
import providerClockOutRequest from './provider-clock-out-request-reducer';
import providerClockInOutSettings from './provider-clock-in-out-settings-reducer';

export default combineReducers({
  clockInRequest: providerClockInRequest,
  clockOutRequest: providerClockOutRequest,
  settings: providerClockInOutSettings
});
