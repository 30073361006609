import React from 'react';
import PropTypes from 'prop-types';

import { INITIAL_CALL_STATE } from 'app/Conference/lib/states';

const OPTUM_TRICARE_COPY = {
  name:         'Care Coordinator',
  buttonText:   'Transfer to Care Coordinator',
  instructions:
    'If member needs non-emergent immediate care when the visit is ' +
    'completed, click the Transfer to Care Coordinator button. Please ' +
    'stay on the line to communicate the reason you are transferring ' +
    'the patient to them and then you can hang up and complete your ' +
    'documentation.'
};

const vendorCopy = vendor => {
  switch (vendor.vendor_name) {
    case 'Optum Tricare Vendor':
      return OPTUM_TRICARE_COPY;
    default:
      return null;
  }
};

const warmTransferClicked = (vendor, copy, props) => {
  const { conferenceId, consultationId, addParticipant, callParticipant } = props;

  const person = {
    admin:          false,
    type:           'Vendor',
    id:             vendor.id,
    name:           copy.name,
    title:          '',
    phoneNumbers:   [vendor.parameters.phone_number],
    selectedNumber: vendor.parameters.phone_number
  };

  addParticipant({
    person,
    conferenceId,
    consultationId,
    conferenceCall: {
      ...INITIAL_CALL_STATE,
      mutable: false
    }
  });

  callParticipant(
    {
      consultationId,
      admin:          person.admin,
      type:           person.type,
      id:             person.id,
      selectedNumber: person.selectedNumber
    },
    { dialMember: true }
  );
};

const vendors = props =>
  props.warmTransferVendors
    .map(vendor => ({ vendor, copy: vendorCopy(vendor) }))
    .filter(v => v.copy !== null);

const renderCopy = props => (
  <div>
    {vendors(props).map(({ vendor, copy }) => (
      <div key={vendor.id} className="vendor-instructions">
        {props.instructions || copy.instructions}
      </div>
    ))}
  </div>
);

const renderButtons = props => (
  <span>
    {vendors(props).map(({ vendor, copy }) => (
      <span key={vendor.id}>
        &nbsp;
        <button
          disabled={props.disabled}
          className="warm-transfer conference-controls"
          onClick={e => {
            e.preventDefault();
            warmTransferClicked(vendor, copy, props);
          }}
        >
          {props.buttonText || copy.buttonText}
        </button>
      </span>
    ))}
  </span>
);

const VendorControls = props => (
  <div>
    {renderCopy(props)}
    {renderButtons(props)}
  </div>
);

export const isVendorAdded = participants => participants.some(p => p.person.type === 'Vendor');

VendorControls.propTypes = {
  addParticipant:      PropTypes.func.isRequired,
  buttonText:          PropTypes.string,
  conferenceId:        PropTypes.number,
  callParticipant:     PropTypes.func.isRequired,
  consultationId:      PropTypes.number.isRequired,
  disabled:            PropTypes.bool.isRequired,
  instructions:        PropTypes.string,
  warmTransferVendors: PropTypes.array.isRequired
};

export default VendorControls;
