import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import { formatDate, lastCharacterIsInteger, validateSearch } from '../utils';
import '../search-form.css';
import moment from 'moment-timezone';

const SearchForm = ({ setExportParams, exportParams }) => {
  const isCanada = window.APPLICATION_REGION === 'CAN';
  const canadaStartDate = moment('01/01/2000', 'MM/DD/YYYY', true);
  const canadaMaxMonths = moment().diff(canadaStartDate, 'months');

  const [validDate, setValidDate] = useState({ start: true, end: true });
  const [searchFormInput, setSearchFormInput] = useState({
    ...exportParams
  });
  const [filterOpen, setFilterOpen] = useState(false);
  const [maxMonths, setMaxMonths] = useState(isCanada ? canadaMaxMonths : 3);
  const [consultSelected, setConsultSelected] = useState(false);
  const [memberSelected, setMemberSelected] = useState(false);

  const defaultDayLimit = 100;
  const canadaDayLimit = moment().diff(canadaStartDate, 'days');
  const searchDayLimit = isCanada ? canadaDayLimit : defaultDayLimit;

  const handleSubmit = () => {
    const searchStatus = validateSearch(
      searchFormInput.start_date,
      searchFormInput.end_date,
      memberSelected,
      consultSelected,
      searchDayLimit
    );
    if (searchStatus.validSearch) {
      setFilterOpen(false);
      setValidDate({ ...validDate, start: true, end: true });
      setExportParams({
        ...searchFormInput,
        start_date: formatDate(searchFormInput.start_date),
        end_date:   formatDate(searchFormInput.end_date),
        page:       1
      });
    } else {
      setSearchFormInput({
        ...searchFormInput,
        start_date: searchStatus.validStartDate ? searchFormInput.start_date : null,
        end_date:   searchStatus.validEndDate ? searchFormInput.end_date : null
      });
      setValidDate({ ...validDate, start: searchStatus.validStartDate, end: searchStatus.validEndDate });
    }
  };

  const handleChange = (value, key) => {
    const updatedSearchFormInput = { ...searchFormInput, [key]: value };
    // Check if single consultation is selected
    if (updatedSearchFormInput.consultation_id !== '') {
      setConsultSelected(true);
    } else {
      setConsultSelected(false);
    }
    // Check if single members is selected by first/last name or ID
    if (
      updatedSearchFormInput.consultation_member_id ||
      (updatedSearchFormInput.first_name_member !== '' && updatedSearchFormInput.last_name_member !== '')
    ) {
      setMemberSelected(true);
      // When a search request is for a single member then the range is 12 months
      setMaxMonths(12);
    } else {
      setMemberSelected(false);
    }
    // Check if consultations are being searched without specific member or consultation
    if (
      updatedSearchFormInput.consultation_id === '' &&
      !(
        updatedSearchFormInput.consultation_member_id ||
        (updatedSearchFormInput.first_name_member !== '' && updatedSearchFormInput.last_name_member !== '')
      )
    ) {
      // When a search request is for all members then the range is 3 months
      setMaxMonths(isCanada ? canadaMaxMonths : 3);
    }
    setSearchFormInput(updatedSearchFormInput);
  };

  const datePickerFormat = date => {
    if (date instanceof Date) {
      return date;
    } else if (date !== null) {
      return moment(date.replace(/\//g, '-')).toDate();
    } else {
      return null;
    }
  };

  // min and max date for start date field
  const startDateMinDate = months => {
    if (searchFormInput.end_date !== null) {
      return moment(searchFormInput.end_date)
        .subtract(months, 'months')
        .toDate();
    } else {
      return null;
    }
  };

  const startDateMaxDate = () => {
    if (searchFormInput.end_date !== null) {
      return moment(searchFormInput.end_date).toDate();
    } else {
      return moment().toDate();
    }
  };

  // min and max date for end date field
  const endDateMinDate = () => {
    if (searchFormInput.start_date !== null) {
      return moment(searchFormInput.start_date).toDate();
    } else {
      return null;
    }
  };

  const endDateMaxDate = months => {
    if (
      searchFormInput.start_date !== null &&
      moment(searchFormInput.start_date)
        .add(months, 'months')
        .isSameOrAfter(moment())
    ) {
      return moment().toDate();
    } else if (searchFormInput.start_date !== null) {
      return moment(searchFormInput.start_date)
        .add(months, 'months')
        .toDate();
    } else {
      return moment().toDate();
    }
  };

  return (
    <React.Fragment>
      <div onClick={() => setFilterOpen(!filterOpen)} className="button open_menu no_select">
        {filterOpen ? 'Hide Filters' : 'Show Filters'}
      </div>
      {!filterOpen ? (
        <React.Fragment />
      ) : (
        <div id="provider_history_search_dropdown" className="search dropdown">
          <form acceptCharset="UTF-8" action="/history" method="get">
            <table className="fields">
              <tbody id="form-container">
                <div id="form-box">
                  <tr>
                    <th>Consultation ID:</th>
                    <td>
                      <input
                        id="consultation_id"
                        name="consultation_id"
                        type="text"
                        value={searchFormInput.consultation_id}
                        onChange={e => {
                          if (lastCharacterIsInteger(e.target.value)) {
                            handleChange(e.target.value, 'consultation_id');
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Member ID:</th>
                    <td>
                      <input
                        id="consultation_member_id"
                        name="consultation_member_id"
                        type="text"
                        value={searchFormInput.consultation_member_id}
                        onChange={e => {
                          if (lastCharacterIsInteger(e.target.value)) {
                            handleChange(e.target.value, 'consultation_member_id');
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>First Name (Member):</th>
                    <td>
                      <input
                        id="first_name_member"
                        name="first_name_member"
                        type="text"
                        value={searchFormInput.first_name_member}
                        onChange={e => handleChange(e.target.value, 'first_name_member')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Last Name (Member):</th>
                    <td>
                      <input
                        id="last_name_member"
                        name="last_name_member"
                        type="text"
                        value={searchFormInput.last_name_member}
                        onChange={e => handleChange(e.target.value, 'last_name_member')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="required">Start Date:</th>
                    <td className="date">
                      {!consultSelected ? (
                        <React.Fragment>
                          <DatePicker
                            isClearable
                            id="start_date"
                            className={validDate.start ? 'valid_date' : 'invalid_date'}
                            placeholderText={validDate.start ? '' : 'Choose a valid start date'}
                            name="start_date"
                            minDate={startDateMinDate(maxMonths)}
                            maxDate={startDateMaxDate()}
                            selected={datePickerFormat(searchFormInput.start_date)}
                            selectsStart
                            startDate={datePickerFormat(searchFormInput.start_date)}
                            endDate={datePickerFormat(searchFormInput.end_date)}
                            value={datePickerFormat(searchFormInput.start_date)}
                            onChange={date => handleChange(date, 'start_date')}
                          />
                        </React.Fragment>
                      ) : (
                        <input disabled id="disabled_date_input" />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className="required">End Date:</th>
                    <td className="date">
                      {!consultSelected ? (
                        <React.Fragment>
                          <DatePicker
                            isClearable
                            id="end_date"
                            className={validDate.end ? 'valid_date' : 'invalid_date'}
                            placeholderText={validDate.end ? '' : 'Choose a valid end date'}
                            name="end_date"
                            minDate={endDateMinDate()}
                            maxDate={endDateMaxDate(maxMonths)}
                            selected={datePickerFormat(searchFormInput.end_date)}
                            selectsEnd
                            startDate={datePickerFormat(searchFormInput.start_date)}
                            endDate={datePickerFormat(searchFormInput.end_date)}
                            value={datePickerFormat(searchFormInput.end_date)}
                            onChange={date => handleChange(date, 'end_date')}
                          />
                        </React.Fragment>
                      ) : (
                        <input disabled id="disabled_date_input" />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Labs Only:</th>
                    <td>
                      <input
                        checked={searchFormInput.labs_only}
                        id="labs_only"
                        name="labs_only"
                        type="checkbox"
                        onChange={() => handleChange(!searchFormInput.labs_only, 'labs_only')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>External:</th>
                    <td>
                      <input
                        checked={searchFormInput.external}
                        id="external"
                        name="external"
                        type="checkbox"
                        onChange={e => handleChange(!searchFormInput.external, 'external')}
                      />
                    </td>
                  </tr>
                </div>
                <div id={'open-info-box'}>
                  <div id="info-text">
                    <header id="info-text-header-main">Search Tips</header>
                    <header id="info-text-header">To Find An Individual Member:</header>
                    <div id="info-inner-text">
                      Enter the Member ID or First/Last Name, then specify a date range of up to one year. When
                      searching by Consultation ID, all dates will be searched.
                    </div>
                    <header id="info-text-header" className="top-info-text">
                      To Find All Consults Within a Date Range:
                    </header>
                    <div id="info-inner-text">
                      Enter a start date and end date. All other fields can be left blank.{' '}
                      {!isCanada && <span id="info-inner-text-bold">Maximum date range is 90 days.</span>}
                    </div>
                  </div>
                </div>
              </tbody>
            </table>
            <button
              onClick={e => {
                e && e.preventDefault();
                handleSubmit();
              }}
              className="button no_select submit_button"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchForm;

SearchForm.propTypes = {
  setExportParams: PropTypes.func
};
