import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue(value => value + 1);
};

const Timer = ({ startedAt }) => {
  const forceUpdate = useForceUpdate();

  const duration = moment().diff(moment(startedAt));
  const hours = Math.floor(moment.duration(duration).asHours());

  useEffect(() => {
    const intervalId = setInterval(forceUpdate, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <React.Fragment>
      {hours > 0 && `${hours}:`}
      {moment.utc(duration).format('mm:ss')}
    </React.Fragment>
  );
};

Timer.propTypes = {
  startedAt: PropTypes.string.isRequired
};

export default Timer;
