import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import checkmarkIcon from '../../assets/images/success-check-mark.svg';
import './consultation-rescheduling-success.scss';

const ConsultationReschedulingSuccess = ({ data: { btnText, btnAction, message }, onConfirm }) => (
  <div className="consultationReschedulingSuccessWrapper">
    <img className="checkmarkIcon" src={checkmarkIcon} />
    <div className="messagesContainer">
      {map(message, (msg, key) => (
        <p className="message" key={key}>
          {msg}
        </p>
      ))}
    </div>
    <button className="button confirm" onClick={onConfirm.bind(this, btnAction)}>
      {btnText}
    </button>
  </div>
);

ConsultationReschedulingSuccess.propTypes = {
  data: PropTypes.shape({
    btnText:   PropTypes.string.isRequired,
    btnAction: PropTypes.string.isRequired,
    message:   PropTypes.arrayOf(PropTypes.string)
  }),
  onConfirm: PropTypes.func.isRequired
};

export default ConsultationReschedulingSuccess;
