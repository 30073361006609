import React, { useState, useEffect } from 'react';
import { useUpdater } from '@td/shared_utils';
import { authToken } from '@td/api';
import { useQuery } from '@apollo/client';
import FETCH_PATIENT_STATUS from '../queries/fetch_patient_status.graphql';
import '../consult-history-page-container.css';
import ConsultHistoryPage from './consult-history-page';

const isMVPToggleOn = Boolean(window.FEATURE_TOGGLES.patient_management_tools_v2);

const ConsultHistoryPageContainer = () => {
  const [exportParams, setExportParams] = useState({
    consultation_id:        '',
    consultation_member_id: '',
    first_name_member:      '',
    last_name_member:       '',
    start_date:             moment()
      .subtract(7, 'day')
      .format('L'),
    end_date:               moment().format('L'),
    external:               false,
    reverse_order:          false,
    labs_only:              false,
    own_consultations_only: true,
    page:                   1
  });

  const [patientStatus, setPatientStatus] = useState([]);

  const { callApi, data } = useUpdater('get', {
    url:     'v4/consultations/search',
    method:  'get',
    headers: { 'Content-Type': 'json', Authorization: `${authToken.get()}` }
  });

  let patientStatusData = '';
  let patientStatusError = '';

  if (isMVPToggleOn) {
    ({ data: patientStatusData, error: patientStatusError } = useQuery(FETCH_PATIENT_STATUS));
  }

  useEffect(() => {
    callApi(exportParams);
    if (patientStatusData && !patientStatusError) {
      let data = [];
      if (patientStatusData.currentUserProvider && patientStatusData.currentUserProvider.patientList) {
        data = patientStatusData.currentUserProvider.patientList.collection;
      }
      setPatientStatus(data);
    }
  }, [exportParams, patientStatusData]);

  const updateCallBack = () => {
    callApi(exportParams);
  };

  return <ConsultHistoryPage {...{ data, setExportParams, exportParams, updateCallBack, patientStatus }} />;
};

export default ConsultHistoryPageContainer;
