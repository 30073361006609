import { createRequestActions } from '@td/utils';

import {
  FETCH_SYMPTOMS,
  FETCH_SYMPTOM_CLASSES,
  ADD_SYMPTOM_SEARCH_REQUEST,
  ADD_SYMPTOM_SEARCH_SUCCESS,
  SYMPTOMS_FORM_RADIO_CHANGE,
  SYMPTOMS_FORM_NOTE_CHANGE,
  TOGGLE_ACCORDION,
  SYMPTOM_SELECTED,
  SYMPTOM_UNSELECTED,
  SYMPTOM_UNSELECTED_ON_VALUE_CHANGE,
  SELECTED_TAB_CHANGE,
  FOCUS_SYMPTOM,
  FOCUS_SYMPTOM_SET,
  FOCUS_SYMPTOM_CLEAR,
  ACCORDION_TOGGLE_END,
  ADD_SYMPTOM_INPUT_CHANGE,
  SYMPTOMS_FORM_INITIALIZE,
  ADDED_SYMPTOMS_INITIALIZE
} from '../action-types';

export const fetchSymptoms = createRequestActions(FETCH_SYMPTOMS);
export const fetchSymptomClasses = createRequestActions(FETCH_SYMPTOM_CLASSES);

export const symptomsFormRadioChange = (symptomCode, radioValue, symptomClass) => ({
  type:    SYMPTOMS_FORM_RADIO_CHANGE,
  payload: {
    symptomCode,
    radioValue,
    symptomClass
  }
});

export const symptomsFormNoteChange = (symptomCode, noteValue) => ({
  type:    SYMPTOMS_FORM_NOTE_CHANGE,
  payload: {
    symptomCode,
    noteValue
  }
});

export const symptomsFormInitialize = state => ({
  type:    SYMPTOMS_FORM_INITIALIZE,
  payload: {
    state
  }
});

export const toggleAccordion = symptomClass => ({
  type:    TOGGLE_ACCORDION,
  payload: {
    symptomClass
  }
});

export const accordionToggleEnd = symptomClass => ({
  type:    ACCORDION_TOGGLE_END,
  payload: {
    symptomClass
  }
});

export const addSymptomInputChange = inputValue => ({
  type:    ADD_SYMPTOM_INPUT_CHANGE,
  payload: {
    inputValue
  }
});

export const addSymptomSearchRequest = symptomCode => ({
  type:    ADD_SYMPTOM_SEARCH_REQUEST,
  payload: {
    symptomCode
  }
});

export const addSymptomSearchSuccess = symptomCode => ({
  type:    ADD_SYMPTOM_SEARCH_SUCCESS,
  payload: {
    symptomCode
  }
});

export const addedSymptomsInitialize = state => ({
  type:    ADDED_SYMPTOMS_INITIALIZE,
  payload: {
    state
  }
});

export const symptomSelected = (symptomCode, symptomClass) => ({
  type:    SYMPTOM_SELECTED,
  payload: {
    symptomCode,
    symptomClass
  }
});

export const symptomUnselected = symptomCode => ({
  type:    SYMPTOM_UNSELECTED,
  payload: {
    symptomCode
  }
});

export const symptomUnselectedOnValueChange = symptomCode => ({
  type:    SYMPTOM_UNSELECTED_ON_VALUE_CHANGE,
  payload: {
    symptomCode
  }
});

export const selectedTabChange = selectedTabIndex => ({
  type:    SELECTED_TAB_CHANGE,
  payload: {
    selectedTabIndex
  }
});

export const focusSymptom = symptomCode => ({
  type:    FOCUS_SYMPTOM,
  payload: {
    symptomCode
  }
});

export const focusSymptomSet = (symptomCode, symptomClass) => ({
  type:    FOCUS_SYMPTOM_SET,
  payload: {
    symptomCode,
    symptomClass
  }
});

export const focusSymptomClear = symptomCode => ({
  type:    FOCUS_SYMPTOM_CLEAR,
  payload: {
    symptomCode
  }
});
