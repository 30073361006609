import useFetchPaginatedConsultations from './useFetchPaginatedConsultations';
import { useData } from 'app/DataProvider';

const useFetchRequestedConsultations = () => {
  const { providerId } = useData();
  return useFetchPaginatedConsultations(
    `/v4/providers/${providerId}/consultations/mental_health_list?consult_type=on_demand`
  );
};

export default useFetchRequestedConsultations;
