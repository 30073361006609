import { combineEpics } from 'redux-observable';
import { from, of, EMPTY, timer, merge } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, mapTo, takeUntil, tap, debounce } from 'rxjs/operators';
import { createRequestEpic } from '@td/utils';

import {
  INIT_CONSULTATION_QUEUE_REFRESH,
  FETCH_CONSULTATION_QUEUE,
  START_CONSULTATION_QUEUE_REFRESH,
  PAUSE_CONSULTATION_QUEUE_REFRESH
} from '../action-types/consultation-queue-action-types';
import {
  fetchConsultationQueue,
  startConsultationQueueRefresh,
  pauseConsultationQueueRefresh
} from '../actions/consultation-queue-actions';
import { LAST_ACTIVE_TIME_UPDATED } from 'app/providerActiveStatus/action-types';
import { showAlert } from 'app/components/alert/actions';
import { AlertPriority } from 'app/components/alert/enums';
import { getTimeToAlert } from '../../../lib/utils';
import { getTimeToNextRefresh, getDelay } from '../../../lib/utils';

export const QUEUE_REFRESH_INITIAL_DELAY = 30 * 1000;
let initialDelay = QUEUE_REFRESH_INITIAL_DELAY;
let initialDelaySet = false;
const ALLOWED_PROVIDER_INACTIVITY_PERIOD = 15 * 60 * 1000;
export const CONSULTATION_QUEUE_REFRESH_INTERVAL = 60;
export const ALERT_TEXT =
  'Because you have been inactive for 15 mins, your dashboard is not refreshing automatically. Click "OK" to enable automatic refreshes.';
export const ERROR_TEXT = 'An error occurred while refreshing the consultation queue, please refresh the page.';

const pauseConsultationQueueRefreshOnInactivity$ = action$ =>
  action$.pipe(
    ofType(LAST_ACTIVE_TIME_UPDATED),
    debounce(({ payload: { lastActiveTime } }) =>
      timer(getTimeToAlert(lastActiveTime, ALLOWED_PROVIDER_INACTIVITY_PERIOD))
    ),
    switchMap(() =>
      from([
        pauseConsultationQueueRefresh(),
        showAlert({
          message:         ALERT_TEXT,
          dispatchOnClose: [startConsultationQueueRefresh()],
          priority:        AlertPriority.LOW
        })
      ])
    ),
    tap(() => {
      initialDelay = 0;
    })
  );

export const initConsultationQueueRefreshEpic = action$ =>
  action$.pipe(
    ofType(INIT_CONSULTATION_QUEUE_REFRESH),
    switchMap(() => merge(of(startConsultationQueueRefresh()), pauseConsultationQueueRefreshOnInactivity$(action$)))
  );

export const startConsultationQueueRefreshEpic = action$ =>
  action$.pipe(
    ofType(START_CONSULTATION_QUEUE_REFRESH),
    switchMap(() => {
      if (!initialDelaySet) {
        initialDelay = getDelay(initialDelay);
        initialDelaySet = true;
      }
      return timer(initialDelay, getTimeToNextRefresh(CONSULTATION_QUEUE_REFRESH_INTERVAL)).pipe(
        mapTo(fetchConsultationQueue.start()),
        takeUntil(
          merge(
            action$.pipe(
              ofType(FETCH_CONSULTATION_QUEUE.SUCCESS),
              tap(() => {
                initialDelay = getTimeToNextRefresh(CONSULTATION_QUEUE_REFRESH_INTERVAL);
                window.dispatch(startConsultationQueueRefresh());
              })
            ),
            action$.pipe(ofType(PAUSE_CONSULTATION_QUEUE_REFRESH))
          )
        )
      );
    })
  );

export const fetchConsultationQueueEpic = createRequestEpic(
  FETCH_CONSULTATION_QUEUE,
  () =>
    from(
      window.$.ajax({
        type:     'GET',
        url:      '/dashboard/refresh_queue',
        dataType: 'script',
        headers:  window.TELADOC_ENVELOP
      })
    ),
  [
    () => {
      window.showGenericMessage(ERROR_TEXT, 'error');

      return EMPTY;
    }
  ]
);

export default combineEpics(
  initConsultationQueueRefreshEpic,
  startConsultationQueueRefreshEpic,
  fetchConsultationQueueEpic
);
