import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';

const OptionContainer = ({ onChange, className, children }) => (
  <form onChange={onChange} className={className}>
    {children}
  </form>
);

const CommitmentOption = ({ className, text, classRadioIcon, returnTag }) => {
  const [id] = useState(_uniqueId());
  return (
    <div className={className}>
      <input type="radio" id={id} value={returnTag} name="commitment" className={classRadioIcon} />
      <label htmlFor={id}> {text} </label>
    </div>
  );
};

OptionContainer.propTypes = {
  onChange:  PropTypes.func.isRequired,
  children:  PropTypes.node.isRequired,
  className: PropTypes.string
};

CommitmentOption.propTypes = {
  text:           PropTypes.string.isRequired,
  returnTag:      PropTypes.string.isRequired,
  className:      PropTypes.string,
  classRadioIcon: PropTypes.string
};

export { OptionContainer, CommitmentOption };
