import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Toggle = ({ checked, onChange, disableToggle }) => {
  const handleChange = useCallback(
    event => {
      onChange(event.target.checked);
    },
    [onChange]
  );

  return (
    <div className="toggle-switch-wrapper">
      <label className="toggle-switch">
        <input type="checkbox" onChange={handleChange} checked={checked} disabled={disableToggle} />
        <span className="toggle-slider round" />
      </label>
    </div>
  );
};

Toggle.propTypes = {
  checked:       PropTypes.bool,
  onChange:      PropTypes.func,
  disableToggle: PropTypes.bool
};

Toggle.defaultProps = {
  checked:       false,
  disableToggle: false
};

export default Toggle;
