import { from, of, concat } from 'rxjs';
import { ofType } from 'redux-observable';
import { mapTo, debounceTime, switchMap, catchError } from 'rxjs/operators';
import { authToken, teladocApi } from '@td/api';
import { success } from 'app/notifications/actions';
import get from 'lodash/get';

import {
  SUBMIT_WEIGHT_FORM,
  VALIDATE_WEIGHT_FORM,
  VALIDATE_WEIGHT_FORM_START,
  VALIDATE_WEIGHT_FORM_SUCCESS,
  VALIDATE_WEIGHT_FORM_FAIL
} from './actionTypes';

import { validateWeightForm } from './weightFormValidator';

import {
  submitWeightFormStart,
  submitWeightFormFail,
  submitWeightFormSuccess,
  partialResetWeightForm
} from './actions';

const postPatientWeight = (
  formData,
  {
    settings: {
      memberParams: { heightWeightMhdQuestions }
    }
  }
) => {
  const { memberId, weight } = formData;
  // question_mnemonic_cd is the closest thing to a unique value that identifies a survey question.
  // The HeightWeight section has two questions, the weight question has the value of q_2_1.
  const weightQuestion = heightWeightMhdQuestions.find(question => question.question_mnemonic_cd === 'q_2_1');
  const weightAnswerId =
    get(weightQuestion, 'latest_member_responses[0].ref_survey_answer_id') ||
    get(weightQuestion, 'member_responses[0].ref_survey_answer_id');

  const params = {
    request: {
      survey_responses: [
        {
          question_id:          weightQuestion.question_id,
          ref_survey_answer_id: weightAnswerId,
          response_text:        weight
        }
      ]
    },
    medical_history_disclosure: {
      request: {
        survey_responses: [
          {
            question_id:          weightQuestion.question_id,
            ref_survey_answer_id: weightAnswerId,
            response_text:        weight
          }
        ]
      }
    }
  };

  const url = `/v1/members/${memberId}/mhds`;

  from(teladocApi.post(url, params, authToken.get()));
};

export const postPatientWeightFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_WEIGHT_FORM),
    switchMap(({ payload: formData }) =>
      concat(
        of(submitWeightFormStart(formData)),
        of(postPatientWeight(formData, state$.value)).pipe(
          switchMap(() =>
            of(submitWeightFormSuccess(formData), partialResetWeightForm(), success('Weight updated successfully'))
          ),
          catchError(error => of(submitWeightFormFail(error)))
        )
      )
    )
  );

export const validatePatientWeightFormEpic = action$ =>
  action$.pipe(
    ofType(VALIDATE_WEIGHT_FORM),
    debounceTime(350),
    switchMap(({ payload: formData }) =>
      concat(
        of({ type: VALIDATE_WEIGHT_FORM_START }),
        from(validateWeightForm(formData)).pipe(
          mapTo({ type: VALIDATE_WEIGHT_FORM_SUCCESS }),
          catchError(error => of({ type: VALIDATE_WEIGHT_FORM_FAIL, payload: error }))
        )
      )
    )
  );
