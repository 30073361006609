import React, { createContext, useState, useContext } from 'react';
import _ from 'lodash';
import { useQuickDxList, useShortcutsList } from '../api';
import { getConsultationType, getConsultationId } from '.';
import { FAVORITES_UNSUPPORTED_VISIT_TYPES, QUICK_DX_UNEDITABLE_VISIT_TYPE } from '../constants';

const FavoriteQuickDxContext = createContext(null);
const visitTypeSupportsFavorites = () => !FAVORITES_UNSUPPORTED_VISIT_TYPES.includes(getConsultationType());
const visitTypeSupportsEditing = () => !QUICK_DX_UNEDITABLE_VISIT_TYPE.includes(getConsultationType());

export const QuickDxFavoritesProvider = ({ children }) => {
  const favoritesEnabled = visitTypeSupportsFavorites();
  const [selectedDxCodes, setSelectedDxCodes] = useState([]); // holds both primary and secondary diagnoses
  const [favorites, setFavorites] = useState([]);
  const [shortcutsList, setShortcutsList] = useState([]);
  const isEditable = visitTypeSupportsEditing();

  const quickDxFavoritesData = useQuickDxList({
    onFetched: data => {
      const favoritesList = _.get(data, 'providerQuickDxList', []);

      setFavorites(favoritesList);
    }
  });

  useShortcutsList({
    onFetched:      fetchedShortcutsList => setShortcutsList(fetchedShortcutsList),
    consultationId: getConsultationId()
  });

  const removeDiagnosis = diagnosis => {
    const { diagnosisCode } = diagnosis;
    const filteredFavorites = favorites.filter(diagnose => diagnose.diagnosisCode !== diagnosisCode);

    setFavorites([...filteredFavorites]);
  };

  const addDiagnosis = diagnosis => {
    const exists = favorites.find(diagnose => diagnose.diagnosisCode === diagnosis.diagnosisCode);

    if (!exists) {
      const favoritesCopy = favorites;
      favoritesCopy.push(diagnosis);

      setFavorites([...favoritesCopy]);
    }
  };

  const handleFavoriteDiagnosisChange = (diagnosis, isFavorite) => {
    if (isFavorite) {
      return addDiagnosis(diagnosis);
    }

    return removeDiagnosis(diagnosis);
  };

  return (
    <FavoriteQuickDxContext.Provider
      value={{
        shortcutsList,
        favorites,
        setFavorites,
        removeDiagnosis,
        addDiagnosis,
        handleFavoriteDiagnosisChange,
        favoritesEnabled,
        isEditable,
        quickDxFavoritesData,
        visitType:       getConsultationType(),
        selectedDxCodes: {
          list: selectedDxCodes,
          set:  setSelectedDxCodes,
          add:  diagnosisCode => {
            if (selectedDxCodes.includes(diagnosisCode)) return;

            setSelectedDxCodes([...selectedDxCodes, diagnosisCode]);
          },
          remove: diagnosisCode => {
            setSelectedDxCodes([...selectedDxCodes.filter(code => diagnosisCode !== code)]);
          }
        }
      }}
    >
      {children}
    </FavoriteQuickDxContext.Provider>
  );
};

export const useQuickDxFavorites = () => useContext(FavoriteQuickDxContext);

export const isDiagnosisCodeFavorite = diagnosisCode => {
  const { favorites } = useQuickDxFavorites();
  const quickDxSearch = favorites.find(diagnose => diagnose.diagnosisCode === diagnosisCode);

  return Boolean(quickDxSearch);
};

export const getQuickDxIdByDiagnosisCode = diagnosisCode => {
  const { favorites } = useQuickDxFavorites();
  const diagnosis = favorites.find(quickDx => quickDx.diagnosisCode === diagnosisCode);

  return _.get(diagnosis, 'quickDxId', null);
};
