import moment from 'moment-timezone';
import { THERAPIST_ROLE } from './constants';

const PATIENT_LIST_DATE_FORMAT = 'L LT z';

export const getBrowserTimezone = (date, format = PATIENT_LIST_DATE_FORMAT) => {
  const localTimezone = moment.tz.guess();

  return date
    ? moment(date)
        .tz(localTimezone)
        .format(format)
    : '';
};

export const isTherapist = role => role === THERAPIST_ROLE;

export const providerHasntSpecifiedBetterInDays = betterInDays => betterInDays === '0';
