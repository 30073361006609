import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PendingMedicationItem from './pending-medication-item';

const isEmrNewMedicationEnabled = window.FEATURE_TOGGLES.emr_new_medication;

const PendingMedicationList = props => {
  const { pendingMeds, removeHandler, editHandler } = props;

  return (
    <div className={classNames("pending-medication-list", { "new-pending-medication-list": isEmrNewMedicationEnabled })}>
      <table className="table">
        <thead>
          <tr>
            <th>Medication</th>
            {!isEmrNewMedicationEnabled && <th>Dosage</th>}
            <th>Direction</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pendingMeds.map(medication => (
            <PendingMedicationItem
              key={`pending-drug-idx-${medication.drugName}`}
              medication={medication}
              removeHandler={removeHandler}
              editHandler={editHandler}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

PendingMedicationList.propTypes = {
  pendingMeds:   PropTypes.array.isRequired,
  removeHandler: PropTypes.func.isRequired,
  editHandler:   PropTypes.func.isRequired
};

export default PendingMedicationList;
