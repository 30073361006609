import React from 'react';
import RescheduleAction from '../actions/RescheduleAction';
import ActionsContainer from '../ActionsContainer';
import AcceptAction from '../actions/AcceptAction';
import PropTypes from 'prop-types';

const LegacyFlowNegotiation = (props) => {
  return (
    <ActionsContainer>
      {[
        <AcceptAction key="legacy-accept" {...props}/>,
        <RescheduleAction key="legacy-reschedule" {...props} />,
      ]}
    </ActionsContainer>
  );
};

LegacyFlowNegotiation.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default LegacyFlowNegotiation;
