import React from 'react';
import PropTypes from 'prop-types';

const RemedyApiStatusWarning = () => (
  <p className="clinical-medication__remedy-error">
    <strong>
      There is a temporary error looking up medication names.
      Please carefully check spelling since this will be a manual entry.
    </strong>
  </p>
);

RemedyApiStatusWarning.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default RemedyApiStatusWarning;
