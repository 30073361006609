exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3QMnVM85d8YzsONtNRML1U {\n  display: flex;\n  flex-direction: column;\n  row-gap: 10px;\n  padding: 16px 0; }\n", ""]);

// exports
exports.locals = {
	"surveyQuestionsContainer": "_3QMnVM85d8YzsONtNRML1U",
	"surveyQuestionsContainer": "_3QMnVM85d8YzsONtNRML1U"
};