exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1SA36t8ezuL1LLzcXCEnEa {\n  display: flex;\n  align-items: flex-start;\n  margin-bottom: 0.875rem; }\n\n._1-6n5y1ixXGlXj5XHsjbax {\n  font-size: 0.75rem;\n  color: #696B6B;\n  margin-right: 0.25rem;\n  flex-shrink: 0; }\n\n._2YxhVaUXjHOoWEPkBQjJ-4 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  font-size: 0.75rem;\n  color: #000000; }\n", ""]);

// exports
exports.locals = {
	"callDetailsItem": "_1SA36t8ezuL1LLzcXCEnEa",
	"callDetailsItem": "_1SA36t8ezuL1LLzcXCEnEa",
	"callDetailsItemLabel": "_1-6n5y1ixXGlXj5XHsjbax",
	"callDetailsItemLabel": "_1-6n5y1ixXGlXj5XHsjbax",
	"callDetailsItemValue": "_2YxhVaUXjHOoWEPkBQjJ-4",
	"callDetailsItemValue": "_2YxhVaUXjHOoWEPkBQjJ-4"
};