import React from 'react';

const Plus = ({ onClick }) => (
  <div className="mds_plus">
    <a className="AddDiagnosis" onClick={onClick}>
      Add Diagnosis
    </a>
    <p>(Optional)</p>
  </div>
);

export default Plus;
