import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { NOTIFICATION_MESSAGES } from "../../constants";
import PatientLimitConfigureNotification from "./index";

const NotificationsContainer = ({ 
    modalData: {currentPatients, overallPatientsLimit},
    showNotification,
    setShowNotification
}) => {


  const renderNotifications = (currentPatients, overallPatientsLimit) => {
    const successMsg = currentPatients === overallPatientsLimit;
    const message = successMsg ?
                    NOTIFICATION_MESSAGES.limit_matched :
                    NOTIFICATION_MESSAGES.limit_saved;
    const container = document.getElementById('react_patient_limit_notification_banner');

    if (container) {
        ReactDOM.render(
        <PatientLimitConfigureNotification
            showNotification={showNotification}
            setShowNotification={setShowNotification}
            successMsg={successMsg}
            message={message}
        />,
        container
        );
    }
  }

  useEffect(() => {
    renderNotifications(currentPatients, overallPatientsLimit);
  }, [showNotification]);


  return (<React.Fragment></React.Fragment>);
};

NotificationsContainer.propTypes = {
  modal: PropTypes.shape({
    modalData: PropTypes.shape({
      overallPatientsLimit: PropTypes.number,
      currentPatients:      PropTypes.number
    }),
    setModalData:           PropTypes.func
  }),
  showNotification:         PropTypes.bool,
  setShowNotification:      PropTypes.func
};

export default NotificationsContainer;
