import { combineEpics } from 'redux-observable';
import { of, merge, EMPTY } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { actions as settingsActions } from 'medication_service_ui/dist/settings';
import get from 'lodash/get';

import { CLOCK_IN_PROVIDER, CLOCK_OUT_PROVIDER, CLOCK_IN_PROVIDER_INIT, INIT_CLOCK_IN_LOCATION } from '../action-types';
import ProviderClockInRequest from '../enums/provider-clock-in-request';
import { redirectToHome, createCsrfHeaders } from 'app/lib/utils';
import createRequestEpic from 'app/lib/redux/utilities/create-request-epic';
import { clockInProvider, setClockInOutLocationSettings } from '../actions';
import { checkNetworkTestNeeded } from 'app/network-test/actions/network-test-actions';
import { NETWORK_TEST_COMPLETE, NETWORK_TEST_CANCEL } from 'app/network-test/action-types';
import getProviderParams from 'app/Provider/selectors/getProviderParams';
import ProviderClockInType from 'app/Provider/enums/provider-clock-in-type';
import { showAlert } from 'app/components/alert/actions';
import { COMPONENT_ID as PROVIDER_VIDEO_CLOCK_OUT_PROMPT_ID } from 'app/Provider/components/alerts/provider-video-clock-out-prompt';

const redirectToHome$ = () =>
  of({}).pipe(
    switchMap(() => {
      redirectToHome();

      return EMPTY;
    })
  );

export const clockInProviderInitEpic = (action$, state$) =>
  action$.pipe(
    ofType(CLOCK_IN_PROVIDER_INIT),
    switchMap(action => {
      const state = state$.value;

      const currentClockInType = get(getProviderParams(state), 'clockInType');
      const clockInRequestType = action.payload.clockInType;
      const locationType = action.payload.locationType;
      const locationText = action.payload.locationText;

      if (currentClockInType === ProviderClockInType.VIDEO && clockInRequestType === ProviderClockInRequest.NORMAL) {
        return of(
          showAlert({
            componentId: PROVIDER_VIDEO_CLOCK_OUT_PROMPT_ID
          })
        );
      }

      const performNetworkTest = state.networkTest.settings.performTest;

      if (performNetworkTest && clockInRequestType === ProviderClockInRequest.VIDEO) {
        return of(checkNetworkTestNeeded.start());
      }

      return of(clockInProvider.start({ clockInType: clockInRequestType, locationType, locationText }));
    })
  );

export const clockInProviderEpic = createRequestEpic(CLOCK_IN_PROVIDER, ({ action }) => {
  const clockInType = action.payload.clockInType;
  const locationType = action.payload.locationType;
  const locationText = action.payload.locationText;

  return ajax
    .post(
      `/dashboard/${clockInType === ProviderClockInRequest.VIDEO ? 'video_in_out' : 'clock_in_out'}?clock_in_out=1`,
      { location_type: locationType, location_text: locationText },
      createCsrfHeaders()
    )
    .pipe(
      map(response => ({
        data: {
          clockInType: get(response, ['response', 'in_out_type'])
        }
      }))
    );
});

export const clockInProviderSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(CLOCK_IN_PROVIDER.SUCCESS),
    switchMap(action =>
      merge(
        of(settingsActions.updateProviderParams({ clockInType: action.payload.clockInType })),
        of({}).pipe(
          switchMap(() => {
            const state = state$.value;

            if (state.networkTest.status.initiated) {
              return action$.pipe(
                ofType(NETWORK_TEST_COMPLETE, NETWORK_TEST_CANCEL),
                switchMap(() => redirectToHome$())
              );
            }

            return redirectToHome$();
          })
        )
      )
    )
  );

export const clockOutProviderEpic = createRequestEpic(CLOCK_OUT_PROVIDER, () =>
  ajax.post('/dashboard/clock_in_out?clock_in_out=0', {}, createCsrfHeaders())
);

export const clockInLocationInitEpic = action$ =>
  action$.pipe(
    ofType(INIT_CLOCK_IN_LOCATION),
    switchMap(() =>
      ajax('/dashboard/location_list', {}, createCsrfHeaders()).pipe(
        map(data =>
          setClockInOutLocationSettings({
            location: {
              type:          data.response.location_type || {},
              text:          data.response.location_text || '',
              list:          data.response.location_list || [],
              useForClockIn: !!data.response.use_location_for_clock_in
            }
          })
        )
      )
    )
  );

export const clockOutProviderSuccessEpic = action$ =>
  action$.pipe(
    ofType(CLOCK_OUT_PROVIDER.SUCCESS),
    switchMap(() => merge(of(settingsActions.updateProviderParams({ clockInType: '' })), redirectToHome$()))
  );

export default combineEpics(
  clockInProviderInitEpic,
  clockInProviderEpic,
  clockInProviderSuccessEpic,
  clockOutProviderEpic,
  clockOutProviderSuccessEpic,
  clockInLocationInitEpic
);
