import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import UPDATE_ACTIVE_CALENDARS_MUTATION from '../../mutations/update_active_calendars.graphql';
import CalendarSettingsModal from './calendar-settings-modal';
import CalendarSettingsConfirmationModal from './calendar-settings-confirmation-modal';

const CalendarSettings = ({ account, onClose }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedCalendars, setSelectedCalendars] = useState(null);
  const initialValues = selectedCalendars && selectedCalendars.reduce((result, { id }) => ({ ...result, [id]: true }), {});

  const [updateSettings] = useMutation(UPDATE_ACTIVE_CALENDARS_MUTATION, {
    onCompleted: ({ externalCalendarsUpdateActiveCalendars: { errors } }) => {
      if (!errors.length) {
        onClose(true);
      } else {
        console.error(errors);
      }
    },
    onError: console.error
  });

  const handleCalendarsSelect = useCallback((values) => {
    setSelectedCalendars(values);
    setShowConfirmationModal(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    const calendarIds = selectedCalendars.map(({ id }) => id);
    await updateSettings({ variables: { id: account.id, calendarIds } });
  }, [account, updateSettings, onClose, selectedCalendars]);

  const handleCancelConfirmation = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  return (
    <Fragment>
      {
        showConfirmationModal ? (
          <CalendarSettingsConfirmationModal
            onConfirm={handleSubmit}
            onCancel={handleCancelConfirmation}
            onClose={onClose}
          />
        ) : (
          <CalendarSettingsModal
            account={account}
            onSubmit={handleCalendarsSelect}
            onClose={onClose}
            initialValues={initialValues}
          />
        )
      }
    </Fragment>
  );
};

CalendarSettings.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
    calendars: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        active: PropTypes.bool,
      })
    )
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CalendarSettings;
