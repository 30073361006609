import React from 'react';
import Proptypes from 'prop-types';
import classNames from 'classnames';

const TextInput = props => {
  const {
    name,
    label,
    onChange,
    placeholder,
    value,
    error,
    disabled,
    className,
    maxLength,
    ...inputProps
  } = props;

  const wrapperClass =
    error && error.length > 0 ? 'field-group has-error' : 'field-group';

  return (
    <div className={classNames(wrapperClass, className)}>
      <label data-name={inputProps['data-name']} className="label" htmlFor={name}>
        {label}
      </label>
      <div className="field">
        <input
          type="text"
          id={name}
          name={name}
          className="form-field"
          placeholder={placeholder}
          value={maxLength ? value.slice(0, maxLength) : value}
          maxLength={maxLength}
          disabled={!!disabled}
          onChange={onChange}
          {...inputProps}
        />
        {error && (
          <div className="alert alert-danger error-msg">{error[0]}</div>
        )}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  name:        Proptypes.string.isRequired,
  label:       Proptypes.string.isRequired,
  onChange:    Proptypes.func.isRequired,
  placeholder: Proptypes.string,
  value:       Proptypes.string,
  disabled:    Proptypes.bool,
  error:       Proptypes.array,
  className:   Proptypes.string,
  maxLength:   Proptypes.number,
  inputProps:  Proptypes.object
};

export default TextInput;
