import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class NutPlanCheckbox extends Component {
  constructor(props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.state = {
      isChecked: this.props.control.response === 'YES'
    };
  }

  onChangeHandler() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  render() {
    return (
      <div className={`${this.props.classNameIdentifier}-checkbox`}>
        <div className={`${this.props.classNameIdentifier}-control-label`}>
          <label htmlFor={`${this.props.controlNamePrefix}[${this.props.questionIdentifier}]`}>
            {this.props.control.question}
          </label>
        </div>
        <div className={`${this.props.classNameIdentifier}-control-field`}>
          <input
            type="hidden"
            name={`${this.props.controlNamePrefix}[${this.props.questionIdentifier}][response]`}
            value="NO"
          />
          <input
            type="checkbox"
            name={`${this.props.controlNamePrefix}[${this.props.questionIdentifier}][response]`}
            value="YES"
            onChange={this.onChangeHandler}
            checked={this.state.isChecked}
          />
        </div>
      </div>
    );
  }
}

NutPlanCheckbox.propTypes = {
  control:             PropTypes.object.isRequired,
  controlNamePrefix:   PropTypes.string.isRequired,
  classNameIdentifier: PropTypes.string.isRequired,
  questionIdentifier:  PropTypes.string.isRequired
};
