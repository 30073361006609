import {
  SYMPTOM_SELECTED,
  SYMPTOM_UNSELECTED,
  ADD_SYMPTOM_SEARCH_SUCCESS,
  CLEAR_COMPLAINT,
  ADDED_SYMPTOMS_INITIALIZE,
  SYMPTOM_UNSELECTED_ON_VALUE_CHANGE
} from '../action-types';
import {
  addImmutable,
  removeImmutable,
  addToSetImmutable,
  removeFromSetImmutable
} from './utilities';

export const DEFAULT_STATE = {
  selectedSymptoms:           [],
  addedSymptomsWithSearchBox: new Set(),
  symptomSelectedClass:       {}
};

function onSymptomSelected(state, action) {
  const { symptomCode, symptomClass } = action.payload;

  return {
    ...state,
    selectedSymptoms:     [...state.selectedSymptoms, symptomCode],
    symptomSelectedClass: addImmutable(state.symptomSelectedClass, symptomCode, symptomClass)
  };
}

function onSymptomUnselected(state, action) {
  const symptomCode = action.payload.symptomCode;

  return {
    ...state,
    selectedSymptoms:           state.selectedSymptoms.filter(symptom => symptom !== symptomCode),
    addedSymptomsWithSearchBox: removeFromSetImmutable(
      state.addedSymptomsWithSearchBox,
      symptomCode
    ),
    symptomSelectedClass: removeImmutable(state.symptomSelectedClass, symptomCode)
  };
}

function onAddSymptomFromSearch(state, action) {
  const symptomCode = action.payload.symptomCode;

  return {
    ...state,
    selectedSymptoms:
        [...state.selectedSymptoms, symptomCode],
    addedSymptomsWithSearchBox: addToSetImmutable(state.addedSymptomsWithSearchBox, symptomCode),
    symptomSelectedClass:
        addImmutable(state.symptomSelectedClass, symptomCode, 'Added symptoms')
  };
}

function onComplaintClear() {
  return DEFAULT_STATE;
}

function onAddedSymptomsInitialize(state, action) {
  return {
    ...action.payload.state,
    addedSymptomsWithSearchBox: new Set(action.payload.state.addedSymptomsWithSearchBox)
  };
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SYMPTOM_SELECTED:
      return onSymptomSelected(state, action);
    case SYMPTOM_UNSELECTED:
    case SYMPTOM_UNSELECTED_ON_VALUE_CHANGE:
      return onSymptomUnselected(state, action);
    case ADD_SYMPTOM_SEARCH_SUCCESS:
      return onAddSymptomFromSearch(state, action);
    case CLEAR_COMPLAINT:
      return onComplaintClear(state, action);
    case ADDED_SYMPTOMS_INITIALIZE:
      return onAddedSymptomsInitialize(state, action);
    default:
      return state;
  }
};
