import { reducers as benefits } from 'medication_service_ui/dist/benefits';
import { reducers as settings } from 'medication_service_ui/dist/settings';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import {
  DrugInteractionReducer as drugInteraction,
  PrescriptionReducer as prescription,
  PendingPrescriptionsReducer as pendingPrescriptions,
  ClinicalAllergyReducer as clinicalAllergies,
  MedicationHistoryReducer as medicationHistory,
  FormularyReducer as formulary,
  PrescriptionBenefitsReducer as prescriptionBenefits,
  PayerSelectorReducer as eligibility
} from 'medication_service_ui';
import { reducer as personalHealthRecordReducer } from '../PersonalHealthRecords/reducers/index';
import { conferenceReducer } from '../Conference';
import { reducer as clinicalMedications } from '../ClinicalMedication/reducers';
import { reducer as provider } from '../Provider/reducers';
import { reducer as patient } from '../Patient/reducers';
import { reducer as providerOrder } from '../ProviderOrder/reducers';
import medicationReview from '../medication-review/reducers';
import consultNavigation from '../consult-navigation/reducers';
import notifications from '../notifications/reducers';
import subjectiveTabReducers from '../subjective-tab/reducers';
import erxErrors from '../erx-errors/reducers';
import networkTest from '../network-test/reducers';
import alert from '../components/alert/reducers';
import sidebar from '../sidebar/reducers';
import { surveys } from 'app/surveys/reducers/';
import { reducer as secondaryDiagnoses } from '../components/SecondaryDiagnosesContainer/redux/reducers';
import { primaryDiagnosisReducer } from '../QuickDx/redux/primary-diagnosis-reducer';
import needsResponseMessagesReducer from '../Message/needsResponseMessagesReducer';
import ehr from '../ehr/redux/reducers';
import { consultFollowupReducer } from '../consult-followup/state-managment/redux';
import { MSEReducer as mentalStatusExam } from '../components/objective-tab/mental-status-exam/redux/reducer';

export const rootReducer = combineReducers({
  conference:              conferenceReducer,
  form:                    formReducer,
  personalHealthDocuments: personalHealthRecordReducer,
  consultFollowup:         consultFollowupReducer,
  primaryDiagnosis:        primaryDiagnosisReducer,
  clinicalMedications,
  medicationHistory,
  formulary,
  prescriptionBenefits,
  eligibility,
  provider,
  patient,
  prescription,
  providerOrder,
  drugInteraction,
  benefits,
  settings,
  notifications,
  pendingPrescriptions,
  consultNavigation,
  clinicalAllergies,
  medicationReview,
  surveys,
  alert,
  ...subjectiveTabReducers,
  erxErrors,
  networkTest,
  sidebar,
  secondaryDiagnoses,
  needsResponseMessagesReducer,
  ehr,
  mentalStatusExam
});

export default rootReducer;
