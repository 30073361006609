import React from 'react';
import cx from 'classnames';

import PropTypes from 'prop-types';

class GuidedMenuItem extends React.Component {
  onClick = () => {
    const { step, onClick } = this.props;
    onClick(step);
  };

  render() {
    const {
      item,
      step,
      selectedIndex,
      totalSteps,
      touched,
      item: { valid }
    } = this.props;

    const isCurrent = selectedIndex === step;
    const isInvalid = touched && !valid && !isCurrent;
    // && !isCurrent - if we don't want to show that current tab has validation problem

    const className = cx('guided-menu-item', 'clickable', {
      current: isCurrent,
      valid:   valid && !isInvalid,
      invalid: isInvalid,
      wide:    item.key === 'reviews'
    });

    return (
      <li onClick={this.onClick} style={{ zIndex: totalSteps - step }} className={className}>
        <div>{item.name}</div>
      </li>
    );
  }
}

GuidedMenuItem.propTypes = {
  item:          PropTypes.object.isRequired,
  step:          PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  totalSteps:    PropTypes.number.isRequired,
  onClick:       PropTypes.func.isRequired,
  touched:       PropTypes.bool.isRequired
};

export default GuidedMenuItem;
