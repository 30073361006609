import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import
  DurationSeverityProgressionFormContainer
from './duration-severity-progression-form-container';
import { fetchDropdownValues } from '../../actions';

export class DurationSeverityProgressionContainer extends Component {
  componentDidMount() {
    if (!this.props.isSuccess) {
      this.props.fetchDropdownValues();
    }
  }

  componentWillUnmount() {
    if (this.props.isLoading) {
      this.props.cancelFetchDropdownValues();
    }
  }

  render() {
    if (!this.props.isSuccess) {
      return null;
    }

    return (
      <DurationSeverityProgressionFormContainer />
    );
  }
}

DurationSeverityProgressionContainer.propTypes = {
  fetchDropdownValues:       PropTypes.func.isRequired,
  cancelFetchDropdownValues: PropTypes.func.isRequired,
  isSuccess:                 PropTypes.bool.isRequired,
  isLoading:                 PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isSuccess: state.durationSeverityProgressionRequest.isSuccess,
  isLoading: state.durationSeverityProgressionRequest.isLoading
});

const mapDispatchToProps = {
  fetchDropdownValues:       fetchDropdownValues.start,
  cancelFetchDropdownValues: fetchDropdownValues.cancel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DurationSeverityProgressionContainer);
