import React from 'react';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';
import styles from './styles.module.scss';

const EndCallModal = ({ isShow, onClose, onEndCall, pipWindow }) => {
  const modalContainer = pipWindow.document.querySelector('#modal_container');
  const ModalComponent = AriaModal.renderTo(modalContainer);

  const onConfirm = () => {
    onEndCall();
    onClose();
    pipWindow.close();
    window.focus();
  }

  return (
    <ModalComponent
      underlayClass='pip-aria-modal'
      dialogId='pip-aria-modal'
      dialogClass='pip-modal-container'
      titleText='confirmation modal'
      mounted={isShow}
      onExit={onClose}
      includeDefaultStyles={false}
      focusTrapPaused={true}
      scrollDisabled={false}
    >
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <span>
            Are you sure you want to end the call?
          </span>
          <button
            className={styles.modalCloseButton}
            onClick={onClose}
          >
            ✖
          </button>
        </div>
        <div className={styles.modalBody}>
          <span>
            End the call will disconnect the patient,
            only end the call when you are finished discussing
            the consult with the patient
          </span>
        </div>
        <div className={styles.modalFooter}>
          <button
            className='button secondary'
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className='button end-call-button'
            onClick={onConfirm}
          >
            End call
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

EndCallModal.propTypes = {
  isShow: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEndCall: PropTypes.func.isRequired,
  pipWindow: PropTypes.object.isRequired
}

export default React.memo(EndCallModal);
