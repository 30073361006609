import React, { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import TeladocModal from '../TeladocModal';
import './ScheduleConsultationMemberAvailability.scss';
import Calendar from '../../app/datepicker/datepicker.js';
import calendarIcon from '../../app/assets/images/calendar-icon.svg';
import deleteIcon from '../../app/assets/images/trash-icon.svg';
import { authToken, translate, useUpdater } from '@td/shared_utils';
import MemberAvailability from '../consult-queues/components/MemberAvailability/MemberAvailability';
import ConsultationSchedulingSuccess from './ConsultationSchedulingSuccess';
import useFetchProviderLockedSlots from './useFetchProviderLockedSlots.js';
import { useScheduledVistsTable } from '../consult-queues/components/ScheduledVisitsTable/ScheduledVistsTableProvider';
import { useRequestedAndProposedVisitsTable } from '../consult-queues/components/RequestedAndProposedVisitsTable/RequestedAndProposedVisitsTableProvider';
import { get, isArray, map } from 'lodash';
import {
  BROWSER_TIMEZONE,
  dateIsIncludedInMemberAvailability,
  dayIsIncludedInMemberAvailability,
  gapBetweenSlots,
  getDayOfTheWeek,
  isDateAfter24Hours,
  isTimeInNext48Hours,
  isWithinDisabledTime,
  timeIsBetween
} from './helpers.js';
import { useData } from '../DataProvider';
import { useMatchesForWaitlistTable } from '../consult-queues/components/MatchesForWaitlistTable/MatchesForWaitlistTableProvider.js';

const ScheduleConsultationMemberAvailability = ({
  isModalOpen,
  setIsModalOpen,
  memberAvailability,
  consultationId,
  memberId,
  isInitial,
  waitlistId,
  consultStatus
}) => {
  const { role, displayProviderMemberWaitlist } = useData();
  const validationErrorRef = useRef(null);
  const { resetPagination: resetSheduledVisitTable } = useScheduledVistsTable();
  const { resetPagination: resetReqAndProposedVisitTable } = useRequestedAndProposedVisitsTable();
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedBackupDates, setSelectedBackupDates] = useState({
    firstPreference: '',
    secondPreference: ''
  });
  const [visitDateAndTimeDatePickerRef, setVisitDateAndTimeDatePickerRef] = useState('');
  const [firstBackupDatePickerRef, setFirstBackupDatePickerRef] = useState('');
  const [secondBackupDatePickerRef, setSecondBackupDatePickerRef] = useState('');
  const [optionalNote, setOptionalNote] = useState('');
  const [validationError, setValidationError] = useState('');
  const [payload, setPayload] = useState('');

  const isWaitlistConsult = displayProviderMemberWaitlist && consultStatus === 'CONSULTSTATUS_WAITLIST';
  const { refetchWaitlist } = useMatchesForWaitlistTable() || {};

  const scheduleRequest = useUpdater('post', {
    url: `/consultations/${consultationId}/propose`,
    method: 'post',
    headers: { Authorization: `${authToken.get()}` },
    baseURL: window.location.origin
  });

  const { data: { lockedSlots = [] } = {} } = useFetchProviderLockedSlots(memberId);
  const providerLockedTimes = lockedSlots.map(day => new Date(day));

  const handleCancel = () => {
    setValidationError('');
    setIsModalOpen(false);
  };

  const handleRefetch = () => {
    resetReqAndProposedVisitTable();
    resetSheduledVisitTable();
    refetchWaitlist && refetchWaitlist(isWaitlistConsult);
  };

  const handleSuccess = () => {
    scheduleRequest.finished && scheduleRequest.data && handleRefetch();
    handleCancel();
  };

  const formatDate = (date, includeTimeZone = true) => {
    if (!date) return null;

    const formattedDate = moment(date).format('Y/M/D h:mm A');

    if (includeTimeZone) {
      const dateTZ = moment(date)
        .tz(BROWSER_TIMEZONE)
        .format('zz');
      return `${formattedDate} ${dateTZ}`;
    }

    return formattedDate;
  };

  const handleSubmit = async => {
    // Validate that at least one date is selected
    if (!selectedDate && !selectedBackupDates.firstPreference && !selectedBackupDates.secondPreference) {
      return setValidationError(translate(null, 'member_availability.schedule_modal.errors', 'no_date_selected'));
    }
    // Define the format string for parsing the input date strings
    const inputDateFormat = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)';

    // Converting selectedDate and selectedBackupDates to Moment objects
    const momentSelectedDate = moment(selectedDate ? selectedDate : null, inputDateFormat);
    const momentFirstPreference = moment(
      selectedBackupDates.firstPreference ? selectedBackupDates.firstPreference : null,
      inputDateFormat
    );
    const momentSecondPreference = moment(
      selectedBackupDates.secondPreference ? selectedBackupDates.secondPreference : null,
      inputDateFormat
    );

    // Setting milliseconds to 0 and converting to ISO strings
    const isoSelectedDate = momentSelectedDate.milliseconds(0).toISOString();
    const isoFirstPreference = momentFirstPreference.milliseconds(0).toISOString();
    const isoSecondPreference = momentSecondPreference.milliseconds(0).toISOString();

    // Comparing for uniqueness of selected times
    if (
      (isoFirstPreference && isoSelectedDate === isoFirstPreference) ||
      (isoSecondPreference && isoSelectedDate === isoSecondPreference) ||
      (isoFirstPreference && isoFirstPreference === isoSecondPreference && isoSecondPreference)
    ) {
      return setValidationError(translate(null, 'consultation_negotiation.errors', 'unique_times'));
    }

    const finalBackupDates = [selectedBackupDates.firstPreference, selectedBackupDates.secondPreference];

    const formattedBackupDates = finalBackupDates.filter(date => date).map(date => formatDate(date));

    const formattedConfirmDate = formatDate(selectedDate);

    const params = {
      consultation: {
        scheduling_notes: optionalNote,
        confirmed_date: formattedConfirmDate,
        proposed_dates: formattedBackupDates,
        negotiation_type:
          displayProviderMemberWaitlist && isWaitlistConsult
            ? 'member_waitlist'
            : 'provider_member_availability'
      }
    };

    if (displayProviderMemberWaitlist && isWaitlistConsult) {
      params.consultation.waitlist_id = waitlistId;
    }

    setPayload(params);
    scheduleRequest.callApi(params);

    // Reset validation error
    setValidationError('');
  };

  const renderCalenderIcon = () => {
    return (
      <span>
        <img className="calendarIcon" src={calendarIcon} />
      </span>
    );
  };
  const selectDateElement = () => {
    return (
      <div className="defaultScheduleInput">
        {renderCalenderIcon()}
        <span>Select</span>
      </div>
    );
  };

  const selectedDateElement = () => {
    if (selectedDate) {
      return (
        <div className="selectedScheduleDate">
          {renderCalenderIcon()}
          <div className="selectedDate">{moment(selectedDate).format('MMM D, h:mm A')}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  const SelectedBackupDateElement = ({ selectedBackupDate, handleDelete }) => {
    return (
      <div className="selectedScheduleDate">
        <span>
          <img className="calendarIcon" src={calendarIcon} />
        </span>
        <div className="backupDatesWrapper">
          <div className="selectedDate">{moment(selectedBackupDate).format('MMM D, h:mm A')}</div>
          <img onClick={handleDelete} className="deleteBackupDatesIcon" src={deleteIcon} alt="Delete" />
        </div>
      </div>
    );
  };

  const getRefForVisitDateAndTime = datePickerRef => {
    setVisitDateAndTimeDatePickerRef(datePickerRef);
  };

  const getRefForFirstBackupDatePicker = datePickerRef => {
    setFirstBackupDatePickerRef(datePickerRef);
  };

  const getRefForSecondBackupDatePicker = datePickerRef => {
    setSecondBackupDatePickerRef(datePickerRef);
  };

  // Gets triggered on click of done
  const handleConfirmDate = date => {
    if (!filterCalendarTime(date)) {
      setValidationError(translate(null, 'member_availability.schedule_modal.errors', 'no_date_selected'));
      visitDateAndTimeDatePickerRef && visitDateAndTimeDatePickerRef.setOpen(true);
      setSelectedDate('');
      return;
    }
    setSelectedDate(date);
    setValidationError('');
  };

  const handleConfirmBackupDate = (date, preference) => {
    if (!filterCalendarTime(date)) {
      setValidationError(translate(null, 'member_availability.schedule_modal.errors', 'no_date_selected'));
      if (preference === 'firstPreference' && firstBackupDatePickerRef) {
        firstBackupDatePickerRef.setOpen(true);
      } else if (preference === 'secondPreference' && secondBackupDatePickerRef) {
        secondBackupDatePickerRef.setOpen(true);
      }
      const modalContainer = document.querySelector('.teladocModalContainer');
      if (modalContainer) {
        modalContainer.scrollTop = modalContainer.scrollHeight;
      }
      return;
    }
    setSelectedBackupDates(prevDates => ({
      ...prevDates,
      [preference]: date
    }));
    setValidationError('');
  };

  const handleDelete = preference => {
    return () => {
      setSelectedBackupDates(prevDates => ({
        ...prevDates,
        [preference]: ''
      }));
    };
  };

  if (scheduleRequest.finished && !isModalOpen) {
    window.location.reload();
  }

  const memberAvailabilityData = memberAvailability && memberAvailability.memberAvailabilityJson;
  const originalTimeZone = memberAvailability.timezone;

  const memberAvailabilityWithTimezone =
    memberAvailabilityData &&
    Object.keys(memberAvailabilityData).reduce(
      (result, day) => ({
        ...result,
        [day]: memberAvailabilityData[day].map(range => ({
          startTime: moment(range.startTime, 'H:mm').tz(originalTimeZone),
          endTime: moment(range.endTime, 'H:mm').tz(originalTimeZone)
        }))
      }),
      {}
    );

  const filterCalendarDates = date => {
    if (!isDateAfter24Hours(date)) return false;
    return dateIsIncludedInMemberAvailability(date, memberAvailabilityData);
  };

  const filterCalendarTime = time => {
    if (!isDateAfter24Hours(time)) return false;
    if (isTimeInNext48Hours(time)) return false;
    if (providerLockedTimes.some(day => moment(time).isSame(moment(day)))) return false;

    const dayOfWeek = getDayOfTheWeek(time);
    if (!dayIsIncludedInMemberAvailability(dayOfWeek, memberAvailabilityData)) return false;

    const selectedTimeInOriginalTimeZone = moment(time).tz(originalTimeZone);
    return memberAvailabilityWithTimezone[dayOfWeek].some((range, index) => {
      const dailySlots = memberAvailabilityWithTimezone[dayOfWeek].length;
      const isLastSlot = dailySlots === index + 1;
      const nextSlot = memberAvailabilityWithTimezone[dayOfWeek][index + 1];
      const isGapBetweenSlots = gapBetweenSlots(index, dailySlots, range, nextSlot);
      const disabledTime = isLastSlot || (!isLastSlot && isGapBetweenSlots) ? isWithinDisabledTime(role, isInitial) : 0;

      const startTimeInOriginalTimeZone = range.startTime;
      const endTimeInOriginalTimeZone = range.endTime;
      const startTimeInUserTimeZone = startTimeInOriginalTimeZone.tz(BROWSER_TIMEZONE);
      const endTimeInUserTimeZone = endTimeInOriginalTimeZone.tz(BROWSER_TIMEZONE);
      return timeIsBetween(selectedTimeInOriginalTimeZone, startTimeInUserTimeZone, endTimeInUserTimeZone, disabledTime);
    });
  };

  const scheduleRequestError = get(scheduleRequest, ['error', 'response', 'data', 'error']);

  useEffect(() => {
    if (scheduleRequestError) {
      setValidationError(scheduleRequestError);
    } else {
      setValidationError('');
    }
  }, [scheduleRequestError]);

  const renderErrors = error => {
    if (isArray(error)) {
      const errors = map(error, e => <p>{e}</p>);
      return <div className="validationError">{errors}</div>;
    } else {
      return <div className="validationError">{error}</div>;
    }
  };

  const scrollToValidationError = () => {
    if (validationErrorRef.current) {
      validationErrorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  useEffect(() => {
    scrollToValidationError();
  }, [validationError]);

  return (
    <Fragment>
      <TeladocModal
        className={'scheduleModal'}
        title={
          scheduleRequest.finished && scheduleRequest.data
            ? translate(null, 'member_availability.schedule_modal.visit_scheduled_modal', 'title')
            : translate(null, 'member_availability.schedule_modal', 'title')
        }
        isOpen={isModalOpen}
        onClose={handleCancel}
      >
        {scheduleRequest.finished && scheduleRequest.data ? (
          <ConsultationSchedulingSuccess payload={payload} data={scheduleRequest.data} handleSuccess={handleSuccess} />
        ) : (
          <Fragment>
            {validationError && renderErrors(validationError)}
            <div className="subHeading">
              <p className="subHeadingText">{translate(null, 'member_availability.schedule_modal', 'guideline')}</p>
            </div>

            <div className="patientPreferencesAndNote">
              <div className="patientPrefernces">
                <b className="patientPreferncesLabel">
                  {translate(null, 'member_availability.schedule_modal', 'patient_preferences')}
                </b>
                <div className="patientPreferncesDaysWrapper">
                  {memberAvailability && <MemberAvailability data={memberAvailability} displayInScheduleModal={true} />}
                </div>
              </div>

              <div className="noteFromPatient">
                <b className="noteFromPatientLabel">
                  {translate(null, 'member_availability.schedule_modal', 'note_from_patient')}
                </b>
                {memberAvailability && Object.keys(memberAvailability).length && (
                  <p className="noteFromPatientText">{memberAvailability.notes}</p>
                )}
              </div>
            </div>

            <div className="visitDateAndTime">
              <b className="visitDateAndTimeDatePicker">
                {translate(null, 'member_availability.schedule_modal', 'visit_date_and_time')}
              </b>
              <div>
                <Calendar
                  selectedDate={selectedDate}
                  filterTime={filterCalendarTime}
                  timezone={BROWSER_TIMEZONE}
                  maxDate={90}
                  filterDate={filterCalendarDates}
                  customInputComponent={!selectedDate ? selectDateElement() : selectedDateElement()}
                  getRef={getRefForVisitDateAndTime}
                  onConfirmDate={handleConfirmDate}
                />
              </div>
            </div>

            <div className="backupDateAndTime">
              <b className="visitDateAndTimeDatePicker">
                {translate(null, 'member_availability.schedule_modal', 'backup_dates_and_time')}
              </b>
              <div className="datePickerWrapper">
                <div className="backupDatePrefernces">
                  {!selectedBackupDates.firstPreference ? (
                    <Calendar
                      selectedDate={selectedBackupDates.firstPreference}
                      filterTime={filterCalendarTime}
                      timezone={BROWSER_TIMEZONE}
                      maxDate={90}
                      filterDate={filterCalendarDates}
                      customInputComponent={selectDateElement()}
                      onConfirmDate={date => handleConfirmBackupDate(date, 'firstPreference')}
                      getRef={getRefForFirstBackupDatePicker}
                    />
                  ) : (
                    selectedBackupDates.firstPreference && (
                      <SelectedBackupDateElement
                        selectedBackupDate={selectedBackupDates.firstPreference}
                        handleDelete={handleDelete('firstPreference')}
                      />
                    )
                  )}
                </div>
                <div className="backupDatePrefernces">
                  {!selectedBackupDates.secondPreference ? (
                    <Calendar
                      selectedDate={selectedBackupDates.secondPreference}
                      filterTime={filterCalendarTime}
                      timezone={BROWSER_TIMEZONE}
                      maxDate={90}
                      filterDate={filterCalendarDates}
                      customInputComponent={selectDateElement()}
                      onConfirmDate={date => handleConfirmBackupDate(date, 'secondPreference')}
                      getRef={getRefForSecondBackupDatePicker}
                    />
                  ) : (
                    selectedBackupDates.secondPreference && (
                      <SelectedBackupDateElement
                        selectedBackupDate={selectedBackupDates.secondPreference}
                        handleDelete={handleDelete('secondPreference')}
                      />
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="optionalNote">
              <p className="optionalNoteLabel">
                {translate(null, 'member_availability.schedule_modal', 'optional_note_to_patient')}
              </p>
              <input
                className="optionalNoteInput"
                value={optionalNote}
                onChange={e => setOptionalNote(e.target.value)}
              />
            </div>

            <div className="actionButtons">
              <button
                id="dialogCancelButton"
                key="dialogCancelButton"
                className="secondary button"
                onClick={handleCancel}
              >
                CANCEL
              </button>
              <button id="dialogConfirmButton" key="dialogConfirmButton" className="button" onClick={handleSubmit}>
                Schedule
              </button>
            </div>
          </Fragment>
        )}
      </TeladocModal>
    </Fragment>
  );
};

export default ScheduleConsultationMemberAvailability;
