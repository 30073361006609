import { teladocApi, TdApiRoutes, authToken } from '@td/api';
import { CALL_STATUS_CHANGE, MUTE_TOGGLE } from './actionTypes';
import { DISCONNECTING } from '../lib/states';

export const updateCallStatus = updateParams => ({
  type:    CALL_STATUS_CHANGE,
  payload: updateParams
});

export const disconnectParticipant = ({ conferenceId, attendanceId }) => {
  teladocApi.post(
    TdApiRoutes.media.dropAttendancePath(),
    { conference_id: conferenceId, attendance_id: attendanceId },
    authToken.get()
  );

  return {
    type:    CALL_STATUS_CHANGE,
    payload: {
      data: {
        attendanceId, callStatus: DISCONNECTING
      }
    }
  };
};

export const toggleMute = ({ attendanceId, muted, conferenceId }) => {
  const toggledMute = !muted;

  teladocApi.put(
    TdApiRoutes.media.updateAttendancePath(attendanceId),
    { conference_id: conferenceId, attendance: { muted: toggledMute } },
    authToken.get()
  );

  return {
    type:    MUTE_TOGGLE,
    payload: { attendanceId, muted: toggledMute }
  };
};
