import React from 'react';
import PropTypes from 'prop-types';

const ProviderOrderList = ({ children, displayOptions: { showRequester, showActions } }) => (
  <div className="pending_prescriptions" id="pending_prescriptions">
    {showRequester ? <h3>Submitted Prescriptions</h3> : <h4>Submitted Prescriptions:</h4>}

    <table className="table">
      <thead>
        <tr>
          <th style={{ width: '45%' }}>Drug Name</th>
          <th>Total Quantity</th>
          <th>Instruction</th>
          {showRequester && <th>Requested By</th>}
          <th>Status</th>
          {showActions && <th>Actions</th>}
        </tr>
      </thead>

      {children.length !== 0 ? (
        children
      ) : (
        <tbody>
          <tr>
            <td colSpan={detailed ? 5 : 4}>There is no teladoc reported prescription.</td>
          </tr>
        </tbody>
      )}
    </table>
  </div>
);

ProviderOrderList.propTypes = {
  editMode:       PropTypes.bool.isRequired,
  children:       PropTypes.arrayOf(PropTypes.node).isRequired,
  displayOptions: PropTypes.object.isRequired
};

export default ProviderOrderList;
