import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@td/shared_utils';
import { capitalize } from 'lodash';
import ConfirmationModal from '../confirmation-modal';

import styles from './styles.module.scss';

const TRANSLATION_SCOPE = 'my_schedule.modals.remove_account_confirmation';

const RemoveAccountConfirmationModal = ({ account, onConfirm, onClose }) => (
  <ConfirmationModal
    className={styles.removeAccountConfirmationModal}
    title={translate(null, TRANSLATION_SCOPE, 'title', { emailAddress: account.emailAddress })}
    onConfirm={onConfirm}
    onCancel={onClose}
    onClose={onClose}
    cancelButtonText={translate(null, TRANSLATION_SCOPE, 'actions.cancel')}
    confirmButtonText={translate(null, TRANSLATION_SCOPE, 'actions.confirm')}
  >
    <p>
      {translate(null, TRANSLATION_SCOPE, 'note', { calendarType: capitalize(account.calendarType) })}
    </p>
  </ConfirmationModal>
);

RemoveAccountConfirmationModal.propTypes = {
  account: PropTypes.shape({
    id:              PropTypes.string,
    emailAddress:    PropTypes.string.isRequired,
    expired:         PropTypes.bool,
    isSynchronizing: PropTypes.bool,
    calendarTypeCd:  PropTypes.string.isRequired,
    calendars:       PropTypes.arrayOf(
      PropTypes.shape({
        id:              PropTypes.string,
        name:            PropTypes.string,
        active:          PropTypes.bool,
        isSynchronizing: PropTypes.bool
      })
    )
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose:   PropTypes.func.isRequired
};

export default RemoveAccountConfirmationModal;
