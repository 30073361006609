exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._2wOV84-ICHRQ-RfV6c3Rtt {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 14px;\n  color: #000000;\n  border: none;\n  background: #ffffff;\n  margin: 0;\n  padding: 0;\n  flex-shrink: 0;\n  font-weight: bold; }\n  ._2wOV84-ICHRQ-RfV6c3Rtt svg {\n    margin-left: 6px;\n    margin-top: 2px; }\n", ""]);

// exports
exports.locals = {
	"callDetailsButton": "_2wOV84-ICHRQ-RfV6c3Rtt",
	"callDetailsButton": "_2wOV84-ICHRQ-RfV6c3Rtt"
};