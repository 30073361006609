exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._11Ft-a-ayJOflMRim4Wi20 {\n  display: flex;\n  align-items: center;\n  column-gap: 0.5rem; }\n", ""]);

// exports
exports.locals = {
	"recommendedWorkingHoursToggle": "_11Ft-a-ayJOflMRim4Wi20",
	"recommendedWorkingHoursToggle": "_11Ft-a-ayJOflMRim4Wi20"
};