import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';
import ProposedDatesTooltip from '../../ProposedDatesTooltip';

const ViewProposalAction = ({ proposedDates, consultationId }) => {
  const TOOLTIP_ID = `view-proposal-action-${consultationId}`;

  return (
    <Fragment>
      <a data-place="bottom" data-tip data-for={TOOLTIP_ID}>
        {I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.actions.view_proposal')}
      </a>
      <ReactTooltip className="view-proposal-dates-tooltip" id={TOOLTIP_ID}>
        <ProposedDatesTooltip proposedDates={proposedDates} />
      </ReactTooltip>
    </Fragment>
  );
};

ViewProposalAction.propTypes = {
  proposedDates: PropTypes.array.isRequired
};

export default ViewProposalAction;
