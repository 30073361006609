import React from 'react';
import PatientListProvider from '../PatientListProvider';
import PatientList from '../PatientList';
import PatientListPagination from '../PatientListPagination';
import PatientListToolbar from '../PatientListToolbar';

const PatientListContainer = () => (
  <PatientListProvider>
    <div className="patient-list-container">
      <PatientListToolbar />
      <PatientList />
      <PatientListPagination />
    </div>
  </PatientListProvider>
);

export default PatientListContainer;
