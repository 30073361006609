import { createSelector } from '@td/utils';
import {
  getNegativeSymptomsWithNotes,
  getPositiveSymptomsWithNotes
} from '../symptoms/getSymptomsWithNotes';

const oxfordCommaJoin = arr =>
  arr.slice(0, -2).join(', ') +
  (arr.slice(0, -2).length ? ', ' : '') +
  arr.slice(-2).join(', and ');

const getSymptomsDetails = symptoms =>
  oxfordCommaJoin(
    symptoms.map(
      symptom => `${symptom.name.toLowerCase()}${symptom.note ? ` (${symptom.note})` : ''}`
    )
  );

export default createSelector(
  getPositiveSymptomsWithNotes,
  getNegativeSymptomsWithNotes,
  (positiveSymptoms, negativeSymptoms) =>
    `${
      positiveSymptoms.length === 0
        ? ''
        : `Patient has associated ${getSymptomsDetails(positiveSymptoms)}. `
    }` +
    `${
      negativeSymptoms.length === 0
        ? ''
        : `Patient denies ${getSymptomsDetails(negativeSymptoms)}. `
    }`
);
