import React from 'react';
import RequiredActionsTable from '../RequiredActionsTable';
import RequestedAndProposedVisitsTable from '../RequestedAndProposedVisitsTable';
import ScheduledVisitsTable from '../ScheduledVisitsTable';
import MatchesForWaitlistTable from '../MatchesForWaitlistTable';
import ConsultQueuesProvider from './ConsultQueuesProvider';
import { useData } from '../../../DataProvider';

const ConsultQueues = ({ offByAdmin, hasActiveLicense }) => {
  const { displayProviderMemberWaitlist } = useData();
  return (
    <ConsultQueuesProvider>
      <div className="consult-queues-container">
        <RequiredActionsTable />
        <RequestedAndProposedVisitsTable />
        {displayProviderMemberWaitlist && hasActiveLicense && !offByAdmin && <MatchesForWaitlistTable />}
        <ScheduledVisitsTable />
      </div>
    </ConsultQueuesProvider>
  );
};

export default ConsultQueues;
