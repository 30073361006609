import React from 'react';
import PropTypes from 'prop-types';
import ActionsContainer from '../ActionsContainer';
import ReviewAction from '../actions/ReviewAction';

const RequiredActionsTableActions = props => {
  return <ActionsContainer>{[<ReviewAction {...props} />]}</ActionsContainer>;
};

RequiredActionsTableActions.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default RequiredActionsTableActions;
