import $ from 'jquery';

function NoConsultationIdError() {
  this.name = 'NoConsultationIdError';
  this.message = 'cacheInterview called without consultationId';
}

export default (id, $form = $('#consult_form'), authorizationToken = $('meta[name=csrf-token]').attr('content')) => {
  let consultationId = id;
  if (!$form.length) {
    return;
  }

  if (!consultationId && window) {
    const idMatch = window.location.pathname.match(/consultations\/(\d+)/) || [];
    consultationId = idMatch[1];
  }

  if (!consultationId) {
    throw new NoConsultationIdError();
  }

  $.ajax({
    method:  'post',
    url:     `/consultations/${consultationId}/cache_interview`,
    data:    $form.serialize(),
    headers: {
      'X-CSRF-Token': authorizationToken,
      ...window.TELADOC_ENVELOP
    }
  });
};
