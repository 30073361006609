import PropTypes from 'prop-types';

export default PropTypes.shape({
  complaintId:         PropTypes.number,
  complaintText:       PropTypes.string,
  other:               PropTypes.string,
  noteFormat:          PropTypes.string,
  durationRequired:    PropTypes.bool,
  severityRequired:    PropTypes.bool,
  progressionRequired: PropTypes.bool
});
