import { teladocApi, authToken } from '@td/api';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';

import { FETCH_COMPLAINTS_LIST, FETCH_COMPLAINTS_CLASSES, CLEAR_COMPLAINT } from '../action-types';
import { durationSeverityProgressionForm } from '../actions';

import { fetchOrigin$ } from '../../lib/utils';
import createRequestEpic from '../../lib/redux/utilities/create-request-epic';

export const fetchComplaintsListEpic = createRequestEpic(FETCH_COMPLAINTS_LIST, ({ action }) =>
  fetchOrigin$(`/complaints_lookup?name_term=${action.payload}`).pipe(
    map(ajaxResponse => ({
      data: {
        complaints: ajaxResponse.response
      }
    }))
  )
);

export const fetchComplaintClassesEpic = createRequestEpic(FETCH_COMPLAINTS_CLASSES, ({ action }) =>
  from(
    teladocApi.get(
      '/v4/subjective_ref_complaints',
      {
        specialty: action.payload
      },
      authToken.get()
    )
  ).pipe(
    map(resp => ({
      data: {
        complaintsClasses: resp.data
      }
    }))
  )
);

export const clearComplaintEpic = action$ =>
  action$.pipe(
    ofType(CLEAR_COMPLAINT),
    switchMap(() => of(durationSeverityProgressionForm.reset()))
  );

export default combineEpics(fetchComplaintsListEpic, fetchComplaintClassesEpic, clearComplaintEpic);
