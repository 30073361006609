import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const CallDetailsItem = React.memo(({ label, value }) => (
  <div className={styles.callDetailsItem}>
    <div className={styles.callDetailsItemLabel} data-testid="call-details-item-label">
      {label}
    </div>
    <div className={styles.callDetailsItemValue} data-testid="call-details-item-value">
      {value}
    </div>
  </div>
));

CallDetailsItem.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired
};

export default CallDetailsItem;
