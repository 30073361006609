import { teladocApi, authToken } from '@td/api';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';

import { FETCH_SYMPTOMS, FETCH_SYMPTOM_CLASSES } from '../action-types';

import createRequestEpic from '../../lib/redux/utilities/create-request-epic';
import { fetchOrigin$ } from '../../lib/utils';

export const fetchSymptomsEpic = createRequestEpic(FETCH_SYMPTOMS, () =>
  fetchOrigin$('/symptoms_lookup').pipe(
    map(ajaxResponse => {
      const symptoms = ajaxResponse.response.map(symptom => ({
        name: symptom.symptom_nm,
        code: symptom.medical_symptom_code_id
      }));

      return {
        data: {
          allSymptoms:  symptoms.map(symptom => symptom.code),
          symptomsById: symptoms.reduce(
            (accumulatedSymptoms, currentSymptom) => ({
              ...accumulatedSymptoms,
              [currentSymptom.code]: currentSymptom
            }),
            {}
          )
        }
      };
    })
  )
);

export const fetchSymptomClassesEpic = createRequestEpic(FETCH_SYMPTOM_CLASSES, () =>
  from(teladocApi.get('/v4/subjective_ref_symptoms', authToken.get())).pipe(
    map(response => {
      const symptomClassesData = response.data.subjective_ref_symptoms;

      return {
        data: {
          allSymptomClasses:  symptomClassesData.map(symptomClassData => symptomClassData.symptom_class),
          symptomClassesById: symptomClassesData.reduce(
            (accumulatedSymptomClasses, currentSymptomClass) => ({
              ...accumulatedSymptomClasses,
              [currentSymptomClass.symptom_class]: {
                symptom_class: currentSymptomClass.symptom_class,
                symptoms:      currentSymptomClass.symptoms.map(symptom => symptom.medical_symptom_code_id)
              }
            }),
            {}
          )
        }
      };
    })
  )
);

export default combineEpics(fetchSymptomClassesEpic, fetchSymptomsEpic);
