import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import TeladocModal from '../../TeladocModal';
import '../add-addendum.scss';
import ADD_ADDENDUM from '../mutations/add_addendum.graphql';

const AddAddendumModal = ({
  isModalOpen = false,
  cancelCallback,
  consultation,
  updateCallBack,
  showActions = false,
  shouldAppendNotes = true,
  mentalHealthStatusExam,
  psychosocialStressors
}) => {
  const [count, setCount] = React.useState(0);
  const [note, setNote] = React.useState('');
  const [addendumNotes,setAddendumNotes] = React.useState([]);
  const [isNotifyPatient, setIsNotifyPatient] = React.useState('true');
  const noteTypeCd = 'NOTETYPE_ADDENDUM';
  const mentalHealthExamDetails =
    mentalHealthStatusExam && Object.keys(mentalHealthStatusExam).length
      ? mentalHealthStatusExam
      : consultation && consultation.mentalHealthExamSurvey;
  const psychosocialStressorDetails = psychosocialStressors
    ? psychosocialStressors
    : consultation && consultation.psychosocialStressors;
  const isMentalHealth = consultation && consultation.specialtyCode === "BEHAVHEALTH";
  const fetchDiagnosis = primaryDiagnosisFlg => {
    return (
      consultation.clinicalDiagnosis.length &&
      consultation.clinicalDiagnosis.filter(item => item.primaryDiagnosisFlg === primaryDiagnosisFlg)
    );
  };
  const primaryDiagnosis = fetchDiagnosis('Y');
  const secondaryDiagnosis = fetchDiagnosis('N');
  const handleChange = e => {
    const noteValue = (e.target.value).trim();
    setNote(noteValue);
    setCount(noteValue.length);
  };

  const handleMutationCompleted = data => {
    let notes = window.sessionStorage.getItem('addendumNote') ? JSON.parse(window.sessionStorage.getItem('addendumNote')) : [];
    notes && !notes.includes(data.clinicalNote.clinicalNote) && notes.push(data.clinicalNote.clinicalNote);
    // setting Added note in session Storage
    window.sessionStorage.setItem('addendumNote',JSON.stringify(notes));
    !shouldAppendNotes && window.sessionStorage && window.sessionStorage.getItem('addendumNote') && window.sessionStorage.removeItem('addendumNote');
    cancelCallback();
    !addendumNotes.length && setAddendumNotes(...notes);
    updateCallBack(data, notes);
    !showActions && document.querySelector('.soap_notes_and_addendum') && document.querySelector('.soap_notes_and_addendum').scrollIntoView();
  };

  const [addAddendum] = useMutation(ADD_ADDENDUM, {
    onCompleted: handleMutationCompleted
  });

  const onChangeValue = e => {
    setIsNotifyPatient(e.target.value);
  };

  const handleCancel = () => {
    setCount(0);
    setNote('');
    cancelCallback();
  };

  const handleSubmit = () => {
    addAddendum({ variables: { consultationId:  consultation.consultationId,
      note,
      memberNotifyFlg: JSON.parse(isNotifyPatient),
      noteTypeCd } });
  };

  const getDateWithTime = date => {
    const timeZone = moment.tz && moment.tz.guess();
    const timeZoneOffset = new Date().getTimezoneOffset();
    const abbr = moment.tz && moment.tz.zone(timeZone).abbr(timeZoneOffset);
    return `${moment(date).format('MM/DD/YYYY hh:mm A')} ${abbr}`;
  };

  useEffect(() => {
    let notesToAppend = [];
    const existingNotes = consultation ? consultation.addendumNotes: [];
    shouldAppendNotes && window.sessionStorage && window.sessionStorage.getItem('addendumNote') && notesToAppend.push(JSON.parse(window.sessionStorage.getItem('addendumNote')));
    const allAddendumNotes = [...existingNotes, ...notesToAppend]
    setAddendumNotes(allAddendumNotes.flatten().reverse());
  }, []);

  return (
    <TeladocModal
      className="addendumModal"
      title="Add addendum"
      isOpen={isModalOpen}
      onClose={handleCancel}
    >
      <div className="content">
        <b className="headerFont">Diagnosis</b>
        <div className="diagnosisWrapper">
          <div className="diagnosisContainer">
            <b className="noteHeader">Primary</b>
            <div className="diagnosisDetails">
              <b className="diagnosisCode">
                {primaryDiagnosis && primaryDiagnosis[0].diagnosisCd}
              </b>
              <p>
                {primaryDiagnosis && primaryDiagnosis[0].diagnosisNm}
              </p>
            </div>
          </div>

          {secondaryDiagnosis && (
            <div className="diagnosisContainer">
              <b className="noteHeader">Secondary</b>
              <div className="secondaryDiagnosisWrapper">
                {secondaryDiagnosis &&
                  secondaryDiagnosis.map(item => {
                    return (
                      <div className="diagnosisDetails">
                        <b className="diagnosisCode">{item.diagnosisCd}</b>
                        <p>{item.diagnosisNm}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        <b className="soapNotesHeaderFont">SOAP notes</b>
        <b className="displayFlex">
          {getDateWithTime(consultation.subjectNotes && consultation.subjectNotes.clinicalNoteDt)}
        </b>
        <div className='noteWrapper'>
          <div className="displayFlex">
            <b className="noteHeader">SUBJECTIVE</b>
            <p className="noteContainer">{consultation.subjectNotes && consultation.subjectNotes.note}</p>
          </div>
          {isMentalHealth && psychosocialStressorDetails &&
            <div className="displayFlex">
              <b className="noteHeader">Psychosocial Stressors</b>
              <div className='PsychosocialStressors'>
                {psychosocialStressorDetails.map(stressor => {
                  return <p>{stressor}</p>;
                })}
              </div>
            </div>
          }
        </div>

        <div className="displayFlex">
          <b className="noteHeader">OBJECTIVE</b>
          {!isMentalHealth && consultation.objectiveNotes && <p className="noteContainer noteWrapper">{consultation.objectiveNotes.note}</p>}
        </div>

        {isMentalHealth && mentalHealthExamDetails && Object.keys(mentalHealthExamDetails).length ? (
          <React.Fragment>
            <div>
              <b className="noteHeader">General</b>
              <p className="noteContainer alignMentalStatus">
                <b>Appearance: </b>
                {mentalHealthExamDetails.Appearance}
              </p>
              <p className="noteContainer alignMentalStatus">
                <b>Speech: </b>
                {mentalHealthExamDetails.Speech}
              </p>
              <p className="noteContainer alignMentalStatus">
                <b>Mood: </b>
                {mentalHealthExamDetails.Mood}
              </p>
              <p className="noteContainer alignMentalStatus">
                <b>Affect: </b>
                {mentalHealthExamDetails.Affect}
              </p>
              <p className="noteContainer alignMentalStatus">
                <b>Behavior: </b>
                {mentalHealthExamDetails.Behavior}
              </p>
            </div>
            <div>
              <b className="noteHeader">Cognition</b>
              <p className="noteContainer alignMentalStatus">
                <b>Orientation impairment: </b>
                {mentalHealthExamDetails['Orientation Impairment'] || mentalHealthExamDetails.OrientationImpairment}
              </p>
              <p className="noteContainer alignMentalStatus">
                <b>Attention: </b>
                {mentalHealthExamDetails.Attention}
              </p>
              <p className="noteContainer alignMentalStatus">
                <b>Memory impairment: </b>
                {mentalHealthExamDetails['Memory Impairment'] || mentalHealthExamDetails.MemoryImpairment}
              </p>
            </div>
            <div className='noteWrapper'>
              <b className="noteHeader">Thought</b>
              <p className="noteContainer alignMentalStatus">
                <b>Suicidality: </b>
                {mentalHealthExamDetails.Suicidality}
              </p>
              <p className="noteContainer alignMentalStatus">
                <b>Homicidality: </b>
                {mentalHealthExamDetails.Homicidality}
              </p>
              {mentalHealthExamDetails['safety plan'] && (
                <p className="noteContainer alignMentalStatus">
                  <b>Safety plan: </b>
                  {mentalHealthExamDetails['safety plan']}
                </p>
              )}
              <p className="noteContainer alignMentalStatus">
                <b>Thought process: </b>
                {mentalHealthExamDetails['Thought Process'] || mentalHealthExamDetails.ThoughtProcess}
              </p>
              <p className="noteContainer alignMentalStatus">
                <b>Thought content: </b>
                {mentalHealthExamDetails['Thought Content'] || mentalHealthExamDetails.ThoughtContent}
              </p>
              <p className="noteContainer alignMentalStatus">
                <b>Insight: </b>
                {mentalHealthExamDetails.Insight}
              </p>
              <p className="noteContainer alignMentalStatus">
                <b>Judgement: </b>
                {mentalHealthExamDetails.Judgement}
              </p>
            </div>
          </React.Fragment>
        ) : null}
        {isMentalHealth && consultation.objectiveNotes &&
          <div className='noteWrapper'>
            <div className="displayFlex">
              <b className="noteHeader">Note</b>
              <p className="noteContainer">{consultation.objectiveNotes.note}</p>
            </div>
          </div>
        }

        <div className='noteWrapper'>
          <div className="displayFlex">
            <b className="noteHeader">ASSESSMENT</b>
            <p className="noteContainer">{consultation.assessmentNotes && consultation.assessmentNotes.note}</p>
          </div>
        </div>
        
        <div className='noteWrapper'>
          <div className="displayFlex">
            <b className="noteHeader">PLAN</b>
            <p className="noteContainer">{consultation.planNotes && consultation.planNotes.note}</p>
          </div>
        </div>
        {Boolean(addendumNotes.length) ? (
          <React.Fragment>
            <b className="headerFont">Addendums</b>
            {addendumNotes.map(item => (
              <div className="displayFlex" key={item.clinicalNoteId}>
                <p className="noteHeader"><b>{getDateWithTime(item.clinicalNoteDt)}</b><br />
                  {(item.memberNotifyFlg === 'Y' || item.memberNotifyFlg === true) && 'Patient notified'}
                </p>
                <p className="noteContainer">{item.note}</p>
              </div>
            ))
          }
          </React.Fragment>
        ) : null }
      </div>
      <div className="noteDetails">
        <label className="fontBold" htmlFor="addendum-note">Note<br /><p className="fontNormal">10 characters minimum (<b>{count}</b>)</p></label>
        <textarea id="addendum-note" onChange={e => handleChange(e)} className="addendumText" name="addendum-note" rows="8" maxLength="8988" />
      </div>
      <div className="displayFlex notifyContainer">
        <label className="fontBold notifyOption" htmlFor="notify">Notify patient?</label>
        <div onChange={e => onChangeValue(e)}>
          <input type="radio" id="yesNotify" value="true" name="notifyOption" defaultChecked />
          <label htmlFor="yesNotify" className="notifyLabel">Yes, notify them about addendum to record</label>
          <input  type="radio" id="doNotNotify" value="false" name="notifyOption" />
          <label htmlFor="doNotNotify" className="notifyLabel">No, add to record without notifying them</label>
        </div>
      </div>
      <div className="actionButtons">
        <button
          id="dialogCancelButton"
          key="dialogCancelButton"
          className="secondary button"
          onClick={handleCancel}
        >
        CANCEL
      </button>
        <button
          id="dialogConfirmButton"
          key="dialogConfirmButton"
          className="button"
          onClick={handleSubmit}
          disabled={note.length < 10}
        >
        SIGN AND SAVE
      </button>
      </div>
    </TeladocModal>
  );
};

AddAddendumModal.propTypes = {
  isModalOpen:       PropTypes.bool,
  cancelCallback:    PropTypes.func,
  consultation:      PropTypes.object,
  updateCallBack:    PropTypes.func,
  showActions:       PropTypes.bool,
  shouldAppendNotes: PropTypes.bool,
  mentalHealthStatusExam: PropTypes.object
};

export default AddAddendumModal;
