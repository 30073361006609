import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaExclamationTriangle } from 'react-icons/fa';
import { I18n, translate, translateMarkupString } from '@td/shared_utils';
import TransferCall from './TransferCall';
import RetryCall from './RetryCall';
import { ATTENDEE_STATUSES, CONFERENCE_STATUSES, NON_CANCELLABLE_STATUSES } from '../constants/call-status';

const CallInProgress = ({
  callStatus,
  connectedViaPhone = false,
  handleCallPhone,
  handleEndCall,
  handleJoinCall,
  isMicrophoneAllowed,
  isMuted,
  setIsMuted,
  memberCallStatus,
  memberName,
  primaryPhone,
  providerCallStatus,
  secondaryPhone,
  setCallStatus,
  toggleMute,
  onCallJoin
}) => {
  const [showPhoneNumbers, setShowPhoneNumbers] = useState(false);
  const [ctaDisabled, setCtaDisabled] = useState(false);

  const isMicError =
    !isMicrophoneAllowed &&
    providerCallStatus !== ATTENDEE_STATUSES.JOINED &&
    memberCallStatus === ATTENDEE_STATUSES.JOINED;
  const isVendorConnError = callStatus === CONFERENCE_STATUSES.WEBRTC_DEVICE_ERROR;
  const isConfApiError = callStatus === CONFERENCE_STATUSES.BAD_REQUEST;

  const isError = isMicError || isVendorConnError || isConfApiError;

  const disableTransferToPhone =
    showPhoneNumbers || (isError && isMicrophoneAllowed) || memberCallStatus === ATTENDEE_STATUSES.DISCONNECTED;

  const joinCallHandler = () => {
    setCtaDisabled(true);
    handleJoinCall();
  };
  const endCallHandler = () => {
    setCtaDisabled(true);
    handleEndCall();
  };

  // Re-enable the CTA whenever the callStatus  or providerCallStatus changes
  useEffect(() => {
    setCtaDisabled(false);
  }, [callStatus, providerCallStatus]);

  const waitingProps = {
    title: translateMarkupString(
      'autodialer.call_interface.call_in_progress',
      'name_in_waiting_room',
      { span: <span /> },
      { name: memberName }
    ),
    buttonCta:        translate(null, 'autodialer.call_interface.call_in_progress', 'join_call_cta'),
    buttonCtaHandler: joinCallHandler,
    joinByPhone:      translate(null, 'misc', 'or'),
    joinByPhoneCta:   translate(null, 'autodialer.call_interface.call_in_progress', 'join_via_phone_cta'),
    phoneQuestion:    translate(null, 'autodialer.call_interface.call_in_progress', 'which_number_call'),
    callCta:          translate(null, 'autodialer.call_interface.call_in_progress', 'call')
  };
  const inProgressProps = {
    title: translateMarkupString(
      'autodialer.call_interface.call_in_progress',
      'on_call_with_name',
      { span: <span /> },
      { name: memberName }
    ),
    buttonCta:        translate(null, 'autodialer.call_interface.call_in_progress', 'end_call'),
    buttonCtaHandler: endCallHandler,
    joinByPhone:      translate(null, 'autodialer.call_interface.call_in_progress', 'connected_via_web'),
    joinByPhoneCta:   translate(null, 'autodialer.call_interface.call_in_progress', 'transfer_to_phone_cta'),
    phoneQuestion:    translate(null, 'autodialer.call_interface.call_in_progress', 'which_number_transfer'),
    callCta:          translate(null, 'autodialer.call_interface.call_in_progress', 'transfer')
  };
  const disconnectedProps = {
    ...inProgressProps,
    title: translateMarkupString(
      'autodialer.call_interface.call_in_progress',
      'name_has_disconnected',
      { span: <span /> },
      { name: memberName }
    )
  };
  const joinErrorProps = {
    title: translateMarkupString(
      'autodialer.call_interface.call_in_progress',
      'name_has_joined_call',
      { span: <span /> },
      { name: memberName }
    ),
    buttonCta:      translate(null, 'autodialer.call_interface.call_in_progress', 'join_call_cta'),
    joinByPhone:    translate(null, 'misc', 'or_simply'),
    joinByPhoneCta: translate(null, 'autodialer.call_interface.call_in_progress', 'join_via_phone_cta'),
    phoneQuestion:  translate(null, 'autodialer.call_interface.call_in_progress', 'which_number_call'),
    callCta:        translate(null, 'autodialer.call_interface.call_in_progress', 'call'),
    errorMessage:   translate(null, 'autodialer.call_interface.call_in_progress', 'join_error_message')
  };

  const getComponentProps = () => {
    // This is in case somehow the member and provider statuses get overwritten to null.
    // This should never happen during a call but in case it does, better to make the
    // provider think the member dropped (which is probably true)
    if (!memberCallStatus && !providerCallStatus) {
      return { ...inProgressProps, title: '' };
    }

    // Need better logic here, we should still show the waiting props if there is an error, but the 'callStatus'
    // is overloaded right now so we need to check both. I am actually not convinced we need the call status for this conditional
    // it should be possible to refactor it to just use the attendee statuses like below
    if (
      (callStatus === CONFERENCE_STATUSES.STARTING || callStatus === CONFERENCE_STATUSES.BAD_REQUEST) &&
      memberCallStatus === ATTENDEE_STATUSES.JOINED
    ) {
      return waitingProps;
    }

    if (memberCallStatus === ATTENDEE_STATUSES.DISCONNECTED) {
      return disconnectedProps;
    }

    if (providerCallStatus === ATTENDEE_STATUSES.NOT_REACHED) {
      return waitingProps;
    }

    if (providerCallStatus === ATTENDEE_STATUSES.DISCONNECTED && memberCallStatus === ATTENDEE_STATUSES.JOINED) {
      return waitingProps;
    }

    // This seems like a very dangerous default to me, I think this needs to be tightened up
    return inProgressProps;
  };

  const wrapComponentProps = () => {
    if (isMicError) {
      return {
        ...getComponentProps(),
        errorMessage: translate(null, 'autodialer.call_interface.call_in_progress', 'mic_error_message')
      };
    } else if (isVendorConnError) {
      return { ...getComponentProps(), errorMessage: 'Error connecting to Twilio' };
    } else if (isConfApiError) {
      return { ...getComponentProps(), errorMessage: 'Error retrieving call status...' };
    } else {
      return getComponentProps();
    }
  };

  const {
    buttonCta,
    buttonCtaHandler,
    callCta,
    errorMessage,
    joinByPhone,
    joinByPhoneCta,
    phoneQuestion,
    title
  } = wrapComponentProps();

  const showMuteButton =
    callStatus === CONFERENCE_STATUSES.IN_PROGRESS &&
    !connectedViaPhone &&
    providerCallStatus !== ATTENDEE_STATUSES.NOT_REACHED &&
    isMicrophoneAllowed &&
    providerCallStatus != ATTENDEE_STATUSES.DISCONNECTED;

  return (
    <div>
      <div>
        <h1 className="auto-dialer-title-text text-center">{title}</h1>
        {memberCallStatus === ATTENDEE_STATUSES.DISCONNECTED && (
          <RetryCall
            handleClick={() => {
              handleEndCall();
              setIsMuted(false);
              setCallStatus(CONFERENCE_STATUSES.READY_TO_START);
            }}
          />
        )}
        <div className="auto-dialer-button-container">
          {showMuteButton && (
            <button
              className={`button auto-dialer-button ${isError ? 'auto-dialer-button--disabled' : ''}`}
              disabled={isError}
              onClick={toggleMute}
            >
              {isMuted ? (
                <I18n scope="autodialer.call_interface.call_in_progress" text="unmute" />
              ) : (
                <I18n scope="autodialer.call_interface.call_in_progress" text="mute" />
              )}
            </button>
          )}
          {buttonCta && (
            <button
              className={`button auto-dialer-button ${isError || ctaDisabled ? 'auto-dialer-button--disabled' : ''}`}
              disabled={isError || ctaDisabled}
              onClick={buttonCtaHandler}
            >
              {buttonCta}
            </button>
          )}
        </div>
        <div className="text-center">
          {connectedViaPhone ? (
            <p>
              <I18n scope="autodialer.call_interface.call_in_progress" text="connected_via_phone" />
            </p>
          ) : (
            <React.Fragment>
              {joinByPhone}{' '}
              <span
                disabled={disableTransferToPhone}
                className={disableTransferToPhone ? 'auto-dialer-link--disabled' : 'auto-dialer-link'}
                onClick={disableTransferToPhone ? null : () => setShowPhoneNumbers(true)}
              >
                {joinByPhoneCta}
              </span>
            </React.Fragment>
          )}
        </div>
      </div>
      {showPhoneNumbers && (
        <TransferCall
          callCtaText={callCta}
          handleCallPhone={handleCallPhone}
          hidePhoneNumbers={() => setShowPhoneNumbers(false)}
          primaryPhone={primaryPhone}
          question={phoneQuestion}
          onCallJoin={onCallJoin}
          secondaryPhone={secondaryPhone}
          setCtaDisabled={setCtaDisabled}
        />
      )}
      {errorMessage && (
        <div className="error">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

CallInProgress.propTypes = {
  callStatus:          PropTypes.string.isRequired,
  connectedViaPhone:   PropTypes.bool,
  handleCallPhone:     PropTypes.func.isRequired,
  handleEndCall:       PropTypes.func.isRequired,
  handleJoinCall:      PropTypes.func.isRequired,
  isMicrophoneAllowed: PropTypes.bool,
  isMuted:             PropTypes.bool,
  setIsMuted:          PropTypes.func.isRequired,
  memberCallStatus:    PropTypes.string.isRequired,
  memberName:          PropTypes.string.isRequired,
  onCallJoin:          PropTypes.func,
  primaryPhone:        PropTypes.string,
  providerCallStatus:  PropTypes.string.isRequired,
  secondaryPhone:      PropTypes.string,
  setCallStatus:       PropTypes.func.isRequired,
  toggleMute:          PropTypes.func
};

export default CallInProgress;
