import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SurveySection } from '.';
import { createSurveyConfig } from 'app/surveys/utils/';
import { forEach, get } from 'lodash';

const Survey = ({ surveyObject, surveyNamingKey, styles, surveyState, changeSurveyState, cachedAnswers }) => {
  const surveyConfig = createSurveyConfig(surveyObject, surveyState[surveyObject.surveyCode] || {}, changeSurveyState);

  // Each onChange callback is called when the component loads for the first time.
  // This initializes the tree in the store.
  useEffect(() => {
    forEach(surveyConfig, (section, k) => {
      forEach(section, (question, k) => {
        forEach(question, (answer, k) => {
          if (answer && answer.onChangeCallback) {
            answer.onChangeCallback({ [k]: answer.value });
          }
        });
      });
    });
  }, [surveyObject]);

  const surveys = get(cachedAnswers, 'table.surveys', []);
  const surveyIndex = surveys.findIndex(survey => get(survey, 'table.survey_code', null) === surveyObject.surveyCode);

  const surveyInfo = get(surveys, `[${surveyIndex}].table`);

  return (
    <div>
      <input type="hidden" name={`${surveyNamingKey}[surveys][][survey_code]`} value={surveyObject.surveyCode} />
      {surveyObject.sections.map(section => {
        const sectionInfo = get(surveyInfo, 'sections', []).find(
          cachedSection => get(section, 'table.section_code') === cachedSection.sectionCode
        );

        return (
          <div key={section.sectionCode}>
            <input
              type="hidden"
              name={`${surveyNamingKey}[surveys][][sections][][section_code]`}
              value={section.sectionCode}
            />
            <SurveySection
              section={section}
              sectionConfig={surveyConfig[section.sectionCode]}
              surveyNamingKey={surveyNamingKey}
              cachedSectionQuestions={get(sectionInfo, 'table.questions', [])}
              styles={styles}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Survey;

Survey.propTypes = {
  surveyObject:    PropTypes.object,
  surveyNamingKey: PropTypes.string,
  styles:          PropTypes.object,
  cachedAnswers:   PropTypes.object
};
