import {
  NETWORK_TEST_START,
  NETWORK_TEST_FAIL,
  NETWORK_TEST_COMPLETE,
  NETWORK_TEST_CANCEL,
  NETWORK_TEST_SUCCESS,
  NETWORK_TEST_PROLONGED
} from '../action-types';

export const DEFAULT_STATE = {
  prolonged: false,
  initiated: false,
  inProgress: false,
  error: null
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case NETWORK_TEST_START: {
      return {
        ...DEFAULT_STATE,
        initiated: true,
        inProgress: true
      };
    }
    case NETWORK_TEST_SUCCESS: {
      return {
        ...state,
        inProgress: false
      };
    }
    case NETWORK_TEST_FAIL: {
      return {
        ...state,
        inProgress: false,
        error: action.payload.error
      };
    }
    case NETWORK_TEST_PROLONGED: {
      return {
        ...state,
        prolonged: true
      };
    }
    case NETWORK_TEST_COMPLETE:
    case NETWORK_TEST_CANCEL: {
      return DEFAULT_STATE;
    }
    default: {
      return state;
    }
  }
};
