import { connect } from 'react-redux';

import
  makeGetSelectedSymptomWithValue
from '../../reducers/selectors/symptoms/makeGetSelectedSymptomWithValue';
import SymptomHiddenFields from './symptom-hidden-fields';

const makeMapStateToProps = () => {
  const getSelectedSymptomWithValue = makeGetSelectedSymptomWithValue();

  const mapStateToProps = (state, ownProps) => ({
    symptom: getSelectedSymptomWithValue(state, ownProps)
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(SymptomHiddenFields);
