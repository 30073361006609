import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SelectInput = props => {
  const {
    name,
    label,
    onChange,
    defaultOption,
    value,
    error,
    options,
    className,
    ...selectProps
  } = props;

  return (
    <div className={classNames('field-group', className)}>
      <h4>
        <label data-name={selectProps['data-name']} className="label" htmlFor={name}>
          {label}
        </label>
      </h4>
      <div className="field">
        <select
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className="form-control"
          {...selectProps}
        >
          <option value="" disabled>
            {defaultOption}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value || option}>
              {option.label || option}
            </option>
          ))}
        </select>
        {error && (
          <div className="alert alert-danger error-msg">{error[0]}</div>
        )}
      </div>
    </div>
  );
};

export const selectOptionsType = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  PropTypes.arrayOf(PropTypes.string)
]);

SelectInput.propTypes = {
  name:          PropTypes.string.isRequired,
  label:         PropTypes.string.isRequired,
  onChange:      PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value:         PropTypes.string,
  error:         PropTypes.array,
  options:       selectOptionsType,
  className:     PropTypes.string
};

export default SelectInput;
