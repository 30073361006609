import React from 'react';
import DataTable from '../../../data-table/DataTable';
import {
  communicationMethodSelector,
  consultationIdSelector,
  memberAvailabilitySelector,
  requestedStatusSelector,
  requestAndPropsedTableActionsSelector,
  requestReasonSelector,
  timeRemainingSelector,
  patientInformationSelector
} from '../selectors';
import { getConsultationAlertRowStyle } from '../utils';

const COLUMNS = [
  {
    name: '',
    selector: communicationMethodSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.patient'),
    selector: patientInformationSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.reason_for_visit'),
    selector: requestReasonSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.visit_id'),
    selector: consultationIdSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.remaining_time'),
    selector: timeRemainingSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.patient_availability'),
    selector: memberAvailabilitySelector
  },
  {
    name: I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.status.title'),
    selector: requestedStatusSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.actions.title'),
    selector: requestAndPropsedTableActionsSelector
  }
];

const RequestedAndProposedVisitsTable = ({ data, totalEntries }) => (
  <DataTable
    columns={COLUMNS}
    data={data}
    rowStyles={getConsultationAlertRowStyle} 
    tableName="RequestedAndProposedVisitsTable"
    loadedRecords={data.length}
    totalRecords={totalEntries}
  />
);

export default RequestedAndProposedVisitsTable;
