import {
  SUBMIT_WEIGHT_FORM,
  SUBMIT_WEIGHT_FORM_START,
  SUBMIT_WEIGHT_FORM_SUCCESS,
  SUBMIT_WEIGHT_FORM_FAIL,
  SUBMIT_WEIGHT_FORM_CANCEL,
  VALIDATE_WEIGHT_FORM,
  VALIDATE_WEIGHT_FORM_START,
  VALIDATE_WEIGHT_FORM_FAIL,
  TOGGLE_PATIENT_IS_MINOR,
  RESET_WEIGHT_FORM,
  PARTIAL_RESET_WEIGHT_FORM
} from './actionTypes';

export const togglePatientIsMinor = () => ({
  type: TOGGLE_PATIENT_IS_MINOR
});

export const resetWeightForm = () => ({
  type: RESET_WEIGHT_FORM
});

export const partialResetWeightForm = () => ({
  type: PARTIAL_RESET_WEIGHT_FORM
});

export const validateWeightFormStart = () => ({
  type: VALIDATE_WEIGHT_FORM_START
});

export const validateWeightFormFail = error => ({
  type:    VALIDATE_WEIGHT_FORM_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const submitWeightFormStart = payload => ({
  type: SUBMIT_WEIGHT_FORM_START,
  payload
});

export const submitWeightFormSuccess = payload => ({
  type: SUBMIT_WEIGHT_FORM_SUCCESS,
  payload
});

export const submitWeightFormFail = error => ({
  type:    SUBMIT_WEIGHT_FORM_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const onCancel = () => ({
  type: SUBMIT_WEIGHT_FORM_CANCEL
});

export const onFormSubmit = formData => ({
  type:    SUBMIT_WEIGHT_FORM,
  payload: formData
});
