import React from 'react';

const AttendeeCheckbox = ({ attendeeId, label, isChecked, styles }) => {
  const checkboxIdentifier = `interview_consult_attendee_presence_${attendeeId}`;
  return (
    <div>
      <input
        id={checkboxIdentifier}
        type="checkbox"
        // use rails accepted params syntax
        name="interview[consult_attendee_presence[][id]]"
        value={attendeeId}
        defaultChecked={isChecked}
        style={styles}
      />
      <label htmlFor={checkboxIdentifier}>{label}</label>
    </div>
  );
};

export default AttendeeCheckbox;
