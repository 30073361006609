import React from 'react';
import PropTypes from 'prop-types';
import VendorControls, { isVendorAdded } from '../../../Conference/components/vendorControls';
import { connect } from 'react-redux';
import { addParticipant, callParticipant } from '../../../Conference/actions';
import { CONFERENCE_IN_PROGRESS } from '../../../Conference/lib/states';

export const CareCoordinatorTransferContainer = props =>
  props.status === CONFERENCE_IN_PROGRESS && props.brandName === 'Tricare' ? (
    <VendorControls
      addParticipant={props.addParticipant}
      buttonText="Transfer"
      callParticipant={props.callParticipant}
      conferenceId={props.conferenceId}
      consultationId={props.consultationId}
      disabled={isVendorAdded(props.participants)}
      instructions={
        <p>
          If this member needs UC/ER within 24 hours, please use the <span>Transfer </span>
          button and the system will connect you. You can drop once the transfer is answered (~15
          seconds) and complete the consult notes.
        </p>
      }
      warmTransferVendors={props.warmTransferVendors}
    />
  ) : null;

CareCoordinatorTransferContainer.propTypes = {
  addParticipant:      PropTypes.func.isRequired,
  brandName:           PropTypes.string.isRequired,
  callParticipant:     PropTypes.func.isRequired,
  conferenceId:        PropTypes.number,
  consultationId:      PropTypes.string.isRequired,
  participants:        PropTypes.array.isRequired,
  status:              PropTypes.string.isRequired,
  warmTransferVendors: PropTypes.array
};

export default connect(
  ({ conference }) => ({
    conferenceId:        conference.conferenceId,
    participants:        conference.conferenceParticipants,
    status:              conference.status,
    warmTransferVendors: conference.warmTransferVendors
  }),
  {
    addParticipant,
    callParticipant
  }
)(CareCoordinatorTransferContainer);
