import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import {
  useHandleFavoriteStatusChange,
  useQuickDxFavorites,
  isDiagnosisCodeFavorite,
  getConsultationSpecialty,
  getQuickDxIdByDiagnosisCode,
  onFavoriteChange
} from '../utils';
import { FavoriteDefault, FavoriteActiveIcon, CloseIcon } from './icons';

export const SelectedDxChip = ({ diagnosis, onRemove, index, isPrimaryDiagnosis, isDisabled }) => {
  const dxName = `${diagnosis.diagnosisCode} ${diagnosis.diagnosisName}`;
  const isFavorite = isDiagnosisCodeFavorite(diagnosis.diagnosisCode);
  const { handleFavoriteDiagnosisChange, favoritesEnabled, isEditable } = useQuickDxFavorites();

  const quickDxId = getQuickDxIdByDiagnosisCode(diagnosis.diagnosisCode) || diagnosis.quickDxId;
  const [handleFavoriteChange] = useHandleFavoriteStatusChange(diagnosis, isFavorite, {
    onSuccess: data =>
      handleFavoriteDiagnosisChange(
        {
          ...diagnosis,
          quickDxId: data.quickDxId
        },
        data.isFavorite
      )
  });

  const secondaryDiagnosisDataToExpose = [
    { name: 'id', value: diagnosis.diagnosisCodeId },
    { name: 'code', value: diagnosis.diagnosisCode },
    { name: 'flag', value: diagnosis.isSecondaryDiagnosis },
    { name: 'name', value: diagnosis.diagnosisName },
    { name: 'active', value: true },
    { name: 'is_quick_dx', value: diagnosis.isQuickDx }
  ];

  const primaryDiagnosisDataToExpose = [
    { name: 'primary_medical_diagnosis_id', value: diagnosis.diagnosisCodeId },
    {
      name:  'primary_medical_diagnosis_code',
      value: diagnosis.diagnosisCode,
      id:    'primary_medical_diagnosis_search_code'
    },
    { name: 'primary_medical_diagnosis_secondary_diagnosis_flag', value: diagnosis.isSecondaryDiagnosis },
    { name: 'primary_medical_diagnosis_name', value: diagnosis.diagnosisName },
    { name: 'primary_medical_diagnosis_is_quick_dx', value: diagnosis.isQuickDx },

    {
      name:  'interview_primary_article_id',
      id:    'interview_primary_article_id',
      value: _.get(diagnosis, 'article.article_id')
    },
    {
      name:  'interview_primary_article_title',
      id:    'interview_primary_article_title',
      value: _.get(diagnosis, 'article.title')
    }
  ];

  const handleOnRemove = () => {
    if (!isEditable) return;

    onRemove();
  };

  return (
    <div className="selected-dx-chip">
      <div className="chip-grid">
        <div
          className={classnames('is-favorite-icon', !favoritesEnabled && 'uneditable', isDisabled && 'uneditable')}
          onClick={() =>
            onFavoriteChange({
              handler:     handleFavoriteChange,
              diagnosisCd: diagnosis.diagnosisCode,
              favoritesEnabled,
              isDisabled,
              isFavorite,
              quickDxId
            })
          }
        >
          {isFavorite ? <FavoriteActiveIcon /> : <FavoriteDefault />}
        </div>

        <div className="dx-name">{dxName}</div>

        {!isDisabled && (
          <div className={classnames('remove-icon', !isEditable && 'uneditable')} onClick={handleOnRemove}>
            <CloseIcon />
          </div>
        )}
      </div>

      {isPrimaryDiagnosis && (
        <React.Fragment>
          {primaryDiagnosisDataToExpose.map(({ name, value, id }) => (
            <input
              key={name}
              type="hidden"
              name={`interview[${name}]`}
              className="js_diagnosis_id"
              value={value}
              id={id}
              {...(name === 'primary_medical_diagnosis_id' ? { id: 'interview_primary_medical_diagnosis_id' } : {})}
            />
          ))}

          <input type="hidden" id="interview_primary_medical_diagnosis_text" value={dxName} />
        </React.Fragment>
      )}

      {!isPrimaryDiagnosis && (
        <React.Fragment>
          {secondaryDiagnosisDataToExpose.map(({ name, value }) => (
            <input
              key={name}
              type="hidden"
              name={`interview[secondary_medical_diagnoses][][${name}]`}
              className="js_diagnosis_id"
              value={value}
              {...(name === 'id'
                ? { id: `interview_secondary_medical_diagnosis_id_secondary_diagnosis_${index}` }
                : {})}
            />
          ))}
        </React.Fragment>
      )}
    </div>
  );
};

SelectedDxChip.displayName = 'SelectedDxChip';

SelectedDxChip.propTypes = {
  index:              PropTypes.number.isRequired,
  diagnosis:          PropTypes.object.isRequired,
  isFavorite:         PropTypes.bool,
  onRemove:           PropTypes.func,
  isPrimaryDiagnosis: PropTypes.bool,
  isDisabled:         PropTypes.bool
};
