import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field } from 'formik';

import { row, fullWidth, field, pharmacyNotes } from '../styles.module.scss';

class PharmacyNotes extends React.Component {
  render() {
    const { errors, touched, ...props } = this.props;

    return (
      <div className={pharmacyNotes}>
        <h3>Pharmacy Notes</h3>
        <div className={cx(row, fullWidth)}>
          <div className={field}>
            <Field
              name="pharmacyNotes"
              component="textarea"
              rows={8}
              value={props.values.pharmacyNotes}
            />
            {
              errors.pharmacyNotes && touched.pharmacyNotes &&
              <div>{errors.pharmacyNotes}</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

PharmacyNotes.propTypes = {
  values:       PropTypes.shape({
    pharmacyNotes: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur:   PropTypes.func.isRequired,
  errors:       PropTypes.object.isRequired,
  touched:      PropTypes.object.isRequired,
};

export { PharmacyNotes };
