import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SymptomTabs from './symptom-tabs-container';
import { fetchSymptoms, fetchSymptomClasses } from '../../actions';
import SymptomsHiddenFields from './symptoms-hidden-fields-container';
import SymptomsHiddenReducers from './symptoms-hidden-reducers-container';
import { mapInlineErrors } from 'app/consultation-interview/utils';

export class SymptomsContainer extends Component {
  componentDidMount() {
    if (!this.props.symptomsFetched) {
      this.props.fetchSymptoms();
    }

    if (!this.props.symptomClassesFetched) {
      this.props.fetchSymptomClasses();
    }
  }

  componentWillUnmount() {
    if (this.props.symptomsLoading) {
      this.props.cancelFetchSymptoms();
    }

    if (this.props.symptomClassesLoading) {
      this.props.cancelFetchSymptomClasses();
    }
  }

  render() {
    if (!this.props.symptomsFetched || !this.props.symptomClassesFetched) {
      return null;
    }

    const symptomsValidationError = this.props.validationErrors.find(({ validatorKey }) => validatorKey === 'symptoms');

    return (
      <div>
        <SymptomTabs />
        <SymptomsHiddenFields />
        <SymptomsHiddenReducers />

        {symptomsValidationError && (
          <div className="interview_inline_validation_error show">{symptomsValidationError.message}</div>
        )}

        <input type="hidden" name="interview[new_subjective_tab_enabled]" value="true" />
      </div>
    );
  }
}

SymptomsContainer.propTypes = {
  fetchSymptoms:             PropTypes.func.isRequired,
  cancelFetchSymptoms:       PropTypes.func.isRequired,
  fetchSymptomClasses:       PropTypes.func.isRequired,
  cancelFetchSymptomClasses: PropTypes.func.isRequired,
  symptomsFetched:           PropTypes.bool.isRequired,
  symptomsLoading:           PropTypes.bool.isRequired,
  symptomClassesFetched:     PropTypes.bool.isRequired,
  symptomClassesLoading:     PropTypes.bool.isRequired,
  validationErrors:          PropTypes.array
};

const mapStateToProps = state => ({
  symptomsFetched:       state.symptomsRequest.isSuccess,
  symptomsLoading:       state.symptomsRequest.isLoading,
  symptomClassesFetched: state.symptomClassesRequest.isSuccess,
  symptomClassesLoading: state.symptomClassesRequest.isLoading,
  validationErrors:      mapInlineErrors(state)
});

const mapDispatchToProps = {
  fetchSymptoms:             fetchSymptoms.start,
  cancelFetchSymptoms:       fetchSymptoms.cancel,
  fetchSymptomClasses:       fetchSymptomClasses.start,
  cancelFetchSymptomClasses: fetchSymptomClasses.cancel
};

export default connect(mapStateToProps, mapDispatchToProps)(SymptomsContainer);
