exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3_WXn9MSwEFRKtnafaCDHG {\n  color: #727069;\n  font-size: 10px;\n  height: 11px;\n  text-transform: uppercase; }\n\n.SoJ-PdpK5czIx5PgLtTfX {\n  margin-top: 11px;\n  margin-bottom: 12px; }\n  .SoJ-PdpK5czIx5PgLtTfX ._3_WXn9MSwEFRKtnafaCDHG {\n    margin-bottom: 4px; }\n  .SoJ-PdpK5czIx5PgLtTfX .sK6ixDF7VUFxQDDoMji8s:not(:first-child) {\n    margin-top: 7px; }\n\n._37V8JXVgOWWcclaRB34o52 br {\n  display: none; }\n", ""]);

// exports
exports.locals = {
	"specialtiesGroupTitle": "_3_WXn9MSwEFRKtnafaCDHG",
	"specialtiesGroupTitle": "_3_WXn9MSwEFRKtnafaCDHG",
	"infoboxSpecialtiesRoot": "SoJ-PdpK5czIx5PgLtTfX",
	"infoboxSpecialtiesRoot": "SoJ-PdpK5czIx5PgLtTfX",
	"specialtiesGroup": "sK6ixDF7VUFxQDDoMji8s",
	"specialtiesGroup": "sK6ixDF7VUFxQDDoMji8s",
	"providerScheduledAppointment": "_37V8JXVgOWWcclaRB34o52",
	"providerScheduledAppointment": "_37V8JXVgOWWcclaRB34o52"
};