import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { STATUS_MAP } from '../constants';
import { TERMINATE_REASONS_MAP } from '../constants';

const PatientListStatus = ({ statusCd, terminateReason }) => {
  const statusClass = classnames({
    'new-status': ['CARETEAMRLTNSTATUS_NEW', 'CARETEAMRLTNSTATUS_NEWTOYOU'].includes(statusCd)
  });

  const terminateReasonInfo = TERMINATE_REASONS_MAP[terminateReason] || TERMINATE_REASONS_MAP['MEMBER_INITIATED_SWITCH_TERMINATION']

  const terminateReasonString = () => {
    return terminateReasonInfo.substr(0, 8).trimEnd() + '...'
  }

  return (
    <Fragment>
      <p className={statusClass}> {STATUS_MAP[statusCd]} </p>
      { statusCd === 'CARETEAMRLTNSTATUS_TERMINATED' &&
        <div>
          <p data-for={terminateReason} data-tip className="terminate-reason">{terminateReasonString()}</p>
          <ReactTooltip id={terminateReason} place="top" type="dark" effect="float">
            {terminateReasonInfo}
          </ReactTooltip>
        </div> }
    </Fragment>
  )
};

PatientListStatus.propTypes = {
  status: PropTypes.string
};

export default PatientListStatus;
