import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'app/components/Loader';
import useSameDayVisitsToggle from '../../hooks/useSameDayVisitsToggle';
import EditSameDayVisitsSection from '../EditSameDayVisitsSection';
import SameDayVisitsSectionHeader from '../SameDayVisitsSectionHeader';
import SameDayVisitsSectionMessage from '../SameDayVisitsSectionMessage';

const SameDayVisitsSection = ({ providerId }) => {
  const [isEdit, setIsEdit] = useState(false);

  const { isAcceptingSameDayVisits, isDisabled, isLoading, error, onUpdate } = useSameDayVisitsToggle(providerId);

  const canEdit = !isLoading && !isDisabled;

  const [isUrlProcessed, setIsUrlProcessed] = useState(false);

  useEffect(() => {
    if (!isUrlProcessed && canEdit) {
      const params = new URLSearchParams(window.location.search);
      const editParam = params.get('edit');
      if (editParam === 'true') {
        setIsEdit(true);
      }
      setIsUrlProcessed(true);
    }
  }, [isUrlProcessed, canEdit]);

  return (
    <div className="module">
      <div className="section">
        <SameDayVisitsSectionHeader canEdit={canEdit && !isEdit} onEdit={() => setIsEdit(value => !value)} />
        <div className="section-column">
          {isLoading ? (
            <div className="loading-container">
              <Loader />
            </div>
          ) : (
            <div>
              {isEdit ? (
                <EditSameDayVisitsSection
                  isAcceptingSameDayVisits={isAcceptingSameDayVisits}
                  onSubmit={onUpdate}
                  onCancel={() => setIsEdit(false)}
                />
              ) : (
                <SameDayVisitsSectionMessage
                  isDisabled={isDisabled}
                  isAcceptingSameDayVisits={isAcceptingSameDayVisits}
                />
              )}
            </div>
          )}
          {error && <p className="error-message">{I18n.t('my_schedule.mutations.error')}</p>}
        </div>
      </div>
    </div>
  );
};

SameDayVisitsSection.propTypes = {
  providerId: PropTypes.number.isRequired
};

export default SameDayVisitsSection;
