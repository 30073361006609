export const ANSWER_NAME =
  'interview[mental_status_exam][][surveys][][sections][][questions][][answers][][answer_code]';
export const QUESTION_NAME = 'interview[mental_status_exam][][surveys][][sections][][questions][][question_code]';
export const TEXT_FIELD_NAME = 'interview[mental_status_exam][][surveys][][sections][][questions][][answers][][text]';
export const VALUE_NAME = 'interview[mental_status_exam][][surveys][][sections][][questions][][answers][][value]';

export const SAFETY_PLAN_SECTION_CD = 'SECTIONMNEMONIC_MENTALSTATUSEXAM_THOUGHT';
export const SAFETY_PLAN_QUESTION_CD = 'safety_plan';

export const MENTAL_STATUS_EXAM_CD = 'SURVEYMNEMONIC_MENTALSTATUSEXAM';
