import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DataTableSortButton from '../DataTableSortButton';
import Loader from 'app/components/Loader';
import ReactTooltip from 'react-tooltip';

const SortableColumn = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const DataTable = React.memo(({ columns, data, sortColumn, sortDirection, onSort, loading, rowStyles, tableName, loadedRecords, totalRecords}) => {
  const tableRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    let timeoutId;
    const table = tableRef.current;
    const tooltip = tooltipRef.current;

    const handleScroll = () => {
      if (table && tooltip) {
        const { scrollTop, clientHeight, scrollHeight } = table;

        if (scrollTop === 0) {
          ReactTooltip.hide(tooltip);
          return;
        } else {
          ReactTooltip.show(tooltip);
        }

        const tooltipHeight = tooltip.offsetHeight;
        const tooltipTop = (scrollTop / (scrollHeight - clientHeight)) * (clientHeight - tooltipHeight) + scrollTop;

        tooltip.style.left = `${table.clientWidth - tooltip.offsetWidth - 10}px`;
        tooltip.style.top = `${tooltipTop}px`;

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          ReactTooltip.hide(tooltip);
        }, 500);
      }
    };

    if (table) {
      table.addEventListener('scroll', handleScroll);
      ReactTooltip.hide(tooltip);
    }

    return () => {
      if (table) {
        table.removeEventListener('scroll', handleScroll);
      }
      clearTimeout(timeoutId);
    };
  }, []);

  if (!data.length) return <p className="no-data-message">{I18n.t('dashboard.consult_queues.no_rows')}</p>;

  return (
    <React.Fragment>
      <div
        className="table-container"
        ref={tableRef}
      >
        <table>
          <thead>
            <tr>
              {columns.map(({ id, name, sortable = false, width }, index) => (
                <th key={index} style={{ width: width || 'auto', verticalAlign: 'initial' }}>
                  <SortableColumn
                    condition={sortable}
                    wrapper={children => (
                      <DataTableSortButton
                        id={id}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        onClick={onSort}
                        tableName={tableName}
                      >
                        {children}
                      </DataTableSortButton>
                    )}
                  >
                    {name}
                  </SortableColumn>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map(({ rowProps = {}, ...row }, index) => (
                <tr key={index} style={{ verticalAlign: 'top', ...(rowStyles && rowStyles(row)) }}>
                  {columns.map(({ selector }, index) => (
                    <td key={index}>{selector(row)}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        <div
          data-tip=""
          ref={tooltipRef}
          data-for={tableName}
          style={{ position: 'absolute' }}
        />
        <ReactTooltip id={tableName} effect="solid" place="left" class='data-table-tooltip-on-scroll' >
          {`${loadedRecords} of ${totalRecords}`}
        </ReactTooltip>
      </div>
      {loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
    </React.Fragment>
  );
});

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      selector: PropTypes.func.isRequired,
      width: PropTypes.string,
      sortable: PropTypes.bool
    })
  ),
  data: PropTypes.array.isRequired,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
  onSort:        PropTypes.func,
  loading:       PropTypes.bool,
  rowStyles:     PropTypes.func,
  tableName:     PropTypes.string,
  loadedRecords: PropTypes.number,
  totalRecords:  PropTypes.number
};

export default DataTable;
