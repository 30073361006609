import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConferenceParticipantPerson from './conferenceParticipantPerson';
import ConferenceCall from './conferenceParticipantCall';

import {
  CONFERENCE_NOT_STARTED,
  CONFERENCE_NEEDS_CONFIRMATION,
  CONFERENCE_IN_PROGRESS
} from '../lib/states';

import { callParticipant, disconnectParticipant, toggleMute } from '../actions';
import { isEnabledToCall } from '../lib/conferenceCall';

class ConferenceParticipant extends Component {
  static propTypes = {
    person:                PropTypes.object.isRequired,
    conferenceCall:        PropTypes.object.isRequired,
    conferenceId:          PropTypes.number.isRequired,
    conferenceStatus:      PropTypes.string.isRequired,
    callParticipant:       PropTypes.func.isRequired,
    disconnectParticipant: PropTypes.func.isRequired,
    toggleMute:            PropTypes.func.isRequired,
    dialMember:            PropTypes.bool
  };

  // The code inside this function is focusing on
  // calling participants when providers click 'Start Conference'
  // We should not call inbound callers or any other already connected callers
  // from this function
  componentWillReceiveProps = nextProps => {
    const { dialMember, person, conferenceStatus, conferenceCall } = this.props;

    if (!isEnabledToCall(conferenceCall.callStatus)) {
      return null;
    }

    const transitioningToConfirmation =
      conferenceStatus === CONFERENCE_NOT_STARTED &&
      nextProps.conferenceStatus === CONFERENCE_NEEDS_CONFIRMATION;

    if (transitioningToConfirmation && person.admin) {
      this.props.callParticipant(person, { dialMember: true });
    }

    const transitioningToProgress =
      conferenceStatus === CONFERENCE_NEEDS_CONFIRMATION &&
      nextProps.conferenceStatus === CONFERENCE_IN_PROGRESS;

    if (transitioningToProgress && !person.admin) {
      if (person.title === 'Patient') {
        this.props.callParticipant(person, { dialMember });
      } else {
        this.props.callParticipant(person, { dialMember: true });
      }
    }
  };

  onRedial = () =>
    this.props.callParticipant(this.props.person, { dialMember: this.props.dialMember });

  onDisconnect = () =>
    this.props.disconnectParticipant({
      conferenceId: this.props.conferenceId,
      attendanceId: this.props.person.attendanceId
    });

  onMuteToggle = () =>
    this.props.toggleMute({
      attendanceId: this.props.person.attendanceId,
      conferenceId: this.props.conferenceId,
      muted:        this.props.conferenceCall.muted
    });

  render() {
    if (this.props.conferenceStatus === CONFERENCE_NEEDS_CONFIRMATION) {
      return null;
    }

    const { conferenceCall, person } = this.props;

    return (
      <div className={`conference-participant-list-item ${person.type.toLowerCase()}`}>
        <div className={`conference-participant-person ${conferenceCall.callStatus}`}>
          <ConferenceParticipantPerson {...person} callStatus={conferenceCall.callStatus} />
        </div>
        <div className="conference-participant-call">
          <ConferenceCall
            {...conferenceCall}
            onRedial={this.onRedial}
            onDisconnect={this.onDisconnect}
            onMuteToggle={this.onMuteToggle}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ conference }) => ({ conferenceId: conference.id }),
  { callParticipant, disconnectParticipant, toggleMute }
)(ConferenceParticipant);
