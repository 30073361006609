import { connect } from 'react-redux';
import get from 'lodash/get';

import getProviderClockedIn from '../../../Provider/selectors/getProviderClockedIn';
import getProviderParams from '../../../Provider/selectors/getProviderParams';
import ProviderClockInOutButtons from './provider-clock-in-out-buttons';
import getProviderCanClockInForType from '../../../Provider/selectors/getProviderCanClockInForType';
import getUseLocationForClockIn from '../../../Provider/selectors/getUseLocationForClockIn';
import ProviderClockInType from '../../../Provider/enums/provider-clock-in-type';

import { clockInLocationInit, enableRequestMode, clockInProviderInit } from '../actions';

export const mapStateToProps = state => ({
  serviceSpecialty:               get(getProviderParams(state), ['serviceSpecialty']),
  clockInType:                    get(getProviderParams(state), ['clockInType']),
  clockedIn:                      getProviderClockedIn(state),
  videoClockInAllowed:            getProviderCanClockInForType(ProviderClockInType.VIDEO)(state),
  combineVideoPhoneClockInButton: state.sidebar.providerClockInOut.settings.combineVideoPhoneClockInButton,
  useLocationForClockIn:          getUseLocationForClockIn(state)
});

const mapDispatchToProps = dispatch => ({
  clockInLocationInit: () => {
    dispatch(clockInLocationInit());
  },
  onProviderClockInButtonClick: clockInType => {
    dispatch(enableRequestMode(clockInType));
  },
  onSwitchClockInButtonClick: clockInType => {
    dispatch(clockInProviderInit(clockInType));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderClockInOutButtons);
