import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CharValidator from '../char-validator';
import './additional-notes.scss';

const MAX_LENGTH = 8000;

const AdditionalNotes = ({ section, toggleSection }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (section.questions.Q_3_1.response) setValue(section.questions.Q_3_1.response);
  }, section.questions.Q_3_1.response);

  const controlNamePrefix =
    'interview[provider_intake_surveys][SURVEYMNEMONIC_NUTPLAN][sections][SECTIONMNEMONIC_NUTPLAN_ADDITIONAL]';
  return (
    <div className="additional-notes-wrapper">
      <CharValidator fieldLength={value.length} maxLength={MAX_LENGTH} />
      <div>
        <textarea
          name={`${controlNamePrefix}[questions][Q_3_1][response]`}
          value={value}
          maxLength={MAX_LENGTH}
          onChange={event => setValue(event.target.value)}
          className="additional-notes-text"
        />
      </div>
      <div className="reviewed-checkbox">
        <input type="hidden" name={`${controlNamePrefix}[reviewed]`} value="false" />
        <input
          id="additional-notes-reviewed"
          type="checkbox"
          name={`${controlNamePrefix}[reviewed]`}
          value="true"
          onChange={toggleSection}
          checked={section.reviewed}
        />
        <label htmlFor="additional-notes-reviewed">{`Reviewed ${section.title}`}</label>
      </div>
    </div>
  );
};

AdditionalNotes.propTypes = {
  section:       PropTypes.object.isRequired,
  toggleSection: PropTypes.func.isRequired
};

export default AdditionalNotes;
