import React from 'react';

import ProviderClockInOutStatus from './provider-clock-in-out-status-container';
import ProviderClockInOutButtons from './provider-clock-in-out-buttons-container';
import ProviderClockInLocation from './provider-clock-in-location-container';

const ProviderClockInOut = ({ requestMode }) => (
  <section className="module clock-in-out">
    <ProviderClockInOutStatus />
    {requestMode ? <ProviderClockInLocation /> : <ProviderClockInOutButtons />}
  </section>
);

export default ProviderClockInOut;
