exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3_CoYZ_5vvHklgWBFHCeJj ._1NLDRbVm6OkjrJlCK2xDLk {\n  color: #244861;\n  font-size: 16px; }\n", ""]);

// exports
exports.locals = {
	"externalCalendarsMainContentWrapper": "_3_CoYZ_5vvHklgWBFHCeJj",
	"externalCalendarsMainContentWrapper": "_3_CoYZ_5vvHklgWBFHCeJj",
	"sectionTitle": "_1NLDRbVm6OkjrJlCK2xDLk",
	"sectionTitle": "_1NLDRbVm6OkjrJlCK2xDLk"
};