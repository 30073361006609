import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { PROVIDERLOCATION_OTHER } from '../constants';
import ProviderClockInType from '../../../Provider/enums/provider-clock-in-type';

const getStatusText = (
  serviceSpecialty,
  clockedIn,
  clockInType,
  videoClockInAllowed,
  locationType,
  locationText,
  requestMode,
  useLocationForClockIn
) => {
  if (requestMode) {
    return '';
  }

  if (!clockedIn) {
    return <b>Clocked out</b>;
  }

  const audioOnlyVerbiage =
    Boolean(window.FEATURE_TOGGLES.allow_auto_dialer) && serviceSpecialty === 'General Medical' ? 'Audio' : 'Phone';

  const location = () => {
    if (locationType.name) {
      return (
        <span>
          | Location:
          <b> {locationType.code === PROVIDERLOCATION_OTHER ? locationText : locationType.name}</b>
        </span>
      );
    } else {
      return '';
    }
  };

  if (clockInType === ProviderClockInType.VIDEO) {
    return (
      <span>
        Clocked in To: <b>Video & {audioOnlyVerbiage}</b> {location()}
      </span>
    );
  }

  if (videoClockInAllowed && clockInType === ProviderClockInType.PHONE) {
    return (
      <span>
        Clocked in To: <b>{audioOnlyVerbiage}</b> {location()}
      </span>
    );
  }

  return <span>Clocked in {location()}</span>;
};

const ProviderClockInOutStatusText = ({
  serviceSpecialty,
  clockedIn,
  clockInType,
  videoClockInAllowed,
  locationType,
  locationText,
  requestMode,
  useLocationForClockIn
}) => (
  <h4>
    {getStatusText(
      serviceSpecialty,
      clockedIn,
      clockInType,
      videoClockInAllowed,
      locationType,
      locationText,
      requestMode,
      useLocationForClockIn
    )}
  </h4>
);

ProviderClockInOutStatusText.propTypes = {
  serviceSpecialty:    PropTypes.string,
  clockedIn:           PropTypes.bool.isRequired,
  clockInType:         PropTypes.string,
  videoClockInAllowed: PropTypes.bool.isRequired
};

export default ProviderClockInOutStatusText;
