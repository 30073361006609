import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import { translate } from '@td/shared_utils';
import Modal from '../modal';
import Divider from '../divider';
import ModalAction from '../modal-action';
import ModalActionBar from '../modal-action-bar';
import { externalAccountTypes, externalProviderTypes } from '../../../../constants';
import styles from './styles.module.scss';

const TRANSLATION_SCOPE = 'my_schedule.modals.connect_external_calendar';

const ConnectExternalCalendarModal = ({ onModalClose, handleAdditionalSetup, accountAuthUrl }) => {
  const getAnchorAttrs = useCallback((providerType) => {
    const href = accountAuthUrl && providerType !== externalProviderTypes.icloud
      ? `${accountAuthUrl}&provider=${providerType}`
      : null;
    const testId = `external-${providerType}-link`;

    if (providerType === externalProviderTypes.icloud) {
      return { onClick: handleAdditionalSetup, 'data-testid': testId };
    }

    return { href: href, 'data-testid': testId };
  }, [handleAdditionalSetup, accountAuthUrl]);

  return (
    <Modal
      className={styles.connectExternalCalendarModal}
      title={translate(null, TRANSLATION_SCOPE, 'title')}
      isOpen
      onClose={onModalClose}
    >
      <div className={styles.connectExternalCalendarModalContent}>
        <div className={styles.connectExternalCalendarModalSubtitle}>
          <p data-testid="subtitle">{translate(null, TRANSLATION_SCOPE, 'subtitle')}</p>
        </div>
        <div className={styles.emailProviders} data-testid="email-providers">
          {
            values(externalAccountTypes).map(({ link: providerType, icon: Icon, title }) => (
              <a key={title} {...getAnchorAttrs(providerType)}>
                <div className={styles.emailProvider}>
                  <Icon width={22} height={22} />
                  <p className={styles.providerTitle}>{title}</p>
                </div>
              </a>
            ))
          }
        </div>

        <p className={styles.additionalSetupNote} data-testid="additional-setup-note">
          {translate(null, TRANSLATION_SCOPE, 'setup_helper_text')}
        </p>
        <Divider />

        <ModalActionBar>
          <ModalAction variant="primary" onClick={onModalClose}>
            {translate(null, TRANSLATION_SCOPE, 'actions.close')}
          </ModalAction>
        </ModalActionBar>
      </div>
    </Modal>
  );
};

ConnectExternalCalendarModal.propTypes = {
  onModalClose:          PropTypes.func.isRequired,
  handleAdditionalSetup: PropTypes.func.isRequired,
  accountAuthUrl:        PropTypes.string.isRequired
};

export default ConnectExternalCalendarModal;
