import React from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import PropTypes from 'prop-types';
import _ from 'lodash';

const ReactAccordionSection = (
  {
    expanded,
    reviewRequired,
    toggleSection,
    title,
    isReviewed,
    children
  }) =>
 (
   <div
     className={`react-accordion-section
     ${expanded ? ' expanded' : ''}
     ${reviewRequired ? ' review_required' : ''}`}
   >
     <div className={`section-title ${_.kebabCase(title)}`} onClick={toggleSection}>
       <span>{title}</span>
       <span className="review">{isReviewed ? 'REVIEWED' : 'REVIEW REQUIRED'}</span>
     </div>
     <SmoothCollapse expanded={expanded} eagerRender>
       <div className="section-body">
         {children}
       </div>
     </SmoothCollapse>
   </div>
 );

ReactAccordionSection.propTypes = {
  expanded:       PropTypes.bool,
  reviewRequired: PropTypes.bool,
  isReviewed:     PropTypes.bool.isRequired,
  title:          PropTypes.string.isRequired,
  children:       PropTypes.object.isRequired,
  toggleSection:  PropTypes.func.isRequired
};

export default ReactAccordionSection;
