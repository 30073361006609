import React, { useRef } from 'react';
import { ModalProvider } from 'app/modal';
import { AutoDialerV3 } from '@teladoc/auto_dialer';

import WebRTC from './WebRTC';
import AutoDialerModal from './modals/AutoDialerModal';
import AutoDialerV3CallStatus from '../../../call-details/components/autodialer-v3-call-status';
import * as styles from '../styles.module.scss';

import { AutoDialerProvider, useAutoDialer } from '../utils';
import CallStatusProvider from '../../../call-details/CallStatusProvider';
import ParticipantsProvider from '../../../call-details/ParticipantsProvider';

const { AutoDialerHeaderRedesign, AutoDialerFooterRedesign } = AutoDialerV3;

const AutoDialer = () => {
  const { consultation } = useAutoDialer();
  const containerRef = useRef();

  return (
    <aside
      data-autodialer-version="AutoDialerV3"
      id="auto_dialer_consult_ct"
      className="autoDialerV3"
    >
      <div className={styles.autoDialerContainer}>
        <hr className={styles.containerTopLine} />
        <AutoDialerHeaderRedesign />
        <AutoDialerV3CallStatus containerRef={containerRef} consultation={consultation} />
        <WebRTC containerRef={containerRef} />
        <AutoDialerFooterRedesign>
          <AutoDialerModal />
        </AutoDialerFooterRedesign>
      </div>
    </aside>
  );
};

const AutoDialerContainer = ({ consultation, ...props }) => {
  const { consultationId } = consultation;

  return (
    <ModalProvider>
      <AutoDialerProvider {...props} consultation={consultation}>
        <CallStatusProvider consultationId={consultationId}>
          <ParticipantsProvider consultationId={consultationId}>
            <AutoDialer />
          </ParticipantsProvider>
        </CallStatusProvider>
      </AutoDialerProvider>
    </ModalProvider>
  );
};

AutoDialer.displayName = 'AutoDialer';
AutoDialerContainer.displayName = 'AutoDialerContainer';

export default AutoDialerContainer;

export { AutoDialer };
