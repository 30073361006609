import React from 'react';
import { useLazyQuery } from '@apollo/client';
import GuidedMenu from './guided-menu';
import NavigationItemsQuery from '../queries/navigation_items.graphql';
import Loader from 'app/components/Loader';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { get } from 'lodash';
import { mapKeysDeep } from '@td/api';
import { transformKey } from '@td/utils';

const MenuLoader = ({ updateConsultNavigationItems, consultationId, defaultNavigationItems }) => {
  const [getData, { loading, error, data }] = useLazyQuery(NavigationItemsQuery, {
    variables:   { id: consultationId },
    onCompleted: () => {
      updateConsultNavigationItems(data.consultation.interviewNavigation.items);
    }
  });

  useEffect(() => {
    // When the navigation items are present in the props, then do not fire graphQL query
    // Use Ruby passed navigation items instead of the graphQL query
    // 1. Save duplicated queries between ruby and Js
    // 2. Overcome blank navigation bar which users can see after loading the page due to the delay of graphQL call
    //
    // The defaultNavigationItems is fetched from graphQL,
    // dependencies/teladoc_framework/app/models/graphql/tas/interview_navigation.rb
    const defaultItems = get(defaultNavigationItems, 'items');
    if (defaultItems) {
      const camelCaseItems = mapKeysDeep(defaultItems, transformKey);
      updateConsultNavigationItems(camelCaseItems);
      return;
    }
    getData({ variables: { id: consultationId } });
  }, [defaultNavigationItems]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div className="error">An error ocurred loading some of the EHR data. Please try reloading the page.</div>;
  }
  return <GuidedMenu />;
};

MenuLoader.propTypes = {
  updateConsultNavigationItems: PropTypes.func,
  consultationId:               PropTypes.string,
  defaultNavigationItems:       PropTypes.object
};

export default MenuLoader;
