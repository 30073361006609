import { UPDATE_NETWORK_TEST_SETTINGS } from '../action-types';

export const DEFAULT_STATE = {
  allowVideoClockIn: null,
  performTest: false
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case UPDATE_NETWORK_TEST_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
