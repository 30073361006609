import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal';
import NetworkTestOngoing from './network-test-ongoing-container';
import NetworkTestError from './network-test-error-container';
import NetworkTestSuccess from './network-test-success-container';

const NetworkTest = ({ initiated, inProgress, finalizing, hasError, successful, cancel }) => {
  const networkTestOngoing = inProgress || finalizing;

  return (
    <Modal
      onClose={cancel}
      showCloseButton={inProgress}
      show={initiated}
      titleText="Video Settings Test"
      className="network-test-modal"
      centered
    >
      <div className="network-test-container">
        {networkTestOngoing && <NetworkTestOngoing />}
        {!networkTestOngoing && hasError && <NetworkTestError />}
        {!networkTestOngoing && successful && <NetworkTestSuccess />}
      </div>
    </Modal>
  );
};

NetworkTest.propTypes = {
  initiated: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  successful: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  finalizing: PropTypes.bool.isRequired
};

export default NetworkTest;
