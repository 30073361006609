import React from 'react';
import PropTypes from 'prop-types';
import { Answer, Note } from '..';
import { answerCodes, fieldNames } from '../../constants';
import styles from './styles.module.scss';

const Question = React.memo(({ title, value, note, sectionCode, questionMnemonicCd, answers }) => (
  <div>
    <input
      type="hidden"
      name={fieldNames.QUESTION_CODE}
      value={questionMnemonicCd}
    />

    {!!value && (
      <React.Fragment>
        <input type="hidden" name={fieldNames.ANSWER_CODE} value={value} />
        <input type="hidden" name={fieldNames.ANSWER_VALUE} value={note} />
      </React.Fragment>
    )}

    <div className={styles.surveyQuestion}>
      <div
        className={styles.surveyQuestionTitle}
        data-testid="survey-question-title"
      >
        {title}
      </div>

      <Answer
        value={value}
        sectionCode={sectionCode}
        questionMnemonicCd={questionMnemonicCd}
        {...answers[answerCodes.NO]}
      />
      <Answer
        value={value}
        sectionCode={sectionCode}
        questionMnemonicCd={questionMnemonicCd}
        {...answers[answerCodes.YES]}
      />

      <Note
        note={note}
        sectionCode={sectionCode}
        questionMnemonicCd={questionMnemonicCd}
        disabled={!value}
      />
    </div>
  </div>
));

Question.displayName = 'Question';

Question.propTypes = {
  title:              PropTypes.string.isRequired,
  value:              PropTypes.string.isRequired,
  note:               PropTypes.string.isRequired,
  sectionCode:        PropTypes.string.isRequired,
  questionMnemonicCd: PropTypes.string.isRequired,
  answers:            PropTypes.object.isRequired
};

export default Question;
