exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".NA5QX7t0ggf4GIHg3DijQ {\n  background: #ffffff;\n  border: 1px solid #000000;\n  box-sizing: border-box;\n  padding: 1rem;\n  width: 248px;\n  transform: translate(90px, 170px); }\n\n._1-jBpiVK08WcnEZSwKbOIk {\n  position: absolute;\n  right: 105px;\n  z-index: 100; }\n\n._1QwfRevgc6UYpASRvzTuLH {\n  display: none; }\n\n._2FJFo6PEKWBxR7Esj6FRK8 {\n  transform: translate(97px, 170px); }\n\n._1D_FPRo9NuTBzs9Goh15nS {\n  font-size: 0.875rem;\n  color: #000000;\n  margin-bottom: 1rem;\n  margin-top: 0.5rem; }\n", ""]);

// exports
exports.locals = {
	"callDetailsPopover": "NA5QX7t0ggf4GIHg3DijQ",
	"callDetailsPopover": "NA5QX7t0ggf4GIHg3DijQ",
	"show": "_1-jBpiVK08WcnEZSwKbOIk",
	"show": "_1-jBpiVK08WcnEZSwKbOIk",
	"hidden": "_1QwfRevgc6UYpASRvzTuLH",
	"hidden": "_1QwfRevgc6UYpASRvzTuLH",
	"onPipWindow": "_2FJFo6PEKWBxR7Esj6FRK8",
	"onPipWindow": "_2FJFo6PEKWBxR7Esj6FRK8",
	"callDetailsPopoverTitle": "_1D_FPRo9NuTBzs9Goh15nS",
	"callDetailsPopoverTitle": "_1D_FPRo9NuTBzs9Goh15nS"
};