import React from 'react';
import PropTypes from 'prop-types';

const PrescriptionSubmissionErrors = ({ pse }) => (
  <span>
    <input
      type="hidden"
      value={pse.drug_ndc_code}
      data-name="drug_ndc_code"
      name="interview[prescriptions_submission_errors][][drug_ndc_code]"
    />
    {pse.errors.map(error => (
      <input
        type="hidden"
        value={error}
        data-name="prescription_submission_error"
        name="interview[prescriptions_submission_errors][][errors][]"
      />
    ))}
  </span>
);

PrescriptionSubmissionErrors.propTypes = {
  pse: PropTypes.shape({
    drug_ndc_code: PropTypes.string.isRequired,
    errors:        PropTypes.arrayOf(PropTypes.string)
  })
};

export default PrescriptionSubmissionErrors;
