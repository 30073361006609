import { NOTIFICATION_MESSAGES, ACCEPTING_NEW_PATIENT_REASON } from './constants';

export const autoDeclineEnabled = Boolean(window.FEATURE_TOGGLES.auto_decline);

export const extractData = (data, providerId, maxOverallPatientsLimit, maxDailyNewPatientsLimit, isAnyConsultCompleted) => {
    if (!data) return undefined;
    const practiceTeamData = data.practiceTeamParticipants.length > 0 ? data.practiceTeamParticipants[0] : undefined;
    const modal = {
      practiceTeamParticipantId: practiceTeamData ? practiceTeamData.id : undefined,
      providerId,
      overallPatientsLimit:      practiceTeamData ? practiceTeamData.providerPanelExtension.maxSize : maxOverallPatientsLimit,
      dailyNewPatientLimit:      practiceTeamData ? practiceTeamData.providerPanelExtension.dailyNewPatientLimit : maxDailyNewPatientsLimit,
      currentPatients:           data.currentPatients,
      states:                    data.stateLicenses
    };
    const summary = {
      currentPatients:           data.currentPatients,
      todayNewPatients:          data.todayPatients.newPatients,
      todayEstablishedPatients:  data.todayPatients.establishedPatients,
      isAnyConsultCompleted:     isAnyConsultCompleted
    }
    return { modal, summary }
};

export const getMessages = (reasonCd, personType, licenseExpired) => {
  const bannerMessages = [];
  const tooltipMessage = [];

  const autoDecline = reasonCd === ACCEPTING_NEW_PATIENT_REASON.off_by_autodecline;
  const offAdmin = (!autoDeclineEnabled && personType === 'Admin') || reasonCd === ACCEPTING_NEW_PATIENT_REASON.off_by_admin;

    if (offAdmin) {
      bannerMessages.push(NOTIFICATION_MESSAGES.turned_off_by_admin);
      tooltipMessage.push(NOTIFICATION_MESSAGES.turned_off_by_admin);
    }
    if (licenseExpired && offAdmin) {
      bannerMessages.push(NOTIFICATION_MESSAGES.no_active_licenses);
    }
    if (autoDecline) {
      bannerMessages.push(NOTIFICATION_MESSAGES.turned_off_by_autodecline);
    }
    if (licenseExpired) {
      tooltipMessage.push(NOTIFICATION_MESSAGES.no_active_licenses);
    }

  return { bannerMessages, tooltipMessage }
};
