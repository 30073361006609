import React from 'react';
import PropTypes from 'prop-types';

import DELETE_PENDING_PRESCRIPTION_MUTATION from '../mutations/delete-pending-prescription.graphql';
import { Mutation } from "react-apollo";

class CanadaProviderOrders extends React.Component {
  render() {
    const { providerOrders } = this.props;
    if (!providerOrders.length) {
      return null;
    }

    return (
      <div id="pending_prescriptions">
        <table>
          <thead>
            <tr>
              <th>Drug</th>
              <th>Quantity</th>
              <th>Instructions</th>
              <th>Requestor</th>
              <th>Refills</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { providerOrders.map(order =>
              <tr key={order.id}>
                <td>{ order.medicationRecord.drugName }</td>
                <td>{ order.medicationRecord.quantity }</td>
                <td>{ order.instructions }</td>
                <td>
                  { order.requestor.name }
                  <br />
                  ({ order.requestor.type })
                </td>
                <td>{ order.refills }</td>
                <td>
                  <Mutation mutation={DELETE_PENDING_PRESCRIPTION_MUTATION} refetchQueries={['activeConsultationQuery']}>
                    { (deletePendingPrescription, { loading }) =>
                      <button
                        disabled={loading}
                        onClick={() => deletePendingPrescription({ variables: { providerOrderId: order.globalId } })}
                        type="button"
                      >
                        {loading ? 'Deleting' : 'Delete'}
                      </button>
                    }
                  </Mutation>
                </td>
              </tr>)
            }
          </tbody>
        </table>
      </div>
    );
  }
}

CanadaProviderOrders.propTypes = {
  providerOrders: PropTypes.arrayOf(PropTypes.object)
};

export default CanadaProviderOrders;
