import React from 'react';
import PropTypes from 'prop-types';

const DisconnectedIcon = ({ width = 11, height = 11, fill = '#565757', ...props }) => (
  <svg {...props} width={width} height={height} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.27249 4.2483L3.63798 0.88281C4.21165 0.290024 5.18688 0.710711 5.18688 1.53296V3.06273C7.82573 3.13922 11 3.65552 11 6.9254C11 8.32131 10.2734 9.48776 9.14515 10.2335C8.43764 10.7116 7.50065 10.0423 7.76836 9.20093C8.22729 7.70941 7.99783 6.9254 5.18688 6.7533V8.24483C5.18688 9.06708 4.21165 9.48776 3.63798 8.89498L0.27249 5.52949C-0.0908308 5.18529 -0.0908308 4.5925 0.27249 4.2483ZM4.26901 8.24483V5.81632C7.42416 5.83544 9.58496 6.44735 8.62886 9.46864C9.31725 9.00971 10.0821 8.20658 10.0821 6.9254C10.0821 4.19094 7.0226 3.98059 4.26901 3.98059V1.51384L0.90352 4.87933L4.26901 8.24483Z"
      fill={fill}
    />
  </svg>
);

DisconnectedIcon.propTypes = {
  width:  PropTypes.number,
  height: PropTypes.number,
  fill:   PropTypes.string
};

export default DisconnectedIcon;
