import { connect } from 'react-redux';

import NetworkTest from './network-test';
import { networkTestCancel } from '../actions';
import getNetworkTestFinalizing from '../reducers/selectors/getNetworkTestFinalizing';

const mapDispatchToProps = {
  cancel: networkTestCancel
};

export const mapStateToProps = state => {
  const initiated = state.networkTest.status.initiated;
  const inProgress = state.networkTest.status.inProgress;
  const finalizing = getNetworkTestFinalizing(state);
  const hasError = Boolean(state.networkTest.status.error);
  const successful = initiated && !(inProgress || hasError);

  return {
    initiated,
    inProgress,
    finalizing,
    hasError,
    successful
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkTest);
