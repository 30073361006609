import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { map } from 'lodash';
import { translate } from '@td/shared_utils';

// Components and constants
import TeladocModal from '../../TeladocModal';

// Styles
import '../styles/shared-working-hours-delete-modal.scss';

// Icons
const closeIcon = require('../../assets/images/event_details/close.svg');

const TRANSLATION_SCOPE = 'my_schedule.modals.regular_working_hours_delete';

const RegularWorkingHoursDeleteModal = ({ data, onModalClose, onDelete }) => {
  const modalActionButtons = () => (
    <div className="actionButtons">
      <div className="regularActions">
        <button id="cancelButton" key="cancelButton" className="button" onClick={onModalClose}>
          {translate(null, TRANSLATION_SCOPE, 'actions.cancel')}
        </button>
      </div>
      <div className="deleteAction">
        <button id="deleteButton" key="deleteButton" className="button" onClick={onDelete}>
          {translate(null, TRANSLATION_SCOPE, 'actions.delete')}
        </button>
      </div>
    </div>
  );

  return (
    <TeladocModal
      className="workingHoursDeleteModalWrapper"
      title={translate(null, TRANSLATION_SCOPE, 'title')}
      isOpen
      onClose={onModalClose}
      closeIcon={closeIcon}
    >
      <div className="contentWrapper">
        <div className="subtitle">
          <p>{translate(null, TRANSLATION_SCOPE, 'subtitle')}</p>
        </div>
        <div className="contentWrapper">
          <div className="dateAntTimeInfoWrapper">
            <div className="dayName">{data.dayName}</div>
            <div className="dateAndTimeList">
              {map(data.dateAndTime, ({ providerScheduleId, startDateTime, endDateTime }) => (
                <div className="dateAndTimeItemInfo" key={`regularWorkingHours-${providerScheduleId}`}>
                  {moment(startDateTime)
                    .format('h:mm A')
                    .replace(':00', '')}
                  <span className="separator" />
                  {moment(endDateTime)
                    .format('h:mm A')
                    .replace(':00', '')}
                </div>
              ))}
            </div>
          </div>
        </div>
        {modalActionButtons()}
      </div>
    </TeladocModal>
  );
};

RegularWorkingHoursDeleteModal.propTypes = {
  data: PropTypes.shape({
    dayName:     PropTypes.string.isRequired,
    dateAndTime: PropTypes.arrayOf(
      PropTypes.shape({
        startDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string])
          .isRequired,
        endDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string])
          .isRequired,
        providerScheduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      })
    )
  }).isRequired,
  onModalClose: PropTypes.func.isRequired,
  onDelete:     PropTypes.func.isRequired
};

export default RegularWorkingHoursDeleteModal;
