import React from 'react';
import { connect } from 'react-redux';
import { map, join, compact } from 'lodash';
import { secondaryDiagnosesListSelector } from '../../redux/selectors';

const SummarySecondaryDiagnoses = ({ diagnosesList }) => {
  const arrayOfDescriptions = map(diagnosesList, diagnosis => diagnosis.text);

  let secondaryDiagnosesText = join(compact(arrayOfDescriptions), '\n');

  if (secondaryDiagnosesText.length === 0) {
    secondaryDiagnosesText = 'None';
  }

  return <span style={{ whiteSpace: 'pre-line' }}>{secondaryDiagnosesText}</span>;
};

const mapStateToProps = state => ({
  diagnosesList: secondaryDiagnosesListSelector(state)
});

export default connect(mapStateToProps)(SummarySecondaryDiagnoses);
