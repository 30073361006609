import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Section } from './components';
import { SURVEY_CODE, sectionCodes, fieldNames } from './constants';
import { fetchTravelMedicationSurvey } from './actions';
import { isEmpty } from 'lodash';

const TravelMedicationSurvey = React.memo(({ survey, fetchTravelMedicationSurvey }) => {
  useEffect(() => {
    fetchTravelMedicationSurvey();
  }, []);

  if (isEmpty(survey)) {
    return null;
  }

  return (
    <React.Fragment>
      <input type="hidden" name={fieldNames.SURVEY_CODE} value={SURVEY_CODE} />

      {survey[sectionCodes.ASSOCIATED_CONDITIONS] && (
        <Section
          sectionCode={sectionCodes.ASSOCIATED_CONDITIONS}
          questions={survey[sectionCodes.ASSOCIATED_CONDITIONS]}
        />
      )}

      {survey[sectionCodes.VACCINATION_HISTORY] && (
        <Section
          sectionCode={sectionCodes.VACCINATION_HISTORY}
          questions={survey[sectionCodes.VACCINATION_HISTORY]}
        />
      )}
    </React.Fragment>
  );
});

const mapStateToProps = ({ travelMedicationSurvey: { survey } }) => ({
  survey
});

const mapDispatchToProps = (dispatch) => ({
  fetchTravelMedicationSurvey: () => dispatch(fetchTravelMedicationSurvey())
});

TravelMedicationSurvey.displayName = 'TravelMedicationSurvey';
TravelMedicationSurvey.propTypes = {
  survey: PropTypes.object.isRequired,
  fetchTravelMedicationSurvey: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TravelMedicationSurvey);
