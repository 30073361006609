exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1k2JRAC32QodvwkIRTPZGo {\n  position: relative;\n  padding: 1.25rem 5.25rem 1.25rem 2rem;\n  border-radius: 0.5rem;\n  font-weight: 400;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  color: #000000;\n  white-space: pre-wrap;\n  box-sizing: border-box;\n  border-width: 2px;\n  border-style: solid; }\n  ._1k2JRAC32QodvwkIRTPZGo a {\n    cursor: pointer; }\n  ._1k2JRAC32QodvwkIRTPZGo._1a35in_7gndae_5GtYYDXk {\n    background: #F8D9DD;\n    border-color: #D0021C; }\n  ._1k2JRAC32QodvwkIRTPZGo._2iRGPF-oI1K17yK9SLpJGO {\n    background: #F9E6B5;\n    border-color: #F9A101; }\n  ._1k2JRAC32QodvwkIRTPZGo._3rmB89S1ScsUHAN3P2By9m {\n    background: #E7F1CD;\n    border-color: #167F01; }\n\n.hgvrGbVdk7GZT8sjx7Mp2 {\n  all: unset;\n  position: absolute;\n  top: 1.125rem;\n  right: 1.75rem;\n  line-height: 0;\n  padding: 0.25rem;\n  cursor: pointer; }\n", ""]);

// exports
exports.locals = {
	"banner": "_1k2JRAC32QodvwkIRTPZGo",
	"banner": "_1k2JRAC32QodvwkIRTPZGo",
	"errorBanner": "_1a35in_7gndae_5GtYYDXk",
	"errorBanner": "_1a35in_7gndae_5GtYYDXk",
	"warningBanner": "_2iRGPF-oI1K17yK9SLpJGO",
	"warningBanner": "_2iRGPF-oI1K17yK9SLpJGO",
	"successBanner": "_3rmB89S1ScsUHAN3P2By9m",
	"successBanner": "_3rmB89S1ScsUHAN3P2By9m",
	"bannerCloseButton": "hgvrGbVdk7GZT8sjx7Mp2",
	"bannerCloseButton": "hgvrGbVdk7GZT8sjx7Mp2"
};