exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3vO4m5jO4FVAzeWBOCvl11 {\n  width: 100%;\n  height: 0;\n  border-top: 1.5px dashed #6dc4e5;\n  margin: 8px 0; }\n", ""]);

// exports
exports.locals = {
	"divider": "_3vO4m5jO4FVAzeWBOCvl11",
	"divider": "_3vO4m5jO4FVAzeWBOCvl11"
};