import React from 'react';

const CannotStart = () =>
  <div className="not-supported-browser">
    <br/>
    <h4>Unfortunately, we are unable to initiate the video.</h4>
    <h4>Please call the patient at the phone number provided.</h4>
  </div>;

export default CannotStart;
