import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';
import cx from 'classnames';
import { Field } from 'formik';

const { fullWidth, row, quarter, field } = styles;

class StructuredSigBuilder extends Component {
  constructor(props) {
    super(props);
  }

  renderUnitOptions = () => {
    const { values: { units } } = this.props;

    return units.map((option, index) =>
      <option key={index} value={option}>{option}</option>
    );
  }

  renderFrequnecyOptions = () => {
    const { values: { directions } } = this.props;

    return directions.map((option, index) =>
      <option key={index} value={option}>{option}</option>
    );
  }

  render() {
    const { values, errors } = this.props;

    return (
      <div
        className={styles.sigInstructions}
        style={{ border: '0px solid gray' }}
      >

        <h3>SIG Instructions</h3>

        <div className={cx(row, quarter)}>
          <div className={field}>
            <label>Direction</label>
            <Field
              name="sig.direction"
              type="text"
              className="form-field"
              value={values.sig.direction}
            />
            {
              errors && errors.sig && errors.sig.direction &&
              <div>{errors.sig.direction}</div>
            }
          </div>

          <div className={field}>
            <label>Quantity</label>
            <Field
              name="sig.quantity"
              type="text"
              className="form-field"
              placeholder="Enter quantity value"
              value={values.sig.quantity}
            />
            {
              errors && errors.sig && errors.sig.quantity &&
              <div>{errors.sig.quantity}</div>
            }
          </div>

          <div className={field}>
            <label>Unit</label>
            <Field
              name="sig.unit"
              component="select"
              className="form-field"
              placeholder="Units"
            >
              <option>- Select drug unit -</option>
              {this.renderUnitOptions()}
            </Field>
            {
              errors && errors.sig && errors.sig.unit &&
              <div>{errors.sig.unit}</div>
            }
          </div>

          <div className={field}>
            <label>Frequency</label>
            <Field
              name="sig.frequency"
              component="select"
              className="form-field"
              placeholder="Frequency"
            >
              <option>- Select drug frequency -</option>
              {this.renderFrequnecyOptions()}
            </Field>
            {
              errors && errors.sig && errors.sig.frequency &&
              <div>{errors.sig.frequency}</div>
            }
          </div>
        </div>

        <div className={cx(row, fullWidth)}>
          <div className={field}>
            <label>Additional Instructions</label>
            <Field
              name="sig.additionalInstructions"
              type="text"
              className="form-field"
              value={values.sig.additionalInstructions}
            />
            {
              errors && errors.sig && errors.sig.additionalInstructions &&
              <div>{errors.sig.additionalInstructions}</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

StructuredSigBuilder.propTypes = {
  values: PropTypes.shape({
    sig: PropTypes.shape({
      additionalInstructions: PropTypes.string,
      direction:              PropTypes.string,
      quantity:               PropTypes.string,
      unit:                   PropTypes.string,
      route:                  PropTypes.string,
      frequency:              PropTypes.string
    })
  }),
  errors:       PropTypes.object.isRequired,
  touched:      PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur:   PropTypes.func.isRequired
};

export default StructuredSigBuilder;
