import { mapKeysDeep, transformKey } from '@td/utils';
import {
  FETCH_CLINICAL_MEDICATIONS_START,
  FETCH_CLINICAL_MEDICATIONS_SUCCESS,
  FETCH_CLINICAL_MEDICATIONS_FAIL,
  SET_SURESCRIPTS_UPDATED_FLAG
} from '../actionTypes';

const DEFAULT_STATE = {
  clinicalMeds:         [],
  fetched:              false,
  isLoading:            false,
  surescriptsUpdated:   true,
  message:              '',
  value:                ''
};

const buildClinicalMeds = (data = []) =>
  data.map(pod => mapKeysDeep(pod, transformKey));

const medicationsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_CLINICAL_MEDICATIONS_START: {
      return {
        ...state,
        fetched:   false,
        isLoading: true
      };
    }
    case FETCH_CLINICAL_MEDICATIONS_SUCCESS: {
      const { clinical_medications } = action.payload.data;

      return {
        ...state,
        fetched:      true,
        clinicalMeds: buildClinicalMeds(clinical_medications)
      };
    }
    case FETCH_CLINICAL_MEDICATIONS_FAIL: {
      return {
        ...state,
        fetched: false,
        message: action.message
      };
    }
    case SET_SURESCRIPTS_UPDATED_FLAG: {
      const { surescriptsUpdated } = action.payload;

      return {
        ...state,
        surescriptsUpdated
      };
    }
    default: {
      return state;
    }
  }
};

export default medicationsReducer;
