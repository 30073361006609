import React from 'react';
import { connect } from 'react-redux';
import { map, join, compact, includes, concat, toInteger, uniq } from 'lodash';
import { secondaryDiagnosesListSelector } from '../../redux/selectors';

const ValidationSecondaryDiagnoses = ({ diagnosesList, addErrors, primaryDiagnosisId }) => {
  const arrayOfDiagnosesIds = map(diagnosesList, diagnosis => diagnosis.id);
  const compactedArrayOfDiagnosesIds = compact(arrayOfDiagnosesIds);

  if (compactedArrayOfDiagnosesIds.length !== arrayOfDiagnosesIds.length) {
    addErrors('You must choose a valid diagnosis.');
  }

  const arrayOfDiagnosesIdsWithPrimaryId = concat(compactedArrayOfDiagnosesIds, toInteger(primaryDiagnosisId));

  if (uniq(arrayOfDiagnosesIdsWithPrimaryId).length !== arrayOfDiagnosesIdsWithPrimaryId.length) {
    addErrors('Duplicate code has been entered. Please resolve.');
  }

  return null;
};

const mapStateToProps = state => ({
  diagnosesList: secondaryDiagnosesListSelector(state)
});

export default connect(mapStateToProps)(ValidationSecondaryDiagnoses);
