exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._18yURV-ULhrkG4ds0ZZjAI {\n  background: #FFFFFF;\n  border: 2px solid #4E89A4;\n  box-sizing: border-box;\n  border-radius: 4px 4px 0 0; }\n\n._3qmA5iaQGX11kjGEY0yPTe {\n  background: #FFFFFF;\n  border: 1px solid  #4E89A4;\n  border-bottom: none;\n  box-sizing: border-box;\n  border-radius: 12px 12px 0 0; }\n\n.WOopqTDII1gnDmUMDYx1W {\n  position: fixed;\n  top: 0;\n  border-top: none;\n  border-radius: 0 0 4px 4px;\n  z-index: 9999; }\n\n.tZrs8MnpE1BqkMNU_lOBO {\n  background: #5B2F91;\n  border-color: #5B2F91; }\n  .tZrs8MnpE1BqkMNU_lOBO .lEqAx4bIxaiWy9shAcUl2 {\n    color: #FFFFFF; }\n\n.lEqAx4bIxaiWy9shAcUl2 {\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 16px;\n  color: #696B6B;\n  font-size: 0.875rem; }\n", ""]);

// exports
exports.locals = {
	"callStatusWrapper": "_18yURV-ULhrkG4ds0ZZjAI",
	"callStatusWrapper": "_18yURV-ULhrkG4ds0ZZjAI",
	"callStatusVideoWrapper": "_3qmA5iaQGX11kjGEY0yPTe",
	"callStatusVideoWrapper": "_3qmA5iaQGX11kjGEY0yPTe",
	"callStatusFixed": "WOopqTDII1gnDmUMDYx1W",
	"callStatusFixed": "WOopqTDII1gnDmUMDYx1W",
	"callStatusInProgress": "tZrs8MnpE1BqkMNU_lOBO",
	"callStatusInProgress": "tZrs8MnpE1BqkMNU_lOBO",
	"callStatus": "lEqAx4bIxaiWy9shAcUl2",
	"callStatus": "lEqAx4bIxaiWy9shAcUl2"
};