import {
  ADD_SYMPTOM_INPUT_CHANGE,
  ADD_SYMPTOM_SEARCH_REQUEST,
  CLEAR_COMPLAINT
} from '../action-types';

export const DEFAULT_STATE = {
  searchValue: ''
};

function onAddSymptomInputChange(state, action) {
  return {
    searchValue: action.payload.inputValue
  };
}

function onStateReset() {
  return DEFAULT_STATE;
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_SYMPTOM_INPUT_CHANGE:
      return onAddSymptomInputChange(state, action);
    case ADD_SYMPTOM_SEARCH_REQUEST:
    case CLEAR_COMPLAINT:
      return onStateReset(state, action);
    default:
      return state;
  }
};
