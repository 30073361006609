import React from 'react';
import PropTypes from 'prop-types';
import PopupForSubjectiveTab from '../../../components/PopupForSubjectiveTab';

const AdditionalNotes = ({ formValues, changeField }) => (
  <div className="additional-notes">
    <h4>
      <label htmlFor="interview[subjective_additional_notes]" className="label">
        Additional Notes
      </label>
    </h4>
    <PopupForSubjectiveTab />
    <textarea
      id="interview[subjective_additional_notes]"
      name="interview[subjective_additional_notes]"
      onChange={changeField}
      value={formValues.subjective_additional_notes}
    />
  </div>
);

AdditionalNotes.propTypes = {
  formValues: PropTypes.shape({
    additional_notes: PropTypes.string
  }),
  changeField: PropTypes.func.isRequired
};

export default AdditionalNotes;
