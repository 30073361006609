import React from 'react';
import PropTypes from 'prop-types';
import { update, get } from 'lodash';
import Survey, { surveyInsert } from 'app/surveys';
import { style } from 'typestyle';
import { translate } from '@td/shared_utils';

const SURVEY_NAMING_KEY = 'interview[psychosocial_stressors][]';
const NO_PSYCHOSOCIAL_STRESSORS_CHECKED = 'NONE';

const surveyStyles = {
  SECTIONMNEMONIC_PSYCHOSOCIALSTRESSORS: {
    Q_1_1: {
      answer: style({
        marginTop: '1em'
      })
    }
  }
};

// Callback function to disables checkboxes based on the selection.
const disablepsychosocialStressors = (answerVal, answerCode, questionCode) => {
  const psychosocialStressorChecked = answerVal[answerCode];
  const noneCheckboxSelector = $(`.${questionCode}_NONE`);
  const allpsychosocialStressorsSelector = $('.psychosocial-stressors');

  if (psychosocialStressorChecked && answerCode === NO_PSYCHOSOCIAL_STRESSORS_CHECKED) {
    noneCheckboxSelector
      .siblings()
      .find('input:checkbox')
      .attr('disabled', 'disabled');
  } else if (psychosocialStressorChecked && answerCode !== NO_PSYCHOSOCIAL_STRESSORS_CHECKED) {
    noneCheckboxSelector.find('input:checkbox').attr('disabled', 'disabled');
  } else if (allpsychosocialStressorsSelector.find('input:checkbox:checked').length === 0) {
    allpsychosocialStressorsSelector.find('input:checkbox').removeAttr('disabled');
  }
};

const PsychosocialStressors = ({ psychosocialStressorsSurvey, cachedAnswers }) => {
  // Adds callback that disables the checkboxes based on the selection.
  psychosocialStressorsSurvey.sections.forEach(stressorSurvey => {
    stressorSurvey.questions.forEach(question => {
      question.choices.forEach(choice => {
        surveyInsert(
          psychosocialStressorsSurvey,
          [stressorSurvey.sectionCode, question.questionCode, choice.answerCode],
          'callback',
          disablepsychosocialStressors
        );
      });
    });
  });

  update(
    psychosocialStressorsSurvey,
    'title',
    title => `${title} ${translate(null, 'ehr.mh.subjective_tab', 'required_label')}`
  );

  return (
    <div className="psychosocial-stressors">
      <h4>
        <label htmlFor="interview[subjective_psychosocial_stressors]" className="label">
          {get(psychosocialStressorsSurvey, 'title', null)}
        </label>
      </h4>
      <Survey
        surveyObject={psychosocialStressorsSurvey}
        surveyNamingKey={SURVEY_NAMING_KEY}
        cachedAnswers={cachedAnswers}
        validationErrors={{}}
        styles={surveyStyles}
      />
      <div id="survey_validation_error" className="interview_inline_validation_error" />
    </div>
  );
};

PsychosocialStressors.propTypes = {
  psychosocialStressorsSurvey: PropTypes.object,
  cachedAnswers:               PropTypes.object
};

export default PsychosocialStressors;
