import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { flattenDeep, get, map, size } from 'lodash';
import { translate } from '@td/shared_utils';

// Components
import Breadcrumbs from '../../components/breadcrumbs';
import RegularWorkingHoursModal from './regular-working-hours-modal';
import RegularWorkingHoursDeleteModal from './regular-working-hours-delete-modal';
import PersonalEventModal from './personal-event-modal';
import PersonalEventDeleteModal from './personal-event-delete-modal';
import SpecialWorkingHoursModal from './special-working-hours-modal';
import SpecialWorkingHoursDeleteModal from './special-working-hours-delete-modal';
import Notification from './notification';
import Availabilities from './availabilities';
import PostConsultBufferSection from './post-consult-buffer-section';
import PostConsultBufferModal from './post-consult-buffer-modal';
import { ExternalCalendarsView } from '../features/external-calendar';
// Styles
import '../styles/availability-settings.scss';
import '../styles/datepicker-custom-styles.scss';

// Constants
import { MyScheduleModals } from '../constants';
import { currentlyActiveEvents } from '../helpers';
import { useRecommendedWorkingHours } from '../../recommended-working-hours/contexts/recommended-working-hours-context';
import RecommendedWorkingHoursToggle from '../../recommended-working-hours/containers/recommended-working-hours-toggle';
import useUpdateCustomBuffer from '../services/custom-buffer-mutation-service';
import { screenTypes } from '../../external-calendar-account-alerts/constants';
import ExternalCalendarAccountAlerts from '../../external-calendar-account-alerts';

const TRANSLATION_SCOPE = 'my_schedule.settings_page';

// Icons
const trashIcon = require('../../assets/images/trash_icon.svg');
const editIcon = require('../../assets/images/edit_icon.svg');

const AvailabilitySettings = ({
  regularWorkingHours,
  specialWorkingHours,
  personalEvents,
  onGoBack,
  onSubmit,
  notification,
  setNotification,
  resetNotifications,
  isRegularWorkingHoursInitiated,
  resetInit,
  validateProps,
  validatePropsMessages,
  saveShift,
  CREATE_SHIFT_EVENT,
  UPDATE_SHIFT_EVENT,
  DELETE_SHIFT_EVENT,
  createRecommendedWorkingHours,
  customBuffer,
  refetchQuery,
  providerId
}) => {
  const [activeModal, setActiveModal] = useState(null);
  const [providerCustomBuffer, setProviderCustomBuffer] = useState(customBuffer || 0);
  const [tempCustomBuffer, setTempCustomBuffer] = useState(customBuffer || 0);

  const { callUpdateCustomBuffer } = useUpdateCustomBuffer();
  const {
    recommendedWorkingHours,
    recommendedWorkingHoursFlag,
    recommendedWorkingHoursIsEnabled
  } = useRecommendedWorkingHours();

  const areRegularWorkingHoursSet = regularWorkingHours && size(regularWorkingHours) > 0;
  const areSpecialWorkingHoursSet = specialWorkingHours && specialWorkingHours.length > 0;
  const arePersonalEventsSet = personalEvents && personalEvents.length > 0;

  // Functions / Handlers and data computing
  const onAddRegularWorkingHours = () => {
    setActiveModal({
      type: MyScheduleModals.REGULAR_WORKING_HOURS
    });
    resetNotifications();
  };

  const onAddEditPersonalEvent = event => {
    setActiveModal({
      type: MyScheduleModals.PERSONAL_EVENTS,
      event
    });
    resetNotifications();
  };

  const onAddEditSpecialWorkingHours = event => {
    setActiveModal({
      type: MyScheduleModals.SPECIAL_WORKING_HOURS,
      event
    });
    resetNotifications();
  };

  // Close any modal
  const handleModalClose = () => {
    setActiveModal(null);
    resetInit();
  };

  const handleSubmitRegularWorkingHours = submitData => {
    onSubmit({ ...activeModal, submitData });
    setActiveModal(null);
  };

  const handleDelete = () => {
    const deleteData = { ...activeModal, action: 'delete' };
    if (activeModal.type.id === MyScheduleModals.REGULAR_WORKING_HOURS.id) {
      deleteData.event = { ...activeModal.data.dateAndTime };
    }

    onSubmit(deleteData);
    setActiveModal(null);
  };

  const handleSubmit = submitEvent => {
    const submitData = {
      ...activeModal,
      event: {
        ...activeModal.event,
        ...submitEvent
      }
    };

    onSubmit(submitData);
    setActiveModal(null);
  };

  const handleBredcrumbClick = index => {
    if (index === 0) {
      onGoBack();
    }
  };

  const onDeleteRegularWorkingHours = ({ dayName, dateAndTime }) => {
    setActiveModal({
      type:   MyScheduleModals.REGULAR_WORKING_HOURS,
      action: 'delete',
      data:   {
        dayName,
        dateAndTime
      }
    });
  };

  const onDeleteSpecialWorkingHours = event => {
    setActiveModal({
      type:   MyScheduleModals.SPECIAL_WORKING_HOURS,
      action: 'delete',
      event
    });
  };

  const onDeletePersonalEvent = event => {
    setActiveModal({
      type:   MyScheduleModals.PERSONAL_EVENTS,
      action: 'delete',
      event
    });
  };

  const breadcrumbsItems = [
    {
      label: translate(null, TRANSLATION_SCOPE, 'breadcrumbs.item1')
    },
    {
      label: translate(null, TRANSLATION_SCOPE, 'breadcrumbs.item2')
    }
  ];

  const handleSubmitCustomBuffer = async () => {
    const { success, errors } = await callUpdateCustomBuffer({ providerId, customBufferTime: tempCustomBuffer });

    if (success) {
      setProviderCustomBuffer(tempCustomBuffer);
      setNotification({ type: 'success', message: translate(null, 'my_schedule.custom_buffer.mutations', 'success') });
      refetchQuery();
    } else {
      const defaultErrorMsg = translate(null, 'my_schedule.custom_buffer.mutations', 'error');
      const errorMsg = get(flattenDeep([errors]), 0, defaultErrorMsg);
      setNotification({ type: 'error', message: typeof errorMsg === 'string' || errorMsg instanceof String ? errorMsg : defaultErrorMsg });

      setTempCustomBuffer(providerCustomBuffer);
    }

    handleModalClose();
  };

  const handleCancelCustomBuffer = () => {
    setTempCustomBuffer(providerCustomBuffer);
    handleModalClose();
  };

  /*
   * Effects
   */
  useEffect(() => {
    if (!activeModal && providerCustomBuffer !== tempCustomBuffer) {
      setActiveModal({ type: MyScheduleModals.CUSTOM_BUFFER });
    }
  }, [tempCustomBuffer]);

  /*
   * Render
   */
  let regularWorkingHoursCount = 0;
  return (
    <div className="availabilitySettingsWrapper">
      <Breadcrumbs items={breadcrumbsItems} onClick={handleBredcrumbClick} onBack={onGoBack} activeIndex={1} />
      <h1 className="availabilityTitle">{translate(null, TRANSLATION_SCOPE, 'title')}</h1>
      {notification &&
        <Notification type={notification.type} message={notification.message} />
      }
      <p className="subtitle">
        {translate(null, TRANSLATION_SCOPE, 'subtitle')}
      </p>

      <div className="availabilitySettingsBanners">
        <ExternalCalendarAccountAlerts
          screenType={screenTypes.WORKING_HOURS}
        />
      </div>

      <div className="availabilitiesWrapper">
        <div className="availabilityBlock typeRegular">
          <div className="availabilityList">
            <div className="header">
              <div className="availabilityText">
                <h4 className="sectionTitle">{translate(null, TRANSLATION_SCOPE, 'section1.title')}</h4>
                <p className={`sectionSubtitle ${areRegularWorkingHoursSet && 'timeSet'}`}>
                  {translate(null, TRANSLATION_SCOPE, 'section1.subtitle')}
                </p>
              </div>
              <div className="availabilityAction">
                <button className="button" onClick={() => onAddRegularWorkingHours()}>
                  {`${
                    areRegularWorkingHoursSet
                      ? translate(null, TRANSLATION_SCOPE, 'actions.edit')
                      : translate(null, TRANSLATION_SCOPE, 'actions.add')
                  }`}
                </button>
              </div>
            </div>
            {areRegularWorkingHoursSet &&
              map(regularWorkingHours, (regularWorkingHourGroup, regularWorkingHourGroupKey) => {
                if (!regularWorkingHourGroup || (regularWorkingHourGroup && regularWorkingHourGroup.length === 0)) {
                  return;
                }
                regularWorkingHoursCount += 1;

                return (
                  <div
                    className={`timeSlotWrapper ${regularWorkingHoursCount < size(regularWorkingHours) &&
                      'hasSeparator'}`}
                    key={`regularWorkingHourGroup-${regularWorkingHourGroupKey}`}
                  >
                    <div className="dateAndTime">
                      <div className="timeSlotDay">{regularWorkingHourGroupKey}</div>

                      <div className="timeSlotTimeWrapper">
                        {map(regularWorkingHourGroup, ({ startDateTime, endDateTime, providerScheduleId }) => (
                          <div className="timeSlotTime" key={`regularWorkingHourGroupHours-${providerScheduleId}`}>
                            {startDateTime.format('h:mm A').replace(':00', '')} -{' '}
                            {endDateTime.format('h:mm A').replace(':00', '')}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="timeSlotActions">
                      <img
                        className="deleteAction"
                        key="deleteIcon"
                        src={trashIcon}
                        onClick={() =>
                          onDeleteRegularWorkingHours({
                            dayName:     regularWorkingHourGroupKey,
                            dateAndTime: regularWorkingHourGroup
                          })
                        }
                        alt={translate(null, TRANSLATION_SCOPE, 'actions.delete')}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="availabilityBlock typeAlternate">
          <div className="availabilityList">
            <div className="header">
              <div className="availabilityText">
                <h4 className="sectionTitle">{translate(null, TRANSLATION_SCOPE, 'section2.title')}</h4>
                <p className={`sectionSubtitle ${areSpecialWorkingHoursSet && 'timeSet'}`}>
                  {translate(null, TRANSLATION_SCOPE, 'section2.subtitle')}
                </p>
              </div>
              <div className="availabilityAction">
                <button className="button" onClick={() => onAddEditSpecialWorkingHours()}>
                  {translate(null, TRANSLATION_SCOPE, 'actions.add')}
                </button>
              </div>
            </div>
            {areSpecialWorkingHoursSet &&
              map(currentlyActiveEvents(specialWorkingHours), (specialWorkingHour, index) => (
                <div
                  className={`timeSlotWrapper ${index + 1 < specialWorkingHours.length && 'hasSeparator'}`}
                  key={`specialWorkingHours-${specialWorkingHour.providerScheduleId}`}
                >
                  <div className="dateAndTime">
                    <div className="timeSlotDate">
                      {moment(specialWorkingHour.startDateTime).format('ddd, MMM D, Y')}
                    </div>
                    <div className="timeSlotTime">
                      {moment(specialWorkingHour.startDateTime)
                        .format('h:mm A')
                        .replace(':00', '')}{' '}
                      -{' '}
                      {moment(specialWorkingHour.endDateTime)
                        .format('h:mm A')
                        .replace(':00', '')}
                    </div>
                  </div>
                  <div className="reason">
                    <p>{specialWorkingHour.eventName}</p>
                  </div>
                  <div className="timeSlotActions">
                    <img
                      className="editAction"
                      key="editIcon"
                      src={editIcon}
                      onClick={() => onAddEditSpecialWorkingHours(specialWorkingHour)}
                      alt={translate(null, TRANSLATION_SCOPE, 'actions.edit')}
                    />
                    <img
                      className="deleteAction"
                      key="deleteIcon"
                      src={trashIcon}
                      onClick={() => onDeleteSpecialWorkingHours(specialWorkingHour)}
                      alt={translate(null, TRANSLATION_SCOPE, 'actions.delete')}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>

        {recommendedWorkingHoursIsEnabled && (
          <div className="availabilityBlock typeRecommendedWorkingHours">
            <RecommendedWorkingHoursToggle />

            {recommendedWorkingHoursFlag && (
              <Availabilities
                title={translate(null, 'my_schedule.working_hours.recommended_working_hours', 'title')}
                description={translate(null, 'my_schedule.working_hours.recommended_working_hours', 'description')}
                noSlotsMessage={translate(null, 'my_schedule.working_hours.recommended_working_hours', 'no_slots_message')}
                slots={recommendedWorkingHours}
                onRowAdd={createRecommendedWorkingHours}
                limit={5}
              />
            )}
          </div>
        )}

        <div className="availabilityBlock typePersonalEvent">
          <div className="availabilityList">
            <div className="header">
              <div className="availabilityText">
                <h4 className="sectionTitle">{translate(null, TRANSLATION_SCOPE, 'section3.title')}</h4>
                <p className={`sectionSubtitle ${arePersonalEventsSet && 'timeSet'}`}>
                  {translate(null, TRANSLATION_SCOPE, 'section3.subtitle')}
                </p>
              </div>
              <div className="availabilityAction">
                <button className="button" onClick={() => onAddEditPersonalEvent()}>
                  {translate(null, TRANSLATION_SCOPE, 'actions.add')}
                </button>
              </div>
            </div>
            {arePersonalEventsSet &&
            map(currentlyActiveEvents(personalEvents), (personalEvent, index) => (
              <div
                className={`timeSlotWrapper ${index + 1 < personalEvents.length && 'hasSeparator'}`}
                key={`personalEvent-${personalEvent.providerScheduleId}`}
              >
                <div className="dateAndTime">
                  <div className="timeSlotDateStart">
                    {moment(personalEvent.startDateTime).format('ddd, MMM D, Y')}{' '}
                    <span>{moment(personalEvent.startDateTime).format('h:mm A').replace(':00', '')} - </span>
                    {moment(personalEvent.endDateTime).format('ddd, MMM D, Y')}{' '}
                    <span>{moment(personalEvent.endDateTime).format('h:mm A').replace(':00', '')}</span>
                  </div>
                </div>
                <div className="reason">
                  <p>{personalEvent.eventName}</p>
                </div>
                <div className="timeSlotActions">
                  <img
                    className="editAction"
                    key="editIcon"
                    src={editIcon}
                    onClick={() => onAddEditPersonalEvent(personalEvent)}
                    alt={translate(null, TRANSLATION_SCOPE, 'actions.edit')}
                  />
                  <img
                    className="deleteAction"
                    key="deleteIcon"
                    src={trashIcon}
                    onClick={() =>
                      onDeletePersonalEvent(personalEvent)
                    }
                    alt={translate(null, TRANSLATION_SCOPE, 'actions.delete')}
                  />
                </div>
              </div>
            )
          )}
          </div>
        </div>

        {Number.isInteger(customBuffer) &&
          <PostConsultBufferSection
            providerCustomBuffer={tempCustomBuffer}
            setProviderCustomBuffer={setTempCustomBuffer}
          />
        }
      </div>

      {activeModal && activeModal.type === MyScheduleModals.CUSTOM_BUFFER && (
        <PostConsultBufferModal
          onModalClose={handleCancelCustomBuffer}
          onSubmit={handleSubmitCustomBuffer}
        />
      )}

      {(isRegularWorkingHoursInitiated || (activeModal && activeModal.type === MyScheduleModals.REGULAR_WORKING_HOURS && activeModal.action !== 'delete')) && (
        <RegularWorkingHoursModal
          regularWorkingHours={regularWorkingHours}
          onModalClose={handleModalClose}
          onSubmit={handleSubmitRegularWorkingHours}
          validateProps={validateProps}
          validatePropsMessages={validatePropsMessages}
          saveShift={saveShift}
          CREATE_SHIFT_EVENT={CREATE_SHIFT_EVENT}
          UPDATE_SHIFT_EVENT={UPDATE_SHIFT_EVENT}
          DELETE_SHIFT_EVENT={DELETE_SHIFT_EVENT}
        />
      )}
      {activeModal && activeModal.type === MyScheduleModals.REGULAR_WORKING_HOURS && activeModal.action === 'delete' && (
        <RegularWorkingHoursDeleteModal
          data={activeModal.data}
          onModalClose={handleModalClose}
          onDelete={handleDelete}
        />
      )}

      {activeModal && activeModal.type === MyScheduleModals.PERSONAL_EVENTS && activeModal.action !== 'delete' && (
        <PersonalEventModal
          onModalClose={handleModalClose}
          onSubmit={handleSubmit}
          onCancel={handleModalClose}
          onDelete={handleDelete}
          event={activeModal.event}
          personalEvents={personalEvents}
        />
      )}
      {activeModal && activeModal.type === MyScheduleModals.PERSONAL_EVENTS && activeModal.action === 'delete' && (
        <PersonalEventDeleteModal
          event={activeModal.event}
          onModalClose={handleModalClose}
          onDelete={handleDelete}
        />
      )}

      {activeModal && activeModal.type === MyScheduleModals.SPECIAL_WORKING_HOURS && activeModal.action !== 'delete' && (
        <SpecialWorkingHoursModal
          onModalClose={handleModalClose}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          event={activeModal.event}
          specialWorkingHours={specialWorkingHours}
          regularWorkingHours={regularWorkingHours}
          personalEvents={personalEvents}
        />
      )}
      {activeModal && activeModal.type === MyScheduleModals.SPECIAL_WORKING_HOURS && activeModal.action === 'delete' && (
        <SpecialWorkingHoursDeleteModal
          event={activeModal.event}
          onModalClose={handleModalClose}
          onDelete={handleDelete}
        />
      )}

      <ExternalCalendarsView providerId={providerId} />
    </div>
  );
};

AvailabilitySettings.propTypes = {
  regularWorkingHours: PropTypes.object.isRequired,
  specialWorkingHours: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeCode: PropTypes.string,
      endDateTime:   PropTypes.Date,
      startDateTime: PropTypes.Date,
      timezoneCode:  PropTypes.string,
      __typename:    PropTypes.string
    })
  ).isRequired,
  personalEvents: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeCode: PropTypes.string,
      endDateTime:   PropTypes.Date,
      startDateTime: PropTypes.Date,
      timezoneCode:  PropTypes.string,
      __typename:    PropTypes.string
    })
  ).isRequired,
  onGoBack:     PropTypes.func.isRequired,
  onSubmit:     PropTypes.func.isRequired,
  notification: PropTypes.shape({
    type:    PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
  }),
  resetNotifications:             PropTypes.func.isRequired,
  setNotification:                PropTypes.func.isRequired,
  isRegularWorkingHoursInitiated: PropTypes.bool,
  resetInit:                      PropTypes.func.isRequired,
  validateProps:                  PropTypes.shape({
    minShiftLengthSize:       PropTypes.number,
    maxShiftLengthSize:       PropTypes.number,
    shiftTimeStep:            PropTypes.number,
    minStartDate:             PropTypes.number,
    shiftPreselectedDuration: PropTypes.number
  }).isRequired,
  validatePropsMessages: PropTypes.shape({
    startDateInPast:        PropTypes.string,
    endDateBeforeStartDate: PropTypes.string,
    endTimeBeforeStartTime: PropTypes.string,
    minShiftLength:         PropTypes.string,
    maxShiftLength:         PropTypes.string,
    overlappingShift:       PropTypes.string
  }).isRequired,
  saveShift:                     PropTypes.func.isRequired,
  CREATE_SHIFT_EVENT:            PropTypes.any.isRequired,
  UPDATE_SHIFT_EVENT:            PropTypes.any.isRequired,
  DELETE_SHIFT_EVENT:            PropTypes.any.isRequired,
  createRecommendedWorkingHours: PropTypes.func.isRequired,
  customBuffer:                  PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  refetchQuery:                  PropTypes.func.isRequired,
  providerId:                    PropTypes.number.isRequired
};

export default AvailabilitySettings;
