import React from 'react';
import PropTypes from 'prop-types';

const VideoIcon = ({ width = 11, height = 11, fill = '#565757', ...props }) => (
  <svg {...props} width={width} height={height} viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3698 1.43972C10.2552 1.43972 10.1406 1.47792 10.026 1.55431L7.94444 2.87202V1.74528C7.94444 1.24875 7.48611 0.828613 6.95139 0.828613H0.973958C0.439236 0.828613 0 1.24875 0 1.74528V7.26438C0 7.76091 0.439236 8.16195 0.973958 8.16195H6.95139C7.48611 8.16195 7.94444 7.76091 7.94444 7.26438V6.13764L10.026 7.45535C10.1406 7.53174 10.2552 7.55084 10.3698 7.55084C10.6944 7.55084 11 7.30257 11 6.95882V2.05084C11 1.68799 10.6944 1.43972 10.3698 1.43972ZM7.02778 3.42584V7.22618C7.00868 7.24528 6.98958 7.24528 6.95139 7.24528H0.973958C0.935764 7.24528 0.916667 7.24528 0.916667 7.22618V1.78347C0.916667 1.76438 0.935764 1.74528 0.973958 1.74528H6.95139C6.98958 1.74528 7.00868 1.76438 7.02778 1.78347V3.42584ZM10.0833 6.405L7.94444 5.0682V3.94146L10.0833 2.60465V6.405Z"
      fill={fill}
    />
  </svg>
);

VideoIcon.propTypes = {
  width:  PropTypes.number,
  height: PropTypes.number,
  fill:   PropTypes.string
};

export default VideoIcon;
