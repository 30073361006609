import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

const ShortcutLink = ({ element }) => {
  const handleShortcutClick = event => {
    event.preventDefault();

    //  updateTextFields
    $('#primary_medical_diagnosis_search_code').val(element.zcode);
    $('#primary_medical_diagnosis_search_name').val(element.nm);

    //  updateHiddenFields
    $('#interview_primary_medical_diagnosis_id').val(element.value);
    $('#interview_primary_medical_diagnosis_text').val(element.label);
    $('#interview_primary_medical_diagnosis_secondary_diagnosis_flag').val(element.secondary_diagnosis_flag);
    $('#interview_primary_medical_diagnosis_free_text_excuse_permitted').val(element.free_text_excuse_permitted);

    // checkExcuseNoteFreeText(
    if ($('[id$=medical_diagnosis_free_text_excuse_permitted').filter(':input[value=false]').length > 0) {
      $('#excuse_note .additional_notes').hide();
    } else {
      $('#excuse_note .additional_notes').show();
    }

    if (element.article) {
      $('#interview_secondary_article_id').val(element.article.article_id);
      $('#interview_secondary_article_title').val(element.article.title);
    }

    $('.validation_message_flex').addClass('validation_message_hidden');
  };

  return (
    <a className="clear diagnosis_shortcuts mds_row_1" href="#" onClick={handleShortcutClick}>
      {element.text}
    </a>
  );
};

ShortcutLink.propTypes = {
  element: PropTypes.object.isRequired
};

export default ShortcutLink;
