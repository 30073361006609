export const ADD_NEW_ITEM_TO_SECONDARY_DIAGNOSES_LIST = 'ADD_NEW_ITEM_TO_SECONDARY_DIAGNOSES_LIST';
export const REMOVE_ITEM_AT_INDEX_FROM_SECONDARY_DIAGNOSES_LIST = 'REMOVE_ITEM_AT_INDEX_FROM_SECONDARY_DIAGNOSES_LIST';
export const REPLACE_ITEM_AT_INDEX_IN_SECONDARY_DIAGNOSES_LIST = 'REPLACE_ITEM_AT_INDEX_IN_SECONDARY_DIAGNOSES_LIST';

export const addNewItemToSecondaryDiagnosesList = ({ newItem }) => ({
  type:    ADD_NEW_ITEM_TO_SECONDARY_DIAGNOSES_LIST,
  payload: { newItem }
});

export const removeItemAtIndexFromSecondaryDiagnosesList = ({ index }) => ({
  type:    REMOVE_ITEM_AT_INDEX_FROM_SECONDARY_DIAGNOSES_LIST,
  payload: { index }
});

export const replaceItemAtIndexInSecondaryDiagnosesList = ({ index, updatedItem }) => ({
  type:    REPLACE_ITEM_AT_INDEX_IN_SECONDARY_DIAGNOSES_LIST,
  payload: { index, updatedItem }
});
