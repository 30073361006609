import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import EmptyState from '../empty-state';
import ExternalAccount from '../external-account';
import ConnectExternalCalendarModal from '../connect-external-calendar-modal';
import AddAccountBar from './add-account-bar';
import ICloudExplanationModal from '../icloud-explanation-modal';
import { modalTypes } from '../../../../constants';
import styles from './styles.module.scss';
import { useAccountAuthUrl } from '../../hooks';
import { useExternalAccounts } from '../../contexts/external-accounts-context';

const ExternalAccounts = React.memo(() => {
  const [modalType, setModalType] = useState(modalTypes.none);
  const { externalAccounts, fetchExternalAccounts } = useExternalAccounts();

  const handleConnectionModalOpen = useCallback(() => {
    setModalType(modalTypes.connection)
  }, []);

  const accountAuthUrl = useAccountAuthUrl();

  const handleConnectionModalClose = useCallback(() => {
    setModalType(modalTypes.none);
  }, []);

  const handleAdditionalSetup = (e) => {
    e.preventDefault();

    setModalType(modalTypes.explanation);
  };

  const onConfirmExplanationModal = useCallback(() => {
    if (accountAuthUrl) {
      window.location.assign(`${accountAuthUrl}&provider=icloud`);
    }
  }, [accountAuthUrl]);

  const onCancelExplanationModal = useCallback(() => {
    setModalType(modalTypes.connection);
  }, []);

  const onCloseExplanationModal = useCallback(() => {
    setModalType(modalTypes.none);
  }, []);

  const handleRelogin = useCallback((account) => {
    window.location.assign(`${accountAuthUrl}&provider=${account.calendarType}`);
  }, [accountAuthUrl]);

  return (
    <React.Fragment>
      <div className={cx('calendarsWrapper', styles.calendarsWrapper)}>
        <div className="availabilityBlock">
          <div className="availabilityList">
            {
              externalAccounts.map(acc => (
                <ExternalAccount
                  account={acc}
                  key={acc.emailAddress}
                  onRelogin={handleRelogin}
                  onSettingsChanged={fetchExternalAccounts}
                />
              ))
            }
            {
              externalAccounts.length ? (
                <AddAccountBar accountsCount={externalAccounts.length} onClick={handleConnectionModalOpen} />
              ) : (
                <EmptyState onClick={handleConnectionModalOpen} />
              )
            }
          </div>
        </div>
      </div>

      {modalType == modalTypes.connection && (
        <ConnectExternalCalendarModal
          onModalClose={handleConnectionModalClose}
          handleAdditionalSetup={handleAdditionalSetup}
          accountAuthUrl={accountAuthUrl}
        />
      )}

      {modalType == modalTypes.explanation && (
        <ICloudExplanationModal
          onConfirm={onConfirmExplanationModal}
          onCancel={onCancelExplanationModal}
          onClose={onCloseExplanationModal}
        />
      )}
    </React.Fragment>
  );
});

ExternalAccounts.propTypes = {
  providerId: PropTypes.number.isRequired
};

export default ExternalAccounts;
