import React, { createContext, useContext } from 'react';
import useFetchScheduledConsultations from '../../hooks/useFetchScheduledConsultations';

const ScheduledVisitsTableContext = createContext();

export const useScheduledVistsTable = () => useContext(ScheduledVisitsTableContext);

export const ScheduledVistsTableProvider = ({ children }) => {
  const consultationsData = useFetchScheduledConsultations();

  return (
    <ScheduledVisitsTableContext.Provider value={consultationsData}>{children}</ScheduledVisitsTableContext.Provider>
  );
};
