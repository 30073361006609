import {
  CHANGE_CONSULT_NAVIGATION_STEP,
  MARK_CONSULT_NAVIGATION_STEP,
  ADD_ERRORS_TO_CONSULT_NAVIGATION_STEP,
  CLEAR_ERRORS_FROM_CONSULT_NAVIGATION_STEP,
  ADD_WARNINGS_TO_CONSULT_NAVIGATION_STEP,
  CLEAR_WARNING_FROM_CONSULT_NAVIGATION_STEP,
  UPDATE_CONSULT_NAVIGATION_ITEMS,
  VALIDATE_CONSULT_NAVIGATION_STEP,
  CHANGE_CONSULT_NAVIGATION_STEP_SUCCESS,
  CONSULT_NAVIGATION_NEXT_STEP,
  CHANGE_CONSULT_NAVIGATION_STEP_FAIL
} from './action-types';

export const changeConsultNavigationStep = selectedIndex => ({
  type: CHANGE_CONSULT_NAVIGATION_STEP,
  selectedIndex
});

export const markConsultNavigationStep = (selectedIndex, valid = false) => ({
  type: MARK_CONSULT_NAVIGATION_STEP,
  selectedIndex,
  valid
});

export const addErrorsToConsultNavigationStep = (selectedIndex, errors) => ({
  type: ADD_ERRORS_TO_CONSULT_NAVIGATION_STEP,
  selectedIndex,
  errors
});

export const clearErrorsFromConsultNavigationStep = selectedIndex => ({
  type: CLEAR_ERRORS_FROM_CONSULT_NAVIGATION_STEP,
  selectedIndex
});

export const addWarningsToConsultNavigationStep = (selectedIndex, warning) => ({
  type: ADD_WARNINGS_TO_CONSULT_NAVIGATION_STEP,
  selectedIndex,
  warning
});

export const clearWarningFromConsultNavigationStep = (selectedIndex, warningKey) => ({
  type: CLEAR_WARNING_FROM_CONSULT_NAVIGATION_STEP,
  selectedIndex,
  warningKey
});

export const updateConsultNavigationItems = items => ({
  type: UPDATE_CONSULT_NAVIGATION_ITEMS,
  items
});

export const validateConsultNavigationStep = (selectedIndex, goNextStep) => ({
  type: VALIDATE_CONSULT_NAVIGATION_STEP,
  selectedIndex,
  goNextStep
});

export const changeConsultNavigationStepSuccess = selectedIndex => ({
  type: CHANGE_CONSULT_NAVIGATION_STEP_SUCCESS,
  selectedIndex
});

export const changeConsultNavigationStepFail = () => ({
  type: CHANGE_CONSULT_NAVIGATION_STEP_FAIL
});

export const consultNavigationNextStep = () => ({
  type: CONSULT_NAVIGATION_NEXT_STEP
});
