import { createSelector } from '@td/utils';

import getSymptomsById from './getSymptomsById';
import getAddedSymptomsFromSearchIds from './getAddedSymptomsFromSearchIds';

export default createSelector(
  getSymptomsById,
  getAddedSymptomsFromSearchIds,
  (symptomsById, addedSymptomIds) =>
    addedSymptomIds.map(symptomCode => symptomsById[symptomCode])
);
