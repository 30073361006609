import { createRequestActions } from '@td/utils';

import { FETCH_PATIENT_SYNC_ERRORS, ERX_ERRORS_INIT } from '../action-types';

export const fetchPatientSyncErrors = createRequestActions(FETCH_PATIENT_SYNC_ERRORS);

export const erxErrorsInit = erxErrors => ({
  type:    ERX_ERRORS_INIT,
  payload: {
    erxErrors
  }
});
