import {
  ADD_SYMPTOM_SEARCH_SUCCESS,
  TOGGLE_ACCORDION,
  ACCORDION_TOGGLE_END,
  CLEAR_COMPLAINT,
  SELECT_COMPLAINT
} from '../action-types';
import { addToSetImmutable } from './utilities';

export const DEFAULT_STATE = {
  expandedAccordions: new Set(),
  expandFinished:     new Set(['Associated'])
};

function onAddSymptomFromSearch(state) {
  return {
    ...state,
    expandedAccordions: addToSetImmutable(state.expandedAccordions, 'Added symptoms')
  };
}

function onToggleAccordion(state, action) {
  const symptomClass = action.payload.symptomClass;

  const newExpandedAccordions = new Set(state.expandedAccordions);

  if (newExpandedAccordions.has(symptomClass)) {
    newExpandedAccordions.delete(symptomClass);
  } else {
    newExpandedAccordions.add(symptomClass);
  }

  return {
    ...state,
    expandedAccordions: newExpandedAccordions
  };
}

function onAccordionToggleEnd(state, action) {
  const symptomClass = action.payload.symptomClass;

  const newExpandFinished = new Set(state.expandFinished);

  if (state.expandedAccordions.has(symptomClass)) {
    newExpandFinished.add(symptomClass);
  } else {
    newExpandFinished.delete(symptomClass);
  }

  return {
    ...state,
    expandFinished: newExpandFinished
  };
}

function onSelectComplaint(state, action) {
  const chiefComplaintClass = action.payload.complaint.symptomClass;

  if (chiefComplaintClass && !state.expandedAccordions.has(chiefComplaintClass)) {
    const newExpandedAccordions = new Set(state.expandedAccordions);
    const newExpandFinished = new Set(state.expandFinished);

    newExpandedAccordions.add(chiefComplaintClass);
    newExpandFinished.add(chiefComplaintClass);

    return {
      ...state,
      expandedAccordions: newExpandedAccordions,
      expandFinished:     newExpandFinished
    };
  }

  return state;
}

function onComplaintClear() {
  return DEFAULT_STATE;
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_SYMPTOM_SEARCH_SUCCESS:
      return onAddSymptomFromSearch(state, action);
    case TOGGLE_ACCORDION:
      return onToggleAccordion(state, action);
    case ACCORDION_TOGGLE_END:
      return onAccordionToggleEnd(state, action);
    case SELECT_COMPLAINT: {
      return onSelectComplaint(state, action);
    }
    case CLEAR_COMPLAINT:
      return onComplaintClear(state, action);
    default:
      return state;
  }
};
