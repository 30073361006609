import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { changeConsultNavigationStep } from '../../consult-navigation/actions';
import PatientInfo from '../interfaces/patient-info-interface';
import NutritionPlan from './nutrition-plan';
import { mapInlineErrors } from 'app/consultation-interview/utils';

class NutritionPlanContainer extends Component {
  getNutritionPlanSections() {
    return Object.values(this.props.nutritionPlan.survey.SURVEYMNEMONIC_NUTPLAN.sections).sort(this.sortBySequence);
  }

  sortBySequence(prevElement, nextElement) {
    return prevElement.sequence - nextElement.sequence;
  }

  render() {
    const nutritionPlanSections = this.getNutritionPlanSections();
    return (
      <NutritionPlan
        nutritionPlanSections={nutritionPlanSections}
        patientInfo={this.props.patientInfo}
        isPlanSelected={this.props.isPlanSelected}
        validationErrors={this.props.validationErrors}
      />
    );
  }
}

const mapStateToProps = state => ({
  isPlanSelected:   state.consultNavigation.selectedIndex === 5,
  validationErrors: mapInlineErrors(state)
});

const mapDispatchToProps = {
  changeConsultNavigationStep
};

NutritionPlanContainer.propTypes = {
  nutritionPlan:    PropTypes.object.isRequired,
  isPlanSelected:   PropTypes.bool.isRequired,
  patientInfo:      PatientInfo,
  validationErrors: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(NutritionPlanContainer);
