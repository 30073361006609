import React from 'react';

export default () => (
  <div>
    <h5 className="network-test-content network-test-header">Performance Test Could Not Run</h5>
    <div className="network-test-content">
      A server error occurred when attempting to run your video quality test. Because of this{' '}
      <span className="network-test-strong">you have been clocked in for phone and video.</span>{' '}
      Please keep in mind that there may be issues with conducting video consultations.
    </div>
  </div>
);
