import React from 'react';
import PropTypes from 'prop-types';
import { FaExclamationTriangle } from 'react-icons/fa';
import { I18n, translate } from '@td/shared_utils';

const JoinViaPhone = ({ primaryPhone, secondaryPhone }) => (
  <div>
    <h1 className="auto-dialer-title-text text-center">
      <I18n scope="autodialer.call_interface.fallbacks" text="system_error" />
    </h1>
    <div className="text-center">
      <FaExclamationTriangle className="icon" color="#C04844" size={24} />
      <div className="join-error-text">
        <I18n scope="autodialer.call_interface.fallbacks" text="auto_dialer_unavailable" />
      </div>
    </div>
    {primaryPhone || secondaryPhone ? (
      <React.Fragment>
        {primaryPhone && (
          <div className="call-phone-container">
            <div>
              {translate(null, 'autodialer.call_interface.fallbacks', 'primary')}: {primaryPhone}
            </div>
          </div>
        )}
        {secondaryPhone && (
          <div className="call-phone-container">
            <div>
              {translate(null, 'autodialer.call_interface.fallbacks', 'secondary')}: {secondaryPhone}
            </div>
          </div>
        )}
      </React.Fragment>
    ) : (
      <div className="call-phone-container">
        <I18n scope="autodialer.call_interface.fallbacks" text="no_phone_numbers" />
      </div>
    )}
    <p>
      <I18n scope="autodialer.call_interface.fallbacks" text="star_67_reminder" />
    </p>
  </div>
);

JoinViaPhone.propTypes = {
  primaryPhone:   PropTypes.string,
  secondaryPhone: PropTypes.string
};

export default JoinViaPhone;
