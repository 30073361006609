import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';

const render = (ComponentClass, renderTarget, props) => {
  if ($(renderTarget).length !== 0) {
    ReactDOM.render(
      <Provider store={store}>
        <ComponentClass {...props} />
      </Provider>,
      window.document.querySelector(renderTarget)
    );
    $(renderTarget).addClass('react');
  }
};

export default render;
