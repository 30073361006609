import React from 'react';
import PropTypes from 'prop-types';

const NetworkTestErrorActions = ({ allowRerun, onRerun, onComplete }) => (
  <div className="network-test-actions">
    {allowRerun && (
      <input
        type="button"
        id="rerun-network-test-button"
        value="Run Video Test Again"
        onClick={onRerun}
      />
    )}
    <input type="button" id="complete-network-test-button" value="Ok" onClick={onComplete} />
  </div>
);

NetworkTestErrorActions.propTypes = {
  allowRerun: PropTypes.bool.isRequired,
  onRerun: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default NetworkTestErrorActions;
