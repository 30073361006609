import { useEffect, useState } from 'react';
import _ from 'lodash';

import { authToken } from '@td/api';
import { useUpdater } from '@td/shared_utils';

export const useActiveConferenceStatus = ({ consultationId }) => {
  const [shouldDisplayAutoDialer, setShouldDisplayAutoDialer] = useState(false);

  // RS stands for 'request state'
  const activeConferenceRS = useUpdater('post', {
    url:     '/v4/autodialer/has-active-call',
    method:  'post',
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `${authToken.get()}` },
    params:  { consultation_id: consultationId }
  });

  useEffect(() => {
    activeConferenceRS.callApi();
  }, [consultationId]);

  useEffect(() => {
    // Decides to show or hide 'patient_contact_information' and 'auto_dialer_consult_ct' sections.
    const confPresence = _.get(activeConferenceRS, 'data.data.hasConfiguredConference');
    if (confPresence) {
      ['patient_contact_information', 'patient_not_reached'].forEach(containerId => {
        const container = document.getElementById(containerId);
        if (container) container.remove();
      });

      setShouldDisplayAutoDialer(true);
    } else {
      setShouldDisplayAutoDialer(false);
    }
  }, [activeConferenceRS.data]);

  return {
    activeConferenceRS,
    shouldDisplayAutoDialer,
    setShouldDisplayAutoDialer,
    activeConferenceRS
  };
};
