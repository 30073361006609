import toastr from 'toastr';

toastr.options = {
  preventDuplicates: true,
  timeOut: 1500,
  extendedTimeOut: 1500,
  closeButton: true,
  positionClass: 'toast-top-right'
};

let currentNotification;

export default incomingNotification => {
  const previousNotification = currentNotification;
  currentNotification = incomingNotification;

  if (currentNotification && previousNotification !== currentNotification) {
    const { message, type, options = {} } = currentNotification;
    const notify = toastr[type] || toastr.info;

    notify(message, null, options);
  }
};
