import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@td/shared_utils';

// Components and constants
import Calendar from '../../calendar';
import CalendarLegend from './calendar-legend';
import { screenTypes } from '../../external-calendar-account-alerts/constants';
import ExternalCalendarAccountAlerts from '../../external-calendar-account-alerts';

// Styles
import '../styles/my-schedule-content.scss';
import '../styles/datepicker-custom-styles.scss';

// Icons
const plusIcon = require('../../assets/images/plus_icon.svg');
const manageIcon = require('../../assets/images/manage_icon.svg');

const TRANSLATION_SCOPE = 'my_schedule.main';

const MyScheduleContent = ({
  apolloClient,
  scheduleTypes,
  onAddEditEvent,
  onOpenSettings,
  providerShifts,
  setNotification,
  createRecommendedWorkingHours,
  canEditSchedule,
  loadSchedules,
  calendarIncrement,
  refetchQuery
}) => (
  <div className="myScheduleContentWrapper">
    <div className="subheading">
      <p className="subtitle">{translate(null, TRANSLATION_SCOPE, 'subtitle')}</p>
      {canEditSchedule && (<div className="actionButtons">
        <button className="addEventBtn" onClick={() => onAddEditEvent()}>
          <span className="icon">
            <img className="plusAction" key="plusIcon" src={plusIcon} role="presentation" />
          </span>
          <span className="label">{translate(null, TRANSLATION_SCOPE, 'actions.personal_event_btn')}</span>
        </button>
        <button className="manageWorkingHoursBtn" onClick={() => onOpenSettings()}>
          <span className="icon">
            <img className="manageAction" key="manageIcon" src={manageIcon} role="presentation" />
          </span>
          <span className="label">{translate(null, TRANSLATION_SCOPE, 'actions.settings_btn')}</span>
        </button>
      </div>
      )}
    </div>

    <ExternalCalendarAccountAlerts
      screenType={screenTypes.MY_SCHEDULE}
      onOpenWorkingHours={onOpenSettings}
    />

    <Calendar
      apolloClient={apolloClient}
      scheduleTypes={scheduleTypes}
      onEditEvent={onAddEditEvent}
      providerShifts={providerShifts}
      setNotification={setNotification}
      createRecommendedWorkingHours={createRecommendedWorkingHours}
      canEditSchedule={canEditSchedule}
      loadSchedules={loadSchedules}
      calendarIncrement={calendarIncrement}
      refetchQuery={refetchQuery}
    />
    <div className="calendarFooter">
      <CalendarLegend
        scheduleTypes={scheduleTypes}
      />
    </div>
  </div>
);

MyScheduleContent.propTypes = {
  apolloClient:                  PropTypes.object.isRequired,
  scheduleTypes:                 PropTypes.object.isRequired,
  onAddEditEvent:                PropTypes.func.isRequired,
  onOpenSettings:                PropTypes.func.isRequired,
  providerShifts:                PropTypes.object.isRequired,
  setNotification:               PropTypes.func.isRequired,
  createRecommendedWorkingHours: PropTypes.func.isRequired,
  canEditSchedule:               PropTypes.bool,
  loadSchedules:                 PropTypes.func.isRequired,
  calendarIncrement:             PropTypes.number,
  refetchQuery:                  PropTypes.func.isRequired
};

export default MyScheduleContent;
