import {
  SYMPTOMS_FORM_NOTE_CHANGE,
  SYMPTOMS_FORM_RADIO_CHANGE,
  ADD_SYMPTOM_SEARCH_SUCCESS,
  CLEAR_COMPLAINT,
  SYMPTOMS_FORM_INITIALIZE
} from '../action-types';

import { addImmutable, removeImmutable } from './utilities';

export const DEFAULT_STATE = {
  radioValues: {},
  noteValues:  {}
};

function onRadioValueChange(state, action) {
  const { symptomCode, radioValue } = action.payload;

  const newState = {
    ...state
  };

  if (radioValue === 'n/a') {
    newState.radioValues = removeImmutable(newState.radioValues, symptomCode);
    newState.noteValues = removeImmutable(newState.noteValues, symptomCode);
  } else {
    newState.radioValues = addImmutable(newState.radioValues, symptomCode, radioValue);
    newState.noteValues = addImmutable(
      newState.noteValues,
      symptomCode,
      newState.noteValues[symptomCode] || ''
    );
  }

  return newState;
}

function onNoteValueChange(state, action) {
  const { symptomCode, noteValue } = action.payload;

  return {
    ...state,
    noteValues: {
      ...state.noteValues,
      [symptomCode]: noteValue
    }
  };
}

function onAddNewSymptom(state, action) {
  const symptomCode = action.payload.symptomCode;

  return {
    ...state,
    radioValues: {
      ...state.radioValues,
      [symptomCode]: 'yes'
    },
    noteValues: {
      ...state.noteValues,
      [symptomCode]: state.noteValues[symptomCode] || ''
    }
  };
}

function onComplaintClear() {
  return DEFAULT_STATE;
}

function onSymptomsFormInitialize(state, action) {
  return action.payload.state;
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SYMPTOMS_FORM_RADIO_CHANGE:
      return onRadioValueChange(state, action);
    case SYMPTOMS_FORM_NOTE_CHANGE:
      return onNoteValueChange(state, action);
    case ADD_SYMPTOM_SEARCH_SUCCESS:
      return onAddNewSymptom(state, action);
    case CLEAR_COMPLAINT:
      return onComplaintClear(state, action);
    case SYMPTOMS_FORM_INITIALIZE:
      return onSymptomsFormInitialize(state, action);
    default:
      return state;
  }
};
