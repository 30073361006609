import { createStore, applyMiddleware, compose } from 'redux';
import ReduxPromise from 'redux-promise';
import { createEpicMiddleware } from 'redux-observable';

/* eslint-disable import/no-extraneous-dependencies */
import logger from 'redux-logger';
/* eslint-enable */

import reducers from '../reducers';
import epics from '../epics';

const epicMiddleware = createEpicMiddleware();
const createMiddlewareStore = applyMiddleware(ReduxPromise)(createStore);

let store;

if (process.env.NODE_ENV === 'production') {
  store = createMiddlewareStore(reducers, applyMiddleware(ReduxPromise, epicMiddleware));
} else {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const haveLog = window.console && console.log;
  const middleware = [ReduxPromise, epicMiddleware];

  console.log('Non-production mode: enabling reduxTools');
  if (haveLog && process.env.REDUX_LOGGING === 'true') {
    middleware.push(logger);
    console.log('Redux logging is on');
  }

  store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));
}

epicMiddleware.run(epics);

export default store;
