import React from 'react';

import CommunicationMethodIcon from '../CommunicationMethodIcon';
import MemberAvailability from '../MemberAvailability';
import RequestedAndProposedVisitsTableStatus from '../RequestedAndProposedVisitsTable/RequestedAndProposedVisitsTableStatus';
import ScheduledVisitsTableActions from '../ScheduledVisitsTable/ScheduledVisitsTableActions';
import RequestedAndProposedVisitsTableActions from '../RequestedAndProposedVisitsTable/RequestedAndProposedVisitsTableActions';
import RequiredActionsTableActions from '../RequiredActionsTable/RequiredActionsTableActions';
import PatientInformation from '../PatientInformation';

import { formatTime } from '../utils';
import MatchesForWaitlistTableActions from '../MatchesForWaitlistTable/MatchesForWaitlistTableActions';

export const communicationMethodSelector = ({ communicationMethod }) => (
  <CommunicationMethodIcon code={communicationMethod} />
);

export const fullNameSelectorForRequiredActions = ({ memberFirstName, memberLastName }) => `${memberFirstName} ${memberLastName}`;

export const patientInformationSelector = props => <PatientInformation {...props} />;

export const fullNameSelector = ({ memberFullName }) => memberFullName;

export const requestReasonSelector = ({ consultRequestReason }) => consultRequestReason;

export const consultationIdSelector = ({ consultationId }) => consultationId;

export const timeRemainingSelector = ({ timeRemaining }) => timeRemaining;

export const requestDateSelector = ({ requestDate }) => formatTime(requestDate);

export const memberAvailabilitySelector = ({ memberAvailability, requestedDates }) => (
  <MemberAvailability data={memberAvailability} requestedDates={requestedDates} />
);

export const requestedStatusSelector = ({ consultStatus }) => (
  <RequestedAndProposedVisitsTableStatus status={consultStatus} />
);

export const scheduledVisitsTableActionsSelector = props => <ScheduledVisitsTableActions {...props} />;

export const requestAndPropsedTableActionsSelector = props => <RequestedAndProposedVisitsTableActions {...props} />;

export const recievedDateSelector = ({ receivedDate }) => formatTime(receivedDate);

export const consultationTypeSelector = ({ consultationSpecialtyName }) => consultationSpecialtyName;

export const requiredActionsTableActionsSelector = ({ consultationId, action }) => (
  <RequiredActionsTableActions consultationId={consultationId} action={action} />
);

export const daysWaitingSelector = ({ daysWaiting }) => daysWaiting;

export const matchesForWaitlistTableActionsSelector = props => <MatchesForWaitlistTableActions {...props} />;
