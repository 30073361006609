function redirect(url) {
  window.location.href = url;
}

function open(url) {
  window.open(url);
}

function popup(url, nameSpace, width = '1200', height = '800') {
  if (nameSpace) {
    return (window[nameSpace] = window.open(url, 'popup', `width=${width},height=${height}`));
  }

  window.open(url, 'popup', `width=${width},height=${height}`);
  return false;
}

const replaceLocation = url => window.location.replace(url);

const TdWindow = {
  redirect,
  open,
  popup,
  replaceLocation
};

export default TdWindow;