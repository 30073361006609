import React from 'react';
import { ScheduledVistsTableProvider } from '../ScheduledVisitsTable/ScheduledVistsTableProvider';
import { RequestedAndProposedVisitsTableProvider } from '../RequestedAndProposedVisitsTable/RequestedAndProposedVisitsTableProvider';
import { MatchesForWaitlistTableProvider } from '../MatchesForWaitlistTable/MatchesForWaitlistTableProvider';
import { useData } from '../../../DataProvider';

const ConsultQueuesProvider = ({ children }) => {
  const { displayProviderMemberWaitlist } = useData();

  return (
    <ScheduledVistsTableProvider>
      <RequestedAndProposedVisitsTableProvider>
        {displayProviderMemberWaitlist ? (
          <MatchesForWaitlistTableProvider>{children}</MatchesForWaitlistTableProvider>
        ) : (
          children
        )}
      </RequestedAndProposedVisitsTableProvider>
    </ScheduledVistsTableProvider>
  );
};

export default ConsultQueuesProvider;
