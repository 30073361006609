import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { authToken } from '@td/api';
import { FileUploads, FileUploadsNetworkErrorModal } from '@td/file_uploads';
import { useUpdater, useFetcher, translate } from '@td/shared_utils';
import { last } from 'lodash';

export const EhrFileUploadsContainer = props => {
  const fileUploadRequestState = useUpdater('post', {
    url:     `/members/${props.memberId}/attachments/upload`,
    method:  'post',
    baseURL: window.location.origin,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `${authToken.get()}` },
    timeout: 5 * 60 * 1000 // timeout to abort connection (in ms, eg 5 minutes)
  });
  const fileTypeOptionsRequestState = useFetcher('/member_attachments/new');
  const [modalIsClosed, setClosed] = useState(true);
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    const uploadResponse = fileUploadRequestState.data;
    if (fileUploadRequestState.finished && uploadResponse && uploadResponse.success && modalIsClosed) {
      if (props.reloadData) {
        props.reloadData();
      } else {
        // TODO delete this after feature toggle new_ehr_images_documents is permanently enabled
        // This triggers some jquery events which refresh the images in Provider and Admin EHRs
        // The preferable approach would be having a preview table in react, but until
        // then we have this. A blueprint on how this can be implemented in react can be
        // found in rav-file-uploads-container.js where temporary images are rendered in the
        // RAV
        document.querySelector('.refresh-images').click();
      }
    }
    if (fileUploadRequestState.finished && ((uploadResponse && uploadResponse.error) || fileUploadRequestState.error)) {
      setClosed(true);
      setNetworkError(true);
      fileUploadRequestState.reset();
    }
  });

  return (
    <div className="file-uploads">
      <FileUploadsNetworkErrorModal isNetworkError={networkError} setNetworkError={setNetworkError} />
      <fieldset id="file_upload" style={{background: "transparent", padding: 0, margin: "10px 0"}}>
        <FileUploads
          fileUploadRequestState={fileUploadRequestState}
          fileTypeOptionsRequestState={fileTypeOptionsRequestState}
          uploadsFormData={{
            attach_to:    'Consultation',
            related_type: props.consultationId ? 'Consultation' : 'Member',
            related_id:   props.consultationId ? props.consultationId : props.memberId,
          }}
          cssModalKey="provider"
          modalIsClosed={modalIsClosed}
          setClosed={setClosed}
          customFields={[
            {
              type:       'prompt',
              identifier: 'person-name-prompt',
              title:      translate(null, 'uploads.general.modal.member_name_prompt', 'title', {
                name: `<i>${props.memberName}</i>`
              }),
              missing:     translate(null, 'uploads.general.modal.member_name_prompt', 'warning'),
              choices:     [{ label: null, val: props.memberName }],
              matchValues: [props.memberName, last(props.memberName.split(' '))]
            }
          ]}
        />
      </fieldset>
    </div>
  );
};
FileUploads.propTypes = {
  memberId:       PropTypes.string,
  memberName:     PropTypes.string,
  consultationId: PropTypes.string,
  reloadData:     PropTypes.func
};
