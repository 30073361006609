import React from 'react';

const InputWithIcon = props => {
  const { field, left, right } = props;

  return (
    <div className="input-container">
      <input {...field} {...props} />
      {left && <span className="left-icon">{left}</span>}
      {right && <span className="right-icon">{right}</span>}
    </div>
  );
};

export default InputWithIcon;
