import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NutPlanCheckbox from './nut-plan-checkbox-control';
import './shopping-guide.scss';

export default class ShoppingGuide extends Component {
  constructor(props) {
    super(props);
    this.controlNamePrefix =
      'interview[provider_intake_surveys][SURVEYMNEMONIC_NUTPLAN][sections][SECTIONMNEMONIC_NUTPLAN_SHOPGUIDE]';
    this.getControls = this.getControls.bind(this);
    this.controls = this.getControls();
  }

  getControls() {
    return Object.entries(this.props.section.questions)
      .filter(question => question[1].question_type === 'checkbox')
      .map(question => (
        <NutPlanCheckbox
          key={question[1].question}
          control={question[1]}
          questionIdentifier={question[0]}
          controlNamePrefix={`${this.controlNamePrefix}[questions]`}
          classNameIdentifier="shopping-guide"
        />));
  }

  render() {
    return (
      <div className="shopping-guide-wrapper">
        <div className="shopping-guide-notice">
          <span>{this.props.section.notice}</span>
        </div>
        <div className="shopping-guide-controls">
          <div className="left-panel">
            {this.controls}
            <div className="other-input">
              <label className="control-label" htmlFor={`${this.controlNamePrefix}[questions][Q_4_8][response]`}>
                {this.props.section.questions.Q_4_8.question}
              </label>
              <input
                maxLength="45"
                className="input-control"
                name={`${this.controlNamePrefix}[questions][Q_4_8][response]`}
                defaultValue={this.props.section.questions.Q_4_8.response}
              />
            </div>
          </div>
          <div className="right-panel">
            <div className="control-wrapper">
              <div className="textarea-label">
                <label htmlFor={`${this.controlNamePrefix}[questions][Q_4_9][response]`}>
                  {this.props.section.questions.Q_4_9.question}
                </label>
              </div>
              <div className="textarea-field">
                <textarea
                  maxLength="350"
                  className="textarea-control height-100 initial-margin"
                  name={`${this.controlNamePrefix}[questions][Q_4_9][response]`}
                  defaultValue={this.props.section.questions.Q_4_9.response}
                />
              </div>
            </div>
            <div className="control-wrapper">
              <div className="textarea-label">
                <label htmlFor={`${this.controlNamePrefix}[questions][Q_4_10][response]`}>
                  {this.props.section.questions.Q_4_10.question}
                </label>
              </div>
              <div className="textarea-field">
                <textarea
                  maxLength="350"
                  className="textarea-control height-100 initial-margin"
                  name={`${this.controlNamePrefix}[questions][Q_4_10][response]`}
                  defaultValue={this.props.section.questions.Q_4_10.response}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="reviewed-checkbox">
          <input type="hidden" name={`${this.controlNamePrefix}[reviewed]`} value="false" />
          <input
            id="shopping-guide-reviewed"
            type="checkbox"
            name={`${this.controlNamePrefix}[reviewed]`}
            value="true"
            onChange={this.props.toggleSection}
            checked={this.props.section.reviewed}
          />
          <label
            htmlFor="shopping-guide-reviewed"
          >
            {`Reviewed ${this.props.section.title}`}
          </label>
        </div>
      </div>
    );
  }
}

ShoppingGuide.propTypes = {
  section:       PropTypes.object.isRequired,
  toggleSection: PropTypes.func.isRequired
};
