import { translate } from '@td/shared_utils';
import { chain, compact, get, includes, isEmpty, join, keyBy, map, transform } from 'lodash';
import { sectionCodes, TRANSLATION_SCOPE } from '../constants';

export const surveyToState = (survey, cachedSurvey) => (
  chain(survey)
    .get('sections')
    .filter(({ sectionCode }) => includes(sectionCodes, sectionCode))
    .transform((sections, { sectionCode, questions }) => {
      sections[sectionCode] = transform(questions, (result, { questionMnemonicCd, answers, ...question }) => {
        result[questionMnemonicCd] = {
          ...question,
          questionMnemonicCd,
          answers: keyBy(answers, 'answerMnemonicCd'),
          value: '',
          note: '',

          ...get(cachedSurvey, [sectionCode, questionMnemonicCd], {})
        };
      }, {});
    }, {})
    .value()
);

export const sectionToNote = (answers, sectionCode) => {
  if (isEmpty(answers)) {
    return;
  }

  return join([
    translate(null, `${TRANSLATION_SCOPE}.sections.${sectionCode}.system_generated_notes`, 'title'),
    join(map(answers, answerToNote), "\n")
  ], "\n");
};

export const answerToNote = ({ title, value, note, answers }) => (
  join(
    compact([
      `${title}:`,
      answers[value]['title'],
      note && `(${note})`
    ]),
    " "
  )
);
