import multiWayConsultAttendeesVerification from './couples-therapy-attendee';

export default () => {
  const { patientCertified } = window.consultationInterview.validation;

  // To accommodate legacy coffeescript validation
  const that = window.consultationInterview.validation;

  return patientCertified.apply(that).then(validations => validations.concat(multiWayConsultAttendeesVerification()));
};
