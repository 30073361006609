import getMinDatetime from './get_min_datetime';
import { businessOpenTimeHour, businessCloseTimeHour} from './business_hours';

export default (availableTimes, date) => {
  let startDate = date ? date : new Date();
  let minDate = getMinDatetime(availableTimes);

  let startDateNotFarEnoughInFuture = false;

  if (startDate) {
    let startDateInPast    = startDate.getTime() < (new Date()).getTime();
    let startDateBeforeMin = startDate < minDate;
    startDateNotFarEnoughInFuture |= startDate.getHours() < businessOpenTimeHour(availableTimes);
    startDateNotFarEnoughInFuture |= startDate.getHours() > businessCloseTimeHour(availableTimes);
    startDateNotFarEnoughInFuture |= startDateInPast || startDateBeforeMin;
  }

  if (!date || !startDate || startDateNotFarEnoughInFuture) {
    startDate = minDate;
  }
  return startDate;
}
