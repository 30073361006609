import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../styles.scss';

class Scratchpad extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scratchpadText: props.scratchpadText
    };
  }

  handleChange = event => {
    const text = event.target.value;

    this.setState({
      scratchpadText: text
    });

    this.props.updateScratchpadFormField(text);
    this.props.onTextChange();
  };

  render() {
    return (
      <div id="scratchpad">
        <h1>Scratchpad</h1>
        <div>Scratchpad text will not be saved or shared with patient.</div>
        <textarea
          value={this.state.scratchpadText}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

Scratchpad.propTypes = {
  scratchpadText:            PropTypes.string,
  onTextChange:              PropTypes.func,
  updateScratchpadFormField: PropTypes.func
};

export default Scratchpad;
