import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import GettingStartedBanner from '../getting-started-banner';
import PatientSummaryCard from './index';
import { patientSummaryCard } from '../../constants';


const SummaryCardsContainer = ({summaryData}) => {
  
  const renderComponent = () => {
    const container = document.querySelector('#summaryCardsContainer');
    if (container) {
      const Component = summaryData.isAnyConsultCompleted ? (
        <React.Fragment>
          <div className="summaryCardsContainer">
              <PatientSummaryCard
                  title={patientSummaryCard.title_todays_patients}
                  summaryData={summaryData}
                  captionOne={patientSummaryCard.caption_new}
                  captionTwo={patientSummaryCard.caption_established}
              />
              <PatientSummaryCard
                  title={patientSummaryCard.title_active_patients}
                  summaryData={summaryData}
                  captionOne={patientSummaryCard.caption_current}
                  captionTwo={patientSummaryCard.caption_limit}
                  shouldChangePatientCountColor={true}
              />
          </div>
        </React.Fragment>
    ) : (
        <GettingStartedBanner/>
    );
    ReactDOM.render(Component, container);
    }
  }

  useEffect(() => {
    if(summaryData) renderComponent();
  }, [summaryData]);

  return (<React.Fragment></React.Fragment>);
};

SummaryCardsContainer.propTypes = {
  summaryData: PropTypes.shape({
    todayNewPatients:            PropTypes.number,
    todayEstablishedPatients:    PropTypes.number,
    currentPatients:             PropTypes.number,
    isAnyConsultCompleted:       PropTypes.bool
  })
};

export default SummaryCardsContainer;
