import React, { useState, useCallback, useLayoutEffect, useEffect } from 'react';
import { useCallStatus } from '../../CallStatusProvider';
import Timer from './Timer';
import { I18n } from '@td/shared_utils';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { communicationMethods, specialtyNames } from '../../../constants';

const CallStatus = ({ containerRef, consultation }) => {
  const { status, startedAt } = useCallStatus();
  const { communicationMethod, specialtyName } = consultation;
  const inProgress = status === 'IN_PROGRESS';

  const [isSticky, setIsSticky] = useState(false);
  const [leftOffset, setLeftOffset] = useState(0);
  const [width, setWidth] = useState(0);

  const handleScreenConditionsChange = useCallback(() => {
    if (inProgress && containerRef && containerRef.current) {
      const containerBounds = containerRef.current.getBoundingClientRect();
      const isSticky = containerBounds.top <= 0;

      setIsSticky(isSticky);
      setLeftOffset(containerBounds.left);
      setWidth(containerBounds.width);
    }
  }, [containerRef.current, inProgress, setIsSticky, setLeftOffset, setWidth]);

  useLayoutEffect(() => {
    handleScreenConditionsChange();

    window.addEventListener('scroll', handleScreenConditionsChange);
    window.addEventListener('resize', handleScreenConditionsChange);

    return () => {
      window.removeEventListener('scroll', handleScreenConditionsChange);
      window.removeEventListener('resize', handleScreenConditionsChange);
    };
  }, [handleScreenConditionsChange]);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.style.paddingTop = `${isSticky ? 40 : 0}px`;
    }
  }, [containerRef.current, isSticky]);

  return (
    <div
      className={classNames(
        communicationMethod === communicationMethods.VIDEO ? styles.callStatusVideoWrapper : styles.callStatusWrapper,
        {
          [styles.callStatusFixed]:      isSticky,
          [styles.callStatusInProgress]: inProgress
        }
      )}
      style={
        isSticky
          ? {
            left: leftOffset,
            width
          }
          : {}
      }
    >
      <div className={styles.callStatus}>
        <div data-testid="call-details-call-status">
          <I18n scope="call_details.call_status" text={inProgress ? 'in_progress' : 'not_started'} />
        </div>

        {inProgress &&
          ((communicationMethod === communicationMethods.PHONE && specialtyName !== specialtyNames.MENTAL_HEALTH) ||
            communicationMethod === communicationMethods.VIDEO) && (
            <div>
              <Timer startedAt={startedAt} />
            </div>
          )}
      </div>
    </div>
  );
};

export default CallStatus;
