import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { DEFAULT_ALERTS_POLL_INTERVAL, WORKING_HOURS_URL } from './constants';
import { screenTypes } from '../external-calendar-account-alerts/constants';
import { useProviderEligibility } from '../my-schedule/features/external-calendar/contexts/provider-eligibility-context';
import ExternalCalendarAccountAlerts from '../external-calendar-account-alerts';
import { useExternalCalendarAccountAlerts } from '../external-calendar-account-alerts/contexts/external-calendar-account-alerts-context';

const DashboardExternalCalendarAccountAlerts = React.memo(() => {
  const { isProviderEligibleForExternalCalendars } = useProviderEligibility();
  const { fetchExternalCalendarAccountAlerts } = useExternalCalendarAccountAlerts();

  useEffect(() => {
    if (!isProviderEligibleForExternalCalendars) {
      return;
    }

    fetchExternalCalendarAccountAlerts();

    const pollInterval = parseInt(
      get(window.FEATURE_TOGGLES_DATA, 'provider_external_calendars.data.alerts_poll_interval')
    );

    const intervalId = setInterval(
      fetchExternalCalendarAccountAlerts,
      moment.duration(pollInterval || DEFAULT_ALERTS_POLL_INTERVAL, 'seconds').asMilliseconds()
    );

    return () => clearInterval(intervalId);
  }, [
    fetchExternalCalendarAccountAlerts,
    isProviderEligibleForExternalCalendars
  ]);

  const handleOpenWorkingHours = useCallback(() => {
    window.location.assign(WORKING_HOURS_URL);
  }, []);

  if (!isProviderEligibleForExternalCalendars) {
    return null;
  }

  return (
    <ExternalCalendarAccountAlerts
      screenType={screenTypes.DASHBOARD}
      onOpenWorkingHours={handleOpenWorkingHours}
    />
  );
});

DashboardExternalCalendarAccountAlerts.displayName = 'DashboardExternalCalendarAccountAlerts';

export default DashboardExternalCalendarAccountAlerts;
