import {
  CHANGE_CONSULT_NAVIGATION_STEP_SUCCESS,
  MARK_CONSULT_NAVIGATION_STEP,
  ADD_ERRORS_TO_CONSULT_NAVIGATION_STEP,
  CLEAR_ERRORS_FROM_CONSULT_NAVIGATION_STEP,
  ADD_WARNINGS_TO_CONSULT_NAVIGATION_STEP,
  CLEAR_WARNING_FROM_CONSULT_NAVIGATION_STEP,
  UPDATE_CONSULT_NAVIGATION_ITEMS
} from './action-types';

const DEFAULT_STATE = {
  selectedIndex: 0,
  items:         []
};

const spliceItemAtIndex = (items, itemIndex, newItem) =>
  items.map((item, index) => {
    if (index !== itemIndex) {
      return item;
    }

    return {
      ...item,
      ...newItem
    };
  });

const outOfBounds = (selectedIndex, totalSteps) => selectedIndex < 0 || selectedIndex > totalSteps - 1;

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case MARK_CONSULT_NAVIGATION_STEP: {
      const { valid, selectedIndex } = action;
      const { items } = state;

      return {
        ...state,
        items: spliceItemAtIndex(items, selectedIndex, { valid })
      };
    }

    case CHANGE_CONSULT_NAVIGATION_STEP_SUCCESS: {
      const totalSteps = state.items.length;
      const { selectedIndex } = action;

      if (outOfBounds(selectedIndex, totalSteps)) {
        return state;
      }

      return {
        ...state,
        selectedIndex
      };
    }

    case ADD_ERRORS_TO_CONSULT_NAVIGATION_STEP: {
      const errors = action.errors.slice(); // the old JS will pass the same array with different messages in it, so passing by reference bugzz :)
      const { items } = state;
      const { selectedIndex } = action;

      if (outOfBounds(selectedIndex, items.length)) {
        return state;
      }

      const newItems = spliceItemAtIndex(items, selectedIndex, { errors });

      return {
        ...state,
        items: newItems
      };
    }

    case CLEAR_ERRORS_FROM_CONSULT_NAVIGATION_STEP: {
      const { items } = state;
      const { selectedIndex } = action;
      const newItems = spliceItemAtIndex(items, selectedIndex, { errors: null });

      if (outOfBounds(selectedIndex, items.length)) {
        return state;
      }

      return {
        ...state,
        items: newItems
      };
    }

    case ADD_WARNINGS_TO_CONSULT_NAVIGATION_STEP: {
      const { items } = state;
      const { selectedIndex, warning } = action;

      if (outOfBounds(selectedIndex, items.length)) {
        return state;
      }
      const currentWarnings = items[selectedIndex].warnings || [];

      const newItems = spliceItemAtIndex(items, selectedIndex, { warnings: [...currentWarnings, warning] });

      return {
        ...state,
        items: newItems
      };
    }

    case CLEAR_WARNING_FROM_CONSULT_NAVIGATION_STEP: {
      const { items } = state;
      const { selectedIndex, warningKey } = action;
      const newWarnings =
        items[selectedIndex] && items[selectedIndex].warning
          ? items[selectedIndex].warnings.filter(warning => warning.key !== warningKey)
          : [];
      const newItems = spliceItemAtIndex(items, selectedIndex, { warnings: newWarnings });

      if (outOfBounds(selectedIndex, items.length)) {
        return state;
      }

      return {
        ...state,
        items: newItems
      };
    }

    case UPDATE_CONSULT_NAVIGATION_ITEMS: {
      const { items } = action;

      return {
        ...state,
        items
      };
    }

    default: {
      return state;
    }
  }
};

export { DEFAULT_STATE };

export default reducer;
