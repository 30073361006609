import React, { useEffect, useState } from 'react';
import { mapInlineErrors } from 'app/consultation-interview/utils';
import store from 'app/lib/store';

import { I18n, translate } from '@td/shared_utils';

import { useQuickDxFavorites, useDiagnosesConfig, useCachedDiagnosis } from '../utils';
import { QuickDxSearchBar } from './QuickDxSearchBar';

import './styles/QuickDx.scss';
import { SelectedDxChip } from './SelectedDxChip';

export const QuickDxComponent = () => {
  const [inlineErrorsList, setInlineErrorsList] = useState([]);
  const { isEditable } = useQuickDxFavorites();
  const { info: diagnosisConfig } = useDiagnosesConfig();
  const readOnlyPrimaryDiagnosis = diagnosisConfig.primaryDiagnosis.isReadOnly;
  const cachedDiagnosis = useCachedDiagnosis();
  const diagnosis = cachedDiagnosis.primary;

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const validationErrors = mapInlineErrors(store.getState());

      setInlineErrorsList(validationErrors);
    });

    return () => unsubscribe();
  }, []);

  const invalidDiagnosis = inlineErrorsList.find(({ validatorKey }) => validatorKey === 'invalidDiagnosis');

  const renderPrimaryDiagnosis = () => {
    if (readOnlyPrimaryDiagnosis && diagnosis) {
      return (
        <div>
          {diagnosisConfig.primaryDiagnosis.noteI18nKey && (
            <p>
              <I18n scope="quick_dx.primary_diagnosis_note" text={diagnosisConfig.primaryDiagnosis.noteI18nKey} />
            </p>
          )}
          <div className="quick-dx">
            <SelectedDxChip
              key={diagnosis.diagnosisCode}
              diagnosis={diagnosis}
              index={0}
              isPrimaryDiagnosis
              isDisabled
            />
          </div>
        </div>
      );
    } else {
      return (
        <QuickDxSearchBar
          sectionName="primaryDiagnosis"
          inputName="quickDxSearchInput_primary_diagnosis"
          label={translate(null, 'quick_dx', 'primary_diagnosis_label')}
          isPrimaryDiagnosis
          inputPlaceholder={translate(null, 'quick_dx', 'search_diagnosis_and_codes')}
          validationError={invalidDiagnosis}
        />
      );
    }
  };

  return (
    <div>
      {/* PRIMARY DIAGNOSIS */}

      <h2>
        <I18n scope="quick_dx" text="primary_diagnosis" />
      </h2>

      {renderPrimaryDiagnosis()}

      {/* SECONDARY DIAGNOSIS */}

      {isEditable && (
        <React.Fragment>
          <h2>
            <I18n scope="quick_dx" text="secondary_diagnosis" />
          </h2>

          <QuickDxSearchBar
            sectionName="secondaryDiagnosis"
            inputName="quickDxSearchInput_secondary_diagnosis"
            label={translate(null, 'quick_dx', 'add_up_to_11')}
            multipleDiagnosises
            inputPlaceholder={translate(null, 'quick_dx', 'search_icd10_diagnoses')}
          />
        </React.Fragment>
      )}
    </div>
  );
};

// NOTE: For some reason doing `connect(mapStateToProps)(QuickDxContainer)`
// causes a bunch of gql queries to be sent for the list of quick dx
