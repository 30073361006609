import { connect } from 'react-redux';

import DefaultAlertButton from './default-alert-button';
import { closeAlert } from '../actions';

const mapStateToProps = state => ({
  buttonText: state.alert.buttonText
});

const mapDispatchToProps = {
  closeAlert
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultAlertButton);
