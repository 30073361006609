import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { VideoIcon, AudioIcon, DisconnectedIcon } from '../../icons';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import styles from './styles.module.scss';
import { translate } from '@td/shared_utils';
import { VIDEO_VISIT_TYPE } from '../../constants';

const ParticipantStatus = ({ status, createdAt, visitType }) => {
  const props = {
    width:      11,
    height:     11,
    className:  styles.participantStatus,
    'data-tip': translate(null, 'call_details.status_message', status, {
      time: moment(createdAt).format('hh:mm a')
    }),
    'data-place':  'bottom',
    'data-effect': 'solid'
  };

  const icons = {
    JOINED:       visitType === VIDEO_VISIT_TYPE ? VideoIcon : AudioIcon,
    DISCONNECTED: DisconnectedIcon
  };

  const IconComponent = icons[status];

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return <IconComponent {...props} />;
};

ParticipantStatus.propTypes = {
  status:    PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  visitType: PropTypes.string.isRequired
};

export default ParticipantStatus;
