import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { translate } from '@td/shared_utils';
import { ESTABLISHED, THERAPIST_DEFAULT_BETTER_IN_DAYS } from '../constants';
import { providerHasntSpecifiedBetterInDays } from '../utils';

const PatientListDueVisit = ({ inDays, lastConsult, nextConsult, statusCd }) => {
  let betterInDays = inDays ? inDays[0].betterInDays : null;
  if (providerHasntSpecifiedBetterInDays(betterInDays)) betterInDays = THERAPIST_DEFAULT_BETTER_IN_DAYS;

  if (statusCd !== ESTABLISHED) return null;

  const browserTimezone = moment.tz.guess();
  const last = moment.tz(lastConsult, browserTimezone).startOf('day');
  const today = moment().tz(browserTimezone).startOf('day');

  let daysUntilFollowup;

  if (!nextConsult) {
    const expectedFollowupDate = last.clone().add(betterInDays, 'days');
    daysUntilFollowup = expectedFollowupDate.diff(today, 'days');
  } else {
    return null;
  }

  let displayText;
  let flagClass = '';

  if (daysUntilFollowup > 0) {
    // Visit is due in the future
    displayText = `${daysUntilFollowup} day${daysUntilFollowup > 1 ? 's' : ''}`;
    if (daysUntilFollowup <= 7) {
      flagClass = 'due_soon_flag';
    }
  } else if (daysUntilFollowup === 0) {
    // Visit is today
    displayText = `${daysUntilFollowup} days`;
    flagClass = 'overdue_flag';
  } else {
    // Visit is overdue
    const overdueDays = Math.abs(daysUntilFollowup);
    displayText = `${overdueDays} day${overdueDays > 1 ? 's' : ''} ago`;
    if (daysUntilFollowup <= -1) {
      flagClass = 'overdue_flag';
    }
  }

  return (
    <React.Fragment>
      <p>{displayText}</p>
      {flagClass && (
        <p className={flagClass}>
          {flagClass === 'due_soon_flag'
            ? translate(null, 'patient_case_load_mgmt_mvp.patient_list', 'due_soon')
            : translate(null, 'patient_case_load_mgmt_mvp.patient_list', 'overdue')}
        </p>
      )}
    </React.Fragment>
  );
};

PatientListDueVisit.propTypes = {
  lastConsult: PropTypes.string,
  nextConsult: PropTypes.string,
  inDays: PropTypes.array,
  statusCd: PropTypes.string
};

export default PatientListDueVisit;
