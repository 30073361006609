import { PropTypes } from 'react';
import _ from 'lodash';
import PersonalHealthRecord from './PersonalHealthRecord';
import { PHR_CATEGORY } from './constants';

class PersonalHealthDocument {
  static buildPersonalHealthDocuments = function (personalHealthRecords) {
    let groupedList = _.groupBy(personalHealthRecords, (item)=>`${PHR_CATEGORY[item.lab_result_type]}`);
    return _.reduce(groupedList, (personalHealthDocuments, value, key) => {
      personalHealthDocuments.push(new PersonalHealthDocument(value));
      return personalHealthDocuments;
    }, []);
  }

  constructor(personalHealthRecords = []) {
    let records = personalHealthRecords
                    .sort((a, b) => {return new Date(a.start_date) - new Date(b.start_date)})
                    .map(phr => new PersonalHealthRecord(phr));

    Object.assign(this, {personalHealthRecords: records});
  }

  getDateRange(){
    const min = _.minBy(this.personalHealthRecords, phr => phr.getDate());
    const max = _.maxBy(this.personalHealthRecords, phr => phr.getDate());
    return `${min.getDate()} - ${max.getDate()}`;
  }

  getType(){
    return this.personalHealthRecords[0].getType();
  }

  getDataSourceName(){
    return this.personalHealthRecords[0].dataSourceName;
  }

  getMeasurementRange(){
    const min = _.minBy(this.personalHealthRecords, "numericResult");
    const max = _.maxBy(this.personalHealthRecords, "numericResult");
    return `${min.getReading()} - ${max.getReading()}`;
  }


  getLast(){
    return _.last(this.personalHealthRecords);
  }

  getLastMeasurementReading(){
    let lastMeasurementDate = this.getLast().getDate();
    let lastMeasurementTime = this.getLast().getTime();
    let allGroupedMesurements = _.filter(this.personalHealthRecords, (rec) =>
      { return rec.getDate() === lastMeasurementDate && rec.getTime() === lastMeasurementTime; });
    return allGroupedMesurements.map(item => item.getReading()).join(' - ');
  }


}

export default PersonalHealthDocument;
