import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import { useClickOutside, useQuickDxFavorites } from '../utils';
import { SearchIcon } from './icons';

import { QuickDxAutoComplete } from './QuickDxAutoComplete';

export const SearchDxBar = ({
  inputPlaceholder,
  onSearchTermChange,
  setSearchIsFocused,
  searchIsFocused,
  inputName,
  searchQuery,
  isInvalid,
  ...props
}) => {
  const { isEditable } = useQuickDxFavorites();

  const searchResultsList = _.get(props, 'dxData.data.searchQuickDx', []);
  const searchBarWrapperRef = useRef(null);

  useClickOutside(searchBarWrapperRef, event => setSearchIsFocused(false));

  const handleSearchQueryChange = event => {
    const queryFieldValue = _.get(event, 'target.value', '');

    onSearchTermChange(queryFieldValue);
  };

  return (
    <div
      className={classnames('search-bar-wrapper', isInvalid && 'invalid', searchIsFocused && 'active-field')}
      ref={searchBarWrapperRef}
    >
      <label htmlFor={inputName}>
        <SearchIcon />
      </label>

      <input
        type="text"
        name={inputName}
        id={inputName}
        autoComplete="off"
        value={searchQuery}
        className={classnames(isInvalid && 'invalid')}
        onFocus={() => setSearchIsFocused(true)}
        onChange={handleSearchQueryChange}
        placeholder={inputPlaceholder}
        disabled={!isEditable}
      />

      <QuickDxAutoComplete
        searchQuery={searchQuery}
        show={searchIsFocused}
        results={props.searchTerm.actual >= 3 ? searchResultsList : []}
        {...props}
      />
    </div>
  );
};

SearchDxBar.displayName = 'SearchDxBar';

SearchDxBar.propTypes = {
  inputPlaceholder:   PropTypes.string.isRequired,
  onSearchTermChange: PropTypes.func.isRequired,
  setSearchIsFocused: PropTypes.func.isRequired,
  searchIsFocused:    PropTypes.bool.isRequired
};
