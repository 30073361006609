import { connect } from 'react-redux';

import { durationSeverityProgressionForm } from '../../actions';
import DurationSeverityProgressionForm from './duration-severity-progression-form';
import { mapInlineErrors } from 'app/consultation-interview/utils';

const mapStateToProps = state => ({
  durationOptions:     state.durationSeverityProgression.durationOptions,
  progressionOptions:  state.durationSeverityProgression.progressionOptions,
  severityOptions:     state.durationSeverityProgression.severityOptions,
  formValues:          state.durationSeverityProgressionForm.formValues,
  durationRequired:    state.chiefComplaint.durationRequired,
  severityRequired:    state.chiefComplaint.severityRequired,
  progressionRequired: state.chiefComplaint.progressionRequired,
  validationErrors:    mapInlineErrors(state)
});

const mapDispatchToProps = {
  changeField: durationSeverityProgressionForm.changeField
};

export default connect(mapStateToProps, mapDispatchToProps)(DurationSeverityProgressionForm);
