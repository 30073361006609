exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._37-K1Ipf0_c9wbjIcrd3_A {\n  margin: 0; }\n", ""]);

// exports
exports.locals = {
	"notifyNurseButton": "_37-K1Ipf0_c9wbjIcrd3_A",
	"notifyNurseButton": "_37-K1Ipf0_c9wbjIcrd3_A"
};