import { translate } from '@td/shared_utils';

export const NOTIFICATION_MESSAGES = {
  turned_off_by_admin: translate(null, 'patient_case_load_mgmt', 'notification_banner_turned_off_by_admin'),
  no_active_licenses: translate(null, 'patient_case_load_mgmt', 'notification_banner_no_active_license'),
  turned_off_by_autodecline: translate(null, 'patient_case_load_mgmt', 'notification_banner_turned_off_by_autodecline'),
  limit_matched:       translate(null, 'patient_volume_configuration', 'patient_limit_configuration_notifications.current_patients_and_overall_limit_matched'),
  limit_saved:         translate(null, 'patient_volume_configuration', 'patient_limit_configuration_notifications.patient_limits_saved'),
  title:               translate(null, 'patient_volume_configuration', 'button.manage_patient_limits')
};

export const instructions = [
  'patient_case_load_mgmt_mvp.new_feature_guided_tour.fill_out_your_profile',
  'patient_case_load_mgmt_mvp.new_feature_guided_tour.set_your_schedule',
  'patient_case_load_mgmt_mvp.new_feature_guided_tour.add_patient_volume_limits',
  'patient_case_load_mgmt_mvp.new_feature_guided_tour.clock_in'
];

export const patientSummaryCard = {
  title_todays_patients:  translate(null, 'patient_case_load_mgmt_mvp.patients_summary_cards', 'todays_patients'),
  caption_new:            translate(null, 'patient_case_load_mgmt_mvp.patients_summary_cards', 'new'),
  caption_established:    translate(null, 'patient_case_load_mgmt_mvp.patients_summary_cards', 'established'),
  title_active_patients:  translate(null, 'patient_case_load_mgmt_mvp.patients_summary_cards', 'active_patients'),
  caption_current:        translate(null, 'patient_case_load_mgmt_mvp.patients_summary_cards', 'current'),
  caption_limit:          translate(null, 'patient_case_load_mgmt_mvp.patients_summary_cards', 'limit')
};

export const ACCEPTING_NEW_PATIENT_REASON = {
  off_by_autodecline: 'ACPTNEWPATIENTREASON_OFFAUTODECLINE',
  off_by_admin: 'ACPTNEWPATIENTREASON_OFFADMIN',
  off_by_provider: 'ACPTNEWPATIENTREASON_OFFPROVIDER',
  on_by_admin: 'ACPTNEWPATIENTREASON_ONADMIN',
  on_by_provider: 'ACPTNEWPATIENTREASON_ONPROVIDER',
  off_no_licenses: 'ACPTNEWPATIENTREASON_OFFNOLICENSE'
}

export const SWITCHER = {
  accepting_new_patients: translate(null, 'patient_case_load_mgmt', 'accepting_new_patients'),
  up_to_overall_limit: translate(null, 'patient_volume_configuration', 'switcher.up_to_overall_limit')
};

export const MODAL = {
  title: translate(null, 'patient_volume_configuration', 'modal.title'),
  cancel:  translate(null, 'patient_volume_configuration', 'modal.cancel'),
  save:  translate(null, 'patient_volume_configuration', 'modal.save')
};

export const INNER_MODAL = {
  overall: translate(null, 'patient_volume_configuration', 'modal.overall_patient_limit'),
  limit: translate(null, 'patient_volume_configuration', 'modal.limit'),
  warning: translate(null, 'patient_volume_configuration', 'modal.limit_warning'),
  daily_limit: translate(null, 'patient_volume_configuration', 'modal.daily_new_patient_limit'),
  psychiatrist: translate(null, 'patient_volume_configuration', 'modal.main_text.psychiatrist'),
  therapist: translate(null, 'patient_volume_configuration', 'modal.main_text.therapist'),
  active:translate(null, 'patient_volume_configuration', 'modal.active_patient_limit_title'),
  active_patient_limit_guidance:translate(null, 'patient_volume_configuration', 'modal.active_patient_limit_guidance'),
};
