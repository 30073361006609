import React from "react";
import PropTypes from "prop-types";
import { I18n } from "@td/shared_utils";

const ClinicalGuideline = React.memo(({ clinicalGuideline: { url } }) => (
  <p>
    <a
      target="_blank"
      href={url}
    >
      <I18n scope="consultation.clinical_guideline" />
    </a>
  </p>
));

ClinicalGuideline.propTypes = {
  clinicalGuideline: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};

export default ClinicalGuideline;
