import { connect } from 'react-redux';
import { removeFromPendingPrescriptionList } from 'app/Prescription/actions';

import ProviderOrder from './ProviderOrder';
import { fetchProviderOrders, deleteProviderOrder, revokeProviderOrder } from '../actions';

const mapStateToProps = state => ({
  providerOrders:       state.providerOrder.orders,
  revokePending:        state.providerOrder.revokeResult.pending,
  pendingPrescriptions: state.pendingPrescriptions,
  settings:             state.settings,
  revokeSuccess:        state.providerOrder.revokeResult.success
});

const mapDispatchToProps = {
  fetchProviderOrders,
  deleteProviderOrder,
  revokeProviderOrder,
  removeFromPendingPrescriptionList
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderOrder);
