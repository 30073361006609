import { createRequestActions } from '@td/utils';

import {
  INIT_CONSULTATION_QUEUE_REFRESH,
  START_CONSULTATION_QUEUE_REFRESH,
  PAUSE_CONSULTATION_QUEUE_REFRESH,
  FETCH_CONSULTATION_QUEUE
} from '../action-types/consultation-queue-action-types';

export const initConsultationQueueRefresh = () => ({
  type: INIT_CONSULTATION_QUEUE_REFRESH
});

export const startConsultationQueueRefresh = () => ({
  type: START_CONSULTATION_QUEUE_REFRESH
});

export const pauseConsultationQueueRefresh = () => ({
  type: PAUSE_CONSULTATION_QUEUE_REFRESH
});

export const fetchConsultationQueue = createRequestActions(FETCH_CONSULTATION_QUEUE);
