import { connect } from 'react-redux';

import { changeSurveyAnswerState } from 'app/surveys/actions/survey-actions';
import Survey from 'app/surveys/components/survey';

const mapStateToProps = state => ({
  surveyState: state.surveys
});

const mapDispatchToProps = {
  changeSurveyState: changeSurveyAnswerState
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Survey);
