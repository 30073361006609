import { combineEpics } from 'redux-observable';

import {
  fetchMedicationHistoryEpic,
  fetchInteractionAlertEpic,
  fetchClinicalAllergyEpic,
  fetchFormularyDrugEpic,
  fetchPrescriptionBenefitsEpic,
  fetchRecommendedRxEpic,
  fetchEligibilityEpic
} from 'medication_service_ui';

import {
  fetchMedicationListEpic,
  fetchClinicalMedEpic,
  refetchClinicalMedicationsEpic,
  submitClinicalMedicationFormEpic,
  setSurescriptsUpdatedFlagEpic
} from '../ClinicalMedication/epics';
import providerEpics from '../Provider/epics';
import { validatePatientWeightFormEpic, postPatientWeightFormEpic } from '../Patient/epics';
import {
  fetchProviderOrderEpic,
  revokeProviderOrderEpic,
  revokeProviderOrderSuccessEpic,
  revokeProviderOrderFailEpic
} from '../ProviderOrder/epics';

import {
  fetchQuickRxByProviderEpic,
  fetchMedicationInfoEpic,
  fetchMedicationFormEpic,
  fetchCommonDosageEpic,
  fetchPrescriptionCodeEpic,
  submitPrescriptionsPostConsultEpic,
  submitPrescriptionsPostConsultSuccessEpic,
  submitPrescriptionFormEpic,
  refetchPrescriptionFormEpic,
  fetchMedicationBySpecialityEpic,
  postQuickRxFormEpic,
  postQuickRxFormSuccessEpic,
  postQuickRxFormFailEpic,
  postRemoveQuickRxFormEpic,
  confirmPendingPrescriptionsListEpic,
  updatePharmacyParamsEpic
} from '../Prescription/epics';

import { rejectConferenceEpic, confirmConferenceEpic } from '../Conference/epics';

import { onChiefComplaintChangeEpic } from '../consult-navigation/epics';

import { submitPendingReviewListEpic } from '../medication-review/epics';

import { cacheInterviewEpic } from '../storage/epics';

import subjectiveTabEpics from '../subjective-tab/epics';

import erxErrorsEpics from '../erx-errors/epics';
import networkTestEpics from '../network-test/epics';

import alertEpics from '../components/alert/epics';

import sidebarEpics from '../sidebar/epics';

import providerActiveStatusEpics from '../providerActiveStatus/epics';

import dashboardEpics from '../dashboard/epics';
import {
  changeConsultNavigationStepEpic,
  consultNavigationNextStepEpic,
  changeConsultNavigationStepSuccessEpic,
  changeConsultNavigationStepFailEpic,
  updateNavigationStepsEpic
} from '../consultation-interview/epics';

export default combineEpics(
  fetchClinicalMedEpic,
  fetchMedicationListEpic,
  refetchClinicalMedicationsEpic,
  submitClinicalMedicationFormEpic,
  refetchPrescriptionFormEpic,
  fetchMedicationHistoryEpic,
  fetchFormularyDrugEpic,
  fetchPrescriptionBenefitsEpic,
  fetchEligibilityEpic,
  fetchRecommendedRxEpic,
  providerEpics,
  postPatientWeightFormEpic,
  confirmPendingPrescriptionsListEpic,
  validatePatientWeightFormEpic,
  fetchQuickRxByProviderEpic,
  fetchMedicationInfoEpic,
  fetchMedicationFormEpic,
  fetchCommonDosageEpic,
  fetchPrescriptionCodeEpic,
  submitPrescriptionsPostConsultEpic,
  submitPrescriptionsPostConsultSuccessEpic,
  submitPrescriptionFormEpic,
  fetchMedicationBySpecialityEpic,
  postQuickRxFormEpic,
  postQuickRxFormSuccessEpic,
  postQuickRxFormFailEpic,
  postRemoveQuickRxFormEpic,
  fetchProviderOrderEpic,
  revokeProviderOrderEpic,
  revokeProviderOrderFailEpic,
  revokeProviderOrderSuccessEpic,
  fetchInteractionAlertEpic,
  rejectConferenceEpic,
  confirmConferenceEpic,
  updatePharmacyParamsEpic,
  fetchClinicalAllergyEpic,
  submitPendingReviewListEpic,
  setSurescriptsUpdatedFlagEpic,
  cacheInterviewEpic,
  subjectiveTabEpics,
  onChiefComplaintChangeEpic,
  erxErrorsEpics,
  networkTestEpics,
  alertEpics,
  sidebarEpics,
  providerActiveStatusEpics,
  dashboardEpics,
  changeConsultNavigationStepEpic,
  changeConsultNavigationStepSuccessEpic,
  updateNavigationStepsEpic,
  consultNavigationNextStepEpic,
  changeConsultNavigationStepFailEpic
);
