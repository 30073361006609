import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AutoDialer from './AutoDialer';

import { AutoDialerV3 as api } from '@teladoc/auto_dialer';
import { useActiveConferenceStatus } from '../utils';

const AutoDialerInitializer = props => {
  const { consultation, currentAttendee, attendeeNotificationsEnabled, options = {} } = props;
  const { consultationId } = consultation;

  const { shouldDisplayAutoDialer, activeConferenceRS } = useActiveConferenceStatus({ consultationId });
  const autoCallStartEnabled = options.shouldAutoStartCall && process.env.NODE_ENV !== 'development';

  const [hidePhoneNumber, setHidePhoneNumber] = useState(false);

  useEffect(() => {
    api.checkIfShouldHidePhoneNumber(consultationId).then(data => setHidePhoneNumber(data.hide_phone_number));
  }, []);

  return (
    shouldDisplayAutoDialer && (
      <AutoDialer
        consultation={consultation}
        currentAttendee={currentAttendee}
        attendeeNotificationsEnabled={attendeeNotificationsEnabled}
        activeConferenceRS={activeConferenceRS}
        options={{ ...options, autoCallStartEnabled }}
        hidePhoneNumber={hidePhoneNumber}
      />
    )
  );
};

AutoDialerInitializer.propTypes = {
  options:      PropTypes.object,
  consultation: PropTypes.shape({
    alternatePhoneNumber: PropTypes.string,
    phoneNumber:          PropTypes.string.isRequired,
    member:               PropTypes.shape({
      authProfileId:           PropTypes.string,
      brandName:               PropTypes.string,
      createdAt:               PropTypes.string,
      dob:                     PropTypes.string,
      domesticCountryCd:       PropTypes.string,
      firstNm:                 PropTypes.string.isRequired,
      genderCd:                PropTypes.string,
      groupId:                 PropTypes.number,
      hasWebAccess:            PropTypes.bool,
      isActiveConsult:         PropTypes.bool,
      isAdult:                 PropTypes.bool,
      languageCd:              PropTypes.string,
      lastNm:                  PropTypes.string.isRequired,
      memberId:                PropTypes.number,
      memberProviderRelations: PropTypes.array,
      memberStatusCd:          PropTypes.string,
      middleNm:                PropTypes.string,
      personType:              PropTypes.string,
      primaryMemberId:         PropTypes.number,
      residenceAddress:        PropTypes.object,
      userNm:                  PropTypes.string
    }).isRequired
  }).isRequired,
  currentAttendee: PropTypes.shape({
    externalId:     PropTypes.number.isRequired,
    externalTypeId: PropTypes.string.isRequired,
    phoneNumbers:   PropTypes.array
  }).isRequired,
  attendeeNotificationsEnabled: PropTypes.bool.isRequired
};

export default AutoDialerInitializer;
