import { clearErrorsFromConsultNavigationStep } from 'app/consult-navigation/actions';
import { CLEAR_COMPLAINT, SELECT_COMPLAINT } from '../subjective-tab/action-types';
import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';

export const onChiefComplaintChangeEpic = action$ =>
  action$.pipe(
    ofType(CLEAR_COMPLAINT, SELECT_COMPLAINT),
    map(() => clearErrorsFromConsultNavigationStep(3))
  );
