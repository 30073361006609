import React from 'react';
import Participants from '../participants';
import Logs from '../logs';
import styles from './styles.module.scss';
import { I18n } from '@td/shared_utils';
import cn from "classnames";

const Title = props => <div {...props} className={styles.callDetailsPopoverTitle} />;

const CallDetailsPopover = ({ isShow, consultation, visitType, pipWindow }) => {
  const { consultationId, member, provider } = consultation;

  return (
    <div
      id="callDetailsPopover"
      className={cn(styles.callDetailsPopover, isShow ? styles.show : styles.hidden, pipWindow && styles.onPipWindow)}
    >
      <Title>
        <I18n scope="call_details" text="participants" />
      </Title>
      <Participants member={member} provider={provider} visitType={visitType} />
      <Title>
        <I18n scope="call_details" text="call_history" />
      </Title>
      <Logs consultationId={consultationId} />
    </div>
  );
};

export default CallDetailsPopover;
export { Title };
