import { createSelector } from '@td/utils';

import getSelectedSymptomIds from './getSelectedSymptomIds';

const getSymptomRadioValues = state => state.symptomsForm.radioValues;

export default createSelector(
  getSelectedSymptomIds,
  getSymptomRadioValues,
  (selectedSymptomIds, symptomRadioValues) =>
    selectedSymptomIds.filter(symptomId => Boolean(symptomRadioValues[symptomId]))
);
