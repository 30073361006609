import _ from 'lodash';
import { createSelector } from '@td/utils';

import {
  getDurationSelectedLabel,
  getProgressionSelectedLabel,
  getSeveritySelectedLabel
} from '../duration-severity-progression/getDropdownsSelectedLabel';

const DEFAULT_NOTE_FORMAT_CODE = 'CLINDOCMESSAGEFORMAT_DEFAULT';

const getAge = (state, props) => props.patientAge;
const getGender = (state, props) => props.patientGender;
const getChiefComplaint = state => state.chiefComplaint.other || state.chiefComplaint.complaintText;
const getDurationValue = state => state.durationSeverityProgressionForm.formValues.duration_value;
const getOtherSeverity = state => state.durationSeverityProgressionForm.formValues.severity_value;
const getOtherProgression = state =>
  state.durationSeverityProgressionForm.formValues.progression_value;
const getNoteFormat = state => state.chiefComplaint.noteFormat;

export default createSelector(
  getAge,
  getGender,
  getDurationValue,
  getDurationSelectedLabel,
  getSeveritySelectedLabel,
  getProgressionSelectedLabel,
  getOtherSeverity,
  getOtherProgression,
  getChiefComplaint,
  getNoteFormat,
  (
    age,
    gender,
    durationValue,
    durationType,
    severity,
    progression,
    otherSeverity,
    otherProgression,
    chiefComplaint,
    noteFormat
  ) => {
    const knownGenders = ['male', 'female'];
    const patientLabel = _.includes(knownGenders, _.toLower(gender)) ? _.toLower(gender) : 'patient';

    const patientData = {
      age,
      patientLabel,
      durationValue,
      durationType,
      severity,
      progression,
      otherSeverity,
      otherProgression,
      chiefComplaint
    };

    return noteFormat == DEFAULT_NOTE_FORMAT_CODE ? getStandardNote(patientData) : getNonsdpNote(patientData);
  }
);

const getStandardNote = (patientData) => {
  const {
    age,
    patientLabel,
    durationValue,
    durationType,
    severity,
    progression,
    otherSeverity,
    otherProgression,
    chiefComplaint
  } = patientData;

  return `${age}-year old ${patientLabel} presents with ` +
         `${durationValue && durationType ? `${durationValue} ${_.toLower(durationType)} of ` : ''}` +
         `${severity !== 'Other' && severity ? `${_.toLower(severity)} ` : ''}` +
         `${_.toLower(chiefComplaint) || 'N/A'}` +
         `${progression !== 'Other' && progression ? ` that is ${_.toLower(progression)}` : ''}` +
         '.' +
         `${severity === 'Other' && otherSeverity ? ` Severity: ${otherSeverity}.` : ''}` +
         `${progression === 'Other' && otherProgression ? ` Progression: ${otherProgression}.` : ''}`;
};

const getNonsdpNote = (patientData) => {
  const {
    age,
    patientLabel,
    chiefComplaint
  } = patientData;

  return `Consult with ${age}-year-old ${patientLabel}. ` +
         `Chief complaint: ${_.toLower(chiefComplaint) || 'N/A'}.`;
};
