import {
  CHANGE_CONSULT_NAVIGATION_STEP,
  UPDATE_CONSULT_NAVIGATION_ITEMS,
  CHANGE_CONSULT_NAVIGATION_STEP_SUCCESS,
  CHANGE_CONSULT_NAVIGATION_STEP_FAIL,
  CONSULT_NAVIGATION_NEXT_STEP
} from 'app/consult-navigation/action-types';
import { changeConsultNavigationStepSuccess, changeConsultNavigationStepFail } from '../consult-navigation/actions';
import { showSelectedStep, cacheInterview } from './utils/switch-step';
import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, filter, map, takeUntil, startWith, delay, catchError } from 'rxjs/operators';
import { authToken, teladocApi } from '@td/api';
import validateConsultationStep from './validations/validate-consultation-step';
import { clearErrorsFromConsultNavigationStep } from 'app/consult-navigation/actions';

const planTabIndex = 5;
const followUpTabIndex = 6;

// This epic is called when users click the continue button
export const consultNavigationNextStepEpic = (action$, state$) =>
  action$.pipe(
    ofType(CONSULT_NAVIGATION_NEXT_STEP),
    switchMap(() => {
      const {
        consultNavigation: { selectedIndex: currentIndex }
      } = state$.value;

      // getting the data attribute of patient_management_tools_v2 from react_consult_form_followups
      const patient_management_tools_v2_enabled = document.getElementById("react_consult_form_followups").getAttribute("data-patient-management-tools-v2-enabled")
      // Since plan and followup tabs are linked, this will clear followup tab holdover errors when plan tab is navigated from
      // CurrentIndex of 5 is the plan tab's location in the navigation and 6 is the follow up tab (only for MH consults)
      if (
        patient_management_tools_v2_enabled && 
        currentIndex === planTabIndex) dispatch(clearErrorsFromConsultNavigationStep(followUpTabIndex));
      // Prevent users from clicking the continue button again before finishing the current validation
      $('.continue').attr('disabled', true);
      return from(
        validateConsultationStep(currentIndex).then(allowProceed => {
          if (allowProceed) {
            return changeConsultNavigationStepSuccess(currentIndex + 1);
          }
          return changeConsultNavigationStepFail();
        })
      );
    })
  );

// This epic is called when users click the navigation bar
export const changeConsultNavigationStepEpic = (action$, state$) =>
  action$.pipe(
    ofType(CHANGE_CONSULT_NAVIGATION_STEP),
    switchMap(({ selectedIndex }) => {
      const {
        consultNavigation: { items, selectedIndex: currentIndex }
      } = state$.value;
      const currentItem = items[currentIndex];

      // getting the data attribute of patient_management_tools_v2 from react_consult_form_followups
      const patient_management_tools_v2_enabled = document.getElementById("react_consult_form_followups").getAttribute("data-patient-management-tools-v2-enabled")
      // Since plan and followup tabs are linked, this will clear followup tab holdover errors when plan tab is navigated from
      // CurrentIndex of 5 is the plan tab's location in the navigation and 6 is the follow up tab (only for MH consults)
      if (
        patient_management_tools_v2_enabled && 
        currentIndex === planTabIndex) dispatch(clearErrorsFromConsultNavigationStep(followUpTabIndex));

      //  Only the EHR and Verify tabs are blocking users from clicking on other tabs(excluding previous tab)
      //  unless they pass validation
      return from(
        validateConsultationStep(currentIndex).then(allowProceed => {
          if (allowProceed || !currentItem.blocking || selectedIndex <= currentIndex) {
            return changeConsultNavigationStepSuccess(selectedIndex);
          }

          return changeConsultNavigationStepFail();
        })
      );
    })
  );

export const changeConsultNavigationStepSuccessEpic = (action$, _store) =>
  action$.pipe(
    ofType(CHANGE_CONSULT_NAVIGATION_STEP_SUCCESS),
    switchMap(({ selectedIndex }) => {
      $('.continue').attr('disabled', false);
      showSelectedStep(selectedIndex);
      cacheInterview(selectedIndex);
      return [];
    })
  );

export const changeConsultNavigationStepFailEpic = (action$, _store) =>
  action$.pipe(
    ofType(CHANGE_CONSULT_NAVIGATION_STEP_FAIL),
    switchMap(() => {
      $('.continue').attr('disabled', false);
      return [];
    })
  );

export const updateNavigationStepsEpic = (action$, _store) =>
  action$.pipe(
    ofType(UPDATE_CONSULT_NAVIGATION_ITEMS),
    switchMap(() => {
      showSelectedStep(0);
      return [];
    })
  );

export const checkFollowUpConsult = consultationId => {
  const url = `/v4/consultations/${consultationId}/follow_up_allowed_to_auto_lock`;
  return teladocApi.get(url, {}, authToken.get());
};

export const checkFollowTimeConflict = async (proposedDate, memberId, screenName, consultationId) => {
  const url = '/v4/consultations/proposal_conflicts_with_existing_appointments';
  let params = {
    proposed_date: new Date(proposedDate).toISOString(),
    member_id:     memberId
  };
  if (screenName) {
    params = {
      ...params,
      screen_name: screenName
    };
  }
  if (consultationId) {
    params = {
      ...params,
      id: consultationId
    };
  }
  return await teladocApi.get(url, params, authToken.get());
};

export const getProviderLockedTimeSlots = (providerId, memberId) => {
  const url = '/v4/consultations/locked_slots';
  return teladocApi.get(url, { provider_id: providerId, member_id: memberId }, authToken.get());
};
