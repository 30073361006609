import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, uniq } from 'lodash';
import { Query } from 'react-apollo';
import LabOrdersList from '../components/LabOrdersList';
import Acknowledgement from '../components/acknowledgement';
import LabQuestion from '../components/LabQuestion';
import AVAILABLE_LABS_QUERY from '../queries/available-labs.graphql';
import { LABS_QUESTION_SETTINGS, LAB_PARTNER_URLS } from '../constants';

class LabPrescriptionContainer extends Component {
  constructor(props) {
    super(props);

    const { cachedLabsPrescribe, cachedLabOffers } = this.props.preselectedData;

    const isLabPrescriptionSelected = LABS_QUESTION_SETTINGS.IS_SELECTED_COMPARATOR[cachedLabsPrescribe];

    this.state = {
      isLabPrescriptionSelected: !!isLabPrescriptionSelected,
      selectedOffers:            JSON.parse(cachedLabOffers || '[]')
    };

    this.isOptionSelected = this.isOptionSelected.bind(this);
    this.switchLabPrescription = this.switchLabPrescription.bind(this);
    this.clickOffer = this.clickOffer.bind(this);
  }

  isOptionSelected(option) {
    return this.state.isLabPrescriptionSelected === LABS_QUESTION_SETTINGS.IS_SELECTED_COMPARATOR[option];
  }

  openNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  hasPartnerLabUrl() {
    return !!LAB_PARTNER_URLS[this.props.countryCode];
  }

  openPartnerLabUrl() {
    const labPartnerUrl = LAB_PARTNER_URLS[this.props.countryCode];

    if (!labPartnerUrl) return;

    this.openNewTab(labPartnerUrl);
  }

  openNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  manualPartnerLabRequest() {
    const labPartnerUrl = LAB_PARTNER_URLS[this.props.countryCode];

    if (!labPartnerUrl) return;

    this.openNewTab(labPartnerUrl);
  }

  switchLabPrescription(e) {
    const isSelected = LABS_QUESTION_SETTINGS.IS_SELECTED_COMPARATOR[e.currentTarget.value];

    if (this.hasPartnerLabUrl() && isSelected) {
      this.openPartnerLabUrl();
    }

    // XXX create feature toggle or universal setting
    if (this.props.countryCode === 'CAN' && isSelected) {
      this.manualPartnerLabRequest();
    }

    this.setState({
      isLabPrescriptionSelected: isSelected
    });
  }

  clickOffer(e) {
    if (e.target.checked) {
      this.setState({
        selectedOffers: uniq([e.target.value, ...this.state.selectedOffers])
      });
    } else {
      this.setState({
        selectedOffers: this.state.selectedOffers.filter(o => o !== e.target.value)
      });
    }
  }

  countrySupportsLabWithoutOffers(countryCode) {
    // If reused for other countries, consider creating a UniversalSetting
    return countryCode === 'CAN';
  }

  renderLabsWithouOffers() {
    return (
      <div>
        <LabQuestion isOptionSelected={this.isOptionSelected} labOptionChanged={this.switchLabPrescription} />
        {this.state.isLabPrescriptionSelected ? <Acknowledgement countryCode={this.props.countryCode} /> : null}
      </div>
    );
  }

  renderLabsPanel(labCategories) {
    if (this.props.countryCode === 'CAN') {
      // TODO extract to a component
      // add click handler
      return (
        <div>
          <h4>Labs</h4>
          <p>Are you ordering labs?</p>
          <ul className="yes_no_radios" id="lab_prescription">
            {this.getInputs()}
          </ul>
          {this.state.isLabPrescriptionSelected ? <Acknowledgement /> : null}
        </div>
      );
    }

    // This check needs to be inside this function to prevent an async ordering issue.
    // If this consultation has a list of available labs, render the Labs section and checkboxes.
    if (isEmpty(labCategories)) return null;

    return (
      <div>
        <LabQuestion isOptionSelected={this.isOptionSelected} labOptionChanged={this.switchLabPrescription} />
        {this.state.isLabPrescriptionSelected ? (
          <div>
            <p className="service_details">
              The ordering physician is responsible for all follow up on labs ordered (2 hours to review results) as
              outlined in training. <b>Remind</b> the member to complete the order <b>and</b> to check message center
              for results.
            </p>
            <LabOrdersList
              labCategories={labCategories}
              selectedOffers={this.state.selectedOffers}
              clickOffer={this.clickOffer}
            />
            <Acknowledgement countryCode={this.props.countryCode} />
          </div>
        ) : null}
      </div>
    );
  }

  renderLabsUnavailableMessage() {
    return (
      <div>
        <h4>Labs</h4>
        <p>Labs are not available in member's area at this time.</p>
      </div>
    );
  }

  render() {
    if (this.countrySupportsLabWithoutOffers(this.props.countryCode)) {
      return this.renderLabsWithouOffers();
    }

    const { consultationId } = this.props;
    return (
      <Query query={AVAILABLE_LABS_QUERY} variables={{ consultationId }}>
        {({ loading, error, data: { consultation: { labCategories } = {} } = {} }) =>
          this.renderLabsPanel(labCategories)
        }
      </Query>
    );
  }
}
LabPrescriptionContainer.propTypes = {
  consultSpecialtyName:     PropTypes.string.isRequired,
  fetchLabOrderSpecialties: PropTypes.func,
  memberId:                 PropTypes.number.isRequired,
  consultationId:           PropTypes.number.isRequired,
  preselectedData:          PropTypes.object.isRequired,
  stateCode:                PropTypes.string.isRequired,
  countryCode:              PropTypes.string.isRequired
};

export default LabPrescriptionContainer;
