import React from 'react';
import DataTable from '../../../data-table';
import {
  communicationMethodSelector,
  daysWaitingSelector,
  fullNameSelector,
  matchesForWaitlistTableActionsSelector,
  memberAvailabilitySelector,
  requestReasonSelector
} from '../selectors';

const COLUMNS = [
  {
    name: '',
    selector: communicationMethodSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.matches_for_waitlist_table.patient'),
    selector: fullNameSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.matches_for_waitlist_table.reason_for_visit'),
    selector: requestReasonSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.matches_for_waitlist_table.days_waiting'),
    selector: daysWaitingSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.matches_for_waitlist_table.patient_availability'),
    selector: memberAvailabilitySelector
  },
  {
    name: I18n.t('dashboard.consult_queues.matches_for_waitlist_table.actions.title'),
    selector: matchesForWaitlistTableActionsSelector
  }
];

const MatchesForWaitlistTable = ({ data }) => <DataTable columns={COLUMNS} data={data} />;

export default MatchesForWaitlistTable;
