import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Toggle } from '../../../../components';

const EditSameDayVisitsSection = ({ onSubmit, onCancel, isAcceptingSameDayVisits }) => {
  const handleSubmit = values => {
    onSubmit(values);
    onCancel();
  };

  return (
    <div className="edit-section">
      <Formik initialValues={{ toggleValue: isAcceptingSameDayVisits }} onSubmit={handleSubmit} enableReinitialize>
        {({ dirty }) => (
          <Form>
            <div className="section-column edit-form">
              <div className="section-row">
                <b className="field-name">{I18n.t('my_account.same_day_visits_section.same_day_visits_field')}</b>
                <Field name="toggleValue">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <div className="section-column">
                      <div className="section-row">
                        <Toggle
                          checked={value}
                          disableToggle={false}
                          onChange={() => setFieldValue('toggleValue', !value)}
                        />
                        <p>
                          {value
                            ? I18n.t('my_account.same_day_visits_section.toggle_on')
                            : I18n.t('my_account.same_day_visits_section.toggle_off')}
                        </p>
                      </div>
                      <p>{I18n.t('my_account.same_day_visits_section.toggle_description')}</p>
                    </div>
                  )}
                </Field>
              </div>
              <div className="section-row actions-section">
                <a className="secondary button" onClick={onCancel}>
                  {I18n.t('my_account.same_day_visits_section.cancel')}
                </a>
                <input type="submit" disabled={!dirty} value={I18n.t('my_account.same_day_visits_section.submit')} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

EditSameDayVisitsSection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isAcceptingSameDayVisits: PropTypes.bool.isRequired
};

export default EditSameDayVisitsSection;
