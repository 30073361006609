import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@td/shared_utils';

import UnavailableMember from './UnavailableMember';

const BusyMember = ({ retryCall, onCancelClick }) => (
  <React.Fragment>
    <UnavailableMember onCancelClick={onCancelClick} customText="member_is_busy" />

    <div className="text-center">
      <span>or </span>

      <span className="auto-dialer-link" onClick={retryCall}>
        <I18n scope="autodialer.call_interface.call_complete" text="call_again" />
      </span>
    </div>
  </React.Fragment>
);

BusyMember.propTypes = {
  retryCall:     PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default BusyMember;
