import React from 'react';
import PropTypes from 'prop-types';
import { map, reject } from 'lodash';
import moment from 'moment-timezone';
import { translate } from '@td/shared_utils';

// Icons and styles
import languageIcon from '../../assets/images/event_details/language.svg';
import '../styles/calendar-legend.scss';
import RecommendedWorkingHoursToggle from '../../recommended-working-hours/containers/recommended-working-hours-toggle';
import { useRecommendedWorkingHours } from '../../recommended-working-hours/contexts/recommended-working-hours-context';

const TRANSLATION_SCOPE = 'my_schedule.main.calendar.legend';

const CalendarLegend = ({ scheduleTypes }) => {
  const timezoneName = moment.tz.guess();
  const timezone = moment.tz(timezoneName).zoneAbbr();
  const filteredScheduleTypes = reject(scheduleTypes, ({ color, legendColor }) => !color || !legendColor);
  const { recommendedWorkingHoursIsEnabled } = useRecommendedWorkingHours();

  return (
    <React.Fragment>
      <div key="legendWrapper" className="legendWrapper">
        <span className="label">{translate(null, TRANSLATION_SCOPE, 'label')}</span>
        <div className="eventTypes" key="eventTypes">
          {map(filteredScheduleTypes, ({ label, legendColor }) => (
            <div className="eventType" key={label}>
              <span
                key={`type-marker-for-${label}`}
                className="eventMarker"
                style={{ background: legendColor }}
              />
              <span key={`type-label-for-${label}`} className="eventLabel">
                {label}
              </span>
            </div>
              )
            )}
        </div>
      </div>
      <div className="timezoneWrapper">
        <img className="languageIcon" src={languageIcon} role="presentation" />
        <div className="timezone">
          {translate(null, TRANSLATION_SCOPE, 'timezone')} <span>{timezone}</span>
        </div>
      </div>
      {recommendedWorkingHoursIsEnabled && (
        <RecommendedWorkingHoursToggle />
      )}
    </React.Fragment>
  );
};

CalendarLegend.propTypes = {
  scheduleTypes: PropTypes.objectOf(
    PropTypes.shape({
      eventTypeCode: PropTypes.string,
      label:         PropTypes.string,
      background:    PropTypes.string,
      border:        PropTypes.string,
      legendColor:   PropTypes.string
    })
  )
};

export default CalendarLegend;

