import React from 'react';
import PropTypes from 'prop-types';

import ProviderClockInOutButton from './provider-clock-in-out-button-container';

import { clockOutProvider } from '../actions';

export const ProviderCancelButtonContainer = ({ text, onClick }) => (
  <ProviderClockInOutButton className="provider-location-cancel-button" text={text} onClick={onClick} />
);

ProviderCancelButtonContainer.propTypes = {
  text:    PropTypes.string,
  onClick: PropTypes.func.isRequired
};

ProviderCancelButtonContainer.defaultProps = {
  text: 'Cancel'
};

export default ProviderCancelButtonContainer;
