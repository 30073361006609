export const units = [
  'app',
  'appful',
  'cap(s)',
  'dose',
  'ea',
  'g',
  'gtt',
  'gum',
  'in',
  'inh',
  'intl units',
  'kit',
  'I',
  'oz',
  'mcg',
  'meq',
  'mg',
  'ml',
  'mmol',
  'ng/kg',
  'patch',
  'pkt(s)',
  'puff(s)',
  'pump(s)',
  'spray(s)',
  'supp(s)',
  'tab(s)'
].map(value => ({ text: value, value }));
