import { bannerTypes } from '../../banners/constants';
import * as alertCodes from './alert-codes';
import * as screenTypes from './screen-types';

export const bannerTypeByAlertCode = {
  [alertCodes.EXTERNAL_CALENDAR_SYNC]: bannerTypes.WARNING,
  [alertCodes.EXTERNAL_CALENDAR_SYNC_SUCCESS]: bannerTypes.SUCCESS,
  [alertCodes.EXTERNAL_CALENDAR_DISCONNECTED]: bannerTypes.ERROR,
  [alertCodes.EXTERNAL_CALENDAR_ERROR]: bannerTypes.ERROR
};

export const allowedAlertCodesByScreenType = {
  [screenTypes.DASHBOARD]: [
    alertCodes.EXTERNAL_CALENDAR_SYNC_SUCCESS
  ],
  [screenTypes.MY_SCHEDULE]: [
    alertCodes.EXTERNAL_CALENDAR_SYNC,
    alertCodes.EXTERNAL_CALENDAR_SYNC_SUCCESS,
    alertCodes.EXTERNAL_CALENDAR_DISCONNECTED
  ],
  [screenTypes.WORKING_HOURS]: [
    alertCodes.EXTERNAL_CALENDAR_SYNC,
    alertCodes.EXTERNAL_CALENDAR_DISCONNECTED,
    alertCodes.EXTERNAL_CALENDAR_ERROR
  ],
};

export {
  alertCodes,
  screenTypes
};
