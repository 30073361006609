export const DIRECTIONS = [
  'every 2 minutes',
  'every 2 to 5 minutes',
  'every 2 to 3 minutes',
  'every 3 minutes',
  'every 4 minutes',
  'every 5 to 15 minutes',
  'every 5 minutes',
  'every 5 to 10 minutes',
  'every 6 to 15 minutes',
  'every 10 to 30 minutes',
  'every 10 to 15 minutes',
  'every 10 minutes',
  'every 15 minutes',
  'every 20 minutes',
  'every 25 minutes',
  'every 25 to 60 minutes',
  'every 30 minutes',
  'every 30 to 60 minutes',
  'every hour',
  'every 1 to 2 hours',
  '16 times a day',
  'every 90 minutes',
  '12 times a day',
  'every 2 hours',
  'every 2 to 4 hours',
  'every 2 to 3 hours',
  '10 times a day',
  '9 times a day',
  'every 3 to 5 hours',
  'every 3 hours',
  'every 3 to 6 hours',
  'every 3 to 4 hours',
  '8 times a day',
  '7 times a day',
  'every 4 to 8 hours',
  '4 to 6 times a day',
  'every 4 hours',
  'every 4 to 6 hours',
  '6 times a day',
  '5 times a day',
  'every 4 hours (5 times/day)',
  'every 4 hours while awake',
  'every 5 hours',
  '4 times a day',
  'every 6 to 8 hours',
  'every 6 hours',
  '4 times a day (before meals and at bedtime)',
  '3 to 4 times a day',
  '4 times a day (with meals and at bedtime)',
  '2 to 4 times a day',
  '4 times a day (after meals and at bedtime)',
  '1 to 4 times a day',
  '3 times a day (before meals)',
  '3 times a day',
  'every 8 to 12 hours',
  '1 to 3 times a day',
  '3 times a day (with meals)',
  '3 times a day (after meals)',
  'every 8 hours',
  '3 times',
  'every 10 hours',
  '2 times a day (before meals)',
  '1 to 2 times a day',
  'twice',
  '2 times a day (after meals)',
  '2 times a day (with meals)',
  'every 12 hours',
  '2 times a day',
  'in the morning and at bedtime',
  'every 16 hours',
  'every 18 hours',
  'once a day (in the evening)',
  'once a day (in the morning)',
  'once',
  'once a day',
  'every 24 hours',
  'once (at bedtime)',
  'now',
  'once a day (before a meal)',
  'once a day (after a meal)',
  'once a day (at bedtime)',
  '2 times a day (3 days a week)',
  '6 times a week',
  'monday through friday',
  '5 times a week',
  'every 36 hours',
  'every 40 hours',
  '4 times a week',
  'every other day',
  'every other day (at bedtime)',
  'every 48 hours',
  '3 to 4 times a week',
  'monday, wednesDay, and friday',
  'tuesday, thursDay, satuRday',
  '3 times a week',
  'every 60 hours',
  'every 3 to 4 days',
  'every 72 hours',
  'every 3 days',
  '2 times a week',
  'every 96 hours',
  'every 4 days',
  'every 5 days',
  'every 6 days',
  'once a week',
  'every 7 days',
  'every other week',
  'every 2 weeks',
  '2 times a month',
  'every 3 to 4 weeks',
  'every 3 weeks',
  'every 4 weeks',
  'once a month',
  'every 6 weeks',
  'every 8 weeks',
  'every 2 months',
  'every 10 weeks',
  'every 3 months',
  'every 3 to 6 months',
  'every 4 months',
  'every 5 to 8 months',
  'every 6 months',
  'yearly'
];
