import React, { Fragment, useMemo } from 'react';
import ConsultQueuesTooltip from '../ConsultQueuesTooltip';
import { useData } from '../../../DataProvider';
import moment from 'moment';
import { get } from 'lodash';

const StartAction = ({ communicationMethod, allowStartInterval, serviceSpecialtyCd, consultationId, scheduledDate, requestDate }) => {
  const { clockedIn, clockInType, customStartInterval } = useData();

  const startInterval = useMemo(() => {
    if (customStartInterval.enabled) {
      return get(customStartInterval.data, serviceSpecialtyCd, allowStartInterval);
    }

    return allowStartInterval;
  }, [allowStartInterval, customStartInterval, serviceSpecialtyCd]);

  const providerClockedInToVideo = clockInType === 'Video';
  const providerClockedInToNormal = clockInType === 'Phone';
  const isMessageCenter = communicationMethod === 'Message Center';
  const isPhone = communicationMethod === 'Phone';

  const providerIsClokedIn = () => {
    if (isMessageCenter && clockedIn) return true;
    return providerClockedInToVideo || (isPhone && providerClockedInToNormal);
  };

  const showClockedInStatus = providerClockedInToVideo
    ? ''
    : providerClockedInToNormal
    ? I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.start_action.not_cloked_in_for_video')
    : I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.start_action.not_cloked_in');

  const consutationCanBeStarted = () => {
    if (!scheduledDate) return true;
    if (startInterval !== undefined) return moment(requestDate).subtract(startInterval, 'seconds') < moment();
  };

  if (!providerIsClokedIn()) {
    return (
      <Fragment>
        <a className="select_consult disabled gray" data-tip={showClockedInStatus}>
          {I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.start')}
        </a>
        <ConsultQueuesTooltip />
      </Fragment>
    );
  }

  if (consutationCanBeStarted()) {
    return (
      <a
        href={`/consultations/${consultationId}/init_review?load_ease=true`}
        className="select_consult"
        data-disable-with={I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.start_action.loading')}
      >
        {I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.start')}
      </a>
    );
  } else {
    return (
      <Fragment>
        <a
          className="select_consult disabled gray"
          data-tip={I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.start_action.not_ready', {
            allow_start_interval: Math.floor(startInterval / 60),
            unit:                 'minutes'
          })}
        >
          {I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.start')}
        </a>
        <ConsultQueuesTooltip />
      </Fragment>
    );
  }
};

export default StartAction;
