import React from 'react';
import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const ModalActionBar = ({ children }) => (
  <div className={styles.externalCalendarModalActionBar}>
    {children}
  </div>
);

ModalActionBar.propTypes = {
  children: PropTypes.node.isRequired
};

export default ModalActionBar;
