import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaVideo, FaPhone } from 'react-icons/fa';
import includes from 'lodash/includes';
import ProviderClockInType from 'app/Provider/enums/provider-clock-in-type';

const phoneClockInTypes = [ProviderClockInType.PHONE, ProviderClockInType.VIDEO];

const ProviderClockInOutStatusTypes = ({ videoClockInAllowed, phoneClockInAllowed, clockInType }) => (
  <div className="clock-in-out-types">
    {videoClockInAllowed && (
      <FaVideo
        className={classNames('clock-in-out-type', {
          'clocked-in-type':  clockInType === ProviderClockInType.VIDEO,
          'clocked-out-type': clockInType !== ProviderClockInType.VIDEO
        })}
      />
    )}
    {phoneClockInAllowed && (
      <React.Fragment>
        <FaPhone
          className={classNames('clock-in-out-type', 'clock-in-out-phone', {
            'clocked-in-type':  includes(phoneClockInTypes, clockInType),
            'clocked-out-type': !includes(phoneClockInTypes, clockInType)
          })}
        />
      </React.Fragment>
    )}
  </div>
);

ProviderClockInOutStatusTypes.propTypes = {
  videoClockInAllowed: PropTypes.bool.isRequired,
  phoneClockInAllowed: PropTypes.bool.isRequired,
  clockInType:         PropTypes.string
};

export default ProviderClockInOutStatusTypes;
