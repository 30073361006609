import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import UPDATE_PATIENTS_LIMIT from '../../mutations/update_patients_limit.graphql';
import CREATE_PROVIDER_PANEL_EXTENSION from '../../mutations/create_provider_panel_extension.graphql';
import TeladocModal from 'app/TeladocModal';
import InnerContent from './inner-content';
import PropTypes from 'prop-types';
import { MODAL } from '../../constants';

const PatientsLimitModal = ({ modal: { modalData, setModalData }, toggle, setShowNotification }) => {
  const toggleOn = toggle.toggleData.acceptingNewPatients;

  const [tempModalData, setTempModalData] = useState(modalData);

  useEffect(() => setTempModalData(modalData), [modalData]);

  const [updatePatientsLimit] = useMutation(UPDATE_PATIENTS_LIMIT);
  const [createProviderPanelExtension] = useMutation(CREATE_PROVIDER_PANEL_EXTENSION);

  const onClose = () => {
    setModalData(data => ({ ...data, displayModal: false }));
  };

  const onSave = () => {
    if (!modalData.practiceTeamParticipantId) {
      createProviderPanelExtension({
        variables: {
          providerId:           modalData.providerId,
          practiceTeamId:       modalData.practiceTeamId,
          maxSize:              tempModalData.overallPatientsLimit,
          dailyNewPatientLimit: tempModalData.dailyNewPatientLimit
        }
      });
    } else {
      updatePatientsLimit({
        variables: {
          practiceTeamParticipantId: modalData.practiceTeamParticipantId,
          maxSize:                   tempModalData.overallPatientsLimit,
          dailyNewPatientLimit:      tempModalData.dailyNewPatientLimit
        }
      });
    }
    setModalData(data => ({ ...data, ...tempModalData, displayModal: false }));
    setShowNotification(true);
  };

  const actions = [
    { className: '', primary: false, onClick: onClose, disabled: !toggleOn, label: MODAL.cancel },
    { className: '', primary: true, onClick: onSave, disabled: !toggleOn, label: MODAL.save }
  ];

  return (
    <TeladocModal
      className={'modal-styles'}
      isOpen={modalData.displayModal}
      onClose={onClose}
      title={MODAL.title}
      actions={actions}
      children={
        <InnerContent
          modal={{tempModalData, setTempModalData}}
          toggle={toggle}
        />
      }
    />
  );
};

PatientsLimitModal.propTypes = {
  toggle: PropTypes.shape({
    toggleData: PropTypes.shape({
      acceptingNewPatients:      PropTypes.bool,
      tooltipMessage:            PropTypes.array,
    }),
    updateToggleValue:           PropTypes.func
  }),
  modal: PropTypes.shape({
    modalData: PropTypes.shape({
      role:                      PropTypes.string,
      providerId:                PropTypes.number,
      displayModal:              PropTypes.bool,
      defaultOverall:            PropTypes.number,
      defaultDaily:              PropTypes.number,
      overallPatientsLimit:      PropTypes.number,
      dailyNewPatientLimit:      PropTypes.number,
      practiceTeamParticipantId: PropTypes.string,
      practiceTeamId:            PropTypes.string,
      currentPatients:           PropTypes.number
    }),
    setModalData:                PropTypes.func
  }),
  setShowNotification:           PropTypes.func
};

export default PatientsLimitModal;
