import { isEmpty } from 'lodash';

const DEFAULT_OPEN_HOUR = 0;
const DEFAULT_CLOSE_HOUR = 23;

export const businessOpenTimeHour = (availableTimes, date) => {
  if (isEmpty(availableTimes)) {
    return DEFAULT_OPEN_HOUR;
  }
  return new Date ( (date || new Date()).toDateString() + ' ' + availableTimes[0]).getHours();
}

export const businessCloseTimeHour = (availableTimes, date) => {
  if (isEmpty(availableTimes)) {
    return DEFAULT_CLOSE_HOUR;
  }
  return new Date ( (date || new Date()).toDateString() + ' ' + availableTimes[availableTimes.length - 1]).getHours();
}
