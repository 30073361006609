import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  Dropdown,
  Field,
  makeStyles,
  Option,
  Textarea
} from '@fluentui/react-components';
import { Form } from 'formik';
import { translate } from '@td/shared_utils';
import { FormikContext } from '../contexts';
import { FIELDS } from '../constants';

const useStyles = makeStyles({
  reasonField: {
    marginRight: '25%'
  }
});

const ModalContent = ({cancelReasonsOptions}) => {
  const { touched, errors, setFieldValue, handleBlur, handleChange, cancelInProgress } = useContext(FormikContext);
  const fluentStyles = useStyles();

  return (
    <DialogContent>
      <Form noValidate>
        <Field
          required
          label={translate(null, 'consultation.cancel.modal.fields.reason.label')}
          validationMessage={touched[FIELDS.REASON] && errors[FIELDS.REASON]}
          className={fluentStyles.reasonField}
        >
          <Dropdown
            name={FIELDS.REASON}
            onOptionSelect={(e, data) => {
              setFieldValue('reason', data.optionValue);
            }}
            placeholder={translate(null, 'consultation.cancel.modal.fields.reason.placeholder')}
            onBlur={handleBlur}
            disabled={cancelInProgress}
          >
            {cancelReasonsOptions.map(({value, text}) => (
              <Option key={value} value={value} text={text}>
                {text}
              </Option>
            ))}
          </Dropdown>
        </Field>
        <Field
          required
          label={translate(null, 'consultation.cancel.modal.fields.notes.label')}
          validationMessage={touched[FIELDS.NOTES] && errors[FIELDS.NOTES]}
        >
          <Textarea
            name={FIELDS.NOTES}
            resize="vertical"
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={cancelInProgress}
          />
        </Field>
      </Form>
    </DialogContent>
  );
};

ModalContent.propTypes = {
  cancelReasonsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  )
};

export default ModalContent;
