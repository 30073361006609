import React from "react";
import PropTypes from "prop-types";
import { I18n } from "@td/shared_utils";
import { TRAVEL_MED_CDC_URL, MEDICAL_CODES_FOR_CDC_GUIDELINES } from "../../utilities/constants/cdc-constants";

const CdcRecommendedGuideline = React.memo(({ medicalCodeServiceSpecialtyRelation: { medicalCode } }) => {
  if (!MEDICAL_CODES_FOR_CDC_GUIDELINES.includes(medicalCode)) {
    return null;
  }

  return (
    <p>
      <a
        target="_blank"
        href={TRAVEL_MED_CDC_URL}
      >
        <I18n scope="consultation.cdc_recommended_guideline" />
      </a>
    </p>
  )
});

CdcRecommendedGuideline.propTypes = {
  medicalCodeServiceSpecialtyRelation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    medicalCode: PropTypes.string.isRequired,
    specialtyCd: PropTypes.string,
  }),
};

export default CdcRecommendedGuideline;
