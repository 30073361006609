import React from 'react';
import './ProposedDatesTooltip.scss';

const ProposedDatesTooltip = React.memo(({ proposedDates }) => {
  const convertUtcToLocal = utcTimeString => {
    const utcDate = new Date(utcTimeString);
    const localTime = utcDate.toLocaleString(undefined, {
      timeZone: 'UTC'
    });
    return localTime;
  };

  return (
    <div className="tooltipWrapper">
      <p className="title">Proposed dates</p>
      <ol className="proposedDates">
        {proposedDates && proposedDates.map((date, index) => (
          <li className="proposedDate" key={index}>
            {`${index + 1}) `}
            <span className="browser_timestamp">{convertUtcToLocal(date)}</span>
          </li>
        ))}
      </ol>
    </div>
  );
});

export default ProposedDatesTooltip;
