const useSelectedTeladocServices = (state, setState) => {
  const findServiceIndex = serviceCode =>
    state.findIndex(selectedService => selectedService.serviceCode === serviceCode);

  const onItemClick = (serviceCode, featureCode = undefined) => {
    const thisServiceIndex = findServiceIndex(serviceCode);
    const isSelectedAlready = thisServiceIndex >= 0;
    if (!isSelectedAlready) {
      setState([...state, { serviceCode, featureCode }]);
    } else if (featureCode) {
      const newValue = [...state];
      newValue[thisServiceIndex] = { serviceCode, featureCode };
      setState(newValue);
    } else {
      setState(state.filter(s => s.serviceCode !== serviceCode));
    }
  };

  const isSelected = (serviceCode, featureCode = undefined) =>
    state.some(s => {
      if (featureCode === undefined) return s.serviceCode === serviceCode;
      else {
        return s.serviceCode === serviceCode && s.featureCode === featureCode;
      }
    });

  return { state, onItemClick, isSelected };
};

export default useSelectedTeladocServices;
