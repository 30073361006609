import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TeladocModal from '../../../../../TeladocModal';
import styles from './styles.module.scss';

const Modal = ({ title, children, onClose, className }) => (
  <TeladocModal
    className={cx(styles.externalCalendarsModal, className)}
    title={title}
    isOpen
    onClose={onClose}
  >
    {children}
  </TeladocModal>
);

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default Modal;
