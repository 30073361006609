// Constants
const FETCH_QUICK_RX_BY_PROVIDER = 'FETCH_QUICK_RX_BY_PROVIDER';
const FETCH_QUICK_RX_BY_PROVIDER_START = 'FETCH_QUICK_RX_BY_PROVIDER_START';
const FETCH_QUICK_RX_BY_PROVIDER_SUCCESS = 'FETCH_QUICK_RX_BY_PROVIDER_SUCCESS';
const FETCH_QUICK_RX_BY_PROVIDER_FAIL = 'FETCH_QUICK_RX_BY_PROVIDER_FAIL';
const FETCH_QUICK_RX_BY_PROVIDER_CANCEL = 'FETCH_QUICK_RX_BY_PROVIDER_CANCEL';
const REMOVE_QUICK_RX_BY_PROVIDER_SUCCESS = 'REMOVE_QUICK_RX_BY_PROVIDER_SUCCESS';
const REMOVE_QUICK_RX_BY_PROVIDER_FAIL = 'REMOVE_QUICK_RX_BY_PROVIDER_FAIL';
const REMOVE_QUICK_RX_BY_PROVIDER_START = 'REMOVE_QUICK_RX_BY_PROVIDER_START';
const REMOVE_QUICK_RX_BY_PROVIDER = 'REMOVE_QUICK_RX_BY_PROVIDER';
const SAVE_QUICK_RX_BY_PROVIDER_START = 'SAVE_QUICK_RX_BY_PROVIDER_START';
const SAVE_QUICK_RX_BY_PROVIDER_SUCCESS = 'SAVE_QUICK_RX_BY_PROVIDER_SUCCESS';
const SAVE_QUICK_RX_BY_PROVIDER_FAIL = 'SAVE_QUICK_RX_BY_PROVIDER_FAIL';
const SAVE_QUICK_RX_BY_PROVIDER = 'SAVE_QUICK_RX_BY_PROVIDER';
const FETCH_MEDICATION_INFO = 'FETCH_MEDICATION_INFO';
const FETCH_MEDICATION_INFO_START = 'FETCH_MEDICATION_INFO_START';
const FETCH_MEDICATION_INFO_SUCCESS = 'FETCH_MEDICATION_INFO_SUCCESS';
const FETCH_MEDICATION_INFO_FAIL = 'FETCH_MEDICATION_INFO_FAIL';
const FETCH_MEDICATION_INFO_CANCEL = 'FETCH_MEDICATION_INFO_CANCEL';
const FETCH_MEDICATION_FORM = 'FETCH_MEDICATION_FORM';
const FETCH_MEDICATION_FORM_START = 'FETCH_MEDICATION_FORM_START';
const FETCH_MEDICATION_FORM_SUCCESS = 'FETCH_MEDICATION_FORM_SUCCESS';
const FETCH_MEDICATION_FORM_FAIL = 'FETCH_MEDICATION_FORM_FAIL';
const FETCH_MEDICATION_FORM_CANCEL = 'FETCH_MEDICATION_FORM_CANCEL';
const FETCH_COMMON_DOSAGE = 'FETCH_COMMON_DOSAGE';
const FETCH_COMMON_DOSAGE_START = 'FETCH_COMMON_DOSAGE_START';
const FETCH_COMMON_DOSAGE_SUCCESS = 'FETCH_COMMON_DOSAGE_SUCCESS';
const FETCH_COMMON_DOSAGE_FAIL = 'FETCH_COMMON_DOSAGE_FAIL';
const FETCH_COMMON_DOSAGE_CANCEL = 'FETCH_COMMON_DOSAGE_CANCEL';
const FETCH_MEDICATION_BY_SPECIALITY = 'FETCH_MEDICATION_BY_SPECIALITY';
const FETCH_MEDICATION_BY_SPECIALITY_START = 'FETCH_MEDICATION_BY_SPECIALITY_START';
const FETCH_MEDICATION_BY_SPECIALITY_SUCCESS = 'FETCH_MEDICATION_BY_SPECIALITY_SUCCESS';
const FETCH_MEDICATION_BY_SPECIALITY_FAIL = 'FETCH_MEDICATION_BY_SPECIALITY_FAIL';
const FETCH_MEDICATION_BY_SPECIALITY_CANCEL = 'FETCH_MEDICATION_BY_SPECIALITY_CANCEL';
const RESET_MEDICATION_BY_SPECIALITY = 'RESET_MEDICATION_BY_SPECIALITY';
const FETCH_PRESCRIPTION_CODE = 'FETCH_PRESCRIPTION_CODE';
const FETCH_PRESCRIPTION_CODE_START = 'FETCH_PRESCRIPTION_CODE_START';
const FETCH_PRESCRIPTION_CODE_SUCCESS = 'FETCH_PRESCRIPTION_CODE_SUCCESS';
const FETCH_PRESCRIPTION_CODE_FAIL = 'FETCH_PRESCRIPTION_CODE_FAIL';
const FETCH_PRESCRIPTION_CODE_CANCEL = 'FETCH_PRESCRIPTION_CODE_CANCEL';
const SUBMIT_PRESCRIPTIONS_POST_CONSULT = 'SUBMIT_PRESCRIPTIONS_POST_CONSULT';
const SUBMIT_PRESCRIPTIONS_POST_CONSULT_SUCCESS = 'SUBMIT_PRESCRIPTIONS_POST_CONSULT_SUCCESS';
const SUBMIT_PRESCRIPTION_FORM = 'SUBMIT_PRESCRIPTION_FORM';
const SUBMIT_PRESCRIPTION_FORM_START = 'SUBMIT_PRESCRIPTION_FORM_START';
const SUBMIT_PRESCRIPTION_FORM_SUCCESS = 'SUBMIT_PRESCRIPTION_FORM_SUCCESS';
const SUBMIT_PRESCRIPTION_FORM_FAIL = 'SUBMIT_PRESCRIPTION_FORM_FAIL';
const SUBMIT_PRESCRIPTION_FORM_CANCEL = 'SUBMIT_PRESCRIPTION_FORM_CANCEL';
const RESET_PRESCRIPTION_FORM = 'RESET_PRESCRIPTION_FORM';
const ADD_TO_PENDING_PRESCRIPTION_LIST = 'ADD_TO_PENDING_PRESCRIPTION_LIST';
const UPSERT_TO_PENDING_PRESCRIPTION_LIST = 'UPSERT_TO_PENDING_PRESCRIPTION_LIST';
const UPDATE_TO_PENDING_PRESCRIPTION_LIST = 'UPDATE_TO_PENDING_PRESCRIPTION_LIST';
const REMOVE_FROM_PENDING_PRESCRIPTION_LIST = 'REMOVE_FROM_PENDING_PRESCRIPTION_LIST';
const CONFIRM_PENDING_PRESCRIPTIONS_LIST = 'CONFIRM_PENDING_PRESCRIPTIONS_LIST';
const CLEAR_PENDING_PRESCRIPTION_LIST = 'CLEAR_PENDING_PRESCRIPTION_LIST';
const TOGGLE_DISPLAY_STATE = 'TOGGLE_DISPLAY_STATE';

export {
  FETCH_QUICK_RX_BY_PROVIDER,
  FETCH_QUICK_RX_BY_PROVIDER_START,
  FETCH_QUICK_RX_BY_PROVIDER_SUCCESS,
  FETCH_QUICK_RX_BY_PROVIDER_FAIL,
  FETCH_QUICK_RX_BY_PROVIDER_CANCEL,
  REMOVE_QUICK_RX_BY_PROVIDER,
  REMOVE_QUICK_RX_BY_PROVIDER_START,
  REMOVE_QUICK_RX_BY_PROVIDER_SUCCESS,
  REMOVE_QUICK_RX_BY_PROVIDER_FAIL,
  SAVE_QUICK_RX_BY_PROVIDER_START,
  SAVE_QUICK_RX_BY_PROVIDER_SUCCESS,
  SAVE_QUICK_RX_BY_PROVIDER_FAIL,
  SAVE_QUICK_RX_BY_PROVIDER,
  FETCH_MEDICATION_INFO,
  FETCH_MEDICATION_INFO_START,
  FETCH_MEDICATION_INFO_SUCCESS,
  FETCH_MEDICATION_INFO_FAIL,
  FETCH_MEDICATION_INFO_CANCEL,
  FETCH_MEDICATION_FORM,
  FETCH_MEDICATION_FORM_START,
  FETCH_MEDICATION_FORM_SUCCESS,
  FETCH_MEDICATION_FORM_FAIL,
  FETCH_MEDICATION_FORM_CANCEL,
  FETCH_COMMON_DOSAGE,
  FETCH_COMMON_DOSAGE_START,
  FETCH_COMMON_DOSAGE_SUCCESS,
  FETCH_COMMON_DOSAGE_FAIL,
  FETCH_COMMON_DOSAGE_CANCEL,
  FETCH_MEDICATION_BY_SPECIALITY,
  FETCH_MEDICATION_BY_SPECIALITY_START,
  FETCH_MEDICATION_BY_SPECIALITY_SUCCESS,
  FETCH_MEDICATION_BY_SPECIALITY_FAIL,
  FETCH_MEDICATION_BY_SPECIALITY_CANCEL,
  RESET_MEDICATION_BY_SPECIALITY,
  FETCH_PRESCRIPTION_CODE,
  FETCH_PRESCRIPTION_CODE_START,
  FETCH_PRESCRIPTION_CODE_SUCCESS,
  FETCH_PRESCRIPTION_CODE_FAIL,
  FETCH_PRESCRIPTION_CODE_CANCEL,
  SUBMIT_PRESCRIPTIONS_POST_CONSULT,
  SUBMIT_PRESCRIPTIONS_POST_CONSULT_SUCCESS,
  SUBMIT_PRESCRIPTION_FORM,
  SUBMIT_PRESCRIPTION_FORM_START,
  SUBMIT_PRESCRIPTION_FORM_SUCCESS,
  SUBMIT_PRESCRIPTION_FORM_FAIL,
  SUBMIT_PRESCRIPTION_FORM_CANCEL,
  RESET_PRESCRIPTION_FORM,
  ADD_TO_PENDING_PRESCRIPTION_LIST,
  UPSERT_TO_PENDING_PRESCRIPTION_LIST,
  UPDATE_TO_PENDING_PRESCRIPTION_LIST,
  REMOVE_FROM_PENDING_PRESCRIPTION_LIST,
  CONFIRM_PENDING_PRESCRIPTIONS_LIST,
  CLEAR_PENDING_PRESCRIPTION_LIST,
  TOGGLE_DISPLAY_STATE
};
