const CALL_OPTUMNURSELINE  = 'conference/CALL_OPTUMNURSELINE';
const CONSULTATION_EVENT  = 'consultation/EVENT';
const CREATE_CONFERENCE_STATISTICS = 'conference/CREATE_CONFERENCE_STATISTICS';
const CREATE_AUTOMATED_CALL = 'conference/CREATE_AUTOMATED_CALL';

export {
  CALL_OPTUMNURSELINE,
  CONSULTATION_EVENT,
  CREATE_CONFERENCE_STATISTICS,
  CREATE_AUTOMATED_CALL
};
