import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';
import Divider from '../divider';
import Modal from '../modal';
import ModalAction from '../modal-action';
import ModalActionBar from '../modal-action-bar';

const ConfirmationModal = ({
  title,
  children,
  onConfirm,
  onCancel,
  onClose,
  cancelButtonText,
  confirmButtonText,
  className
}) => (
  <Modal
    className={cx(styles.confirmationModal, className)}
    title={title}
    onClose={onClose}
  >
    <div className={styles.confirmationModalContent}>
      <div
        className={styles.confirmationModalSubtitle}
        data-testid="subtitle"
      >
        {children}
      </div>

      <Divider />

      <ModalActionBar>
        <ModalAction variant="secondary" onClick={onCancel}>
          {cancelButtonText}
        </ModalAction>
        <ModalAction variant="primary" onClick={onConfirm}>
          {confirmButtonText}
        </ModalAction>
      </ModalActionBar>
    </div>
  </Modal>
);

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  className: PropTypes.string
};

export default ConfirmationModal;
