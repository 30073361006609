import { from, of, EMPTY, interval, merge, timer } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  map,
  mergeMap,
  mapTo,
  filter,
  switchMap,
  first,
  takeUntil,
  debounce,
  startWith,
  delay,
  catchError
} from 'rxjs/operators';
import get from 'lodash/get';
import every from 'lodash/every';
import includes from 'lodash/includes';
import { combineEpics } from 'redux-observable';
import { teladocApi, authToken } from '@td/api';

import {
  FETCH_PROVIDER_REQUEST,
  FETCH_PROVIDER_CANCEL,
  TRACK_FOR_CLOCK_OUT,
  TRACK_CONSULT_INACTIVITY,
  TRACK_LOCKED_CONSULT,
  TRACK_IN_REVIEW_CONSULT,
  START_REVIEW,
  EXTEND_EHR_REVIEW,
  LOG_ALERT_ACKNOWLEDGEMENT
} from './actionTypes';

import {
  fetchProviderStart,
  fetchProviderSuccess,
  fetchProviderFail,
  trackLockedConsult,
  trackInReviewConsult,
  startReview,
  extendEhrReview,
  logAlertAcknowledgement
} from './actions';
import { LAST_ACTIVE_TIME_UPDATED } from 'app/providerActiveStatus/action-types';
import getProviderId from './selectors/getProviderId';
import getConsultationHasStarted from 'app/Consult/selectors/getConsultationHasStarted';
import { redirectToHome, redirectToRelative, getTimeToAlert } from 'app/lib/utils';
import { showAlert } from 'app/components/alert/actions';

const buildFetchProviderRequest = payload => {
  const { url, params, token } = payload;
  return from(teladocApi.get(url, params, token));
};

export const fetchProviderEpic = action$ =>
  action$.pipe(
    ofType(FETCH_PROVIDER_REQUEST),
    mergeMap(action =>
      buildFetchProviderRequest(action.payload).pipe(
        map(response => fetchProviderSuccess(response)),
        takeUntil(action$.pipe(ofType(FETCH_PROVIDER_CANCEL))),
        startWith(fetchProviderStart()),
        catchError(error => of(fetchProviderFail(error)))
      )
    )
  );

const getAlertTemplate$ = alertType => from(teladocApi.get(`/v4/ref_alerts/${alertType}`, null, authToken.get()));

const PROVIDER_CLOCK_OUT_CHECK_DEBOUNCE_TIME = 30 * 1000;
const PROVIDER_CLOCK_OUT_ALERT_TYPE = 'provider_clock_out';

const checkProviderBeingTrackedForClockOut$ = state$ =>
  from(
    teladocApi.get(`/v4/providers/${getProviderId(state$.value)}/tracked_for_clock_out`, null, authToken.get())
  ).pipe(map(result => get(result, ['data', 'tracked_for_clock_out'])));

const hasProviderBeenClockedOut = clockStatusData =>
  every(get(clockStatusData, ['data', 'providers']), clockInType => get(clockInType, 'in_out_flg') === 'N');

const checkProviderClockStatus$ = state$ =>
  from(teladocApi.get(`/v4/providers/${getProviderId(state$.value)}/clock_status`, null, authToken.get())).pipe(
    filter(hasProviderBeenClockedOut),
    switchMap(() => {
      redirectToHome();

      return EMPTY;
    })
  );

const checkProviderClockStatusOnActivity$ = (action$, state$) =>
  action$.pipe(
    ofType(LAST_ACTIVE_TIME_UPDATED),
    first(),
    switchMap(() => checkProviderClockStatus$(state$))
  );

const pollProviderClockStatus$ = (action$, state$) =>
  interval(PROVIDER_CLOCK_OUT_CHECK_DEBOUNCE_TIME).pipe(
    switchMap(() => checkProviderClockStatus$(state$)),
    takeUntil(action$.pipe(ofType(LAST_ACTIVE_TIME_UPDATED)))
  );

const logProviderClockOutAlertEvent$ = state$ =>
  from(
    teladocApi.post(
      `/v4/providers/${getProviderId(state$.value)}/alerts`,
      {
        alert_type: PROVIDER_CLOCK_OUT_ALERT_TYPE
      },
      authToken.get()
    )
  ).pipe(
    switchMap(({ data: { alert: { alert_id: alertId } } }) => [alertId])
  );

const showProviderClockOutAlert$ = state$ =>
  getAlertTemplate$(PROVIDER_CLOCK_OUT_ALERT_TYPE).pipe(
    switchMap(alertTemplate =>
      logProviderClockOutAlertEvent$(state$).pipe(
        map(alertId =>
          showAlert({
            message:         get(alertTemplate, ['data', 'ref_alert', 'alert_text']),
            dispatchOnClose: [logAlertAcknowledgement({ alertId })]
          })
        )
      )
    )
  );

export const providerInactivityClockOutEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRACK_FOR_CLOCK_OUT),
    switchMap(({ payload: { clockOutAlertTiming } }) =>
      action$.pipe(
        ofType(LAST_ACTIVE_TIME_UPDATED),
        debounce(({ payload: { lastActiveTime } }) => timer(getTimeToAlert(lastActiveTime, clockOutAlertTiming))),
        switchMap(() => checkProviderBeingTrackedForClockOut$(state$)),
        filter(trackedForClockOut => trackedForClockOut),
        switchMap(() =>
          merge(
            checkProviderClockStatusOnActivity$(action$, state$),
            pollProviderClockStatus$(action$, state$),
            showProviderClockOutAlert$(state$)
          )
        )
      )
    )
  );

const CONSULT_UNLOCK_CHECK_DEBOUNCE_TIME = 30 * 1000;
const CONSULT_UNLOCK_LOCKED_ALERT_TYPE = 'consult_locked';
const CONSULT_UNLOCK_IN_REVIEW_ALERT_TYPE = 'consult_ehr_review';

const EHR_REVIEW_EXTEND_EVENT_NAME = 'EHR Review Extend';

const hasConsultBeenUnlocked = ajaxResult => {
  const consultStatus = get(ajaxResult, ['data', 'consult_status', 'consult_status']);
  return !includes(['Locked', 'EHR Review', 'Started'], consultStatus);
};

const getConsultStatus$ = consultationId =>
  from(teladocApi.get(`/v4/consultations/${consultationId}/consult_status`, null, authToken.get()));

const pollConsultUnlockStatus$ = (action$, consultationId) =>
  interval(CONSULT_UNLOCK_CHECK_DEBOUNCE_TIME).pipe(
    switchMap(() => getConsultStatus$(consultationId)),
    filter(hasConsultBeenUnlocked),
    switchMap(() => {
      redirectToHome();

      return EMPTY;
    }),
    takeUntil(action$.pipe(ofType(EXTEND_EHR_REVIEW)))
  );

const populateConsultUnlockTemplate = (message, data) => {
  const { consultationId, memberName } = data;

  const templateData = {
    ConsultID: consultationId,
    PatientNM: memberName
  };

  return message.replace(/<(\w+)>/gi, (_, element) => templateData[element]);
};

const logLockedConsultAlertEvent$ = state$ =>
  from(
    teladocApi.post(
      `/v4/providers/${getProviderId(state$.value)}/alerts`,
      {
        alert_type: CONSULT_UNLOCK_LOCKED_ALERT_TYPE
      },
      authToken.get()
    )
  ).pipe(
    switchMap(({ data: { alert: { alert_id: alertId } } }) => [alertId])
  );

const logInReviewConsultAlertEvent$ = state$ =>
  from(
    teladocApi.post(
      `/v4/providers/${getProviderId(state$.value)}/alerts`,
      {
        alert_type: CONSULT_UNLOCK_IN_REVIEW_ALERT_TYPE
      },
      authToken.get()
    )
  ).pipe(
    switchMap(({ data: { alert: { alert_id: alertId } } }) => [alertId])
  );

const showLockedConsultUnlockAlert$ = (payload, state$) =>
  getAlertTemplate$(CONSULT_UNLOCK_LOCKED_ALERT_TYPE).pipe(
    switchMap(alertTemplate =>
      logLockedConsultAlertEvent$(state$).pipe(
        map(alertId => {
          const messageTemplate = get(alertTemplate, ['data', 'ref_alert', 'alert_text']);

          return showAlert({
            message:         populateConsultUnlockTemplate(messageTemplate, payload),
            dispatchOnClose: [startReview({ ...payload, alertId })]
          });
        })
      )
    )
  );

const showInReviewConsultUnlockAlert$ = (payload, state$) =>
  getAlertTemplate$(CONSULT_UNLOCK_IN_REVIEW_ALERT_TYPE).pipe(
    switchMap(alertTemplate =>
      logInReviewConsultAlertEvent$(state$).pipe(
        map(alertId => {
          const messageTemplate = get(alertTemplate, ['data', 'ref_alert', 'alert_text']);

          return showAlert({
            message:         populateConsultUnlockTemplate(messageTemplate, payload),
            dispatchOnClose: [extendEhrReview({ ...payload, alertId })]
          });
        })
      )
    )
  );

const sendExtendEhrReviewRequest$ = consultationId =>
  from(
    teladocApi.post(
      `/v4/consultations/${consultationId}/consultation_events`,
      {
        event_name: EHR_REVIEW_EXTEND_EVENT_NAME
      },
      authToken.get()
    )
  );

const getCurrentlyLockedConsult$ = state$ =>
  from(teladocApi.get(`/v4/providers/${getProviderId(state$.value)}/tracked_consult`, null, authToken.get())).pipe(
    catchError(() => EMPTY)
  );

const logAlertAcknowledgement$ = alertId =>
  from(
    teladocApi.put(
      `/v4/alerts/${alertId}/track_event`,
      {
        event: 'viewed'
      },
      authToken.get()
    )
  );

const logAlertAcknowledgementEpic = action$ =>
  action$.pipe(
    ofType(LOG_ALERT_ACKNOWLEDGEMENT),
    switchMap(({ payload: { alertId } }) => logAlertAcknowledgement$(alertId))
  );

export const startReviewEpic = action$ =>
  action$.pipe(
    ofType(START_REVIEW),
    switchMap(({ payload: { alertId, consultationId } }) =>
      logAlertAcknowledgement$(alertId).pipe(
        switchMap(() => {
          redirectToRelative(`/consultations/${consultationId}/init_review`);

          return EMPTY;
        })
      )
    )
  );

export const extendEhrReviewEpic = action$ =>
  action$.pipe(
    ofType(EXTEND_EHR_REVIEW),
    switchMap(({ payload }) =>
      logAlertAcknowledgement$(payload.alertId).pipe(
        switchMap(() =>
          getConsultStatus$(payload.consultationId).pipe(
            switchMap(consultStatusData => {
              if (hasConsultBeenUnlocked(consultStatusData)) {
                redirectToHome();

                return EMPTY;
              }

              return sendExtendEhrReviewRequest$(payload.consultationId);
            }),
            mapTo(trackInReviewConsult({ ...payload, timeToAlert: payload.alertTiming }))
          )
        )
      )
    )
  );

export const lockedConsultUnlockEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRACK_LOCKED_CONSULT),
    switchMap(({ payload }) =>
      of({}).pipe(
        delay(payload.timeToAlert),
        switchMap(() =>
          merge(
            pollConsultUnlockStatus$(action$, payload.consultationId),
            showLockedConsultUnlockAlert$(payload, state$)
          )
        )
      )
    )
  );

export const inReviewConsultUnlockEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRACK_IN_REVIEW_CONSULT),
    switchMap(({ payload }) =>
      of({}).pipe(
        delay(payload.timeToAlert),
        filter(() => !getConsultationHasStarted(state$.value)),
        switchMap(() =>
          merge(
            pollConsultUnlockStatus$(action$, payload.consultationId),
            showInReviewConsultUnlockAlert$(payload, state$)
          )
        )
      )
    )
  );

export const consultInactivityUnlockEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRACK_CONSULT_INACTIVITY),
    mergeMap(action =>
      getCurrentlyLockedConsult$(state$).pipe(
        filter(({ data: { tracked_consult: trackedConsult } }) => trackedConsult !== null),
        mergeMap(({ data: { tracked_consult: trackedConsult } }) => {
          const {
            shouldTrackLockedConsultation,
            shouldTrackInReviewConsultation,
            lockedConsultAlertTiming,
            inReviewConsultAlertTiming
          } = action.payload;

          const {
            consultation_id: consultationId,
            consult_status: consultStatus,
            consult_status_change_date: consultStatusDate,
            member_full_name: memberName
          } = trackedConsult;

          const hasReviewStarted = consultStatus === 'EHR Review';

          const alertTiming = hasReviewStarted ? inReviewConsultAlertTiming : lockedConsultAlertTiming;

          const timeToAlert = getTimeToAlert(consultStatusDate, alertTiming);

          const payload = { alertTiming, timeToAlert, consultationId, memberName };

          if (hasReviewStarted && shouldTrackInReviewConsultation) {
            return of(trackInReviewConsult(payload));
          }

          if (!hasReviewStarted && shouldTrackLockedConsultation) {
            return of(trackLockedConsult(payload));
          }

          return EMPTY;
        })
      )
    )
  );

export default combineEpics(
  fetchProviderEpic,
  providerInactivityClockOutEpic,
  consultInactivityUnlockEpic,
  lockedConsultUnlockEpic,
  inReviewConsultUnlockEpic,
  startReviewEpic,
  extendEhrReviewEpic,
  logAlertAcknowledgementEpic
);
