import moment from 'moment-timezone';
import { sortBy } from 'lodash';

const getAvailableTimeBlocksWithinRange = ({ startTime, endTime }, timeBlocksToOmit) => {
  const extractedTimeBlocks = [];
  let currentStart = startTime;

  sortBy(timeBlocksToOmit, 'start').forEach(({ start, end }) => {
    if (!moment(currentStart).isSame(start)) {
      extractedTimeBlocks.push({ start: currentStart, end: start });
      currentStart = end;
    }

    if (moment(currentStart).isSame(start)) currentStart = end;
  });

  if (!moment(currentStart).isSame(endTime)) {
    extractedTimeBlocks.push({ start: currentStart, end: endTime });
  }

  return extractedTimeBlocks;
};

export default getAvailableTimeBlocksWithinRange;
