import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import cx from 'classnames';

import { translate } from '@td/shared_utils';
import { questionCountStyle } from '../styles';

const questionLabel = count => {
  if (count === 1) {
    return translate(null, 'ehr.mh.objective_tab.mental_status_exam_survey.question_count', 'single');
  }
  return translate(null, 'ehr.mh.objective_tab.mental_status_exam_survey.question_count', 'multiple');
};

const QuestionCount = ({ count, styles }) => (
  <div className={cx(questionCountStyle, get(styles, 'container'))}>
    {count} {questionLabel(count)}
  </div>
);

QuestionCount.propTypes = {
  count:  PropTypes.number,
  styles: PropTypes.object
};

export default QuestionCount;
