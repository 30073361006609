import React from 'react';
import PropTypes from 'prop-types';

const ArrowDownIcon = ({ width = 8, height = 8, fill = '#181919', ...props }) => (
  <svg {...props} width={width} height={height} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.9322 1.13934L7.5887 0.777758C7.49831 0.687362 7.35367 0.687362 7.28136 0.777758L4.00904 4.05007L0.718644 0.777758C0.646328 0.687362 0.501695 0.687362 0.411299 0.777758L0.0677966 1.13934C-0.0225989 1.21166 -0.0225989 1.35629 0.0677966 1.44668L3.84633 5.22522C3.93672 5.31561 4.06328 5.31561 4.15367 5.22522L7.9322 1.44668C8.0226 1.35629 8.0226 1.21166 7.9322 1.13934Z"
      fill={fill}
    />
  </svg>
);

ArrowDownIcon.propTypes = {
  width:  PropTypes.number,
  height: PropTypes.number,
  fill:   PropTypes.string
};

export default ArrowDownIcon;
