export const mapFormValueToRequest = ({
  consultationId,
  selectedMedication,
  sig: { direction, quantity, unit, route, frequency, additionalInstructions },
  pharmacyNotes,
  totalQuantity,
  refills,
  substitutions
}) => ({
  pharmacyId:   '12345',
  consultationId,
  prescription: {
    pharmacyNotes,
    medication: {
      drugName:        selectedMedication.drugName,
      issuingBodyId:   selectedMedication.identification.id,
      issuingBodyCode: selectedMedication.identification.code,
      manual:          selectedMedication.manual
    },
    dispenseInstructions: {
      refills:  parseInt(refills, 10) || 0,
      quantity: parseFloat(totalQuantity),
      substitutions
    },
    sig: {
      direction,
      quantity: parseFloat(quantity),
      unit,
      route,
      frequency,
      additionalInstructions
    }
  }
});
