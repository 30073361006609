import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MealPlanControl from './meal-plan-control';
import NutritionPlanAlert from '../nutrition-plan-alert';
import './meal-plan.scss';

export default class MealPlan extends Component {
  constructor(props) {
    super(props);
    this.controlNamePrefix =
      'interview[provider_intake_surveys][SURVEYMNEMONIC_NUTPLAN][sections][SECTIONMNEMONIC_NUTPLAN_MEALPLAN]';
    this.setMeals = this.setMeals.bind(this);
    this.isSectionInvalid = this.isSectionInvalid.bind(this);
    this.reviewedHandler = this.reviewedHandler.bind(this);
    this.checkSectionValidity = this.checkSectionValidity.bind(this);
    this.state = {
      breakfast:        this.props.section.questions.Q_2_1.response || '',
      lunch:            this.props.section.questions.Q_2_2.response || '',
      dinner:           this.props.section.questions.Q_2_3.response || '',
      snack:            this.props.section.questions.Q_2_4.response || '',
      drinks:           this.props.section.questions.Q_2_5.response || '',
      isSectionInvalid: false
    };
  }

  setMeals(mealName, value) {
    this.setState({ [mealName]: value }, () => this.checkSectionValidity());
  }

  checkSectionValidity() {
    if (this.isSectionInvalid() && this.props.section.reviewed) {
      this.props.toggleSection();
    }
  }

  isSectionInvalid() {
    return this.state.breakfast.length < 5 ||
      this.state.lunch.length < 5 ||
      this.state.dinner.length < 5 ||
      this.state.snack.length < 5 ||
      this.state.drinks.length < 5;
  }

  reviewedHandler() {
    let alertVisibility;
    if (!this.isSectionInvalid()) {
      alertVisibility = false;
      this.props.toggleSection();
    } else {
      alertVisibility = true;
    }
    this.setState({ isSectionInvalid: alertVisibility });
  }

  render() {
    return (
      <div className="meal-plan-wrapper">
        <NutritionPlanAlert show={this.state.isSectionInvalid} />
        <div className="meal-plan-notes">
          <span className="meal-plan-text">
            {this.props.section.notice}
          </span>
        </div>
        <div className="meal-plan-fields">{
          Object.entries(this.props.section.questions)
            .map(entry => (
              <MealPlanControl
                key={entry[1].question}
                value={this.state[entry[1].question.toLowerCase()]}
                control={entry[1]}
                mealHandler={this.setMeals}
                questionIdentifier={entry[0]}
                controlNamePrefix={`${this.controlNamePrefix}[questions]`}
              />)
            )
        }
        </div>
        <div className="reviewed-checkbox">
          <input type="hidden" name={`${this.controlNamePrefix}[reviewed]`} value="false" />
          <input
            id="meal-plan-reviewed"
            type="checkbox"
            name={`${this.controlNamePrefix}[reviewed]`}
            value="true"
            onChange={this.reviewedHandler}
            checked={this.props.section.reviewed}
          />
          <label
            htmlFor="meal-plan-reviewed"
          >
            {`Reviewed ${this.props.section.title}`}
          </label>
        </div>
      </div>
    );
  }
}

MealPlan.propTypes = {
  section:       PropTypes.object.isRequired,
  toggleSection: PropTypes.func.isRequired
};
