import React from 'react';
import PropTypes from 'prop-types';
import './alert.scss';

const NutritionPlanAlert = ({ show }) => (
  <div className={`nutrition-plan-alert${show ? ' visible' : ''}`}>Please fill out the required fields.</div>
);

NutritionPlanAlert.propTypes = {
  show: PropTypes.bool.isRequired
};

export default NutritionPlanAlert;
