import React from 'react';
import Modal from 'app/components/Modal';
import cx from 'classnames';
import { I18n } from '@td/shared_utils';
import styles from './../styles.module.scss';
import moment from 'moment';

const LockedConsultModal = ({ closeModal, selectedEvent, stateInit }) => (
  <Modal
    onClose={closeModal}
    showCloseButton
    show
    titleText={`Locked Appointment - ID: ${selectedEvent.lockedConsultId}`}
    className={cx('provider-calendar-event-modal', styles.calendarModal)}
    centered
  >
    <div className={styles.modalBody}>
      <div className={styles.modalBodyRowConsult}>
        <div className={styles.modalBodyTextConsult}>
          <strong>
            <I18n scope="provider_calendar.modal_locked.start" />:
          </strong>{' '}
          {moment(selectedEvent.start).format(stateInit.dateTimeFormatLong)}
        </div>
        <div className={styles.modalBodyTextConsult}>
          <strong>
            <I18n scope="provider_calendar.modal_locked.end" />:
          </strong>{' '}
          {moment(selectedEvent.end).format(stateInit.dateTimeFormatLong)}
        </div>
      </div>
    </div>
    <div className={styles.modalFooterConsult}>
      <span>
        <I18n scope="provider_calendar.modal_locked.footer_text" />
        <a href="/homepage" className="javascript_link">
          {' '}
          <I18n scope="provider_calendar.modal_locked.dashboard" />
        </a>
        .
      </span>
    </div>
  </Modal>
);
export default LockedConsultModal;
