import { teladocApi, TdApiRoutes, authToken } from '@td/api';
import { createRequestActions } from '@td/utils';

import { LAST_ACTIVE_TIME_UPDATED, FETCH_PROVIDER_LAST_ACTIVE_TIME } from './action-types';

//
// The url is /v4/user_last_active_time
// Not call TAS on the login page and reset password page
//
export const updateLastActiveTime = lastActiveTime => {
  let ISODateFormat;
  const url = TdApiRoutes.userLastActiveTime();

  if (lastActiveTime instanceof Date) {
    ISODateFormat = lastActiveTime.toISOString();
  } else {
    ISODateFormat = new Date().toISOString();
  }

  // Todo surface the error to dev when the api call failing
  return teladocApi.put(url, { last_active_date: ISODateFormat }, authToken.get());
};

export const lastActiveTimeUpdated = lastActiveTime => ({
  type: LAST_ACTIVE_TIME_UPDATED,
  payload: { lastActiveTime }
});

export const fetchProviderLastActiveTime = createRequestActions(
  FETCH_PROVIDER_LAST_ACTIVE_TIME
);
