import { TdApiRoutes, authToken, teladocApi } from '@td/api';
import { PHR_TO_FETCH } from '../constants';

export const RECEIVE_PERSONAL_HEALTH_RECORDS = 'RECEIVE_PERSONAL_HEALTH_RECORDS';

export function fetchPersonalHealthRecords(memberId) {
  const params = {type: PHR_TO_FETCH};
  const url = TdApiRoutes.member.personalHealthRecordsPath(memberId);
  return {
    type: RECEIVE_PERSONAL_HEALTH_RECORDS,
    payload: teladocApi.get(url, params, authToken.get())
  };
}
