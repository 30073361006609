import React from 'react';
import _ from 'lodash';
import AttendeeCheckbox from './attendee-checkbox';
import { translate } from '@td/shared_utils';

const couples_therapy_multi_way_attendee_verification = partners => ({
  question:        translate(null, 'ehr.mh.verification_tab.attendee.question'),
  attendeeOptions: [
    {
      label:    `${translate(null, 'ehr.mh.verification_tab.attendee.labels.member')}: ${partners[0].name}`,
      personId: partners[0].person_id
    },
    {
      label:    `${translate(null, 'ehr.mh.verification_tab.attendee.labels.partner')}: ${partners[1].name}`,
      personId: partners[1].person_id
    }
  ]
});

const checkboxContainerStyle = {
  display:   'flex',
  marginTop: '1em'
};

const checkboxStyle = {
  verticalAlign: 'middle',
  marginLeft:    '1em'
};

const MultiWayConsultAttendeesVerification = ({ consultAttendees, preSelectedConsultAttendees }) => {
  const { question, attendeeOptions } = couples_therapy_multi_way_attendee_verification(consultAttendees);

  const checkboxList = attendeeOptions.map(({ personId, label }, index) => (
    <AttendeeCheckbox
      label={label}
      attendeeId={personId}
      isChecked={preSelectedConsultAttendees.includes(personId)}
      styles={index != 0 ? checkboxStyle : _.omit(checkboxStyle, ['marginLeft'])}
      key={personId}
    />
  ));

  return (
    <div className="multi-way-consult-attendees-verification">
      <strong>{question}</strong>
      <div className="multi-way-consult-attendees-verification-checkbox-group" style={checkboxContainerStyle}>
        {checkboxList}
      </div>
      <div
        id="verify_multi_way_consult_attendees_verification_validation_error"
        className="interview_inline_validation_error"
      />
    </div>
  );
};

export default MultiWayConsultAttendeesVerification;
