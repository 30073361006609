import React from 'react';
import PropTypes from 'prop-types';
import { MdCheckCircle } from 'react-icons/md';

const NetworkTestSuccess = ({ onComplete }) => (
  <div>
    <div className="network-test-results">
      <MdCheckCircle className="network-test-icon network-test-icon-success" />
      <div>
        <h5 className="network-test-content network-test-header">Bandwidth Test Successful</h5>
        <div className="network-test-content">
          Our performance test confirmed you have sufficient bandwidth to conduct video
          consultations.{' '}
          <span className="network-test-strong">
            You have been clocked in for both phone and video.
          </span>
        </div>
        <ul className="network-test-content network-test-list">
          <li>
            Please make sure that you are in a well-lit, HIPAA compliant area wearing professional
            attire.
          </li>
          <li>Ensure you can maintain a strong internet connection.</li>
          <li>Stay focused on the member without any distractions or unnecessary movement.</li>
          <li>Check your video and make sure your head is centered and that the camera is at eye level.</li>
        </ul>
      </div>
    </div>
    <div className="network-test-actions">
      <input type="button" value="Ok" onClick={onComplete} />
    </div>
  </div>
);

NetworkTestSuccess.propTypes = {
  onComplete: PropTypes.func.isRequired
};

export default NetworkTestSuccess;
