import React from 'react';
import PropTypes from 'prop-types';

import SymptomsAccordion from './symptoms-accordion-container';
import AddedSymptomsAccordion from './added-symptoms-accordion-container';

const AdditionalSymptoms = ({ symptomClasses }) => (
  <div>
    {symptomClasses.map(symptomClass => (
      <SymptomsAccordion key={symptomClass} symptomClass={symptomClass} />
    ))}
    <AddedSymptomsAccordion />
  </div>
);

AdditionalSymptoms.propTypes = {
  symptomClasses: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default AdditionalSymptoms;
