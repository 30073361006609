import React from 'react';
import { PropTypes } from 'prop-types';
import { I18n, translate } from '@td/shared_utils';

const buildAcknowledgementConditions = countryCode => {
  const conditions = translate(null, 'labs.lab_prescription.acknowledge.conditions', countryCode.toLowerCase(), {
    defaults: [{ scope: 'labs.lab_prescription.acknowledge.conditions.default' }]
  });

  return conditions.map((condition, idx) => <li key={idx}>{condition}</li>);
};

const Acknowledgement = ({ countryCode }) => (
  <div className="lab-prescription-acknowledge-section">
    <input type="checkbox" name="interview[lab_prescription_acknowledge]" id="lab_prescription_acknowledge" />
    <label htmlFor="lab_prescription_acknowledge">
      <I18n scope="labs.lab_prescription.acknowledge" text="title" />
    </label>
    <ul className="lab-prescription-acknowledge-subtext">{buildAcknowledgementConditions(countryCode)}</ul>
  </div>
);

Acknowledgement.propTypes = {
  countryCode: PropTypes.string.isRequired
};

export default Acknowledgement;
