import { connect } from 'react-redux';

import AdditionalSymptoms from './additional-symptoms';

const mapStateToProps = state => ({
  symptomClasses: state.symptoms.allSymptomClasses
});

export default connect(
  mapStateToProps
)(AdditionalSymptoms);
