import { logError } from '@td/shared_utils';
import { Device } from 'twilio-client';
import _ from 'lodash';

import { CONFERENCE_STATUSES } from '../constants/call-status';
import { twilioErrorHandlers } from '../utils/twilio-error-handlers';
import { setupMeanOpinionScoreListener } from '.';

const getTwilioDeviceProps = setCallStatus => ({
  onReady: () => {
    setCallStatus(CONFERENCE_STATUSES.READY_TO_START);
  },
  onCancel: () => {
    setCallStatus(CONFERENCE_STATUSES.READY_TO_START);
  },
  onError: err => {
    const errorHandler = _.get(twilioErrorHandlers, `[${err.code}]`, null);

    logError(err, 'WebRTC device error');

    if (errorHandler) {
      const { isCritical } = errorHandler(err);

      if (!isCritical) return;
    }

    setCallStatus(CONFERENCE_STATUSES.WEBRTC_DEVICE_ERROR);
  },
  onIncoming: connection => {
    connection.accept(() => {
      setCallStatus(CONFERENCE_STATUSES.IN_PROGRESS);
    });
  }
});

export default class TwilioDevice {
  constructor(token, callbacks, options) {
    this.device = new Device(token, options);

    const { onCancel, onError, onIncoming, onReady } = getTwilioDeviceProps(callbacks.setCallStatus);

    // Intialize the event handlers
    this.device.on('cancel', onCancel);
    this.device.on('error', onError);
    this.device.on('incoming', onIncoming);
    this.device.on('ready', onReady);
  }

  createConnection(params = {}, options = {}) {
    const connection = this.device.connect(params, options);

    setupMeanOpinionScoreListener(connection);

    return connection;
  }

  getConnection() {
    return this.device.activeConnection();
  }

  disconnect() {
    this.device.disconnect();
  }

  disconnectAll() {
    this.device.disconnectAll();
  }

  removeDevice() {
    this.device.destroy();
  }

  toggleMute(value) {
    const connection = this.getConnection();
    connection.mute(value);
  }
}
