import { FOCUS_SYMPTOM_SET, FOCUS_SYMPTOM_CLEAR, CLEAR_COMPLAINT } from '../action-types';
import { addImmutable, removeImmutable } from './utilities';

export const DEFAULT_STATE = {
  symptomsToFocus: {}
};

function onFocusSymptomSet(state, action) {
  const { symptomCode, symptomClass } = action.payload;

  return {
    ...state,
    symptomsToFocus: addImmutable(state.symptomsToFocus, symptomCode, symptomClass)
  };
}

function onFocusSymptomClear(state, action) {
  return {
    ...state,
    symptomsToFocus: removeImmutable(state.symptomsToFocus, action.payload.symptomCode)
  };
}

function onComplaintClear() {
  return DEFAULT_STATE;
}


export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FOCUS_SYMPTOM_SET:
      return onFocusSymptomSet(state, action);
    case FOCUS_SYMPTOM_CLEAR:
      return onFocusSymptomClear(state, action);
    case CLEAR_COMPLAINT:
      return onComplaintClear(state, action);
    default:
      return state;
  }
};
