import { connect } from 'react-redux';

import NetworkTestOngoing from './network-test-ongoing';
import { networkTestCancel } from '../actions';
import { clockInProvider } from '../../sidebar/provider-clock-in-out/actions';
import ProviderClockInRequest from '../../sidebar/provider-clock-in-out/enums/provider-clock-in-request';
import getNetworkTestFinalizing from '../reducers/selectors/getNetworkTestFinalizing';

export const mapStateToProps = state => ({
  prolonged: state.networkTest.status.prolonged,
  finalizing: getNetworkTestFinalizing(state)
});

export const mapDispatchToProps = dispatch => ({
  onCancelClick: () => {
    dispatch(networkTestCancel());
    dispatch(clockInProvider.start({ clockInType: ProviderClockInRequest.NORMAL }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkTestOngoing);
