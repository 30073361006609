import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import PROVIDER_PROMPTS from '../queries/provider-prompts.graphql';
import StudyPrompt from './study-prompt';

const ProviderPrompts = ({ providerID }) => {
  const { loading, error, data } = useQuery(PROVIDER_PROMPTS, {
    variables: { providerID }
  });

  if (error || loading || !data) return null;

  if (data.provider.providerPrompts.providerDashboard === null) return null;
  const {
    provider: {
      providerPrompts: {
        providerDashboard: { content }
      }
    }
  } = data;

  const prompts = content.map(prompt => (
    <StudyPrompt
      text={prompt.text}
      styling={prompt.styling}
      links={prompt.links}
      updatedAt={prompt.updated_at}
      key={prompt}
      metricId={prompt.study_container_content_id}
      type={prompt.type}
      providerId={providerID}
    />
  ));

  return <div>{prompts}</div>;
};

ProviderPrompts.propTypes = {
  providerID: PropTypes.number.isRequired
};

export default ProviderPrompts;
