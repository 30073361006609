import React from 'react';

export const FavoriteActiveIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.14719 0.581866L5.2022 4.56163L0.803509 5.19001C0.0255094 5.30971 -0.273721 6.26724 0.294817 6.83578L3.43674 9.91785L2.68866 14.2567C2.56897 15.0347 3.40681 15.6332 4.09504 15.2741L8.01496 13.2094L11.905 15.2741C12.5932 15.6332 13.431 15.0347 13.3113 14.2567L12.5633 9.91785L15.7052 6.83578C16.2737 6.26724 15.9745 5.30971 15.1965 5.19001L10.8277 4.56163L8.85281 0.581866C8.52365 -0.106364 7.50627 -0.136287 7.14719 0.581866Z"
      fill="#E2B215"
    />
  </svg>
);
