import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaCircle } from 'react-icons/fa';

import ProviderClockInOutStatusTypes from './provider-clock-in-out-status-types';
import ProviderClockInOutStatusText from './provider-clock-in-out-status-text-container';

const ProviderClockInOutStatus = ({ clockedIn, clockInType, phoneClockInAllowed, videoClockInAllowed }) => (
  <header className="clock-in-out-header">
    <h1>
      Your Status
      <FaCircle
        className={classNames('clock-in-out-status', {
          'clocked-in-status':  clockedIn,
          'clocked-out-status': !clockedIn
        })}
      />
      <ProviderClockInOutStatusTypes
        clockInType={clockInType}
        videoClockInAllowed={videoClockInAllowed}
        phoneClockInAllowed={phoneClockInAllowed}
      />
    </h1>
    <ProviderClockInOutStatusText
      clockInType={clockInType}
      clockedIn={clockedIn}
      videoClockInAllowed={videoClockInAllowed}
    />
  </header>
);

ProviderClockInOutStatus.propTypes = {
  clockedIn:           PropTypes.bool.isRequired,
  clockInType:         PropTypes.string,
  videoClockInAllowed: PropTypes.bool.isRequired,
  phoneClockInAllowed: PropTypes.bool.isRequired
};

export default ProviderClockInOutStatus;
