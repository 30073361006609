import { isEmpty, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CBuffer from 'CBuffer';
import { fetchVendors } from 'app/Conference/actions';
import { CONFERENCE_STARTED_EVENT } from '../../config/constants';
import { SignalBars } from '@td/td-ui-kit';
import { VideoStatsMos } from '@td/utils';
import { logger } from 'conference-react';
import { DraggableVideo } from 'app/draggable-video';
import { translate } from '@td/shared_utils';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import {
  Attendee,
  Attendees,
  Conference,
  ConferenceControls,
  Notification,
  Publisher,
  PublishControl,
  isBadVideoParams,
  SipPublishControl
} from 'conference-react';
import { callOptumNurseline, logConferenceEvent, createConferenceStatistic, createAutomatedCall } from '../actions';
import tdPipWindow from '../../lib/td-pip-window';
import CannotStart from './cannot-start';
import { MultiView } from './multi-view';
import PIPNotification from './pip-notification';
import EndCallModal from './end-call-modal';
import Timer from './timer';
import CallStatus from '../../call-details/components/call-status';
import CallNotification from '../../call-details/components/call-notification';
import CallDetailsPopoverButton from '../../call-details/components/call-details-popover-button';
import CallDetailsWrapper from '../../call-details/components/call-details-wrapper';
import MemberInfoPIPMode from '../../call-details/components/member-info-pip-mode';
import KeepAlive from '../../keep-alive/keep-alive';
import { VIDEO_VISIT_TYPE } from '../../call-details/constants';
import { ProviderConsultationTimer } from '../../provider-consultation-timer';
import { NotifyRegisteredNurseButton } from '../../attendee-notifications';
import styles from './styles.module.scss';

const WARM_TRANSFER_COPY = {
  name:         'Care Coordinator',
  instructions:
    'If member needs non-emergent immediate care when the visit is ' +
    'completed, click the Transfer to Care Coordinator button. Please ' +
    'stay on the line to communicate the reason you are transferring ' +
    'the patient to them and then you can end call and complete your ' +
    'documentation.'
};

const SETTINGS = {
  NOTIFICATIONS: {
    CONNECTION_LOST:             'Network connection lost.',
    NETWORK_CONNECTIVITY_ISSUES:
      'Your network connection is limited and it is impacting video. Please move to an area of stronger connection.',
    PATIENT_AUTO_CALLED:      'Waiting for member, an automated call has been sent to alert them to join this video.',
    PROVIDER_CONNECTED:       'You have joined the room.',
    PROVIDER_DISCONNECTED:    'You have disconnected.',
    SESSION_RECONNECTED:      'Reconnected',
    SESSION_RECONNECTING:     'Network connection lost. Attempting to reconnect.',
    SESSION_STREAM_DESTROYED: 'Sip call disconnected',
    SIP_CONNECTED:            'Care Coordinator connected',
    SIP_DISCONNECTED:         'Care Coordinator disconnected',
    START_CALL_WHEN_READY:    'Start the call when ready.',
    WAITING_FOR_PATIENT:      'Waiting for patient.'
  },
  MIN_MOS_SCORE:           3,
  MAX_CONSECUTIVE_LOW_MOS: 2,
  REASONS:                 {
    NETWORK_TIMEDOUT: 'networkTimedout'
  },

  CONN_STATS_INTERVAL: 1500,
  SIGNAL_COLOR:        {
    1: '#ef4723',
    2: '#ef4723',
    3: '#fecc09',
    4: '#0e9246',
    5: '#0e9246'
  },
  ATTENDEE_STATS_INTERVAL: 1000
};

const ACTIONS = {
  CONNECTED:    'connected',
  DISCONNECTED: 'disconnected'
};

class Video extends Component {
  constructor(props) {
    super(props);

    this.videoStatsMos = new VideoStatsMos();
    this.numOfConsecutiveLowMos = 0;
    this.state = {
      pipWindow:          null,
      isShowEndCallModal: false,
      videoStatsScore:    5,
      reconnect:          false,
      show:               false,
      expanded:           false,
      renderPublisher:    null,
      isPublished:        false,
      isSipPublished:     true,
      attendees:          [],
      subscribers:        [],
      myStreamId:         null,
      config:             this.props.videoParams,
      notification:       SETTINGS.NOTIFICATIONS.START_CALL_WHEN_READY,
      canAutoCall:        this.props.canAutoCall || false,
      connectionStats:    {
        subscribers: {}
      }
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.props.fetchVendors(this.props.consultationId);
  }

  onConnectionLost = () => {
    this.setState({
      show:         false,
      notification: SETTINGS.NOTIFICATIONS.CONNECTION_LOST
    });
  };

  // eslint-disable-next-line no-unused-vars
  onCreateConference = config => {
    this.setState({ show: true });
  };

  onCreatePublisher = () => {
    this.setState({ renderPublisher: true });
  };

  onCreateAttendee = stream => {
    const { userType, userName } = JSON.parse(stream.connection.data);
    const currentNotification = this.onNotificationChange(userType, userName, ACTIONS.CONNECTED);
    const attendees = uniqBy([...this.state.attendees, stream], 'streamId');

    if (userType === 'tdoc_member' || userType === 'tdoc_provider') {
      this.setState({
        attendees,
        notification: currentNotification
      });
    } else if (userType === 'Nurse Line') {
      this.setState({
        attendees,
        isSipPublished: true,
        notification:   SETTINGS.NOTIFICATIONS.SIP_CONNECTED
      });
    } else {
      this.setState({
        attendees,
        isSipPublished: true,
        notification:   currentNotification
      });
    }
  };

  removeSubscriber = streamIdToRemove => {
    const currentSubscribers = this.state.connectionStats.subscribers;
    const subDataToRemove = currentSubscribers[streamIdToRemove];

    clearInterval(subDataToRemove.intervalRef);
    delete currentSubscribers[streamIdToRemove];

    return currentSubscribers;
  };

  formatAttendeeStatisticParams = stream => {
    const { attendeeId } = JSON.parse(stream.connection.data);
    if (this.state.conference_statistic && this.state.conference_statistic[stream.streamId]) {
      const conference_statistic = this.state.conference_statistic[stream.streamId];
      conference_statistic.attendee_id = attendeeId;
      // use current time since opentok connection class doesnt provider stream destroy time
      conference_statistic.connection_end_time = Date(Date.now());
      conference_statistic.connection_start_time = this.state.conference_start_time[stream.streamId];
      return {
        consultation_id: this.props.consultationId,
        conference_statistic
      };
    } else {
      return {};
    }
  };

  onDestroyAttendee = (stream, _subscriber) => {
    const { attendees } = this.state;
    const filteredAttendees = attendees.filter(attendee => attendee !== stream);

    const newSubscribers = this.removeSubscriber(stream.streamId);
    const { userType, userName } = JSON.parse(stream.connection.data);
    const currentNotification = this.onNotificationChange(userType, userName, ACTIONS.DISCONNECTED);

    this.props.createConferenceStatistic(this.formatAttendeeStatisticParams(stream));

    if (userType === 'Nurse Line') {
      this.setState({
        attendees:      filteredAttendees,
        isSipPublished: false,
        notification:   SETTINGS.NOTIFICATIONS.SIP_DISCONNECTED
      });
    } else {
      this.setState({
        attendees:            filteredAttendees,
        notification:         currentNotification,
        conference_statistic: {},
        connectionStatus:     {
          subscribers: newSubscribers
        }
      });
    }

    if (this.state.attendees.length === 0) {
      this.setState({ isSipPublished: true });
    }
  };

  onNewVideoStats = newVideoStatsScore => {
    const { notification, videoStatsScore } = this.state;
    if (videoStatsScore !== newVideoStatsScore) {
      this.setState({ videoStatsScore: newVideoStatsScore });
    }

    if (newVideoStatsScore < SETTINGS.MIN_MOS_SCORE) {
      this.numOfConsecutiveLowMos++;

      // Runs every ~6s, show message if consistently bad connection over 12s
      if (this.numOfConsecutiveLowMos > SETTINGS.MAX_CONSECUTIVE_LOW_MOS) {
        this.setState({
          notification: SETTINGS.NOTIFICATIONS.NETWORK_CONNECTIVITY_ISSUES
        });
      }
    } else if (notification === SETTINGS.NOTIFICATIONS.NETWORK_CONNECTIVITY_ISSUES) {
      this.setState({ notification: null });
      this.numOfConsecutiveLowMos = 0;
    }
  };

  onPublisherStreamCreated = (stream, publisher) => {
    this.setState({ myStreamId: stream.streamId });
    if (this.state.attendees.length == 0 && this.state.canAutoCall) {
      this.props.createAutomatedCall(this.props.consultationId);
    }
    setTimeout(this.showWaitingIfNoPatientYet, 1500);
    this.videoStatsMos.setVideoDimensions({
      width:  publisher.videoWidth(),
      height: publisher.videoHeight()
    });
    const intervalFn = () =>
      publisher.getStats((error, statsArray) => {
        if (error) {
          this.setState({ videoStatsScore: 0 });
        } else {
          const publisherStats = statsArray[0].stats;
          const stats = {
            bytes:     publisherStats.video.bytesSent,
            timestamp: publisherStats.timestamp
          };
          this.videoStatsMos.onVideoStats(stats, score => this.onNewVideoStats(score));
        }
      });
    setInterval(intervalFn, SETTINGS.CONN_STATS_INTERVAL);
  };

  isLowResVideo = (width, height) => width < 640 || height < 480;

  isLowBandwidth = subscriberSamples => {
    let aMinLost = Number.MAX_SAFE_INTEGER;
    let aMaxLost = 0;
    let vMinLost = Number.MAX_SAFE_INTEGER;
    let vMaxLost = 0;

    let aMinRecvd = Number.MAX_SAFE_INTEGER;
    let aMaxRecvd = 0;
    let vMinRecvd = Number.MAX_SAFE_INTEGER;
    let vMaxRecvd = 0;

    let numLowRes = 0;

    subscriberSamples.forEach(sample => {
      const { audio, video } = sample;

      aMinLost = Math.min(aMinLost, audio.packetsLost);
      aMaxLost = Math.max(aMaxLost, audio.packetsLost);
      aMinRecvd = Math.min(aMinRecvd, audio.packetsReceived);
      aMaxRecvd = Math.max(aMaxRecvd, audio.packetsReceived);

      vMinLost = Math.min(vMinLost, video.packetsLost);
      vMaxLost = Math.max(vMaxLost, video.packetsLost);
      vMinRecvd = Math.min(vMinRecvd, video.packetsReceived);
      vMaxRecvd = Math.max(vMaxRecvd, video.packetsReceived);

      if (this.isLowResVideo(sample.width, sample.height)) {
        numLowRes += 1;
      }
    });

    const aPeriodPackets = aMaxRecvd - aMinRecvd;
    const aPeriodLoss = aMaxLost - aMinLost;
    const aPeriodCalc = (aPeriodLoss * 100) / aPeriodPackets;

    const vPeriodPackets = vMaxRecvd - vMinRecvd;
    const vPeriodLoss = vMaxLost - vMinLost;
    const vPeriodCalc = (vPeriodLoss * 100) / vPeriodPackets;

    return aPeriodCalc > 3 || vPeriodCalc > 1 || numLowRes > 3;
  };

  calculatedWeightedAverage = (baseAvg, AvgCount, NewNum) => (baseAvg * AvgCount + NewNum) / (AvgCount + 1);

  onNotificationChange = (userType, userName, action) =>
    translate(null, 'video.video_visit_status', `${userType}_${action}`, { username: userName });

  getAttendeeStats(subscriber) {
    if (!subscriber) return;

    const { prevStats } = subscriber;

    subscriber.getStats((err, stats) => {
      let min_audio_bitrate = Number.MAX_SAFE_INTEGER;
      let max_audio_bitrate = Number.MIN_SAFE_INTEGER;
      let avg_audio_bitrate = 0;
      let min_video_bitrate = Number.MAX_SAFE_INTEGER;
      let max_video_bitrate = Number.MIN_SAFE_INTEGER;
      let avg_video_bitrate = 0;
      let average_count = 0;

      if (err) {
        return;
      }

      if (prevStats && stats) {
        subscriber.videoBitRate = (8 * (stats.video.bytesReceived - prevStats.video.bytesReceived)) / 1000;
        subscriber.audioBitRate = (8 * (stats.audio.bytesReceived - prevStats.audio.bytesReceived)) / 1000;
      }
      subscriber.prevStats = stats;

      if (this.state.conference_statistic && this.state.conference_statistic[subscriber.streamId]) {
        const stream_statistic = this.state.conference_statistic[subscriber.streamId];
        min_audio_bitrate = Math.min(stream_statistic.min_audio_bitrate, subscriber.audioBitRate).toFixed(2);
        max_audio_bitrate = Math.max(stream_statistic.max_audio_bitrate, subscriber.audioBitRate).toFixed(2);
        avg_audio_bitrate = this.calculatedWeightedAverage(
          stream_statistic.avg_audio_bitrate,
          stream_statistic.average_count,
          subscriber.audioBitRate
        ).toFixed(2);
        min_video_bitrate = Math.min(stream_statistic.min_video_bitrate, subscriber.videoBitRate).toFixed(2);
        max_video_bitrate = Math.max(stream_statistic.max_video_bitrate, subscriber.videoBitRate).toFixed(2);
        avg_video_bitrate = this.calculatedWeightedAverage(
          stream_statistic.avg_video_bitrate,
          stream_statistic.average_count,
          subscriber.videoBitRate
        ).toFixed(2);
        average_count = stream_statistic.average_count + 1;
      }

      this.setState({
        conference_statistic: {
          [subscriber.streamId]: {
            attendee_connection_id: subscriber.session.connection.connectionId,
            min_audio_bitrate,
            max_audio_bitrate,
            avg_audio_bitrate,
            min_video_bitrate,
            max_video_bitrate,
            avg_video_bitrate,
            average_count
          }
        }
      });
    });
  }

  onSubscriberStreamCreated = (subscriber, statsFn) => {
    const streamId = subscriber.streamId;
    const currTime = Date(Date.now());

    this.setState({
      conference_start_time: { [streamId]: currTime }
    });

    const attendeeStats = () => {
      this.getAttendeeStats(subscriber);
    };

    setInterval(attendeeStats, SETTINGS.ATTENDEE_STATS_INTERVAL);

    const newSubscriberData = {
      streamId,
      samples: new CBuffer(20)
    };

    const { subscribers } = this.state;

    this.setState({
      subscribers:     [...subscribers, subscriber],
      connectionStats: {
        subscribers: {
          ...this.state.connectionStats.subscribers,
          [subscriber.streamId]: newSubscriberData
        }
      }
    });
  };

  onPublished = event => {
    this.bindCustomPublisherEvents(event);
    this.setState({ isPublished: true });
  };

  onRetryConnection = (resolve, reject, error) => {
    if (isEmpty(error)) {
      resolve();
    } else {
      reject(error);
    }
  };

  onSessionDisconnected = stream => {
    if (stream.reason !== SETTINGS.REASONS.NETWORK_TIMEDOUT) return false;

    return new Promise((resolve, reject) => {
      stream.target.connect(stream.target.apiKey, this.onRetryConnection.bind(this, resolve, reject));
    })
      .then(() => {
        window.location.reload(true);
      })
      .catch(error => {
        // eslint-disable-line no-unused-vars
        this.onConnectionLost();
      });
  };

  // eslint-disable-next-line no-unused-vars
  onSessionReconnected = stream => {
    this.setState({ notification: SETTINGS.NOTIFICATIONS.SESSION_RECONNECTED });
  };

  // eslint-disable-next-line no-unused-vars
  onSessionReconnecting = stream => {
    this.setState({ notification: SETTINGS.NOTIFICATIONS.SESSION_RECONNECTING });
  };

  // eslint-disable-next-line no-unused-vars
  onStreamDisconnected = stream => {
    this.setState({ notification: SETTINGS.NOTIFICATIONS.SESSION_STREAM_DESTROYED });
  };

  onStartPublisher = () => {
    if (this.state.reconnect) {
      this.reconnectSession();
    }

    this.publisher.createPublisher();

    this.setState({
      isPublished:    true,
      isSipPublished: false,
      notification:   SETTINGS.NOTIFICATIONS.PROVIDER_CONNECTED
    });

    this.props.logConferenceEvent(this.props.consultationId, CONFERENCE_STARTED_EVENT);
  };

  onStartSipCall = () => {
    this.props.callOptumNurseline(this.props.consultationId);
    this.setState({
      isSipPublished: true,
      notification:   SETTINGS.NOTIFICATIONS.SIP_CONNECTED
    });
  };

  onEndPublisher = () => {
    this.publisher.destroyPublisher();
    this.setState({
      isPublished:  false,
      notification: SETTINGS.NOTIFICATIONS.PROVIDER_DISCONNECTED
    });
  };

  onEndCall = () => {
    const { subscribers } = this.state;
    this.publisher.disconnectSession(subscribers);
    this.setState({
      isPublished:        false,
      notification:       SETTINGS.NOTIFICATIONS.PROVIDER_DISCONNECTED,
      subscribers:        [],
      reconnect:          true,
      isShowEndCallModal: false
    });
  };

  toggleExpanded = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
    this.props.onVideoExpanded(!expanded);
  };

  isTransferable = () => this.state.isSipPublished;

  reconnectSession = () => {
    const { session } = this.publisher.props.provider;
    const { token } = this.state.config;

    session.connect(token);
  };

  /**
   * Called after provider published and a timeout expires.
   * If patient still hasn't joined we'll show the "Waiting
   * for patient" notifcation.
   */
  showWaitingIfNoPatientYet = () => {
    const { attendees } = this.state;

    if (!isEmpty(attendees)) {
      return;
    }
    if (this.state.canAutoCall) {
      this.setState({
        notification: SETTINGS.NOTIFICATIONS.PATIENT_AUTO_CALLED,
        canAutoCall:  false
      });

      return;
    }

    this.setState({
      notification: SETTINGS.NOTIFICATIONS.WAITING_FOR_PATIENT
    });
  };

  bindCustomPublisherEvents = publisher => {
    try {
      publisher.props.provider.session.on({
        sessionDisconnected: this.onSessionDisconnected,
        sessionReconnected:  this.onSessionReconnected,
        sessionReconnecting: this.onSessionReconnecting
      });
    } catch (error) {
      throw new Error('Could not bind events to publisher!');
    }
  };

  isOwnStream = stream => stream.streamId === this.state.myStreamId;

  notCompatibleBrowserCheck = () => window.OT.checkSystemRequirements() === 0 && !window.OTPlugin.isSupported();

  renderAttendee = (attendee, index) => {
    if (!attendee) return false;
    const { streamId } = attendee;
    const key = `${streamId}_${index}`;
    const { userType } = JSON.parse(attendee.connection.data);

    return (
      <Attendee
        key={key}
        stream={attendee}
        userType={userType}
        onSubscribed={this.onSubscriberStreamCreated}
        onDestroyed={this.onDestroyAttendee}
      />
    );
  };
  renderCopy = () => {
    if (this.props.warmTransferVendors.length === 0) {
      return null;
    }

    return (
      <div>
        <div className="video-vendor-instruction">{WARM_TRANSFER_COPY.instructions}</div>
      </div>
    );
  };

  showEndCallModal = () => this.setState({ isShowEndCallModal: true });

  closeEndCallModal = () => this.setState({ isShowEndCallModal: false });

  togglePictureInPictureMode = async () => {
    if (!this.state.pipWindow) {
      if (this.state.expanded) {
        this.setState({ expanded: false });
        this.props.onVideoExpanded(false);
      }

      const pipWindow = await tdPipWindow.createPipWindow(
        300,
        400
      );

      pipWindow.addEventListener('pagehide', () => {
        this.setState({ pipWindow: null });
      });

      const pipContainer = document.createElement('div');
      pipContainer.id = 'pip_container';
      pipContainer.className = `react without-timer`;

      const modalContainer = document.createElement('div');
      modalContainer.id = 'modal_container'
      pipContainer.append(modalContainer);

      pipWindow.document.body.append(pipContainer);

      this.setState({ pipWindow });
    } else {
      this.state.pipWindow.close();
      window.focus();
    }
  }

  renderVideo() {
    const { config, attendees, isPublished, notification, videoStatsScore, pipWindow } = this.state;
    const { consultation, consultationId, attendeesNotificationsEnabled, canUsePIP, isMentalHealth } = this.props;
    const isThereAttendee = !!attendees.length;

    return (
      <div id="video_content">
        <KeepAlive id="timer" useKeepAlive={canUsePIP}>
          {isMentalHealth ? (
            <ProviderConsultationTimer consultation_id={consultationId} pipWindow={pipWindow} />
          ) : (
            <Timer pipWindow={pipWindow} />
          )}
        </KeepAlive>
        {pipWindow && <MemberInfoPIPMode consultation={consultation} />}
        {!pipWindow && <CallDetailsWrapper>
          <div className="call-notification">
            <CallNotification />
            <Notification notification={notification} />
          </div>
          <CallDetailsPopoverButton consultation={consultation} visitType={VIDEO_VISIT_TYPE} pipWindow={pipWindow} />
        </CallDetailsWrapper>}
        <div ref={this.containerRef}>
          {!pipWindow && <CallStatus containerRef={this.containerRef} consultation={consultation} />}
          <div className="conf-app">
            <div className="conf-app--video">
              {!pipWindow && <SignalBars
                barsContainerClassName="signal-bars"
                numberOfBars={videoStatsScore}
                signalColor={SETTINGS.SIGNAL_COLOR[videoStatsScore]}
                emptySignalColor="#FFFFFF"
              />}
              <Conference
                config={config}
                onCreatePublisher={this.onCreatePublisher}
                onStreamCreated={this.onCreateAttendee}
              >
                <Attendees>
                  <KeepAlive id="publisher" useKeepAlive={canUsePIP}>
                    <DraggableVideo pipWindow={pipWindow}>
                      <Publisher
                        ref={ele => (this.publisher = ele)}
                        onPublished={this.onPublished}
                        autoPublish={false}
                        onPublisherStreamCreated={this.onPublisherStreamCreated}
                      />
                    </DraggableVideo>
                  </KeepAlive>
                  {isPublished && (
                    <MultiView attendees={attendees} canUsePIP={canUsePIP}>
                      {attendees.map(this.renderAttendee)}
                    </MultiView>
                  )}
                </Attendees>
                <ConferenceControls>
                  <PublishControl
                    isThereAttendee={isThereAttendee}
                    isPublished={isPublished}
                    onStart={this.onStartPublisher}
                    onEnd={pipWindow ? this.showEndCallModal : this.onEndCall}
                    buttonClass="button"
                  />
                  <NotifyRegisteredNurseButton
                    inProgress={isPublished}
                    consultationId={consultationId}
                    attendeeNotificationsEnabled={attendeesNotificationsEnabled}
                    className={styles.notifyRnButton}
                  />
                </ConferenceControls>
                <ConferenceControls>
                  <SipPublishControl
                    isSipPublished={this.isTransferable()}
                    onSipStart={this.onStartSipCall}
                    warmTransferVendors={this.props.warmTransferVendors}
                    buttonClass="button sip-button"
                    buttonText="Transfer to Care Coordinator"
                  />
                </ConferenceControls>
                {canUsePIP && (
                  <button
                    className={cn(
                      'pip-button',
                      pipWindow ? styles.turnOffPipIcon : styles.turnOnPipIcon,
                      pipWindow && 'pip-button-position-to-right'
                    )}
                    onClick={this.togglePictureInPictureMode}
                    title={pipWindow ? 'Back to tab' : 'Pop out video in new window'}
                  />
                )}
                <button
                  id="video-expansion-button"
                  className={cn(
                    'video-expansion-button',
                    styles.videoExpansionButtonIcon,
                    pipWindow ? 'hidden' : 'visible'
                  )}
                  onClick={this.toggleExpanded}
                />
              </Conference>
              {this.renderCopy()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { config, attendees, isPublished, pipWindow, isShowEndCallModal } = this.state;
    const isThereAttendee = !!attendees.length;

    // Internet Explorer 6-11
    const isIE = /* @cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Logger is causing video loading issues in IE11 so we are disabling event logging in IE
    if (!isIE || !isEdge) {
      logger.config({
        jwt:      config.jwt,
        baseURL:  config.loggerBaseUrl,
        endpoint: config.loggerEndpoint
      });
    }

    if (this.notCompatibleBrowserCheck()) {
      return (
        <div className="not-supported-browser">
          <p>
            Your browser is incompatible with the video component of this application. Please upgrade to the latest
            version of Chrome or Firefox to do video consults.
          </p>
        </div>
      );
    }

    if (isBadVideoParams(config)) {
      return <CannotStart />;
    }

    return (
      <div>
        {
          pipWindow &&
          <PIPNotification
            isPublished={isPublished}
            isThereAttendee={isThereAttendee}
            onStartPublisher={this.onStartPublisher}
            onEndCall={this.onEndCall}
            pipWindow={pipWindow}
          />
        }
        {
          pipWindow
            ? createPortal(this.renderVideo(), pipWindow.document.querySelector('#pip_container'))
            : this.renderVideo()
        }
        {
          pipWindow &&
          <EndCallModal
            isShow={isShowEndCallModal}
            onClose={this.closeEndCallModal}
            onEndCall={this.onEndCall}
            pipWindow={pipWindow}
          />
        }
      </div>
    );
  }
}

Video.propTypes = {
  videoParams:                   PropTypes.object.isRequired,
  consultationId:                PropTypes.number.isRequired,
  canUsePIP:                     PropTypes.bool.isRequired,
  isMentalHealth:                PropTypes.bool.isRequired,
  canAutoCall:                   PropTypes.bool,
  callOptumNurseline:            PropTypes.func,
  fetchVendors:                  PropTypes.func,
  logConferenceEvent:            PropTypes.func,
  createConferenceStatistic:     PropTypes.func,
  createAutomatedCall:           PropTypes.func,
  warmTransferVendors:           PropTypes.array,
  onVideoExpanded:               PropTypes.func,
  attendeesNotificationsEnabled: PropTypes.bool
};

export { Video };

export default connect(({ conference }) => ({ warmTransferVendors: conference.warmTransferVendors }), {
  callOptumNurseline,
  fetchVendors,
  logConferenceEvent,
  createConferenceStatistic,
  createAutomatedCall
})(Video);
