import React from 'react';
import PropTypes from 'prop-types';

import SymptomListItem from './symptoms-list-item-container';
import ISymptom from '../../interfaces/symptom-interface';

const SymptomsList = ({ symptoms, listId }) => (
  <div className="symptoms-list">
    {symptoms.map(symptom => (
      <SymptomListItem
        key={`${symptom.code}-${listId}`}
        symptom={symptom}
        listId={listId}
      />
    ))}
  </div>
);

SymptomsList.propTypes = {
  symptoms: PropTypes.arrayOf(ISymptom).isRequired,
  listId:   PropTypes.string.isRequired
};

export default SymptomsList;
