import { teladocApi, TdApiRoutes, authToken } from '@td/api';
import { providerListStatesV4Path } from '../../config/paths';

import {
  START_CONFERENCE,
  CONFERENCE_START_BLOCKED,
  CONFERENCE_START_UNBLOCKED,
  CONFERENCE_CONFIRMATION_ACCEPT,
  CONFERENCE_CONFIRMATION_REJECT,
  FETCH_CONFERENCE,
  FETCH_VENDORS,
  FETCH_COUNTRY_PHONE_CODES,
  CALL_PARTICIPANT,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT,
  PROVIDER_SELECT_NOT_CONNECTED
} from './actionTypes';

import {
  CONFERENCE_NOT_STARTED,
  CONFERENCE_CANT_START,
  CONFERENCE_NEEDS_CONFIRMATION,
  CONFERENCE_IN_PROGRESS,
  CONFERENCE_COMPLETED
} from '../lib/states';

const startConference = () => ({
  type:    START_CONFERENCE,
  payload: CONFERENCE_NEEDS_CONFIRMATION
});

const blockConferenceStart = () => ({
  type:    CONFERENCE_START_BLOCKED,
  payload: CONFERENCE_CANT_START
});

const conferenceConfirmationAccept = () => ({
  type:    CONFERENCE_CONFIRMATION_ACCEPT,
  payload: CONFERENCE_IN_PROGRESS
});

const conferenceConfirmationReject = () => ({
  type:    CONFERENCE_CONFIRMATION_REJECT,
  payload: CONFERENCE_NOT_STARTED
});

const selectNotConnectedToConference = () => ({
  type: PROVIDER_SELECT_NOT_CONNECTED
});

const unblockConferenceStart = () => ({
  type:    CONFERENCE_START_UNBLOCKED,
  payload: CONFERENCE_NOT_STARTED
});

const fetchConference = consultationId => ({
  type:    FETCH_CONFERENCE,
  payload: teladocApi.get(
    TdApiRoutes.forConsult(consultationId).conferenceInformation(),
    null,
    authToken.get()
  )
});

const fetchVendors = consultationId => ({
  type:    FETCH_VENDORS,
  payload: teladocApi.get(
    TdApiRoutes.forConsult(consultationId).vendors(),
    null,
    authToken.get()
  )
});

const fetchCountryPhoneCodes = providerId => ({
  type:    FETCH_COUNTRY_PHONE_CODES,
  payload: teladocApi.get(
    providerListStatesV4Path(providerId),
    null,
    authToken.get()
  )
});

const callParticipant = (attrs, { dialMember }) => {
  const params = {
    external_id:      attrs.id,
    external_type_id: attrs.type,
    telephone_number: attrs.selectedNumber,
    conference_id:    attrs.conferenceId,
    consultation_id:  attrs.consultationId,
    dial:             dialMember,
    admin:            attrs.admin
  };

  return {
    type:    CALL_PARTICIPANT,
    payload: teladocApi.post(TdApiRoutes.media.createCall(), params, authToken.get())
  };
};

const addParticipant = ({ person, conferenceCall, conferenceId, consultationId }) => {
  const dialMember = person.name !== 'Hearing Impaired Relay';

  return ({
    type:        ADD_PARTICIPANT,
    participant: {
      person: {
        ...person,
        conferenceId,
        consultationId
      },
      conferenceCall
    },
    dialMember
  });
};

const removeParticipant = (id, name) => {
  const dialMember = name !== 'Hearing Impaired Relay';

  return {
    type: REMOVE_PARTICIPANT,
    id,
    dialMember
  };
};


export {
  startConference,
  blockConferenceStart,
  conferenceConfirmationAccept,
  conferenceConfirmationReject,
  selectNotConnectedToConference,
  unblockConferenceStart,
  fetchConference,
  fetchVendors,
  fetchCountryPhoneCodes,
  callParticipant,
  addParticipant,
  removeParticipant
};
