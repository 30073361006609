import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Error } from 'medication_service_ui';
import ProviderOrderList from './ProviderOrderList';
import ProviderOrderListItem from './ProviderOrderListItem';
import PendingOrderListItem from './pending-order-list-item';

import Modal from 'app/components/Modal';
import { PrescriptionUI as Prescription } from 'medication_service_ui';

import '../styles.scss';

class ProviderOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetched:        false,
      providerOrders: [],
      isLoading:      false,
      providerId:     '',
      isSaving:       false,
      editMode:       false
    };
  }

  componentDidMount() {
    const {
      consultationParams: { consultationId }
    } = this.props;
    this.props.fetchProviderOrders({ consultationId });
  }

  deleteHandler = event => {
    event.preventDefault();
    this.props.deleteProviderOrder();
  };

  toggleEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  editPendingOrder = editedPrescription => {
    this.setState({ editedPrescription });
  };

  deactivateModal = () => {
    this.setState({ editedPrescription: null });
  };



  render() {
    let { displayOptions } = this.props;
    const {  
      errorMessage,
      providerOrders,
      pendingPrescriptions = [],
      revokeProviderOrder,
      revokePending,
      consultationParams,
      removeFromPendingPrescriptionList,
      displayOptions: displayOptionsProps,
      settings: {
        providerParams: { canDoCancelRx },
        providerParams,
        memberParams,
        pharmacyParams
      },
      displayOptions: { section },
      revokeSuccess
    } = this.props;

    if (section === 'rxView' && pendingPrescriptions.length > 0) {
      displayOptions = Object.assign({}, displayOptionsProps, { showActions: true });
    }
    
    const { editedPrescription } = this.state;

    const isSubmittedOrder = order => !order.pendingSave;
    const allPrescriptions = [...providerOrders, ...pendingPrescriptions];
    const providerOrderListDisplayOptions = {
      ...displayOptions,
      showActions: displayOptions.showActions && allPrescriptions.some(isSubmittedOrder)
    };

    const providerOrderListItems = providerOrders.map(order => (
      <ProviderOrderListItem
        key={order.providerOrderId}
        providerOrder={order}
        toggleEditMode={this.toggleEditMode}
        onDelete={this.deleteHandler}
        displayOptions={displayOptions}
        canDoCancelRx={canDoCancelRx}
        revokeHandlerFunc={revokeProviderOrder}
        revokePending={revokePending}
        revokeSuccess={revokeSuccess}
        consultationParams={consultationParams}
        providerParams={providerParams}
        memberParams={memberParams}
        pharmacyParams={pharmacyParams}
      />
    ));

    const pendingOrderListItems = pendingPrescriptions.map(order => {
      const orderDisplayOptions = {
        ...displayOptionsProps,
        showActions: displayOptions.showActions && isSubmittedOrder(order)
      }

      return (
        <PendingOrderListItem
          pendingOrder={order}
          onRemoveOrder={removeFromPendingPrescriptionList}
          onEditOrder={this.editPendingOrder}
          displayOptions={ orderDisplayOptions }
          key={order.uid}
        />
      );
    });
   
    const combinedOrders = providerOrderListItems.concat(pendingOrderListItems);

    if (errorMessage) {
      return <Error message={errorMessage} />;
    }

    if (displayOptions.displayBlank && !combinedOrders.length > 0) {
      return <div>None</div>;
    }

    return (
      <div id="provider_order">
        {combinedOrders.length > 0 && (
          <ProviderOrderList 
            editMode={pendingOrderListItems.length > 0} 
            displayOptions={providerOrderListDisplayOptions}>
            {combinedOrders}
          </ProviderOrderList>
        )}
        {editedPrescription && (
          <Modal show={!!editedPrescription} onClose={this.deactivateModal}>
            <Prescription editedPrescription={editedPrescription} onSubmit={this.deactivateModal} />
          </Modal>
        )}
      </div>
    );
  }
}
ProviderOrder.defaultProps = {
  displayOptions: {
    showRequester: false,
    showActions:   true,
    displayBlank:  false
  }
};

ProviderOrder.propTypes = {
  providerId:         PropTypes.number,
  consultationParams: PropTypes.shape({
    consultationId: PropTypes.number.isRequired
  }).isRequired,
  settings: {
    providerParams: PropTypes.shape({
      canDoCancelRx: PropTypes.bool
    })
  },
  fetched:                           PropTypes.bool,
  isLoading:                         PropTypes.bool,
  providerOrders:                    PropTypes.array.isRequired,
  pendingPrescriptions:              PropTypes.array.isRequired,
  errorMessage:                      PropTypes.string,
  deleteProviderOrder:               PropTypes.func.isRequired,
  fetchProviderOrders:               PropTypes.func.isRequired,
  revokeProviderOrder:               PropTypes.func.isRequired,
  revokePending:                     PropTypes.bool.isRequired,
  removeFromPendingPrescriptionList: PropTypes.func.isRequired,
  displayOptions:                    PropTypes.shape({
    showRequester: PropTypes.bool,
    showActions:   PropTypes.bool,
    displayBlank:  PropTypes.bool
  }).isRequired,
  revokeSuccess: PropTypes.bool.isRequired
};

export default ProviderOrder;