exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3yPi5OnjtrkzKPjADvAu_c {\n  display: grid;\n  grid-template-columns: auto minmax(0, 100%);\n  grid-gap: 0.875rem 0.5rem;\n  font-size: 0.75rem;\n  color: #000000;\n  max-height: 280px;\n  overflow-y: auto; }\n", ""]);

// exports
exports.locals = {
	"logsContainer": "_3yPi5OnjtrkzKPjADvAu_c",
	"logsContainer": "_3yPi5OnjtrkzKPjADvAu_c"
};