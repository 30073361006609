import * as yup from 'yup';

const schemaValidations = () =>
  yup.lazy(() =>
    yup.object().shape({
      confirmation: yup
        .boolean()
        .oneOf([true], 'Please confirm the above prescription(s) and instructions are correct.'),
      selectedMedication: yup
        .object().shape({
          code:            yup.object().shape({}),
          issuingBodyId:   yup.string(),
          issuingBodyCode: yup.string()
        }).nullable(),
      sig: yup.object().shape({
        quantity: yup
          .string()
          .required('Quantity cannot blank'),
        direction: yup
          .string()
          .required('Select a direction e.g Take or Inject'),
        unit: yup
          .string()
          .required('Unit cannot blank'),
        route: yup
          .string(),
        frequency: yup
          .string()
          .required('Frequency cannot blank'),
        additionalInstructions: yup
          .string()
      }),
      totalQuantity: yup
        .number('Total quantity must be a number type')
        .positive('Total quantity must be greater than zero')
        .required('Total quantity cannot blank'),
      refills: yup
        .string()
        .required()
    }));

export default schemaValidations;
