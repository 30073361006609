import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useQuickDxFavorites, isDiagnosisCodeFavorite } from '../utils';
import { AutoCompleteCategoryList } from './AutoCompleteCategoryList';
import { translateMarkupString, translate, I18n } from '@td/shared_utils';

export const QuickDxAutoComplete = ({ searchTerm, searchQuery, show, dxData, ...props }) => {
  const { favorites: quickDxFavoritesList, quickDxFavoritesData } = useQuickDxFavorites();
  const searchResults = _.get(dxData, 'data.searchQuickDx', []);

  const quickDxResults = searchResults.filter(({ diagnosisCode }) => isDiagnosisCodeFavorite(diagnosisCode));
  const nonQuickDxResults = searchResults.filter(({ diagnosisCode }) => !isDiagnosisCodeFavorite(diagnosisCode));

  const quickDxIsLoading = _.get(quickDxFavoritesData, 'query.loading', false);
  const isLoading = _.get(dxData, 'loading', false);
  const searchTermIsDebounced = searchTerm.debounced === searchTerm.actual;
  const noResults = searchQuery.length >= 3 && searchResults.length === 0 && !isLoading;

  const sections = [
    {
      label:         translate(null, 'quick_dx', 'quick_dx_label'),
      dxList:        searchQuery ? quickDxResults : quickDxFavoritesList,
      favoritesOnly: true
    },
    {
      label:  translate(null, 'quick_dx', 'search_results'),
      dxList: searchTerm.actual.length >= 3 ? nonQuickDxResults : []
    }
  ];

  if (isLoading || quickDxIsLoading || (!searchTermIsDebounced && searchTerm.actual.length > 0)) {
    return (
      show && (
        <div className="quick-dx-autocomplete no-results">
          <h2>
            <I18n scope="quick_dx" text="loading" />
          </h2>
        </div>
      )
    );
  }

  if (noResults) {
    return (
      show && (
        <div className="quick-dx-autocomplete no-results">
          <h2>{translateMarkupString('quick_dx', 'no_results_found_for_query', {}, { searchQuery })}</h2>
        </div>
      )
    );
  }

  return (
    show && (
      <div className="quick-dx-autocomplete">
        {sections.map(({ label, dxList, favoritesOnly }) => (
          <AutoCompleteCategoryList
            {...props}
            key={label}
            label={label}
            diagnoses={dxList}
            favoritesOnly={favoritesOnly}
          />
        ))}
      </div>
    )
  );
};

QuickDxAutoComplete.displayName = 'QuickDxAutoComplete';

QuickDxAutoComplete.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  show:        PropTypes.bool.isRequired,
  dxData:      PropTypes.shape({
    data: PropTypes.shape({
      searchQuickDx: PropTypes.arrayOf(PropTypes.shape(PropTypes.object))
    })
  })
};
