import {
  FETCH_PROVIDER_ORDER_REQUEST,
  FETCH_PROVIDER_ORDER_START,
  FETCH_PROVIDER_ORDER_SUCCESS,
  FETCH_PROVIDER_ORDER_FAIL,
  UPDATE_PROVIDER_ORDER_SUCCESS,
  UPDATE_PROVIDER_ORDER_FAIL,
  REVOKE_PROVIDER_ORDER_REQUEST,
  REVOKE_PROVIDER_ORDER_START,
  REVOKE_PROVIDER_ORDER_SUCCESS,
  REVOKE_PROVIDER_ORDER_FAIL,
  DELETE_PROVIDER_ORDER,
  DELETE_PROVIDER_ORDER_SUCCESS,
  DELETE_PROVIDER_ORDER_FAIL
} from './actionTypes';

export const revokeProviderOrderStart = () => ({
  type: REVOKE_PROVIDER_ORDER_START
});

export const revokeProviderOrderSuccess = payload => ({
  type: REVOKE_PROVIDER_ORDER_SUCCESS,
  payload
});

export const revokeProviderOrderFail = error => ({
  type:    REVOKE_PROVIDER_ORDER_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const revokeProviderOrder = payload => ({
  type: REVOKE_PROVIDER_ORDER_REQUEST,
  payload
});

export const fetchProviderOrderStart = () => ({
  type: FETCH_PROVIDER_ORDER_START
});

export const fetchProviderOrderSuccess = payload => ({
  type: FETCH_PROVIDER_ORDER_SUCCESS,
  payload
});

export const fetchProviderOrderFail = error => ({
  type:    FETCH_PROVIDER_ORDER_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const fetchProviderOrders = payload => ({
  type: FETCH_PROVIDER_ORDER_REQUEST,
  payload
});

export const updateProviderOrderSuccess = () => ({
  type: UPDATE_PROVIDER_ORDER_SUCCESS
});

export const updateProviderOrderFail = () => ({
  type: UPDATE_PROVIDER_ORDER_FAIL
});

export const deleteProviderOrder = payload => ({
  type: DELETE_PROVIDER_ORDER,
  payload
});

export const deleteProviderOrderSuccess = () => ({
  type: DELETE_PROVIDER_ORDER_SUCCESS
});

export const deleteProviderOrderFail = () => ({
  type: DELETE_PROVIDER_ORDER_FAIL
});
