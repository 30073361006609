import { connect } from 'react-redux';

import Button from '../../../components/Button';

export const mapStateToProps = (state, props) => ({
  disabled: Boolean(
    state.networkTest.checkNetworkTestNeededRequest.isLoading ||
      state.sidebar.providerClockInOut.clockInRequest.isLoading ||
      state.sidebar.providerClockInOut.clockOutRequest.isLoading ||
      props.disableButton
  )
});

export default connect(mapStateToProps)(Button);
