import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FluentProvider, webLightTheme, Spinner } from "@fluentui/react-components";
import TeladocModal from '../../TeladocModal';
import ConsultationReschedulingModalForm from './consultation-rescheduling-modal-form';
import getReschedulingInfo from './apis/RescheduleInfo';

const ConsultationReschedulingTeladocModal = ({
  consultationId,
  memberId,
  providerId,
  isWebsdkRescheduleToggle,
  isTimeSlotOnly,
  onClose
}) => {
  const [data, setData] = useState({});

  useEffect(() => {
    getReschedulingInfo({
      consultationId,
      onSuccess: (res) => {
        setData(res.data);
      }
    });
  }, []);

  return (
    <TeladocModal onClose={onClose} isOpen title={"Reschedule"}>
      {isEmpty(data) ?
        <FluentProvider theme={webLightTheme}>
          <div className="reschedulingModalLoader">
            <Spinner size="small" label="Loading..." />
          </div>
        </FluentProvider>
        :
        <ConsultationReschedulingModalForm
          onClose={onClose}
          consultationId={`${consultationId}`}
          memberId={`${memberId}`}
          providerId={`${providerId}`}
          isWebsdkRescheduleToggle={isWebsdkRescheduleToggle}
          isTimeSlotOnly={isTimeSlotOnly}
          requestedDates={data.requestedDates}
          reschedulingIntervals={data.reschedulingIntervals}
          intervalBetweenScheduling={data.intervalBetweenScheduling}
        />
      }
    </TeladocModal>
  );
}

ConsultationReschedulingTeladocModal.propTypes = {
  onClose:                   PropTypes.func.isRequired,
  consultationId:            PropTypes.number.isRequired,
  memberId:                  PropTypes.number.isRequired,
  providerId:                PropTypes.number.isRequired,
  isWebsdkRescheduleToggle:  PropTypes.bool,
  isTimeSlotOnly:            PropTypes.bool
};

export default ConsultationReschedulingTeladocModal;
