exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1yxlYAWnZ3rUMdppq04vEP {\n  display: flex;\n  flex-direction: column;\n  gap: 20px; }\n\n._1ypwE9Z4pFuabdiIJ3TkpZ {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16px;\n  color: #7C7D74;\n  margin-top: 10px; }\n\n._1rdeUjm6Az0K4-wsWN0cuz .Qr4bAbWRVMn3JUj7Wbt4O {\n  display: flex;\n  gap: 16px;\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 20px;\n  text-align: left;\n  color: #244861; }\n\n._1rdeUjm6Az0K4-wsWN0cuz ._1k0k9L0WLKZo0mLIhfFU8r {\n  display: flex;\n  height: 64px;\n  gap: 8px;\n  align-items: center;\n  padding: 24px 10px 24px 32px;\n  box-sizing: border-box; }\n  ._1rdeUjm6Az0K4-wsWN0cuz ._1k0k9L0WLKZo0mLIhfFU8r:not(:last-child) {\n    border-bottom: 1px solid rgba(124, 125, 116, 0.3); }\n  ._1rdeUjm6Az0K4-wsWN0cuz ._3RuIZ0ppViDBiSE7dqOP1o {\n    font-size: 12px;\n    font-weight: 700;\n    line-height: 16px;\n    text-overflow: ellipsis;\n    width: 100%;\n    max-width: 40%;\n    white-space: nowrap;\n    overflow: hidden; }\n  ._1rdeUjm6Az0K4-wsWN0cuz .oNiqe-67a97UPmVzOPzcM {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    max-width: 50%; }\n    ._1rdeUjm6Az0K4-wsWN0cuz .oNiqe-67a97UPmVzOPzcM input {\n      margin-right: 8px; }\n    ._1rdeUjm6Az0K4-wsWN0cuz ._2qCdWbPkG-HR_JEQRusGlv {\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden; }\n\n._36iQA06jEZfuQy-i2kaQae ._5vLhhnWSZNK9ZiDuHKiJv,\n._36iQA06jEZfuQy-i2kaQae ._2-pssIM-nls_zqb2GhSXjP {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16px; }\n\n._36iQA06jEZfuQy-i2kaQae ._2-pssIM-nls_zqb2GhSXjP {\n  margin-top: 16px; }\n\n.MyJg0c51My9bHUdppXqO7 {\n  max-height: 192px;\n  overflow-x: hidden; }\n", ""]);

// exports
exports.locals = {
	"calendarSettingsModalContent": "_1yxlYAWnZ3rUMdppq04vEP",
	"calendarSettingsModalContent": "_1yxlYAWnZ3rUMdppq04vEP",
	"calendarSettingsModalSubtitle": "_1ypwE9Z4pFuabdiIJ3TkpZ",
	"calendarSettingsModalSubtitle": "_1ypwE9Z4pFuabdiIJ3TkpZ",
	"calendarSettingsModal": "_1rdeUjm6Az0K4-wsWN0cuz",
	"calendarSettingsModal": "_1rdeUjm6Az0K4-wsWN0cuz",
	"externalAccountTile": "Qr4bAbWRVMn3JUj7Wbt4O",
	"externalAccountTile": "Qr4bAbWRVMn3JUj7Wbt4O",
	"externalCalendarTile": "_1k0k9L0WLKZo0mLIhfFU8r",
	"externalCalendarTile": "_1k0k9L0WLKZo0mLIhfFU8r",
	"externalCalendarTile_calendarName": "_3RuIZ0ppViDBiSE7dqOP1o",
	"externalCalendarTileCalendarName": "_3RuIZ0ppViDBiSE7dqOP1o",
	"externalCalendarTile_checkbox": "oNiqe-67a97UPmVzOPzcM",
	"externalCalendarTileCheckbox": "oNiqe-67a97UPmVzOPzcM",
	"externalCalendarTile_checkboxLabel": "_2qCdWbPkG-HR_JEQRusGlv",
	"externalCalendarTileCheckboxLabel": "_2qCdWbPkG-HR_JEQRusGlv",
	"calendarSettingsConfirmationModal": "_36iQA06jEZfuQy-i2kaQae",
	"calendarSettingsConfirmationModal": "_36iQA06jEZfuQy-i2kaQae",
	"connectingNote": "_5vLhhnWSZNK9ZiDuHKiJv",
	"connectingNote": "_5vLhhnWSZNK9ZiDuHKiJv",
	"disconnectingNote": "_2-pssIM-nls_zqb2GhSXjP",
	"disconnectingNote": "_2-pssIM-nls_zqb2GhSXjP",
	"calendarSettingsItems": "MyJg0c51My9bHUdppXqO7",
	"calendarSettingsItems": "MyJg0c51My9bHUdppXqO7"
};