import React, { createContext, useContext } from 'react';
import useFetchRequestedConsultations from '../../hooks/useFetchRequestedConsultations';

const RequestedAndProposedVisitsTableContext = createContext();

export const useRequestedAndProposedVisitsTable = () => useContext(RequestedAndProposedVisitsTableContext);

export const RequestedAndProposedVisitsTableProvider = ({ children }) => {
  const consultationsData = useFetchRequestedConsultations();

  return (
    <RequestedAndProposedVisitsTableContext.Provider value={consultationsData}>
      {children}
    </RequestedAndProposedVisitsTableContext.Provider>
  );
};
