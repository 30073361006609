import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import npsGauge from './nps-gauge';
import PROVIDER_NET_PROMOTER_METRICS from './queries/provider-net-promoter-metrics.graphql';
function NetPromoterScore(props) {
  const { loading, error, data } = useQuery(PROVIDER_NET_PROMOTER_METRICS, {});
  useEffect(() => {
    if (data && data.currentUserProvider.netPromoterScore) {
      props.npsGauge(data.currentUserProvider.netPromoterScore, '#linear-gauge');
    }
  });

  if (data && data.currentUserProvider.netPromoterDetails) {
    // piwik tracking
    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event:              'provider-nps',
        'app-name':         'provider-web',
        netPromoterScore:   data.currentUserProvider.netPromoterScore,
        netPromoterDetails: data.currentUserProvider.netPromoterDetails.substring(0, 200)
      });
    }
    return (
      <div className="module quick_links">
        <h1>{props.title}</h1>
        <a href={props.infoURL} id="nps-info">
          {props.infoTitle}
        </a>
        <p>{props.npsDescription}</p>
        <div id="linear-gauge" />
        <p id="nps-details">
          <i>{data ? data.currentUserProvider.netPromoterDetails : ''}</i>
        </p>
        <div id="nps-footer">
          <a href={props.improvementURL} id="nps-improvement">
            {props.improvementTitle}
          </a>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

NetPromoterScore.defaultProps = {
  title:            'Your Net Promoter Score',
  npsDescription:   'A measure of how likely your patients are to recommend your services to a friend.',
  npsGauge,
  infoURL:          'https://static-teladoc-com.s3.us-east-2.amazonaws.com/what_is_physician_nps.pdf',
  infoTitle:        "What's this?",
  improvementURL:   'https://static-teladoc-com.s3.us-east-2.amazonaws.com/physician_nps_improve_score.pdf',
  improvementTitle: 'How can I improve?'
};

export default NetPromoterScore;
