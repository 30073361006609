import { connect } from 'react-redux';
import ClinicalMedication from './ClinicalMedication';
import {
  fetchClinicalMedications,
  submitClinicalMedicationForm,
  fetchMedicationList,
  setSelectedMedication,
  resetMedicationList,
  addToPendingMedicationList,
  removeFromPendingMedicationList,
  clearPendingMedicationList
} from '../actions';

const mapStateToProps = state => {
  const {
    clinicalMedications: {
      medications: clinicalMedications,
      medicationAutosuggest,
      medicationForm,
      pendingMedications,
      surescriptsUpdated
    }
  } = state;

  const medication = {
    activelyTakingFlag:   '',
    clinicalMedicationId: null,
    createdAt:            '',
    createdBy:            null,
    createdByType:        '',
    dawFlag:              null,
    drugCode:             '',
    drugFrequency:        '',
    drugName:             '',
    drugQuantity:         '',
    drugQuantityUnit:     '',
    drugSupply:           null,
    drugTypeCode:         ''
  };

  return {
    fetched:            clinicalMedications.fetched,
    errorMessage:       clinicalMedications.message,
    isLoading:          clinicalMedications.isLoading,
    clinicalMeds:       clinicalMedications.clinicalMeds,
    surescriptsUpdated: clinicalMedications.surescriptsUpdated,
    suggestions:        medicationAutosuggest.suggestions,
    selectedMed:        medicationAutosuggest.selectedMed,
    formData:           medicationForm.formData,
    validation:         medicationForm.validation,
    submitResult:       medicationForm.submitResult,
    pendingMedications: pendingMedications.medications,
    medication
  };
};

const mapDispatchToProps = dispatch => ({
  fetchClinicalMedications: memberId => {
    dispatch(fetchClinicalMedications(memberId));
  },
  submitClinicalMedicationForm: formData => {
    dispatch(submitClinicalMedicationForm(formData));
  },
  fetchMedicationList: value => {
    dispatch(fetchMedicationList(value));
  },
  resetMedicationList: () => {
    dispatch(resetMedicationList());
  },
  setSelectedMedication: selection => {
    dispatch(setSelectedMedication(selection));
  },
  addToPendingMedicationList: medication => {
    dispatch(addToPendingMedicationList(medication));
  },
  removeFromPendingMedicationList: medication => {
    dispatch(removeFromPendingMedicationList(medication));
  },
  clearPendingMedicationList: () => {
    dispatch(clearPendingMedicationList());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalMedication);
