exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._2oF0q4NxoFwntiRZfgA94Z {\n  margin-right: 16px; }\n", ""]);

// exports
exports.locals = {
	"externalCalendarIcon": "_2oF0q4NxoFwntiRZfgA94Z",
	"externalCalendarIcon": "_2oF0q4NxoFwntiRZfgA94Z"
};