// Constants
const FETCH_PROVIDER_REQUEST    = 'FETCH_PROVIDER_REQUEST';
const FETCH_PROVIDER_START      = 'FETCH_PROVIDER_START';
const FETCH_PROVIDER_SUCCESS    = 'FETCH_PROVIDER_SUCCESS';
const FETCH_PROVIDER_FAIL       = 'FETCH_PROVIDER_FAIL';
const FETCH_PROVIDER_CANCEL     = 'FETCH_PROVIDER_CANCEL';
const UPDATE_PROVIDER_SUCCESS   = 'UPDATE_PROVIDER_SUCCESS';
const UPDATE_PROVIDER_FAIL      = 'UPDATE_PROVIDER_FAIL';
const TRACK_FOR_CLOCK_OUT       = 'TRACK_FOR_CLOCK_OUT';
const TRACK_CONSULT_INACTIVITY  = 'TRACK_CONSULT_INACTIVITY';
const TRACK_LOCKED_CONSULT      = 'TRACK_LOCKED_CONSULT';
const TRACK_IN_REVIEW_CONSULT   = 'TRACK_IN_REVIEW_CONSULT';
const START_REVIEW              = 'START_REVIEW';
const EXTEND_EHR_REVIEW         = 'EXTEND_EHR_REVIEW';
const LOG_ALERT_ACKNOWLEDGEMENT = 'LOG_ALERT_ACKNOWLEDGEMENT';

export {
  FETCH_PROVIDER_REQUEST,
  FETCH_PROVIDER_START,
  FETCH_PROVIDER_SUCCESS,
  FETCH_PROVIDER_CANCEL,
  FETCH_PROVIDER_FAIL,
  UPDATE_PROVIDER_SUCCESS,
  UPDATE_PROVIDER_FAIL,
  TRACK_FOR_CLOCK_OUT,
  TRACK_CONSULT_INACTIVITY,
  TRACK_LOCKED_CONSULT,
  TRACK_IN_REVIEW_CONSULT,
  START_REVIEW,
  EXTEND_EHR_REVIEW,
  LOG_ALERT_ACKNOWLEDGEMENT
};
