import React from 'react';

const Minus = ({ onClick }) => (
  <div className="mds_minus">
    <a className="mds_center" onClick={onClick}>
      Remove
    </a>
  </div>
);

export default Minus;
