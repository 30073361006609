import { useCallback, useMemo, useState } from 'react';
import getClientInstance from '../../services/apollo-client';
import NOTIFY_NURSE from '../mutations/notify-nurse.graphql';

const successSelector = ({ notifyNurse: { success } }) => success;

const useNurseNotification = ({ consultationId, roleCd, onSuccess = () => {} }) => {
  const apolloClient = useMemo(() => getClientInstance(), []);
  const [inProgress, setInProgress] = useState(false);

  const notify = useCallback(async () => {
    if (inProgress) {
      return;
    }

    try {
      setInProgress(true);

      const { data } = await apolloClient.mutate({
        mutation:  NOTIFY_NURSE,
        variables: { consultationId, roleCd }
      });

      const isSuccess = successSelector(data);

      if (isSuccess) {
        onSuccess();
      }
    } finally {
      setInProgress(false);
    }
  }, [consultationId, roleCd, onSuccess, inProgress]);

  return {
    notify,
    inProgress
  };
};

export default useNurseNotification;
