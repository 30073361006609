import React from 'react';
import PropTypes from 'prop-types';
import KeepAlive from '../../keep-alive/keep-alive';

export const MultiView = ({ children, attendees, canUsePIP, ...restOfProps }) => {
  const attendeeNm = child => JSON.parse(child.props.stream.connection.data).userName;

  const attendeesElements = child => {
    return (
      <div className="attendee">
        <KeepAlive
          id={`attendee-${child.props.stream.streamId}`}
          useKeepAlive={canUsePIP}
          containerClassName='keep_alive_attendee'
          portalClassName='keep_alive_attendee'
        >
          {React.cloneElement(child, { ...restOfProps })}
        </KeepAlive>
        {renderSignature(attendeeNm(child), child)}
      </div>
    );
  }
  const renderSignature = attendeeName => (
    <div className="attendee_title">
      <h3>{attendeeName}</h3>
    </div>
  );
  const renderAdmins = adminType =>
    children && children.some(child => child.props.userType.includes(adminType)) ? (
      <div className="attendee-admins">
        {childIterator(`${adminType}_ma`)}
        {childIterator(`${adminType}_rn`)}
      </div>
    ) : null;

  const childIterator = attendeeType =>
    React.Children.map(children, child => (child.props.userType === attendeeType ? attendeesElements(child) : null));

  const checkType = type => attendees && attendees.some(x => JSON.parse(x.connection.data).userType === type);

  return (
    <div className={attendees.length > 1 && checkType('tdoc_member') ? 'multi-view' : 'single-view'}>
      {childIterator('tdoc_member')}
      {renderAdmins('tdoc_admin')}
    </div>
  );
};

MultiView.propTypes = {
  children:  PropTypes.node,
  attendees: PropTypes.arrayOf(Object).isRequired,
  canUsePIP: PropTypes.bool.isRequired
};
