exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".afJPDwmC3nfyDmXslIvMc {\n  color: #696B6B;\n  text-align: right; }\n", ""]);

// exports
exports.locals = {
	"LogItemTime": "afJPDwmC3nfyDmXslIvMc",
	"logItemTime": "afJPDwmC3nfyDmXslIvMc"
};