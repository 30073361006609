import { get } from 'lodash';
import { FETCH_NEEDS_RESPONSE_MESSAGES } from './actionTypes';

const INITIAL_STATE = {
  needsResponseMessages: false
};

const needsResponseMessagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_NEEDS_RESPONSE_MESSAGES:
      if (action.payload.status !== 200) {
        const serverError = get(action, 'payload.response.data.error', 'Unknown error occured');

        return {
          ...state,
          errors: serverError
        };
      }

      return {
        ...state,
        needsResponseMessages: action.payload.data.needs_response_messages
      };

    default:
      return state;
  }
};

export default needsResponseMessagesReducer;
