import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProviderClockInOutButton from './provider-clock-in-out-button-container';

import { clockOutProvider } from '../actions';

export const ProviderClockOutButtonContainer = ({ text, onClick }) => (
  <ProviderClockInOutButton className="clock-out-button" text={text} onClick={onClick} />
);

ProviderClockOutButtonContainer.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

ProviderClockOutButtonContainer.defaultProps = {
  text: 'Clock Out'
};

const mapDispatchToProps = {
  onClick: clockOutProvider.start
};

export default connect(
  null,
  mapDispatchToProps
)(ProviderClockOutButtonContainer);
