import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { SurveyQuestion } from '.';

const getCachedQuestion = (cachedSectionQuestions, question) => {
  const questionInfo = cachedSectionQuestions.find(
    cachedQuestion => question.questionCode === get(cachedQuestion, 'table.question_code')
  );

  return get(questionInfo, 'table', null);
};

export const SurveySection = ({ section, sectionConfig, surveyNamingKey, styles, cachedSectionQuestions }) =>
  section.questions
    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
    .map(question => (
      <div key={question.questionCode}>
        <input
          type="hidden"
          name={`${surveyNamingKey}[surveys][][sections][][questions][][question_code]`}
          value={question.questionCode}
        />
        <SurveyQuestion
          question={question}
          section={section}
          questionConfig={sectionConfig[question.questionCode]}
          surveyNamingKey={surveyNamingKey}
          styles={styles}
          cachedQuestion={getCachedQuestion(cachedSectionQuestions, question)}
        />
      </div>
    ));

SurveySection.propTypes = {
  section:                PropTypes.object,
  sectionConfig:          PropTypes.object,
  surveyNamingKey:        PropTypes.string,
  styles:                 PropTypes.object,
  cachedSectionQuestions: PropTypes.array
};
