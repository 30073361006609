import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import moment from 'moment';
import TeladocModal from '../../../TeladocModal';
import { regularWorkingHours } from '../../../my-schedule/constants';

const AvailabilityReminder = ({ forceOpen = false }) => {
  const areRegularWorkingHoursSet = regularWorkingHours && regularWorkingHours.length > 0;
  let regularWorkingHoursOutdated = false;
  const today = moment();
  map(regularWorkingHours, regularWorkingHour => {
    const difference = today.diff(regularWorkingHour.updatedAt, 'days');
    if (difference >= 60) regularWorkingHoursOutdated = true;
  });

  const [isOpen, setIsOpen] = useState(regularWorkingHoursOutdated);

  return (
    <TeladocModal
      className="availabilityReminder"
      title="Keep your schedule up-to-date"
      isOpen={forceOpen || isOpen}
      showCloseButton={false}
    >
      <div className="content">
        <p>Patients can schedule visits during your working hours up to 90 days in advance.</p>
        <p>Make sure your hours are up-to-date and personal events are on your schedule.</p>
      </div>
      <div className="actionButtons">
        <a
          id="dialogConfirmButton"
          href="/calendar#active=regular_working_hours"
          key="dialogConfirmButton"
          className="button"
        >
          UPDATE WORKING HOURS
        </a>
      </div>
    </TeladocModal>
  );
};

AvailabilityReminder.propTypes = {
  forceOpen: PropTypes.bool
};

export default AvailabilityReminder;
