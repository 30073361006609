import React, { useContext, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { useLazyQuery, useMutation } from '@apollo/client';
import GET_EXTERNAL_CALENDAR_ACCOUNT_ALERTS_QUERY from '../queries/get-external-calendar-account-alerts.graphql';
import CLOSE_EXTERNAL_CALENDAR_ACCOUNT_ALERT_MUTATION from '../mutations/close-external-calendar-account-alert.graphql';
import { forEach, get, reject, values } from 'lodash';
import { alertCodes } from '../constants';
import { translate } from '@td/shared_utils';

export const ExternalCalendarAccountAlertsContext = React.createContext({});

const ExternalCalendarAccountAlertsProvider = ({ providerId, ...props }) => {
  const [externalCalendarAccountAlerts, setExternalCalendarAccountAlerts] = useState([]);

  const [fetchExternalCalendarAccountAlerts] = useLazyQuery(GET_EXTERNAL_CALENDAR_ACCOUNT_ALERTS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      providerId: `${providerId}`,
      refAlertCds: values(alertCodes),
      onlyUnviewed: true
    },
    onCompleted: ({ provider: { alerts } }) => {
      setExternalCalendarAccountAlerts(alerts);
    },
    onError: () => {
      toastr.error(translate(null, 'external_calendar_accounts', 'server_error'));
    }
  });

  const [mutateCloseExternalCalendarAccountAlert] = useMutation(CLOSE_EXTERNAL_CALENDAR_ACCOUNT_ALERT_MUTATION, {
    onCompleted: ({ viewAlert: { success, errors } }) => {
      if (!success) {
        forEach(errors, (error) => toastr.error(error))
      }
    },
    onError: () => {
      toastr.error(translate(null, 'external_calendar_accounts', 'server_error'));
    }
  });

  const closeExternalCalendarAccountAlert = useCallback(({ alertId }) => {
    setExternalCalendarAccountAlerts((prevState) => reject(prevState, { id: alertId }));

    mutateCloseExternalCalendarAccountAlert({
      variables: {
        alertId,
      }
    });
  }, [
    setExternalCalendarAccountAlerts,
    mutateCloseExternalCalendarAccountAlert
  ]);

  const value = useMemo(() => ({
    externalCalendarAccountAlerts,
    fetchExternalCalendarAccountAlerts,
    closeExternalCalendarAccountAlert
  }), [
    externalCalendarAccountAlerts,
    fetchExternalCalendarAccountAlerts,
    closeExternalCalendarAccountAlert
  ]);

  return (
    <ExternalCalendarAccountAlertsContext.Provider
      {...props}
      value={value}
    />
  );
};

export const useExternalCalendarAccountAlerts = () => {
  const context = useContext(ExternalCalendarAccountAlertsContext);

  if (context === undefined) {
    throw new Error('useExternalCalendarAccountAlerts must be used within a ExternalCalendarAccountAlertsProvider');
  }

  return context;
};

ExternalCalendarAccountAlertsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  providerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
};

export default ExternalCalendarAccountAlertsProvider;
