import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { get, isEmpty, isArray } from 'lodash';
import Loader from 'app/components/Loader';
import CONSULTATION_DIAGNOSIS_SHORTCUTS_QUERY from './queries/consultation-diagnosis-shortcuts-query.graphql';
import ShortcutLink from './components/ShortcutLink';

const extractShortcutsFromData = data => {
  const json = get(data, 'consultation.diagnosisShortcuts');

  let parsed;

  try {
    parsed = JSON.parse(json);
  } catch (error) {
    return [];
  }

  const shortcuts = get(parsed, 'data');

  if (!isArray(shortcuts)) return [];

  return shortcuts;
};

const ConsultationDiagnosisShortcuts = ({ consultationId }) => {
  const { loading, error, data } = useQuery(CONSULTATION_DIAGNOSIS_SHORTCUTS_QUERY, {
    variables: { id: consultationId }
  });

  if (loading) return <Loader />;
  if (error) return null;

  const shortcuts = extractShortcutsFromData(data);

  if (isEmpty(shortcuts)) return null;

  const renderShortcut = element => <ShortcutLink key={element.zcode} element={element} />;

  return (
    <div className="mds_base_container">
      <div className="mds_col_1_flex">
        <div className="label">Shortcuts:</div>
      </div>
      <div className="mds_col_7_flex">{shortcuts.map(renderShortcut)}</div>
    </div>
  );
};

ConsultationDiagnosisShortcuts.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default ConsultationDiagnosisShortcuts;
