import moment from 'moment-timezone';
import { get, has } from 'lodash';

import validateSchema from '../../calendar/services/provider-calendar-validations';
import { scheduleTypes } from '../constants';

const saveShiftV2 = ({
  formState,
  selectedEvent,
  validateProps,
  validatePropsMessages,
  setInterfaceError,
  setModalErrorMessage,
  callCreateShift,
  callUpdateShift,
  isProviderCalendarV2
}) => {
  const DEFAULT_EVENT_NAME =
    isProviderCalendarV2 && scheduleTypes.PROVSCHEDEVENT_ATB.label
      ? scheduleTypes.PROVSCHEDEVENT_ATB.label
      : 'Availability';

  const { startDate, startTime, endDate, endTime, appointmentType } = formState;
  const eventTypeCode = get(selectedEvent, 'eventTypeCode', scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode);
  const isAvailabilityEvent = eventTypeCode === scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode;
  const eventName = get(selectedEvent, 'eventName', DEFAULT_EVENT_NAME);
  const allDayEventFlg = get(selectedEvent, 'allDayEventFlg', false) ? 'Y' : 'N';
  const availabilityOverrideFlg = get(selectedEvent, 'availabilityOverrideFlg', false) ? 'Y' : 'N';

  return validateSchema({ validateProps, validatePropsMessages, isAvailabilityEvent })
    .validate({
      startDate,
      endDate,
      startTime,
      endTime
    })
    .then(valid => {
      if (valid) {
        const input = {
          eventName,
          eventTypeCode,
          appointmentTypeCode: appointmentType || null,
          startDateTime:       moment(startDate)
            .set({ hour: moment(startTime).hour(), minute: moment(startTime).minute() })
            .format(),
          endDateTime: moment(endDate)
            .set({ hour: moment(endTime).hour(), minute: moment(endTime).minute() })
            .format(),
          timezoneCode:     moment.tz.guess(),
          eventDescription: get(selectedEvent, 'eventDescription', eventName),
          allDayEventFlg,
          availabilityOverrideFlg
        };

        const mutationInput = isProviderCalendarV2 ? 'provider_schedule_input_params' : 'input';
        const providerScheduleId = isProviderCalendarV2 ? 'provider_schedule_id' : 'providerScheduleId';
        if (has(selectedEvent, 'providerScheduleId')) {
          callUpdateShift({
            variables: { [providerScheduleId]: selectedEvent.providerScheduleId, [mutationInput]: input }
          });
        } else {
          callCreateShift({ variables: { [mutationInput]: input } });
        }
      }
    })
    .catch(err => {
      setInterfaceError(err.message);
      setModalErrorMessage(true);
    });
};

export default saveShiftV2;
