import { ATTENDEE_STATUSES, CONFERENCE_STATUSES, DIALING_STATUSES, VIEW_STATES } from '../constants/call-status';

// Determine the AutoDialer view to show.
// NOTE: these routes are evaluated from top down, so order matters!
const getCallStatusRoute = (conferenceStatus, memberStatus, currentAttendeeStatus) => {
  if (conferenceStatus === CONFERENCE_STATUSES.READY_TO_START) {
    return VIEW_STATES.START_CALL;
  }

  if (conferenceStatus === CONFERENCE_STATUSES.AUTH_ERROR || conferenceStatus === CONFERENCE_STATUSES.CALL_FAILURE) {
    return VIEW_STATES.JOIN_VIA_PHONE;
  }

  if (conferenceStatus === CONFERENCE_STATUSES.BAD_REQUEST) {
    if (memberStatus === ATTENDEE_STATUSES.BAD_REQUEST || memberStatus === ATTENDEE_STATUSES.NOT_REACHED) {
      return VIEW_STATES.UNAVAILABLE;
    }

    // Why do we default to call in progress here if conf status is BAD_REQUEST, maybe a hack?
    return VIEW_STATES.CALL_IN_PROGRESS;
  }

  if (conferenceStatus === CONFERENCE_STATUSES.CONFERENCE_COMPLETE) {
    if (memberStatus === ATTENDEE_STATUSES.NOT_REACHED) {
      return VIEW_STATES.UNAVAILABLE;
    }

    return VIEW_STATES.CALL_COMPLETE;
  }

  if (conferenceStatus === CONFERENCE_STATUSES.STARTING) {
    if (DIALING_STATUSES.includes(memberStatus) || memberStatus === ATTENDEE_STATUSES.DISCONNECTED) {
      return VIEW_STATES.DIALING_MEMBER;
    }

    if (DIALING_STATUSES.includes(currentAttendeeStatus) || currentAttendeeStatus === ATTENDEE_STATUSES.DISCONNECTED) {
      return VIEW_STATES.DIALING_PROVIDER;
    }

    if (memberStatus === ATTENDEE_STATUSES.JOINED) {
      return VIEW_STATES.CALL_IN_PROGRESS;
    }

    // While the call is starting we briefly do not have provider or member statuses in which case we should
    // just continue to show the *disabled* "Start Call" button
    if (!memberStatus && !currentAttendeeStatus) {
      return VIEW_STATES.START_CALL;
    }
  }

  if (conferenceStatus === CONFERENCE_STATUSES.IN_PROGRESS) {
    if (DIALING_STATUSES.includes(currentAttendeeStatus)) {
      return VIEW_STATES.DIALING_PROVIDER;
    }

    if (currentAttendeeStatus === ATTENDEE_STATUSES.DISCONNECTED && memberStatus === ATTENDEE_STATUSES.JOINED) {
      return VIEW_STATES.PROVIDER_REJOIN;
    }

    return VIEW_STATES.CALL_IN_PROGRESS;
  }

  return VIEW_STATES.UNKNOWN;
};

export default getCallStatusRoute;
