import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Error } from 'medication_service_ui';
import classNames from 'classnames';
import ClinicalMedicationList from './ClinicalMedicationList';
import ClinicalMedicationForm from './ClinicalMedicationForm';
import PendingMedicationList from './pending-medication-list';
import '../styles.scss';

const isEmrNewMedicationEnabled = window.FEATURE_TOGGLES.emr_new_medication;

class ClinicalMedication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetched:      false,
      clinicalMeds: [],
      errors:       {},
      medication:   this.props.medication || {}
    };
  }

  componentDidMount() {
    const { memberId } = this.props.memberParams;
    this.props.fetchClinicalMedications({ memberId });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.clinicalMeds !== nextProps.clinicalMeds) {
      this.setState({
        clinicalMeds: nextProps.clinicalMeds
      });
    }

    if (this.props.medication !== nextProps.medication) {
      this.setState({
        medication: nextProps.medication
      });
    }
  }

  removeHandler = medication => {
    this.props.removeFromPendingMedicationList(medication);
  };

  editHandler = medication => {
    this.setState({ medication });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { pendingMedications, memberParams, mhdParams } = this.props;

    pendingMedications.forEach(medication => {
      // transforming medication to be "<dosage value> <unit name>"
      // since those values are being stored under different fields
      // as drugQuantity and drugQuantityUnit
      const tranformedMedication = {
        ...medication,
        drugQuantity:     `${medication.drugQuantity} ${medication.drugQuantityUnit}`,
        drugQuantityUnit: undefined
      };

      const formData = { ...tranformedMedication, ...memberParams, ...mhdParams };

      this.props.submitClinicalMedicationForm(formData);
    });

    this.props.clearPendingMedicationList();
  };

  render() {
    const { fetched, clinicalMeds, errorMessage, editable, surescriptsUpdated } = this.props;
    const { success } = this.props.submitResult;

    if (errorMessage) {
      return <Error message={errorMessage} />;
    }

    return (
      <div>
        {(fetched || success) && (
          <ClinicalMedicationList
            surescriptsUpdated={surescriptsUpdated}
            medicationList={clinicalMeds}
            onSubmit={this.handleSubmit}
          />
        )}

        {editable && (
          <ClinicalMedicationForm
            medication={this.state.medication}
            errors={this.state.errors}
            fetchMedicationList={this.props.fetchMedicationList}
            resetMedicationList={this.props.resetMedicationList}
            setSelectedMedication={this.props.setSelectedMedication}
            addToPendingMedicationList={this.props.addToPendingMedicationList}
            removeFromPendingMedicationList={this.props.removeFromPendingMedicationList}
            clearPendingMedicationList={this.props.clearPendingMedicationList}
            suggestions={this.props.suggestions}
            selectedMed={this.props.selectedMed}
            fetched={this.props.fetched}
            isLoading={this.props.isLoading}
            errorMessage={this.props.errorMessage}
            remedyApiStatus={this.props.mhdParams.remedyApiStatus}
            erxPatientId={this.props.memberParams.erxPatientId}
            submitResult={this.props.submitResult}
            validation={this.props.validation}
            pendingMedications={this.props.pendingMedications}
            memberParams={this.props.memberParams}
            fetchClinicalMedications={this.props.fetchClinicalMedications}
          />
        )}

        {this.props.pendingMedications.length !== 0 && (
          <div className="field-group">
            <PendingMedicationList
              pendingMeds={this.props.pendingMedications}
              removeHandler={this.removeHandler}
              editHandler={this.editHandler}
            />

            <div className={classNames("field", { "new-medication-submit": isEmrNewMedicationEnabled })}>
              <input type="submit" value={'Save Medication'} className="btn btn-primary" onClick={this.handleSubmit} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ClinicalMedication.propTypes = {
  fetched:            PropTypes.bool,
  isLoading:          PropTypes.bool,
  editable:           PropTypes.bool.isRequired,
  errorMessage:       PropTypes.string,
  suggestions:        PropTypes.array,
  clinicalMeds:       PropTypes.array,
  pendingMedications: PropTypes.array.isRequired,
  selectedMed:        PropTypes.object,
  medication:         PropTypes.object,
  surescriptsUpdated: PropTypes.bool.isRequired,
  submitResult:       PropTypes.object,
  validation:         PropTypes.object,
  memberParams:       PropTypes.shape({
    memberId:  PropTypes.number.isRequired,
    memberDoB: PropTypes.string.isRequired,

    // TODO: Refactor backend to return uniform response format
    erxPatientId:   PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    consultationId: PropTypes.number
  }),
  mhdParams: PropTypes.shape({
    consultationId:             PropTypes.number,
    remedyApiStatus:            PropTypes.bool.isRequired,
    newMedActiveConsult:        PropTypes.bool.isRequired,
    newMedicationMhdQuestionId: PropTypes.number,
    newMedicationMhdAnswerId:   PropTypes.number
  }),
  providerParams: PropTypes.shape({
    consultationId: PropTypes.number
  }),
  fetchClinicalMedications:        PropTypes.func,
  submitClinicalMedicationForm:    PropTypes.func,
  setSelectedMedication:           PropTypes.func,
  fetchMedicationList:             PropTypes.func,
  resetMedicationList:             PropTypes.func,
  addToPendingMedicationList:      PropTypes.func.isRequired,
  removeFromPendingMedicationList: PropTypes.func.isRequired,
  clearPendingMedicationList:      PropTypes.func.isRequired
};

export default ClinicalMedication;
