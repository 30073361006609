import { I18n } from '@td/shared_utils';
import React, { Fragment, useEffect, useState } from 'react';

export const TRANSLATION_SCOPE = 'followup';

const ConsultFollowupTitle = ({ continueTreatment }) => {
  const [continueTreatmentForMember, setContinueTreatmentForMember] = useState(continueTreatment);

  useEffect(() => {
    const updateContinueTreatment = e => {
      setContinueTreatmentForMember(e.target.value);
    };

    document.querySelectorAll('input[name="interview[continue_treatment]"]').forEach(elem => {
      elem.addEventListener('change', e => {
        updateContinueTreatment(e);
      });
    });
  }, []);

  return (
    <Fragment>
      <h2 className="title_h2_up">
        <I18n
          scope={TRANSLATION_SCOPE}
          text={continueTreatmentForMember !== 'no' ? 'title.continue_treatment' : 'title.terminate_treatment'}
        />
      </h2>

      <p className="terminate-treatment-message">
        <I18n
          scope={TRANSLATION_SCOPE}
          text={continueTreatmentForMember !== 'no' ? 'continue_treatment_message' : 'terminate_treatment_message'}
        />
      </p>
    </Fragment>
  );
};

export default ConsultFollowupTitle;
