import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { I18n } from '@td/shared_utils';
import { Question } from '..';
import { TRANSLATION_SCOPE, fieldNames } from '../../constants';
import styles from './styles.module.scss';

const Section = React.memo(({ sectionCode, questions }) => ((
  <div>
    <input type="hidden" name={fieldNames.SECTION_CODE} value={sectionCode} />

    <h3 data-testid="survey-section-title">
      <I18n scope={`${TRANSLATION_SCOPE}.sections.${sectionCode}`} text="title" />
    </h3>
    <div data-testid="survey-section-legends">
      <p><I18n scope={`${TRANSLATION_SCOPE}.sections.${sectionCode}.legends`} text="no" /></p>
      <p><I18n scope={`${TRANSLATION_SCOPE}.sections.${sectionCode}.legends`} text="yes" /></p>
    </div>

    <div className={styles.surveyQuestionsContainer}>
      {map(questions, (question) => (
        <Question
          key={question.questionMnemonicCd}
          sectionCode={sectionCode}
          {...question}
        />
      ))}
    </div>
  </div>
)));

Section.displayName = 'Section';

Section.propTypes = {
  sectionCode: PropTypes.string.isRequired,
  questions: PropTypes.object.isRequired
};

export default Section;
