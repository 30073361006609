exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".P9LSCkziMn-LmOfguHPnF {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  margin-bottom: 1rem; }\n", ""]);

// exports
exports.locals = {
	"callDetailsWrapper": "P9LSCkziMn-LmOfguHPnF",
	"callDetailsWrapper": "P9LSCkziMn-LmOfguHPnF"
};