import React from 'react';
import { I18n } from '@td/shared_utils';
import { isEmpty } from 'lodash';
import { NON_TELADOC_SERVICES_THAT_SUPPORT_PCP_REFERAL } from '../constants';
import { usePcpSelectionDom } from '../dom-integration';
import { translateServiceFeature } from '../helpers';

const TRANSLATION_SCOPE = 'consult_followup.summary.referrals';

const ReferralsSummary = ({
  teladocServices,
  selectedTeladocServices,
  p2pNote,
  nonTeladocServices,
  selectedNonTeladocService
}) => {
  const getTeladocServices = () =>
    selectedTeladocServices.map(({ serviceCode, featureCode }) =>
      mapTeladocService(teladocServices, serviceCode, featureCode)
    );

  const pcpSelectionDom = usePcpSelectionDom();

  const getSelectedNonTeladocService = () => {
    const service = nonTeladocServices.find(s => s.displayCode === selectedNonTeladocService);
    if (NON_TELADOC_SERVICES_THAT_SUPPORT_PCP_REFERAL.includes(selectedNonTeladocService)) {
      const referal = pcpSelectionDom.getValue();
      return `${service.displayName} - ${referal}`;
    } else return service.displayName;
  };

  return (
    <div className="summary">
      <strong>
        <I18n scope={TRANSLATION_SCOPE} text="heading" />
      </strong>
      <ul>
        <li id="secondary_followup_internal">
          {!isEmpty(selectedTeladocServices) && (
            <div className="my-1">
              <strong style={{ fontSize: 'unset' }}>
                <I18n scope={TRANSLATION_SCOPE} text="recommended_teladoc_services" />:
              </strong>
              <ul className="pl-6" style={{ listStyle: 'disc' }}>
                {getTeladocServices().map((service, index) => (
                  <li className="my-1" key={index}>
                    {service}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
        <li id="secondary_followup_external">
          {!isEmpty(selectedNonTeladocService) && (
            <div className="my-2">
              <strong style={{ fontSize: 'unset' }}>
                <I18n scope={TRANSLATION_SCOPE} text="non_teladoc_service_heading" />:
              </strong>
              <div>{getSelectedNonTeladocService()}</div>
            </div>
          )}
        </li>
        <li id="secondary_provider_note">
          {!isEmpty(p2pNote) && (
            <div className="my-2">
              <strong style={{ fontSize: 'unset' }}>
                <I18n scope={TRANSLATION_SCOPE} text="p2pNote_heading" />:
              </strong>
              <div>{p2pNote}</div>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default ReferralsSummary;

const mapTeladocService = (teladocServices, serviceCode, featureCode) => {
  const service = teladocServices.find(s => s.code === serviceCode);
  if (featureCode) {
    const featureTranslated = translateServiceFeature(featureCode);
    return `${service.displayName} - ${featureTranslated}`;
  } else return service.displayName;
};
