import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, get } from 'lodash';
import _ from 'lodash';
import Survey, { surveyInsert } from 'app/surveys';
import { surveyStyles, validationLabelStyle } from './styles';
import { translate } from '@td/shared_utils';

import { addTextField, addTextFieldForRadio, expandSections, section, disableCheckboxes } from './utils';
import SafetyPlan from './containers/safety-plan-container';
import { SAFETY_PLAN_QUESTION_CD, SAFETY_PLAN_SECTION_CD } from './constants';
import { UPDATE_MENTAL_STATUS_EXAM_INPUT } from '../../../components/objective-tab/mental-status-exam/redux/action-types';

const SURVEY_NAMING_KEY = 'interview[mental_status_exam][]';
const CUSTOM_ANSWER_CD = 'TEXT';
const SURVEY_SAFETY_PLAN = 'safety_plan';

const MentalStatusExam = ({ mentalStatusExamSurvey, cachedAnswers }) => {
  const surveyCachedData = [
    { surveys: [get(cachedAnswers, 'table.surveys[0]')] },
    { surveys: [get(cachedAnswers, 'table.surveys[1]')] },
    { surveys: [get(cachedAnswers, 'table.surveys[2]')] }
  ];

  const cachedData = [{ table: surveyCachedData[0] }, { table: surveyCachedData[1] }, { table: surveyCachedData[2] }];

  const sectionData = [
    { id: 'mse-general', name: 'General', data: get(mentalStatusExamSurvey, 'sections[0]'), table: cachedData[0] },
    { id: 'mse-cognition', name: 'Cognition', data: get(mentalStatusExamSurvey, 'sections[1]'), table: cachedData[1] },
    { id: 'mse-thought', name: 'Thought', data: get(mentalStatusExamSurvey, 'sections[2]'), table: cachedData[2] }
  ];

  const addCallbackFor = ['Q_1_2', 'Q_1_4', 'Q_2_1', 'Q_2_2', 'Q_2_3', 'Q_3_1', 'Q_3_2', 'Q_3_4'];
  const skipCustomInputFor = ['Q_2_1', 'Q_3_1', 'Q_3_2', 'Q_3_5', 'Q_3_6'];

  const addCallbacksToSurveyFields = () => {
    mentalStatusExamSurvey.sections.forEach(section => {
      section.questions.forEach(question => {
        if (addCallbackFor.indexOf(question.questionCode) !== -1) {
          // Adds callback that disables the checkboxes based on the answer selection.
          question.choices.forEach(choice => {
            surveyInsert(
              mentalStatusExamSurvey,
              [section.sectionCode, question.questionCode, choice.answerCode],
              'callback',
              disableCheckboxes
            );
          });
        }

        if (skipCustomInputFor.findIndex(skip => skip === question.questionCode) !== -1) {
          return;
        }
        question.choices.forEach(choice => {
          // Add callbacks that trigger textfields to the last checkbox answer of most questions
          if (choice.answerCode.includes(CUSTOM_ANSWER_CD)) {
            surveyInsert(
              mentalStatusExamSurvey,
              [section.sectionCode, question.questionCode, choice.answerCode],
              'callback',
              addTextField
            );
          }
        });
      });
    });
  };

  addCallbacksToSurveyFields();

  const setQuestionRequiredLabels = () => {
    mentalStatusExamSurvey.sections
      .filter(s => s.required)
      .forEach(s => {
        s.questions.forEach(question => {
          question.question += ` ${translate(
            null,
            'ehr.mh.objective_tab.mental_status_exam_survey',
            'required_label'
          )}`;
        });
      });
  };

  const cacheSurveyTextFields = () => {
    const surveyQuestions = _.chain(cachedData)
      .flatMap(surveys => get(surveys, 'table.surveys', []))
      .flatMap(sections => get(sections, 'table.sections', []))
      .flatMap(questions => get(questions, 'table.questions', []))
      .value();

    surveyQuestions.forEach(question => {
      const answers = get(question, 'table.answers', []);
      const textInputAnswers = _.filter(
        answers,
        a => a.table.answer_code === CUSTOM_ANSWER_CD || a.table.answer_code === SURVEY_SAFETY_PLAN
      );

      textInputAnswers.forEach(answer => {
        window.td.store.dispatch({
          type:    UPDATE_MENTAL_STATUS_EXAM_INPUT,
          payload: { questionCode: question.table.question_code, inputValue: answer.table.text }
        });
      });
    });
  };

  // Load the comment sections for the radio button questions in the end
  useEffect(addTextFieldForRadio, []);

  // Expand all 3 sections on component first render
  useEffect(expandSections, []);

  useEffect(cacheSurveyTextFields, []);

  useEffect(setQuestionRequiredLabels, []);

  return (
    <div className="mental-status-exam">
      <h4>
        <label htmlFor="interview[objective_mental_status_exam]" className="label">
          {get(mentalStatusExamSurvey, 'title', null)}
        </label>
      </h4>
      <p>{translate(null, 'ehr.mh.objective_tab.mental_status_exam_survey', 'description')}</p>
      {sectionData.map(s => {
        const surveyData = cloneDeep(mentalStatusExamSurvey);
        surveyData.sections = [s.data];
        let content = (
          <Survey
            surveyObject={surveyData}
            surveyNamingKey={SURVEY_NAMING_KEY}
            cachedAnswers={s.table}
            validationErrors={{}}
            styles={surveyStyles}
          />
        );
        if (s.data.sectionCode === SAFETY_PLAN_SECTION_CD) {
          content = (
            <React.Fragment>
              {content}
              <SafetyPlan styles={{ validationLabelStyle }} minChars={5} />
            </React.Fragment>
          );
        }
        return section(s, content);
      })}
    </div>
  );
};

MentalStatusExam.propTypes = {
  mentalStatusExamSurvey: PropTypes.object,
  cachedAnswers:          PropTypes.object
};

export default MentalStatusExam;
