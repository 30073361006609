import { ATTENDEE_STATUSES, CONFERENCE_STATUSES, VIEW_STATES } from '../constants/call-status';

// Determine the AutoDialer view to show.
// NOTE: these routes are evaluated from top down, so order matters!
const getCallStatusRoute = (conferenceStatus, memberStatus, dialCount, currentAttendeeStatus, interpreterStatus) => {
  switch (conferenceStatus) {
    case CONFERENCE_STATUSES.READY_TO_START:
      return VIEW_STATES.START_CALL;

    case CONFERENCE_STATUSES.AUTH_ERROR:
    case CONFERENCE_STATUSES.CALL_FAILURE:
      return VIEW_STATES.JOIN_VIA_PHONE;

    case CONFERENCE_STATUSES.BAD_REQUEST:
      if (memberStatus === ATTENDEE_STATUSES.BAD_REQUEST || (memberStatus === ATTENDEE_STATUSES.NOT_REACHED && dialCount === 2)) {
        return VIEW_STATES.UNAVAILABLE;
      }
      return VIEW_STATES.CALL_IN_PROGRESS;

    case CONFERENCE_STATUSES.CONFERENCE_COMPLETE:
      if (memberStatus === ATTENDEE_STATUSES.NOT_REACHED && dialCount === 2) {
        return VIEW_STATES.UNAVAILABLE;
      }
      if (memberStatus === ATTENDEE_STATUSES.DISCONNECTED) {
        if (currentAttendeeStatus === ATTENDEE_STATUSES.DISCONNECTED && !interpreterStatus) {
          return VIEW_STATES.START_CALL;
        }
        return VIEW_STATES.CALL_IN_PROGRESS;
      }
      return VIEW_STATES.CALL_IN_PROGRESS;

    case CONFERENCE_STATUSES.IN_PROGRESS:
      return VIEW_STATES.CALL_IN_PROGRESS;

    case CONFERENCE_STATUSES.STARTING:
      return VIEW_STATES.DIALING_MEMBER;

    default:
      return VIEW_STATES.UNKNOWN;
  }
};

export default getCallStatusRoute;
