import React from 'react';
import { labReviewRequired, labIconClassName } from '../utils';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import TdWindow from '../../lib/tdWindow';
import styles from '../past-consult.css';
import AddAddendumModal from './add-addendum-modal';
import { ApolloProvider as ApolloProviderNew } from '@apollo/client';
import getClientInstance from 'app/services/apollo-client';
import { FaEllipsisV } from 'react-icons/fa';

const TERMINATED = 'CARETEAMRLTNSTATUS_TERMINATED';

const PastConsult = ({ consultation, updateCallBack, setSpinner, lastConsultsByMember, status }) => {
  const addendumToggle = Boolean(window.FEATURE_TOGGLES.post_consult_addendum);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
  const ref = React.useRef(null);

  const handleClick = event => {
    const reason = window.prompt('Please enter a reason:');
    if (reason) {
      TdWindow.redirect(
        `${`/consultations/${consultation.consultationId}/mark_as_problem_patient?` + 'reason='}${escape(reason)}`
      );
      event && event.preventDefault();
    }
  };

  const kebabClicked = () => {
    const kebabMenu = ref.current;
    kebabMenu.classList.add('active');
  };

  const closeKebab = () => {
    const kebabMenu = ref.current;
    if (kebabMenu.classList.contains('active')) {
      kebabMenu.classList.remove('active');
    }
  };

  const handleModalCancel = () => {
    setShowConfirmationDialog(false);
  };

  const handleAddendumModal = e => {
    e.preventDefault();
    setShowConfirmationDialog(true);
    closeKebab();
  };

  const handleFollowUp = event => {
    event && event.preventDefault();
    setSpinner(true);
    $.get(`/history/consultation/${consultation.consultationId}/schedule_followup_modal`, () => {
      setSpinner(false);
    });
  };

  const scheduleButton = () => {
    // From all the consultations that belongs to a member, check if this one is the last and if it allows followups.
    // If it's either not the last or it doesn't allow followups, don't display anything.
    // If it's the last and it doesn't have a follow up, allow to schedule.
    const isTerminated = status === TERMINATED;
    const lastConsultationId = lastConsultsByMember[consultation.memberId].consultationId;
    const followupAlreadySelected = consultation.followupConsultations
      ? consultation.followupConsultations.some(consult => consult.instructionCd === 'INSTRUCTION_SCHEDULEDSAME')
      : false;
    if (
      consultation.consultationId !== lastConsultationId ||
      isTerminated ||
      !consultation.followupEnabled ||
      followupAlreadySelected ||
      !(consultation.followupConsultations instanceof Array)
    ) {
      return null;
    }
    return (
      <li>
        <a tabIndex={0} onClick={handleFollowUp} className="schedule_followup" id="schedule-follow-up">
          Schedule follow-up
        </a>
      </li>
    );
  };

  React.useEffect(() => {
    document.addEventListener('click', closeKebab, true);
    return () => {
      document.removeEventListener('click', closeKebab, true);
    };
  }, []);

  const renderCommunicationMethod = () => {
    const consultationType = consultation.isFollowup ? 'Follow-up' : 'Initial';

    return consultation.specialtyCode === 'BEHAVHEALTH'
      ? `${consultationType} ${consultation.communicationMethod}`
      : consultation.communicationMethod;
  };

  const handleKey = e => {
    if (e.charCode === 13) {
      const menuList = document.getElementsByClassName('kebabMenu');
      Array.from(menuList).forEach(element => {
        if (element.classList.contains('active')) {
          element.classList.remove('active');
          return;
        }
      });
      kebabClicked();
    }
  };

  const sendMessage = () => {
    const { supportNewMessaging, providerInitiatesMessaging, messageRoomId, consultationId } = consultation;
    if (!supportNewMessaging && !providerInitiatesMessaging) return null;

    const url = supportNewMessaging
      ? `/care_team_messages/${messageRoomId}?consultation_id=${consultationId}`
      : `/history/consultation/${consultationId}/follow_up_modal`;

    return (
      <a
        title="New Message to Member"
        data-related-message-id="null"
        className={addendumToggle ? 'action_item_new' : 'action_item'}
        href={url}
        data-remote={!supportNewMessaging ? true : null}
        tabIndex={0}
      >
        {addendumToggle ? 'Message patient' : 'Send message'}
      </a>
    );
  };

  const renderAction = () => {
    if (isEmpty(consultation.fullMemberName)) {
      const message = `Please Contact Orange Team for Incorrect Data on Consultation #${consultation.id}`;
      return (
        <a className="edit" href={`javascript:alert("${message}")`}>
          Edit
        </a>
      );
    } else if (isEmpty(consultation.memberAddress) && isEmpty(consultation.pharmacyAddress)) {
      return (
        <a className="external_consult" href={`/external_consults/${consultation.consultationId}/route`}>
          External Edit
        </a>
      );
    } else {
      return (
        <React.Fragment>
          <a className="edit" href={`/history/consultation/${consultation.consultationId}`}>
            Edit
          </a>
          {consultation.problemPatientQa ? null : (
            <React.Fragment>
              &nbsp;
              <a onClick={handleClick} className="problem" href="#">
                Mark as Problem Patient
              </a>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
  };

  const renderNewAction = () => {
    if (isEmpty(consultation.fullMemberName)) {
      const message = `Please Contact Orange Team for Incorrect Data on Consultation #${consultation.id}`;
      return (
        <a className="edit" href={`javascript:alert("${message}")`}>
          Edit
        </a>
      );
    } else if (isEmpty(consultation.memberAddress) && isEmpty(consultation.pharmacyAddress)) {
      return (
        <a className="external_consult" href={`/external_consults/${consultation.consultationId}/route`}>
          External Edit
        </a>
      );
    } else {
      return (
        <React.Fragment>
          <li>
            <a tabIndex={0} href={`/history/consultation/${consultation.consultationId}`}>
              Edit
            </a>
          </li>
          <li>{sendMessage()}</li>
          <li>
            <a tabIndex={0} id="add-addendum" onClick={handleAddendumModal}>
              Add addendum
            </a>
          </li>
          {scheduleButton()}
          {consultation.problemPatientQa ? null : (
            <li>
              <a tabIndex={0} onClick={handleClick} className="problem" href="#">
                Mark as problem patient
              </a>
            </li>
          )}
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <tr
        className={`past_consultation ${labReviewRequired(consultation) ? 'red' : ''}`}
        id={`consultation_${consultation.consultationId}`}
        key={consultation.consultationId}
        styles={styles}
      >
        <td>
          <div className="col">
            <p>
              {consultation.consultationId}
              {!consultation.isSelectConsult ? null : (
                <p className="text-center teladoc_select_label">TELADOC SELECT</p>
              )}
            </p>
          </div>
        </td>
        <td>{renderCommunicationMethod()}</td>
        <td>
          <span className="browser_timestamp">{consultation.actualStartDate}</span>
        </td>
        <td>
          <span className="browser_timestamp">{consultation.actualEndDate}</span>
        </td>
        <td>{isEmpty(consultation.fullMemberName) ? 'NA' : consultation.fullMemberName}</td>
        <td>{consultation.memberId}</td>
        <td>{consultation.memberAddress}</td>
        <td>{consultation.pharmacyAddress}</td>
        <td className="actions">
          {addendumToggle ? (
            <div tabIndex={0} className="kebabWrapper" onKeyPress={handleKey}>
              <div className="kebab" onClick={kebabClicked}>
                <FaEllipsisV size={16} color="#42a6cc" />
                <ul ref={ref} className="kebabMenu">
                  {renderNewAction()}
                </ul>
              </div>
            </div>
          ) : (
            <React.Fragment>
              {sendMessage()}
              {renderAction()}
            </React.Fragment>
          )}
        </td>
        <td className={labIconClassName(consultation)} />
      </tr>
      <div id="add_follow_up_reply" />
      {showConfirmationDialog && (
        <ApolloProviderNew client={getClientInstance()}>
          <AddAddendumModal
            isModalOpen={showConfirmationDialog}
            cancelCallback={handleModalCancel}
            consultation={consultation}
            updateCallBack={updateCallBack}
            shouldAppendNotes={false}
          />
        </ApolloProviderNew>
      )}
    </React.Fragment>
  );
};

export default PastConsult;

PastConsult.propTypes = {
  consultation:      PropTypes.object,
  updateCallBack:    PropTypes.func,
  shouldAppendNotes: PropTypes.bool
};
