import React from 'react';
import PropTypes from 'prop-types';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import Loader from 'app/components/Loader';

const Accordion = ({ title, subtitle, isOpen, onToggle, loading, children }) => {
  return (
    <div className="accordion">
      <div className="accordion-title" onClick={onToggle}>
        {isOpen ? <FaMinusCircle /> : <FaPlusCircle />}
        <b>{title}</b>
        {!loading && subtitle && <p className="accordion-subtitle">{subtitle}</p>}
      </div>
      {isOpen && (
        <div className="accordion-content">
          {loading ? (
            <div className="loader-container">
              <Loader />
            </div>
          ) : (
            children
          )}
        </div>
      )}
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node
};

export default Accordion;
