import { createRequestActions } from '@td/utils';

import {
  CLOCK_IN_PROVIDER,
  CLOCK_OUT_PROVIDER,
  SET_CLOCK_IN_OUT_SETTINGS,
  CLOCK_IN_PROVIDER_INIT,
  SET_CLOCK_IN_OUT_LOCATION_SETTINGS,
  INIT_CLOCK_IN_LOCATION,
  ENABLE_REQUEST_MODE,
  DISABLE_REQUEST_MODE
} from '../action-types';

export const clockInProviderInit = (clockInType, locationType, locationText) => ({
  type:    CLOCK_IN_PROVIDER_INIT,
  payload: {
    clockInType,
    locationType,
    locationText
  }
});
export const clockInProvider = createRequestActions(CLOCK_IN_PROVIDER);
export const clockOutProvider = createRequestActions(CLOCK_OUT_PROVIDER);
export const setClockInOutSettings = settings => ({
  type:    SET_CLOCK_IN_OUT_SETTINGS,
  payload: settings
});
export const setClockInOutLocationSettings = settings => ({
  type:    SET_CLOCK_IN_OUT_LOCATION_SETTINGS,
  payload: settings
});
export const clockInLocationInit = () => ({
  type: INIT_CLOCK_IN_LOCATION
});
export const enableRequestMode = clockInType => ({
  type:    ENABLE_REQUEST_MODE,
  payload: { clockInType }
});
export const disableRequestMode = () => ({
  type: DISABLE_REQUEST_MODE
});
