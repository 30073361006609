import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { authToken } from '@td/api';
import { FileUploads, FileUploadsNetworkErrorModal, FileUploadsTmpPreviewTable } from '@td/file_uploads';
import { useUpdater, useFetcher, translate } from '@td/shared_utils';
import { last } from 'lodash';

export const PrxFileUploadsContainer = props => {
  const fileUploadRequestState = useUpdater('post', {
    url:     '/temporary_attachments/',
    method:  'post',
    baseURL: window.location.origin,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `${authToken.get()}` },
    timeout: 5 * 60 * 1000 // timeout to abort connection (in ms, eg 5 minutes)
  });
  const fileTypeOptionsRequestState = useFetcher('/member_attachments/new');
  const providerId = document.body.dataset.providerId;
  const [tmpFileUploads, setTmpFileUploads] = useState({});
  const [modalIsClosed, setClosed] = useState(true);
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    const uploadResponse = fileUploadRequestState.data;
    if (fileUploadRequestState.finished && uploadResponse && uploadResponse.success) {
      setTmpFileUploads({ ...tmpFileUploads, ...uploadResponse.success });
      setNetworkError(false);
    }
    if (fileUploadRequestState.finished && ((uploadResponse && uploadResponse.error) || fileUploadRequestState.error)) {
      setClosed(true);
      setNetworkError(true);
      fileUploadRequestState.reset();
    }
  }, [fileUploadRequestState.finished]);

  return (
    <div className="file-uploads">
      <FileUploadsNetworkErrorModal isNetworkError={networkError} setNetworkError={setNetworkError} />
      <fieldset id="file_upload">
        <FileUploads
          fileUploadRequestState={fileUploadRequestState}
          fileTypeOptionsRequestState={fileTypeOptionsRequestState}
          uploadsFormData={{
            attach_to:     'Consultation',
            attacher_id:   providerId,
            related_type:  'Consultation',
            related_id:    props.consultationId,
            attacher_type: 'Provider'
          }}
          cssModalKey="provider"
          modalIsClosed={modalIsClosed}
          setClosed={setClosed}
          customFields={[
            {
              type:       'prompt',
              identifier: 'person-name-prompt',
              title:      translate(null, 'uploads.general.modal.member_name_prompt', 'title', {
                name: `<i>${props.memberName}</i>`
              }),
              missing:     translate(null, 'uploads.general.modal.member_name_prompt', 'warning'),
              choices:     [{ label: null, val: props.memberName }],
              matchValues: [props.memberName, last(props.memberName.split(' '))]
            }
          ]}
        />
      </fieldset>
      <FileUploadsTmpPreviewTable
        tmpFileUploads={tmpFileUploads}
        setTmpFileUploads={setTmpFileUploads}
        attachFileField="interview[attached_images][][uuid]"
      />
    </div>
  );
};
FileUploads.propTypes = {
  memberId:       PropTypes.string,
  memberName:     PropTypes.string,
  consultationId: PropTypes.string
};
