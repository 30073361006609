import React, { useCallback } from 'react';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { translate } from '@td/shared_utils';
import classNames from 'classnames';
import useNurseNotification from '../../hooks/use-nurse-notification';
import { success } from '../../../notifications/actions';
import { attendeeTypes } from '../../../constants';

const resolveButtonLabel = roleCd =>
  ({
    [attendeeTypes.RN]: translate(null, 'consultation_attendees.rn.notification', 'button')
  }[roleCd]);

const resolveNotificationMessage = roleCd =>
  ({
    [attendeeTypes.RN]: translate(null, 'consultation_attendees.rn.sender_notification', 'title')
  }[roleCd]);

const NotifyNurseButton = React.memo(({ consultationId, roleCd, className, dispatch }) => {
  const handleSuccess = useCallback(() => {
    dispatch(success(resolveNotificationMessage(roleCd), { timeOut: 4000 }));
  }, []);

  const { notify, inProgress } = useNurseNotification({
    consultationId,
    roleCd,
    onSuccess: handleSuccess
  });

  return (
    <button
      disabled={inProgress}
      onClick={notify}
      className={classNames('button', styles.notifyNurseButton, className)}
    >
      {resolveButtonLabel(roleCd)}
    </button>
  );
});

export default connect()(NotifyNurseButton);
