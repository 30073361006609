import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  map,
  without,
  concat,
  reject,
  keys,
  flatMap,
  zipObject,
  pickBy,
  get,
  flattenDeep,
  mapValues,
  uniqueId,
  differenceBy
} from 'lodash';
import { translate } from '@td/shared_utils';
import { useMutation } from '@apollo/client';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/regular-working-hours-modal.scss';

// Components, constants and helpers
import { DAY_NAMES, scheduleTypes } from '../constants';
import TeladocModal from '../../TeladocModal';
import { checkEventsOverlap, dayNameToIndex, getNextDay, separateDatesAndTimes } from '../helpers';
import RegularWorkingHoursDay from './regular-working-hours-day';

// Icons
const resetAllIcon = require('../../assets/images/reset_all_icon.svg');

// I18n
const TRANSLATION_SCOPE = 'my_schedule.modals.regular_working_hours';

const RegularWorkingHoursModal = ({
  regularWorkingHours,
  onModalClose,
  onSubmit,
  validateProps,
  validatePropsMessages,
  saveShift,
  CREATE_SHIFT_EVENT,
  UPDATE_SHIFT_EVENT,
  DELETE_SHIFT_EVENT
}) => {
  /*
   * States
   */
  const [tempRegularWorkingHours, setTempRegularWorkingHours] = useState(
    mapValues(regularWorkingHours, dayOfWeek =>
      dayOfWeek.map(timeBlock => ({ ...timeBlock, isValid: true, isNonOverlapping: true, isEdited: false }))
    )
  );
  const [enabledRegularWorkingHoursGroups, enableRegularWorkingHoursGroups] = useState(
    keys(pickBy(regularWorkingHours, rwhGroup => rwhGroup.length > 0))
  );
  const [isSubmitting, setSubmitting] = useState(false);
  const [mutationCount, setMutationCount] = useState(0);
  const [mutationSuccess, setMutationSuccess] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState(false);
  const [mutationErrorList, setMutationErrorList] = useState([]);

  /*
   * Mutation callbacks
   */
  const handleMutationError = mutationError => {
    const errorMsg = get(flattenDeep([mutationError]), 0, translate(null, 'provider_calendar', 'mutation.error'));

    setModalErrorMessage(true);
    setMutationErrorList(prevMutationErrorList => concat(prevMutationErrorList, errorMsg));
    setMutationCount(prevMutationCount => prevMutationCount - 1);
  };

  const handleMutationSuccess = () => {
    setMutationSuccess(true);
    setMutationCount(prevMutationCount => prevMutationCount - 1);
  };

  /*
   * Mutations
   */
  const [callCreateShift, { loading: createShiftLoading }] = useMutation(CREATE_SHIFT_EVENT, {
    onCompleted: ({ tasCreateProviderScheduleEvent: { success, errors } }) => {
      if (success) {
        handleMutationSuccess();
      } else {
        handleMutationError(errors);
      }
    },
    onError: error => handleMutationError(error)
  });

  const [callUpdateShift, { loading: updateShiftLoading }] = useMutation(UPDATE_SHIFT_EVENT, {
    onCompleted: ({ tasUpdateProviderScheduleEvent: { success, errors } }) => {
      if (success) {
        handleMutationSuccess();
      } else {
        handleMutationError(errors);
      }
    },
    onError: error => handleMutationError(error)
  });

  const [callDeleteShift, { loading: deleteShiftLoading }] = useMutation(DELETE_SHIFT_EVENT, {
    onCompleted: ({ tasDeleteProviderScheduleEvent: { success, errors } }) => {
      if (success) {
        handleMutationSuccess();
      } else {
        handleMutationError(errors);
      }
    },
    onError: error => handleMutationError(error)
  });

  /*
   * Local helper functions
   */
  const countEvents = workingHours => flatMap(workingHours).length;

  const localSaveShift = (newEvent, selectedEvent) => {
    setMutationCount(prevMutationCount => prevMutationCount + 1);

    saveShift({
      formState:            separateDatesAndTimes(newEvent),
      setInterfaceError:    handleMutationError,
      isProviderCalendarV2: true,
      selectedEvent,
      validateProps,
      validatePropsMessages,
      setModalErrorMessage,
      callCreateShift,
      callUpdateShift
    });
  };

  const deleteEvent = ({ providerScheduleId }) => {
    setMutationCount(prevMutationCount => prevMutationCount + 1);

    callDeleteShift({ variables: { provider_schedule_id: providerScheduleId } });
  };

  const validateAndSplitEvent = newEvent => {
    const { startDateTime, endDateTime } = newEvent;
    const now = moment();

    if (now.isBefore(startDateTime) && now.isBefore(endDateTime)) return newEvent;

    const newStartDateTime = getNextDay(startDateTime, DAY_NAMES[moment(startDateTime).day()]);

    if (now.isAfter(startDateTime) && now.isAfter(endDateTime)) {
      return {
        ...newEvent,
        startDateTime: newStartDateTime,
        endDateTime:   moment(newStartDateTime).set({ hours: endDateTime.hours(), minutes: endDateTime.minutes() })
      };
    }

    /**
     * When a user wants to set a block in the same day as today
     * with a start time earlier and an end time later than the current time.
     * startDateTime will be in the past, raising BE error.
     * According to product:
     * - an ATB appointment block is for the remaining time of the current day.
     * - a full RH block is created starting next week.
     */
    const minutesToAdd = 15 - (now.minute() % 15);

    const atbBlock = {
      eventName:     scheduleTypes.PROVSCHEDEVENT_ATB.label || 'Special working hours',
      eventTypeCode: scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode,
      startDateTime: moment().add(minutesToAdd, 'minute').startOf('minute'),
      endDateTime:   now.startOf('day').set({ hours: moment(endDateTime).hours(), minutes: moment(endDateTime).minutes() })
    };

    if (atbBlock.endDateTime.diff(atbBlock.startDateTime, 'minutes') >= 30) {
      localSaveShift(atbBlock, atbBlock);
    }

    const rhBlock = {
      ...newEvent,
      startDateTime: newStartDateTime,
      endDateTime:   moment(newStartDateTime).set({ hours: endDateTime.hours(), minutes: endDateTime.minutes() })
    };

    return rhBlock;
  };

  const updateEvent = selectedEvent => {
    const eventToSave = validateAndSplitEvent(selectedEvent);

    localSaveShift(eventToSave, selectedEvent);
  };

  const addEvent = newEvent => {
    const eventToSave = validateAndSplitEvent(newEvent);

    const selectedEvent = {
      eventName:     scheduleTypes.PROVSCHEDEVENT_RH.label,
      eventTypeCode: scheduleTypes.PROVSCHEDEVENT_RH.eventTypeCode
    };

    localSaveShift(eventToSave, selectedEvent);
  };

  const emptyDays = () =>
    zipObject(
      DAY_NAMES,
      Array.from(Array(DAY_NAMES.length), () => [])
    );

  const removeTimeBlock = (dayTimeBlocks, { providerScheduleId }) => reject(dayTimeBlocks, { providerScheduleId });

  const getNewTimeKey = () => uniqueId('newTime-');

  const validateRegularHours = dayOfWeek => {
    const workingHoursToValidate = dayOfWeek ? tempRegularWorkingHours[dayOfWeek] : flatMap(tempRegularWorkingHours);

    return workingHoursToValidate.every(({ isValid, isNonOverlapping }) => isValid && isNonOverlapping);
  };

  /*
   * Constants
   */
  const tempRegularWorkingHoursCount = countEvents(tempRegularWorkingHours);
  const hasChanged =
    flatMap(tempRegularWorkingHours).some(({ isEdited }) => isEdited) ||
    tempRegularWorkingHoursCount !== countEvents(regularWorkingHours);
  const isLoading = createShiftLoading || updateShiftLoading || deleteShiftLoading;
  const disabledSubmit = isSubmitting || tempRegularWorkingHoursCount === 0 || !hasChanged || isLoading;

  /*
   * Effects
   */
  useEffect(() => {
    if (isSubmitting && mutationCount === 0) {
      setSubmitting(false);
      onSubmit({ success: mutationSuccess, error: modalErrorMessage, errorList: mutationErrorList });
    }
  }, [isSubmitting, mutationCount, modalErrorMessage, mutationErrorList, mutationSuccess]);

  /*
   * Handlers
   */
  const handleToggleCheckboxChange = dayName =>
    enableRegularWorkingHoursGroups(prevEnabledRegularWorkingHoursGroups => {
      if (prevEnabledRegularWorkingHoursGroups.includes(dayName)) {
        setTempRegularWorkingHours(prevTempRegularWorkingHours => ({ ...prevTempRegularWorkingHours, [dayName]: [] }));
        return without(prevEnabledRegularWorkingHoursGroups, dayName);
      } else {
        return concat(prevEnabledRegularWorkingHoursGroups, dayName);
      }
    });

  const handleResetAll = () => {
    setTempRegularWorkingHours(emptyDays());
    enableRegularWorkingHoursGroups([]);
  };

  const handleSubmit = () => {
    const areAllValidHours = validateRegularHours();

    if (!disabledSubmit && areAllValidHours) {
      setMutationCount(0);
      setMutationSuccess(false);
      setModalErrorMessage(false);
      setMutationErrorList([]);
      setSubmitting(true);

      const isNewId = id => String(id).substring(0, 8) === 'newTime-';
      const oldEditedEvents = ({ providerScheduleId, isEdited }) => !isNewId(providerScheduleId) && isEdited;
      const newEvents = ({ providerScheduleId }) => isNewId(providerScheduleId);

      const allEvents = flatMap(tempRegularWorkingHours);
      const eventsToDelete = differenceBy(flatMap(regularWorkingHours), allEvents, 'providerScheduleId');
      const eventsToUpdate = allEvents.filter(oldEditedEvents);
      const eventsToAdd = allEvents.filter(newEvents);

      eventsToDelete.forEach(eventToDelete => deleteEvent(eventToDelete));
      eventsToUpdate.forEach(eventToUpdate => updateEvent(eventToUpdate));
      eventsToAdd.forEach(eventToAdd => addEvent(eventToAdd));
    }
  };

  const handleTimeChange = (dayOfWeek, timeBlock) => {
    const { startDateTime: blockStart, endDateTime: blockEnd } = timeBlock;
    const startDateTime = moment(blockStart)
      .startOf('week')
      .set({
        day:     dayNameToIndex(dayOfWeek),
        hours:   moment(blockStart).hours(),
        minutes: moment(blockStart).minutes()
      });
    const endDateTime = moment(blockEnd)
      .startOf('week')
      .set({
        day:     dayNameToIndex(dayOfWeek),
        hours:   moment(blockEnd).hours(),
        minutes: moment(blockEnd).minutes()
      });

    const changedTimeBlock = { ...timeBlock, startDateTime, endDateTime };

    setTempRegularWorkingHours(prevTempRegularWorkingHours => {
      const prevTimeBlock = prevTempRegularWorkingHours[dayOfWeek].find(
        ({ providerScheduleId }) => providerScheduleId === timeBlock.providerScheduleId
      );
      const dayTimeBlocks = removeTimeBlock(prevTempRegularWorkingHours[dayOfWeek], timeBlock);

      changedTimeBlock.isEdited =
        !startDateTime.isSame(prevTimeBlock.startDateTime) || !endDateTime.isSame(prevTimeBlock.endDateTime);
      changedTimeBlock.isNonOverlapping = !checkEventsOverlap(dayTimeBlocks, separateDatesAndTimes(changedTimeBlock));
      dayTimeBlocks.push(changedTimeBlock);

      return { ...prevTempRegularWorkingHours, [dayOfWeek]: dayTimeBlocks };
    });

    return changedTimeBlock;
  };

  const handleDeleteTime = (dayOfWeek, timeBlock) => {
    setTempRegularWorkingHours(prevTempRegularWorkingHours => {
      const dayTimeBlocks = removeTimeBlock(prevTempRegularWorkingHours[dayOfWeek], timeBlock);

      return { ...prevTempRegularWorkingHours, [dayOfWeek]: dayTimeBlocks };
    });
  };

  const handleAddTime = dayOfWeek => {
    const isValid = validateRegularHours(dayOfWeek);
    if (!isValid) return;

    setTempRegularWorkingHours(prevTempRegularWorkingHours => {
      const dayTimeBlocks = prevTempRegularWorkingHours[dayOfWeek];
      dayTimeBlocks.push({ providerScheduleId: getNewTimeKey(), startDateTime: null, endDateTime: null });

      return { ...prevTempRegularWorkingHours, [dayOfWeek]: dayTimeBlocks };
    });
  };

  const handleApplyAll = dayOfWeek => {
    const isValid = validateRegularHours(dayOfWeek);
    if (!isValid) return;

    setTempRegularWorkingHours(prevTempRegularWorkingHours => {
      const timeBlocksToCopy = prevTempRegularWorkingHours[dayOfWeek];

      const newRegularWorkingHours = emptyDays();
      newRegularWorkingHours[dayOfWeek] = [...timeBlocksToCopy];

      const daysToApply = without(enabledRegularWorkingHoursGroups, dayOfWeek);
      return daysToApply.reduce((rwh, day) => {
        // eslint-disable-next-line no-param-reassign
        rwh[day] = timeBlocksToCopy.map(({ startDateTime, endDateTime }) => ({
          providerScheduleId: getNewTimeKey(),
          isValid:            true,
          isNonOverlapping:   true,
          startDateTime:      moment().startOf('week').set({
            day:     dayNameToIndex(day),
            hours:   startDateTime.hours(),
            minutes: startDateTime.minutes()
          }),
          endDateTime: moment().startOf('week').set({
            day:     dayNameToIndex(day),
            hours:   endDateTime.hours(),
            minutes: endDateTime.minutes()
          })
        }));

        return rwh;
      }, newRegularWorkingHours);
    });
  };

  const handleCloseModal = () => {
    if (!isSubmitting) {
      onModalClose();
    }
  };

  /*
   * Render
   */
  return (
    <TeladocModal
      className="regularWorkingHoursModalWrapper"
      title={translate(null, TRANSLATION_SCOPE, 'title')}
      isOpen
      onClose={handleCloseModal}
    >
      <div className="contentWrapper">
        <div className="subtitle">
          <p>{translate(null, TRANSLATION_SCOPE, 'subtitle.line1')}</p>
          <p>{translate(null, TRANSLATION_SCOPE, 'subtitle.line2')}</p>
        </div>

        <div className="workingHoursWrapper">
          <div className="secionlabel">
            <p>{translate(null, TRANSLATION_SCOPE, 'days_and_times_section.label')}</p>
            <div className="resetAll" onClick={handleResetAll}>
              <img
                className="resetAllIcon"
                src={resetAllIcon}
                alt={translate(null, TRANSLATION_SCOPE, 'days_and_times_section.reset_all')}
              />
              <span className="resetAllLabel">
                {translate(null, TRANSLATION_SCOPE, 'days_and_times_section.reset_all')}
              </span>
            </div>
          </div>
          <div className="timeBlocks">
            {map(tempRegularWorkingHours, (currentDayRegularWorkingHours, dayOfWeek) => (
              <RegularWorkingHoursDay
                key={dayOfWeek}
                day={dayOfWeek}
                hours={currentDayRegularWorkingHours}
                isChecked={enabledRegularWorkingHoursGroups.includes(dayOfWeek)}
                onCheckDay={() => handleToggleCheckboxChange(dayOfWeek)}
                onTimeChange={timeBlock => handleTimeChange(dayOfWeek, timeBlock)}
                onAddTime={() => handleAddTime(dayOfWeek)}
                onDeleteTime={timeBlock => handleDeleteTime(dayOfWeek, timeBlock)}
                onApplyToAll={() => handleApplyAll(dayOfWeek)}
              />
            ))}
          </div>
        </div>
        <div className="actionButtons">
          <button id="cancelButton" className="button" onClick={handleCloseModal} disabled={isSubmitting}>
            {translate(null, TRANSLATION_SCOPE, 'actions.cancel')}
          </button>
          <button id="confirmButton" className="button" onClick={handleSubmit} disabled={disabledSubmit}>
            {isSubmitting
              ? translate(null, TRANSLATION_SCOPE, 'actions.submitting')
              : translate(null, TRANSLATION_SCOPE, 'actions.save')}
          </button>
        </div>
      </div>
    </TeladocModal>
  );
};

RegularWorkingHoursModal.propTypes = {
  regularWorkingHours: PropTypes.object.isRequired,
  onModalClose:        PropTypes.func.isRequired,
  onSubmit:            PropTypes.func.isRequired,
  validateProps:       PropTypes.shape({
    minShiftLengthSize:       PropTypes.number,
    maxShiftLengthSize:       PropTypes.number,
    shiftTimeStep:            PropTypes.number,
    minStartDate:             PropTypes.number,
    shiftPreselectedDuration: PropTypes.number
  }).isRequired,
  validatePropsMessages: PropTypes.shape({
    startDateInPast:        PropTypes.string,
    endDateBeforeStartDate: PropTypes.string,
    endTimeBeforeStartTime: PropTypes.string,
    minShiftLength:         PropTypes.string,
    maxShiftLength:         PropTypes.string,
    overlappingShift:       PropTypes.string
  }).isRequired,
  saveShift:          PropTypes.func.isRequired,
  CREATE_SHIFT_EVENT: PropTypes.any.isRequired,
  UPDATE_SHIFT_EVENT: PropTypes.any.isRequired,
  DELETE_SHIFT_EVENT: PropTypes.any.isRequired
};

export default RegularWorkingHoursModal;
