import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TeladocModal from '../../TeladocModal';

const ConsultationComplete = ({
  dataMessage,
  dataDismissSteps,
  dataShow,
  isModalOpen = false,
  onConfirm,
  onCancel
}) => {
  const [showModal, setShowModal] = useState(isModalOpen);
  const [isConfirming, setConfirming] = useState(false);

  const handleToggleModal = () => {
    if (showModal) {
      setConfirming(false);
    }
    setShowModal(!showModal);
  };

  const handleConfirm = e => {
    e.preventDefault();
    handleToggleModal();
    onConfirm();
  };

  const handleCancel = e => {
    e.preventDefault();
    handleToggleModal();
    onCancel();
  };

  return (
    <div className="consultationCompleteWrapper">
      <input
        type="button"
        className="button openModalAction"
        value="Close visit and electronically sign"
        onClick={handleToggleModal}
        data-show={dataShow}
        data-dismiss-steps={dataDismissSteps}
        data-message={dataMessage}
      />
      <TeladocModal
        className="consultCompleteModal"
        title="Complete consultation"
        isOpen={showModal}
        onClose={handleToggleModal}
        parentClassName="consultationCompleteWrapper"
      >
        <div className="content">
          <p className="explanation">{dataMessage}</p>
          <div className="consultCompleteModalButtons">
            <button
              id="consultCompleteModalDismiss"
              key="cancelButton"
              className="button"
              onClick={handleCancel}
              disabled={false}
            >
              No
            </button>
            <button
              id="consultCompleteModalConfirm"
              key="confirmButton"
              className="button"
              onClick={handleConfirm}
              disabled={isConfirming}
            >
              Yes
            </button>
          </div>
        </div>
      </TeladocModal>
    </div>
  );
};

ConsultationComplete.propTypes = {
  onConfirm:        PropTypes.func.isRequired,
  onCancel:         PropTypes.func.isRequired,
  dataMessage:      PropTypes.string,
  dataDismissSteps: PropTypes.number,
  dataShow:         PropTypes.bool
};

export default ConsultationComplete;
