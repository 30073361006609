import PropTypes from 'prop-types';
import { values } from 'lodash';
import { bannerTypes } from '../constants';

export const BannerPropType = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(values(bannerTypes)).isRequired,
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired
};
