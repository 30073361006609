import React from 'react';
import ParticipantItem from '../participant-item';
import { useCallStatus } from '../../CallStatusProvider';
import { useParticipants } from '../../ParticipantsProvider';
import { translate } from '@td/shared_utils';
import ReactTooltip from 'react-tooltip';
import { attendeeTypes } from '../../../constants';

const participantSelector = roleCd => attendee => attendee.roleCd === roleCd;
const attendeeStatusSelector = (id, roleCd) => attendee =>
  parseInt(attendee.id) === parseInt(id) && attendee.roleCd.replace(/^(tdoc\_)/, '') === roleCd;

const Participants = ({ member, provider, visitType }) => {
  const { attendees } = useCallStatus();
  const participants = useParticipants();

  const attendeeRn = participants.find(participantSelector(attendeeTypes.RN));
  const attendeeMa = participants.find(participantSelector(attendeeTypes.MA));

  return (
    <React.Fragment>
      <ParticipantItem
        label={translate(null, 'call_details.participant_labels', 'member')}
        participant={{
          name: `${member.firstNm} ${member.lastNm}`
        }}
        status={attendees.find(attendeeStatusSelector(member.memberId || member.id, 'member'))}
        visitType={visitType}
      />
      <ParticipantItem
        label={translate(null, 'call_details.participant_labels', 'provider')}
        participant={{
          name: `${provider.firstNm} ${provider.lastNm}`
        }}
        status={attendees.find(attendeeStatusSelector(provider.providerId || provider.id, 'provider'))}
        visitType={visitType}
      />
      {attendeeMa && (
        <ParticipantItem
          label={translate(null, 'call_details.participant_labels', 'pre_consult')}
          participant={{ name: attendeeMa.name }}
          status={attendees.find(attendeeStatusSelector(attendeeMa.id, 'admin_ma'))}
          visitType={visitType}
        />
      )}
      {attendeeRn && (
        <ParticipantItem
          label={translate(null, 'call_details.participant_labels', 'post_consult')}
          participant={{ name: attendeeRn.name }}
          status={attendees.find(attendeeStatusSelector(attendeeRn.id, 'admin_rn'))}
          visitType={visitType}
        />
      )}

      <ReactTooltip />
    </React.Fragment>
  );
};

export default Participants;
