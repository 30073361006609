import axios from 'axios';

const axiosInstance = axios.create();

export default ({ consultationId, onSuccess }) => {
  axiosInstance.get(
    `/consultations/${consultationId}/propose_new_times_modal_react`)
    .then(response => {
      if (typeof onSuccess === 'function') onSuccess(response);
  })
};
