import React from 'react';
import PropTypes from 'prop-types';

const ScheduleAction = ({ openModal }) => {
  return (
    <a
      className="schedule_action"
      title={'schedule'}
      onClick={e => {
        e.preventDefault();
        openModal();
      }}
      href="#"
    >
      Schedule
    </a>
  );
};

ScheduleAction.propTypes = {
  openModal: PropTypes.func.isRequired
};

export default ScheduleAction;
