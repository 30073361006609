import { UPDATE_CLINICAL_ALERTS_ACTIVATED_FLAG } from '../action-types';

const clinicalAlertsActivatedFlagReducer = (state = false, action) => {
  switch(action.type) {
    case UPDATE_CLINICAL_ALERTS_ACTIVATED_FLAG: {
      return action.payload.clinicalAlertsActivatedFlag;
    }
    default: {
      return state;
    }
  }
}

export default clinicalAlertsActivatedFlagReducer;
