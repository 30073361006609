import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isEmpty } from 'lodash';
import { translate } from '@td/shared_utils';
import moment from 'moment-timezone';
import { AlertCircleIcon } from '../../icons';
import styles from './styles.module.scss';

const Availabilities = React.memo(({ title, description, noSlotsMessage, slots, limit = 5, onRowAdd }) => {
  const [showMore, setShowMore] = useState(false);

  const handleRowAdd = useCallback(event => () => onRowAdd(event), [onRowAdd]);

  const renderRow = useCallback(event => {
    const { start, end, reason } = event;

    return (
      <div className={styles.availabilitiesItem} data-testid="availabilities-item">
        {moment(start).isSame(end, 'day') ? (
          <div className={styles.availabilitiesItemTimeRange} data-testid="availabilities-item-time-range">
            <span>{moment(start).format('ddd, MMM D, Y')}</span>
            <div>
              {moment(start)
                .format('h:mm A')
                .replace(':00', '')}
            </div>
            &ndash;
            <div>
              {moment(end)
                .format('h:mm A')
                .replace(':00', '')}
            </div>
          </div>
        ) : (
          <div className={styles.availabilitiesItemDateRange} data-testid="availabilities-item-date-range">
            <div>
              <span>{moment(start).format('ddd, MMM D, Y')}</span>
              {moment(start)
                .format('h:mm A')
                .replace(':00', '')}
            </div>
            &ndash;
            <div>
              <span>{moment(end).format('ddd, MMM D, Y')}</span>
              {moment(end)
                .format('h:mm A')
                .replace(':00', '')}
            </div>
          </div>
        )}
        <div data-testid="availabilities-item-reason">{reason}</div>
        <div className={styles.availabilitiesItemActions} data-testid="availabilities-item-actions">
          {isFunction(onRowAdd) && (
            <button
              onClick={handleRowAdd(event)}
              className="button primary"
              data-testid="availabilities-item-add-button"
            >
              {translate(null, 'my_schedule.availabilities.actions', 'add_row')}
            </button>
          )}
        </div>
      </div>
    );
  });

  return (
    <div className={styles.availabilities} data-testid="availabilities">
      <div className={styles.availabilitiesTitle} data-testid="availabilities-title">
        <AlertCircleIcon width={16} height={16} fill="#D87F00" />
        {title}
      </div>
      <div className={styles.availabilitiesDescription} data-testid="availabilities-description">
        {(isEmpty(slots) && noSlotsMessage) || description}
      </div>
      <div className={styles.availabilitiesList}>
        {slots.slice(0, limit).map(renderRow)}
        {showMore && slots.slice(limit).map(renderRow)}
      </div>
      {slots.length > limit && (
        <a onClick={() => setShowMore(!showMore)} data-testid="availabilities-show-more-button">
          {translate(null, 'my_schedule.availabilities.actions', showMore ? 'show_less' : 'show_more')}
        </a>
      )}
    </div>
  );
});

Availabilities.displayName = 'Availabilities';
Availabilities.propTypes = {
  title:          PropTypes.string.isRequired,
  description:    PropTypes.string.isRequired,
  noSlotsMessage: PropTypes.string,
  slots:          PropTypes.arrayOf(
    PropTypes.shape({
      start:  PropTypes.instanceOf(Date).isRequired,
      end:    PropTypes.instanceOf(Date).isRequired,
      reason: PropTypes.string.isRequired
    })
  ),
  limit:    PropTypes.number,
  onRowAdd: PropTypes.func
};

export default Availabilities;
