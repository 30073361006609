import React, { useState, useRef } from 'react';
import SearchForm from './search-form';
import PastConsult from './past-consult';
import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { camelizeKeys, formatExportData } from '../utils';
import '../consult-history-page-container.css';
import { authToken, teladocApi } from '@td/api';
import moment from 'moment';
import '../consult-history-page.css';

const ConsultHistoryPage = ({ data, setExportParams, exportParams, updateCallBack, patientStatus }) => {
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const addendumToggle = Boolean(window.FEATURE_TOGGLES.post_consult_addendum);
  const csvLink = useRef();

  const downloadExportData = () => {
    setLoading(true);
    teladocApi.get('v4/consultations/search', { ...exportParams, per_page: 1000 }, authToken.get()).then(resp => {
      if (resp.status === 200) {
        const formattedResponse = resp.data.consultations.map(consult => camelizeKeys(consult));
        setExportData(formatExportData(formattedResponse));
      }
      csvLink.current.link.click();
      setLoading(false);
    });
  };

  const renderConsults = () => {
    if (data === null || !data.consultations) {
      return null;
    } else {
      const lastConsultsByMember = getLastConsultsByMember(data.consultations);
      return data.consultations.map(consultation => {
        let status = '';
        const match = patientStatus.find(entry => consultation.memberId.toString() === entry.memberId.toString());
        if (match && match.careTeamRelations && match.careTeamRelations.length > 0) {
          status = match.careTeamRelations[0].statusCd;
        }
        return (
          <PastConsult
            consultation={consultation}
            updateCallBack={updateCallBack}
            lastConsultsByMember={lastConsultsByMember}
            setSpinner={setSpinner}
            status={status}
            key={consultation.consultationId}
          />
        );
      });
    }
  };

  const Spinner = () => (
    <div className="loader">
      <img className="spinner" src={image_path('ajax_loader_66.gif')} alt="Loading..." />
    </div>
  );

  const getLastConsultsByMember = consultations => {
    const lastConsultsByMember = {};
    consultations.forEach(consult => {
      const member = consult.memberId;
      const date = new Date(consult.actualEndDate);
      const consultationId = consult.consultationId;
      if (!(member in lastConsultsByMember)) {
        lastConsultsByMember[member] = { date, consultationId };
      } else if (lastConsultsByMember[member].date < date) {
        lastConsultsByMember[member] = { date, consultationId };
      }
    });
    return lastConsultsByMember;
  };

  return (
    <div className="module">
      <div id="schedule_followup_container" className="schedule-follow-up" />
      <h1>History</h1>
      <SearchForm setExportParams={setExportParams} exportParams={exportParams} />

      <h3>Consultations</h3>
      <table>
        <thead>
          <tr>
            <th>Consult ID</th>
            <th>Consult Type</th>
            <th>Consult Start Time</th>
            <th>Consult Stop Time</th>
            <th>Patient</th>
            <th>Member ID</th>
            <th>Patient Address</th>
            <th>Pharmacy Address</th>
            <th className={!addendumToggle ? 'action' : null} colSpan="2">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="consultation-list">{!spinner && renderConsults()}</tbody>
      </table>
      {data === null || spinner ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Pagination
            activePage={parseInt(data.page)}
            totalItemsCount={data.totalEntries}
            itemsCountPerPage={15}
            pageRangeDisplayed={15}
            onChange={page => setExportParams({ ...exportParams, page })}
            prevPageText="← Previous"
            nextPageText="Next →"
            hideFirstLastPages
            innerClass="pagination"
            itemClass="page-item"
            activeLinkClass="current-page-number"
            linkClass="page-number"
          />
          <div id="consultation-history-footer">
            {`${data.totalEntries} Results`}
            <button
              id="download-consultation-button"
              disabled={data.totalEntries == 0 || loading}
              onClick={e => downloadExportData()}
            >
              {loading ? 'Downloading' : 'Download Consults'}
            </button>
            <CSVLink
              data={exportData}
              filename={`past_consultations_${moment().format('L')}.csv`}
              className="hidden"
              ref={csvLink}
            />
            <div id="consultation-history-footer-date">{`Showing consults from ${exportParams.start_date} to ${exportParams.end_date}`}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ConsultHistoryPage;

ConsultHistoryPage.propTypes = {
  data:            PropTypes.object,
  setExportParams: PropTypes.func,
  exportParams:    PropTypes.object,
  updateCallBack:  PropTypes.func
};
