import _ from 'lodash';
import store from 'app/lib/store';

import { MOUNT_CONTAINER_ID } from '../constants';

export const getConsultationType = () => {
  const rawDataList = _.get(document.querySelector('#consult_form'), 'dataset.initialValues', null);

  if (!rawDataList) return null;

  return _.get(JSON.parse(rawDataList), 'navigation_type', null);
};

export const getConsultationId = () => _.get(store.getState(), 'settings.consultationParams.consultationId', null);
export const getConsultationSpecialty = () =>
  _.get(document.querySelector(MOUNT_CONTAINER_ID), 'dataset.specialtyCode', null);
