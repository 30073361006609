import * as Yup from 'yup';
import moment from 'moment';

const validateSchema = ({ validateProps, validatePropsMessages, isAvailabilityEvent }) =>
  Yup.object().shape({
    startDate: Yup.date()
      .min(
        moment()
          .subtract(1, 'days')
          .toDate(),
        validatePropsMessages.startDateInPast
      )
      .required('Start Date is required')
      .test('check-time-buffer', function t(value) {
        if (!isAvailabilityEvent) return true;

        const { startTime } = this.parent;
        const now = moment();
        const startDateTime = moment(value).set({ hour: moment(startTime).hour(), minute: moment(startTime).minute() });
        const timeDifference = startDateTime.diff(now, 'minutes');
        if (timeDifference < validateProps.minStartDate) {
          if (timeDifference > 0) {
            return this.createError({
              message: validatePropsMessages.minBufferTime,
              path:    'startDate' // Fieldname
            });
          }
        } else {
          return true;
        }
      }),
    endDate: Yup.date()
      .min(Yup.ref('startDate'), validatePropsMessages.endDateBeforeStartDate)
      .required('End Date is required'),
    startTime: Yup.date()
      .min(
        moment()
          .subtract(1, 'days')
          .toDate(),
        validatePropsMessages.startDateInPast
      )
      .required('Start time is required')
      .test('start-time-in-past', function t(value) {
        const { startDate } = this.parent;
        const timeCheck = moment(value).isBefore(moment());
        const dateCheck = moment(startDate).isBefore(moment());
        // TODO update validation unit tests https://teladoc.atlassian.net/browse/PRS-838
        if (timeCheck && dateCheck) {
          return this.createError({
            message: validatePropsMessages.startDateInPast,
            path:    'startTime' // Fieldname
          });
        } else {
          return true;
        }
      }),
    endTime: Yup.date()
      .required('End time is required')
      .test('is-min-shift-length', validatePropsMessages.minShiftLength, function t(value) {
        if (!isAvailabilityEvent) return true;

        const { startTime } = this.parent;
        const endTime = value;
        const difference = moment(endTime).diff(startTime, 'minutes');
        if (difference >= validateProps.minShiftLengthSize || difference < 0) {
          return true;
        }
      })
      .test('is-max-shift-length', validatePropsMessages.maxShiftLength, function t(value) {
        if (!isAvailabilityEvent) return true;

        const { startTime } = this.parent;
        const endTime = value;
        const difference = moment(endTime).diff(moment(startTime), 'minutes');
        if (difference <= validateProps.maxShiftLengthSize) {
          return true;
        }
      })
      .test('check-end-time', validatePropsMessages.endTimeBeforeStartTime, function t(value) {
        const { startDate, endDate, startTime } = this.parent;
        const dateDifference = moment(endDate).diff(startDate, 'minutes');
        const timeDifference = moment(value).diff(startTime, 'minutes');
        if ((timeDifference > 0 && dateDifference === 0) || dateDifference > 0) {
          return true;
        }
      })
  });
export default validateSchema;
