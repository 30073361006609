import {
  SET_PRIMARY_DIAGNOSIS,
  CLEAR_PRIMARY_DIAGNOSIS
} from './actions';

const DEFAULT_STATE = {
  diagnosis: null
};

export const primaryDiagnosisReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_PRIMARY_DIAGNOSIS: {
      return {
        ...state,
        diagnosis: action.payload.diagnosis
      };
    }
    case CLEAR_PRIMARY_DIAGNOSIS: {
      return {
        ...state,
        diagnosis: null
      };
    }
    default: {
      return state;
    }
  }
};
