import { translate } from '@td/shared_utils';

export const HEALTH_CARD_COUNTRY = translate(null, 'personal_details.sections.health_card.labels',
  'health_card_country');

export const HEALTH_CARD_EXPIRY = translate(null, 'personal_details.sections.health_card.labels',
  'health_card_expiry');

export const HEALTH_CARD_ID = `${translate(null, 'personal_details.sections.health_card.labels',
  'health_card_id')} (no spaces or dashes)`;

export const HEALTH_CARD_PROVINCE = translate(null, 'personal_details.sections.health_card.labels',
  'health_card_state.default');

export const BUTTONS = {
  cancel: translate(null, 'my_schedule.modals.personal_event', 'actions.cancel'),
  save:   translate(null, 'my_schedule.modals.personal_event', 'actions.save')
};

export const SELECT_PROVINCE = 'Please select';

export const NOTIFICATION_SUCCESS_MESSAGE = 'Health Card successfully updated. Refresh the page to see those changes.';

export const NOTIFICATION_ERROR_MESSAGE = 'Health Card not updated. Please try again.';
