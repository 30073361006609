import { connect } from 'react-redux';
import MedicationReview from './medication-review';
import { mapInlineErrors } from 'app/consultation-interview/utils';
import {
  addToPendingReviewList,
  removeFromPendingReviewList,
  submitPendingReviewList,
  resetPendingReviewList,
  updatePendingReviewList
} from '../actions';

const mapStateToProps = state => {
  const {
    clinicalMedications: { medications: clinicalMedications },
    medicationHistory,
    medicationReview: { pendingReviewList, submitResult },
    consultNavigation
  } = state;

  return {
    clinicalMedications,
    medicationHistory,
    pendingReviewList,
    submitResult,
    validationErrors: mapInlineErrors(state)
  };
};

const mapDispatchToProps = {
  updatePendingReviewList,
  addToPendingReviewList,
  removeFromPendingReviewList,
  submitPendingReviewList,
  resetPendingReviewList
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicationReview);
