const consultationId = () => $('#consult').data('consultation_id');
const updateRequestMoreInfoSection = () => {
  if (!$('#primary_medical_diagnosis_search_code').val()) {
    return $('div#additional_info_section').show();
  } else {
    return $('div#additional_info_section').hide();
  }
};

export const showSelectedStep = index => {
  const content = $('#consult_content .react-step').eq(index);
  $('#consult_content .react-step').hide();
  content.fadeIn('fast');
};

export const cacheInterview = index => {
  if (consultationId() === undefined || index === 0) {
    return;
  }

  $.ajax({
    type:    'POST',
    data:    $('#consult_form').serialize(),
    url:     `/consultations/${consultationId()}/cache_interview`,
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });
  updateRequestMoreInfoSection();
};
