import React, {useContext, createContext, useState} from 'react';
import {createPortal} from 'react-dom';
import {isEqual} from 'lodash';

const KeepAliveContext = createContext();

export const KeepAliveProvider = ({children}) => {
  const [nodes, setNodes] = useState({});

  const getPortalElement = (id, portalClassName, children) => {
    const node = nodes[id];

    if (!node) {
      const element = document.createElement('div');
      portalClassName && (element.className = portalClassName);

      const newNode = {
        children,
        element
      };

      setNodes(prevNodes => ({
        ...prevNodes,
        [id]: newNode
      }));

      return element;
    } else if (node && !isEqual(node.children, children)) {
      const newNode = {
        children,
        element: node.element
      };

      setNodes(prevNodes => ({
        ...prevNodes,
        [id]: newNode
      }));
    }

    return node.element;
  };

  const renderPortal = () => Object
    .entries(nodes)
    .map(([id, node]) => createPortal(node.children, node.element));

  return (
    <KeepAliveContext.Provider value={{ getPortalElement }}>
      { children }
      { renderPortal() }
    </KeepAliveContext.Provider>
  );
};

export const useKeepAliveContext = () => useContext(KeepAliveContext);
