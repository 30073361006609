const CHANGE_CONSULT_NAVIGATION_STEP = 'CHANGE_CONSULT_NAVIGATION_STEP';
const CHANGE_CONSULT_NAVIGATION_STEP_SUCCESS = 'CHANGE_CONSULT_NAVIGATION_STEP/SUCCESS';
const CHANGE_CONSULT_NAVIGATION_STEP_FAIL = 'CHANGE_CONSULT_NAVIGATION_STEP/FAIL';
const MARK_CONSULT_NAVIGATION_STEP = 'MARK_CONSULT_NAVIGATION_STEP';
const ADD_ERRORS_TO_CONSULT_NAVIGATION_STEP = 'ADD_ERRORS_TO_CONSULT_NAVIGATION_STEP';
const CLEAR_ERRORS_FROM_CONSULT_NAVIGATION_STEP = 'CLEAR_ERRORS_FROM_CONSULT_NAVIGATION_STEP';
const ADD_WARNINGS_TO_CONSULT_NAVIGATION_STEP = 'ADD_WARNINGS_TO_CONSULT_NAVIGATION_STEP';
const CLEAR_WARNING_FROM_CONSULT_NAVIGATION_STEP = 'CLEAR_WARNING_FROM_CONSULT_NAVIGATION_STEP';
const UPDATE_CONSULT_NAVIGATION_ITEMS = 'UPDATE_CONSULT_NAVIGATION_ITEMS';
const CONSULT_NAVIGATION_NEXT_STEP = 'CONSULT_NAVIGATION_NEXT_STEP';
const VALIDATE_CONSULT_NAVIGATION_STEP = 'VALIDATE_CONSULT_NAVIGATION_STEP';

export {
  CHANGE_CONSULT_NAVIGATION_STEP,
  MARK_CONSULT_NAVIGATION_STEP,
  ADD_ERRORS_TO_CONSULT_NAVIGATION_STEP,
  CLEAR_ERRORS_FROM_CONSULT_NAVIGATION_STEP,
  ADD_WARNINGS_TO_CONSULT_NAVIGATION_STEP,
  CLEAR_WARNING_FROM_CONSULT_NAVIGATION_STEP,
  UPDATE_CONSULT_NAVIGATION_ITEMS,
  CHANGE_CONSULT_NAVIGATION_STEP_SUCCESS,
  VALIDATE_CONSULT_NAVIGATION_STEP,
  CONSULT_NAVIGATION_NEXT_STEP,
  CHANGE_CONSULT_NAVIGATION_STEP_FAIL
};
