import React, { forwardRef } from 'react';
import styles from './styles.module.scss';
import { calculateAgeByBirthday } from '../../../lib/utils';

const MemberInfoPIPMode = forwardRef(({ consultation }) => {
  const { member } = consultation;
  const { firstNm, lastNm, dob, id, memberId, healthEquityPronoun } = member;

  const fullName = `${firstNm} ${lastNm}`;

  const convertPronoun = pronoun => {
    const parts = pronoun.split('/');
    const capitalizedSecondPart = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
    return `(${parts[0]}/${capitalizedSecondPart})`;
  };

  return (
    <div className={styles.pipwDetailContainer}>
      <div className={styles.pipwName}>
        <div>
          <span>{fullName}</span>
        </div>
        <div>
          <span>
            &nbsp;
            {healthEquityPronoun && healthEquityPronoun.length > 0 && convertPronoun(healthEquityPronoun[0])}
          </span>
        </div>
      </div>
      <div className={styles.pipwInfo}>
        <div className={styles.pipwAge}>
          <span>
            Age <b>{calculateAgeByBirthday(dob)}</b>
          </span>
        </div>
        <div className={styles.pipwMrn}>
          <span>
            MRN <b>{id || memberId}</b>
          </span>
        </div>
      </div>
    </div>
  );
});

export default MemberInfoPIPMode;
