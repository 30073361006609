import { connect } from 'react-redux';

import NetworkTestErrorActions from './network-test-error-actions';
import { networkTestComplete, networkTestStart } from '../actions';
import { isUnsupportedBrowserError } from '../../lib/open-tok-network-test/open-tok-network-test-error';

export const mapStateToProps = state => {
  const error = state.networkTest.status.error;
  const allowVideoClockIn = state.networkTest.settings.allowVideoClockIn;

  return {
    allowRerun: !isUnsupportedBrowserError(error) && !allowVideoClockIn
  };
};

const mapDispatchToProps = {
  onComplete: networkTestComplete,
  onRerun: networkTestStart
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkTestErrorActions);
