import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { translateMarkupString } from '@td/shared_utils';

const SameDayVisitsSectionMessage = ({ isDisabled, isAcceptingSameDayVisits }) => {
  const sectionMessageKey = (() => {
    if (isDisabled) return 'toggle_disabled_message_html';
    if (isAcceptingSameDayVisits) return 'toggle_on_message_html';
    return 'toggle_off_message_html';
  })();

  return (
    <p className="same-day-visits-message">
      {translateMarkupString('my_account.same_day_visits_section', sectionMessageKey, {
        b: <b />,
        a: createElement('a', { href: 'mailto:providersupport@teladochealth.com' })
      })}
    </p>
  );
};

SameDayVisitsSectionMessage.propTypes = {
  isDisabled: PropTypes.bool,
  isAcceptingSameDayVisits: PropTypes.bool
};

export default SameDayVisitsSectionMessage;
