import React, { useRef, useLayoutEffect } from 'react';

/*
  Used for DOM hacking.
  It moves (relocates) a certain HTML element from initial position container to it's own container.
  CurrentPosition property tells the current position for the element that is being relocated, meaning this is a controlled component.
*/
const HTMLElementRelocator = ({ style, className, initialPositionContainer, currentPosition }) => {
  const relocatedPositionContainerRef = useRef();
  const initialPostionContainerElement = document.querySelector(initialPositionContainer);
  useLayoutEffect(() => {
    if (currentPosition === 'initial') {
      const followupAppointmentElement = relocatedPositionContainerRef.current.children[0];
      followupAppointmentElement && initialPostionContainerElement.appendChild(followupAppointmentElement);
    } else if (currentPosition === 'relocated') {
      const followupAppointmentElement = initialPostionContainerElement.children[0];
      followupAppointmentElement && relocatedPositionContainerRef.current.appendChild(followupAppointmentElement);
    }
  }, [currentPosition]);
  return <div className={className} style={style} ref={relocatedPositionContainerRef} />;
};

export default HTMLElementRelocator;
