import React from 'react';

import DataTable from '../data-table';
import PatientListStatus from './PatientListStatus';
import PatientListDiagnosis from './PatientListDiagnosis';
import PatientListMemberName from './PatientListMemberName';
import PatientListDueVisit from './PatientListDueVisit';

import { usePatientList } from './PatientListProvider';
import { getBrowserTimezone, isTherapist } from './utils';
import { isEmpty } from 'lodash';
import ActionsMenu from './ActionsMenu/ActionsMenu';
import { useData } from '../DataProvider';

const getColumns = ({ role }) => [
  {
    id: 'personName',
    name: I18n.t('patient_case_load_mgmt_mvp.patient_list.name'),
    selector: props => <PatientListMemberName {...props} />,
    sortable: true
  },
  {
    id: 'status',
    name: I18n.t('patient_case_load_mgmt_mvp.patient_list.status'),
    selector: ({ relationWithCurrentProvider }) => {
      const { statusCd, terminateReason } = relationWithCurrentProvider || {};
      return <PatientListStatus statusCd={statusCd} terminateReason={terminateReason} />;
    },
    sortable: true
  },
  {
    id: 'diagnosis',
    name: I18n.t('patient_case_load_mgmt_mvp.patient_list.primary_diagnosis'),
    selector: ({ lastConsultationForCurrentProvider }) => {
      const { clinicalDiagnosis } = lastConsultationForCurrentProvider || {};
      return <PatientListDiagnosis clinicalDiagnosis={clinicalDiagnosis} />;
    }
  },
  {
    id: 'last_consultation',
    name: I18n.t('patient_case_load_mgmt_mvp.patient_list.last_consult'),
    selector: ({ lastConsultationForCurrentProvider }) => {
      const { actualEndDate } = lastConsultationForCurrentProvider || {};
      return getBrowserTimezone(actualEndDate);
    },
    sortable: true
  },
  {
    id: 'next_consultation',
    name: I18n.t('patient_case_load_mgmt_mvp.patient_list.next_consult'),
    selector: ({ nextConsultationForCurrentProvider }) => {
      const { requestOrFollowUpDate } = nextConsultationForCurrentProvider || {};
      return getBrowserTimezone(requestOrFollowUpDate);
    },
    sortable: true
  },
  ...(isTherapist(role) && [
    {
      id: 'due_for_visit_in',
      name: I18n.t('patient_case_load_mgmt_mvp.patient_list.due_for_visit_in'),
      selector: ({ lastConsultationForCurrentProvider, nextConsultationForCurrentProvider, relationWithCurrentProvider }) => {
        const { statusCd } = relationWithCurrentProvider || {};
        const { requestOrFollowUpDate } = nextConsultationForCurrentProvider || {};
        const { actualEndDate, followupInstruction } = lastConsultationForCurrentProvider || {};
        return (
          <PatientListDueVisit
            inDays={followupInstruction}
            lastConsult={actualEndDate}
            nextConsult={requestOrFollowUpDate}
            statusCd={statusCd}
          />
        );
      },
      sortable: true
    }
  ]),
  {
    id: 'actions',
    name: '',
    selector: props => <ActionsMenu {...props} />
  }
];

const PatientList = () => {
  const {
    data: { collection = [], metaData = {} } = {},
    loading,
    sortColumn,
    sortDirection,
    handleSortChange,
    filters
  } = usePatientList();

  const { role } = useData();

  const noFilterMatches = !loading && !collection.length && !isEmpty(filters);

  return noFilterMatches ? (
    <p>{I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.no_matches')}</p>
  ) : (
    <DataTable
      columns={getColumns({ role })}
      data={collection}
      loading={loading}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      onSort={handleSortChange}
      tableName="PatientList"
      loadedRecords={collection.length}
      totalRecords={metaData.totalCount}
    />
  );
};

export default PatientList;
