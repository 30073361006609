import { SET_EHR_IS_LOADED, SET_VIEW_ALL_MENTAL_HEALTH_DOCUMENTS } from './action-types';

const DEFAULT_STATE = {
  isLoaded:                         false,
  isViewedAllMentalHealthDocuments: false
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_EHR_IS_LOADED: {
      return {
        ...state,
        isLoaded: true
      };
    }
    case SET_VIEW_ALL_MENTAL_HEALTH_DOCUMENTS: {
      return {
        ...state,
        isViewedAllMentalHealthDocuments: true
      };
    }
    default: {
      return state;
    }
  }
};

export { DEFAULT_STATE };

export default reducer;
