import React from 'react';
import PropTypes from 'prop-types';
import PharmacyListItem from './pharmacy-list-item';

const PharmacyList = ({ searchResults = [], onClickHandler }) => (
  <div>
    <h3>Search Results</h3>
    <table>
      <thead>
        <tr>
          <th>Pharmacy Name</th>
          <th>Street Address</th>
          <th>City</th>
          <th>State/Province</th>
          <th>Phone Number</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {searchResults.length !== 0 ? (
          searchResults.map((pharmacy, index) => (
            <PharmacyListItem
              key={index}
              onClickHandler={onClickHandler}
              {...pharmacy}
            />
          ))
        ) : (
          <tr>
            <td colSpan="6">
              <p>Unable to find pharmacies at specified address.</p>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

PharmacyList.propTypes = {
  searchResults:  PropTypes.array.isRequired,
  onClickHandler: PropTypes.func.isRequired
};

export default PharmacyList;
