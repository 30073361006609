export const RECEIVE_PERSONAL_HEALTH_RECORDS = 'RECEIVE_PERSONAL_HEALTH_RECORDS'

export const HK_BODY_TEMPERATURE = "HKQuantityTypeIdentifierBodyTemperature"
export const KINSA_TEMPERATURE =  "KinsaTemperature"
export const BODY_TEMPERATURE_TYPES = [KINSA_TEMPERATURE, HK_BODY_TEMPERATURE]

export const HK_BLOOD_PRESSURE_SYSTOLIC = "HKQuantityTypeIdentifierBloodPressureSystolic"
export const HK_BLOOD_PRESSURE_DIASTOLIC = "HKQuantityTypeIdentifierBloodPressureDiastolic"
export const BLOOD_PRESSURE_TYPES = [HK_BLOOD_PRESSURE_SYSTOLIC, HK_BLOOD_PRESSURE_DIASTOLIC]

export const LAB_RESULT_TYPES = {
  BODY_TEMPERATURE_TYPE: BODY_TEMPERATURE_TYPES
};

export const PHR_CATEGORY = {
  HEART_RATE_TYPE:              'Heart Rate',
  BODY_TEMPERATURE_TYPE:        'Body Temperature',
  BLOOD_PRESSURE_SYSTOLIC_TYPE: 'Blood Pressure',
  BLOOD_PRESSURE_DIASTOLIC_TYPE:'Blood Pressure'
};

export const LAB_RESULT_NAMES = {
  [HK_BODY_TEMPERATURE]:        'Body Temperature',
  [KINSA_TEMPERATURE]:          'Body Temperature',
  [HK_BLOOD_PRESSURE_SYSTOLIC]: 'Systolic',
  [HK_BLOOD_PRESSURE_DIASTOLIC]:'Diastolic'
};

export const PHR_TO_FETCH = [].concat(BODY_TEMPERATURE_TYPES, BLOOD_PRESSURE_TYPES)
