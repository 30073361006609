import { NameAttributes } from '../dom-integration';
import { FOLLOWUP_APPOINTMENT_OPTIONS } from '../constants';

// Each time user switches the tab in the consult form, it saves all inputs on the server, this files cares for restoring those values

const restorePreviousState = (state, previousValues, providerScheduledAppointmentAllowed) => {
  const res = {
    selectedAppointmentOption: getAppointmentOption(previousValues, providerScheduledAppointmentAllowed),
    noFurtherCareDays:         previousValues[NameAttributes.appointment.noFurtherCareDays.restore] || '',
    selectedTeladocServices:   restoreSelectedTeladocServices(previousValues, state.teladocServices),
    selectedNonTeladocService: previousValues[NameAttributes.referrals.nonTeladocServices.dropdown.restore],
    p2pNote:                   previousValues[NameAttributes.referrals.teladocServices.p2pNote.restore]
  };
  return res;
};

export default restorePreviousState;

const restoreSelectedTeladocServices = (prevValues, teladocServices) => {
  const programs = prevValues[NameAttributes.referrals.teladocServices.program.restore] || [];
  const services = prevValues[NameAttributes.referrals.teladocServices.service.restore] || [];
  const partnerServices = prevValues[NameAttributes.referrals.partnerServices.restore] || [];
  const names = [...programs, ...services, ...partnerServices].filter(n => !!n);
  const serviceFeatues = {}; // TODO: try to restore
  const codes = names
    .filter(serviceName => teladocServices.some(s => s.name === serviceName))
    .map(serviceName => {
      const serviceCode = teladocServices.find(s => s.name === serviceName).code;
      const featureCode = serviceFeatues[serviceName] && featureNameToCode(serviceCode, serviceFeatues[serviceName]);
      return { serviceCode, featureCode };
    });

  return codes;
};

const featureNameToCode = (serviceCode, featureName) => {
  if (serviceCode === 'BEHAVHEALTH' && featureName === 'Psychiatrist') return 'MD';
  else if (serviceCode === 'BEHAVHEALTH' && featureName === 'Therapist/Counselor') return 'NONMD';
};

const getAppointmentOption = (previousValues, providerScheduledAppointmentAllowed) => {
  const restored = previousValues[NameAttributes.appointment.activeSection.restore];
  if (restored) return restored;
  else if (providerScheduledAppointmentAllowed) return FOLLOWUP_APPOINTMENT_OPTIONS.PROVIDER_SCHEDULED;
};
