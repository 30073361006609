import {
  ADD_TO_PENDING_REVIEW_LIST,
  UPDATE_PENDING_REVIEW_LIST,
  REMOVE_FROM_PENDING_REVIEW_LIST,
  RESET_PENDING_REVIEW_LIST,
  SUBMIT_PENDING_REVIEW_LIST_START,
  SUBMIT_PENDING_REVIEW_LIST_FAIL,
  SUBMIT_PENDING_REVIEW_LIST_SUCCESS
} from './action-types';

const DEFAULT_STATE = {
  errors:            {},
  pendingReviewList: [],
  submitResult:      { pending: false, success: false, failed: false }
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_TO_PENDING_REVIEW_LIST: {
      const { pendingReviewList } = state;
      const medication            = action.payload;

      return {
        ...state,
        pendingReviewList: [
          ...pendingReviewList,
          medication
        ]
      };
    }

    case REMOVE_FROM_PENDING_REVIEW_LIST: {
      const { drugName } = action.payload;
      const { pendingReviewList } = state;
      const newList = pendingReviewList.filter(med => med.drugName !== drugName);

      return {
        ...state,
        pendingReviewList: newList
      };
    }

    case UPDATE_PENDING_REVIEW_LIST: {
      const { pendingReviewList } = state;
      const newMedication = action.payload;
      const newList = pendingReviewList.slice();

      const index = pendingReviewList.findIndex(
        med => med.clinicalMedicationId === newMedication.clinicalMedicationId);

      if (index > -1) { newList.splice(index, 1, newMedication); }

      return { ...state,  pendingReviewList: newList };
    }

    case RESET_PENDING_REVIEW_LIST: {
      return {
        ...state,
        pendingReviewList: []
      };
    }

    case SUBMIT_PENDING_REVIEW_LIST_START: {
      const { submitResult } = state;

      const result = {
        ...submitResult,
        pending: true,
        failed:  false,
        success: false
      };

      return {
        ...state,
        pendingReviewList: [],
        submitResult:      result
      };
    }

    case SUBMIT_PENDING_REVIEW_LIST_SUCCESS: {
      const { submitResult } = state;
      const result = {
        ...submitResult,
        pending: false,
        failed:  false,
        success: true
      };

      return {
        ...state,
        submitResult: result
      };
    }

    case SUBMIT_PENDING_REVIEW_LIST_FAIL: {
      const { submitResult } = state;

      return {
        ...state,
        submitResult: {
          ...submitResult,
          failed: true
        }
      };
    }

    default: {
      return state;
    }
  }
};
