import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import SurescriptsMedicationReview from './surescripts-medication-review';
import TeladocMedicationReview from './teladoc-medication-review';
import ReviewSummary from './review-summary';
import '../styles.scss';

const isEmrNewMedicationEnabled = window.FEATURE_TOGGLES.emr_new_medication;

const RenderSureScriptsMedicationReview = ({
  groupedClaims = [],
  groupedFills = [],
  clinicalMedications = {},
  pendingReviewList,
  addToPendingReviewList,
  updatePendingReviewList
}) => {
  if (isEmpty(groupedFills) && isEmpty(groupedClaims)) {
    return null;
  }

  const { clinicalMeds = [] } = clinicalMedications;

  const tdocNdcs = new Set();
  clinicalMeds.forEach(med => tdocNdcs.add(med.drugCode));

  const medicationList = [...groupedClaims, ...groupedFills];

  const medications = medicationList.filter(
    groupedMedication => !groupedMedication.olderThanDays(90) && !tdocNdcs.has(groupedMedication.getProductCode())
  );

  return (
    <div>
      {!isEmrNewMedicationEnabled && <h3 className="medication-review-header">SureScripts Reported</h3>}
      <SurescriptsMedicationReview
        medications={medications}
        pendingReviewList={pendingReviewList}
        addToPendingReviewList={addToPendingReviewList}
        updatePendingReviewList={updatePendingReviewList}
      />
    </div>
  );
};

RenderSureScriptsMedicationReview.propTypes = {
  groupedClaims:           PropTypes.array,
  groupedFills:            PropTypes.array,
  clinicalMedications:     PropTypes.object,
  pendingReviewList:       PropTypes.array,
  addToPendingReviewList:  PropTypes.func,
  updatePendingReviewList: PropTypes.func.isRequired
};

const RenderTeladocMedicationReview = ({
  clinicalMeds = [],
  pendingReviewList,
  addToPendingReviewList,
  updatePendingReviewList
}) => {
  if (clinicalMeds.length === 0) {
    return null;
  }

  return (
    <div>
      {!isEmrNewMedicationEnabled && <h3 className="medication-review-header">Teladoc Reported</h3>}
      <TeladocMedicationReview
        medications={clinicalMeds}
        pendingReviewList={pendingReviewList}
        addToPendingReviewList={addToPendingReviewList}
        updatePendingReviewList={updatePendingReviewList}
      />
    </div>
  );
};

RenderTeladocMedicationReview.propTypes = {
  clinicalMeds:            PropTypes.array,
  pendingReviewList:       PropTypes.array,
  addToPendingReviewList:  PropTypes.func,
  updatePendingReviewList: PropTypes.func.isRequired
};

class MedicationReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingReviewList:   this.props.pendingReviewList || [],
      clinicalMedications: this.props.clinicalMedications || [],
      errors:              {}
    };
  }

  addToPendingReviewList = medication => {
    const { pendingReviewList } = this.props;
    const { isDirty, drugName } = medication;
    const index = pendingReviewList.findIndex(med => med.drugName === drugName);

    if (isDirty) {
      this.props.addToPendingReviewList(medication);
    } else if (index > -1) {
      this.props.removeFromPendingReviewList(medication);
    }
  };

  handleOnFormClear = () => {
    this.setState({
      pendingReviewList:   this.props.pendingReviewList,
      clinicalMedications: this.props.clinicalMedications
    });

    this.props.resetPendingReviewList();
  };

  handleOnFormSubmit = e => {
    e.preventDefault();

    const { pendingReviewList } = this.props;
    const params = pendingReviewList.map(med => ({
      id:                   med.clinicalMedicationId,
      actively_taking_flag: med.activelyTakingFlag
    }));

    this.props.submitPendingReviewList(params);
  };

  render() {
    const {
      clinicalMedications: { fetched: clinicalMedsFetched },
      medicationHistory: { fetched: historyMedsFetched, medicationRecords },
      pendingReviewList,
      updatePendingReviewList,
      validationErrors
    } = this.props;

    const reviewSubmitRequiredError = validationErrors.find(
      ({ validatorKey }) => validatorKey === 'reviewedMedicationSubmitRequired'
    );

    return (
      <div>
        <p className="medication-header-notice">
          All the medications on record for the patient are listed below. Please review and update the status so proper
          interaction checking occurs.
        </p>
        {window.FEATURE_TOGGLES.emr_new_medication && (
          <p>
            <strong>Note:</strong> If the status cannot be changed and member has not updated their EHR, the medication must be reentered.
          </p>
        )}
        {clinicalMedsFetched && (
          <RenderTeladocMedicationReview
            {...this.props.clinicalMedications}
            pendingReviewList={pendingReviewList}
            addToPendingReviewList={this.addToPendingReviewList}
            updatePendingReviewList={updatePendingReviewList}
          />
        )}

        {historyMedsFetched && clinicalMedsFetched && (
          <RenderSureScriptsMedicationReview
            {...medicationRecords}
            clinicalMedications={this.props.clinicalMedications}
            pendingReviewList={pendingReviewList}
            addToPendingReviewList={this.addToPendingReviewList}
            updatePendingReviewList={updatePendingReviewList}
          />
        )}

        {this.props.pendingReviewList.length > 0 && (
          <div>
            <ReviewSummary medications={this.props.pendingReviewList} />

            {reviewSubmitRequiredError && (
              <div className="interview_inline_validation_error show">{reviewSubmitRequiredError.message}</div>
            )}

            <div className="review-container__actions">
              <div className="review-container__actions-column">
                <input className="button-secondary" type="button" value="Cancel" onClick={this.handleOnFormClear} />
              </div>
              <div className="review-container__actions-column">
                <input
                  className="button-secondary"
                  type="button"
                  value="Complete Review"
                  onClick={this.handleOnFormSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

MedicationReview.propTypes = {
  clinicalMedications:         PropTypes.object,
  medicationHistory:           PropTypes.object,
  pendingReviewList:           PropTypes.array.isRequired,
  removeFromPendingReviewList: PropTypes.func.isRequired,
  addToPendingReviewList:      PropTypes.func.isRequired,
  updatePendingReviewList:     PropTypes.func.isRequired,
  resetPendingReviewList:      PropTypes.func.isRequired,
  submitPendingReviewList:     PropTypes.func.isRequired,
  validationErrors:            PropTypes.array.isRequired
};

export default MedicationReview;
