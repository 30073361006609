import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { authToken } from '@td/api';
import { useUpdater, logError } from '@td/shared_utils';
import { map, isEmpty, get, isEqual, flattenDeep } from 'lodash';
import LoadingIndicator from 'app/components/loading-indicator/loading-indicator';
import { LockNextConsultButton } from './lock-next-consult-button';
import { commonJsRedirect } from '../utils/utils';
import '../styles/styles.scss';

const ERROR_LOCK_NEXT = 'There was a problem locking the next consultation.';
const ERROR_SUBMIT_INTERVIEW = 'There was a problem completing the consultation.';

export const LockNextConsultRequestHandler = ({
  communicationMethods,
  consultNavItems,
  addErrorsToConsultNavigationStep,
  consultationId }) => {
  const formattedCommMethods = communicationMethods ? communicationMethods.map(c => c.toLowerCase()) : null;
  const summaryStep = consultNavItems.items && consultNavItems.items.find(e => e.key === 'summary');

  const r1 = useUpdater('post', {
    url:     `/consultations/${consultationId}/complete`,
    method:  'post',
    baseURL: window.location.origin,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `${authToken.get()}` }
  });

  const r2 = useUpdater('get', {
    url:     '/consultations/lock',
    method:  'get',
    baseURL: window.location.origin,
    headers: { 'Content-Type': 'text/html', Authorization: `${authToken.get()}` },
    params:  { communication_methods: formattedCommMethods, reason_code: 'CONSULTSTATUSRSN_AUTOLOCK' }
  });

  const showConsultSubmissionErrors = requestState => {
    const errorMsgs = get(requestState.error, 'response.data.errors');
    if (errorMsgs && typeof (errorMsgs) === 'object') {
      showError(flattenDeep(Object.values(errorMsgs)));
    } else {
      showError([ERROR_SUBMIT_INTERVIEW]);
    }
  };

  const showConsultLockErrors = requestState => {
    const lockNextServerError = requestState.data && requestState.data.errors;
    if (Array.isArray(lockNextServerError)) {
      const errorMsgs = lockNextServerError.map(tp => Object.values(tp));
      if (errorMsgs) {
        showError(flattenDeep(errorMsgs));
      } else {
        showError([ERROR_LOCK_NEXT]);
      }
    } else {
      showError([ERROR_LOCK_NEXT]);
    }
  };

  const showError = msgArr => {
    if (!isEqual(msgArr.sort(), summaryStep.errors && summaryStep.errors.sort())) {
      addErrorsToConsultNavigationStep(8, msgArr);
    }
  };

  useEffect(() => {
    document.addEventListener('reactInterviewReadyToSubmit', e => {
      const payload = e.detail;
      payload.append('authenticity_token', document.querySelector("meta[name='csrf-token']").getAttribute('content'));
      payload.set("interview[auto_lock][][selected]", true);
      r1.callApi(payload);
    });
  }, [consultationId]);

  useEffect(() => {
    if (r1.finished && r1.data && r1.data.consultationId && !r2.data && !r2.error) {
      r2.callApi();
    }
  }, [r1.finished]);

  useEffect(() => {
    if (r1.finished && !(r1.data && r1.data.consultationId)) {
      showConsultSubmissionErrors(r1);
    }
  }, [r1.finished]);

  useEffect(() => {
    if (r2.finished && !(r2.data && r2.data.consultationId)) {
      showConsultLockErrors(r2);
    }
  }, [r2.finished]);

  if (r1.finished) {
    if (r1.data && r1.data.consultationId) {
      if (r2.finished) {
        if (r2.data && r2.data.consultationId && r2.data.isExternalConsult) {
          commonJsRedirect('/');
          return (
            <div className="overlay-disable">
              <div className="centered-loading-indicator">
                <LoadingIndicator />
                <div><h3>Successfully locked external consult, redirecting to dashboard</h3></div>
              </div>
            </div>
          );
        } else if (r2.data && r2.data.consultationId) {
          commonJsRedirect(`/consultations/${r2.data.consultationId}`);
          return (
            <div className="overlay-disable">
              <div className="centered-loading-indicator">
                <LoadingIndicator />
                <div><h3>Successfully locked next consultation</h3></div>
              </div>
            </div>
          );
        } else {
          commonJsRedirect('/');
          return (
            <div className="overlay-disable">
              <div className="centered-loading-indicator">
                <LoadingIndicator />
              </div>
            </div>
          );
        }
      } else if (r2.loading) {
        return (
          <div className="overlay-disable">
            <div className="centered-loading-indicator">
              <LoadingIndicator />
              <div><h3>Attempting to lock next consult</h3></div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      commonJsRedirect('/');
      return (
        <div className="overlay-disable">
          <div className="centered-loading-indicator">
            <LoadingIndicator />
          </div>
        </div>
      );
    }
  } else if (r1.loading) {
    return (
      <div className="overlay-disable">
        <div className="centered-loading-indicator">
          <LoadingIndicator />
        </div>
      </div>
    );
  } else {
    return (
      <LockNextConsultButton communicationMethods={communicationMethods} consultNavItems={consultNavItems} />
    );
  }
};

LockNextConsultRequestHandler.propTypes = {
  communicationMethods: PropTypes.array,
  consultNavItems:      PropTypes.shape({
    items:         PropTypes.array,
    selectedIndex: PropTypes.number
  }),
  addErrorsToConsultNavigationStep: PropTypes.func,
  consultationId:                   PropTypes.number
};
