// Temporary fix for Canada Provider
// Prevent providers from changing password in provider.teladoc.ca
const preventProfileEdit = () => {
  const app_region = `${window.APPLICATION_REGION}`;
  const host_uri   = $(location).attr('host');

  if (app_region == 'CAN') {
    $("a[href$='my_account/account_information_edit']").hide();
    $("a[href$='my_account/password_information_edit']").hide();
    $("div.editable_content").find(".edit").hide();
    $("div.editable_content").find(".delete").hide();
    $("div.new_record").find(".add").hide();
    $('.preferred-availability--selection input[type="submit"]').hide();
    $('.provider-unavailability--content input[type="submit"]').hide();
  }
  return true
}

export default preventProfileEdit;
