import { connect } from 'react-redux';

import { additionalNotesForm } from '../../actions';
import AdditionalNotes from './additional-notes';

const mapStateToProps = state => ({
  formValues: state.additionalNotesForm.formValues
});

const mapDispatchToProps = {
  changeField: additionalNotesForm.changeField
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalNotes);
