import React from 'react';
import PropTypes from 'prop-types';

const SymptomsHiddenReducers = ({ symptomsFormReducer, symptomsAddedReducer }) => (
  <span>
    <input type="hidden" value={symptomsFormReducer} name="interview[symptoms_form_reducer]" />
    <input type="hidden" value={symptomsAddedReducer} name="interview[symptoms_added_reducer]" />
  </span>
);

SymptomsHiddenReducers.propTypes = {
  symptomsFormReducer:  PropTypes.string.isRequired,
  symptomsAddedReducer: PropTypes.string.isRequired
};

export default SymptomsHiddenReducers;
