import React from 'react';
import PropTypes from 'prop-types';
import RescheduleAction from './RescheduleAction';

const RescheduleScheduleVisitAction = ({ scheduledDate, requestDate, denyRescheduleInterval, ...props }) => {
  const consultationIsReschedulable = () => {
    if (!scheduledDate) return false;
    if (denyRescheduleInterval !== undefined)
      return moment(requestDate).subtract(denyRescheduleInterval, 'seconds') > moment();
  };

  if (!consultationIsReschedulable()) return null;

  return <RescheduleAction {...props} />;
};

RescheduleScheduleVisitAction.propTypes = {
  scheduledDate: PropTypes.string,
  requestDate: PropTypes.string,
  denyRescheduleInterval: PropTypes.number
};

export default RescheduleScheduleVisitAction;
