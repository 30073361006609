import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import moment from 'moment';
import { translate } from '@td/shared_utils';

const LogItem = React.memo(({ name, roleCd, status, createdAt }) => {
  const date = moment(createdAt);

  return (
    <React.Fragment>
      <div className={styles.logItemTime} data-testid="call-details-log-item-time">
        {date.format(date.isSame(moment(), 'day') ? 'hh:mm a' : 'DD MMM, hh:mm a')}
      </div>
      <div data-testid="call-details-log-item-message">
        {translate(null, 'call_details.log_message', `${roleCd}_${status}`.replace(/^(tdoc\_)/, '').toLowerCase(), {
          username: name
        })}
      </div>
    </React.Fragment>
  );
});

LogItem.propTypes = {
  name:      PropTypes.string.isRequired,
  roleCd:    PropTypes.string.isRequired,
  status:    PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired
};

export default LogItem;
