import React from 'react';
import PropTypes from 'prop-types';
import { compact, join } from 'lodash';

const SystemGeneratedNote = React.memo(({ generalInformationNote, travelMedicationSurveyNote, symptomsNote }) => {
  const fieldValue = join(
    compact([
      generalInformationNote,
      travelMedicationSurveyNote,
      symptomsNote
    ]),
    "\n"
  );

  return (
    <div>
      <h3>System-Generated Note</h3>
      <div className="system-generated-note">
        <input
          type="hidden"
          value={fieldValue}
          name="interview[clinical_notes[0]][note]"
          data-name="subjective_note"
        />
        <input
          type="hidden"
          value="NOTETYPE_SUB"
          name="interview[clinical_notes[0]][note_type_cd]"
          data-name="subjective_note_type"
        />

        <div id="general-information-note">{generalInformationNote}</div>
        {travelMedicationSurveyNote && (
          <div id="travel-medication-survey-note">{travelMedicationSurveyNote}</div>
        )}
        {symptomsNote && (
          <div id="symptoms-note">{symptomsNote}</div>
        )}
      </div>
    </div>
  );
});

SystemGeneratedNote.displayName = 'SystemGeneratedNote';
SystemGeneratedNote.propTypes = {
  generalInformationNote: PropTypes.string,
  symptomsNote: PropTypes.string,
  travelMedicationSurveyNote: PropTypes.string
};

export default SystemGeneratedNote;
