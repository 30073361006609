import { FETCH_PATIENT_SYNC_ERRORS, ERX_ERRORS_INIT } from '../action-types';
import {
  SUBMIT_PRESCRIPTION_FORM_FAIL,
  REMOVE_FROM_PENDING_PRESCRIPTION_LIST
} from '../../Prescription/actionTypes';

export const DEFAULT_STATE = {
  patientSyncError:              '',
  prescriptionsSubmissionErrors: []
};

const onFetchPatientSyncErrorsSuccess = (state, action) => ({
  ...state,
  patientSyncError: action.payload.error || ''
});

const onFetchPatientSyncErrorsFail = (state, action) => ({
  ...state,
  patientSyncError: action.payload.message
});

const onSubmitPrescriptionFormFail = (state, action) => ({
  ...state,
  prescriptionsSubmissionErrors: [
    ...state.prescriptionsSubmissionErrors.filter(
      p => p.drug_ndc_code !== action.payload.drugNdcCode
    ),
    {
      drug_ndc_code: action.payload.drugNdcCode,
      errors:        action.payload.errors
    }
  ]
});

const onRemoveFromPendingPrescriptionList = (state, action) => ({
  ...state,
  prescriptionsSubmissionErrors: state.prescriptionsSubmissionErrors.filter(
    p => p.drug_ndc_code !== action.payload.drugNdcCode
  )
});

const onErxErrorsInit = (state, action) => ({
  ...state,
  ...action.payload.erxErrors
});

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_PATIENT_SYNC_ERRORS.SUCCESS: {
      return onFetchPatientSyncErrorsSuccess(state, action);
    }
    case FETCH_PATIENT_SYNC_ERRORS.FAIL: {
      return onFetchPatientSyncErrorsFail(state, action);
    }
    case SUBMIT_PRESCRIPTION_FORM_FAIL: {
      return onSubmitPrescriptionFormFail(state, action);
    }
    case REMOVE_FROM_PENDING_PRESCRIPTION_LIST: {
      return onRemoveFromPendingPrescriptionList(state, action);
    }
    case ERX_ERRORS_INIT: {
      return onErxErrorsInit(state, action);
    }
    default: {
      return state;
    }
  }
};
