import React from 'react';
import PropTypes from 'prop-types';
import useConferenceLogs from '../../hooks/use-conference-logs';
import styles from './styles.module.scss';
import LogItem from '../log-item';

const Logs = ({ consultationId }) => {
  const logs = useConferenceLogs({ consultationId });

  return (
    <div className={styles.logsContainer}>
      {logs.map(({ id, ...log }) => (
        <LogItem key={id} {...log} />
      ))}
    </div>
  );
};

Logs.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default Logs;
