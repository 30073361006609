import React from 'react';
import PropTypes from 'prop-types';

const PatientListDiagnosis = ({ clinicalDiagnosis }) => {
  const { diagnosisCd, diagnosisNm } = clinicalDiagnosis || {};

  return (
    <p>
      <span className="diagnosis-code">{diagnosisCd}</span> - {diagnosisNm}
    </p>
  );
};

PatientListDiagnosis.propTypes = {
  clinicalDiagnosis: PropTypes.shape({
    diagnosisCd: PropTypes.string,
    diagnosisNm: PropTypes.string
  })
};

export default PatientListDiagnosis;
