import { SHOW_ALERT, CLOSE_ALERT } from './action-types';
import { AlertPriority } from './enums';

export const DEFAULT_STATE = {
  message: null,
  componentId: null,
  buttonText: undefined,
  priority: AlertPriority.LOW
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SHOW_ALERT: {
      const { message, componentId, buttonText, priority } = action.payload;

      if (priority >= state.priority) {
        return {
          ...state,
          message,
          componentId,
          buttonText,
          priority
        };
      }

      return state;
    }
    case CLOSE_ALERT: {
      return DEFAULT_STATE;
    }
    default: {
      return state;
    }
  }
};
