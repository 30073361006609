import { get } from 'lodash';

export const clinicalAlertValidator = state => {
  const caSurvey = get(state, 'surveys.SURVEYMNEMONIC_ALERTCOVID19V3');

  const doYouPlanToPrescribePaxlovidFlag = get(caSurvey, 'SECTIONMNEMONIC_ALERTCOVID19V3.Q_1_1');
  const iAttestCheckbox = get(caSurvey, 'SECTIONMNEMONIC_ALERTCOVID19V3.Q_1_2');

  const caFlag = get(state, 'clinicalAlertForm.mainInquiry');

  return {
    caSurveyPresence: {
      invalid:  !caFlag,
      message:  'You must fill out the epidemiological risk assesment followup section.',
      isInline: true
    },
    'SECTIONMNEMONIC_ALERTCOVID19V3.Q_1_1': {
      invalid:  caFlag === 'Y' && doYouPlanToPrescribePaxlovidFlag && !doYouPlanToPrescribePaxlovidFlag.YES && !doYouPlanToPrescribePaxlovidFlag.NO,
      message:  'You must answer if you plan to prescribe Paxlovid for this patient',
      isInline: true
    },
    'SECTIONMNEMONIC_ALERTCOVID19V3.Q_1_2': {
      invalid:  (doYouPlanToPrescribePaxlovidFlag && doYouPlanToPrescribePaxlovidFlag.YES) && (iAttestCheckbox && !iAttestCheckbox.PAXLOVID_ATTESTED),
      message:  'You must attest that the patient meets all Paxlovid criteria',
      isInline: true
    }
  };
};
