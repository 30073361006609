import { useEffect } from 'react';
import { ATTENDEE_STATUSES } from '../../constants';
import { AutoDialerV3 } from '@teladoc/auto_dialer';

export const useNotification = ({ memberCallData }) => {
  // On member in the room notification
  useEffect(() => {
    if (memberCallData.currentStatusCode === ATTENDEE_STATUSES.JOINED) AutoDialerV3.playNotification();
  }, [memberCallData.currentStatusCode]);
};
