import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConferenceParticipantsList from './conferenceParticipantsList';
import ConferenceControls from './conferenceControls';
import ConferenceWebSocket from './conferenceWebSocket';
import ProviderInstructions from './providerInstructions';

import { fetchConference, fetchVendors, fetchCountryPhoneCodes } from '../actions';

class Conference extends Component {
  static propTypes = {
    consultationId:           PropTypes.string.isRequired,
    brandName:                PropTypes.string.isRequired,
    providerId:               PropTypes.string.isRequired,
    showCallRecordingMessage: PropTypes.string,
    fetchConference:          PropTypes.func.isRequired,
    fetchVendors:             PropTypes.func.isRequired,
    fetchCountryPhoneCodes:   PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.props.fetchConference(this.props.consultationId);
    this.props.fetchVendors(this.props.consultationId);
    this.props.fetchCountryPhoneCodes(this.props.providerId);
  };

  render() {
    return (
      <div>
        <ProviderInstructions brandName={this.props.brandName} showCallRecordingMessage={this.props.showCallRecordingMessage} />
        <ConferenceParticipantsList consultationId={this.props.consultationId} />
        <ConferenceControls consultationId={this.props.consultationId} />
        <ConferenceWebSocket />
      </div>
    );
  }
}

export default connect(
  null,
  { fetchConference, fetchVendors, fetchCountryPhoneCodes }
)(Conference);
