import { connect } from 'react-redux';

import { toggleAccordion, accordionToggleEnd } from '../../actions';
import makeGetSymptomsForClass from '../../reducers/selectors/symptoms/makeGetSymptomsForClass';
import SymptomsAccordion from './symptoms-accordion';

const makeMapStateToProps = () => {
  const getSymptomsForClass = makeGetSymptomsForClass();

  const mapStateToProps = (state, ownProps) => ({
    symptoms: getSymptomsForClass(state, ownProps),
    expanded: state.symptomAccordions.expandedAccordions.has(ownProps.symptomClass)
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  toggleAccordion,
  accordionToggleEnd
};

export default connect(makeMapStateToProps, mapDispatchToProps)(SymptomsAccordion);
