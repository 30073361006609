import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider as ApolloProviderNew } from '@apollo/client';
import getClientInstance from 'app/services/apollo-client';
import AddAddendumModal from './add-addendum-modal';
import fileIcon from '../../assets/images/vector.png';
import calendarIcon from '../../assets/images/calendar.png';
import chatIcon from '../../assets/images/chat.png';
import '../add-addendum.scss';

const AddAddendum = ({ consultation, showActions, mentalStatusExamData, psychosocialStressors, canAddFollowup}) => {
  const data = {
    communicationMethod: consultation.communication_method,
    consultationId:      consultation.consultation_id,
    memberId:            consultation.member_id,
    clinicalDiagnosis:   consultation.clinical_document.clinical_diagnosis.filter(item => {
      item.primaryDiagnosisFlg = item.primary_diagnosis_flg;
      item.diagnosisCd = item.diagnosis_cd;
      item.diagnosisNm = item.diagnosis_nm;
      return item;
    }),
    subjectNotes:   consultation.soap_notes.find(item => {
      if (item.note_type_cd === 'NOTETYPE_SUB') {
        item.clinicalNoteDt = item.clinical_note_dt;
        return item;
      }
    }),
    objectiveNotes:  consultation.soap_notes.find(item => item.note_type_cd === 'NOTETYPE_OBJ'),
    specialtyCode:   consultation.specialty_cd,
    assessmentNotes: consultation.soap_notes.find(item => item.note_type_cd === 'NOTETYPE_ASSES'),
    planNotes:       consultation.soap_notes.find(item => item.note_type_cd === 'NOTETYPE_PLANOFCARE'),
    addendumNotes:   consultation.clinical_document.clinical_notes.filter(item => {
      if (['NOTETYPE_ADDENDUM', 'NOTETYPE_MED'].includes(item.note_type_cd)) {
        item.clinicalNoteId = item.clinical_note_id;
        item.clinicalNoteDt = item.clinical_note_dt;
        item.memberNotifyFlg = item.member_notify_flg;
        return item;
      }
    })
  };
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
  const updateCallBack = (noteData, notesToAppend) => {
    // when data.addendumNotes are empty i.e when no exitsing note is present and new note added for the first time
    !data.addendumNotes.length && notesToAppend.length === 1 && document.querySelector('.addendums_title') && document.querySelector('.addendums_title').classList.remove('hidden');
    const addednumNotesWrapper = document.querySelector('.addednum_notes_wrapper');
    const noteElement = `<div class="display_flex">
      <div class="heading note_date">
        <p class="span browser_timestamp">${noteData.clinicalNote.clinicalNote.clinicalNoteDt}</p>
        <p class="patient_notified">${
          noteData.clinicalNote.clinicalNote.memberNotifyFlg === 'Y' ||
          noteData.clinicalNote.clinicalNote.memberNotifyFlg === true
            ? 'Patient notified'
            : ''
        }</p>
      </div>
      <div class="content content_margin">${noteData.clinicalNote.clinicalNote.note}</div>
    </div>`;
    return addednumNotesWrapper.insertAdjacentHTML('afterbegin', noteElement);
  };

  const handleModalCancel = () => {
    setShowConfirmationDialog(false);
  };

  const handleAddendumModal = e => {
    e.preventDefault();
    setShowConfirmationDialog(true);
  };

  const handleFollowup = () => {
    document.getElementById('schedule_followup').click();
  };

  const renderFollowupButton = () => (
    canAddFollowup ? (
      <p className="followUp" onClick={handleFollowup}>
        <img className="fileSize" alt="file" src={calendarIcon} />
        Schedule follow-up
      </p>
    ) : ''
  );

  return (
    <React.Fragment>
      <div className={showActions ? 'showActions' : 'addAddendumAction'}>
        <p
          className="buttonContainer"
          onClick={handleAddendumModal}
        ><img className="fileSize" alt="file" src={fileIcon} />Add addendum</p>
        {showActions &&
        <React.Fragment>
          {renderFollowupButton()}
          <a
            className="messagePatient"
            href={`/care_team_messages/${consultation.message_room_id}?consultation_id=${consultation.consultation_id}`}
          ><img className="chatIcon" alt="file" src={chatIcon} /> Message patient</a>
        </React.Fragment>
      }
      </div>

      {showConfirmationDialog &&
      <ApolloProviderNew client={getClientInstance()}>
        <AddAddendumModal
          isModalOpen={showConfirmationDialog}
          cancelCallback={handleModalCancel}
          consultation={data}
          updateCallBack={updateCallBack}
          showActions={showActions}
          mentalHealthStatusExam={mentalStatusExamData}
          psychosocialStressors={psychosocialStressors}
        />
      </ApolloProviderNew>
    }
    </React.Fragment>
  );
};

AddAddendum.propTypes = {
  isModalOpen:          PropTypes.bool,
  cancelCallback:       PropTypes.func,
  consultation:         PropTypes.object,
  updateCallBack:       PropTypes.func,
  showActions:          PropTypes.bool,
  mentalStatusExamData: PropTypes.object,
  psychosocialStressors:PropTypes.string,
  canAddFollowup:       PropTypes.bool
};

export default AddAddendum;
