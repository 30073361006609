import validateSchema from './provider-calendar-validations';
import moment from 'moment-timezone';
import { eventTypeCodes, workHourTypeCodes } from '../constants';
import { translate } from '@td/shared_utils';
import { getAvailableTimeBlocksWithinRange, timeRangeToTimeBlock } from '../utils';
import { pick, partition, differenceWith, isEqual } from 'lodash';

const saveShift = ({
  formState,
  formattedEvents,
  selectedEvent,
  validateProps,
  validatePropsMessages,
  setInterfaceError,
  setModalErrorMessage,
  callCreateShift,
  callUpdateShift
}) => {
  const { startTime, endTime, appointmentType } = formState;
  let shifts = [formState];

  const allEventsWithinSelectedRange = formattedEvents.filter(
    formattedEvent =>
      moment(formattedEvent.start).isBetween(startTime, endTime) ||
      moment(formattedEvent.end).isBetween(startTime, endTime)
  );

  const filteredEvents = partition(
    allEventsWithinSelectedRange,
    event => event.typeCode === eventTypeCodes.RECOMMENDED_WORKING_HOURS
  );

  const cutToBeWithinSelectedRange = ({ start, end }) => ({
    start: start < startTime ? startTime : start,
    end:   end > endTime ? endTime : end
  });

  const recommendedWorkingHoursEvents = filteredEvents[0].map(cutToBeWithinSelectedRange);

  const otherEvents = filteredEvents[1].map(fe => pick(fe, ['start', 'end']));

  if (recommendedWorkingHoursEvents.length > 0 && !selectedEvent) {
    const availableTimeBlocks = getAvailableTimeBlocksWithinRange({ startTime, endTime }, [
      ...recommendedWorkingHoursEvents,
      ...otherEvents
    ]);

    shifts = [
      ...timeRangeToTimeBlock(recommendedWorkingHoursEvents, { isRecommendedWorkingHour: true, appointmentType }),
      ...timeRangeToTimeBlock(availableTimeBlocks, { appointmentType })
    ];
  }

  return shifts.forEach(({ startDate, endDate, startTime, endTime, isRecommendedWorkingHour, appointmentType }) => {
    validateSchema({ validateProps, validatePropsMessages })
      .validate({
        startDate,
        endDate,
        startTime,
        endTime
      })
      .then(valid => {
        if (valid) {
          const input = {
            eventName:           'Availability',
            eventTypeCode:       'PROVSCHEDEVENT_ATB',
            appointmentTypeCode: appointmentType === '' ? null : appointmentType,
            startDateTime:       moment(startDate)
              .set({ hour: moment(startTime).hour(), minute: moment(startTime).minute() })
              .format(),
            endDateTime: moment(endDate)
              .set({ hour: moment(endTime).hour(), minute: moment(endTime).minute() })
              .format(),
            timezoneCode: moment.tz.guess(),
            ...(isRecommendedWorkingHour && {
              workHourTypeCd:   workHourTypeCodes.RECOMMENDED,
              eventDescription: translate(null, 'provider_calendar.events.recommended_working_hours', 'title')
            })
          };

          if (selectedEvent && selectedEvent.providerScheduleId) {
            callUpdateShift({ variables: { providerScheduleId: selectedEvent.providerScheduleId, input } });
          } else {
            callCreateShift({ variables: { input } });
          }
        }
      })
      .catch(err => {
        setInterfaceError(err.message);
        setModalErrorMessage(true);
      });
  });
};

export default saveShift;
