/* eslint-disable max-len */
import store from 'app/lib/store';

const updatePNoteSummary = pNote => {
  $('#p_note').text(pNote.val() || 'N/A');
};

const updateImagesAndDocuments = () => {
  $('#file_names').empty();
  $('.attached_file').each(_ => $('#file_names').append(`<p>${$(this).data('filename')}</p>`));
  if ($('.attached_file').length === 0) {
    $('#file_names').text('None');
  }
};

const plan = () => {
  const validations = [];
  const errors = [];
  const { providerCanRx } = store.getState().settings.providerParams;
  const isNutrition = store.getState().settings.consultationParams.consultSpecialtyName === 'Nutrition';
  const prescriptions = $('#pending_prescriptions');
  const yesRadio = $('#activate-add-prescription-modal-yes');

  if (!prescriptions[0] && providerCanRx && yesRadio && yesRadio[0] && yesRadio[0].checked) {
    errors.push('No prescriptions added. Please click no if you do not wish to prescribe.');
  }

  updateImagesAndDocuments();

  // TODO update this hardcoded condition after implementation https://teladoc.atlassian.net/browse/NUT-29
  if (isNutrition) {
    $('#p_note').text('None');
    if (
      !document.getElementById('patient-info-reviewed').checked ||
      !document.getElementById('meal-plan-reviewed').checked ||
      !document.getElementById('additional-notes-reviewed').checked ||
      !document.getElementById('shopping-guide-reviewed').checked ||
      !document.getElementById('suggested-supplements-reviewed').checked
    ) {
      validations.push({
        validator: {
          invalid: true,
          message: 'You must complete all the required sections'
        },
        validatorKey: 'nutritionReviewAllSectionsRequired'
      });
    }

    return validations;
  }
  const countryCode = document.getElementById('consult_form').getAttribute('data-country-code');
  const isUSRegion = countryCode === 'USA';
  const consultSpecialtyName = document.getElementById('consult_form').getAttribute('data-consult-specialty-name');
  const isMentalHealth = consultSpecialtyName === 'Mental Health';
  const patient_management_tools_v2 = window.FEATURE_TOGGLES.patient_management_tools_v2;
  const patient_management_tools_v2_enabled = patient_management_tools_v2 && isUSRegion && isMentalHealth;

  if (patient_management_tools_v2_enabled) {
    const continueToTreatNotSelected =
      !document.getElementById('continue_to_treat_yes').checked &&
      !document.getElementById('continue_to_treat_no').checked;
    const wontContinueToTreat = document.getElementById('continue_to_treat_no').checked;
    const reportBehaviorNotSelected =
      document.getElementById('interview_terminate_reason_cd').value === 'PLANNOTESUBTYPE_INAPPROPRIATEBEHAVIOR' &&
      !document.getElementById('report_behavior_yes').checked &&
      !document.getElementById('report_behavior_no').checked;
    if (wontContinueToTreat && reportBehaviorNotSelected) {
      validations.push({
        validator: {
          invalid: true,
          message: I18n.t('plan.validation.must_complete_required_sections')
        },
        validatorKey: 'planReviewAllSectionsRequired'
      });
      return validations;
    }
    if (continueToTreatNotSelected) {
      validations.push({
        validator: {
          invalid: true,
          message: I18n.t('plan.validation.must_complete_required_sections')
        },
        validatorKey: 'planReviewAllSectionsRequired'
      });
      return validations;
    }
  }

  const pNote = $('.soap_note.p');
  const pNoteMinLength = $(pNote).data('length');
  validations.push({
    validator: {
      invalid: pNote.val() !== undefined ? pNote.val().trim().length < pNoteMinLength : false,
      message: `SOAP Notes (P) must contain at least ${pNoteMinLength} characters`
    },
    errorContainerSelector: '#plan_note_validation_error'
  });

  if (patient_management_tools_v2_enabled) {
    const reportBehaviorYesRadio = document.getElementById('report_behavior_yes');
    const behaviorNote = $('.report-behavior-notes-textarea');
    const behaviorNoteMinLength = $(pNote).data('length') || 25;
    if (reportBehaviorYesRadio.checked) {
      validations.push({
        validator: {
          invalid: behaviorNote.val().trim().length < behaviorNoteMinLength,
          message: `Behavior report must contain at least ${behaviorNoteMinLength} characters`
        },
        errorContainerSelector: '#plan_note_validation_error'
      });
    }
  }

  if ($('#lab_prescription_yes').is(':checked') && !$('#lab_prescription_acknowledge').is(':checked')) {
    errors.push('The lab order responsibility acknowledgement must be checked in order to continue.');
  }

  const labOffersExist = $('.lab-offers-list').length;
  if (
    labOffersExist &&
    $('#lab_prescription_yes').is(':checked') &&
    !$('input[name="interview[lab_offers][]"]:checked').length
  ) {
    errors.push('Please select at least one lab offer.');
  }

  if (!validations.filter(({ validator }) => validator.invalid).length && !errors.length) {
    updatePNoteSummary(pNote);
  }

  return [...validations, ...errors];
};

export default () => Promise.resolve(plan());
