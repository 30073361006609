import {
  INIT_TRAVEL_MEDICATION_SURVEY,
  CHANGE_TRAVEL_MEDICATION_SURVEY,
  INIT_TRAVEL_MEDICATION_SURVEY_CACHE
} from '../action-types';
import { surveyToState } from '../utils';

const initialState = {
  cache: {},
  survey: {}
};

const travelMedicationSurveyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_TRAVEL_MEDICATION_SURVEY_CACHE:
      return {
        ...state,
        cache: payload
      };
    case INIT_TRAVEL_MEDICATION_SURVEY:
      return {
        ...state,
        survey: surveyToState(payload, state.cache)
      };
    case CHANGE_TRAVEL_MEDICATION_SURVEY:
      const { sectionCode, questionType, ...props } = payload;

      return {
        ...state,
        survey: {
          ...state.survey,

          [sectionCode]: {
            ...state.survey[sectionCode],

            [questionType]: {
              ...state.survey[sectionCode][questionType],
              ...props
            }
          }
        }
      };
    default:
      return state;
  }
};

export { travelMedicationSurveyReducer };
