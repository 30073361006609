import { connect } from 'react-redux';
import GuidedMenu from 'app/components/guided-menu';

import { changeConsultNavigationStep } from '../actions';

const mapStateToProps = state => {
  const {
    consultNavigation: { selectedIndex, totalSteps, items },
    settings: {
      consultationParams: { consultationId }
    }
  } = state;

  return {
    selectedIndex,
    totalSteps,
    items,
    consultationId
  };
};

const mapDispatchToProps = {
  changeStep: changeConsultNavigationStep
};

export default connect(mapStateToProps, mapDispatchToProps)(GuidedMenu);
