import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { translate } from '@td/shared_utils';

// Components and constants
import TeladocModal from '../../TeladocModal';

// Styles
import '../styles/shared-working-hours-delete-modal.scss';

const closeIcon = require('../../assets/images/event_details/close.svg');

const TRANSLATION_SCOPE = 'my_schedule.modals.special_working_hours_delete';

const SpecialWorkingHoursDeleteModal = ({ event, onModalClose, onDelete }) => {
  const modalActionButtons = () => (
    <div className="actionButtons">
      <div className="cancelAction">
        <button id="cancelButton" key="cancelButton" className="button" onClick={onModalClose}>
          {translate(null, TRANSLATION_SCOPE, 'actions.cancel')}
        </button>
      </div>
      <div className="deleteAction">
        <button id="deleteButton" key="deleteButton" className="button" onClick={onDelete}>
          {translate(null, TRANSLATION_SCOPE, 'actions.delete')}
        </button>
      </div>
    </div>
  );

  return (
    <TeladocModal
      className="workingHoursDeleteModalWrapper"
      title={translate(null, TRANSLATION_SCOPE, 'title')}
      isOpen
      onClose={onModalClose}
      closeIcon={closeIcon}
    >
      <div className="contentWrapper">
        <div className="subtitle">
          <p>{translate(null, TRANSLATION_SCOPE, 'subtitle')}</p>
        </div>
        <div className="contentWrapper">
          <div className="dateAntTimeInfoWrapper">
            <div className="dayName">{moment(event.startDateTime).format('ddd, MMM D, Y')}</div>
            <div className="dateAndTimeList">
              <div className="dateAndTimeItemInfo">
                {moment(event.startDateTime)
                  .format('h:mm A')
                  .replace(':00', '')}
                <span className="separator" />
                {moment(event.endDateTime)
                  .format('h:mm A')
                  .replace(':00', '')}
              </div>
            </div>
          </div>
        </div>
        {modalActionButtons()}
      </div>
    </TeladocModal>
  );
};

SpecialWorkingHoursDeleteModal.propTypes = {
  event: PropTypes.shape({
    startDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string]),
    endDateTime:   PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string])
  }).isRequired,
  onModalClose: PropTypes.func.isRequired,
  onDelete:     PropTypes.func.isRequired
};

export default SpecialWorkingHoursDeleteModal;
