import React from 'react';
import lockIcon from './../../assets/images/lock-icon.svg';
import moment from 'moment';

const CustomCalendarEvent = ({ event }) => {
  if (event.typeCode === 'PROVSCHEDEVENT_AWM') {
    return (
      <div className="provider-calendar-locked-consult">
        <div className="provider-calendar-locked-consult__time">
          <img src={lockIcon} style={{ marginRight: 5 }} />
          {moment(event.start).format('h:mma')} - {moment(event.end).format('h:mma')}
        </div>
        {/* <div className="provider-calendar-locked-consult__ID">{event.providerScheduleId}</div> */}
      </div>
    );
  } else {
    return (
      <div className="provider-calendar-event">
        <div className="provider-calendar-event__time">
          {moment(event.start).format('h:mma')} - {moment(event.end).format('h:mma')}
        </div>
        <div className="provider-calendar-event__title">{event.title}</div>
        {event.desc && `:  ${event.desc}`}
      </div>
    );
  }
};

export default CustomCalendarEvent;
