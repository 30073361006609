import {
  FETCH_MEDICATION_LIST_START,
  FETCH_MEDICATION_LIST_SUCCESS,
  FETCH_MEDICATION_LIST_FAIL,
  SET_SELECTED_MEDICATION,
  RESET_MEDICATION_LIST
} from '../actionTypes';

const DEFAULT_STATE = {
  fetched:     false,
  isLoading:   false,
  message:     '',
  value:       '',
  suggestions: [],
  selectedMed: {}
};

const suggestionsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_MEDICATION_LIST_START: {
      return {
        ...state,
        fetched:   false,
        isLoading: true
      };
    }
    case FETCH_MEDICATION_LIST_SUCCESS: {
      const { drugs: suggestions } = action.payload.data || {};

      return {
        ...state,
        suggestions,
        fetched:   true,
        isLoading: false
      };
    }
    case FETCH_MEDICATION_LIST_FAIL: {
      return {
        ...state,
        isLoading: false,
        fetched:   false
      };
    }
    case SET_SELECTED_MEDICATION: {
      return {
        ...state,
        isLoading:   false,
        fetched:     true,
        selectedMed: action.medication
      };
    }
    case RESET_MEDICATION_LIST: {
      return {
        ...state,
        isLoading:   false,
        fetched:     false,
        suggestions: []
      };
    }
    default: {
      return state;
    }
  }
};

export { suggestionsReducer };
