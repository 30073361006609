import React from 'react';
import PropTypes from 'prop-types';
import { BannerPropType } from './prop-types';
import { map } from 'lodash';
import { BannersContainer, Banner } from './components';

const Banners = React.memo(({ banners }) => (
  <BannersContainer>
    {map(banners, (banner) => (
      <Banner
        key={banner.id}
        {...banner}
      />
    ))}
  </BannersContainer>
));

Banners.displayName = 'Banners';
Banners.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape(BannerPropType)
  )
};

export default Banners;
