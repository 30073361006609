import { Socket } from 'phoenix';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WebSocket extends Component {
  static propTypes = {
    socket: PropTypes.shape({
      endpoint: PropTypes.string.isRequired,
      params:   PropTypes.object
    }).isRequired,
    channel: PropTypes.shape({
      topic:        PropTypes.string.isRequired,
      events:       PropTypes.arrayOf(PropTypes.string).isRequired,
      pushCallback: PropTypes.func.isRequired,
      params:       PropTypes.object
    }).isRequired
  }

  constructor(props) {
    super(props);
    const { socket, channel } = this.props;

    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const stateSocket = new Socket(
      `${protocol}//${socket.endpoint}`,
      { params: socket.params }
    );

    const stateChannel = stateSocket.channel(channel.topic, channel.params);

    this.state = { socket: stateSocket, channel: stateChannel };
  }

  componentDidMount = () => {
    const { socket, channel } = this.state;

    socket.connect();
    channel.join();

    for (const event of this.props.channel.events) {
      channel.on(event, this.props.channel.pushCallback);
    }
  }

  render = () =>
    <div />
}

export default WebSocket;
