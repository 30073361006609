import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { translate, translateMarkupString } from '@td/shared_utils';
import cx from 'classnames';

import Modal from '../../components/Modal';
import Loader from '../../components/Loader';

import styles from '../styles.module.scss';

import deleteIcon from '../../assets/images/delete-icon.svg';
import { eventTypeCodes } from '../constants';

const TRANSLATION_SCOPE = 'provider_calendar.modal';

const HARDCODED_STRINGS = {
  titleText:     'Add or edit availability block',
  modalBodyText:
    'Availability blocks are times when patients can schedule consults.' +
    'You can choose whether the blocks are available for new patients, ' +
    'established patients or all patients.',
  availableFor:            'Available for',
  allPatients:             'All patients',
  newPatients:             'New patients',
  existingPatients:        'Existing patients',
  deleteAvailabilityBlock: 'Delete availability block',
  PROVSCHEDEVENT_ATB:      'PROVSCHEDEVENT_ATB',
  APPOINTMENTTYPE_INITIAL: 'APPOINTMENTTYPE_INITIAL',
  APPOINTMENTTYPE_ONGOING: 'APPOINTMENTTYPE_ONGOING'
};

const AppointmentBlockModal = ({
  formState,
  setFormState,
  selectedEvent,
  validateProps,
  validatePropsMessages,
  interfaceError,
  modalErrorMessage,
  callDeleteShift,
  closeModal,
  modalOpen,
  savingError,
  createShiftLoading,
  updateShiftLoading,
  deleteShiftLoading,
  handleSaveClick,
  allowSave,
  dedicatedAppointments
}) => {
  const onInputChange = event => {
    const { name, value } = event.target;
    setFormState(prevFormState => ({ ...prevFormState, [name]: value }));
  };

  const datePickerHandleChange = (value, key) => {
    setFormState(prevFormState => ({ ...prevFormState, [key]: value }));
  };

  const datePickerFormat = date => {
    if (date instanceof Date) {
      return date;
    } else if (date !== null) {
      return moment(date.replace(/\//g, '-')).toDate();
    } else {
      return null;
    }
  };

  const deleteShift = (event, cbDeleteShift) => {
    cbDeleteShift({ variables: { providerScheduleId: event.providerScheduleId } });
  };

  const showAppointmentType =
    dedicatedAppointments && (!selectedEvent || selectedEvent.typeCode === HARDCODED_STRINGS.PROVSCHEDEVENT_ATB);

  const modalTitle = useMemo(() => {
    if (selectedEvent && selectedEvent.typeCode === eventTypeCodes.RECOMMENDED_WORKING_HOURS) {
      return translate(null, 'provider_calendar.events.recommended_working_hours', 'title');
    }

    return HARDCODED_STRINGS.titleText;
  }, [selectedEvent]);

  const modalDescription = useMemo(() => {
    if (selectedEvent && selectedEvent.typeCode === eventTypeCodes.RECOMMENDED_WORKING_HOURS) {
      return translateMarkupString('provider_calendar.events.recommended_working_hours', 'description', { p: <p /> });
    }

    if (showAppointmentType) {
      return <p>{HARDCODED_STRINGS.modalBodyText}</p>;
    } else {
      return (
        <React.Fragment>
          <p>{translate(null, TRANSLATION_SCOPE, 'description.availability')}</p>
          <p>{translate(null, TRANSLATION_SCOPE, 'description.member')}</p>
        </React.Fragment>
      );
    }
  }, [selectedEvent, showAppointmentType]);

  return (
    <Modal
      onClose={closeModal}
      showCloseButton
      show={modalOpen}
      titleText={modalTitle}
      className={cx('provider-calendar-event-modal', styles.calendarModal)}
      centered
    >
      {(createShiftLoading || updateShiftLoading || deleteShiftLoading) && <Loader />}

      <div className={styles.modalBody}>
        {modalErrorMessage && savingError && <div className="error provider-calendar-error">{savingError}</div>}
        {modalErrorMessage && interfaceError && <div className="error provider-calendar-error">{interfaceError}</div>}
        <div className={styles.modalBodyRow}>
          <span className={styles.modalBodyText}>
            {modalDescription}

            <p>{validatePropsMessages.maxShiftLength}</p>
          </span>
        </div>
        <div className={styles.modalBodyRow}>
          <div className={styles.modalFormGroup}>
            <label className={styles.modalLabel} htmlFor={'startDate'}>
              {translate(null, TRANSLATION_SCOPE, 'start_date')}
            </label>
            <input
              className={styles.modalInput}
              type="date"
              name="startDate"
              id="startDate"
              value={formState.startDate}
              min={moment().format('YYYY-MM-DD')}
              onChange={onInputChange}
            />
          </div>
          <div className={styles.modalFormGroup}>
            <label className={styles.modalLabel} htmlFor={'startTime'}>
              {translate(null, TRANSLATION_SCOPE, 'start_time')}
            </label>
            <DatePicker
              id="startTime"
              name="startTime"
              valueName="startTime"
              selected={datePickerFormat(formState.startTime)}
              onChange={date => datePickerHandleChange(date, 'startTime')}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={validateProps.shiftTimeStep}
              timeCaption={translate(null, TRANSLATION_SCOPE, 'start_time')}
              dateFormat="h:mm aa"
              className={styles.modalInput}
            />
          </div>
        </div>
        <div className={styles.modalBodyRow}>
          <div className={styles.modalFormGroup}>
            <label className={styles.modalLabel} htmlFor={'endDate'}>
              {translate(null, TRANSLATION_SCOPE, 'end_date')}
            </label>
            <input
              className={styles.modalInput}
              type="date"
              name="endDate"
              id="endDate"
              min={moment().format('YYYY-MM-DD')}
              value={formState.endDate}
              onChange={onInputChange}
            />
          </div>
          <div className={styles.modalFormGroup}>
            <label className={styles.modalLabel} htmlFor={'endTime'}>
              {translate(null, TRANSLATION_SCOPE, 'end_time')}
            </label>
            <DatePicker
              id="endTime"
              name="endTime"
              valueName="endTime"
              selected={datePickerFormat(formState.endTime)}
              onChange={date => datePickerHandleChange(date, 'endTime')}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={validateProps.shiftTimeStep}
              timeCaption={translate(null, TRANSLATION_SCOPE, 'end_time')}
              dateFormat="h:mm aa"
              className={styles.modalInput}
            />
          </div>
        </div>
        {showAppointmentType && (
          <div className={styles.modalBodyRow}>
            <div>
              <p className={styles.modalLabel}>{HARDCODED_STRINGS.availableFor}</p>
              <div className={styles.availableFor}>
                <div className={styles.availableForOption}>
                  <input
                    type="radio"
                    name="appointmentType"
                    id="allPatients"
                    value=""
                    checked={!!formState.appointmentType === false}
                    onChange={onInputChange}
                  />
                  <label className={styles.modalLabel} htmlFor={'allPatients'}>
                    {HARDCODED_STRINGS.allPatients}
                  </label>
                </div>
                <div className={styles.availableForOption}>
                  <input
                    type="radio"
                    name="appointmentType"
                    id="newPatients"
                    value={HARDCODED_STRINGS.APPOINTMENTTYPE_INITIAL}
                    checked={formState.appointmentType === HARDCODED_STRINGS.APPOINTMENTTYPE_INITIAL}
                    onChange={onInputChange}
                  />
                  <label className={styles.modalLabel} htmlFor={'newPatients'}>
                    {HARDCODED_STRINGS.newPatients}
                  </label>
                </div>
                <div className={styles.availableForOption}>
                  <input
                    type="radio"
                    name="appointmentType"
                    id="existingPatients"
                    value={HARDCODED_STRINGS.APPOINTMENTTYPE_ONGOING}
                    checked={formState.appointmentType === HARDCODED_STRINGS.APPOINTMENTTYPE_ONGOING}
                    onChange={onInputChange}
                  />
                  <label className={styles.modalLabel} htmlFor={'existingPatients'}>
                    {HARDCODED_STRINGS.existingPatients}
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.modalFooter}>
        <a className="button secondary cancel javascript_link" style={{ marginRight: 9 }} onClick={closeModal}>
          {translate(null, TRANSLATION_SCOPE, 'cancel')}
        </a>
        <a
          className={cx('button primary provider-calendar-appointment-save')}
          onClick={handleSaveClick}
          disabled={!allowSave}
        >
          {translate(null, TRANSLATION_SCOPE, 'save')}
        </a>
        {selectedEvent && selectedEvent.typeCode !== eventTypeCodes.RECOMMENDED_WORKING_HOURS && (
          <a
            className={cx('provider-calendar-appointment-delete', styles.delete)}
            onClick={() => deleteShift(selectedEvent, callDeleteShift)}
          >
            <img
              src={deleteIcon}
              alt={
                showAppointmentType
                  ? HARDCODED_STRINGS.deleteAvailabilityBlock
                  : translate(null, TRANSLATION_SCOPE, 'delete')
              }
            />{' '}
            <span>
              {showAppointmentType
                ? HARDCODED_STRINGS.deleteAvailabilityBlock
                : translate(null, TRANSLATION_SCOPE, 'delete')}
            </span>
          </a>
        )}
      </div>
    </Modal>
  );
};

AppointmentBlockModal.propTypes = {
  formState: PropTypes.shape({
    startDate:       PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    startTime:       PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    endDate:         PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    endTime:         PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    appointmentType: PropTypes.string
  }),
  setFormState:          PropTypes.func.isRequired,
  selectedEvent:         PropTypes.object,
  validateProps:         PropTypes.object.isRequired,
  validatePropsMessages: PropTypes.object.isRequired,
  interfaceError:        PropTypes.string,
  modalErrorMessage:     PropTypes.bool,
  callDeleteShift:       PropTypes.func.isRequired,
  closeModal:            PropTypes.func.isRequired,
  modalOpen:             PropTypes.bool,
  savingError:           PropTypes.string,
  createShiftLoading:    PropTypes.bool,
  updateShiftLoading:    PropTypes.bool,
  deleteShiftLoading:    PropTypes.bool,
  handleSaveClick:       PropTypes.func.isRequired,
  allowSave:             PropTypes.bool,
  dedicatedAppointments: PropTypes.bool
};

export default AppointmentBlockModal;
