import React from 'react';
import PropTypes from 'prop-types';

import SymptomsAccordion from './symptoms-accordion';
import ISymptom from '../../interfaces/symptom-interface';

const AddedSymptomsAccordion = ({ symptoms, toggleAccordion, expanded, accordionToggleEnd }) =>
  (
    <div style={{ display: symptoms.length === 0 ? 'none' : 'block' }}>
      <SymptomsAccordion
        symptomClass={'Added symptoms'}
        symptoms={symptoms}
        expanded={expanded}
        toggleAccordion={toggleAccordion}
        accordionToggleEnd={accordionToggleEnd}
      />
    </div>
  );

AddedSymptomsAccordion.propTypes = {
  symptoms:           PropTypes.arrayOf(ISymptom).isRequired,
  expanded:           PropTypes.bool.isRequired,
  toggleAccordion:    PropTypes.func.isRequired,
  accordionToggleEnd: PropTypes.func.isRequired
};

export default AddedSymptomsAccordion;
