import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import ACTIVE_CONSULTATION_QUERY from '../prescription/queries/active-consultation-query.graphql';

class ProviderOrderContainer extends Component {
  render() {
    const { consultationId } = this.props;
    return (
      <Query query={ACTIVE_CONSULTATION_QUERY} variables={{ consultationId }}>
        {
        ({ data: { currentUserProvider: { consultation: { providerOrders = [] } = {} } = {} } }) => {
          return (
            <div id="pending_prescriptions">
              <table>
                <thead>
                  <tr>
                    <th>Drug</th>
                    <th>Quantity</th>
                    <th>Instructions</th>
                    <th>Requestor</th>
                    <th>Refills</th>
                    <th>status</th>
                  </tr>
                </thead>
                <tbody>
                  { providerOrders.map(order =>
                    <tr key={order.id}>
                      <td>{ order.medicationRecord.drugName }</td>
                      <td>{ order.medicationRecord.quantity }</td>
                      <td>{ order.instructions }</td>
                      <td>
                        { order.requestor.name }
                        <br />
                        ({ order.requestor.type })
                      </td>
                      <td>{ order.refills }</td>
                      <td>
                        { order.statuses.map((status, index) =>
                          <p key={index}>{ status.name }</p>)
                        }
                      </td>
                    </tr>)
                  }
                </tbody>
              </table>
            </div>
          );
        }
      }
      </Query>
    );
  }
};


ProviderOrderContainer.propTypes = {
  consultationId: PropTypes.number.isRequired,
  providerOrders: PropTypes.arrayOf(PropTypes.object)
};

export default ProviderOrderContainer;
