import { get, set } from 'lodash';
import { connect } from 'react-redux';
import MentalStatusExamSummary from './mental-status-exam-summary';
import {
  MENTAL_STATUS_EXAM_CD,
  SAFETY_PLAN_QUESTION_CD,
  SAFETY_PLAN_SECTION_CD
} from '../../components/objective-tab/mental-status-exam/constants';

const mapStateToProps = state => {
  const surveyState = get(state, ['surveys', MENTAL_STATUS_EXAM_CD], null);
  set(surveyState, [SAFETY_PLAN_SECTION_CD, SAFETY_PLAN_QUESTION_CD], { TEXT: true });
  return {
    surveyState,
    customTextAnswers: get(state, 'mentalStatusExam', null)
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MentalStatusExamSummary);
