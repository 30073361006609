import React, { useEffect } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { isViewedAllMentalHealthDocumentsSelector } from '../../../redux/selectors';

import { tryToggleContinueButton } from '../../../ehr-utils';

const EHRButtonContinue = ({ consultationId, disableButton, isViewedAllMentalHealthDocuments }) => {
  useEffect(() => {
    tryToggleContinueButton();
  }, [isViewedAllMentalHealthDocuments]);

  return (
    <a
      id="ehr-button-continue"
      className={cn('button continue start_consult', { continue_btn: disableButton })}
      data-consult_id={consultationId}
      href="#"
    >
      Continue
    </a>
  );
};

const mapStateToProps = state => ({
  isViewedAllMentalHealthDocuments: isViewedAllMentalHealthDocumentsSelector(state)
});

export default connect(mapStateToProps)(EHRButtonContinue);
