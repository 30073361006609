import { businessOpenTimeHour, businessCloseTimeHour} from './business_hours';

const SCHEDULE_DELAY_OFFSET = 30 * 60 * 1000;

const roundToNearestCeilHalfHour = (datetime) => {
  datetime.setHours(datetime.getHours() + (datetime.getMinutes() > 30 ? 1 : 0));
  datetime.setMinutes((Math.ceil(datetime.getMinutes() / 30) * 30) % 60 - 1);

  return datetime;
}

export default (availableTimes) => {
  let minDatetime = new Date(Date.now() + SCHEDULE_DELAY_OFFSET);

  let openTime = businessOpenTimeHour(availableTimes, minDatetime);

  let closeTime = businessCloseTimeHour(availableTimes, minDatetime);

  minDatetime = roundToNearestCeilHalfHour(minDatetime);

  if (minDatetime.getHours() < openTime) {
    minDatetime.setHours(openTime);
    minDatetime.setMinutes(0);
  } 
  else if (minDatetime.getHours() > closeTime) {
    minDatetime.setDate(minDatetime.getDate()+1);
    minDatetime.setHours(openTime);
    minDatetime.setMinutes(0);
  }

  return minDatetime;
}
