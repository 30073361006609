import React from 'react';
import { translate, translateMarkupString } from '@td/shared_utils';

const InnerContent = () => {
  return (
    <div className="feature-overview-container">
      <div className="instructions">
        <p className="feature-intro">{translate(null, 'dashboard.same_day_visit_alert_modal', 'description')}</p>
        <p className="example-content">{translate(null, 'dashboard.same_day_visit_alert_modal', 'example')}</p>
        <p className="example-content">
          {translateMarkupString('dashboard.same_day_visit_alert_modal', 'guidance', { b: <b /> })}
        </p>
      </div>
    </div>
  );
};

export default InnerContent;
