import React from 'react';
import PropTypes from 'prop-types';

import CharValidator from '../char-validator';

const MealPlanControl = ({ control, value, mealHandler, questionIdentifier, controlNamePrefix }) => {
  const controlName = control.question.toLowerCase();
  return (
    <div className={`control-wrapper ${controlName}`}>
      <div className="meal-plan-control-label">
        <label className={`${controlName}-label`} htmlFor={`${controlNamePrefix}[${questionIdentifier}]`}>
          <span className="required-control">*</span>
          {control.question}
        </label>
      </div>
      <div className="meal-plan-control-field">
        <textarea
          maxLength="330"
          name={`${controlNamePrefix}[${questionIdentifier}][response]`}
          className={`${controlName}-text-field height-100 initial-margin`}
          onChange={event => mealHandler(control.question.toLowerCase(), event.target.value)}
          defaultValue={value}
        />
      </div>
      <CharValidator fieldLength={value.trim().length} minLength={5} />
    </div>
  );
};

MealPlanControl.propTypes = {
  control:            PropTypes.object.isRequired,
  value:              PropTypes.string.isRequired,
  mealHandler:        PropTypes.func.isRequired,
  questionIdentifier: PropTypes.string.isRequired,
  controlNamePrefix:  PropTypes.string.isRequired
};

export default MealPlanControl;
