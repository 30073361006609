import moment from 'moment';
import { translate } from '@td/shared_utils';
import {
  AppleIcon,
  GoogleIcon,
  MicrosoftIcon
} from './icons';

const TRANSLATION_SCOPE = 'my_schedule';

export const DAY_NAMES = Array.isArray(translate(null, 'date', 'day_names'))
  ? translate(null, 'date', 'day_names')
  : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MyScheduleViews = {
  DEFAULT: {
    id:    'default',
    name:  translate(null, TRANSLATION_SCOPE, 'views.default'),
    index: 0
  },
  SETTINGS: {
    id:    'settings',
    name:  translate(null, TRANSLATION_SCOPE, 'views.settings'),
    index: 1
  }
};

export const MyScheduleModals = {
  REGULAR_WORKING_HOURS: {
    id:    'regular',
    index: 0
  },
  SPECIAL_WORKING_HOURS: {
    id:    'special',
    index: 1
  },
  PERSONAL_EVENTS: {
    id:    'personal_events',
    index: 3
  },
  CUSTOM_BUFFER: {
    id:    'custom buffer',
    index: 4
  }
};

export const regularWorkingHours = [
  {
    eventName:     translate(null, TRANSLATION_SCOPE, 'event_section_labels.availability'),
    eventTypeCode: MyScheduleModals.REGULAR_WORKING_HOURS,
    updatedAt:     moment('2022-04-08')
  }
];

export const scheduleTypeMethods = {
  video: {
    method: 'video',
    label:  translate(null, TRANSLATION_SCOPE, 'event_section_labels.video_visit'),
    icon:   'video_type'
  },
  phone: {
    method: 'phone',
    label:  translate(null, TRANSLATION_SCOPE, 'event_section_labels.phone_visit'),
    icon:   'audio_type'
  },
  canceled: {
    status: 'CONSULTSTATUS_CAN',
    label:  translate(null, TRANSLATION_SCOPE, 'event_section_labels.canceled_visit'),
    short:  translate(null, TRANSLATION_SCOPE, 'event_section_labels.canceled_visit_short'),
    icon:   'canceled_type'
  },
  completed: {
    status: 'CONSULTSTATUS_COM',
    label:  translate(null, TRANSLATION_SCOPE, 'event_section_labels.completed_visit'),
    short:  translate(null, TRANSLATION_SCOPE, 'event_section_labels.completed_visit_short'),
    icon:   'completed_type'
  }
};

export const scheduleTypes = {
  PROVSCHEDEVENT_AWM: {
    eventTypeCode: 'PROVSCHEDEVENT_AWM',
    label:         translate(null, TRANSLATION_SCOPE, 'event_section_labels.patient_visit'),
    background:    '#005C95',
    border:        '#FFFFFF',
    legendColor:   '#005C95',
    color:         '#FFFFFF'
  },
  PROVSCHEDEVENT_PERSONALEVENT: {
    eventTypeCode: 'PROVSCHEDEVENT_PERSONALEVENT',
    label:         translate(null, TRANSLATION_SCOPE, 'event_section_labels.personal_event'),
    background:    '#F0E6F4',
    border:        '#5B2F91',
    legendColor:   '#732CCA',
    color:         '#000000'
  },
  PROVSCHEDEVENT_AWM_CAN: {
    eventTypeCode: 'PROVSCHEDEVENT_AWM_CAN',
    label:         translate(null, TRANSLATION_SCOPE, 'event_section_labels.canceled_visit'),
    background:    '#CFE5F2',
    border:        '#82AECA',
    legendColor:   '#CFE5F2',
    color:         '#005C95'
  },
  PROVSCHEDEVENT_RH: {
    eventTypeCode: 'PROVSCHEDEVENT_RH',
    label:         translate(null, TRANSLATION_SCOPE, 'event_section_labels.regular_working_hours'),
    background:    'initial'
  },
  PROVSCHEDEVENT_ATB: {
    eventTypeCode: 'PROVSCHEDEVENT_ATB',
    label:         translate(null, TRANSLATION_SCOPE, 'event_section_labels.special_working_hours'),
    background:    'initial'
  },
  PROVSCHEDEVENT_PERSONALEVENT_EXTERNAL: {
    eventTypeCode: 'PROVSCHEDEVENT_PERSONALEVENT_EXTERNAL',
    label:         translate(null, TRANSLATION_SCOPE, 'event_section_labels.external_event'),
    background:    '#E9E6E0',
    border:        '#B4B4AF',
    legendColor:   '#E9E6E0',
    color:         '#000000',
  },
};

export const externalAccountProviderIcons = {
  google:    GoogleIcon,
  microsoft: MicrosoftIcon,
  icloud:    AppleIcon
};

export const externalAccountTypes = {
  google: {
    link:                  'google',
    icon:                  externalAccountProviderIcons.google,
    title:                 translate(null, TRANSLATION_SCOPE, 'modals.connect_external_calendar.account_providers.google')
  },
  microsoft: {
    link:                  'microsoft',
    icon:                  externalAccountProviderIcons.microsoft,
    title:                 translate(null, TRANSLATION_SCOPE, 'modals.connect_external_calendar.account_providers.microsoft')
  },
  icloud: {
    link:                  'icloud',
    icon:                  externalAccountProviderIcons.icloud,
    title:                 translate(null, TRANSLATION_SCOPE, 'modals.connect_external_calendar.account_providers.apple')
  }
};

export const externalProviderTypes = {
  google: 'google',
  microsoft: 'microsoft',
  icloud: 'icloud',
};

export const modalTypes = {
  none: 'none',
  connection: 'connection',
  explanation: 'explanation',
};

export const DEFAULT_ACCOUNTS_LIMIT = 3;

export const VIEW_URI_HASH_INDICATOR = {
  regularWorkingHours: '#active=regular_working_hours',
  externalCalendars: '#active=external_calendars'
};

export const ICLOUD_INSTRUCTIONS_URL = 'https://support.apple.com/en-us/102654';
