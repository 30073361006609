import React from 'react';
import DataTable from '../../../data-table';
import {
  consultationTypeSelector,
  fullNameSelectorForRequiredActions,
  recievedDateSelector,
  requiredActionsTableActionsSelector
} from '../selectors';

const COLUMNS = [
  {
    name: I18n.t('dashboard.consult_queues.required_actions_table.received_date'),
    selector: recievedDateSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.required_actions_table.type'),
    selector: consultationTypeSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.required_actions_table.member'),
    selector: fullNameSelectorForRequiredActions
  },
  {
    name: I18n.t('dashboard.consult_queues.required_actions_table.actions.title'),
    selector: requiredActionsTableActionsSelector
  }
];

const RequiredActionsTable = ({ data }) => <DataTable columns={COLUMNS} data={data} />;

export default RequiredActionsTable;
