import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from '@td/shared_utils';
import { changeTravelMedicationSurvey } from '../../actions';
import { TRANSLATION_SCOPE } from '../../constants';
import styles from './styles.module.scss';

const Note = React.memo(({ note, sectionCode, questionMnemonicCd, onChange, disabled }) => {
  const handleChange = useCallback(e => {
    onChange({
      sectionCode,
      questionType: questionMnemonicCd,
      note:         e.target.value
    });
  }, [onChange, sectionCode, questionMnemonicCd]);

  return (
    <div className={styles.surveyAnswerNote}>
      <input
        type="text"
        placeholder={translate(null, `${TRANSLATION_SCOPE}.sections.${sectionCode}.fields.note`, 'placeholder')}
        onInput={handleChange}
        value={note}
        disabled={disabled}
      />
    </div>
  );
});

const mapDispatchToProps = dispatch => ({
  onChange: payload => dispatch(changeTravelMedicationSurvey(payload))
});

Note.displayName = 'Note';

Note.propTypes = {
  note:               PropTypes.string.isRequired,
  sectionCode:        PropTypes.string.isRequired,
  questionMnemonicCd: PropTypes.string.isRequired,
  onChange:           PropTypes.func.isRequired,
  disabled:           PropTypes.bool.isRequired
};

export default connect(null, mapDispatchToProps)(Note);
