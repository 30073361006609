import { createRequestActionTypes } from '@td/utils';

export const FETCH_SYMPTOMS = createRequestActionTypes('subjective', 'FETCH_SYMPTOMS');
export const FETCH_SYMPTOM_CLASSES = createRequestActionTypes(
  'subjective',
  'FETCH_SYMPTOM_CLASSES'
);

export const SYMPTOMS_FORM_RADIO_CHANGE = 'subjective/SYMPTOMS_FORM_RADIO_CHANGE';
export const SYMPTOMS_FORM_NOTE_CHANGE = 'subjective/SYMPTOMS_FORM_NOTE_CHANGE';
export const SYMPTOMS_FORM_INITIALIZE = 'subjective/SYMPTOMS_FORM_INITIALIZE';

export const TOGGLE_ACCORDION = 'subjective/TOGGLE_ACCORDION';
export const ACCORDION_TOGGLE_END = 'subjective/ACCORDION_TOGGLE_END';

export const SELECTED_TAB_CHANGE = 'subjective/SELECTED_TAB_CHANGE';

export const SYMPTOM_SELECTED = 'subjective/SYMPTOM_SELECTED';
export const SYMPTOM_UNSELECTED = 'subjective/SYMPTOM_UNSELECTED';
export const SYMPTOM_UNSELECTED_ON_VALUE_CHANGE = 'subjective/SYMPTOM_UNSELECTED_ON_VALUE_CHANGE';

export const ADD_SYMPTOM_INPUT_CHANGE = 'subjective/ADD_SYMPTOM_INPUT_CHANGE';
export const ADD_SYMPTOM_SEARCH_REQUEST = 'subjective/ADD_SYMPTOM_SEARCH_REQUEST';
export const ADD_SYMPTOM_SEARCH_SUCCESS = 'subjective/ADD_SYMPTOM_SEARCH_SUCCESS';
export const ADDED_SYMPTOMS_INITIALIZE = 'subjective/ADDED_SYMPTOMS_INITIALIZE';

export const FOCUS_SYMPTOM = 'subjective/FOCUS_SYMPTOM';
export const FOCUS_SYMPTOM_SET = 'subjective/FOCUS_SYMPTOM_SET';
export const FOCUS_SYMPTOM_CLEAR = 'subjective/FOCUS_SYMPTOM_CLEAR';
