import validate from 'validate.js';

const rules = {
  weight: {
    presence: {
      allowEmpty: false,
      message: '^You must provide the current weight'
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      notValid: '^Weight must be a valid number',
      notInteger: '^Weight must be a round number',
      notGreaterThan: '^Weight must be greater than zero'
    }
  }
};

export function validateWeightForm(attributes) {
  return validate.async(attributes, rules);
}
