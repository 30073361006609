import { createSelector } from '@td/utils';

import getChiefComplaintClassSymptomIdsSet from './getChiefComplaintClassSymptomIdsSet';

const getSymptomListId = (_, props) => props.listId;
const getSymptomCode = (_, props) => props.symptom.code;

const makeGetCanRemoveSymptom = () =>
  createSelector(
    getChiefComplaintClassSymptomIdsSet,
    getSymptomListId,
    getSymptomCode,
    (
      chiefComplaintClassSymptomIdsSet,
      symptomListId,
      symptomCode
    ) =>
      (symptomListId === 'Associated' && !chiefComplaintClassSymptomIdsSet.has(symptomCode)) ||
      symptomListId === 'Added symptoms'
  );

export default makeGetCanRemoveSymptom;
