import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

export const Notification = ({ errorMessage, successMessage, styles }) => (
  <div
    className={cx(styles.notificationContainer,
        errorMessage && styles.error, successMessage && styles.success)}
    data-testid="notification"
  >
    <p>{errorMessage || successMessage}</p>
  </div>
  );

Notification.propTypes = {
  errorMessage:   PropTypes.string,
  successMessage: PropTypes.string,
  styles:         PropTypes.object.isRequired
};
