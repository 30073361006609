import { 
  CA_FORM_MAIN_INQUIRY_CHANGE, 
  UPDATE_CLINICAL_ALERTS_ACTIVATED_FLAG 
} from '../action-types';

export const caFormMainInquiryChange = (radioValue) => ({
  type: CA_FORM_MAIN_INQUIRY_CHANGE,
  payload: radioValue
});

export const updateClinicalAlertsActivatedFlag = (clinicalAlertsActivatedFlag) => ({
  type: UPDATE_CLINICAL_ALERTS_ACTIVATED_FLAG,
  payload: {
    clinicalAlertsActivatedFlag
  }
});
