import React from 'react';
import PropTypes from 'prop-types';

export const SubmitButtons = ({ styles, onCancel, label }) => (
  <div className={styles.actionButtons}>
    <div className={styles.regularActions}>
      <button className="button" onClick={onCancel} type="button" data-testid="cancel-button">
        {label.cancel}
      </button>
      <button
        className="button"
        type="submit"
        data-testid="submit-button"
      >
        {label.save}
      </button>
    </div>
  </div>
  );

SubmitButtons.propTypes = {
  styles:   PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  label:    PropTypes.shape({
    cancel: PropTypes.string.isRequired,
    save:   PropTypes.string.isRequired
  })
};
