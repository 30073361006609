import React from 'react';
import cx from 'classnames';
import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const ModalAction = ({ variant, className, children, ...props }) => (
  <button
    {...props}
    className={cx('button', styles.modalAction, styles[`modalAction__${variant}`], className)}
    data-testid={`modal-action-${variant}`}
  >
    {children}
  </button>
);

ModalAction.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']).isRequired
};

export default ModalAction;
