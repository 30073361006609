import React from 'react';
import PropTypes from 'prop-types';
import { map, get, keys } from 'lodash';
import styles from './styles';
import { SAFETY_PLAN_QUESTION_CD } from '../../components/objective-tab/mental-status-exam/constants';

const SurveySummary = ({ surveyState, surveyStructure, hideQuestionLabels, customTextAnswers }) => {
  const surveyCode = get(surveyStructure, 'surveyCode', null);

  const translateSurvey = () => get(surveyStructure, 'title', surveyCode);

  const translateQuestion = (sectionCode, questionCode) => {
    const section = get(surveyStructure, 'sections', []).find(s => s.sectionCode === sectionCode);
    const questionData = get(section, 'questions', []).find(q => q.questionCode === questionCode);

    return questionData.question;
  };

  const translateAnswer = (sectionCode, questionCode, answerCode) => {
    const section = get(surveyStructure, 'sections', []).find(s => s.sectionCode === sectionCode);
    const question = get(section, 'questions', []).find(q => q.questionCode === questionCode);
    const answerData = get(question, 'choices', []).find(a => a.answerCode === answerCode);

    return answerData.answer;
  };

  const getCustomAnswerText = questionCode => {
    const customText = get(customTextAnswers, questionCode, null);
    if (questionCode === SAFETY_PLAN_QUESTION_CD) {
      return customText;
    }
    return customText ? `(${customText})` : null;
  };

  const surveyAnswers = () => {
    const surveyInfo = get(surveyState, surveyCode, null);

    if (!surveyInfo) {
      return null;
    }

    const answerCollection = [];

    surveyStructure.sections.forEach(section => {
      section.questions.forEach(question => {
        const selectedAnswersObject = get(surveyInfo, [section.sectionCode, question.questionCode], null);
        const answersCodes = question.choices.map(answer => answer.answerCode);
        const answers = answersCodes
          .filter(answerCode => selectedAnswersObject[answerCode])
          .map(answerCode => translateAnswer(section.sectionCode, question.questionCode, answerCode));

        const answersString = answers.join(', ');

        // if no answer is present or the safety plan is missing then do not show anything
        if (
          !answersString ||
          (question.questionCode === SAFETY_PLAN_QUESTION_CD && !getCustomAnswerText(question.questionCode))
        ) {
          return null;
        }

        answerCollection.push(
          <React.Fragment key={`${section.sectionCode}.${question.questionCode}`}>
            <p className={get(styles, 'questionContainer')}>
              <span>
                {!hideQuestionLabels ? `${translateQuestion(section.sectionCode, question.questionCode)}: ` : ''}
              </span>
              {answersString} {getCustomAnswerText(question.questionCode)}
            </p>
          </React.Fragment>
        );
      });
    });

    return answerCollection;
  };

  return (
    <div className={get(styles, 'container')}>
      <strong>{translateSurvey(surveyCode)}</strong>
      <div>{surveyAnswers()}</div>
    </div>
  );
};

SurveySummary.propTypes = {
  surveyState:        PropTypes.object,
  surveyStructure:    PropTypes.object,
  hideQuestionLabels: PropTypes.bool,
  customTextAnswers:  PropTypes.object
};

export default SurveySummary;
