let currentMeanOpinionScoreHandler = null;

export * from './phone-validation';

// Strips the input of any non-numeric characters,
export const convertPhoneToDigitsOnly = phoneNumber => phoneNumber.replace(/\D/g, '');

export const setCurrentMeanOpinionScoreHandler = handler => {
  currentMeanOpinionScoreHandler = handler;
};

export const setupMeanOpinionScoreListener = connection => {
  connection.on('sample', data => {
    if (!data.mos || !currentMeanOpinionScoreHandler) return;

    currentMeanOpinionScoreHandler(data.mos);
  });
};

export * from './notifications';
export * from './useCallDuration';
export * from './use-member-unavailable-modal';

export { controls } from './controls';
