import React from 'react';

export const SearchIcon = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8888 11.704L9.05705 8.87226C8.98684 8.82545 8.91663 8.77864 8.84642 8.77864H8.54218C9.26767 7.93613 9.73574 6.81277 9.73574 5.59581C9.73574 2.92784 7.53584 0.727936 4.86787 0.727936C2.1765 0.727936 0 2.92784 0 5.59581C0 8.28718 2.1765 10.4637 4.86787 10.4637C6.08484 10.4637 7.18479 10.019 8.05071 9.29351V9.59776C8.05071 9.66796 8.07411 9.73818 8.12092 9.80838L10.9527 12.6402C11.0697 12.7572 11.2569 12.7572 11.3506 12.6402L11.8888 12.1019C12.0059 12.0083 12.0059 11.8211 11.8888 11.704ZM4.86787 9.34032C2.78498 9.34032 1.12335 7.67869 1.12335 5.59581C1.12335 3.53632 2.78498 1.85129 4.86787 1.85129C6.92735 1.85129 8.61238 3.53632 8.61238 5.59581C8.61238 7.67869 6.92735 9.34032 4.86787 9.34032Z"
      fill="#B4B4AF"
    />
  </svg>
);
