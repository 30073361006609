import { useEffect, useRef } from 'react';
import restorePreviousState from './restore-previous-state';
import useSelectedTeladocServices from './use-selected-teladoc-services';
import SERIVCE_SPECIALTIES_QUERY from '../queries/service-specialties.graphql';
import { useQuery } from '@apollo/client';
import { useFetcher } from '@td/shared_utils';

const useConsultFollowup = ({
  memberId,
  consultSpecialtyName,
  stateCode,
  reduxState,
  setReduxState,
  noFurtherCareMinDays,
  noFurtherCareMaxDays,
  initialValues,
  communicationMethod,
  providerScheduledAppointmentAllowed
}) => {
  if (!reduxState.noFurtherCareMaxDays) setReduxState({ noFurtherCareMaxDays, communicationMethod });
  if (!reduxState.noFurtherCareMinDays) setReduxState({ noFurtherCareMinDays, communicationMethod });

  const didInitializeReduxStoreRef = useRef(false);

  const teladocServicesFetch = useQuery(SERIVCE_SPECIALTIES_QUERY, {
    variables: { memberId, stateCode }
  });
  const nonTeladocServicesFetch = useFetcher(`v4/members/${memberId}/display_code_mappings`, {
    specialty_name: consultSpecialtyName
  });

  const isError = teladocServicesFetch.error || nonTeladocServicesFetch.error;
  const isLoading =
    teladocServicesFetch.loading || nonTeladocServicesFetch.loading || !didInitializeReduxStoreRef.current;

  useEffect(() => {
    if (!teladocServicesFetch.loading) {
      setReduxState({ teladocServices: teladocServicesFetch.data.member.serviceSpecialties });
    }
  }, [teladocServicesFetch.loading]);

  useEffect(() => {
    if (!nonTeladocServicesFetch.loading && nonTeladocServicesFetch.data) {
      setReduxState({ nonTeladocServices: nonTeladocServicesFetch.data.displayCodeMappings });
    }
  }, [nonTeladocServicesFetch.loading]);

  const allDataLoaded = reduxState.teladocServices && reduxState.nonTeladocServices;
  useEffect(() => {
    if (allDataLoaded && !didInitializeReduxStoreRef.current) {
      setReduxState(restorePreviousState(reduxState, initialValues, providerScheduledAppointmentAllowed));
      didInitializeReduxStoreRef.current = true;
    }
  }, [allDataLoaded]);

  const setNoFurtherCareDays = noFurtherCareDays => setReduxState({ noFurtherCareDays });

  const selectedTeladocServices = useSelectedTeladocServices(
    reduxState.selectedTeladocServices,
    selectedTeladocServices => setReduxState({ selectedTeladocServices })
  );

  const setSelectedNonTeladocService = selectedNonTeladocService => setReduxState({ selectedNonTeladocService });

  const setSelectedAppointmentOption = selectedAppointmentOption => setReduxState({ selectedAppointmentOption });
  window.setSelectedAppointmentOption = setSelectedAppointmentOption;

  const setP2pNote = p2pNote => setReduxState({ p2pNote });

  return {
    teladocServicesFetch,
    nonTeladocServicesFetch,
    isError,
    isLoading,

    noFurtherCareDays: reduxState.noFurtherCareDays,
    setNoFurtherCareDays,

    selectedTeladocServices,

    selectedAppointmentOption: reduxState.selectedAppointmentOption,
    setSelectedAppointmentOption,

    selectedNonTeladocService: reduxState.selectedNonTeladocService,
    setSelectedNonTeladocService,

    p2pNote: reduxState.p2pNote,
    setP2pNote
  };
};

export default useConsultFollowup;
