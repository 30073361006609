// Seems like an overkill for the purpose it serves
export const LABS_QUESTION_SETTINGS = {
  OPTIONS: [
    {
      text:  'No',
      value: 'no'
    },
    {
      text:  'Yes',
      value: 'yes'
    }
  ],
  IS_SELECTED_COMPARATOR: { yes: true, no: false }
};

export const LAB_PARTNER_URLS = {
  // Add partner links here in the following format:
  // LOCATION_CODE: 'url'
  // Example:
  // CAN: 'https://www.junoemr.com/login'
};
