import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { ModalProvider } from 'app/modal';
import BandwidthColors from 'app/Provider/enums/bandwidth-colors';
import { useBandwidth, useCallDuration, setCurrentMeanOpinionScoreHandler } from '../utils';
import { SignalBars } from '@td/td-ui-kit';
import AutoDialerFooter from './AutoDialerFooter';
import AutoDialerTimer from './AutoDialerTimer';
import WebRTCInitializer from './WebRTCInitializer';
import { checkIfShouldHidePhoneNumber } from '../api';

const AutoDialer = ({ consultation, activeConferenceRS, options = {} }) => {
  const { callLengthLabel, callJoinTime, callDurationJoinCallHandler, callDurationEndCallHandler } = useCallDuration();
  const [mosScore, setMosScore] = useState(5);
  const [hidePhoneNumber, setHidePhoneNumber] = useState(false);
  const autoDialerContainerRef = useRef();

  useEffect(() => {
    setCurrentMeanOpinionScoreHandler(setMosScore);
  }, [setMosScore]);

  useEffect(() => {
    checkIfShouldHidePhoneNumber(consultation.consultationId).then(data => setHidePhoneNumber(data.hide_phone_number));
  }, []);

  return (
    <ModalProvider>
      <SignalBars
        barsClassName="auto-dialer-bandwidth-bar"
        barsContainerClassName="auto-dialer-bandwidth"
        emptySignalColor="#FFFFFF"
        numberOfBars={Math.round(mosScore)}
        signalColor={BandwidthColors[Math.round(mosScore)]}
      />

      <div
        id="auto_dialer_consult_ct"
        data-autodialer-version="AutoDialerV1"
        className={callJoinTime && callLengthLabel && 'auto-dialer-sticky-timer'}
        ref={autoDialerContainerRef}
      >
        <AutoDialerTimer
          autoDialerContainer={autoDialerContainerRef}
          callLengthLabel={callLengthLabel}
          callJoinTime={callJoinTime}
        />

        <div id="auto_dialer_widget_container">
          <WebRTCInitializer
            consultation={consultation}
            options={options}
            activeConferenceRS={activeConferenceRS}
            onCallJoin={callDurationJoinCallHandler}
            onEndCall={callDurationEndCallHandler}
          />
          <AutoDialerFooter
            consultationId={consultation.consultationId}
            primaryPhone={consultation.phoneNumber}
            secondaryPhone={consultation.alternatePhoneNumber}
            hidePhoneNumber={hidePhoneNumber}
          />
        </div>
      </div>
    </ModalProvider>
  );
};

// Consultation obj shape converted exhaustively in AutoDialerInitializer.js
AutoDialer.propTypes = {
  consultation: PropTypes.object,
  options:      PropTypes.object
};

AutoDialer.displayName = 'AutoDialer';

export default AutoDialer;
