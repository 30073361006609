import moment from 'moment';
import { map } from 'lodash';

const getFormattedEvents = events => {
  const formattedEvents = [];
  if (events && events.length > 0) {
    map(events, event => {
      const startDay = parseInt(moment(event.start).format('D'), 10);
      const endDay = parseInt(moment(event.end).format('D'), 10);
      if (startDay < endDay) {
        const startDayEvent = {
          ...event,
          start: event.start,
          end:   moment(event.start).endOf('day')._d // e.g. 11.59PM
        };
        formattedEvents.push(startDayEvent);
        const endDayEvent = {
          ...event,
          start: moment(event.end).startOf('day')._d, // e.g. 12.00AM
          end:   event.end
        };
        formattedEvents.push(endDayEvent);
      } else {
        // Set original event since it doesn't spread through 2 days
        formattedEvents.push(event);
      }
    });
  }

  return formattedEvents;
};

export default getFormattedEvents;
