import moment from 'moment';

const formatSingleEvent = event => {
  const formattedEvents = [];
  const pastDate = moment().startOf('week').subtract(10, 'years');

  const { allDayEventFlg, startDateTime, endDateTime, providerScheduleId } = event;
  const startDay = moment.duration(moment(startDateTime).startOf('day').diff(pastDate)).asDays();
  const endDay = moment.duration(moment(endDateTime).startOf('day').diff(pastDate)).asDays();

  if (!allDayEventFlg && endDay === startDay) {
    formattedEvents.push(event);
  } else {
    for (let currentDay = startDay; currentDay <= endDay; currentDay++) {
      const id = `${providerScheduleId}-${currentDay}`;
      const start = allDayEventFlg || currentDay !== startDay
        ? moment(startDateTime).add(currentDay - startDay, 'day').startOf('day').toDate()
        : moment(startDateTime).toDate();
      let end = currentDay !== endDay
        ? moment(start).endOf('day').toDate()
        : moment(endDateTime).toDate();
      if (allDayEventFlg) {
        end = moment(start).startOf('day').add(1, 'day').toDate();
      }

      formattedEvents.push({ ...event, start, end, id });
    }
  }

  return formattedEvents;
};

const getFormattedEvents = events => events.flatMap(formatSingleEvent);

export default getFormattedEvents;
export { formatSingleEvent };
