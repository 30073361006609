import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const teladoc = 'Teladoc';
const surescripts = 'Surescripts';
const isEmrNewMedicationEnabled = window.FEATURE_TOGGLES.emr_new_medication;

const DATA_SOURCE_TRANSLATIONS = {
  'Admin Portal':    teladoc,
  'Provider Portal': teladoc,
  'Provider Mobile': teladoc,
  'Member Portal':   teladoc,
  'Member Mobile':   teladoc,
  Surescripts:       surescripts
};

const ClinicalMedicationListItem = ({ medication, surescriptsUpdated }) => (
  <tr>
    <td className={medication.validForAlerts ? '' : 'medication-alert__disabled'}>
      {
        (isEmrNewMedicationEnabled && medication.legacyFormatFlg === 'Y')
          ? `${medication.drugName}` + (medication.drugQuantity ? `, ${medication.drugQuantity}` : '')
          : medication.drugName
      }
    </td>
    {!isEmrNewMedicationEnabled && <td>{medication.drugQuantity}</td>}
    <td>{medication.drugFrequency}</td>
    <td>
      {surescriptsUpdated && (medication.activelyTakingFlag === 'Y' ? 'Current' : 'Past')}
      {!surescriptsUpdated && 'Updating...'}
    </td>
    <td>
      {medication.serviceDate
        ? moment(medication.serviceDate).format('MM/DD/YYYY')
        : moment(medication.createdAt).format('MM/DD/YYYY')}
    </td>
    <td>
      {medication.dataSource !== 'Surescripts' && (
        <span>
          <span>{medication.createdByType}</span>
          {medication.providerName && <span> ({medication.providerName})</span>}
        </span>
      )}
      {medication.dataSource === 'Surescripts' && <span>{medication.dataSource}</span>}
    </td>
    <td>
      {DATA_SOURCE_TRANSLATIONS[medication.dataSource] && medication.event
        ? `${DATA_SOURCE_TRANSLATIONS[medication.dataSource]} ${medication.event}`
        : 'Unknown'}
    </td>
  </tr>
);

ClinicalMedicationListItem.propTypes = {
  medication:         PropTypes.object.isRequired,
  surescriptsUpdated: PropTypes.bool.isRequired
};

ClinicalMedicationListItem.defaultProps = {
  surescriptsUpdated: false
};

export default ClinicalMedicationListItem;
