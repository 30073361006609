exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "#auto_dialer_consult_ct.autoDialerV3 ._2KiY_0ctgw8p42-y20LTQi {\n  margin-bottom: 10px;\n  padding: 15px;\n  background: #FFFFFF;\n  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);\n  border-radius: 8px 8px 0px 8px; }\n  #auto_dialer_consult_ct.autoDialerV3 ._2KiY_0ctgw8p42-y20LTQi ._1NG_VPXMeDSWLDc3yzqW5r {\n    border: 0;\n    margin: 0;\n    border-top: 3px solid #6DC4E5; }\n  #auto_dialer_consult_ct.autoDialerV3 ._2KiY_0ctgw8p42-y20LTQi ._3JGBIYkAOIV8ABXX4uLpQ6 {\n    background: #E5F4FA;\n    border-radius: 8px;\n    border: 2px solid #4E89A4;\n    height: 184px; }\n  #auto_dialer_consult_ct.autoDialerV3 ._2KiY_0ctgw8p42-y20LTQi ._3Uv77mo_dYcpY-IpvLfJj0 {\n    height: 100%;\n    display: flex;\n    flex-direction: column; }\n  #auto_dialer_consult_ct.autoDialerV3 ._2KiY_0ctgw8p42-y20LTQi ._2eD3-3YDuToay0ix4XEKQl {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center; }\n", ""]);

// exports
exports.locals = {
	"autoDialerContainer": "_2KiY_0ctgw8p42-y20LTQi",
	"autoDialerContainer": "_2KiY_0ctgw8p42-y20LTQi",
	"containerTopLine": "_1NG_VPXMeDSWLDc3yzqW5r",
	"containerTopLine": "_1NG_VPXMeDSWLDc3yzqW5r",
	"callViewContainer": "_3JGBIYkAOIV8ABXX4uLpQ6",
	"callViewContainer": "_3JGBIYkAOIV8ABXX4uLpQ6",
	"dialingContainer": "_3Uv77mo_dYcpY-IpvLfJj0",
	"dialingContainer": "_3Uv77mo_dYcpY-IpvLfJj0",
	"loadingContainer": "_2eD3-3YDuToay0ix4XEKQl",
	"loadingContainer": "_2eD3-3YDuToay0ix4XEKQl"
};