import { from } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { switchMap, map, catchError } from 'rxjs/operators';
import { FETCH_TRAVEL_MEDICATION_SURVEY } from '../action-types';
import GET_SURVEY_QUERY from '../queries/get-survey.graphql';
import getClientInstance from '../../../../services/apollo-client';
import { SURVEY_CODE, TRANSLATION_SCOPE } from '../constants';
import { initTravelMedicationSurvey } from '../actions';
import { translate } from '@td/shared_utils';

export const fetchTravelMedicationSurveyEpic = (action$) => (
  action$.pipe(
    ofType(FETCH_TRAVEL_MEDICATION_SURVEY),
    switchMap(() => {
      const apolloClient = getClientInstance();

      return from(
        apolloClient.query({
          query: GET_SURVEY_QUERY,
          variables: {
            surveyMnemonicCd: SURVEY_CODE
          }
        })
      )
    }),
    map(({ data: { survey } }) => (
      initTravelMedicationSurvey(survey)
    )),
    catchError(() => {
      window.showGenericMessageAndDoNotHide(
        translate(null, TRANSLATION_SCOPE, 'server_error'),
        'error'
      )
    })
  )
);

export default combineEpics(fetchTravelMedicationSurveyEpic);
