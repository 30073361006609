import React from 'react';
import PropTypes from 'prop-types';

const SymptomHiddenFields = ({ symptom }) => (
  <span>
    <input
      type="hidden"
      name="interview[medical_symptoms][][symptom_id]"
      value={symptom.id}
    />
    <input
      type="hidden"
      name="interview[medical_symptoms][][symptom_name]"
      value={symptom.name}
    />
    <input
      type="hidden"
      name="interview[medical_symptoms][][symptom_present_now_cd]"
      value={symptom.radioValue}
    />
    <input
      type="hidden"
      name="interview[medical_symptoms][][symptom_note]"
      value={symptom.noteValue}
    />
  </span>
);

SymptomHiddenFields.propTypes = {
  symptom: PropTypes.shape({
    id:         PropTypes.number.isRequired,
    name:       PropTypes.string.isRequired,
    radioValue: PropTypes.string.isRequired,
    noteValue:  PropTypes.string
  })
};

export default SymptomHiddenFields;
