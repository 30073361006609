import { mapKeysDeep, transformKey } from '@td/utils';
import {
  FETCH_PROVIDER_ORDER_START,
  FETCH_PROVIDER_ORDER_SUCCESS,
  FETCH_PROVIDER_ORDER_FAIL,
  REVOKE_PROVIDER_ORDER_SUCCESS,
  REVOKE_PROVIDER_ORDER_FAIL,
  REVOKE_PROVIDER_ORDER_START
} from './actionTypes';

const DEFAULT_STATE = {
  fetched:      false,
  isLoading:    false,
  message:      '',
  orders:       [],
  revokeResult: { pending: false, success: false, failed: false }
};

const buildProviderOrders = data =>
  data.map(pod => mapKeysDeep(pod, transformKey));

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_PROVIDER_ORDER_START: {
      return {
        ...state,
        fetched:   false,
        isLoading: true
      };
    }
    case FETCH_PROVIDER_ORDER_SUCCESS: {
      const orders = buildProviderOrders(action.payload.data.provider_orders);

      return {
        ...state,
        fetched:   true,
        isLoading: false,
        orders
      };
    }
    case FETCH_PROVIDER_ORDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        fetched:   false
      };
    }
    case REVOKE_PROVIDER_ORDER_START: {
      let revokeResult = {
        ...state.revokeResult,
        pending: true,
        success: false,
        failed:  false
      };

      return {
        ...state,
        fetched:   false,
        isLoading: true,
        revokeResult
      };
    }
    case REVOKE_PROVIDER_ORDER_SUCCESS: {
      let revokeResult = {
        ...state.revokeResult,
        pending: false,
        success: true,
        failed:  false
      };

      return {
        ...state,
        fetched:   true,
        isLoading: false,
        revokeResult
      };
    }
    case REVOKE_PROVIDER_ORDER_FAIL: {
      let revokeResult = revokeResult = {
        ...state.revokeResult,
        pending: false,
        success: false,
        failed:  true
      };

      return {
        ...state,
        isLoading: false,
        fetched:   false,
        revokeResult
      };
    }
    default: {
      return state;
    }
  }
};
