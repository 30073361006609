import React, { useEffect } from 'react';
import TitleRow from './components/TitleRow';
import Row from './components/Row';
import { genereateName, prepareSelector } from '../../utils/base';

const SecondaryDiagnosisRow = ({
  diagnosis,
  index,
  onChange,
  filterAutoCompleteListProposals,
  onAutoCompleteSelect,
  onClickRemove,
  onClickClear,
  showMinus,
  uniqueKeyAllSelectedSecondaryDiagnoses
}) => {
  const handleChangeInput = (key, value, indexOfInput) => {
    onChange(key, value, indexOfInput);
  };

  const handleAutoCompleteSelect = item => {
    onAutoCompleteSelect(item, index);
  };

  useEffect(() => {
    const mds = new window.MedicalDiagnosisSearch({
      diagnosis_name_selector:  prepareSelector(diagnosis, '#secondary_medical_diagnosis_search_name'),
      diagnosis_code_selector:  prepareSelector(diagnosis, '#secondary_medical_diagnosis_search_code'),
      diagnosis_id_selector:    prepareSelector(diagnosis, '#interview_secondary_medical_diagnosis_id'),
      article_id_selector:      prepareSelector(diagnosis, '#interview_secondary_article_id'),
      article_title_selector:   prepareSelector(diagnosis, '#interview_secondary_article_title'),
      diagnosis_text_selector:  prepareSelector(diagnosis, '#interview_secondary_medical_diagnosis_text'),
      diagnosis_name_container: prepareSelector(diagnosis, '#secondary_diagnosis_name_auto_container'),
      diagnosis_code_container: prepareSelector(diagnosis, '#secondary_diagnosis_code_auto_container'),
      diagnosis_clear_selector: prepareSelector(diagnosis, '#secondary_diagnosis_code_clear'),
      secondary_diagnosis_flag: prepareSelector(
        diagnosis,
        '#interview_secondary_medical_diagnosis_secondary_diagnosis_flag'
      ),
      free_text_excuse_permitted: prepareSelector(
        diagnosis,
        '#interview_secondary_medical_diagnosis_free_text_excuse_permitted'
      ),
      onAutoCompleteSelect: handleAutoCompleteSelect,
      filterAutoCompleteListProposals
    });
  }, [uniqueKeyAllSelectedSecondaryDiagnoses]);

  const renderRow = () => (
    <Row
      index={index}
      showMinus={showMinus}
      diagnosis={diagnosis}
      onClickRemove={onClickRemove}
      onClickClear={onClickClear}
      onChangeInput={handleChangeInput}
    />
  );

  return (
    <div>
      <TitleRow index={index} />
      {renderRow()}
      <input
        type="hidden"
        value={diagnosis.text}
        name={genereateName('text')}
        id={prepareSelector(diagnosis, 'interview_secondary_medical_diagnosis_text')}
        className="js_diagnosis_text"
      />
      <input
        type="hidden"
        value={diagnosis.id}
        name={genereateName('id')}
        id={prepareSelector(diagnosis, 'interview_secondary_medical_diagnosis_id')}
        className="js_diagnosis_id"
      />
      <input
        type="hidden"
        value={diagnosis.flag}
        name={genereateName('flag')}
        id={prepareSelector(diagnosis, 'interview_secondary_medical_diagnosis_secondary_diagnosis_flag')}
        className="js_diagnosis_flag"
      />
      <input
        type="hidden"
        value={diagnosis.free}
        name={genereateName('free_text_excuse_permitted')}
        id={prepareSelector(diagnosis, 'interview_secondary_medical_diagnosis_free_text_excuse_permitted')}
      />
      <input
        type="hidden"
        value={diagnosis.article_id}
        name={genereateName('article_id')}
        id={prepareSelector(diagnosis, 'interview_secondary_article_id')}
      />
      <input
        type="hidden"
        value={diagnosis.article_title}
        name={genereateName('article_title')}
        id={prepareSelector(diagnosis, 'interview_secondary_article_title')}
      />
      <input
        type="hidden"
        value={diagnosis.index}
        name={genereateName('index')}
        id={prepareSelector(diagnosis, 'interview_secondary_article_index')}
        value={index}
      />
      <div
        id={prepareSelector(diagnosis, 'secondary_diagnosis_name_auto_container')}
        className="js_diagnosis_name_auto"
      />
      <div
        id={prepareSelector(diagnosis, 'secondary_diagnosis_code_auto_container')}
        className="js_diagnosis_code_auto"
      />
    </div>
  );
};

export default SecondaryDiagnosisRow;
