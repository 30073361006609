import { clinicalAlertValidator } from 'app/consult-navigation/validators/clinical-alert-validator';
import store from 'app/lib/store';
import { chain } from 'lodash';

const emrPui = () => {
  const state = store.getState();

  const validators = { ...clinicalAlertValidator(state) };
  const tabErrorMessages = chain(validators)
    .values()
    .filter(validator => validator.invalid)
    .map(validator => validator.message)
    .value();

  return tabErrorMessages;
};

export default () => Promise.resolve(emrPui());
