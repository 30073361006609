import { medicationV4InfoPath } from 'app/config/paths';

import {
  FETCH_QUICK_RX_BY_PROVIDER,
  FETCH_QUICK_RX_BY_PROVIDER_START,
  FETCH_QUICK_RX_BY_PROVIDER_SUCCESS,
  FETCH_QUICK_RX_BY_PROVIDER_FAIL,
  SAVE_QUICK_RX_BY_PROVIDER_START,
  SAVE_QUICK_RX_BY_PROVIDER_SUCCESS,
  SAVE_QUICK_RX_BY_PROVIDER_FAIL,
  SAVE_QUICK_RX_BY_PROVIDER,
  REMOVE_QUICK_RX_BY_PROVIDER,
  REMOVE_QUICK_RX_BY_PROVIDER_START,
  REMOVE_QUICK_RX_BY_PROVIDER_SUCCESS,
  REMOVE_QUICK_RX_BY_PROVIDER_FAIL,
  FETCH_MEDICATION_INFO,
  FETCH_MEDICATION_INFO_START,
  FETCH_MEDICATION_INFO_SUCCESS,
  FETCH_MEDICATION_INFO_FAIL,
  FETCH_MEDICATION_FORM,
  FETCH_MEDICATION_FORM_START,
  FETCH_MEDICATION_FORM_SUCCESS,
  FETCH_MEDICATION_FORM_FAIL,
  FETCH_COMMON_DOSAGE,
  FETCH_COMMON_DOSAGE_START,
  FETCH_COMMON_DOSAGE_SUCCESS,
  FETCH_COMMON_DOSAGE_FAIL,
  FETCH_MEDICATION_BY_SPECIALITY,
  FETCH_MEDICATION_BY_SPECIALITY_START,
  FETCH_MEDICATION_BY_SPECIALITY_SUCCESS,
  FETCH_MEDICATION_BY_SPECIALITY_FAIL,
  RESET_MEDICATION_BY_SPECIALITY,
  FETCH_PRESCRIPTION_CODE,
  FETCH_PRESCRIPTION_CODE_START,
  FETCH_PRESCRIPTION_CODE_SUCCESS,
  FETCH_PRESCRIPTION_CODE_FAIL,
  SUBMIT_PRESCRIPTIONS_POST_CONSULT,
  SUBMIT_PRESCRIPTIONS_POST_CONSULT_SUCCESS,
  SUBMIT_PRESCRIPTION_FORM_START,
  SUBMIT_PRESCRIPTION_FORM_SUCCESS,
  SUBMIT_PRESCRIPTION_FORM_FAIL,
  SUBMIT_PRESCRIPTION_FORM,
  ADD_TO_PENDING_PRESCRIPTION_LIST,
  UPSERT_TO_PENDING_PRESCRIPTION_LIST,
  UPDATE_TO_PENDING_PRESCRIPTION_LIST,
  REMOVE_FROM_PENDING_PRESCRIPTION_LIST,
  CONFIRM_PENDING_PRESCRIPTIONS_LIST,
  CLEAR_PENDING_PRESCRIPTION_LIST,
  TOGGLE_DISPLAY_STATE
} from './actionTypes';

import { PROVIDER_BASE_URL } from '../config/constants';

export const toggleDisplayState = () => ({
  type: TOGGLE_DISPLAY_STATE
});

export const fetchQuickRxByProviderStart = () => ({
  type: FETCH_QUICK_RX_BY_PROVIDER_START
});

export const fetchQuickRxByProviderSuccess = payload => ({
  type: FETCH_QUICK_RX_BY_PROVIDER_SUCCESS,
  payload
});

export const fetchQuickRxByProviderFail = error => ({
  type:    FETCH_QUICK_RX_BY_PROVIDER_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const fetchQuickRxByProvider = ({ consultationId }) => ({
  type:    FETCH_QUICK_RX_BY_PROVIDER,
  payload: { consultationId }
});

export const removeQuickRxByProviderStart = quickRxItem => ({
  type: REMOVE_QUICK_RX_BY_PROVIDER_START,
  quickRxItem
});

export const removeQuickRxByProviderSuccess = payload => ({
  type: REMOVE_QUICK_RX_BY_PROVIDER_SUCCESS,
  payload
});

export const removeQuickRxByProviderFail = error => ({
  type: REMOVE_QUICK_RX_BY_PROVIDER_FAIL,
  error
});

export const removeQuickRxByProvider = formData => ({
  type:    REMOVE_QUICK_RX_BY_PROVIDER,
  payload: formData
});

export const saveQuickRxByProviderStart = payload => ({
  type: SAVE_QUICK_RX_BY_PROVIDER_START,
  payload
});

export const saveQuickRxByProviderSuccess = payload => ({
  type: SAVE_QUICK_RX_BY_PROVIDER_SUCCESS,
  payload
});

export const saveQuickRxByProviderFail = payload => ({
  type: SAVE_QUICK_RX_BY_PROVIDER_FAIL,
  payload
});

export const saveQuickRxByProvider = formData => ({
  type:    SAVE_QUICK_RX_BY_PROVIDER,
  payload: formData
});

export const fetchMedicationInfoStart = () => ({
  type: FETCH_MEDICATION_INFO_START
});

export const fetchMedicationInfoSuccess = payload => ({
  type: FETCH_MEDICATION_INFO_SUCCESS,
  payload
});

export const fetchMedicationInfoFail = error => ({
  type:    FETCH_MEDICATION_INFO_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const fetchMedicationInfo = payload => {
  const { ndc, brandCode } = payload;
  const url = medicationV4InfoPath(ndc);
  const params = { drug_id: ndc, brand_code: brandCode };

  return {
    type:    FETCH_MEDICATION_INFO,
    payload: { url, params }
  };
};

export const fetchMedicationFormStart = () => ({
  type: FETCH_MEDICATION_FORM_START
});

export const fetchMedicationFormSuccess = payload => ({
  type: FETCH_MEDICATION_FORM_SUCCESS,
  payload
});

export const fetchMedicationFormFail = error => ({
  type:    FETCH_MEDICATION_FORM_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const fetchDrugForms = payload => {
  const { ndc, brandCode, name, generic, consultationId } = payload;
  const specificity = generic ? 'GENERIC' : 'BRAND';
  const url = `${PROVIDER_BASE_URL}drug_forms/${ndc}?brand_code=${brandCode ||
    ''}&drug_name=${name}&specificity=${specificity}&consultation_id=${consultationId}`;
  const params = {
    drug_id:         ndc,
    brand_code:      brandCode,
    drug_name:       name,
    drug_code:       ndc,
    specificity,
    consultation_id: consultationId
  };

  return {
    type:    FETCH_MEDICATION_FORM,
    payload: { url, params }
  };
};

export const fetchCommonDosageStart = () => ({
  type: FETCH_COMMON_DOSAGE_START
});

export const fetchCommonDosageSuccess = payload => ({
  type: FETCH_COMMON_DOSAGE_SUCCESS,
  payload
});

export const fetchCommonDosageFail = error => ({
  type:    FETCH_COMMON_DOSAGE_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const fetchCommonDosage = payload => {
  const url = `${PROVIDER_BASE_URL}common_dosage/${payload}`;
  const params = {};

  return {
    type:    FETCH_COMMON_DOSAGE,
    payload: { url, params }
  };
};

export const resetMedicationList = () => ({
  type: RESET_MEDICATION_BY_SPECIALITY
});

export const fetchMedicationBySpeciality = ({ consultationId, erxPatientId, value }) => {
  const url = `${PROVIDER_BASE_URL}prescription_lookup/${consultationId}?term=${encodeURIComponent(
    value
  )}&erx_patient_id=${erxPatientId}`;
  const params = {};

  return {
    type:    FETCH_MEDICATION_BY_SPECIALITY,
    payload: { url, params }
  };
};

export const fetchMedicationBySpecialityStart = () => ({
  type: FETCH_MEDICATION_BY_SPECIALITY_START
});

export const fetchMedicationBySpecialitySuccess = data => ({
  type:    FETCH_MEDICATION_BY_SPECIALITY_SUCCESS,
  payload: data
});

export const fetchMedicationBySpecialityFail = error => ({
  type:    FETCH_MEDICATION_BY_SPECIALITY_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const fetchPrescriptionCodeStart = () => ({
  type: FETCH_PRESCRIPTION_CODE_START
});

export const fetchPrescriptionCodeSuccess = payload => ({
  type: FETCH_PRESCRIPTION_CODE_SUCCESS,
  payload
});

export const fetchPrescriptionCodeFail = error => ({
  type:    FETCH_PRESCRIPTION_CODE_FAIL,
  error:   true,
  message: error.message || 'Something went wrong'
});

export const fetchPrescriptionCode = payload => {
  const { consultationId } = payload;
  const url = `${PROVIDER_BASE_URL}consultations/${consultationId}/rx_codes`;
  const params = {};

  return {
    type:    FETCH_PRESCRIPTION_CODE,
    payload: { url, params }
  };
};

export const submitPrescriptionFormStart = payload => ({
  type: SUBMIT_PRESCRIPTION_FORM_START,
  payload
});

export const submitPrescriptionFormSuccess = payload => ({
  type: SUBMIT_PRESCRIPTION_FORM_SUCCESS,
  payload
});

export const submitPrescriptionFormFail = (error, { drugNdcCode, errors }) => ({
  type:    SUBMIT_PRESCRIPTION_FORM_FAIL,
  error:   true,
  message: error.message || 'Something went wrong',
  domain:  error.domain,
  field:   error.field,
  ssError: error.ssError,
  payload: {
    drugNdcCode,
    errors
  }
});

export const submitPrescriptionForm = formData => ({
  type:    SUBMIT_PRESCRIPTION_FORM,
  payload: formData
});

export const submitPrescriptionsPostConsult = pendingPrescriptions => ({
  type:    SUBMIT_PRESCRIPTIONS_POST_CONSULT,
  payload: pendingPrescriptions
});

export const submitPrescriptionsPostConsultSuccess = pendingPrescriptions => ({
  type:    SUBMIT_PRESCRIPTIONS_POST_CONSULT_SUCCESS,
  payload: pendingPrescriptions
});

export const addToPendingPrescriptionList = payload => ({
  type: ADD_TO_PENDING_PRESCRIPTION_LIST,
  payload
});

export const confirmPendingPrescriptionsList = (payload = { postConsult: false }) => ({
  type: CONFIRM_PENDING_PRESCRIPTIONS_LIST,
  payload
});

export const upsertToPendingPrescriptionList = payload => ({
  type: UPSERT_TO_PENDING_PRESCRIPTION_LIST,
  payload
});

export const updateToPendingPrescriptionList = payload => ({
  // Replaces a pending prescription only if the item is still in the collection
  // Avoids a race condition. If you want to create if it doesn't exist, use UPSERT
  type: UPDATE_TO_PENDING_PRESCRIPTION_LIST,
  payload
});

export const removeFromPendingPrescriptionList = payload => ({
  type: REMOVE_FROM_PENDING_PRESCRIPTION_LIST,
  payload
});

export const clearPendingPrescriptionList = () => ({
  type: CLEAR_PENDING_PRESCRIPTION_LIST
});
