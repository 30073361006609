import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import removeAtIndex from './utils/arrays/removeAtIndex';
import replaceAtIndex from './utils/arrays/replaceAtIndex';
import Loader from '../Loader';
import {
  get,
  uniqueId,
  reject,
  map,
  includes,
  concat,
  pick,
  isUndefined,
  isNil,
  times,
  isEmpty,
  isEqual,
  join,
  isNumber
} from 'lodash';

import SecondaryDiagnosisRow from './components/SecondaryDiagnosisRow';
import Minus from './components/Minus';
import Plus from './components/Plus';

import {
  addNewItemToSecondaryDiagnosesList,
  removeItemAtIndexFromSecondaryDiagnosesList,
  replaceItemAtIndexInSecondaryDiagnosesList
} from './redux/actions';

import { secondaryDiagnosesListSelector, secondaryDiagnosesConfigSelector } from './redux/selectors';

const getEmptyDiagnosis = () => ({
  diagnosisKey: uniqueId('secondary_diagnosis_'),
  code:         '',
  description:  ''
});

const SecondaryDiagnosesContainer = ({
  diagnosesList,
  addNewItem,
  removeItemAtIndex,
  replaceItemAtIndex,
  tasConfig,
  cachedSecondaryDiagnoses,
  componentName
}) => {
  //* ***Hooks*****
  // const [config, setConfig] = useState({ minItems: 0, maxItems: 1 });
  const [showMinus, setShowMinus] = useState(true);
  const [showPlus, setShowPlus] = useState(true);

  useEffect(() => {
    if (isNil(cachedSecondaryDiagnoses)) return;

    cachedSecondaryDiagnoses.map(cachedDiagnosis => {
      const emptyDiagnosis = getEmptyDiagnosis();

      const updatedDiagnosis = {
        ...emptyDiagnosis,
        text:          cachedDiagnosis.text,
        id:            cachedDiagnosis.id,
        code:          cachedDiagnosis.code,
        description:   cachedDiagnosis.name,
        flag:          cachedDiagnosis.flag,
        free:          cachedDiagnosis.free_text_excuse_permitted,
        article_id:    cachedDiagnosis.article_id,
        article_title: cachedDiagnosis.article_title,
        index:         cachedDiagnosis.index
      };

      addNewItem({ newItem: updatedDiagnosis });
    });
  }, []);

  useEffect(() => {
    if (!isNil(cachedSecondaryDiagnoses)) return;

    times(get(tasConfig, 'meta.minItems'), () => {
      addNewItem({ newItem: getEmptyDiagnosis() });
    });
  }, [get(tasConfig, 'meta.minItems')]);

  useEffect(() => {
    if (diagnosesList.length <= 0 || diagnosesList.length > get(tasConfig, 'meta.maxItems')) {
      setShowMinus(false);
    } else {
      setShowMinus(true);
    }

    if (diagnosesList.length === get(tasConfig, 'meta.maxItems')) {
      setShowPlus(false);
    } else {
      setShowPlus(true);
    }
  });
  //* ***Hooks*****

  if (!get(tasConfig, 'display')) return null;
  if (!isNumber(get(tasConfig, 'meta.minItems'))) return null;
  if (!isNumber(get(tasConfig, 'meta.maxItems'))) return null;
  if (tasConfig.meta.minItems > tasConfig.meta.maxItems) return null;

  const uniqueKeyAllSelectedSecondaryDiagnoses = join(
    diagnosesList.map(diagnosis => diagnosis.id),
    '-'
  );

  const handleChangeInput = (key, value, index) => {
    const currentDiagnosis = diagnosesList[index];
    if (isUndefined(currentDiagnosis[key]) || currentDiagnosis[key].length !== value.length) {
      const emptyDiagnosis = pick(currentDiagnosis, ['diagnosisKey', key]);
      const updatedDiagnosis = { ...emptyDiagnosis, [key]: value };
      replaceItemAtIndex({ index, updatedItem: updatedDiagnosis });
    }
  };

  const handleClickRemove = index => {
    removeItemAtIndex({ index });
  };

  const handleClickClear = index => {
    replaceItemAtIndex({ index, updatedItem: getEmptyDiagnosis() });
  };

  const handleClickAdd = e => {
    e.preventDefault();

    addNewItem({ newItem: getEmptyDiagnosis() });
  };

  const filterAutoCompleteListProposals = diagnosis_codes => {
    const diagnosesListDiagnosesCode = map(diagnosesList, item => item.code);

    const primaryMedicalDiagnosisSearchCode = $('#primary_medical_diagnosis_search_code').val();
    const diagnosesListDiagnosesCodeWithPrimaryCode = concat(
      diagnosesListDiagnosesCode,
      primaryMedicalDiagnosisSearchCode
    );
    const filter = element => {
      const hasSelected = includes(diagnosesListDiagnosesCodeWithPrimaryCode, element.diagnosis_code);
      // const filterNotSecondaryDiagnosis = element.secondary_diagnosis_flag == "N";
      // return hasSelected || filterNotSecondaryDiagnosis;
      return hasSelected;
    };
    return reject(diagnosis_codes, filter);
  };

  const getdiagnosesList = () => diagnosesList;

  const handleAutoCompleteSelect = (uiItem, index) => {
    const diagnosesList = getdiagnosesList();
    const diagnosis = diagnosesList[index];

    const updatedDiagnosis = {
      ...diagnosis,
      text:          uiItem.label,
      id:            uiItem.value,
      code:          uiItem.diagnosis_code,
      description:   uiItem.diagnosis_name,
      flag:          uiItem.secondary_diagnosis_flag,
      free:          uiItem.free_text_excuse_permitted,
      article_id:    get(uiItem, 'article.article_id', '') || '',
      article_title: get(uiItem, 'article.title', '') || '',
      index
    };

    replaceItemAtIndex({ index, updatedItem: updatedDiagnosis });
  };

  return (
    <div id="secondary_diagnosis_section">
      <h2 className="mds_h2_up">
        <label htmlFor="SECONDARY DIAGNOSIS">Secondary Diagnosis</label>
      </h2>
      <div>
        {diagnosesList.map((diagnosis, index) => (
          <div key={diagnosis.diagnosisKey}>
            <SecondaryDiagnosisRow
              uniqueKeyAllSelectedSecondaryDiagnoses={uniqueKeyAllSelectedSecondaryDiagnoses}
              diagnosis={diagnosis}
              index={index}
              onChange={handleChangeInput}
              onAutoCompleteSelect={handleAutoCompleteSelect}
              onClickRemove={handleClickRemove}
              onClickClear={handleClickClear}
              showMinus={showMinus}
              filterAutoCompleteListProposals={filterAutoCompleteListProposals}
            />
          </div>
        ))}
        {showPlus && <Plus onClick={handleClickAdd} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  diagnosesList: secondaryDiagnosesListSelector(state),
  tasConfig:     secondaryDiagnosesConfigSelector(ownProps)(state)
});

const mapDispatchToProps = {
  addNewItem:         addNewItemToSecondaryDiagnosesList,
  removeItemAtIndex:  removeItemAtIndexFromSecondaryDiagnosesList,
  replaceItemAtIndex: replaceItemAtIndexInSecondaryDiagnosesList
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryDiagnosesContainer);
