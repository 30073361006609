import React, { useContext } from 'react';
import {
  Button,
  DialogActions,
  Spinner,
  makeStyles
} from '@fluentui/react-components';
import { I18n } from '@td/shared_utils';
import { FormikContext } from '../contexts';

const useStyles = makeStyles({
  actionButton: {
    marginTop: '20px'
  },
  actionSpinner: {
    marginLeft: '10px'
  }
});

const ModalActions = () => {
  const { handleSubmit, cancelInProgress } = useContext(FormikContext);
  const fluentStyles = useStyles();

  return (
    <DialogActions>
      <Button
        appearance="primary"
        disabled={cancelInProgress}
        onClick={handleSubmit}
        className={fluentStyles.actionButton}
      >
        <I18n scope="consultation.cancel.modal.submit"/>
        {cancelInProgress && <Spinner size="tiny" className={fluentStyles.actionSpinner} />}
      </Button>
    </DialogActions>
  );
};

export default ModalActions;
