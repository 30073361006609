import { get, map, indexOf } from 'lodash';

import { VERIFICATION_STEP_KEY } from '../constants';

const getConsultNavigation = () => {
  const getState = get(window, 'td.store.getState');
  if (typeof getState === 'function') {
    return get(getState(), 'consultNavigation');
  }
};

const verificationIndex = () => {
  const nav = getConsultNavigation() || {};
  const itemsKeys = map(nav.items, 'key');

  return indexOf(itemsKeys, VERIFICATION_STEP_KEY);
};

const currentIndex = () => {
  const nav = getConsultNavigation() || {};
  return nav.selectedIndex;
};

const passedVerificationStep = () => {
  const verificationStepIndex = verificationIndex();
  return verificationStepIndex > 0 && verificationStepIndex < currentIndex();
};

const currentNavItemKey = () => {
  const nav = getConsultNavigation() || {};
  const navItems = get(nav, 'items', []);
  const currentNavItem = navItems[currentIndex()] || {};
  return currentNavItem.key
};

export {
  getConsultNavigation,
  passedVerificationStep,
  currentNavItemKey
};
