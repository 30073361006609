exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._30xOzMlbkOH1AXHW9FXHXu {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 214, 10, 0.03);\n  border: 0.125rem dashed #D87F00;\n  border-radius: 0;\n  padding: 0.1875rem 0.25rem;\n  box-sizing: border-box; }\n  ._30xOzMlbkOH1AXHW9FXHXu ._3rEn7Ai1NPLgOchZutdrt8 {\n    font-size: 0.625rem;\n    font-weight: 400;\n    line-height: 0.875rem;\n    color: #A25E00; }\n  ._30xOzMlbkOH1AXHW9FXHXu ._3SvEBAXz9_vdqre9WPw8gs {\n    font-size: 0.5rem;\n    font-weight: 400;\n    line-height: 0.625rem;\n    color: #A25E00;\n    margin: 0.25rem 0 0 0; }\n", ""]);

// exports
exports.locals = {
	"recommendedWorkingHoursEvent": "_30xOzMlbkOH1AXHW9FXHXu",
	"recommendedWorkingHoursEvent": "_30xOzMlbkOH1AXHW9FXHXu",
	"recommendedWorkingHoursEventTitle": "_3rEn7Ai1NPLgOchZutdrt8",
	"recommendedWorkingHoursEventTitle": "_3rEn7Ai1NPLgOchZutdrt8",
	"recommendedWorkingHoursEventTimeRange": "_3SvEBAXz9_vdqre9WPw8gs",
	"recommendedWorkingHoursEventTimeRange": "_3SvEBAXz9_vdqre9WPw8gs"
};