import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './styles/shortcuts.scss';

export const ShortcutsList = ({ list, removeDiagnosis, handleDiagnoseSelect, selectedDx }) => {
  const transformedList = list.map(({ value, nm, zcode, ...diagnosis }) => ({
    diagnosisCodeId: value,
    diagnosisName:   nm,
    diagnosisCode:   zcode,
    active:          true,
    ...diagnosis
  }));

  const selectShortcut = data => {
    // remove selected diagnosis
    const selectedDxCode = _.get(selectedDx, '[0].diagnosisCode', null);
    removeDiagnosis({ diagnosisCode: selectedDxCode });

    handleDiagnoseSelect(data);
  };

  return (
    list.length > 0 && (
      <div className="shortcuts-wrapper">
        <div className="shortcuts-label">Shortcuts</div>

        <div className="shortcuts-list">
          {transformedList.map(diagnosis => (
            <div
              className="shortcuts-list-item"
              key={diagnosis.diagnosisCode}
              onClick={() => selectShortcut(diagnosis)}
            >
              {diagnosis.diagnosisCode} {diagnosis.diagnosisName}
            </div>
          ))}
        </div>
      </div>
    )
  );
};

ShortcutsList.displayName = 'ShortcutsList';

ShortcutsList.propTypes = {
  list: PropTypes.array.isRequired
};
