import { get, includes, chain, find, isNil, thru } from 'lodash';

export const secondaryDiagnosesListSelector = state =>
  state.secondaryDiagnoses.secondaryDiagnoses.secondaryDiagnosesList;

export const secondaryDiagnosesConfigSelector = ({ componentName, forTabs }) => state => {
  try {
    const consultNavigationItems = state.consultNavigation.items;

    const tasConfig = chain(consultNavigationItems)
      .find(item => includes(forTabs, item.key))
      .get('components')
      .thru(json_string => {
        if (isNil(json_string)) {
          return { [componentName]: { meta: {} } };
        } else {
          return JSON.parse(json_string);
        }
      }) // "{"secondaryDeagnoses":{"display":true, meta: {"minItems":0,"maxItems":11} }}"
      .get(componentName)
      .value(); // {display: true, meta: { minItems: 0, maxItems: 11 } }
    return tasConfig;
  } catch (e) {
    return {};
  }
};
