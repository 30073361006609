import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@td/shared_utils';

const UnavailableMember = ({ onCancelClick, customText = 'member_unavailable' }) => (
  <div>
    <h1 className="auto-dialer-title-text text-center">
      <I18n scope="autodialer.call_interface.call_complete" text={customText} />
    </h1>
    <button className="button auto-dialer-button" onClick={onCancelClick}>
      <I18n scope="autodialer.call_interface.call_complete" text="cancel_consult" />
    </button>
  </div>
);

UnavailableMember.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  customText:    PropTypes.string
};

export default UnavailableMember;
