import { connect } from 'react-redux';
import SurveyTextfield from '../components/survey-textfield';
import { updateMentalStatusExamInputAction } from '../redux/actions';

const mapStateToProps = state => ({
  mentalHealthCachedState: state.mentalStatusExam
});

const mapDispatchToProps = dispatch => ({
  updateMentalStatusExamInput: (questionCode, value) => {
    dispatch(updateMentalStatusExamInputAction(questionCode, value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTextfield);
