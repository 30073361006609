import { createRequestActionTypes } from '@td/utils';

export const CHECK_NETWORK_TEST_NEEDED = createRequestActionTypes(
  'sidebar',
  'CHECK_NETWORK_TEST_NEEDED'
);

export const NETWORK_TEST_START = 'network-test/NETWORK_TEST_START';
export const NETWORK_TEST_FAIL = 'network-test/NETWORK_TEST_FAIL';
export const NETWORK_TEST_CANCEL = 'network-test/NETWORK_TEST_CANCEL';
export const NETWORK_TEST_SUCCESS = 'network-test/NETWORK_TEST_SUCCESS';
export const NETWORK_TEST_COMPLETE = 'network-test/NETWORK_TEST_COMPLETE';
export const NETWORK_TEST_PROLONGED = 'network-test/NETWORK_TEST_PROLONGED';

export const UPDATE_NETWORK_TEST_SETTINGS = 'network-test/UPDATE_NETWORK_TEST_SETTINGS';

export const PERFORM_CONNECTIVITY_CHECK = createRequestActionTypes(
  'network-test',
  'PERFORM_CONNECTIVITY_CHECK'
);
export const PERFORM_QUALITY_CHECK = createRequestActionTypes(
  'network-test',
  'PERFORM_QUALITY_CHECK'
);

export const LOG_BANDWIDTH_TEST_RESULTS = createRequestActionTypes(
  'network-test',
  'LOG_BANDWIDTH_TEST_RESULTS'
);
