import React, { Fragments } from 'react';
import Minus from '../../../Minus';
import Clear from '../../../Clear';
import { genereateName, prepareSelector } from '../../../../utils/base';

const Row = ({ index, showMinus, diagnosis, onClickRemove, onChangeInput, onClickClear }) => {
  const renderControlElement = () => {
    if (showMinus) {
      return <Minus onClick={() => onClickRemove(index)} />;
    } else {
      return <Clear onClick={() => onClickClear(index)} />;
    }
  };

  return (
    <div className="mds_base_container_next_row">
      <div className="mds_col_2_flex">
        <input
          data-url="/medical_diagnosis_codes/diagnosis_search"
          type="text"
          name={genereateName('code')}
          id={prepareSelector(diagnosis, 'secondary_medical_diagnosis_search_code')}
          className="js_mds_row_1_code"
          value={diagnosis.code}
          placeholder="Search diagnosis codes"
          onChange={e => onChangeInput('code', e.target.value, index)}
        />
      </div>
      <div className="mds_col_6_flex">
        <input
          data-url="/medical_diagnosis_codes/diagnosis_search"
          type="text"
          name={genereateName('name')}
          id={prepareSelector(diagnosis, 'secondary_medical_diagnosis_search_name')}
          className="js_mds_row_1_description"
          value={diagnosis.description}
          placeholder="Search descriptions"
          onChange={e => onChangeInput('description', e.target.value, index)}
        />
      </div>
      <div className="mds_col_1_flex">
        <div className="mds_col_1_flex">{renderControlElement()}</div>
      </div>
    </div>
  );
};

export default Row;
