import React, { Fragment, useState } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const ProvinceField = (
  {
    label,
    styles,
    name = 'healthCardProvince',
    id = 'healthCardProvince',
    errors,
    options,
    homeStateProvince,
    stateMismatchDisclaimer,
    values
  }) => {
  return (
    <Fragment>
      <label className={styles.fieldLabel} htmlFor={id}>{label}</label>
      <Field
        name={name}
        id={id}
        className={cx(styles.selectField, errors[name] ? styles.errorField : '')}
        component="select"
      >
        {options.map(option => (
          <option
            value={option.value}
            key={option.value}
            disabled={option.disabled}
          >
            {option.text}
          </option>
        ))}
      </Field>
      {
        errors[name] &&
        <span className={styles.errorMessage} data-testid="province-error">
          {errors[name]}
        </span>
      }
      {
        (values.healthCardProvince && (homeStateProvince !== values.healthCardProvince)) &&
        <span className={styles.errorMessage} data-testid="state-province-mismatch-disclaimer">
          {stateMismatchDisclaimer}
        </span>
      }
    </Fragment>
  );
}

ProvinceField.propTypes = {
  label: PropTypes.string,
  styles: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.string,
  errors: PropTypes.shape({
    healthCardProvince: PropTypes.string
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }))
};
