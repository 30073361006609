import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './checkbox.scss';

// TODO get rid of list-style-type: circle, needed for a COVID19 card 1434
export const Checkbox = ({ cachedAnswer, choice, answerConfig, surveyNamingKey }) => {
  const { value, onChangeCallback } = answerConfig;

  useEffect(() => {
    setImmediate(() => {
      if (cachedAnswer) {
        onChangeCallback({ [choice.answerCode]: cachedAnswer === 'Y' });
      }
    });
  }, []);

  return (
    <ul style={{ display: 'flex' }}>
      <li>
        <input
          type="hidden"
          name={`${surveyNamingKey}[surveys][][sections][][questions][][answers][][answer_code]`}
          value={choice.answerCode}
        />
        <input
          name={`${surveyNamingKey}[surveys][][sections][][questions][][answers][][value]`}
          value="Y"
          checked={value}
          onChange={event => {
            onChangeCallback({ [choice.answerCode]: event.target.checked });
          }}
          type="checkbox"
        />
        {!value ? (
          <input
            type="hidden"
            name={`${surveyNamingKey}[surveys][][sections][][questions][][answers][][value]`}
            value="N"
          />
        ) : null}
      </li>
      <li>
        <div className="temporary-css-override-survey-checkbox" dangerouslySetInnerHTML={{ __html: choice.answer }} />
      </li>
    </ul>
  );
};

Checkbox.propTypes = {
  choice: PropTypes.shape({
    answer:     PropTypes.string,
    answerCode: PropTypes.string
  }),
  answerConfig: PropTypes.shape({
    value:            PropTypes.boolean,
    onChangeCallback: PropTypes.func
  }),
  surveyNamingKey: PropTypes.string,
  cachedAnswer:    PropTypes.string
};
