import React, { useEffect } from 'react';
import {
  tryToggleContinueButton,
  wasEhrTabReviewedBefore,
  isAnyLabTestCheckboxPresent,
  selectEhrConfirmReviewCheckbox,
  hideMentalHealthNote,
  hideLabTestsNote,
  isMentalHealthSectionPresent,
  mentalHealthPatientType
} from '../../../ehr-utils';
import { I18n } from '@td/shared_utils';

const EHRConfirmReviewCheckbox = ({ i18nKey }) => {
  useEffect(() => {
    if (wasEhrTabReviewedBefore()) {
      selectEhrConfirmReviewCheckbox();

      hideMentalHealthNote();
    }

    if (!isAnyLabTestCheckboxPresent()) {
      hideLabTestsNote();
    }

    if (isMentalHealthSectionPresent()) {
      $('label[for="ehr_confirm_review_checkbox"]').text(
        `I have reviewed this patient's Health Record and confirm there is consent to treat this ${mentalHealthPatientType()}`
      );
    }

    tryToggleContinueButton();
  }, []);

  return (
    <div className="ehr_confirm_review_checkbox_container">
      <input type="checkbox" id="ehr_confirm_review_checkbox" onClick={tryToggleContinueButton} />
      <label htmlFor="ehr_confirm_review_checkbox">
        <I18n scope="consultations" text={i18nKey} />
      </label>
    </div>
  );
};

export default EHRConfirmReviewCheckbox;
