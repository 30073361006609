import React from 'react';
import PropTypes from 'prop-types';
import { I18n, translateMarkupString } from '@td/shared_utils';

const DialingProvider = ({ callCount = 1, memberName }) => (
  <div>
    <h1 className="auto-dialer-title-text text-center">
      {translateMarkupString(
        'autodialer.call_interface.call_in_progress',
        'on_call_with_name',
        { span: <span /> },
        { name: memberName }
      )}
    </h1>
    <p className="text-center">
      <I18n scope="autodialer.call_interface.call_in_progress" text="system_dialing_phone" />
    </p>
    <div className="redial-container">
      <div className="auto-dialer-loader-small" />
      <div className="ml-12 redial-text">
        <I18n scope="autodialer.call_interface.dialing" text="dialing_provider" />
      </div>
    </div>
    <p className="text-center">
      <I18n scope="autodialer.call_interface.call_in_progress" text="connected_via_web" />
    </p>
  </div>
);

DialingProvider.propTypes = {
  callCount:  PropTypes.number,
  memberName: PropTypes.string.isRequired
};

export default DialingProvider;
