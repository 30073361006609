import React from 'react';
import PropTypes from 'prop-types';
import CallDetailsItem from '../call-details-item';
import ParticipantStatus from '../participant-status';

const ParticipantItem = ({ label, participant, status, visitType }) => (
  <CallDetailsItem
    label={`${label}:`}
    value={
      <React.Fragment>
        <div data-testid="call-details-participant-item-name">{participant.name}</div>

        {status && <ParticipantStatus {...status} visitType={visitType} />}
      </React.Fragment>
    }
  />
);

ParticipantItem.propTypes = {
  label:       PropTypes.string.isRequired,
  participant: PropTypes.object.isRequired,
  status:      PropTypes.object,
  visitType:   PropTypes.string.isRequired
};

export default ParticipantItem;
