exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3sRFC9zC8A4JeJuTZevGr6 {\n  display: grid;\n  grid-template-columns: 55% 45%;\n  width: 100%;\n  margin-bottom: 8px;\n  font-size: 12px;\n  color: #244861;\n  white-space: nowrap; }\n\n._1lmwn27DSCjJ97aCFKyx5r {\n  display: flex;\n  font-weight: bold; }\n  ._1lmwn27DSCjJ97aCFKyx5r div:nth-child(1) {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap; }\n\n._2yZ54OTb95ly-1Fc8Jf0Qz {\n  display: flex;\n  justify-content: space-between; }\n  @media (max-width: 350px) {\n    ._2yZ54OTb95ly-1Fc8Jf0Qz {\n      justify-content: flex-end; } }\n  @media (max-width: 350px) {\n    ._2yZ54OTb95ly-1Fc8Jf0Qz ._3OO2fRkwT8Pq_lrFIQHAi0 {\n      display: none; } }\n  ._2yZ54OTb95ly-1Fc8Jf0Qz .HVSD1HyYxNpDVXWSkbh5W {\n    text-align: right; }\n", ""]);

// exports
exports.locals = {
	"pipwDetailContainer": "_3sRFC9zC8A4JeJuTZevGr6",
	"pipwDetailContainer": "_3sRFC9zC8A4JeJuTZevGr6",
	"pipwName": "_1lmwn27DSCjJ97aCFKyx5r",
	"pipwName": "_1lmwn27DSCjJ97aCFKyx5r",
	"pipwInfo": "_2yZ54OTb95ly-1Fc8Jf0Qz",
	"pipwInfo": "_2yZ54OTb95ly-1Fc8Jf0Qz",
	"pipwAge": "_3OO2fRkwT8Pq_lrFIQHAi0",
	"pipwAge": "_3OO2fRkwT8Pq_lrFIQHAi0",
	"pipwMrn": "HVSD1HyYxNpDVXWSkbh5W",
	"pipwMrn": "HVSD1HyYxNpDVXWSkbh5W"
};