import { get, join } from 'lodash';
import { from, of, concat } from 'rxjs';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { mergeMap, switchMap, map, takeUntil, debounceTime, startWith, catchError } from 'rxjs/operators';
import { fetchOrigin$ } from 'app/lib/utils';
import { buildSigInstructions, prescriptionToFormObject } from 'medication_service_ui';
import isEmpty from 'lodash/isEmpty';
import { providerConsultationsQuickRxPath } from 'app/config/paths';
import { authToken, teladocApi } from '@td/api';

import { success, error as errorNotify } from 'app/notifications/actions';

import { erxEnabled, actionTypes as settingsActionTypes } from 'medication_service_ui/dist/settings';

import {
  FETCH_QUICK_RX_BY_PROVIDER,
  FETCH_QUICK_RX_BY_PROVIDER_CANCEL,
  SAVE_QUICK_RX_BY_PROVIDER,
  SAVE_QUICK_RX_BY_PROVIDER_SUCCESS,
  SAVE_QUICK_RX_BY_PROVIDER_FAIL,
  REMOVE_QUICK_RX_BY_PROVIDER,
  FETCH_MEDICATION_INFO,
  FETCH_MEDICATION_INFO_CANCEL,
  FETCH_MEDICATION_FORM,
  FETCH_MEDICATION_FORM_CANCEL,
  FETCH_COMMON_DOSAGE,
  FETCH_COMMON_DOSAGE_CANCEL,
  FETCH_MEDICATION_BY_SPECIALITY,
  FETCH_MEDICATION_BY_SPECIALITY_CANCEL,
  FETCH_PRESCRIPTION_CODE,
  FETCH_PRESCRIPTION_CODE_CANCEL,
  SUBMIT_PRESCRIPTIONS_POST_CONSULT,
  SUBMIT_PRESCRIPTIONS_POST_CONSULT_SUCCESS,
  SUBMIT_PRESCRIPTION_FORM,
  SUBMIT_PRESCRIPTION_FORM_SUCCESS,
  RESET_PRESCRIPTION_FORM,
  CONFIRM_PENDING_PRESCRIPTIONS_LIST
} from './actionTypes';

import {
  confirmPendingPrescriptionsList,
  fetchQuickRxByProvider,
  fetchQuickRxByProviderStart,
  fetchQuickRxByProviderSuccess,
  fetchQuickRxByProviderFail,
  saveQuickRxByProvider,
  saveQuickRxByProviderStart,
  saveQuickRxByProviderFail,
  saveQuickRxByProviderSuccess,
  removeQuickRxByProviderStart,
  removeQuickRxByProviderSuccess,
  removeQuickRxByProviderFail,
  submitPrescriptionForm,
  submitPrescriptionsPostConsult,
  submitPrescriptionsPostConsultSuccess,
  fetchMedicationInfoStart,
  fetchMedicationInfoSuccess,
  fetchMedicationInfoFail,
  fetchMedicationFormStart,
  fetchMedicationFormSuccess,
  fetchMedicationFormFail,
  fetchCommonDosageStart,
  fetchCommonDosageSuccess,
  fetchCommonDosageFail,
  fetchMedicationBySpecialitySuccess,
  fetchMedicationBySpecialityStart,
  fetchMedicationBySpecialityFail,
  fetchPrescriptionCodeStart,
  fetchPrescriptionCodeSuccess,
  fetchPrescriptionCodeFail,
  submitPrescriptionFormSuccess,
  submitPrescriptionFormFail,
  updateToPendingPrescriptionList,
  removeFromPendingPrescriptionList
} from './actions';

import { fetchClinicalMedications } from '../ClinicalMedication/actions';
import { fetchProviderOrders } from '../ProviderOrder/actions';

const { UPDATE_PHARMACY_PARAMS } = settingsActionTypes;

const fetchQuickRxRequest = consultationId => fetchOrigin$(providerConsultationsQuickRxPath(consultationId));

const makeCommonDosageApiRequest = payload => {
  const { url } = payload;
  return fetchOrigin$(url);
};

const makeMedicationFormApiRequest = payload => {
  const { url } = payload;
  return fetchOrigin$(url);
};

const fetchMedicationInfoRequest = payload => {
  const { url, params } = payload;
  return from(teladocApi.get(url, params, authToken.get()));
};

export const fetchQuickRxByProviderEpic = action$ =>
  action$.pipe(
    ofType(FETCH_QUICK_RX_BY_PROVIDER),
    debounceTime(500),
    mergeMap(action =>
      fetchQuickRxRequest(action.payload.consultationId).pipe(
        map(response => fetchQuickRxByProviderSuccess(response)),
        takeUntil(action$.pipe(ofType(FETCH_QUICK_RX_BY_PROVIDER_CANCEL))),
        startWith(fetchQuickRxByProviderStart()),
        catchError(error => of(fetchQuickRxByProviderFail(error)))
      )
    )
  );

export const fetchMedicationInfoEpic = action$ =>
  action$.pipe(
    ofType(FETCH_MEDICATION_INFO),
    debounceTime(500),
    mergeMap(action =>
      fetchMedicationInfoRequest(action.payload).pipe(
        map(response => fetchMedicationInfoSuccess(response)),
        takeUntil(action$.pipe(ofType(FETCH_MEDICATION_INFO_CANCEL))),
        startWith(fetchMedicationInfoStart()),
        catchError(error => of(fetchMedicationInfoFail(error)))
      )
    )
  );

export const fetchMedicationFormEpic = action$ =>
  action$.pipe(
    ofType(FETCH_MEDICATION_FORM),
    mergeMap(action =>
      makeMedicationFormApiRequest(action.payload).pipe(
        map(response => fetchMedicationFormSuccess(response)),
        takeUntil(action$.pipe(ofType(FETCH_MEDICATION_FORM_CANCEL))),
        startWith(fetchMedicationFormStart()),
        catchError(error => of(fetchMedicationFormFail(error)))
      )
    )
  );

export const fetchCommonDosageEpic = action$ =>
  action$.pipe(
    ofType(FETCH_COMMON_DOSAGE),
    debounceTime(500),
    mergeMap(action =>
      makeCommonDosageApiRequest(action.payload).pipe(
        map(response => {
          if (response.response === null || isEmpty(response.response)) {
            const errorMsg = {
              message: 'Common Dosage not available for Drug',
              status:  404
            };

            return fetchCommonDosageFail(errorMsg);
          }

          return fetchCommonDosageSuccess(response);
        }),
        takeUntil(action$.pipe(ofType(FETCH_COMMON_DOSAGE_CANCEL))),
        startWith(fetchCommonDosageStart()),
        catchError(error => of(fetchCommonDosageFail(error)))
      )
    )
  );

export const fetchMedicationBySpecialityEpic = action$ =>
  action$.pipe(
    ofType(FETCH_MEDICATION_BY_SPECIALITY),
    debounceTime(350),
    mergeMap(action =>
      fetchOrigin$(action.payload.url).pipe(
        map(response => fetchMedicationBySpecialitySuccess(response)),
        takeUntil(action$.pipe(ofType(FETCH_MEDICATION_BY_SPECIALITY_CANCEL))),
        startWith(fetchMedicationBySpecialityStart()),
        catchError(error => of(fetchMedicationBySpecialityFail(error)))
      )
    )
  );

export const fetchPrescriptionCodeEpic = action$ =>
  action$.pipe(
    ofType(FETCH_PRESCRIPTION_CODE),
    debounceTime(350),
    mergeMap(({ payload: formData }) =>
      fetchOrigin$(formData.url).pipe(
        map(response => {
          if (response.response === null) {
            const errorMsg = {
              message: 'ERX service not available',
              status:  503
            };

            return fetchPrescriptionCodeFail(errorMsg);
          }

          return fetchPrescriptionCodeSuccess(response);
        }),
        takeUntil(action$.pipe(ofType(FETCH_PRESCRIPTION_CODE_CANCEL))),
        startWith(fetchPrescriptionCodeStart()),
        catchError(error => of(fetchPrescriptionCodeFail(error)))
      )
    )
  );

const buildRxParams = formData => {
  const refillTypeText = formData.drugRefillType === 'As Needed' ? 'PRN' : formData.drugRefillType;

  const { selectedPayer } = formData;
  const isa_num = get(formData, 'isa_num', '') || get(selectedPayer, 'plan.account.isa_num', '');
  const formulary_status_text = get(selectedPayer, 'formulary_status_text', '');
  const formulary_status = get(selectedPayer, 'formulary_status', '');
  const group_number = get(selectedPayer, 'plan.account.group_number', '');
  const group_name = get(selectedPayer, 'plan.account.group_name', '');
  const pbm_member_id = get(selectedPayer, 'plan.account.member_id', '');
  const pbm_id = get(selectedPayer, 'plan.account.pbm_id', '');
  const pbm_name = get(selectedPayer, 'plan.account.pbm_name', '');
  const processor_id = get(selectedPayer, 'plan.account.processor_id', '');
  const bin = get(selectedPayer, 'plan.account.bin', '');
  const cardholder_id = get(selectedPayer, 'plan.account.cardholder_id', '');
  const cardholder_name = get(selectedPayer, 'plan.account.cardholder_name', '');
  const plan_number = get(selectedPayer, 'plan.account.plan_number', '');
  const plan_name = get(selectedPayer, 'plan.account.plan_name', '');
  const copay_id = get(selectedPayer, 'plan.formulary.copay_id', '');
  const coverage_id = get(selectedPayer, 'plan.formulary.coverage_id', '');
  const formulary_id = get(selectedPayer, 'plan.formulary.formulary_id', '');
  const alternative_id = get(selectedPayer, 'plan.formulary.alternative_id', '');

  return {
    pbm_id,
    pbm_member_id,
    isa_num,
    processor_id,
    bin,
    cardholder_name,
    cardholder_id,
    copay_id,
    coverage_id,
    formulary_id,
    alternative_id,
    formulary_status,
    formulary_status_text,
    group_id:                     group_number,
    group_name,
    payer_name:                   pbm_name,
    drug_nm:                      formData.drugName,
    drug_brand_code:              formData.brandCode,
    drug_mmdc:                    formData.drugForm.mmdc,
    drug_mmdc_text:               formData.drugForm.mmdcDesc,
    drug_ndc_code:                formData.drugNdcCode,
    drug_nm_confirmation:         formData.drugFormText,
    drug_quantity:                formData.qty,
    drug_form:                    formData.potencyUnit.potencyUnitCode,
    drug_form_text:               formData.potencyUnit.ssUnitDesc,
    drug_notes:                   formData.pharmacyNotes,
    drug_substitutions:           formData.substitutionsAllowed,
    drug_refill_type:             refillTypeText,
    drug_refills:                 formData.refills,
    dea_checked:                  formData.deaChecked,
    actively_taking_flg:          formData.activelyTakingFlag,
    drug_csa_level:               '',
    prescription_confirm_checked: formData.confirmedRxChecked,
    sig_text_1:                   formData.sigText1, // Take
    sig_qty:                      formData.sigQty, // 1
    sig_unit:                     formData.sigUnit, // tab(s)
    sig_route:                    formData.sigRoute, // orally
    sig_freq:                     formData.sigFreq, // every 10 minutes
    drug_frequency:               formData.sigFreq, // Duplicate?
    sig_text_4:                   formData.sigText4, // with coffee
    quick_rx_id:                  formData.quickRxid,
    add_quick_rx:                 formData.addToQuickRx,
    del_quick_rx:                 formData.removeFromQuickRx,
    acknowledgements:             formData.acknowledgements,
    timestamp:                    Date.now(),
    drug_instructions:            buildSigInstructions(formData)
  };
};

const parseSureScriptsErrors = errors => ({
  ssError: join(errors, ' '),
  message: 'Unable to save prescription'
});

const buildFormularyParams = formData => {
  const { selectedPayer } = formData;
  const isa_num = get(formData, 'isa_num', '') || get(selectedPayer, 'plan.account.isa_num', '');
  const formulary_status_text = get(selectedPayer, 'formulary_status_text', '');
  const formulary_status = get(selectedPayer, 'formulary_status', '');
  const group_number = get(selectedPayer, 'plan.account.group_number', '');
  const group_name = get(selectedPayer, 'plan.account.group_name', '');
  const pbm_member_id = get(selectedPayer, 'plan.account.member_id', '');
  const pbm_id = get(selectedPayer, 'plan.account.pbm_id', '');
  const pbm_name = get(selectedPayer, 'plan.account.pbm_name', '');
  const processor_id = get(selectedPayer, 'plan.account.processor_id', '');
  const bin = get(selectedPayer, 'plan.account.bin', '');
  const cardholder_id = get(selectedPayer, 'plan.account.cardholder_id', '');
  const cardholder_name = get(selectedPayer, 'plan.account.cardholder_name', '');
  const plan_number = get(selectedPayer, 'plan.account.plan_number', '');
  const plan_name = get(selectedPayer, 'plan.account.plan_name', '');
  const copay_id = get(selectedPayer, 'plan.formulary.copay_id', '');
  const coverage_id = get(selectedPayer, 'plan.formulary.coverage_id', '');
  const formulary_id = get(selectedPayer, 'plan.formulary.formulary_id', '');
  const alternative_id = get(selectedPayer, 'plan.formulary.alternative_id', '');

  return {
    copay_id,
    coverage_id,
    formulary_id,
    alternative_id,
    pbm_id,
    pbm_member_id,
    cardholder_name,
    cardholder_id,
    pbm_name,
    group_id: group_number,
    group_name,
    isa_num,
    processor_id,
    bin,
    formulary_status,
    formulary_status_text
  };
};

const parseRefillType = formData => (formData.drugRefillType === 'As Needed' ? 'PRN' : formData.drugRefillType);

const buildPrescriptionParams = (formData, state$) => {
  const {
    settings: {
      memberParams: { erxPatientId },
      consultationParams: { consultationId },
      providerParams: { erxUserId, erxUserToken },
      pharmacyParams: { ncpdpId }
    }
  } = state$.value;

  return {
    drug_substitutions:    formData.substitutionsAllowed,
    note_to_pharmacist:    formData.pharmacyNotes,
    sig_text_1:            formData.sigText1,
    sig_qty:               formData.sigQty,
    sig_unit:              formData.sigUnit,
    sig_route:             formData.sigRoute,
    sig_freq:              formData.sigFreq,
    sig_text_4:            formData.sigText4,
    sig_is_manual_entry:   formData.manualEntryMode,
    drug_name:             formData.drugName,
    drug_quantity:         formData.qty,
    drug_days_supply:      formData.daysSupply || '',
    drug_form:             formData.potencyUnitCode || formData.potencyUnit.potencyUnitCode,
    pharmacy_id:           ncpdpId,
    drug_brand_code:       formData.brandCode,
    drug_mmdc:             formData.drugForm.mmdc,
    drug_route:            formData.drugForm.productRouteDesc,
    drug_strength:         formData.drugForm.productStrength,
    drug_form_form:        formData.drugForm.form,
    drug_id:               formData.drugId,
    drug_type_code:        formData.drugTypeCode,
    drug_ndc_code:         formData.drugNdcCode,
    drug_price:            formData.relativeCost,
    patient_id:            erxPatientId,
    prescriber_id:         erxUserId,
    partner_id:            erxUserId,
    prescriber_token:      erxUserToken,
    drug_refill_type:      parseRefillType(formData),
    drug_refills:          formData.refills,
    show_ophthalmic_alert: formData.showOphthalmicAlert,
    consultation:          { consultation_id: consultationId },
    rtpb_message_id:         formData.rtpbMessageId
  };
};

const buildNewRxParams = (formData, state$) => {
  const formulary = buildFormularyParams(formData);
  const overrides = formData.overrides;
  const initialDrug = formData.initialDrug;
  const erxParams = buildPrescriptionParams(formData, state$);

  return {
    prescription: {
      formulary,
      overrides,
      ...(!isEmpty(initialDrug) && { initial_drug: initialDrug }),
      ...erxParams
    }
  };
};

const postPrescriptionsPostConsult = (pendingPrescriptions, { authorizationToken, consultationId, countryCode }) => {
  const url = `/consultations/${consultationId}/add_prescriptions`;
  const body = {
    consultation: {
      medications: {}
    },
    country_code: countryCode
  };

  pendingPrescriptions.forEach(
    (prescription, index) => (body.consultation.medications[index] = prescriptionToFormObject(prescription))
  );

  return ajax({
    url,
    body,
    method:  'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': authorizationToken,
      ...window.TELADOC_ENVELOP
    }
  });
};

const postPrescriptionForm = (formData, state$, { consultationId }) => {
  const params = buildNewRxParams(formData, state$);
  const url = `/v4/consultations/${consultationId}/submit_erx`;
  return from(teladocApi.post(url, params, authToken.get()));
};

const shouldSubmitRxOnConsultComplete = settings => settings.submitRxOnConsultComplete || false;

export const confirmPendingPrescriptionsListEpic = (action$, state$) =>
  action$.pipe(
    ofType(CONFIRM_PENDING_PRESCRIPTIONS_LIST),
    debounceTime(100),
    mergeMap(({ payload: { postConsult } }) => {
      const { pendingPrescriptions, settings } = state$.value;
      let prescriptionsToSubmit = [];
      let quickRxToSave = [];

      if (postConsult) {
        prescriptionsToSubmit = [submitPrescriptionsPostConsult(pendingPrescriptions)];
      } else if (erxEnabled(settings) && !shouldSubmitRxOnConsultComplete(settings)) {
        prescriptionsToSubmit = pendingPrescriptions
          .filter(prescription => !prescription.pendingSave)
          .map(prescription => submitPrescriptionForm(prescription));
      }

      quickRxToSave = pendingPrescriptions
        .filter(
          prescription =>
            prescription.addToQuickRx && !prescription.quickRxId && prescription.saveQuickRxFailed !== true
        )
        .map(prescription => saveQuickRxByProvider(prescription));

      return of(...prescriptionsToSubmit.concat(quickRxToSave));
    })
  );

export const refetchPrescriptionFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_PRESCRIPTION_FORM_SUCCESS),
    debounceTime(30),
    mergeMap(() => {
      const {
        settings: {
          memberParams: { memberId },
          consultationParams: { consultationId }
        }
      } = state$.value;

      return of(
        success('Prescription added successfully!'),
        fetchClinicalMedications({ memberId }),
        fetchProviderOrders({ consultationId })
      );
    })
  );

export const submitPrescriptionsPostConsultEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_PRESCRIPTIONS_POST_CONSULT),
    mergeMap(({ payload: pendingPrescriptions }) => {
      const { consultationId, countryCode, authorizationToken } = state$.value.settings.consultationParams;

      return from(
        postPrescriptionsPostConsult(pendingPrescriptions, { authorizationToken, consultationId, countryCode }).pipe(
          mergeMap(response => {
            if (!/2\d+\d+/.test(response.status)) {
              return errorNotify('Submission of prescriptions failed. Please submit a support request');
            }
            return of(submitPrescriptionsPostConsultSuccess(pendingPrescriptions));
          })
        )
      );
    })
  );

export const submitPrescriptionsPostConsultSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_PRESCRIPTIONS_POST_CONSULT_SUCCESS),
    mergeMap(({ payload: pendingPrescriptions }) => {
      const {
        settings: {
          memberParams: { memberId },
          consultationParams: { consultationId }
        }
      } = state$.value;

      return of(
        success('Prescriptions submitted successfully'),
        ...pendingPrescriptions.map(removeFromPendingPrescriptionList),
        fetchClinicalMedications({ memberId }),
        fetchProviderOrders({ consultationId })
      );
    })
  );

export const submitPrescriptionFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_PRESCRIPTION_FORM),
    mergeMap(({ payload: formData }) => {
      const { consultationId } = state$.value.settings.consultationParams;
      const inflightPrescription = Object.assign({}, formData, { pendingSave: true });

      return concat(
        of(updateToPendingPrescriptionList(inflightPrescription)),
        from(postPrescriptionForm(formData, state$, { consultationId })).pipe(
          mergeMap(response => {
            const {
              data: {
                erx_submission: { errors: errorsResponse }
              }
            } = response;

            if (errorsResponse.length > 0) {
              const errorMsg = parseSureScriptsErrors(errorsResponse);
              inflightPrescription.pendingSave = false;
              return of(
                submitPrescriptionFormFail(errorMsg, {
                  drugNdcCode: formData.drugNdcCode,
                  errors:      errorsResponse
                }),
                updateToPendingPrescriptionList(inflightPrescription)
              );
            }

            return from([
              submitPrescriptionFormSuccess(formData),
              removeFromPendingPrescriptionList(formData),
              { type: RESET_PRESCRIPTION_FORM }
            ]);
          }),
          catchError(error => {
            const erxErrors = get(error, 'response.data.erx_submission.errors');
            inflightPrescription.pendingSave = false;
            const submissionError = erxErrors ? parseSureScriptsErrors(erxErrors) : error.message;

            return of(
              updateToPendingPrescriptionList(inflightPrescription),
              submitPrescriptionFormFail(submissionError, {
                drugNdcCode: formData.drugNdcCode,
                errors:      erxErrors || [submissionError]
              }),
              errorNotify('Failed to add prescription. Please check the data and resubmit.')
            );
          })
        )
      );
    })
  );

const postQuickRxForm = (formData, { consultationId, authorizationToken }) => {
  const { addToQuickRx } = formData;

  if (!addToQuickRx) {
    return of('No-ops');
  }

  const params = buildRxParams(formData);
  const url = `/consultations/${consultationId}/submit_quick_rx`;

  return ajax({
    url,
    body:    params,
    method:  'POST',
    headers: {
      Accept:         'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authorizationToken,
      ...window.TELADOC_ENVELOP
    }
  });
};

export const postQuickRxFormSuccessEpic = (action$, state$) =>
  // Todo
  // Please add Rx catchError or Js catch to this epic
  action$.pipe(
    ofType(SAVE_QUICK_RX_BY_PROVIDER_SUCCESS),
    mergeMap(({ payload: { pendingPrescription, quickRxItem } }) => {
      const {
        settings: {
          consultationParams: { consultationId }
        },
        pendingPrescriptions
      } = state$.value;
      const messages = [fetchQuickRxByProvider({ consultationId })];
      const updatedPrescription = pendingPrescriptions.find(existing => existing.uid === pendingPrescription.uid);

      if (updatedPrescription) {
        messages.push(success(`${updatedPrescription.drugName} added to Quick RX successfully`));
        messages.push(
          updateToPendingPrescriptionList(Object.assign({}, updatedPrescription, { quickRxId: quickRxItem.id }))
        );
      }

      return of(...messages);
    })
  );

export const postQuickRxFormFailEpic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_QUICK_RX_BY_PROVIDER_FAIL),
    mergeMap(({ payload: { pendingPrescription } }) => {
      const {
        pendingPrescriptions,
        settings: {
          consultationParams: { consultationId }
        }
      } = state$.value;
      const messages = [fetchQuickRxByProvider({ consultationId })];

      const updatedPrescription = pendingPrescriptions.find(existing => existing.uid === pendingPrescription.uid);
      if (updatedPrescription) {
        messages.push(
          updateToPendingPrescriptionList(Object.assign({}, updatedPrescription, { saveQuickRxFailed: true }))
        );
      }
      return of(...messages);
    })
  );

export const postQuickRxFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_QUICK_RX_BY_PROVIDER),
    switchMap(({ payload: formData }) => {
      const { consultationId, authorizationToken } = state$.value.settings.consultationParams;

      return concat(
        // Todo
        // saveQuickRxByProviderStart is not used
        // Please remove it after double check
        of(saveQuickRxByProviderStart(formData)),
        from(postQuickRxForm(formData, { authorizationToken, consultationId })).pipe(
          switchMap(request => {
            let saveAttemptResponse;
            const { response } = request;

            if (!response || !response.quick_rx) {
              saveAttemptResponse = saveQuickRxByProviderFail({
                error:               request.response,
                pendingPrescription: formData
              });
            } else {
              saveAttemptResponse = saveQuickRxByProviderSuccess({
                pendingPrescription: formData,
                quickRxItem:         response.quick_rx
              });
            }

            return of(saveAttemptResponse);
          }),
          catchError(error => of(saveQuickRxByProviderFail({ error, pendingPrescription: formData })))
        )
      );
    })
  );

const postDeleteQuickRxForm = (quickRxItem, { consultationId, authorizationToken }) => {
  const url = `/consultations/${consultationId}/del_quick_rx/${quickRxItem.id}`;

  return ajax({
    url,
    body:    null,
    method:  'DELETE',
    headers: {
      Accept:         'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authorizationToken,
      ...window.TELADOC_ENVELOP
    }
  });
};

export const cacheInterviewOnPrescriptionEpic = action$ =>
  action$.pipe(
    ofType(...pendingPrescriptionActions),
    debounceTime(30),
    switchMap(() => {
      cacheInterview();

      return [];
    })
  );

export const postRemoveQuickRxFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(REMOVE_QUICK_RX_BY_PROVIDER),
    debounceTime(500),
    switchMap(({ payload: quickRxItem }) => {
      const { consultationId, authorizationToken } = state$.value.settings.consultationParams;
      return concat(
        of(removeQuickRxByProviderStart(quickRxItem)),
        from(postDeleteQuickRxForm(quickRxItem, { authorizationToken, consultationId })).pipe(
          switchMap(response => {
            if (response.response === false) {
              const errorMsg = {
                message: 'Unable to remove quick Rx',
                status:  503
              };

              return of(removeQuickRxByProviderFail(errorMsg));
            }

            return of(removeQuickRxByProviderSuccess(response), fetchQuickRxByProvider({ consultationId }));
          }),
          catchError(error => of(removeQuickRxByProviderFail(error)))
        )
      );
    })
  );

export const updatePharmacyParamsEpic = action$ =>
  action$.pipe(
    ofType(UPDATE_PHARMACY_PARAMS),
    mergeMap(() => of(confirmPendingPrescriptionsList()))
  );
