import React from 'react';
import { BannerPropType } from '../../prop-types';
import classNames from 'classnames/bind';
import { CloseIcon } from '../../icons';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const Banner = React.memo(({ message, type, onClose }) => (
  <div
    data-testid="banner"
    className={cx('banner', `${type}Banner`)}
  >
    {message}

    <button
      data-testid="banner-close-button"
      className={styles.bannerCloseButton}
      onClick={onClose}
    >
      <CloseIcon
        fill="#444443"
        width={12}
        height={12}
      />
    </button>
  </div>
));

Banner.displayName = 'Banner';
Banner.propTypes = BannerPropType;

export default Banner;
