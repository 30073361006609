import { connect } from 'react-redux';

import NetworkTestError from './network-test-error';

export const mapStateToProps = state => ({
  error: state.networkTest.status.error
});

export default connect(
  mapStateToProps
)(NetworkTestError);
