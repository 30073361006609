import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProviderClockInOutButton from './provider-clock-in-out-button-container';

import { clockInProviderInit, enableRequsetMode } from '../actions';

export const ProviderClockInButtonContainer = ({ text, onClick, disableButton }) => (
  <ProviderClockInOutButton className="clock-in-button" text={text} onClick={onClick} disableButton={disableButton} />
);

ProviderClockInButtonContainer.propTypes = {
  text:          PropTypes.string,
  disableButton: PropTypes.bool
};

ProviderClockInButtonContainer.defaultProps = {
  text: 'Clock In'
};

export default ProviderClockInButtonContainer;
