import { connect } from 'react-redux';
import get from 'lodash/get';

import getProviderClockedIn from '../../../Provider/selectors/getProviderClockedIn';
import ProviderClockInOutStatus from './provider-clock-in-out-status';
import getProviderParams from '../../../Provider/selectors/getProviderParams';
import ProviderClockInType from '../../../Provider/enums/provider-clock-in-type';
import getProviderCanClockInForType from '../../../Provider/selectors/getProviderCanClockInForType';

export const mapStateToProps = state => ({
  clockInType: get(getProviderParams(state), ['clockInType']),
  videoClockInAllowed: getProviderCanClockInForType(ProviderClockInType.VIDEO)(state),
  phoneClockInAllowed: getProviderCanClockInForType(ProviderClockInType.PHONE)(state),
  clockedIn: getProviderClockedIn(state)
});

export default connect(mapStateToProps)(ProviderClockInOutStatus);
