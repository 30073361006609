import { isEmpty } from 'lodash';
// helper methods used in consult history components

export const validateSearch = (startDate, endDate, memberSelected, consultSelected, searchDayLimit) => {
  let validSearch;
  let validStartDate;
  let validEndDate;
  if (consultSelected) {
    validSearch = true;
    validStartDate = true;
    validEndDate = true;
  } else if (startDate === null || endDate === null) {
    validSearch = false;
    validStartDate = startDate !== null;
    validEndDate = endDate !== null;
  } else if (!memberSelected && Math.floor((Date.parse(endDate) - Date.parse(startDate)) / 86400000) > searchDayLimit) {
    validSearch = false;
    validStartDate = false;
    validEndDate = false;
  } else {
    validSearch = true;
    validStartDate = true;
    validEndDate = true;
  }
  return {
    validSearch,
    validStartDate,
    validEndDate
  };
};

export const MAX_TIME_RANGE_FOR_EXPORT = 8204349645;

export function camelizeKeys(object) {
  const snakeToCamel = str =>
    str.replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );

  const updatedObject = {};
  Object.keys(object).map(key => {
    const newKey = snakeToCamel(key);
    updatedObject[newKey] = object[key];
  });
  return updatedObject;
}

export const formatExportData = exportData => {
  const formattedData = exportData.map(consultation => {
    const consultationType = consultation.isFollowup ? 'Follow-up' : 'Initital';

    return {
      'Consult ID':   consultation.consultationId,
      'Consult Type':
        consultation.specialtyCode === 'BEHAVHEALTH'
          ? `${consultationType} ${consultation.communicationMethod}`
          : consultation.communicationMethod,
      'Consult Start Time': consultation.actualStartDate,
      'Consult Stop Time':  consultation.actualEndDate,
      'Member ID':          consultation.memberId,
      'Pharmacy Address':   consultation.pharmacyAddress
    };
  });
  return formattedData;
};

export const formatDate = date => {
  if (date instanceof Date) {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  } else {
    return date;
  }
};

export const lastCharacterIsInteger = value =>
  Number.isInteger(parseInt(value.charAt(value.length - 1))) || value === '';

export const labIconClassName = consultation => {
  if (isEmpty(consultation.labReviewStatus.isReviewed)) {
    return '';
  } else {
    return consultation.labReviewStatus.isReviewed === 'Y' ? 'lab_reviewed_icon' : 'lab_review_required_icon';
  }
};

export const labReviewRequired = consultation => {
  if (consultation.labReviewStatus.isReviewed && consultation.labReviewStatus.isCritical) {
    if (!(consultation.labReviewStatus.isReviewed === 'Y') && consultation.labReviewStatus.isCritical === 'Y') {
      return true;
    }
  } else {
    return false;
  }
};
