import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import EXTERNAL_ACCOUNTS_QUERY from '../queries/external_accounts.graphql';
import { useProviderEligibility } from './provider-eligibility-context';
import { useExternalCalendarAccountAlerts } from '../../../../external-calendar-account-alerts/contexts/external-calendar-account-alerts-context';

export const ExternalAccountsContext = React.createContext({});

const ExternalAccountsProvider = ({ children, providerId }) => {
  const [externalAccounts, setExternalAccounts] = useState([]);
  const { isProviderEligibleForExternalCalendars } = useProviderEligibility();
  const { fetchExternalCalendarAccountAlerts } = useExternalCalendarAccountAlerts();

  const [fetchExternalAccounts] = useLazyQuery(
    EXTERNAL_ACCOUNTS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: `${providerId}`
      },
      onCompleted: ({ providerProfile: { externalCalendarAccounts } }) => {
        fetchExternalCalendarAccountAlerts();
        setExternalAccounts(externalCalendarAccounts);
      },
      pollInterval: moment.duration(20, 'seconds').asMilliseconds(),
      notifyOnNetworkStatusChange: true
    }
  );

  useEffect(() => {
    if (!isProviderEligibleForExternalCalendars) {
      return;
    }

    fetchExternalAccounts();
  }, [fetchExternalAccounts, isProviderEligibleForExternalCalendars]);

  const value = useMemo(() => ({
    externalAccounts,
    fetchExternalAccounts,
  }), [externalAccounts, fetchExternalAccounts]);

  return (
    <ExternalAccountsContext.Provider value={value}>
      {children}
    </ExternalAccountsContext.Provider>
  );
};

export const useExternalAccounts = () => {
  const context = useContext(ExternalAccountsContext);

  if (context === undefined) {
    throw new Error('useExternalAccounts must be used within a ExternalAccountsProvider');
  }

  return context;
};

ExternalAccountsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  providerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
};

export default ExternalAccountsProvider;
