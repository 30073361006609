import React from 'react';
import PropTypes from 'prop-types';

import { CategoryListItem } from './CategoryListItem';
import { useQuickDxFavorites } from '../utils';

export const AutoCompleteCategoryList = ({ label, diagnoses, selectedDiagnoses, isPrimaryDiagnosis, ...props }) => {
  const { selectedDxCodes } = useQuickDxFavorites();
  const isAlreadySelected = dxCode => selectedDxCodes.list.includes(dxCode);

  return (
    Boolean(diagnoses.length) && (
      <div>
        <div className="category-label">{label}</div>

        {diagnoses.map(diagnose => (
          <CategoryListItem
            key={diagnose.diagnosisCode}
            diagnose={diagnose}
            isAlreadySelected={Boolean(isAlreadySelected(diagnose.diagnosisCode))}
            isPrimaryDiagnosisField={isPrimaryDiagnosis}
            {...props}
          />
        ))}
      </div>
    )
  );
};

AutoCompleteCategoryList.displayName = 'AutoCompleteCategoryList';

AutoCompleteCategoryList.propTypes = {
  label:     PropTypes.string.isRequired,
  diagnoses: PropTypes.arrayOf(
    PropTypes.shape({
      code:        PropTypes.string,
      description: PropTypes.string,
      isFavorite:  PropTypes.bool
    })
  )
};
