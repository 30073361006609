import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { translateMarkupString } from '@td/shared_utils';

const CALL_LENGTH_LABEL_INTERVAL_TIME = 1000; // 1s

export const useCallDuration = () => {
  const [callLengthLabel, setCallLengthLabel] = useState('');
  const [callJoinTime, setCallJoinTime] = useState(null);

  const callDurationJoinCallHandler = (time = Date.now()) => {
    if (callJoinTime !== null) return;

    setCallJoinTime(time);
  };

  const callDurationEndCallHandler = () => {
    setCallJoinTime(null);
    setCallLengthLabel('');
  };

  const getFormattedMessage = useCallback(() => {
    const formatTimeValue = value => (value < 10 ? `0${value}` : value);

    const callSecondsLength = moment.utc(new Date()).diff(callJoinTime) / 1000;
    const callLengthMinutes = Math.floor(callSecondsLength / 60);
    const callLengthSeconds = Math.round(callSecondsLength - callLengthMinutes * 60);

    const formattedCallLength = `${formatTimeValue(callLengthMinutes)}:${formatTimeValue(callLengthSeconds)}`;

    const formattedMessage = translateMarkupString(
      'autodialer.call_interface.call_in_progress',
      'call_duration_with_time',
      {},
      { time: formattedCallLength }
    );

    return {
      formattedMessage,
      formattedCallLength
    };
  }, [callJoinTime]);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (!callJoinTime) return;

      const message = getFormattedMessage();

      setCallLengthLabel(message.formattedMessage);
    }, CALL_LENGTH_LABEL_INTERVAL_TIME);

    return () => clearInterval(timeInterval);
  }, [getFormattedMessage, setCallLengthLabel, callJoinTime]);

  return {
    callJoinTime,
    callLengthLabel,
    callDurationJoinCallHandler,
    callDurationEndCallHandler,
    getFormattedMessage
  };
};
