import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Badge = ({ value, isOpen }) => <span className={classnames('badge', isOpen && 'openBadge')}>{value}</span>;

Badge.propTypes = {
  value: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default Badge;
