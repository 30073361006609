import React from "react";
import PropTypes from "prop-types";
import searchIcon from "../../assets/images/search_icon.svg";
import crossIcon from "../../assets/images/cross_icon.svg";

const MedicationNameField = ({
  children,
  error,
  handleFocus,
  handleClear,
  value,
  label,
}) => (
  <div className="new-medication-field">
    <label
      className="new-medication-field__label"
      htmlFor="drugName"
    >
      {label}
    </label>
    <div className="new-medication-field__input--container">
      <div className="medication-autosuggest">
        {children}
        <button
          className="medication-autosuggest__button"
          onClick={value ? handleClear : handleFocus}
        >
          <img src={value ? crossIcon : searchIcon} alt="Search icon"/>
        </button>
      </div>
      {error && <div className="alert alert-danger error-msg">{error[0]}</div>}
    </div>
  </div>
);

MedicationNameField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  error: PropTypes.array,
  handleFocus: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default MedicationNameField;
