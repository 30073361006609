import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ConsultationReschedulingTeladocModal from '../../../consultation-rescheduling/components/consultation-rescheduling-teladoc-modal';

const RescheduleAction = ({ consultationId, memberId, providerId, websdkRescheduleToggle=false, isTimeSlotOnly }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Fragment>
      <a
        id="consult_queues"
        className="reschedule requested_and_proposed_visits consultation_queue_list"
        title={'Reschedule'}
        onClick={() => setShowModal(true)}
      >
        Reschedule
      </a>
      {showModal &&
        <ConsultationReschedulingTeladocModal
          consultationId={consultationId}
          memberId={memberId}
          providerId={providerId}
          isWebsdkRescheduleToggle={websdkRescheduleToggle}
          isTimeSlotOnly={isTimeSlotOnly}
          onClose={() => setShowModal(false)}
        />
      }
    </Fragment>
  );
};

RescheduleAction.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default RescheduleAction;
