import React from 'react';

const Clear = ({ onClick }) => (
  <div className="mds_clear">
    <a className="mds_center" onClick={onClick}>
      Clear
    </a>
  </div>
);

export default Clear;
