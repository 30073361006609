import React from 'react';
import DataTablePagination from '../data-table/DataTablePagination';
import { usePatientList } from './PatientListProvider';

const PatientListPagination = () => {
  const { hasNextPage, handlePaginationChange, loading } = usePatientList();

  return (
    <DataTablePagination
      name={I18n.t('patient_case_load_mgmt_mvp.patient_list.view_more_patients')}
      onClick={handlePaginationChange}
      loading={loading}
      hasNextPage={hasNextPage}
    />
  );
};

export default PatientListPagination;
