import { currentNavItemKey } from './consultNavigation';

export default (reason, notes) => {
  const consultForm = document.getElementById('consult_form');
  const formData = new FormData(consultForm);

  if (!formData.has('consultation[reason_cd]')) {
    formData.append('consultation[reason_cd]', reason);
  }

  if (!formData.has('consultation[nav_item_key]')) {
    formData.append('consultation[nav_item_key]', currentNavItemKey());
  }

  if (!formData.has('consultation[notes]')) {
    formData.append('consultation[notes]', notes);
  }

  if (!formData.has('save_data_on_cancel')) {
    formData.append('save_data_on_cancel', 'true');
  }

  return formData;
};
