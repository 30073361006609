import React from 'react';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';
import './styles.scss';

const ConfirmationDialog = ({ visible, title, text, onConfirm, onClose }) => (
  <AriaModal
    dialogClass="confirmation-dialog"
    dialogId="subjective-tab-dialog"
    titleText={text}
    onExit={onClose}
    underlayClickExits={false}
    mounted={visible}
    verticallyCenter
  >
    <div className="modal-body">
      {
        title ?
          <h1>
            {title}
          </h1> : null
      }
      <p>
        {text}
      </p>
    </div>
    <footer className="modal-footer">
      <button className="confirmation-dialog-button" onClick={onClose}>
        Cancel
      </button>
      <button
        className="confirmation-dialog-button confirm"
        onClick={() => {
          onConfirm();
          onClose();
        }}
      >
        Continue
      </button>
    </footer>
  </AriaModal>
);

ConfirmationDialog.propTypes = {
  visible:   PropTypes.bool.isRequired,
  title:     PropTypes.string,
  text:      PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose:   PropTypes.func.isRequired
};

export default ConfirmationDialog;
