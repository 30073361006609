import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import PROVIDER_PROFILE_QUERY from '../queries/provider_profile.graphql';

export const ProviderEligibilityContext = React.createContext({});

const ProviderEligibilityProvider = ({ children, providerId }) => {
  const { data, loading } = useQuery(PROVIDER_PROFILE_QUERY, {
    variables: {
      id: `${providerId}`
    }
  });

  const value = useMemo(() => ({
    isProviderEligibleForExternalCalendars: get(data, 'providerProfile.eligibleForExternalCalendars')
  }), [data]);

  if (loading) {
    return null;
  }

  return (
    <ProviderEligibilityContext.Provider value={value}>
      {children}
    </ProviderEligibilityContext.Provider>
  );
};

export const useProviderEligibility = () => {
  const context = useContext(ProviderEligibilityContext);

  if (context === undefined) {
    throw new Error('useProviderEligibility must be used within a ProviderEligibilityProvider');
  }

  return context;
};

ProviderEligibilityProvider.propTypes = {
  children: PropTypes.node.isRequired,
  providerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
};

export default ProviderEligibilityProvider;
