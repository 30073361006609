import React from "react";
import PropTypes from "prop-types";
import dropdownIcon from "../../assets/images/dropdown_icon.svg";

const MedicationDirectionField = ({
  name,
  onChange,
  defaultOption,
  value,
  error,
  options,
  label,
}) => (
  <div className="new-medication-field">
    <label
      className="new-medication-field__label"
      htmlFor={name}
    >
      {label}
    </label>
    <div className="new-medication-field__input--container">
      <div className="medication-select-status">
        <select name={name} value={value} onChange={onChange} className="">
          <option value="">{defaultOption}</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
        <img
          className="medication-select-status__icon"
          src={dropdownIcon}
          alt="Dropdown icon"
        />
      </div>
      {error && <div className="alert alert-danger error-msg">{error[0]}</div>}
    </div>
  </div>
);

MedicationDirectionField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.array,
  value: PropTypes.string,
  options: PropTypes.array,
  defaultOption: PropTypes.string,
};

export default MedicationDirectionField;
