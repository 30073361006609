import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addParticipant, removeParticipant } from '../actions';
import Participant from './conferenceParticipant';
import { INITIAL_CALL_STATE, CONFERENCE_NEEDS_CONFIRMATION } from '../lib/states';
import { hasInboundCaller } from '../lib/participants';

const LANGUAGE_LINE = {
  person: {
    admin:          false,
    id:             -1,
    name:           'Language Line',
    type:           'Help Line',
    title:          'Assistance',
    phoneNumbers:   ['+18552213665'],
    selectedNumber: '+18552213665'
  },
  conferenceCall: {
    ...INITIAL_CALL_STATE,
    mutable: false
  }
};

const HEARING_IMPAIRED_LINE = {
  person: {
    admin:          false,
    id:             -2,
    name:           'Hearing Impaired Relay',
    type:           'Help Line',
    title:          'Assistance',
    phoneNumbers:   ['+18008778973'],
    selectedNumber: '+18008778973'
  },
  conferenceCall: {
    ...INITIAL_CALL_STATE,
    mutable: false
  }
};

export class ConferenceParticipantsListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageLine:        false,
      hearingImpairedLine: false
    };
  }

  toggleLanguageLine = () => {
    this.toggleParticipant(LANGUAGE_LINE, this.state.languageLine);
    this.setState({ languageLine: !this.state.languageLine });
  }

  toggleHearingImpairedLine = () => {
    this.toggleParticipant(HEARING_IMPAIRED_LINE, this.state.hearingImpairedLine);
    this.setState({ hearingImpairedLine: !this.state.hearingImpairedLine });
  }

  toggleParticipant = (participant, toRemove) => {
    if (toRemove) {
      this.props.removeParticipant(participant.person.id, participant.person.name);
    } else {
      this.props.addParticipant({
        ...participant,
        consultationId: this.props.consultationId,
        conferenceId:   this.props.conferenceId
      });
    }
  }

  renderParticipants = () => {
    let { participants } = this.props;

    // Hide member attendees if the InboundCaller is present
    // The reason is that we only show inbound caller if the inbound caller is present
    // Displaying both inbound caller and member is quite confused for providers
    if (hasInboundCaller(participants)) {
      participants = participants
        .filter(({ person }) => person.type !== 'Member');
    }

    return participants.map(participant => {
      const { person: { id, type } } = participant;

      return (<Participant
        {...participant}
        key={`${type}-${id}`}
        conferenceStatus={this.props.conferenceStatus}
        dialMember={this.props.dialMember}
      />);
    });
  }

  renderHelpLines = () => {
    if (this.props.conferenceStatus === CONFERENCE_NEEDS_CONFIRMATION) {
      return null;
    }

    return (
      <div className="conference-help-lines">
        <div className="language-line">
          <input
            type="checkbox"
            name="language-check-box"
            className="language-check-box"
            checked={this.state.languageLine}
            onChange={this.toggleLanguageLine}
          />
          <label htmlFor="language-check-box">
            I need a language line. (Access Code: 23234)
          </label>
        </div>
        <div className="hearing-impaired-line">
          <input
            type="checkbox"
            className="hearing-impaired-check-box"
            name="hearing-impaired-check-box"
            checked={this.state.hearingImpairedLine}
            onChange={this.toggleHearingImpairedLine}
          />
          <label htmlFor="hearing-impaired-check-box">
            I need the hearing impaired relay service.
          </label>
        </div>
      </div>
    );
  }

  render = () =>
    <div className="conference_participant_list">
      { this.renderParticipants() }
      { this.renderHelpLines() }
    </div>
}

ConferenceParticipantsListContainer.propTypes = {
  participants:      PropTypes.array.isRequired,
  conferenceStatus:  PropTypes.string.isRequired,
  removeParticipant: PropTypes.func.isRequired,
  addParticipant:    PropTypes.func.isRequired,
  consultationId:    PropTypes.string.isRequired,
  conferenceId:      PropTypes.number,
  dialMember:        PropTypes.bool
};

export default connect(
  ({ conference }) => ({
    participants:     conference.conferenceParticipants,
    conferenceStatus: conference.status,
    conferenceId:     conference.id,
    dialMember:       conference.dialMember
  }),
  { addParticipant, removeParticipant }
)(ConferenceParticipantsListContainer);
