import React from 'react';
import PropTypes from 'prop-types';
import { buildSigInstructions, drugFormStr, OverridesList } from 'medication_service_ui';
import '../styles.scss';

const getInteractor = ({ type, ...rest }) => {
  switch (type) {
    case 'DDI': {
      const {
        drug1: { activeIngredients, category, drugDesc }
      } = rest;
      const interactor = drugDesc || activeIngredients || category;
      return interactor;
    }

    case 'Allergy': {
      const { relevant_drug, specified_category, candidate_drug, candidate_category } = rest;

      const interactor = relevant_drug || specified_category || candidate_drug || candidate_category;

      return interactor;
    }
    default:
      return '';
  }
};

const decorateWithFakeId = overrides =>
  overrides.map((override, index) =>
    Object.assign({}, override, {
      id:         index,
      interactor: getInteractor(override),
      reason:     override.override_reason,
      type:       override.type.toLowerCase()
    })
  );

class PendingOrderListItem extends React.Component {
  componentDidMount() {
    document
      .querySelectorAll('.truncated-text')
      .forEach(el => el.addEventListener('mouseenter', event => this.truncatedTextExpansion(event)));
  }

  truncatedTextExpansion(event) {
    const text = event.target;
    const $text = $(event.target);
    if (text.offsetHeight < text.scrollHeight && !$text.attr('title')) $text.attr('title', $text.text());
  }

  onRemoveOrder = () => {
    if (window.confirm('Are you sure?')) {
      const { pendingOrder, onRemoveOrder } = this.props;

      onRemoveOrder(pendingOrder);
    }
  };

  onEditOrder = () => {
    this.props.onEditOrder(this.props.pendingOrder);
  };

  render() {
    const {
      pendingOrder,
      displayOptions: { showRequester, showActions }
    } = this.props;
    const { overrides = [] } = pendingOrder;

    return (
      <tbody>
        <tr>
          <td>
            {`${pendingOrder.drugName} ${drugFormStr(pendingOrder)}`}
            {!!overrides.length && <OverridesList overrides={decorateWithFakeId(overrides)} />}
          </td>
          <td>{pendingOrder.qty}</td>
          <td className="instructions">
            <div className="truncated-text">{buildSigInstructions(pendingOrder)}</div>
          </td>
          {showRequester && pendingOrder.requestedBy ? (
            <td>{`${pendingOrder.requestedBy} (${pendingOrder.createdByType})`}</td>
          ) : showRequester ? (
            <td>&nbsp;</td>
          ) : null}
          <td>Pending</td>
          {showActions && (
            <td>
              <button type="button" className="action-btn" onClick={this.onEditOrder}>Edit</button>
              <button type="button" className="action-btn" onClick={this.onRemoveOrder}>Remove</button>
            </td>)
          }
        </tr>
      </tbody>
    );
  }
}

PendingOrderListItem.propTypes = {
  pendingOrder:   PropTypes.object.isRequired,
  onRemoveOrder:  PropTypes.func.isRequired,
  onEditOrder:    PropTypes.func.isRequired,
  displayOptions: PropTypes.object.isRequired
};

export default PendingOrderListItem;
