import React, { useState, useMemo } from 'react';
import { MdFilterList } from 'react-icons/md';
import PatientListFiltersForm from './components/PatientListFiltersForm';
import Badge from './components/Badge';

import { usePatientList } from '../PatientListProvider';
import { getFiltersCount } from './helpers';

const PatientListFilters = () => {
  const { handleFiltersChange, filters } = usePatientList();

  const [isOpen, setIsOpen] = useState(false);

  const toggleFilters = () => setIsOpen(!isOpen);

  const filtersCount = useMemo(() => getFiltersCount(filters), [filters]);

  const handleOnSubmit = data => {
    toggleFilters();
    handleFiltersChange(data);
  };

  return (
    <div className="filters-container">
      <div className="filters-toggle">
        <a className="filters-button" onClick={toggleFilters}>
          <MdFilterList size={16} />
          {isOpen
            ? I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.hide_filter')
            : I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.show_filter')}
          <Badge value={filtersCount} isOpen={isOpen} />
        </a>
      </div>
      {isOpen && <PatientListFiltersForm onSubmit={handleOnSubmit} />}
    </div>
  );
};

export default PatientListFilters;
