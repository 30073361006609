import React from 'react';
import PersistentAccordion from '../PersistentAccordion';
import RequiredActionsTable from './RequiredActionsTable';
import useFetchActionNeededConsultations from '../../hooks/useFetchActionNeededConsultations';

const RequiredActionsTableContainer = () => {
  const {
    loading,
    data: { reviewNeededItems = [] }
  } = useFetchActionNeededConsultations();

  return (
    <PersistentAccordion
      storageKey="td-consult-queues-required-actions-table-open"
      title={I18n.t('dashboard.consult_queues.required_actions_table.title')}
      loading={loading}
    >
      <RequiredActionsTable data={reviewNeededItems} />
    </PersistentAccordion>
  );
};

export default RequiredActionsTableContainer;
