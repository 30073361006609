/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { translate } from '@td/shared_utils';
import { isEmpty, find } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { scheduleTypeMethods, scheduleTypes } from '../../my-schedule/constants';

import Loader from '../../components/Loader';

import calendarIcon from './../../assets/images/event_details/calendar.svg';
import clockIcon from './../../assets/images/event_details/clock.svg';
import languageIcon from './../../assets/images/event_details/language.svg';
import disconnectedIcon from './../../assets/images/event_details/disconnected.svg';
import emailIcon from './../../assets/images/event_details/email.svg';
import { AlertCircleIcon } from '../../my-schedule/icons';
import { eventTypeCodes } from '../constants';

import AWM_CONSULTATION_DATA_QUERY from '../../my-schedule/queries/awm-consultation-data.graphql';

const TRANSLATION_SCOPE = 'my_schedule.event_details';

const EventDetails = ({
  event,
  onReschedule,
  onStartVisit,
  onEditEvent,
  onApprove,
  onCancel,
  canEditSchedule,
  calendarIncrement
}) => {
  const {
    providerId,
    schedulingTimeSpanDays: reschedulingIntervals,
    consultationId,
    eventName: memberName,
    eventDescription: reasonForVisit,
    consultLcnsFlag,
    title,
    start,
    startDateTime,
    endDateTime,
    typeCode,
    eventDuration,
    afterPosition,
    topPosition: eventTopPosition,
    allDayEventFlg,
    externalEvent
  } = event;

  const isAWMEvent = typeCode === scheduleTypes.PROVSCHEDEVENT_AWM.eventTypeCode;

  const [consultationData, setConsultationData] = useState({});
  const [fetchConsultationData, { loading }] = useLazyQuery(AWM_CONSULTATION_DATA_QUERY, {
    variables:   { consultationId },
    fetchPolicy: 'network-only',
    onCompleted: ({ consultation }) => setConsultationData((consultation || {}))
  });

  useEffect(() => {
    if (calendarIncrement >= 2 && isAWMEvent && consultationId) {
      fetchConsultationData();
    }
  }, []);

  const {
    communicationMethod,
    isNewVisit,
    consultStatus,
    messageRoomId,
    supportNewMessaging,
    memberId,
    allowStartInterval,
    canReschedule,
    reasonForVisit: visitReasonObj
  } = consultationData;

  const isFuture = moment(startDateTime).isAfter(moment());
  const isPersonalEvent = typeCode === scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode;
  const isRecommendedWorkingHours = typeCode === eventTypeCodes.RECOMMENDED_WORKING_HOURS;
  const topPosition = allDayEventFlg ? 3 : eventTopPosition || 3;
  const isSameDay = moment(startDateTime).date() === moment(endDateTime).date();
  const showFromAndToDates = !(allDayEventFlg && isSameDay);
  const formattedFromDate = moment(startDateTime).format('dddd, MMMM D');
  const timeFrom = moment(startDateTime).format('h:mm A');
  const formattedToDate = moment(endDateTime).format('dddd, MMMM D');
  const timeTo = moment(endDateTime).format('h:mm A');
  const timezoneName = moment.tz.guess();
  const timezone = moment.tz(timezoneName).zoneAbbr();
  const dayInWeek = moment(start).day();
  const isLeft = dayInWeek > 3 && dayInWeek !== 7;

  let isCanceled = false;
  let isCompleted = false;
  let eventStatusIcon;
  let statusTypeMethod;
  if (consultStatus) {
    statusTypeMethod = find(scheduleTypeMethods, ['status', consultStatus]);
    if (statusTypeMethod) {
      isCanceled = statusTypeMethod.status === scheduleTypeMethods.canceled.status;
      isCompleted = statusTypeMethod.status === scheduleTypeMethods.completed.status;
    }
  } else {
    isCanceled = isAWMEvent && consultLcnsFlag;
    isCompleted = isAWMEvent && !isCanceled && moment(endDateTime).isBefore(moment());
  }
  const isCanceledOrCompleted = isCanceled || isCompleted;

  if (isCanceledOrCompleted) {
    statusTypeMethod = statusTypeMethod || (isCanceled ? scheduleTypeMethods.canceled : scheduleTypeMethods.completed);
    eventStatusIcon = require(`../../assets/images/${statusTypeMethod.icon}.svg`);
  }

  const rescheduleProps = {
    memberId,
    providerId,
    reschedulingIntervals
  };

  const eventStatusLabel = isCanceledOrCompleted ? statusTypeMethod.short : null;
  const canSendMessage =
    supportNewMessaging && messageRoomId && moment(startDateTime).subtract(24, 'hours') < moment();
  const commTypeMethod = scheduleTypeMethods[String(communicationMethod).toLowerCase()];
  const eventCommunicationMethodIcon = communicationMethod
      ? require(`./../../assets/images/event_details/${commTypeMethod.icon}.svg`)
      : null;
  const { visitReasonUft: visitReasonDetails } = visitReasonObj || {};
  let visitType;
  if (!isEmpty(consultationData)) {
    visitType = isNewVisit ? 'visit_type_initial' : 'visit_type_ongoing';
  }
  const canStart = isAWMEvent && !isCanceledOrCompleted && moment(startDateTime).subtract(allowStartInterval, 'seconds') < moment();

  return (
    <div
      className={`eventDetailsWrapper ${typeCode} ${isLeft && 'left'} after-position-${afterPosition}`}
      style={{ top: `${topPosition}px` }}
    >
      { loading ?
        <div style={{ minHeight: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loader />
        </div>
      : (
        <div className={`content ${typeCode}${externalEvent ? ' external-event' : ''} ${eventStatusLabel}`}>
          <div className="header">
            {isNewVisit && !isCanceledOrCompleted && <span className="newLabel">{translate(null, TRANSLATION_SCOPE, 'new_label')}</span>}
            {eventStatusIcon && <img className="eventStatusIcon" src={eventStatusIcon} alt={statusTypeMethod.label} />}
            <h4 className="eventTitle">
              {isRecommendedWorkingHours ? (
                <div style={{ alignItems: 'center' }}>
                  <AlertCircleIcon width={20} height={20} fill="#D87F00" />

                  {translate(null, 'my_schedule.events.recommended_working_hours', 'title')}
                </div>
              ) : (
                <React.Fragment>{isAWMEvent ? memberName : title}</React.Fragment>
              )}
              {isCanceledOrCompleted && <span className="eventStatusLabel">{eventStatusLabel}</span>}
            </h4>
            {eventCommunicationMethodIcon && (
              <div className="communicationMethod">
                <span>{commTypeMethod.label}</span>
                <img className="communicationMethodIcon" src={eventCommunicationMethodIcon} alt={commTypeMethod.label} />
              </div>
            )}
          </div>
          <div className="dateDetails">
            {showFromAndToDates && !isSameDay ? (
              <React.Fragment>
                {
                  externalEvent && externalEvent.isExpired && (
                    <div className="externalEventStatus">
                      <img className="calendarIcon" src={disconnectedIcon} role="presentation" />
                      <div className="externalEventStatus__label">
                        {translate(null, TRANSLATION_SCOPE, 'external_events.status.disconnected')}
                      </div>
                    </div>
                  )
                }
                {
                  externalEvent && (
                    <div className="externalEventEmail">
                      <img className="calendarIcon" src={emailIcon} role="presentation" />
                      <div className="externalEventEmail__label">
                        {externalEvent.email}
                      </div>
                    </div>
                  )
                }
                <div className="eventDateWrapper">
                  <img className="calendarIcon" src={calendarIcon} role="presentation" />
                  <div className="date">
                    {`${translate(null, TRANSLATION_SCOPE, 'from_label')} `}
                    <span>{`${formattedFromDate}${allDayEventFlg ? '' : `, ${timeFrom}`}`}</span>
                  </div>
                </div>
                <div className="eventDateWrapper">
                  <div className="emptyImg" />
                  <div className="date">
                    {`${translate(null, TRANSLATION_SCOPE, 'to_label')} `}
                    <span>{`${formattedToDate}${allDayEventFlg ? '' : `, ${timeTo}`}`}</span>
                  </div>
                </div>
                <div className="eventDurationWrapper">
                  <img className="clockIcon" src={clockIcon} role="presentation" />
                  <div className="duration">
                    {!isRecommendedWorkingHours ? (
                      <React.Fragment>
                        {`${translate(null, TRANSLATION_SCOPE, 'duration_label')} `}
                        <span>
                          {eventDuration.asDays() > 1
                            ? `${Math.ceil(eventDuration.asDays())} ${translate(null, TRANSLATION_SCOPE, 'days')}`
                            : `${eventDuration.asMinutes()} ${translate(null, TRANSLATION_SCOPE, 'minutes_abbrev')}`}
                        </span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {timeFrom} - {timeTo}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="eventDateWrapper">
                  <img className="calendarIcon" src={calendarIcon} role="presentation" />
                  <div className="date">{formattedFromDate}</div>
                </div>
                <div className="eventDurationWrapper">
                  <img className="clockIcon" src={clockIcon} role="presentation" />
                  <div className="duration">
                    {allDayEventFlg ? (
                      translate(null, TRANSLATION_SCOPE, 'all_day')
                    ) : (
                      <React.Fragment>
                        {timeFrom} - {timeTo}
                        {!isRecommendedWorkingHours && (
                          <span>
                            {' '}
                            ({eventDuration.asMinutes()} {translate(null, TRANSLATION_SCOPE, 'minutes_abbrev')})
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}

            {!isRecommendedWorkingHours && (
              <div className="timezoneWrapper">
                <img className="languageIcon" src={languageIcon} role="presentation" />
                <div className="timezone">
                  {translate(null, TRANSLATION_SCOPE, 'time_zone')} <span> {timezone}</span>
                </div>
              </div>
            )}
          </div>

          {isRecommendedWorkingHours && (
            <div className="eventDescription">
              {translate(null, 'my_schedule.events.recommended_working_hours', 'description')}
            </div>
          )}

          {isAWMEvent && (
            <div className="visitDetailsWrapper">
              <h4 className="subtitle">{translate(null, TRANSLATION_SCOPE, 'visit_details')}</h4>
              <div className="visitType">
                {visitType && (
                  <div className="typeDetails">
                    <React.Fragment>
                      {translate(null, TRANSLATION_SCOPE, 'visit_type')}:&nbsp;
                      <span>{translate(null, TRANSLATION_SCOPE, visitType)}</span>
                    </React.Fragment>
                  </div>
                )}
                {canSendMessage && consultationId && (
                  <a href={`/care_team_messages/${messageRoomId}?consultation_id=${consultationId}`} className="linkto">
                    {translate(null, TRANSLATION_SCOPE, 'send_message')}
                  </a>
                )}
              </div>
              <div className="visitReason">
                <div className="visitReasonDetails">
                  <React.Fragment>
                    {translate(null, TRANSLATION_SCOPE, 'reason_details')}: <span>{visitReasonDetails || reasonForVisit}</span>
                  </React.Fragment>
                </div>
                {!isCanceledOrCompleted && consultationId && (
                  <a href={`/consultations/${consultationId}/ehr_preview_modal`} className="preview-patient-ehr">
                    {translate(null, TRANSLATION_SCOPE, 'view_ehr')}
                  </a>
                )}
              </div>
              <div className="visitId">
                <React.Fragment>
                  {translate(null, TRANSLATION_SCOPE, 'visit_id')}:{' '}
                  {isCanceledOrCompleted && consultationId ? (
                    <div className="linkto">
                      <a href={`/history/consultation/${consultationId}`}>{consultationId}</a>
                    </div>
                  ) : (
                    consultationId
                  )}
                </React.Fragment>
              </div>
            </div>
          )}

          {!isCanceledOrCompleted && (
            <div className="eventDetailsActionsWrapper">
              {!isRecommendedWorkingHours ? (
                <React.Fragment>
                  {isPersonalEvent && isFuture && canEditSchedule && !externalEvent && (
                    <button onClick={onEditEvent} className="button primary noMarginBtn" id="editButton">
                      {translate(null, TRANSLATION_SCOPE, 'edit')}
                    </button>
                  )}
                  {canStart && (
                    <button onClick={onStartVisit} className="button primary noMarginBtn" id="startButton">
                      {translate(null, TRANSLATION_SCOPE, 'start_visit')}
                    </button>
                  )}
                  {canReschedule && isFuture && (
                    <button
                      onClick={() => onReschedule({ consultationId, rescheduleProps })}
                      className="button primary marginBtn"
                      id="rescheduleButton"
                      title={translate(null, TRANSLATION_SCOPE, 'reschedule')}
                    >
                      {translate(null, TRANSLATION_SCOPE, 'reschedule')}
                    </button>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <button onClick={onApprove} className="button primary">
                    {translate(null, 'my_schedule.events.recommended_working_hours.actions', 'add')}
                  </button>
                  <button onClick={onCancel} className="button secondary">
                    {translate(null, 'my_schedule.events.recommended_working_hours.actions', 'cancel')}
                  </button>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

EventDetails.propTypes = {
  event: PropTypes.shape({
    start:         PropTypes.instanceOf(Date).isRequired,
    startDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string])
      .isRequired,
    endDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string])
      .isRequired,
    afterPosition:          PropTypes.string,
    topPosition:            PropTypes.number,
    allDayEventFlg:         PropTypes.bool,
    title:                  PropTypes.string.isRequired,
    typeCode:               PropTypes.string.isRequired,
    eventDuration:          PropTypes.object.isRequired,
    providerId:             PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    schedulingTimeSpanDays: PropTypes.number,
    consultationId:         PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    eventName:              PropTypes.string,
    eventDescription:       PropTypes.string,
    consultLcnsFlag:        PropTypes.bool
  }).isRequired,
  onReschedule:      PropTypes.func.isRequired,
  onStartVisit:      PropTypes.func.isRequired,
  onEditEvent:       PropTypes.func.isRequired,
  onApprove:         PropTypes.func,
  onCancel:          PropTypes.func,
  canEditSchedule:   PropTypes.bool,
  calendarIncrement: PropTypes.number
};

export default EventDetails;
