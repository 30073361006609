import { getBrowserTimezone } from '../../patient-list/utils';

const CONSULT_QUEUES_DATE_FORMAT = 'MM/DD LT z';

const CONSULTATION_ALERT_LEVEL_COLORS = {
  white: '#FFFFFF',
  yellow: '#FFFF99',
  orange: '#FAC695',
  red: '#F9C7C6',
  purple: '#e8d2f8'
};

export const formatTime = date => getBrowserTimezone(date, CONSULT_QUEUES_DATE_FORMAT);

export const consultationIsRequested = status => status === 'Requested';

export const getConsultationAlertRowStyle = ({ currentAlertLevelColor }) =>
  currentAlertLevelColor && { backgroundColor: CONSULTATION_ALERT_LEVEL_COLORS[currentAlertLevelColor] };
