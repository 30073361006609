import React from 'react';
import PropTypes from 'prop-types';

const AlertCircleIcon = React.memo(({ width = 16, height = 16, fill = '#000000' }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill={fill} />
    <path
      fill="#FFFFFF"
      d="M9.50781 11.7852L9.08008 7.42578V5.41016H10.8613V7.42578L10.4395 11.7852H9.50781ZM9.15039 14V12.3535H10.7969V14H9.15039Z"
    />
  </svg>
));

AlertCircleIcon.displayName = 'AlertCircleIcon';
AlertCircleIcon.propTypes = {
  width:  PropTypes.number,
  height: PropTypes.number,
  fill:   PropTypes.string
};

export default AlertCircleIcon;
