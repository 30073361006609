import React from 'react';
import { renderToString } from 'react-dom/server';

import ValidationSecondaryDiagnoses from 'app/components/SecondaryDiagnosesContainer/components/ValidationSecondaryDiagnoses';

import store from '../../lib/store';
import { Provider } from 'react-redux';

const updateDxSummary = selectText => {
  $('p#diagnosis').html(selectText);
};
const updateSecondaryDxSummary = () => {
  // TODO: !!! change secondaryDXText

  if (!window.FEATURE_TOGGLES.vpcc_risk_adjustment_v1) {
    const secondaryDXText = $('#interview_secondary_medical_diagnosis_text').val();

    if (secondaryDXText.length > 0) {
      return $('#summary_secondary_diagnosis').html(secondaryDXText);
    } else {
      return $('#summary_secondary_diagnosis').html('None');
    }
  }
};

const diagnosisValidate = () => {
  const validations = [];
  const errors = [];
  const selectText = $('#interview_primary_medical_diagnosis_text').val();
  const medicalDiagnosisId = $('#interview_primary_medical_diagnosis_id').val();
  const secondaryDiagnosis = $('#interview_primary_medical_diagnosis_secondary_diagnosis_flag').val() === 'Y';

  validations.push({
    validator: {
      invalid: !selectText || !medicalDiagnosisId,
      message: 'You must choose a valid diagnosis.'
    },
    errorContainerSelector: '#diagnosis_validation_error',
    validatorKey:           'invalidDiagnosis'
  });

  if (secondaryDiagnosis) {
    errors.push('This Dx can only be used as secondary; you must choose another Dx as primary.');
  }

  if (window.FEATURE_TOGGLES.vpcc_risk_adjustment_v1) {
    const addErrors = message => {
      errors.push(message);
    };

    const validSecondaryDiagnoses = renderToString(
      <Provider store={store}>
        <ValidationSecondaryDiagnoses addErrors={addErrors} primaryDiagnosisId={medicalDiagnosisId} />
      </Provider>
    );
  }

  const invalidValidations = validations.filter(({ validator }) => validator.invalid);

  if (![...invalidValidations, ...errors].length) {
    updateDxSummary(selectText);
    updateSecondaryDxSummary();
  }

  return [...errors, ...validations];
};

export default () => Promise.resolve(diagnosisValidate());

export { diagnosisValidate };
