import React from 'react';
import moment from 'moment';
import { get } from 'lodash';

const PatientListSendMessageAction = ({
  currentConsultationForProvider,
  lastConsultationForCurrentProvider,
  nextConsultationForCurrentProvider
}) => {
  const consultation = currentConsultationForProvider || lastConsultationForCurrentProvider || {};

  const { id: consultationId, messageRoomId, requestOrFollowUpDate, supportNewMessaging } = consultation;
  const isLastConsultationOverSixWeeks = get(lastConsultationForCurrentProvider, 'isLastConsultationOverSixWeeks');
  const isNextConsultLessThan24Hours = requestOrFollowUpDate && moment(requestOrFollowUpDate).subtract(24, 'hours').isBefore(moment());

  if (
    (!messageRoomId && !supportNewMessaging) ||
    (!nextConsultationForCurrentProvider && isLastConsultationOverSixWeeks) ||
    (!isNextConsultLessThan24Hours && isLastConsultationOverSixWeeks) ||
    (!lastConsultationForCurrentProvider && !isNextConsultLessThan24Hours)
  ) return null;

  const finalConsultationId = isNextConsultLessThan24Hours
    ? consultationId
    : lastConsultationForCurrentProvider && lastConsultationForCurrentProvider.id;

  const finalMessageRoomId = isNextConsultLessThan24Hours
    ? messageRoomId
    : lastConsultationForCurrentProvider && lastConsultationForCurrentProvider.messageRoomId;

  return (
    <a href={`/care_team_messages/${finalMessageRoomId}?autolock=true&consultation_id=${finalConsultationId}`}>
      {I18n.t('patient_case_load_mgmt_mvp.patient_list.message_patient')}
    </a>
  );
};

export default PatientListSendMessageAction;
