import 'medication_service_ui/dist/med-service-ui.css';
import 'react-tabs/style/react-tabs.scss';
import $ from 'jquery';
import { set } from 'lodash';
import 'moment-timezone';
import moment from 'moment-timezone';
import highcharts from 'highcharts';
import {
  addErrorsToConsultNavigationStep,
  markConsultNavigationStep,
  changeConsultNavigationStep,
  clearErrorsFromConsultNavigationStep,
  validateConsultNavigationStep,
  consultNavigationNextStep
} from 'app/consult-navigation/actions';
import { actions as settingsActions } from 'medication_service_ui/dist/settings';

import { fetchClinicalAllergies } from 'medication_service_ui';
import consultationInterview from 'app/consultation-interview';
import getClientInstance from 'app/services/apollo-client';
import trackGuidelineClick from './dashboard/track-guideline-click';

// TODO: This has jQuery dependency.
// Using it temporary until we can use Mike's version
import '../../node_modules/toastr/build/toastr.css';
import './notifications/styles.scss';
import '../styles/global/global.scss';

import initialize from './initializer';
import store from './lib/store';
import { $rollout } from './lib/utils';
import * as ehrUtils from '../app/ehr/ehr-utils';
import * as summaryUtils from '../app/summary/summary-utils';

const { updatePharmacyParams, consultationStarted } = settingsActions;

window.moment = moment;
window.Highcharts = highcharts;
window.remedyApiStatus = '';
window.initializeReactApp = initialize;
if (window && store) {
  window.dispatch = store.dispatch;
}

window.td = window.td || {};
window.td.globals = window.td.globals || {};

// Share actions with legacy coffeescripts
window.td.globals.consultationStarted = consultationStarted;
window.td.globals.fetchClinicalAllergies = fetchClinicalAllergies;
window.td.globals.updatePharmacyParams = updatePharmacyParams;
window.td.globals.client = getClientInstance();
window.td.globals.trackGuidelineClick = trackGuidelineClick;
window.td.globals.store = store;
window.td.globals.consultationInterview = consultationInterview;

window.td.store = store;
window.td.markConsultNavigationStep = markConsultNavigationStep;
window.td.addErrorsToConsultNavigationStep = addErrorsToConsultNavigationStep;
window.td.clearErrorsFromConsultNavigationStep = clearErrorsFromConsultNavigationStep;
window.td.changeConsultNavigationStep = changeConsultNavigationStep;
window.td.consultNavigationNextStep = consultNavigationNextStep;
window.td.validateConsultNavigationStep = validateConsultNavigationStep;

set(window, 'td.ehr.utils', ehrUtils);
set(window, 'consultationInterview.summary.utils', summaryUtils);
set(window, '$rollout', $rollout);

$(() => initialize('body', { editable: true }));
