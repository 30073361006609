import { get, isEmpty, keys, pickBy } from 'lodash';
import { connect } from 'react-redux';
import SafetyPlan from '../components/safety-plan';
import { SAFETY_PLAN_SECTION_CD, MENTAL_STATUS_EXAM_CD } from '../constants';
import { updateMentalStatusExamInputAction } from '../redux/actions';

const isRequired = state => {
  if (isEmpty(state.surveys)) {
    return false;
  }

  const sectionPath = ['surveys', MENTAL_STATUS_EXAM_CD, SAFETY_PLAN_SECTION_CD];
  const Q_3_1_answerKeys = keys(pickBy(get(state, [...sectionPath, 'Q_3_1'], null), value => value));
  const Q_3_2_answerKeys = keys(pickBy(get(state, [...sectionPath, 'Q_3_2'], null), value => value));

  return !!Q_3_1_answerKeys.concat(Q_3_2_answerKeys).find(answerKey => answerKey !== 'DENYALL');
};

const mapStateToProps = state => ({
  isRequired: isRequired(state),
  value:      state.mentalStatusExam.safety_plan
});

const mapDispatchToProps = dispatch => ({
  updateMentalStatusExamInput: (questionCode, value) => {
    dispatch(updateMentalStatusExamInputAction(questionCode, value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SafetyPlan);
