import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, map, takeUntil, delay } from 'rxjs/operators';
import { teladocApi, authToken } from '@td/api';
import { createRequestEpic } from '@td/utils';

import { FETCH_PROVIDER_LAST_ACTIVE_TIME, LAST_ACTIVE_TIME_UPDATED } from './action-types';
import { lastActiveTimeUpdated } from './actions';

export const fetchProviderLastActiveTimeEpic = createRequestEpic(FETCH_PROVIDER_LAST_ACTIVE_TIME, ({ action$ }) =>
  of({}).pipe(
    delay(5000),
    switchMap(() => from(teladocApi.get('/v4/user_last_active_time', {}, authToken.get()))),
    takeUntil(action$.pipe(ofType(LAST_ACTIVE_TIME_UPDATED)))
  )
);

export const onFetchProviderLastActiveTimeSuccessEpic = action$ =>
  action$.pipe(
    ofType(FETCH_PROVIDER_LAST_ACTIVE_TIME.SUCCESS),
    map(action => {
      const lastActiveTime = action.payload.last_active_date;

      return lastActiveTimeUpdated(lastActiveTime);
    })
  );

export default combineEpics(fetchProviderLastActiveTimeEpic, onFetchProviderLastActiveTimeSuccessEpic);
