import React from 'react';

export default () => (
  <div>
    <h5 className="network-test-content network-test-header">Unsupported Browser Detected</h5>
    <div className="network-test-content">
      Our performance test confirmed that you are using an unsupported browser or older browser
      version. Because of this{' '}
      <span className="network-test-strong">you have been clocked in for phone only.</span> Please
      use the latest version of Chrome or Safari in order to clock in for video consultations.
    </div>
  </div>
);
