import React from 'react';
import { translate, translateMarkupString } from '@td/shared_utils';

import { useAutoDialer } from '.';
import { ATTENDEE_STATUSES, CONFERENCE_STATUSES } from '../../constants';

export const useCallInProgressProps = ({ setCtaDisabled, showPhoneNumbers }) => {
  const {
    attendeesData,
    memberCallData: { currentStatusCode: memberCallStatus },
    currentAttendeeCallData: { currentStatusCode: currentAttendeeCallStatus },
    memberName,
    disconnectCall,
    joinCall,
    isMicrophoneAllowed,
    callStatus
  } = useAutoDialer();

  // may look scary, but it's just a function
  // that returns function
  const useCallActionHandler = handler => () => {
    setCtaDisabled(true);
    handler();
  };

  const joinedAttendees = attendeesData.filter(attendee => attendee.currentStatusCode === ATTENDEE_STATUSES.JOINED);

  const isMicError =
    !isMicrophoneAllowed &&
    currentAttendeeCallStatus !== ATTENDEE_STATUSES.JOINED &&
    memberCallStatus === ATTENDEE_STATUSES.JOINED;

  const isVendorConnError = callStatus === CONFERENCE_STATUSES.WEBRTC_DEVICE_ERROR;
  const isConfApiError = callStatus === CONFERENCE_STATUSES.BAD_REQUEST;

  const isError = isMicError || isVendorConnError || isConfApiError;

  const disableTransferToPhone =
    showPhoneNumbers || (isError && isMicrophoneAllowed) || memberCallStatus === ATTENDEE_STATUSES.DISCONNECTED;

  const resolveTitle = () => {
    if (memberCallStatus === ATTENDEE_STATUSES.JOINED && currentAttendeeCallStatus === ATTENDEE_STATUSES.JOINED) {
      return translateMarkupString(
        'autodialer.call_interface.call_in_progress',
        'on_call_with_name',
        { span: <span /> },
        { name: memberName }
      );
    }

    if (memberCallStatus === ATTENDEE_STATUSES.JOINED) {
      return translateMarkupString(
        'autodialer.call_interface.call_in_progress',
        'name_in_waiting_room',
        { span: <span /> },
        { name: memberName }
      );
    }

    if (memberCallStatus !== ATTENDEE_STATUSES.JOINED) {
      return translateMarkupString(
        'autodialer.call_interface.call_in_progress',
        'name_has_disconnected',
        { span: <span /> },
        { name: memberName }
      );
    }
  };

  const waitingProps = {
    title:            resolveTitle(),
    buttonCta:        translate(null, 'autodialer.call_interface.call_in_progress', 'join_call_cta'),
    buttonCtaHandler: useCallActionHandler(joinCall),
    joinByPhone:      translate(null, 'misc', 'or'),
    joinByPhoneCta:   translate(null, 'autodialer.call_interface.call_in_progress', 'join_via_phone_cta'),
    phoneQuestion:    translate(null, 'autodialer.call_interface.call_in_progress', 'which_number_call'),
    callCta:          translate(null, 'autodialer.call_interface.call_in_progress', 'call')
  };

  const inProgressProps = {
    title:            resolveTitle(),
    buttonCta:        translate(null, 'autodialer.call_interface.call_in_progress', 'leave_call'),
    buttonCtaHandler: useCallActionHandler(disconnectCall),
    joinByPhone:      translate(null, 'autodialer.call_interface.call_in_progress', 'connected_via_web'),
    joinByPhoneCta:   translate(null, 'autodialer.call_interface.call_in_progress', 'transfer_to_phone_cta'),
    phoneQuestion:    translate(null, 'autodialer.call_interface.call_in_progress', 'which_number_transfer'),
    callCta:          translate(null, 'autodialer.call_interface.call_in_progress', 'transfer')
  };

  const disconnectedProps = {
    ...inProgressProps,
    title: resolveTitle()
  };

  const getComponentProps = () => {
    // This is in case somehow the member and provider statuses get overwritten to null.
    // This should never happen during a call but in case it does, better to make the
    // provider think the member dropped (which is probably true)
    if (!memberCallStatus && !currentAttendeeCallStatus) {
      return { ...inProgressProps, title: '' };
    }

    // Need better logic here, we should still show the waiting props if there is an error, but the 'callStatus'
    // is overloaded right now so we need to check both. I am actually not convinced we need the call status for this conditional
    // it should be possible to refactor it to just use the attendee statuses like below
    if (
      (callStatus === CONFERENCE_STATUSES.STARTING || callStatus === CONFERENCE_STATUSES.BAD_REQUEST) &&
      memberCallStatus === ATTENDEE_STATUSES.JOINED
    ) {
      return waitingProps;
    }

    if (
      callStatus === CONFERENCE_STATUSES.IN_PROGRESS &&
      currentAttendeeCallStatus === ATTENDEE_STATUSES.DISCONNECTED
    ) {
      return waitingProps;
    }

    if (
      currentAttendeeCallStatus === ATTENDEE_STATUSES.READY_TO_DIAL &&
      memberCallStatus !== ATTENDEE_STATUSES.JOINED &&
      joinedAttendees.length > 0
    ) {
      return waitingProps;
    }

    if (memberCallStatus !== ATTENDEE_STATUSES.JOINED) {
      return disconnectedProps;
    }

    if (
      currentAttendeeCallStatus === ATTENDEE_STATUSES.READY_TO_DIAL &&
      memberCallStatus === ATTENDEE_STATUSES.JOINED
    ) {
      return waitingProps;
    }

    if (currentAttendeeCallStatus === ATTENDEE_STATUSES.NOT_REACHED) {
      return waitingProps;
    }

    if (currentAttendeeCallStatus === ATTENDEE_STATUSES.DISCONNECTED && memberCallStatus === ATTENDEE_STATUSES.JOINED) {
      return waitingProps;
    }

    // This seems like a very dangerous default to me, I think this needs to be tightened up
    return inProgressProps;
  };

  const wrapComponentProps = () => {
    if (isMicError) {
      return {
        ...getComponentProps(),
        errorMessage: translate(null, 'autodialer.call_interface.call_in_progress', 'mic_error_message')
      };
    } else if (isVendorConnError) {
      return { ...getComponentProps(), errorMessage: 'Error connecting to Twilio' };
    } else if (isConfApiError) {
      return { ...getComponentProps(), errorMessage: 'Error retrieving call status...' };
    } else {
      return getComponentProps();
    }
  };

  return {
    componentProps: wrapComponentProps(),
    disableTransferToPhone,
    isError
  };
};
