import * as yup from 'yup';

export const validationSchema = yup.object().shape(
  {
    lastConsultationFrom: yup
      .date()
      .typeError(I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.validations.from_date_validation'))
      .when(
        'lastConsultationTo',
        (lastConsultationTo, schema) =>
          lastConsultationTo &&
          schema.required(I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.validations.from_date_validation'))
      ),
    lastConsultationTo: yup
      .date()
      .typeError(I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.validations.to_date_validation'))
      .when(
        'lastConsultationFrom',
        (lastConsultationFrom, schema) =>
          lastConsultationFrom &&
          schema.required(I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.validations.to_date_validation'))
      ),
    nextConsultationFrom: yup
      .date()
      .typeError(I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.validations.from_date_validation'))
      .when(
        'nextConsultationTo',
        (nextConsultationTo, schema) =>
          nextConsultationTo &&
          schema.required(I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.validations.from_date_validation'))
      ),
    nextConsultationTo: yup
      .date()
      .typeError(I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.validations.to_date_validation'))
      .when(
        'nextConsultationFrom',
        (nextConsultationFrom, schema) =>
          nextConsultationFrom &&
          schema.required(I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.validations.to_date_validation'))
      )
  },
  [
    ['lastConsultationFrom', 'lastConsultationTo'],
    ['lastConsultationTo', 'lastConsultationFrom'],
    ['nextConsultationFrom', 'nextConsultationTo'],
    ['nextConsultationTo', 'nextConsultationFrom']
  ]
);
