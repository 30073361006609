import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { translate, translateMarkupString } from '@td/shared_utils';
import ConfirmationModal from '../confirmation-modal';
import styles from './styles.module.scss';
import { ICLOUD_INSTRUCTIONS_URL } from '../../../../constants';

const TRANSLATION_SCOPE = 'my_schedule.modals.icloud_explanation_modal';
const togglesPath = 'provider_external_calendars.data.additional_setup_instructions.icloud_url';
const icloudUrl = get(window.FEATURE_TOGGLES_DATA, togglesPath, ICLOUD_INSTRUCTIONS_URL);

const ICloudExplanationModal = ({ onConfirm, onCancel, onClose }) => (
  <ConfirmationModal
    className={styles.explanationModal}
    title={translate(null, TRANSLATION_SCOPE, 'title')}
    onConfirm={onConfirm}
    onCancel={onCancel}
    onClose={onClose}
    cancelButtonText={translate(null, TRANSLATION_SCOPE, 'actions.cancel')}
    confirmButtonText={translate(null, TRANSLATION_SCOPE, 'actions.confirm')}
  >
    <div
      className={styles.explanationModalContent}
      data-testid="icloud-modal-content"
    >
      <p className={styles.explanationModalDescription}>
        {translateMarkupString(TRANSLATION_SCOPE, 'description', { a: <a href={icloudUrl} target='_blank' />, b: <b /> })}
      </p>
    </div>
  </ConfirmationModal>
);

ICloudExplanationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ICloudExplanationModal;
