exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".z5X8ySqLEvNMRcbwDlEJe {\n  margin: 0;\n  padding: 7px 24px; }\n  ._3bVvG6v-_O246X83cTJ6D-, ._3bVvG6v-_O246X83cTJ6D-:active {\n    background: linear-gradient(90deg, #A8AAA7 0%, #B4B4AF 100%); }\n    ._3bVvG6v-_O246X83cTJ6D-:disabled, ._3bVvG6v-_O246X83cTJ6D-:active:disabled {\n      background: #F3F2EF;\n      color: #CCCCCC; }\n  ._2jIXLu_BYrMHXq3J7XlE4v {\n    background: linear-gradient(90deg, #D18D02 0%, #F9A101 100%); }\n    ._2jIXLu_BYrMHXq3J7XlE4v:disabled {\n      background: #F3F2EF;\n      color: #CCCCCC; }\n", ""]);

// exports
exports.locals = {
	"modalAction": "z5X8ySqLEvNMRcbwDlEJe",
	"modalAction": "z5X8ySqLEvNMRcbwDlEJe",
	"modalAction__secondary": "_3bVvG6v-_O246X83cTJ6D-",
	"modalActionSecondary": "_3bVvG6v-_O246X83cTJ6D-",
	"modalAction__primary": "_2jIXLu_BYrMHXq3J7XlE4v",
	"modalActionPrimary": "_2jIXLu_BYrMHXq3J7XlE4v"
};