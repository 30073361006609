import React from 'react';
import PropTypes from 'prop-types';
import { FaExclamationCircle } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import map from 'lodash/map';

import InactivityNotificationKey from '../enums/inactivity-notification-key';

const parseNotificationMessage = notification => {
  const notificationKey = get(notification, 'key');
  const notificationData = JSON.parse(get(notification, 'data'));

  if (notificationKey === InactivityNotificationKey.PROVIDER_CLOCKED_OUT) {
    return 'You have been clocked out.';
  }

  if (notificationKey === InactivityNotificationKey.CONSULTATION_UNLOCKED) {
    return `Consult with ID ${notificationData.consultation_id} for patient ${
      notificationData.member_name
    } has been unlocked.`;
  }

  return 'An unknown action occurred.';
};

const InactivityNotifications = ({ notifications }) =>
  isEmpty(notifications) ? null : (
    <div className="notice inactivity-notifications">
      <FaExclamationCircle className="inactivity-notifications-icon" />
      <div>
        Due to your recent inactivity:
        <ul className="inactivity-notifications-list">
          {map(notifications, notification => (
            <li key={get(notification, 'key')}>{parseNotificationMessage(notification)}</li>
          ))}
        </ul>
      </div>
    </div>
  );

InactivityNotifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      data: PropTypes.string
    })
  )
};

export default InactivityNotifications;
