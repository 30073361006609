import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@td/shared_utils';
import { CONFERENCE_COMPLETE } from '../constants/call-status';

const CallComplete = ({ callStatus, onClick }) => (
  <div>
    <h1 className="text-center auto-dialer-title-text auto-dialer-title">
      {callStatus === CONFERENCE_COMPLETE ? (
        <I18n scope="autodialer.call_interface.call_complete" text="call_has_ended" />
      ) : (
        <I18n scope="autodialer.call_interface.call_complete" text="you_disconnected" />
      )}
    </h1>
    <div className="text-center auto-dialer-link" onClick={onClick}>
      <I18n scope="autodialer.call_interface.call_complete" text="restart" />
    </div>
  </div>
);

CallComplete.propTypes = {
  callStatus: PropTypes.string.isRequired,
  onClick:    PropTypes.func.isRequired
};

export default CallComplete;
