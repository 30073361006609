import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import cx from 'classnames';

import { translate } from '@td/shared_utils';

const SurveyTextfield = ({
  label,
  placeholder,
  styles,
  name,
  isRequired,
  hiddenFields,
  questionCode,
  updateMentalStatusExamInput,
  mentalHealthCachedState,
  minChars
}) => {
  const [chars, setChars] = useState(0);

  const onInputChange = e => {
    const value = e.target.value;
    setChars(value.length);
    updateMentalStatusExamInput(questionCode, value);
  };

  const validationLabelStyle = () => {
    if (chars < minChars) {
      return get(styles, ['validationLabelStyle', 'required'], null);
    }
    return get(styles, ['validationLabelStyle', 'normal'], null);
  };

  const TRANSLATION_KEY = 'ehr.mh.objective_tab.mental_status_exam_survey.custom_text_fields';

  return (
    <div className={get(styles, 'container')}>
      {label && (
        <label className={get(styles, 'label')} htmlFor={name}>
          {label}
        </label>
      )}
      {hiddenFields &&
        hiddenFields.map((field, index) => <input key={index} type="hidden" name={field.name} value={field.value} />)}
      <input
        className={cx(get(styles, 'input'), 'survey_text_field')}
        placeholder={placeholder}
        name={name}
        onChange={onInputChange}
        value={mentalHealthCachedState[questionCode]}
      />
      {isRequired && (
        <p className={validationLabelStyle()}>
          {translate(null, TRANSLATION_KEY, 'validation_message', { minimum_chars: minChars })} ({chars})
        </p>
      )}
    </div>
  );
};

SurveyTextfield.propTypes = {
  label:                       PropTypes.string,
  placeholder:                 PropTypes.string,
  styles:                      PropTypes.object,
  isRequired:                  PropTypes.bool,
  name:                        PropTypes.string,
  hiddenFields:                PropTypes.array,
  questionCode:                PropTypes.string,
  updateMentalStatusExamInput: PropTypes.func,
  minChars:                    PropTypes.number
};

export default SurveyTextfield;
