import { from, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, first, mergeMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';

import { SHOW_ALERT, CLOSE_ALERT } from './action-types';

import { changeFavIconToAlert, changeFavIconToDefault } from 'app/lib/favicon-manager';

export const showAlertEpic = action$ =>
  action$.pipe(
    ofType(SHOW_ALERT),
    mergeMap(({ payload: { dispatchOnClose } }) => {
      changeFavIconToAlert();

      return action$.pipe(
        ofType(CLOSE_ALERT),
        first(),
        switchMap(() => from(dispatchOnClose))
      );
    })
  );

export const closeAlertEpic = action$ =>
  action$.pipe(
    ofType(CLOSE_ALERT),
    switchMap(() => {
      changeFavIconToDefault();

      return EMPTY;
    })
  );

export default combineEpics(showAlertEpic, closeAlertEpic);
