exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._2FNC2KD0YnF45_iIwtW7-g {\n  color: #27accd;\n  font-size: 12px;\n  font-weight: bold;\n  line-height: 16px;\n  cursor: pointer; }\n\n.m_FWMa1pHlV8G5lzUiNYQ.m_FWMa1pHlV8G5lzUiNYQ {\n  padding: 12px 18px 15px;\n  width: 235px;\n  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22);\n  border-radius: 5px;\n  --tooltipBackground: rgb(204, 242, 250);\n  --tooltipBorder: rgb(22, 97, 116);\n  pointer-events: initial; }\n  .m_FWMa1pHlV8G5lzUiNYQ.m_FWMa1pHlV8G5lzUiNYQ ._13I_CS5dobfQ0hkwzktaHM {\n    position: absolute;\n    width: 8px;\n    right: 9px;\n    top: 9px;\n    cursor: pointer; }\n  .m_FWMa1pHlV8G5lzUiNYQ.m_FWMa1pHlV8G5lzUiNYQ ._3a3qnW2zCpBiuMGro_0AA5 {\n    width: 174px;\n    color: #0a0a0a;\n    font-size: 12px;\n    font-family: Arial;\n    font-weight: bold;\n    line-height: 13px; }\n  .m_FWMa1pHlV8G5lzUiNYQ.m_FWMa1pHlV8G5lzUiNYQ ._1UrquRbGpqIkbKYNeebP4z {\n    margin-top: 5px;\n    color: #0a0a0a;\n    font-size: 12px;\n    font-family: 'Arial';\n    line-height: 15px; }\n", ""]);

// exports
exports.locals = {
	"text": "_2FNC2KD0YnF45_iIwtW7-g",
	"text": "_2FNC2KD0YnF45_iIwtW7-g",
	"popup": "m_FWMa1pHlV8G5lzUiNYQ",
	"popup": "m_FWMa1pHlV8G5lzUiNYQ",
	"popupCloseIcon": "_13I_CS5dobfQ0hkwzktaHM",
	"popupCloseIcon": "_13I_CS5dobfQ0hkwzktaHM",
	"popupTitle": "_3a3qnW2zCpBiuMGro_0AA5",
	"popupTitle": "_3a3qnW2zCpBiuMGro_0AA5",
	"popupDescription": "_1UrquRbGpqIkbKYNeebP4z",
	"popupDescription": "_1UrquRbGpqIkbKYNeebP4z"
};