import { connect } from 'react-redux';
import Scratchpad from './scratchpad';
import { updateScratchpadText } from '../actions';

const mapDispatchToProps = dispatch => ({
  onTextChange: () => dispatch(updateScratchpadText())
});

export default connect(
  null,
  mapDispatchToProps
)(Scratchpad);
